import { Descriptions, Form, Space } from 'antd';
import styled from 'styled-components';

import { Sider } from 'components/UI';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
`;

export const StyledSider = styled(Sider)`
  min-height: calc(100vh - 61px);

  .ant-layout-sider-zero-width-trigger {
    position: fixed;
    top: 50%;
    left: ${(p) => (p.collapsed ? 15 : 185)}px;
    transition: left 0.2s;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 15px;
    color: ${(p) => p.theme.dark_gray};
    background-color: #fff;
    border: 1px solid ${(p) => p.theme.light_gray};
    border-radius: 50%;
    box-shadow: 0 0 2px ${(p) => p.theme.light_gray};
  }

  .ant-form-item-label > label {
    font-size: 12px;
  }

  .ant-form-item-label {
    padding: 0;
  }
`;

export const StyledSpace = styled(Space)`
  max-width: 100%;
  min-height: 100%;
`;

export const StyledPlaceholder = styled('div')`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.2;
`;

export const StyledNoImageContainer = styled('div')`
  width: 170px;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
  border: 1px solid red;
  background-color: ${(p) => p.theme.white};
`;
export const StyledDescriptions = styled(Descriptions)`
  margin-top: 10px;

  .ant-descriptions-row > th {
    padding-bottom: 0;
  }

  .ant-descriptions-item-label {
    font-size: 12px;
    color: ${(p) => p.theme.white};
  }

  .ant-descriptions-item-content {
    color: ${(p) => p.theme.white};
  }
`;

export const DescriptionsContentStyle = { fontWeight: 'bold', fontSize: 11 };

export const SelectWrapper = styled(Form.Item)`
  & .ant-form-item-label > label {
    color: ${(p) => p.theme.white};
  }

  & .ant-select-item-option-content {
    font-size: 12px;
  }
`;
