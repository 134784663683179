import styled from 'styled-components';

import { GRID_MAX_HEIGHT } from 'constants/gridConstants';

export const SectionTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  color: ${(p) => p.theme.white};
`;

export const AdaptiveGridResizer = styled.div`
  div.ag-root .ag-body-viewport {
    height: auto;
    max-height: ${GRID_MAX_HEIGHT};
    overflow-y: scroll;
  }
  .ag-header-viewport {
    flex: unset;
  }
`;

export const ExportButtonWrapper = styled.div`
  display: flex;
  & button {
    margin-left: auto;
    font-size: 12px;
  }
`;
