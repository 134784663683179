import { AgGridReact } from 'ag-grid-react';

import { DISABLED_ROW_CLASS } from 'constants/gridConstants';
import { BasicRow } from 'types/gridRows';

interface toggleRowAsInactiveProps {
  isInactive: boolean;
  row?: HTMLElement | null;
  rowChildCell?: HTMLElement;
}

export const toggleRowAsInactive = ({ isInactive, row, rowChildCell }: toggleRowAsInactiveProps) => {
  if (!rowChildCell && !row) return;

  if (!row) {
    row = rowChildCell?.parentElement;
  }

  row?.classList.toggle(DISABLED_ROW_CLASS, isInactive);
};

export function setGridRows<T extends BasicRow>(
  rows: T[],
  setRowData: React.Dispatch<React.SetStateAction<T[]>>,
  gridRefs: React.RefObject<AgGridReact> | React.RefObject<AgGridReact>[],
  updatedRowIdRef?: React.MutableRefObject<number | string>,
) {
  if (!rows || !setRowData) return;

  if (updatedRowIdRef?.current) {
    const refetchedRow = rows.find((row) => row.id === updatedRowIdRef.current);
    const gridRefsArray = !Array.isArray(gridRefs) ? [gridRefs] : gridRefs;

    refetchedRow &&
      gridRefsArray.forEach((gridRef) => {
        if (gridRef.current?.api) {
          //TODO fix getRowNode
          const rowNode = gridRef.current.api.getRowNode(String(updatedRowIdRef.current));

          if (rowNode) {
            rowNode.setData(refetchedRow);
            gridRef.current.api.redrawRows({ rowNodes: [rowNode] });
          } else rows && setRowData(rows);
        }
      });

    updatedRowIdRef.current = typeof updatedRowIdRef.current === 'string' ? '' : 0;
  } else {
    rows && setRowData(rows);
  }
}
