import { cloneDeep } from 'lodash';

import { BasicFieldValue, BasicFieldsState } from 'types/common';

export const isJSON = (strData: string) => {
  try {
    JSON.parse(strData);
  } catch (e) {
    return false;
  }

  return true;
};

export const partiallyEditedData = (
  modifiedKey: string,
  newValue: BasicFieldValue,
  prevModificationsState: BasicFieldsState,
  initialState: BasicFieldsState | null | undefined,
) => {
  if (
    !initialState ||
    (newValue === prevModificationsState[modifiedKey] &&
      !modifiedKey.includes('customFieldValue') &&
      newValue !== undefined)
  )
    return null;

  const newModificationsState = cloneDeep(prevModificationsState);

  if (newValue === initialState[modifiedKey]) {
    if (modifiedKey in newModificationsState) {
      delete newModificationsState[modifiedKey];
    }
  } else {
    if (modifiedKey.includes('customFieldValue') && newValue === undefined) {
      newModificationsState[modifiedKey] = null;
    } else {
      newModificationsState[modifiedKey] = newValue;
    }
  }

  // console.log(modifiedKey, newValue);
  // console.log({ prevModificationsState, initialState });
  // console.log(newModificationsState);

  return newModificationsState;
};
