import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { LineChartOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  CellEditingStoppedEvent,
  ColDef,
  GetRowIdFunc,
  GetRowIdParams,
  ICellRendererParams,
  RowClassParams,
  RowClickedEvent,
  ExcelStyle,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { TableViewsContext, PermissionsContext } from 'context';
import { isBoolean, isEmpty, isString, xor, isNumber } from 'lodash';
import { useTranslation } from 'react-i18next';
import { getFormattedCurrentDateTime } from 'utils/moment';

import { CellSelect, ClearFiltersButton, NumericCellEditor, Views } from 'components/AgGridComponents';
import { ConciseGridContainer } from 'components/AgGridComponents';
import CellCheckbox from 'components/AgGridComponents/CellCheckbox';
import { numberWithSpaceFormatter } from 'components/AgGridComponents/valueFormatters';
import ImportExportXlsxComponent from 'components/ImportExportXlsxComponent';
import { StoreDetailModal, StoreFleetAnalyticsModal, ConfirmModal } from 'components/Modals';
import NoSeasonEmptyState from 'components/NoSeasonEmptyState';
import { Button, Spinner, NakedButton } from 'components/UI';
import { ActionButtonsWrapper, VerticalPageContent } from 'components/wrappers';
import { COLLECTION_FIELD_PREFIX, COLLECTION_ID_COL_DEF } from 'constants/collections';
import {
  EDITABLE_CELL_CONTENT_CLASS,
  HIGHLIGHTED_COLUMN_COL_DEFS,
  UNIVERSE_HIGHLIGHTED_COLUMN_COL_DEFS,
  DISABLED_ROW_CLASS,
  ALWAYS_ENABLED_CELL_CLASS,
  GRID_DEFAULT_COL_DEFS,
  GRID_DEFAULT_PROPS,
} from 'constants/gridConstants';
import { isCollectionColDef, prepareRowData } from 'helpers/collections';
import { generateCustomFieldsColDefs } from 'helpers/customFields';
import { hasFiltersChanged } from 'helpers/grid';
import { applyColWidths, getVisibleColDefs, sortEmpty } from 'helpers/gridCols';
import { getContextMenu } from 'helpers/gridContextMenu';
import { setGridRows, toggleRowAsInactive } from 'helpers/gridRows';
import { useCommonData } from 'hooks/useCommonData';
import { useGridCols } from 'hooks/useGridCols';
import {
  StoreFleetDto,
  useGetCollectionsNamesQuery,
  useGetCollectionStoreGroupsLazyQuery,
  useGetStoreFleetLazyQuery,
  useStoreCustomFieldsQuery,
  useUpdateStoreDetailsMutation,
  useUpdateStoreFleetMutation,
  useUpdateStoreToStoreGroupMutation,
  PermissionsEnum,
  useGetCapacityTypeQuery,
  CapacityType,
} from 'services/graphql/main';
import { useError } from 'services/utils';
import { AnyColDef, CollectionColDef } from 'types/collections';
import { SelectedViewId } from 'types/views';

import { EDITABLE_BOOLEAN_FIELDS } from './constants';
import { CollectionStoreGroups } from './types';

const { Option } = CellSelect;

const StoreFleetTable: React.FC = () => {
  const { t } = useTranslation('translation');
  const { addError } = useError();

  const { selectedViewId, getView, getColumnsWidths, pendingTableRefresh, setPendingTableRefresh } =
    useContext(TableViewsContext);
  const { permissions } = useContext(PermissionsContext);

  const { seasonId, universeId, seasonName, universeName, universeCode } = useCommonData();

  const [allColumnDefs, setAllColumnDefs] = useState<AnyColDef[] | null>(null);
  const [columnDefs, setColumnDefs] = useState<AnyColDef[] | null>(null);
  const [rowData, setRowData] = useState<StoreFleetDto[]>([]);
  const [collectionsStoreGroups, setCollectionsStoreGroups] = useState<CollectionStoreGroups | null>(null);
  const [storeId, setStoreId] = useState<number>();
  const [isModalOpen, setIsModalOpen] = useState({
    storeDetailModal: false,
    analyticsModal: false,
    confirmationModal: false,
  });
  const [isGridReady, setIsGridReady] = useState(false);
  const [hasFilters, setHasFilters] = useState(false);
  const [selectedRowForDeletion, setSelectedRowForDeletion] = useState<number>();
  const [refetchLoading, setRefetchLoading] = useState(false);

  const [
    getStoreFleetLazyQuery,
    { data: dataStoreFleet, loading: loadingStoreFleet, refetch: refetchStoreFleet, error: errorStoreFleet },
  ] = useGetStoreFleetLazyQuery({ onError: (err) => addError(err, 'error') });

  const {
    data: dataCollections,
    loading: loadingCollections,
    refetch: refetchDataCollections,
  } = useGetCollectionsNamesQuery({
    variables: { universeId, isActive: true },
    onError: (err) => addError(err, 'error'),
  });

  const { data: dataStoreCustomFields } = useStoreCustomFieldsQuery({ onError: (err) => addError(err, 'error') });
  const { data: dataCapacityType } = useGetCapacityTypeQuery({ onError: (err) => addError(err, 'error') });

  const [viewColsInitialized, setViewColsInitialized] = useState<SelectedViewId | undefined>();
  const [getCollectionStoreGroups, { loading: loadingCollectionStoreGroup }] = useGetCollectionStoreGroupsLazyQuery();
  const [updateStoreFleetMutation] = useUpdateStoreFleetMutation();
  const [updateStoreToStoreGroupMutation] = useUpdateStoreToStoreGroupMutation();
  const [updateStoreDetailsMutation] = useUpdateStoreDetailsMutation();

  const gridRef = useRef<AgGridReact>(null);
  const selectedGroupValueRef = useRef<{ id?: string; initialValue?: number[]; value?: number[] }>({});
  const updatedRowIdRef = useRef(0);
  const loadedDataForSeasonRef = useRef<number | null>(null);

  const { handleColumnMoved, handleColumnResized, handleSortChanged, handleFilterChanged, handleColumnPinned } =
    useGridCols({
      isGridReady,
      gridRef,
      tableName: 'storeFleet',
    });

  useEffect(() => {
    if (!universeId || !seasonId) return;

    const loadData = async () => {
      if (!loadedDataForSeasonRef.current) {
        await getStoreFleetLazyQuery({ variables: { seasonId, universeId } });
        gridRef.current?.api?.redrawRows();
      } else {
        await refetchStoreFleet({ seasonId, universeId });

        gridRef.current?.api.redrawRows();
      }

      loadedDataForSeasonRef.current = seasonId;
    };

    loadData();
  }, [getStoreFleetLazyQuery, refetchStoreFleet, seasonId, universeId]);

  const updateStoreFleetData = useCallback(
    async (id: number, colId: string, newValue: number | boolean) => {
      setIsGridReady(false);
      if (!seasonId || !universeId) return;

      let isUpdated = false;

      if (colId === 'isSynced' || colId === 'ignoreMinMax') {
        const result = await updateStoreDetailsMutation({
          variables: { storeDetails: { universeId, seasonId, id, [colId]: newValue } },
          onError: (err) => addError(err, 'error'),
        });

        if (result.data?.updateStoreDetails.id) isUpdated = true;
      } else {
        const result = await updateStoreFleetMutation({
          variables: { storeFleet: { seasonId, universeId, storeId: id, [colId]: newValue } },
          onError: (err) => addError(err, 'error'),
        });

        if (result.data?.updateStoreFleet.id) isUpdated = true;
      }

      if (isUpdated) {
        if (colId !== 'targetTurnover') updatedRowIdRef.current = id;

        await refetchStoreFleet();
      }
      setIsGridReady(true);
    },
    [addError, refetchStoreFleet, seasonId, universeId, updateStoreDetailsMutation, updateStoreFleetMutation],
  );

  const handleBooleanCellValueChange = useCallback(
    (id: number, colId: string, newValue: boolean) => {
      updateStoreFleetData(id, colId, newValue);
    },
    [updateStoreFleetData],
  );

  const showModal = useMemo(
    () => ({
      STORE_DETAIL_MODAL: () => setIsModalOpen((prevState) => ({ ...prevState, storeDetailModal: true })),
      ANALYTICS_MODAL: () => setIsModalOpen((prevState) => ({ ...prevState, analyticsModal: true })),
      CONFIRMATION_MODAL: () => setIsModalOpen((prevState) => ({ ...prevState, confirmationModal: true })),
    }),
    [],
  );

  const hideModal = useMemo(
    () => ({
      STORE_DETAIL_MODAL: () => setIsModalOpen((prevState) => ({ ...prevState, storeDetailModal: false })),
      ANALYTICS_MODAL: () => setIsModalOpen((prevState) => ({ ...prevState, analyticsModal: false })),
      CONFIRMATION_MODAL: () => setIsModalOpen((prevState) => ({ ...prevState, confirmationModal: false })),
    }),
    [],
  );

  const defaultColDef: ColDef = useMemo(() => {
    return {
      ...GRID_DEFAULT_COL_DEFS,
      cellClass(params) {
        return params.column.getColDef().field === 'isActive' ? ALWAYS_ENABLED_CELL_CLASS : undefined;
      },
      tooltipValueGetter(params) {
        const value = params.valueFormatted ? params.valueFormatted : params.value;

        return isString(value) && !isCollectionColDef(params.colDef!) ? value : null;
      },
      cellRenderer(params: ICellRendererParams) {
        const value = params.valueFormatted ? params.valueFormatted : params.value;
        const field = params.colDef!.field!;

        if (isBoolean(value)) {
          return (
            <CellCheckbox
              checked={value}
              disabled={
                !EDITABLE_BOOLEAN_FIELDS.includes(field) ||
                !(permissions as PermissionsEnum[])?.includes(PermissionsEnum.StoreFleetMainTableEdit)
              }
              onChange={(checked) => {
                field === 'isActive' && toggleRowAsInactive({ isInactive: checked, rowChildCell: params.eGridCell });
                handleBooleanCellValueChange(params.data.id, field, checked);
              }}
              changeColor={field === 'isSynced'}
            />
          );
        }

        return params.colDef?.editable ? <div className={EDITABLE_CELL_CONTENT_CLASS}>{value}</div> : value;
      },
    };
  }, [permissions, handleBooleanCellValueChange]);

  const handleRemoveRow = useCallback(async () => {
    if (selectedRowForDeletion && seasonId) {
      await updateStoreDetailsMutation({
        variables: { storeDetails: { universeId, seasonId, id: selectedRowForDeletion, isDeleted: true } },
        onError: (err) => addError(err, 'error'),
      });
      await refetchStoreFleet();

      setSelectedRowForDeletion(undefined);
      hideModal['CONFIRMATION_MODAL']();
    }
  }, [
    updateStoreDetailsMutation,
    addError,
    hideModal,
    refetchStoreFleet,
    seasonId,
    selectedRowForDeletion,
    universeId,
  ]);

  const RemoveRenderer = useCallback(
    (props: ICellRendererParams) => {
      const handlePrepareRemoveRow = (props: ICellRendererParams) => {
        setSelectedRowForDeletion(props.node.data.id);
        showModal['CONFIRMATION_MODAL']();
      };

      return (
        <NakedButton type="text" onClick={() => handlePrepareRemoveRow(props)}>
          <DeleteOutlined style={{ cursor: 'pointer', textAlign: 'center' }} />
        </NakedButton>
      );
    },
    [showModal],
  );

  const updateStoreToStoreGroups = async (params: ICellRendererParams, collectionId: number) => {
    const { initialValue = [], value = [] } = selectedGroupValueRef.current;

    let newValue = value;
    if (typeof value === 'number') {
      newValue = value === -1 ? [] : [value];
    }

    universeId &&
      seasonId &&
      (await updateStoreToStoreGroupMutation({
        variables: {
          storeToStoreGroups: {
            universeId,
            seasonId,
            storeId: params.data.id,
            collectionId,
            storeGroupIds: newValue,
          },
        },
      }));
    await refetchStoreFleet();
  };

  useEffect(() => {
    dataCollections && refetchDataCollections();
  }, [refetchDataCollections, dataCollections]);

  useEffect(() => {
    if (!dataCollections?.collections) return;

    const loadAllCollectionGroups = async (collectionIds: number[]) => {
      const collectionStoreGroups: CollectionStoreGroups = {};

      const result = await getCollectionStoreGroups({
        variables: { collectionId: collectionIds[0] },
      });

      collectionStoreGroups[collectionIds[0]] = result.data?.storeGroups || [];

      for (let i = 1; i < collectionIds.length; i++) {
        const restResults = await getCollectionStoreGroups({ variables: { collectionId: collectionIds[i] } });
        collectionStoreGroups[collectionIds[i]] = restResults.data?.storeGroups || [];
      }

      setCollectionsStoreGroups(collectionStoreGroups);
    };

    const collectionIds = dataCollections?.collections?.map((collection) => collection.id);
    if (isEmpty(collectionIds)) {
      setCollectionsStoreGroups({});
    } else {
      loadAllCollectionGroups(collectionIds);
    }
  }, [dataCollections?.collections, getCollectionStoreGroups]);

  const getCapacityUnit = useCallback(() => {
    if (dataCapacityType?.tenantSettings.storeCapacityType === CapacityType.NotSet) return '';
    if (dataCapacityType?.tenantSettings.storeCapacityType === CapacityType.Pieces) return '(pièces)';
    if (dataCapacityType?.tenantSettings.storeCapacityType === CapacityType.SquareMeters) return '(m²)';
  }, [dataCapacityType?.tenantSettings.storeCapacityType]);

  useEffect(() => {
    const storeFleetData = dataStoreFleet?.storeFleet;
    const collectionsData = dataCollections?.collections;

    if (
      loadingStoreFleet ||
      loadingCollections ||
      !storeFleetData ||
      !collectionsData ||
      !collectionsStoreGroups ||
      (selectedViewId !== undefined && viewColsInitialized === selectedViewId && !pendingTableRefresh) ||
      updatedRowIdRef.current
    )
      return;

    let colDefs: ColDef[] = [
      {
        field: 'storeCode',
        headerName: t(`store.code`),
        minWidth: 65,
        sort: 'asc',
        type: 'TEXT_COLUMN',
        cellClass: 'stringType',
      },
      { field: 'storeType', headerName: t(`store.type`), minWidth: 70, type: 'TEXT_COLUMN' },
      {
        field: 'storeRegion',
        headerName: t(`storeFleet.store_region`),
        minWidth: 70,
        type: 'TEXT_COLUMN',
        comparator: sortEmpty,
      },
      {
        field: 'storeName',
        headerName: t(`store.name`),
        minWidth: 70,
        type: 'TEXT_COLUMN',
        comparator: sortEmpty,
      },
    ];

    if (!isEmpty(collectionsData)) {
      const collectionsColDefs: ColDef[] = collectionsData.map((collection, index: number) => {
        const name = collection.name || t('store.collection', { index }) || '';
        const field = `${COLLECTION_FIELD_PREFIX}${collection.id}`;

        return {
          [COLLECTION_ID_COL_DEF]: collection.id,
          field,
          headerName: name,
          minWidth: 80,
          filter: true,
          filterParams: {
            valueFormatter: (params: any) => {
              const collectionId = params.colDef.__collectionId;
              const value = collectionsStoreGroups[collectionId].find(
                (collection) => collection.id == params.value,
              )?.name;

              return value || '';
            },
          },
          ...HIGHLIGHTED_COLUMN_COL_DEFS,
          cellRenderer(params: ICellRendererParams) {
            if (!params.data) return ' ';

            const currentColDef = params.colDef as CollectionColDef;
            const collectionId = currentColDef?.[COLLECTION_ID_COL_DEF];

            if (collectionId && !isEmpty(collectionsStoreGroups[collectionId])) {
              const selectId = `${collectionId}_${params.data.id}`;
              const isCurrent = selectedGroupValueRef.current.id === selectId;
              const isMultipleSelection = collection.combinationType !== 'EXCLUSIVE';

              return (
                <CellSelect
                  defaultValue={params.value}
                  bordered={false}
                  mode={isMultipleSelection ? 'multiple' : undefined}
                  loading={isCurrent && loadingCollectionStoreGroup}
                  showArrow
                  showSearch={false}
                  popupClassName="ant-custom-dropdown"
                  clearIcon={null}
                  dropdownMatchSelectWidth={false}
                  maxTagCount="responsive"
                  maxTagPlaceholder={(omittedItems) => {
                    return (
                      <span className="maxTag" title={omittedItems.map((item) => item.label).join('; ')}>
                        +{omittedItems.length}
                      </span>
                    );
                  }}
                  onChange={(value) => {
                    selectedGroupValueRef.current = { ...selectedGroupValueRef.current, value: value as number[] };
                  }}
                  onSelect={() => updateStoreToStoreGroups(params, collectionId)}
                  onDeselect={() => updateStoreToStoreGroups(params, collectionId)}
                >
                  {!isMultipleSelection && (
                    <Option className="ant-custom-option" value={-1}>
                      {' '}
                    </Option>
                  )}

                  {collectionsStoreGroups[collectionId].map((group) => (
                    <Option className="ant-custom-option" value={group.id} key={group.id}>
                      {group.name}
                    </Option>
                  ))}
                </CellSelect>
              );
            }

            return null;
          },
        };
      });

      colDefs.push(...collectionsColDefs);
    }

    colDefs.push(
      {
        field: 'storeStrengthImplant',
        headerName: t(`storeFleet.store_strength_implant`),
        ...HIGHLIGHTED_COLUMN_COL_DEFS,
      },
      {
        field: 'targetTurnover',
        headerName: t(`storeFleet.target_turnover`),
        editable: (permissions as PermissionsEnum[])?.includes(PermissionsEnum.StoreFleetMainTableEdit),
        valueParser: 'Number(newValue)',
        valueFormatter: numberWithSpaceFormatter,
        cellEditor: NumericCellEditor,
        ...HIGHLIGHTED_COLUMN_COL_DEFS,
      },

      ...generateCustomFieldsColDefs(dataStoreCustomFields?.storeCustomFields, 'storeCustomField'),

      {
        field: 'ignoreMinMax',
        headerName: t(`storeFleet.excluded_from_conditional_min_max`),
        filter: true,
      },
      {
        field: 'pickingPriority',
        headerName: t(`storeFleet.priority_picking_order`),
        editable: (permissions as PermissionsEnum[])?.includes(PermissionsEnum.StoreFleetMainTableEdit),
        valueParser: 'Number(newValue)',
        cellEditor: NumericCellEditor,
      },
      {
        field: 'capacity',
        headerName: t(`store.capacity`, { unit: getCapacityUnit() }),
        editable: (permissions as PermissionsEnum[])?.includes(PermissionsEnum.StoreFleetMainTableEdit),
        valueParser: 'Number(newValue)',
        valueFormatter: numberWithSpaceFormatter,
        cellEditor: NumericCellEditor,
      },
      {
        field: 'handlesShowcase',
        headerName: t(`storeFleet.storefront_management`),
        filter: true,
      },
      {
        field: 'isSynced',
        headerName: t(`storeFleet.sending_is`),
        filter: true,
      },
      {
        field: 'isActive',
        headerName: t(`storeFleet.is_active`),
        filter: true,
        comparator: (valueA, valueB, nodeA, nodeB) => {
          if (valueA === valueB) {
            return nodeA.data.storeCode > nodeB.data.storeCode ? 1 : -1;
          }

          return valueA < valueB ? 1 : -1;
        },
        ...UNIVERSE_HIGHLIGHTED_COLUMN_COL_DEFS,
      },
    );

    colDefs = colDefs.map((colDef) => ({ ...colDef, headerTooltip: colDef.headerName }));

    setColumnDefs([
      ...getVisibleColDefs({ allColDefs: colDefs, getView }),
      {
        field: 'deleteOption',
        headerName: '',
        suppressMenu: true,
        sortable: false,
        cellStyle: { textAlign: 'center', pointerEvents: 'all' },
        maxWidth: 60,
        editable: false,
        cellRenderer: RemoveRenderer,
        pinned: 'right',
        hide: !(permissions as PermissionsEnum[])?.includes(PermissionsEnum.StoreFleetMainTableDelete),
      },
      {
        field: 'season',
        headerName: t('common.season'),
        valueFormatter: () => (seasonName ? seasonName : ''),
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
      {
        field: 'universe',
        headerName: t('common.universe'),
        valueFormatter: () => (universeName ? universeName : ''),
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
    ]);
    setAllColumnDefs(colDefs);
    setViewColsInitialized(selectedViewId);
    setPendingTableRefresh(false);
  }, [
    collectionsStoreGroups,
    dataCollections?.collections,
    dataStoreCustomFields?.storeCustomFields,
    dataStoreFleet?.storeFleet,
    getView,
    loadingCollectionStoreGroup,
    loadingCollections,
    loadingStoreFleet,
    pendingTableRefresh,
    seasonId,
    selectedViewId,
    setPendingTableRefresh,
    t,
    universeId,
    updateStoreToStoreGroupMutation,
    viewColsInitialized,
    RemoveRenderer,
    permissions,
    updateStoreToStoreGroups,
    getCapacityUnit,
    seasonName,
    universeName,
  ]);

  useEffect(() => {
    columnDefs?.length && !selectedViewId && gridRef?.current?.columnApi.resetColumnState();
    viewColsInitialized && applyColWidths(gridRef, getColumnsWidths);
  }, [getColumnsWidths, viewColsInitialized, columnDefs, gridRef, selectedViewId]);

  const redrawStoreIdRow = useCallback(() => {
    if (!storeId || !gridRef.current) return;

    const storeRowNode = gridRef.current.api.getRowNode(String(storeId));
    storeRowNode && gridRef.current.api.redrawRows({ rowNodes: [storeRowNode] });
  }, [storeId]);

  useEffect(() => {
    const storeFleetData = dataStoreFleet?.storeFleet;

    if (!allColumnDefs) return;

    const rows = prepareRowData(storeFleetData, allColumnDefs) as StoreFleetDto[];

    setGridRows(rows, setRowData, gridRef, updatedRowIdRef);

    redrawStoreIdRow();
  }, [allColumnDefs, dataStoreFleet?.storeFleet, redrawStoreIdRow]);

  const excelStyles: ExcelStyle[] = [
    {
      id: 'stringType',
      dataType: 'String',
    },
  ];

  const generateColumnsForExport = () => {
    const keys = gridRef.current?.columnApi
      .getAllDisplayedColumns()
      .map((column) => column.getColId())
      .filter((column) => column !== 'deleteOption');
    keys?.unshift('season');
    keys?.unshift('universe');

    return keys;
  };

  const onExport = useCallback(() => {
    gridRef.current &&
      gridRef.current.api.exportDataAsExcel({
        columnKeys: generateColumnsForExport(),
        processCellCallback: (params) => {
          if (params.column.getColDef().field?.includes('collection')) {
            const collectionId = params.column.getColDef().field?.slice(11);
            let storeGroups;
            if (collectionsStoreGroups && collectionId) {
              storeGroups = collectionsStoreGroups[collectionId]
                ?.filter((group) => params.value.includes(group.id))
                .map((group) => group.name)
                .join('; ');
            }

            return storeGroups;
          }

          if (params.column.getColId() === 'season') {
            return seasonName;
          }
          if (params.column.getColId() === 'universe') {
            return universeCode;
          }
          if (params.column.getColId() === 'storeCode') {
            return params.value.toString();
          }

          if (isNumber(params.value) && params?.value % 1 != 0) {
            return params.value.toFixed(1);
          }

          return params.value;
        },
        fileName: `${t('storeFleet.store_fleet')}_${getFormattedCurrentDateTime()}`,
      });
  }, [collectionsStoreGroups, t, seasonName, universeCode]);

  const onCSVExport = useCallback(() => {
    gridRef.current!.api.exportDataAsCsv();
  }, []);

  const handleCellEditingStopped = useCallback(
    async (e: CellEditingStoppedEvent) => {
      if (typeof e.newValue === 'boolean') return;

      const id = e.data.id;
      const colId = e.column.getColId();
      const oldValue = Number(e.oldValue);
      const newValue = Number(e.newValue);

      if (isNaN(newValue) || newValue === oldValue) return;

      updateStoreFleetData(id, colId, newValue);
    },
    [updateStoreFleetData],
  );

  const getRowClass = useCallback((params: RowClassParams) => {
    return params.data?.isActive ? undefined : DISABLED_ROW_CLASS;
  }, []);

  const getRowId = useMemo<GetRowIdFunc>(() => {
    return (params: GetRowIdParams) => params.data.id;
  }, []);

  const handleRowDoubleClick = useCallback(
    (params: RowClickedEvent) => {
      const { id } = params.data;

      setStoreId(id);
      showModal['STORE_DETAIL_MODAL']();
    },
    [showModal],
  );

  const handleAnalyticsButtonClicked = useCallback(() => {
    showModal['ANALYTICS_MODAL']();
  }, [showModal]);

  if (!seasonId) return <NoSeasonEmptyState />;
  if (loadingStoreFleet || loadingCollections) return <Spinner />;

  return (
    <>
      <VerticalPageContent>
        <ActionButtonsWrapper style={{ justifyContent: 'flex-start', margin: '0 0 5px' }}>
          <Views
            flatColDefs={allColumnDefs?.filter(
              (column) => column.field !== 'universe' && column.field !== 'season' && column.field !== 'deleteOption',
            )}
          />
          <Button className="analytics-button" onClick={handleAnalyticsButtonClicked} size="small">
            <LineChartOutlined />
          </Button>

          <ClearFiltersButton gridRefs={gridRef} hasFilters={hasFilters} />

          <div style={{ marginLeft: 'auto' }}>
            <ImportExportXlsxComponent
              exportClickHandler={onExport}
              refetchData={async () => {
                setRefetchLoading(true);
                await refetchDataCollections();
                await refetchStoreFleet();
                await gridRef.current?.api.redrawRows();
                setRefetchLoading(false);
              }}
            />
          </div>
        </ActionButtonsWrapper>

        {refetchLoading ? (
          <Spinner />
        ) : (
          !errorStoreFleet && (
            <ConciseGridContainer isGridReady={isGridReady}>
              <AgGridReact
                {...GRID_DEFAULT_PROPS}
                ref={gridRef}
                defaultColDef={defaultColDef}
                columnDefs={columnDefs}
                rowData={rowData}
                onGridReady={() => {
                  isEmpty(dataStoreFleet?.storeFleet) && setIsGridReady(true);
                }}
                onFirstDataRendered={() => setIsGridReady(true)}
                onRowDoubleClicked={handleRowDoubleClick}
                onCellEditingStopped={handleCellEditingStopped}
                getRowClass={getRowClass}
                getRowId={getRowId}
                singleClickEdit
                onSortChanged={(e) => handleSortChanged(null, e)}
                onColumnPinned={handleColumnPinned}
                onColumnMoved={handleColumnMoved}
                onColumnResized={handleColumnResized}
                onFilterChanged={(e) => {
                  setHasFilters(hasFiltersChanged(e));
                  handleFilterChanged();
                }}
                enterMovesDownAfterEdit={true}
                getContextMenuItems={() => getContextMenu(onExport, onCSVExport)}
                excelStyles={excelStyles}
              />
            </ConciseGridContainer>
          )
        )}
      </VerticalPageContent>

      {dataStoreFleet?.storeFleet && storeId && (
        <StoreDetailModal
          isOpen={isModalOpen.storeDetailModal}
          handleCancel={hideModal['STORE_DETAIL_MODAL']}
          storeId={storeId}
          refetchStoreFleet={async () => {
            await refetchStoreFleet({ seasonId, universeId });
            gridRef.current?.api.redrawRows();
          }}
        />
      )}
      <StoreFleetAnalyticsModal isOpen={isModalOpen.analyticsModal} handleCancel={hideModal['ANALYTICS_MODAL']} />
      <ConfirmModal
        customTitle={t('operationalSettings.delete_row_warning_message')}
        onOk={handleRemoveRow}
        open={isModalOpen.confirmationModal}
        onCancel={() => {
          hideModal['CONFIRMATION_MODAL']();
          setSelectedRowForDeletion(undefined);
        }}
      />
    </>
  );
};

export default StoreFleetTable;
