import { Select } from 'antd';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const NavbarContainer = styled.nav`
  width: 100%;
  background-color: ${(p) => p.theme.light_blue_background};
  display: flex;
  flex-direction: column;
  padding: 5px 0;
`;

export const LeftContainer = styled.div`
  flex: 70%;
  display: flex;
  align-items: center;
  height: fit-content;
  padding-left: 10px;
`;

export const RightContainer = styled.div`
  flex: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
  height: fit-content;
`;

export const NavbarInnerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const NavbarLinkContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NavbarLink = styled(NavLink)`
  color: white;
  text-decoration: none;
  padding: 3px 1px;
  margin-left: 25px;
  border-bottom: 2px solid transparent;
  font-size: 13px;

  :hover {
    color: ${(p) => p.theme.primary_yellow};
  }

  :focus,
  &.active {
    font-weight: bold;
    border-bottom: 2px solid ${(p) => p.theme.primary_yellow};
  }
`;

export const LogoutButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  color: white;
  padding: 5px;
  margin-left: 20px;
  border-bottom: 2px solid transparent;

  :hover {
    color: ${(p) => p.theme.primary_yellow};
  }

  :focus,
  &.active {
    font-weight: bold;
    border-bottom: 2px solid ${(p) => p.theme.primary_yellow};
  }
`;

export const Logo = styled.img`
  width: 20px;
  height: auto;
`;

export const StyledSelect = styled(Select)`
  height: fit-content;
  font-size: 12px;

  .ant-select:not(.ant-select-customize-input),
  .ant-select-selector {
    height: 25px !important;
    display: flex;
    align-items: center;
  }

  .ant-select-selector {
    background-color: ${(p) => p.theme.dark_blue_background} !important;
    color: ${(p) => p.theme.white};
  }

  .ant-select-arrow {
    color: ${(p) => p.theme.white};
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-selector:hover,
  .ant-select-open .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }
`;
