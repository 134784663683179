import styled from 'styled-components';

export const StyledTitle = styled.h5`
  color: ${(props) => props.theme.white};
  font-size: 23px;
  font-weight: 700;
  margin-bottom: 20px;
`;

export const DetailsBox = styled.div`
  background-color: ${(props) => props.theme.white};
  border: 2px solid #dcdde0;
  border-radius: 3px;
  padding: 16px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;

  & p:not(:first-child) {
    margin-top: 0px;
    margin-left: 10px;
  }

  & svg {
    font-size: 17px;
  }

  & span {
    margin-left: auto;
  }
`;

export const BoxTitle = styled.p`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
`;

export const GridResizer = styled.div`
  div.ag-root .ag-body-viewport {
    height: 400px;
    overflow-y: scroll;
  }

  .ag-header-viewport {
    flex: unset;
  }
`;

export const SubTitle = styled.p`
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 15px;
`;

export const NewsTitle = styled.h5`
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
`;

export const NewsSubtitle = styled.p`
  font-size: 14px;
  margin-top: 12px;
`;

export const NewsBody = styled.p`
  margin: 14px 0;
`;

export const NewsImage = styled.img`
  width: 80%;
`;
