import React, { useEffect, useMemo, useRef, useState } from 'react';

import { PlusCircleOutlined, CloseCircleOutlined, CheckCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { StyledModal } from 'components/Modals/index';
import { NakedButton } from 'components/UI';
import { composeTranslatedMessage } from 'helpers/translations';
import { useGetFileImportLogsQuery, useGetFileImportDetailsLazyQuery } from 'services/graphql/main';
import { useError } from 'services/utils';

import { GridResizer } from '../styles';

type Props = {
  handleCancel: () => void;
  isOpen: boolean;
};

const LastImportDetails: React.FC<Props> = ({ isOpen, handleCancel }) => {
  const { t } = useTranslation('translation');
  const { addError } = useError();
  const gridRef = useRef<AgGridReact<{ object: string }[]>>(null);

  const [selectedLineId, setSelectedLineId] = useState<number>();
  const [errorsData, setErrorsData] = useState();

  const { data: fileImportLogs } = useGetFileImportLogsQuery({
    onError: (err) => addError(err, 'warning'),
  });
  const [getFileImportDetails] = useGetFileImportDetailsLazyQuery({
    onError: (err) => addError(err, 'warning'),
  });

  useEffect(() => {
    const fetchDetailedErrors = async () => {
      if (selectedLineId) {
        const detailedErrors = await getFileImportDetails({ variables: { fileImportLogId: selectedLineId } });
        setErrorsData(JSON.parse(detailedErrors.data?.fileImportLog.errors || ''));
      }
    };
    fetchDetailedErrors();
  }, [selectedLineId, getFileImportDetails]);

  const columnDetailsDefs: ColDef[] = useMemo(
    () => [
      {
        field: 'importDate',
        headerName: t('dashboard.import_date'),
        valueFormatter: (params) => {
          return moment(params.value).local().format('DD-MM-YYYY HH:mm:ss');
        },
      },
      {
        field: 'object',
        headerName: t('dashboard.lib_interface'),
      },
      {
        field: 'errorsCount',
        headerName: t('dashboard.errors_count'),
      },
      {
        field: 'isSuccessful',
        headerName: t('dashboard.integration'),
        cellRenderer: (props: ICellRendererParams) => {
          return props.data.isSuccessful ? (
            <CheckCircleOutlined style={{ color: 'green' }} />
          ) : (
            <CloseCircleOutlined style={{ color: 'red' }} />
          );
        },
      },
      {
        suppressMenu: true,
        sortable: false,
        cellStyle: { textAlign: 'center' },
        maxWidth: 60,
        editable: false,
        cellRenderer: (props: ICellRendererParams) => {
          return (
            <NakedButton type="text" onClick={() => setSelectedLineId(props.data.id)}>
              <PlusCircleOutlined style={{ cursor: 'pointer', textAlign: 'center' }} />
            </NakedButton>
          );
        },
        pinned: 'right',
      },
    ],
    [t],
  );

  const columnErrorsDefs: ColDef[] = [
    {
      field: 'lineIndex',
      headerName: t('dashboard.line_index'),
    },
    {
      field: 'code',
      headerName: t('dashboard.error_message'),
      valueFormatter: (params) => {
        if (params.data.args?.length) {
          return composeTranslatedMessage(t(`errors.${params.value}`), params.data.args);
        } else {
          return t(`errors.${params.value}`);
        }
      },
    },
    {
      field: 'field',
      headerName: t('dashboard.field'),
    },
  ];

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100,
      sortable: true,
    };
  }, []);

  return (
    <StyledModal
      title={t('dashboard.last_import_details')}
      open={isOpen}
      onCancel={() => {
        setSelectedLineId(undefined);
        setErrorsData(undefined);
        handleCancel();
      }}
      footer={null}
      width="70%"
      centered={true}
    >
      <GridResizer>
        {selectedLineId ? (
          <>
            <NakedButton type="text" onClick={() => setSelectedLineId(undefined)}>
              <ArrowLeftOutlined
                style={{ cursor: 'pointer', textAlign: 'center', color: '#ffffff', fontSize: 20, marginBottom: 10 }}
              />
            </NakedButton>
            <AgGridReact
              containerStyle={{ width: '100%' }}
              ref={gridRef}
              columnDefs={columnErrorsDefs}
              rowData={errorsData}
              defaultColDef={defaultColDef}
              domLayout={'autoHeight'}
            />
          </>
        ) : (
          <AgGridReact
            containerStyle={{ width: '100%' }}
            ref={gridRef}
            columnDefs={columnDetailsDefs}
            rowData={fileImportLogs?.fileImportLogs}
            defaultColDef={defaultColDef}
            domLayout={'autoHeight'}
          />
        )}
      </GridResizer>
    </StyledModal>
  );
};
export default LastImportDetails;
