import { ICellRendererParams } from 'ag-grid-community';

import { ProductCustomFieldsDto, StoreCustomFieldsDto } from 'services/graphql/main';

export const generateCustomFieldsColDefs = (
  customFields: StoreCustomFieldsDto | ProductCustomFieldsDto | undefined,
  fieldPrefix = 'customField',
  cellRenderer?: (params: ICellRendererParams) => any,
) => {
  const colDefs = [];

  if (customFields?.customFieldName1) {
    colDefs.push({
      field: `${fieldPrefix}1`,
      headerName: customFields?.customFieldName1,
      type: 'TEXT_COLUMN',
      cellRenderer: cellRenderer || undefined,
    });
  }
  if (customFields?.customFieldName2) {
    colDefs.push({
      field: `${fieldPrefix}2`,
      headerName: customFields?.customFieldName2,
      type: 'TEXT_COLUMN',
      cellRenderer: cellRenderer || undefined,
    });
  }
  if (customFields?.customFieldName3) {
    colDefs.push({
      field: `${fieldPrefix}3`,
      headerName: customFields?.customFieldName3,
      type: 'TEXT_COLUMN',
      cellRenderer: cellRenderer || undefined,
    });
  }
  if (customFields?.customFieldName4) {
    colDefs.push({
      field: `${fieldPrefix}4`,
      headerName: customFields?.customFieldName4,
      type: 'TEXT_COLUMN',
      cellRenderer: cellRenderer || undefined,
    });
  }
  if (customFields?.customFieldName5) {
    colDefs.push({
      field: `${fieldPrefix}5`,
      headerName: customFields?.customFieldName5,
      type: 'TEXT_COLUMN',
      cellRenderer: cellRenderer || undefined,
    });
  }

  return colDefs;
};
