import React from 'react';

import { Checkbox } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';

type Props = CheckboxProps & {
  isChecked?: boolean;
};

const PrimaryCheckbox: React.FC<Props> = ({ isChecked, onChange, ...rest }) => {
  return <Checkbox checked={isChecked} onChange={onChange} {...rest} />;
};

export default PrimaryCheckbox;
