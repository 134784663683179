import { useCallback, useEffect, useRef } from 'react';

import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

import { TOTAL_FIELD } from '../constants/optimalStockCalculationTable';

interface Props {
  hasStockMax: boolean | null;
  hasStockMin: boolean | null;
  isOverridesUpdateInProgress: boolean;
  overridesColumnDefs: ColDef[] | null;
  overridesGridRef: React.RefObject<AgGridReact>;
}

export const useOverrideFocusOnFirstSizeCell = ({
  overridesGridRef,
  overridesColumnDefs,
  isOverridesUpdateInProgress,
  hasStockMin,
  hasStockMax,
}: Props) => {
  const focusRowIndexRef = useRef<number | null>(null);

  const focusFirstSizeCell = useCallback(
    (rowIndex: number) => {
      const columns = overridesGridRef.current?.columnApi.getColumns();

      const firstSizeCol = columns?.find((col) => {
        const colDef = col.getColDef();
        const rowNode = overridesGridRef.current?.api.getRowNode(String(rowIndex));
        const isCellEditable = col.isCellEditable(rowNode!);

        return !colDef.pinned && colDef.colId !== TOTAL_FIELD && !colDef.hide && isCellEditable;
      });

      setTimeout(() => {
        firstSizeCol && overridesGridRef.current?.api.startEditingCell({ rowIndex, colKey: firstSizeCol });
      }, 200);
    },
    [overridesGridRef],
  );

  useEffect(() => {
    if (!overridesColumnDefs || isOverridesUpdateInProgress) return;

    if (typeof focusRowIndexRef.current === 'number') {
      focusFirstSizeCell(focusRowIndexRef.current);
      focusRowIndexRef.current = null;
    }
  }, [focusFirstSizeCell, isOverridesUpdateInProgress, overridesColumnDefs]);

  useEffect(() => {
    if (hasStockMin) focusRowIndexRef.current = 0;
  }, [focusFirstSizeCell, hasStockMin]);

  useEffect(() => {
    if (hasStockMax) focusRowIndexRef.current = 1;
  }, [focusFirstSizeCell, hasStockMax]);
};
