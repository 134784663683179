import { Collapse, Tag } from 'antd';
import styled from 'styled-components';

import { Switch } from 'components/UI';

const { Panel } = Collapse;

export const StyledPanel = styled(Panel)`
  margin-bottom: 10px;
  padding-bottom: 0;
  font-size: 12px;

  .ant-collapse-content-box {
    padding-top: 5px !important;
    padding-bottom: 0 !important;
  }

  .ant-collapse-header {
    display: inline-flex !important;
    padding: 0 !important;
  }

  .ant-collapse-header-text {
    font-weight: bold;
    color: ${(p) => p.theme.white};
  }

  .ant-collapse-expand-icon {
    color: ${(p) => p.theme.white};
  }
`;

export const StyledTag = styled(Tag)`
  font-weight: normal;
  background: ${(p) => p.theme.primary_yellow};
  border: none;
  margin-left: 10px;
  font-size: 10px;
`;

export const StyledSwitch = styled(Switch)`
  .ant-switch-inner {
    font-size: 10px;
  }

  margin: -2px 0 0 10px;
`;
