import styled from 'styled-components';
import defaultTheme from 'theme';

import { Tabs } from 'components/UI';

export const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    position: absolute;
    top: 5px;
    left: 120px;
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #808080;
      font-size: 14px;
    }
  }

  .ant-tabs-tab {
    color: ${(p) => p.theme.white}
    font-size: 14px;
    padding: 12px 0 16px;
  }

  .ant-tabs-ink-bar {
    background-color: ${defaultTheme.primary_yellow};
  }
`;

export const AdaptiveGridResizer = styled.div`
  div.ag-root .ag-body-viewport {
    height: 65vh;
    overflow-y: scroll;
  }

  .ag-header-viewport {
    flex: unset;
  }
`;
export const StyledAdaptiveGridResizer = styled(AdaptiveGridResizer)`
  .ag-header-cell .ag-header-cell-text {
    line-height: 1;
  }

  .ag-header-cell-comp-wrapper {
    height: 100%;
  }

  .ag-cell-label-container {
    flex-direction: column-reverse;
    justify-content: space-between;
  }
`;

export const ExportButtonWrapper = styled.div`
  display: flex;

  & button {
    margin-left: auto;
    font-size: 12px;
  }
`;
