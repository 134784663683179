import styled from 'styled-components';

export const TitleContainer = styled.h1`
  text-align: center;
  color: ${(p) => p.theme.white};
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;

  .prefix-container {
    width: 20%;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    color: ${(p) => p.theme.white};
  }

  .ant-input {
    width: 100%;
  }
`;
