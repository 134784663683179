import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ColDef, ICellRendererParams, RowDragEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Checkbox } from 'components/UI';
import { CENTERED_HEADER_CLASS } from 'constants/gridConstants';
import { ArrangedColumn } from 'types/views';

const GridContainer = styled.div`
  width: 50%;
  height: 300px;
`;

type Props = {
  columns: ArrangedColumn[];
  saveArrangedColumns: (columns: ArrangedColumn[]) => void;
  selectedViewId: string | null;
};

const ViewsConfigColumns: React.FC<Props> = ({ columns, saveArrangedColumns, selectedViewId }) => {
  const { t } = useTranslation('translation');
  const gridRef = useRef<AgGridReact>(null);
  const [lastChangedRowIndex, setLastChangedRowIndex] = useState<number | null>(null);
  const onRowDragEnd = (event: RowDragEvent<ArrangedColumn>) => {
    const arrangedColumns: ArrangedColumn[] = [];
    event.api.forEachNode((rowNode) => {
      rowNode.data && arrangedColumns.push(rowNode.data);
    });
    saveArrangedColumns(arrangedColumns);
  };

  const scrollToLastChangedRowIndex = useCallback(() => {
    gridRef?.current && lastChangedRowIndex && gridRef.current.api.ensureIndexVisible(lastChangedRowIndex, 'middle');
  }, [lastChangedRowIndex, gridRef]);

  const handleColumnVisibilityChange = useCallback(
    (field: string, e: CheckboxChangeEvent) => {
      const arrangedCols = cloneDeep(columns);
      const currentColumn = arrangedCols.find((col) => col.field === field);

      if (currentColumn) {
        currentColumn.hidden = !e.target.checked;

        saveArrangedColumns(arrangedCols);
      }
    },
    [columns, saveArrangedColumns, gridRef],
  );

  useEffect(() => {
    columns && scrollToLastChangedRowIndex();
  }, [columns]);
  useEffect(() => {
    selectedViewId && setLastChangedRowIndex(null);
  }, [selectedViewId]);

  const colDefs: ColDef[] = [
    {
      field: 'headerName',
      headerName: t('storeFleet.columns'),
      suppressMovable: true,
      suppressMenu: true,
      resizable: false,
      minWidth: 300,
      headerClass: CENTERED_HEADER_CLASS,
      cellRenderer(params: ICellRendererParams<ArrangedColumn>) {
        return (
          <Checkbox
            onChange={(e) => handleColumnVisibilityChange(params.data?.field || '', e)}
            checked={!params.data?.hidden}
            disabled={!selectedViewId}
          >
            {params.value}
          </Checkbox>
        );
      },
    },
  ];

  return (
    <GridContainer>
      <AgGridReact
        ref={gridRef}
        rowData={columns}
        columnDefs={colDefs}
        rowDragManaged
        headerHeight={38}
        animateRows
        onGridReady={(e) => e.api.sizeColumnsToFit()}
        rowDragEntireRow
        rowDragMultiRow
        onRowClicked={(event) => setLastChangedRowIndex(event.rowIndex)}
        suppressExcelExport
        suppressCsvExport
        rowStyle={{ cursor: 'grab' }}
        rowSelection="single"
        onRowDragEnd={onRowDragEnd}
      />
    </GridContainer>
  );
};
export default ViewsConfigColumns;
