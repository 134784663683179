import { Select } from 'antd';
import styled from 'styled-components';

export const StyledSelect = styled(Select)`
  .ant-select-arrow {
    display: none;
  }

  .ant-select-selection-item {
    padding-right: 0 !important;
    text-align: center !important;
  }

  .ant-select-selector {
    background-color: ${(p) => p.theme.primary_yellow} !important;
    border: none !important;
    outline: none !important;
  }

  .ant-select-clear {
    background-color: transparent;
    top: 37%;
    right: 5px;
  }
`;
