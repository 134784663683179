import React, { useRef, useState, useEffect, useMemo } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { NakedButton } from 'components/UI';
import { COLUMN_TYPES } from 'constants/gridConstants';
import {
  FileImportType,
  ProductColorImportType,
  ProductColorSizeImportType,
  ProductColorSizeStoreImportType,
  ProductColorStoreImportType,
  StoreImportType,
  StoreSeasonImportType,
  StoreSeasonPermutationFieldsImportType,
  useStoreCustomFieldsQuery,
  useProductCustomFieldsQuery,
  ProductCustomFieldsDto,
  StoreCustomFieldsDto,
} from 'services/graphql/main';
import { useError } from 'services/utils';

type Props = {
  fileHeaders: { name: string; value: number }[];
  importType: string;
  matchedData: { ColumnIndex: number | null; Field: string }[];
  setMatchedData: (data: { ColumnIndex: number | null; Field: string }[]) => void;
};

const ImportTable: React.FC<Props> = ({ fileHeaders, importType, setMatchedData }) => {
  const { t } = useTranslation('translation');
  const { addCustomError, addError } = useError();
  const gridRef = useRef<AgGridReact>(null);

  const [rowData, setRowData] = useState<{ import_data?: number | null; name: string }[]>([]);
  const [mandatoryFields, setMandatoryFields] = useState<string[]>([]);
  const [importSchema, setImportSchema] = useState<
    | typeof ProductColorImportType
    | typeof ProductColorSizeImportType
    | typeof ProductColorSizeStoreImportType
    | typeof ProductColorStoreImportType
    | typeof StoreImportType
    | typeof StoreSeasonImportType
    | typeof StoreSeasonPermutationFieldsImportType
  >();
  const [customFieldNames, setCustomFieldNames] = useState<{ label: string; name?: string | null }[]>();

  const { data: dataProductCustomFields } = useProductCustomFieldsQuery({
    onError: (err) => addError(err, 'error'),
  });
  const { data: dataStoreCustomFields } = useStoreCustomFieldsQuery({
    onError: (err) => addError(err, 'error'),
  });

  const getImportTypeSchema = (importType: string) => {
    const importTypeKey = Object.keys(FileImportType).find(
      (key) => FileImportType[key as keyof typeof FileImportType] === importType,
    );
    switch (importTypeKey) {
      case 'ProductColor':
        setMandatoryFields(['ColorCode', 'ProductCode', 'SeasonCode']);

        return ProductColorImportType;
      case 'ProductColorSize':
        setMandatoryFields(['ColorCode', 'ProductCode', 'SizeSystemHeaderName', 'SeasonCode']);

        return ProductColorSizeImportType;
      case 'ProductColorSizeStore':
        setMandatoryFields([
          'ColorCode',
          'ProductCode',
          'SeasonCode',
          'UniverseCode',
          'StoreCode',
          'SizeSystemHeaderName',
        ]);

        return ProductColorSizeStoreImportType;
      case 'ProductColorStore':
        setMandatoryFields(['ColorCode', 'ProductCode', 'StoreCode', 'SeasonCode', 'UniverseCode']);

        return ProductColorStoreImportType;
      case 'Store':
        setMandatoryFields(['StoreCode', 'UniverseCode']);

        return StoreImportType;
      case 'StoreSeason':
        setMandatoryFields(['SeasonCode', 'StoreCode', 'UniverseCode']);

        return StoreSeasonImportType;
      case 'StoreSeasonPermutationFields':
        setMandatoryFields(['SeasonCode', 'StoreCode', 'PermutationFields', 'UniverseCode']);

        return StoreSeasonPermutationFieldsImportType;
    }
  };

  useEffect(() => {
    if (importType && dataStoreCustomFields && dataProductCustomFields) {
      if (importType === 'STORE') {
        const customFields = [];
        for (let i = 1; i <= 5; i++) {
          if (dataStoreCustomFields?.storeCustomFields[`customFieldName${i}` as keyof StoreCustomFieldsDto]) {
            customFields.push({
              label: `CustomFieldValue_${i}`,
              name: dataStoreCustomFields?.storeCustomFields[`customFieldName${i}` as keyof StoreCustomFieldsDto],
            });
          }
        }
        setCustomFieldNames(customFields);
      } else if (importType === 'PRODUCT_COLOR') {
        const customFields = [];
        for (let i = 1; i <= 5; i++) {
          if (dataProductCustomFields?.productCustomFields[`customFieldName${i}` as keyof ProductCustomFieldsDto]) {
            customFields.push({
              label: `CustomFieldValue_${i}`,
              name: dataProductCustomFields?.productCustomFields[`customFieldName${i}` as keyof ProductCustomFieldsDto],
            });
          }
        }
        setCustomFieldNames(customFields);
      } else {
        setCustomFieldNames(undefined);
      }
    }
  }, [importType, dataStoreCustomFields, dataProductCustomFields]);

  useEffect(() => {
    if (importType) {
      const importTypeSchema = getImportTypeSchema(importType);
      setImportSchema(importTypeSchema);
      const importTypeSchemaKeys = importTypeSchema && Object.keys(importTypeSchema);

      importTypeSchemaKeys &&
        setRowData(
          importTypeSchemaKeys
            .filter((key) => {
              if (key.includes('CustomFieldValue') && !customFieldNames?.find((obj) => obj.label === key)) {
                return;
              }

              return key;
            })
            .map((key) => {
              return {
                name: key,
                import_data: undefined,
              };
            }),
        );
    }
  }, [importType, customFieldNames, fileHeaders]);

  useEffect(() => {
    let newlyMatchedData: { ColumnIndex: number | null; Field: string }[] = [];
    if (rowData && importSchema) {
      newlyMatchedData = rowData
        .filter((line) => line.import_data !== undefined)
        .map((line) => ({
          ColumnIndex: line.import_data!,
          Field: importSchema[line.name as keyof typeof importSchema],
        }));
      const filledMandatoryFields = mandatoryFields.filter((field) =>
        newlyMatchedData.find((obj) => obj.Field === importSchema[field as keyof typeof importSchema]),
      );
      const restMandatoryFields = mandatoryFields
        .filter((field) => !filledMandatoryFields.includes(field))
        .map((field) => ({
          ColumnIndex: null,
          Field: field,
        }));
      setMatchedData([...newlyMatchedData, ...restMandatoryFields]);
    }
  }, [rowData, setMatchedData, mandatoryFields, importSchema]);

  const RemoveRenderer = (props: ICellRendererParams) => {
    const handlePrepareRemoveRow = (props: ICellRendererParams) => {
      setRowData(
        rowData.map((obj) => {
          if (obj.name === props.data.name) {
            return { import_data: undefined, name: props.data.name };
          }

          return obj;
        }),
      );
    };

    return (
      <NakedButton type="text" onClick={() => handlePrepareRemoveRow(props)}>
        <DeleteOutlined style={{ cursor: 'pointer', textAlign: 'center' }} />
      </NakedButton>
    );
  };

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100,
      editable: true,
    };
  }, []);

  const columnDefs: ColDef[] = [
    {
      field: 'name',
      headerName: t('importSettings.label'),
      editable: false,
      valueFormatter: (params) => {
        if (params.value.includes('CustomFieldValue') && customFieldNames) {
          return customFieldNames.find((field) => field.label === params.value)?.name || '';
        }

        return `${t(`importSettings.${params.value}`)}${mandatoryFields.includes(params.value) ? ' *' : ''}`;
      },
    },
    {
      field: 'import_data',
      headerName: t('importSettings.import_data'),
      editable: false,
      cellRenderer(params: ICellRendererParams) {
        return (
          <Select
            placeholder={t('importSettings.select_column')}
            disabled={!fileHeaders.length}
            popupClassName="styledDropdown"
            value={rowData.find((obj) => obj.name === params.data.name)?.import_data}
            onChange={(value: any) => {
              const columnAlreadySelected = rowData.find((line) => line.import_data === value);
              if (columnAlreadySelected) {
                addCustomError({ message: t('COLUMN_ALREADY_SELECTED') }, 'warning');
                setRowData(
                  rowData.map((obj) => {
                    if (obj.name === params.data.name) {
                      return { import_data: undefined, name: params.data.name };
                    }

                    return obj;
                  }),
                );

                return;
              }

              setRowData(
                rowData.map((obj) => {
                  if (obj.name === params.data.name) {
                    return { import_data: value, name: params.data.name };
                  }

                  return obj;
                }),
              );
            }}
            style={{ width: '100%' }}
          >
            {fileHeaders?.map((header) => (
              <Select.Option key={header.value} value={header.value}>
                {header.name}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      suppressMenu: true,
      cellStyle: { textAlign: 'center' },
      maxWidth: 60,
      editable: false,
      cellRenderer: RemoveRenderer,
      pinned: 'right',
    },
  ];

  return (
    <AgGridReact
      containerStyle={{ width: '100%', marginTop: 5, marginBottom: 10, height: 'auto' }}
      ref={gridRef}
      columnDefs={columnDefs}
      rowData={rowData}
      defaultColDef={defaultColDef}
      domLayout={'autoHeight'}
      suppressCellFocus={true}
      singleClickEdit={true}
      suppressRowVirtualisation={true}
      stopEditingWhenCellsLoseFocus={true}
      rowSelection={'single'}
      columnTypes={COLUMN_TYPES}
    />
  );
};

export default ImportTable;
