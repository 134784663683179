import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Form, Input, InputRef, List } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { TableViewsContext } from 'context';
import { cloneDeep, trim } from 'lodash';
import { useTranslation } from 'react-i18next';

import ViewsConfigColumns from 'components/AgGridComponents/Views/ViewsConfigurationModal/ViewsConfigColumns';
import { ConfirmModal, DraggableModal } from 'components/Modals';
import StyledModal from 'components/Modals/StyledModal/StyledModal';
import { Button } from 'components/UI';
import { ArrangedColumn, ViewId, ViewName, ViewsColDefProps } from 'types/views';

import { generateId } from './helpers';
import {
  ListHeaderContent,
  ListsContainer,
  ModalContent,
  StyledFormItem,
  StyledViewItem,
  StyledCheckbox,
} from './styles';

type ValidateStatus = Parameters<typeof Form.Item>[0]['validateStatus'];

interface ValidationResult {
  errorMsg: string | null;
  validateStatus: ValidateStatus;
}

type ViewNameInput = ValidationResult & { value: string };

type UpsertViewAction = 'create' | 'rename' | 'none';
type ViewsConfigurationModalProps = ViewsColDefProps & {
  close: () => void;
  isOpen: boolean;
};

const ViewsConfigurationModal: React.FC<ViewsConfigurationModalProps> = ({
  isOpen,
  close,
  flatColDefs,
  separatedColDefs,
  suppressColMovingForGrids,
}) => {
  const { t } = useTranslation('translation');

  const {
    selectedViewId,
    setSelectedViewId,
    setPendingTableRefresh,
    clearSelectedViewId,
    views,
    getView,
    getViewByName,
    upsertView,
    deleteView,
  } = useContext(TableViewsContext);

  const viewNameInputRef = useRef<InputRef>(null);

  const [arrangedColumns, setArrangedColumns] = useState<ArrangedColumn[]>([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(true);
  const [upsertViewModalState, setUpsertViewModalState] = useState<boolean | ViewId>(false);
  const [deleteViewModalState, setDeleteViewModalState] = useState<ViewId | null>(null);
  const [viewNameInput, setViewNameInput] = useState<ViewNameInput>({ value: '', errorMsg: null, validateStatus: '' });

  const isUpsertViewModalNameValid = viewNameInput.value && viewNameInput.validateStatus === 'success';

  const allColumns = useMemo(() => {
    if (!isOpen) return [];

    let allCols = [];

    if (flatColDefs?.length) {
      allCols = flatColDefs.map((colDef) => ({ field: colDef.field!, headerName: colDef.headerName, hidden: false }));
    } else if (separatedColDefs && Object.keys(separatedColDefs).length > 0) {
      for (const gridName in separatedColDefs) {
        allCols.push(
          ...separatedColDefs[gridName].map((colDef) => ({
            field: colDef.field!,
            headerName: colDef.headerName,
            hidden: false,
            parentGrid: gridName,
          })),
        );
      }
    }

    return allCols;
  }, [flatColDefs, isOpen, separatedColDefs]);

  const getVisibleColumnsCount = useCallback(() => {
    const visibleColumns = arrangedColumns.filter((col) => !col.hidden);

    return visibleColumns.length;
  }, [arrangedColumns]);

  useEffect(() => {
    if (!isOpen || !allColumns) return;

    const view = getView();
    let cols = allColumns;

    if (view) {
      cols = view.cols
        .filter((viewCol) => allColumns.findIndex((allCol) => allCol.field === viewCol.field) > -1)
        .map((col) => {
          const headerName = allColumns.find((c) => c.field === col.field)?.headerName;

          return { ...col, headerName };
        });

      if (allColumns.length > cols!.length) {
        for (const allCol of allColumns) {
          if (cols?.findIndex((viewCol) => viewCol.field === allCol.field) === -1) {
            cols.push({ field: allCol.field, headerName: allCol.headerName, hidden: true });
          }
        }
      }
    }

    setArrangedColumns(cols);
  }, [allColumns, getView, isOpen]);

  useEffect(() => {
    setIsSelectAllChecked(getVisibleColumnsCount() >= arrangedColumns.length);
  }, [arrangedColumns.length, getVisibleColumnsCount]);

  useEffect(() => {
    if (upsertViewModalState) {
      setTimeout(() => {
        viewNameInputRef.current?.focus();
      }, 1);
    }
  }, [upsertViewModalState]);

  const openCreateViewModal = useCallback(() => {
    setUpsertViewModalState(true);
  }, []);

  const openRenameViewModal = useCallback((viewId: ViewId) => {
    setUpsertViewModalState(viewId);
  }, []);

  const closeUpsertViewModal = useCallback(() => {
    setViewNameInput({ value: '', errorMsg: null, validateStatus: '' });
    setUpsertViewModalState(false);
  }, []);

  const handleUpsertViewModalSave = useCallback(() => {
    closeUpsertViewModal();

    const name = viewNameInput.value.trim();

    if (name) {
      let id: ViewId;
      const isEditMode = typeof upsertViewModalState === 'string';
      let cols = allColumns;

      if (isEditMode) {
        id = upsertViewModalState;
        cols = getView(id)!.cols;
      } else {
        id = generateId();
        while (getView(id)) {
          id = generateId();
        }
      }
      upsertView({ id, name, cols });

      !isEditMode && setSelectedViewId(id);
    }
  }, [
    allColumns,
    closeUpsertViewModal,
    getView,
    setSelectedViewId,
    upsertView,
    upsertViewModalState,
    viewNameInput.value,
  ]);

  const validateViewName = useCallback(
    (name: string): ValidationResult => {
      const trimmedName = trim(name);

      if (trimmedName === '') {
        return {
          validateStatus: 'error',
          errorMsg: t('storeFleet.validation_enter_name'),
        };
      }

      const sameNameView = getViewByName(trimmedName);

      if (sameNameView) {
        return {
          validateStatus: 'error',
          errorMsg: t(
            sameNameView.id === upsertViewModalState
              ? 'storeFleet.validation_edited_name_equals_previous'
              : 'storeFleet.validation_name_exists',
          ),
        };
      }

      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    },
    [getViewByName, upsertViewModalState, t],
  );

  const handleViewNameInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.value;

      setViewNameInput({
        ...validateViewName(name),
        value: name,
      });
    },
    [validateViewName],
  );

  const handleNameInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && isUpsertViewModalNameValid) {
        handleUpsertViewModalSave();
      }
    },
    [handleUpsertViewModalSave, isUpsertViewModalNameValid],
  );

  const showDeleteViewConfirmModal = useCallback((viewId: ViewId) => {
    setDeleteViewModalState(viewId);
  }, []);

  const closeDeleteViewConfirmModal = useCallback(() => {
    setDeleteViewModalState(null);
  }, []);

  const handleDeleteViewConfirmed = useCallback(() => {
    closeDeleteViewConfirmModal();
    deleteViewModalState && deleteView(deleteViewModalState);
  }, [closeDeleteViewConfirmModal, deleteView, deleteViewModalState]);

  const handleViewRowClick = (viewId: ViewId) => {
    if (viewId === selectedViewId) {
      clearSelectedViewId();
    } else {
      setSelectedViewId(viewId);
    }
  };

  const saveArrangedColumns = useCallback(
    (columns: ArrangedColumn[]) => {
      setArrangedColumns(columns);

      const view = getView();
      view && upsertView({ ...view, cols: [...columns] });

      // !!setViewColsInitialized && setViewColsInitialized(null);
      setPendingTableRefresh(true);
    },
    [getView, setPendingTableRefresh, upsertView],
  );

  const handleSelectAllChange = useCallback(
    (e: CheckboxChangeEvent) => {
      setIsSelectAllChecked(e.target.checked);

      const arrangedCols = cloneDeep(arrangedColumns);

      arrangedCols.forEach((col) => {
        col.hidden = !e.target.checked;
      });

      saveArrangedColumns(arrangedCols);
    },
    [arrangedColumns, saveArrangedColumns],
  );

  let upsertViewAction: UpsertViewAction = 'none';
  let editedViewName: ViewName | undefined;

  if (upsertViewModalState) {
    if (upsertViewModalState === true) {
      upsertViewAction = 'create';
    } else if (typeof upsertViewModalState === 'string') {
      upsertViewAction = 'rename';
      editedViewName = getView(upsertViewModalState)?.name;
    }
  }

  const isDeleteViewModalOpen = typeof deleteViewModalState === 'string';
  const deleteViewName = getView(deleteViewModalState)?.name;

  return (
    <>
      <DraggableModal
        titleKey="storeFleet.views_configurator"
        open={isOpen}
        onCancel={close}
        footer={<Button onClick={close} textKey="common.close" />}
        width={860}
        destroyOnClose
      >
        <ModalContent>
          <StyledCheckbox
            disabled={!selectedViewId}
            textKey="common.selectAll"
            className="selectAllCheckbox"
            onChange={handleSelectAllChange}
            checked={isSelectAllChecked}
          />

          <ListsContainer>
            <List
              size="small"
              header={
                <ListHeaderContent>
                  <span>{t('storeFleet.views')}</span>

                  <div className="itemActions visibleOnHover">
                    <PlusCircleOutlined onClick={openCreateViewModal} />
                  </div>
                </ListHeaderContent>
              }
              bordered
              dataSource={views}
              renderItem={(view) => (
                <StyledViewItem
                  onClick={() => {
                    handleViewRowClick(view.id);
                  }}
                  className={view.id === selectedViewId ? 'selected' : undefined}
                >
                  <span>{view.name}</span>

                  <div className="itemActions visibleOnHover">
                    <EditOutlined
                      onClick={(e) => {
                        e.stopPropagation();
                        openRenameViewModal(view.id);
                      }}
                    />
                    <DeleteOutlined
                      onClick={(e) => {
                        e.stopPropagation();
                        showDeleteViewConfirmModal(view.id);
                      }}
                    />
                  </div>
                </StyledViewItem>
              )}
              className="list"
            />
            <ViewsConfigColumns
              selectedViewId={selectedViewId}
              columns={arrangedColumns}
              saveArrangedColumns={saveArrangedColumns}
            />
          </ListsContainer>
        </ModalContent>
      </DraggableModal>

      <ConfirmModal
        customTitleKey="storeFleet.delete_view_modal_title"
        text={t('storeFleet.delete_view_confirmation_text', { viewName: deleteViewName })}
        open={isDeleteViewModalOpen}
        onOk={handleDeleteViewConfirmed}
        onCancel={() => {
          setDeleteViewModalState(null);
        }}
      />

      <StyledModal
        title={t(upsertViewAction === 'rename' ? 'storeFleet.edit_view' : 'storeFleet.create_view')}
        open={upsertViewAction !== 'none'}
        onOk={handleUpsertViewModalSave}
        okButtonProps={{ disabled: !isUpsertViewModalNameValid }}
        onCancel={closeUpsertViewModal}
        okText={t('common.save')}
        width={400}
        destroyOnClose
      >
        <Form autoComplete="off">
          <StyledFormItem
            label={t('common.name')}
            name="name"
            validateStatus={viewNameInput.validateStatus}
            help={viewNameInput.errorMsg}
            initialValue={editedViewName}
          >
            <Input
              value={viewNameInput.value}
              onChange={handleViewNameInputChange}
              onKeyDown={handleNameInputKeyDown}
              ref={viewNameInputRef}
            />
          </StyledFormItem>
        </Form>
      </StyledModal>
    </>
  );
};

export default ViewsConfigurationModal;
