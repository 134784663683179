import React, { useContext, useState, useEffect } from 'react';

import { PermissionsContext } from 'context';
import { Navigate } from 'react-router-dom';

import { PermissionsEnum } from 'services/graphql/main';

type Props = {
  children: JSX.Element;
  page: string;
};

const PermissionsProtectedRoute: React.FC<Props> = ({ page, children }) => {
  const { permissions } = useContext(PermissionsContext);

  const [notAuthorized, setNotAuthorized] = useState(false);

  useEffect(() => {
    if (permissions) {
      switch (page) {
        case 'settings':
          !(permissions as PermissionsEnum[])?.includes(PermissionsEnum.SettingsView) && setNotAuthorized(true);
          break;
        case 'generalSettings':
          !(permissions as PermissionsEnum[])?.includes(PermissionsEnum.SettingsGeneralSettingsView) &&
            setNotAuthorized(true);
          break;
        case 'userManagement':
          !(permissions as PermissionsEnum[])?.includes(PermissionsEnum.SettingsUserManagementView) &&
            setNotAuthorized(true);
          break;
      }
    }
  }, [page, permissions]);

  if (notAuthorized) {
    return <Navigate replace to="/" />;
  }

  return children;
};

export default PermissionsProtectedRoute;
