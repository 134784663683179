import React, { useState, useMemo, useContext, useEffect, useRef, useCallback } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { ColDef, ICellRendererParams, CellEditingStartedEvent, CellEditingStoppedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { AlphanumericCellEditor } from 'components/AgGridComponents';
import { ConfirmModal } from 'components/Modals';
import { PrimaryCheckbox, NakedButton, PrimaryButton } from 'components/UI';
import { ActionButtonsWrapper } from 'components/wrappers';
import { VALIDATIONS_TYPES } from 'constants/validations';
import { CollectionContext } from 'context/collection-context';
import { UniverseContext } from 'context/universe-context';
import { forbidEmptyCellValueSetter } from 'helpers/gridCells';
import { sortEmpty } from 'helpers/gridCols';
import { partiallyEditedGridData } from 'helpers/gridData';
import { validateGridCustom } from 'helpers/validations';
import {
  useGetCollectionsQuery,
  useBulkUpdateCollectionsMutation,
  useCreateCollectionMutation,
  useDeleteCollectionMutation,
  useUpdateCollectionMutation,
} from 'services/graphql/main';
import { useError } from 'services/utils';

import {
  CollectionProps,
  MutationStateProps,
  AddRowConstructorProps,
  CollectionMutableProps,
  GridDataProps,
} from '../../common/types';
import { AdaptiveGridResizer } from './styles';

const CollectionsTable: React.FC = () => {
  const { t } = useTranslation('translation');
  const { addCustomError, addError } = useError();
  const { selectedUniverse } = useContext(UniverseContext);
  const { setSelectedCollection, isAssociatedGroupsTableUpdateActive, setIsCollectionsTableUpdateActive } =
    useContext(CollectionContext);

  const initialGridData = useRef<GridDataProps>(null);
  const gridRef = useRef<AgGridReact<CollectionProps | AddRowConstructorProps>>(null);

  const { data, refetch } = useGetCollectionsQuery({
    variables: { universeId: selectedUniverse?.id || 0 },
    onError: (err) => addError(err, 'error'),
  });

  const [createCollection, { error: createError }] = useCreateCollectionMutation({
    onError: (err) => addError(err, 'warning'),
  });
  const [updateBulkCollections, { error: bulkUpdateError }] = useBulkUpdateCollectionsMutation({
    onError: (err) => addError(err, 'warning'),
  });
  const [updateCollection, { error: updateError }] = useUpdateCollectionMutation({
    onError: (err) => addError(err, 'warning'),
  });
  const [deleteCollection] = useDeleteCollectionMutation({ onError: (err) => addError(err, 'warning') });

  const [rowData, setRowData] = useState<
    (CollectionProps | null)[] | (CollectionMutableProps | null) | null | undefined
  >();

  const [currentEditedRow, setCurrentEditedRow] = useState<number | null>(null);
  const [selectedRowForDeletion, setSelectedRowForDeletion] = useState({ id: 0, sortNumber: 0 });
  const [isDeleteRowModalOpen, setIsDeleteRowModalOpen] = useState(false);

  const [mutationState, setMutationState] = useState<MutationStateProps | null>(null);

  const [isUpdate, setIsUpdate] = useState({ isCreateRowActive: false, isCellActive: false });
  const hasUpdates = !isEmpty(mutationState?.data);

  const [errors, setErrors] = useState<any>(null);

  const showModal = () => {
    setIsDeleteRowModalOpen(true);
  };

  const hideModal = useCallback(() => {
    setIsDeleteRowModalOpen(false);
  }, []);

  const RemoveRenderer = useCallback(
    (props: ICellRendererParams) => {
      const handlePrepareRemoveRow = (props: ICellRendererParams) => {
        setSelectedRowForDeletion(props.node.data);
        showModal();
      };

      return (
        <NakedButton
          disabled={isUpdate.isCreateRowActive || hasUpdates}
          type="text"
          onClick={() => handlePrepareRemoveRow(props)}
        >
          <DeleteOutlined style={{ cursor: 'pointer', textAlign: 'center' }} />
        </NakedButton>
      );
    },
    [hasUpdates, isUpdate.isCreateRowActive],
  );

  const CheckboxRenderer = useCallback(
    (props: ICellRendererParams) => {
      const handleCheckboxChange = async () => {
        await updateBulkCollections({
          variables: {
            inputCollections: [{ id: props.data.id, isActive: !props.data.isActive }],
          },
        });

        await refetch();
      };

      return (
        <PrimaryCheckbox
          isChecked={props.data.isActive}
          disabled={isUpdate.isCreateRowActive || hasUpdates || isAssociatedGroupsTableUpdateActive}
          onChange={handleCheckboxChange}
        />
      );
    },
    [hasUpdates, isAssociatedGroupsTableUpdateActive, isUpdate.isCreateRowActive, refetch, updateBulkCollections],
  );

  const handleSaveMutation = useCallback(async () => {
    const gridData = gridRef
      .current!.api.getRenderedNodes()
      .map((x) => x.data)
      .map((y) => {
        return {
          code: y?.code,
          name: y?.name,
        };
      });

    const { DUPLICATES, EMPTY_CELL, LENGTH } = VALIDATIONS_TYPES;

    const validations = [DUPLICATES, EMPTY_CELL, LENGTH];
    const validation = validateGridCustom(validations, gridData);

    if (validation?.isValid === false) {
      addCustomError({ message: validation?.code }, 'warning');
    } else if (mutationState) {
      switch (mutationState.type) {
        case 'create-row':
          await createCollection({
            variables: { collection: mutationState.data },
          });

          await refetch();

          setMutationState(null);

          break;
        case 'update-row':
          if (mutationState?.data?.length > 1)
            await updateBulkCollections({
              variables: {
                inputCollections: mutationState.data,
              },
            });
          else await updateCollection({ variables: { inputCollection: mutationState.data[0] } });
          await refetch();

          setMutationState(null);

          break;
        default:
          break;
      }

      setIsUpdate((prevState) => ({
        ...prevState,
        isCreateRowActive: false,
      }));

      setIsCollectionsTableUpdateActive(false);
      setCurrentEditedRow(null);
    }
  }, [
    addCustomError,
    createCollection,
    mutationState,
    refetch,
    setIsCollectionsTableUpdateActive,
    updateBulkCollections,
  ]);

  const onSelectionChanged = useCallback(() => {
    if (!isAssociatedGroupsTableUpdateActive) {
      const selectedRows = gridRef?.current!.api.getSelectedRows();

      if (selectedRows[0].id) {
        setSelectedCollection({ id: selectedRows[0].id, code: selectedRows[0].code });
      }
    }
  }, [isAssociatedGroupsTableUpdateActive, setSelectedCollection]);

  const handleAddRow = useCallback(() => {
    const constructorObject: AddRowConstructorProps = {
      code: '',
      name: '',
      isActive: false,
      universeId: selectedUniverse.id,
    };

    setIsUpdate((prevState) => ({
      ...prevState,
      isCreateRowActive: true,
    }));

    setIsCollectionsTableUpdateActive(true);

    setMutationState({
      type: 'create-row',
      data: {
        code: '',
        name: '',
        isActive: false,
        universeId: selectedUniverse.id,
      },
    });

    gridRef.current!.api.applyTransaction({
      add: [constructorObject],
    });

    const gridData = gridRef.current!.api.getRenderedNodes();

    setTimeout(() => {
      gridRef.current!.api.startEditingCell({
        rowIndex: gridData.length - 1,
        colKey: 'code',
      });
    }, 1);
  }, [selectedUniverse.id, setIsCollectionsTableUpdateActive]);

  const handleRemoveRow = useCallback(async () => {
    if (selectedRowForDeletion.id != 0) {
      await deleteCollection({ variables: { id: selectedRowForDeletion.id } });
      await refetch();

      setSelectedCollection(null);
    }

    setSelectedRowForDeletion({ id: 0, sortNumber: 0 });
    hideModal();
    setIsUpdate((prevState) => ({
      ...prevState,
      isCreateRowActive: false,
    }));
    setIsCollectionsTableUpdateActive(false);
  }, [
    deleteCollection,
    hideModal,
    refetch,
    selectedRowForDeletion.id,
    setIsCollectionsTableUpdateActive,
    setSelectedCollection,
  ]);

  const onCellEditingStarted = useCallback(
    (event: CellEditingStartedEvent) => {
      if (currentEditedRow === null) {
        setCurrentEditedRow(event.rowIndex);
      }

      setIsUpdate((prevState) => ({
        ...prevState,
        isCellActive: true,
      }));
    },
    [currentEditedRow],
  );

  const onCellEditingStopped = useCallback(
    (event: CellEditingStoppedEvent) => {
      if (mutationState?.type === 'create-row') {
        setMutationState({
          type: 'create-row',
          data: {
            code: event.data.code,
            name: event.data.name,
            universeId: selectedUniverse.id,
            isActive: event.data.isActive,
          },
        });
      } else if (mutationState === null || mutationState?.type === 'update-row') {
        const editedRows = partiallyEditedGridData({
          event,
          prevRows: mutationState?.data,
          initialRows: initialGridData.current,
        });
        if (editedRows) {
          setMutationState({
            type: 'update-row',
            data: editedRows,
          });
        }
      }

      setIsUpdate((prevState) => ({
        ...prevState,
        isCellActive: false,
      }));

      setIsCollectionsTableUpdateActive(true);
    },
    [mutationState, selectedUniverse.id, setIsCollectionsTableUpdateActive],
  );

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        field: 'code',
        ...(!mutationState === null && { sort: 'asc' }),
        comparator: sortEmpty,
        headerName: t('operationalSettings.code'),
        valueSetter: forbidEmptyCellValueSetter,
        cellEditor: AlphanumericCellEditor,
      },
      {
        field: 'name',
        comparator: sortEmpty,
        headerName: t('operationalSettings.long_label'),
        valueSetter: forbidEmptyCellValueSetter,
      },
      {
        headerName: t('operationalSettings.active'),
        field: 'isActive',
        sortable: false,
        cellRenderer: CheckboxRenderer,
        suppressMenu: true,
        cellStyle: { textAlign: 'center' },
        maxWidth: 60,
        editable: false,
        pinned: 'right',
      },
      {
        sortable: false,
        suppressMenu: true,
        cellStyle: { textAlign: 'center' },
        maxWidth: 60,
        editable: false,
        cellRenderer: RemoveRenderer,
        pinned: 'right',
      },
    ],
    [CheckboxRenderer, RemoveRenderer, mutationState, t],
  );

  const columnTypes = useMemo<{
    [key: string]: ColDef;
  }>(() => {
    return {
      valueColumn: {
        editable: true,
        valueParser: 'newValue',
      },
    };
  }, []);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100,
      editable: true,
      sortable: true,
      suppressMovable: true,
    };
  }, []);

  const handleCancelButton = useCallback(() => {
    if (mutationState?.type === 'create-row') {
      const lastRowIndex = gridRef.current!.api.getLastDisplayedRow();
      const lastRow = gridRef.current!.api.getDisplayedRowAtIndex(lastRowIndex);

      gridRef.current?.api.applyTransaction({ remove: [lastRow?.data!] });

      const mutableData =
        data &&
        data?.collections?.map((x) => ({
          code: x?.code,
          combinationType: x?.combinationType,
          id: x?.id,
          isActive: x?.isActive,
          name: x?.name,
        }));

      mutableData && gridRef.current?.api.setRowData(mutableData as any);

      setRowData(mutableData);
    } else {
      const mutableData =
        data &&
        data?.collections?.map((x) => ({
          code: x?.code,
          combinationType: x?.combinationType,
          id: x?.id,
          isActive: x?.isActive,
          name: x?.name,
        }));

      mutableData && gridRef.current?.api.setRowData(mutableData as any);

      setRowData(mutableData);
    }

    setIsUpdate((prevState) => ({
      ...prevState,
      isCreateRowActive: false,
      isUpdateRowActive: false,
      isCellActive: false,
    }));

    setIsCollectionsTableUpdateActive(false);

    setMutationState(null);
  }, [data, mutationState?.type, setIsCollectionsTableUpdateActive]);

  useEffect(() => {
    if (data?.collections?.length === 0 || data?.collections) {
      const mutableData: CollectionMutableProps =
        data &&
        data?.collections?.map((x) => ({
          code: x?.code,
          combinationType: x?.combinationType,
          id: x?.id,
          isActive: x?.isActive,
          name: x?.name,
          __typename: x?.__typename,
        }));

      const gridData =
        data &&
        data?.collections?.map((y) => {
          return {
            id: y?.id,
            code: y?.code,
            name: y?.name,
            isActive: y?.isActive,
          };
        });

      initialGridData.current = gridData;

      data && setRowData(mutableData);
    } else {
      setRowData([]);
    }
  }, [data]);

  useEffect(() => {
    createError?.graphQLErrors || bulkUpdateError?.graphQLErrors || updateError?.graphQLErrors
      ? setErrors(true)
      : setErrors(null);
  }, [bulkUpdateError?.graphQLErrors, createError?.graphQLErrors, updateError?.graphQLErrors]);

  return (
    <AdaptiveGridResizer>
      <AgGridReact
        containerStyle={{ width: '100%', marginTop: 15, marginBottom: 5, height: 'auto' }}
        ref={gridRef}
        columnTypes={columnTypes}
        columnDefs={columnDefs}
        rowData={rowData}
        defaultColDef={defaultColDef}
        domLayout={'autoHeight'}
        suppressRowVirtualisation={true}
        suppressRowClickSelection={isAssociatedGroupsTableUpdateActive}
        stopEditingWhenCellsLoseFocus={true}
        onCellEditingStarted={onCellEditingStarted}
        onCellEditingStopped={onCellEditingStopped}
        onSelectionChanged={onSelectionChanged}
        rowSelection={'single'}
        enableRangeSelection={true}
      />

      <ActionButtonsWrapper>
        <NakedButton
          type="text"
          text={t('operationalSettings.add_collection')}
          disabled={isUpdate.isCreateRowActive || hasUpdates || isAssociatedGroupsTableUpdateActive || errors}
          onClick={handleAddRow}
          style={{ color: '#FFFFFF', marginRight: 'auto' }}
        />
        {isUpdate.isCreateRowActive || hasUpdates ? (
          <>
            <NakedButton
              type="text"
              onClick={handleCancelButton}
              textKey="common.cancel"
              style={{ color: '#FFFFFF' }}
            />

            <PrimaryButton
              onClick={handleSaveMutation}
              textKey="common.save"
              disabled={isUpdate.isCellActive}
            ></PrimaryButton>
          </>
        ) : null}
      </ActionButtonsWrapper>

      <ConfirmModal
        customTitle={t('operationalSettings.delete_row_warning_message')}
        open={isDeleteRowModalOpen}
        onOk={handleRemoveRow}
        onCancel={hideModal}
      />
    </AdaptiveGridResizer>
  );
};

export default CollectionsTable;
