import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { ColDef, ICellRendererParams, CellEditingStartedEvent, CellEditingStoppedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { ConfirmModal } from 'components/Modals';
import { Spinner, PrimaryButton, NakedButton } from 'components/UI';
import { ActionButtonsWrapper, SubActionButtonsWrapper } from 'components/wrappers';
import { COLUMN_TYPES } from 'constants/gridConstants';
import { VALIDATIONS_TYPES } from 'constants/validations';
import { forbidEmptyCellValueSetter } from 'helpers/gridCells';
import { sortEmpty } from 'helpers/gridCols';
import { partiallyEditedGridData } from 'helpers/gridData';
import { validateGridCustom } from 'helpers/validations';
import {
  useGetUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  UserRoleType,
  User,
  UserCreateInput,
} from 'services/graphql/main';
import { useError } from 'services/utils';

import { GridWrapper, StyledSelect } from './styles';
import { MutationStateProps, GridDataProps } from './types';

const UsersManagement: React.FC = () => {
  const { t } = useTranslation('translation');
  const { addCustomError, addError } = useError();

  const initialGridData = useRef<GridDataProps>(null);
  const gridRef = useRef<AgGridReact<any>>(null);

  const { loading, data, refetch } = useGetUsersQuery({
    onError: (err) => addError(err, 'error'),
  });

  const [createUser, { error: createError }] = useCreateUserMutation({
    onError: (err) => addError(err, 'warning'),
  });

  const [updateUser, { error: updateError }] = useUpdateUserMutation({
    onError: (err) => addError(err, 'warning'),
  });

  const [deleteUser] = useDeleteUserMutation({
    onError: (err) => addError(err, 'warning'),
  });

  const [rowData, setRowData] = useState<(User | null)[] | GridDataProps | null | undefined>();

  const [currentEditedRow, setCurrentEditedRow] = useState<number | null>(null);

  const [selectedRowForDeletion, setSelectedRowForDeletion] = useState({ id: 0 });
  const [isDeleteRowModalOpen, setIsDeleteRowModalOpen] = useState(false);

  const [mutationState, setMutationState] = useState<MutationStateProps | null>(null);

  const [isUpdate, setIsUpdate] = useState({ isCreateRowActive: false, isCellActive: false });
  const hasUpdate = !isEmpty(mutationState?.data);

  const [errors, setErrors] = useState<any>(null);

  const showModal = () => {
    setIsDeleteRowModalOpen(true);
  };

  const hideModal = () => {
    setIsDeleteRowModalOpen(false);
  };

  const handleSaveMutation = async () => {
    const gridData = gridRef
      .current!.api.getRenderedNodes()
      .map((x) => x.data)
      .map((y) => {
        return {
          email: y?.email,
          username: y?.username,
          userRoleType: y?.userRoleType,
          jobDescription: y?.jobDescription,
        };
      });

    const { EMPTY_CELL } = VALIDATIONS_TYPES;

    const validations = [EMPTY_CELL];
    const validation = validateGridCustom(validations, gridData);

    if (validation?.isValid === false) {
      addCustomError({ message: validation?.code }, 'warning');
    } else if (validation?.isValid) {
      if (mutationState) {
        switch (mutationState.type) {
          case 'create-row':
            await createUser({
              variables: { userInput: mutationState.data },
            });
            await refetch();
            setMutationState(null);
            break;
          case 'update-row':
            await updateUser({
              variables: {
                userInput: mutationState.data,
              },
            });
            await refetch();
            setMutationState(null);
            break;
          default:
            break;
        }
        setIsUpdate((prevState) => ({
          ...prevState,
          isCreateRowActive: false,
        }));
        setCurrentEditedRow(null);
      }
    }
  };

  const handleAddRow = () => {
    const constructorObject: UserCreateInput = {
      username: '',
      jobDescription: '',
      email: '',
      userRoleType: UserRoleType.Admin,
    };

    setIsUpdate((prevState) => ({
      ...prevState,
      isCreateRowActive: true,
    }));

    setMutationState({
      type: 'create-row',
      data: {
        username: '',
        jobDescription: '',
        email: '',
        userRoleType: '',
      },
    });

    gridRef.current!.api.applyTransaction({
      add: [constructorObject as UserCreateInput],
    });

    const gridData = gridRef.current!.api.getRenderedNodes();

    setTimeout(() => {
      gridRef.current!.api.startEditingCell({
        rowIndex: gridData.length - 1,
        colKey: 'username',
      });
    }, 1);
  };

  const onCellEditingStarted = useCallback(
    (event: CellEditingStartedEvent) => {
      if (currentEditedRow === null) {
        setCurrentEditedRow(event.rowIndex);
      }

      setIsUpdate((prevState) => ({
        ...prevState,
        isCellActive: true,
      }));
    },
    [currentEditedRow],
  );

  const onCellEditingStopped = useCallback(
    (event: CellEditingStoppedEvent) => {
      if (mutationState?.type === 'create-row') {
        setMutationState({
          type: 'create-row',
          data: {
            username: event.data.username,
            jobDescription: event.data.jobDescription,
            email: event.data.email,
            userRoleType: event.data.userRoleType,
          },
        });
      } else if (mutationState === null || mutationState?.type === 'update-row') {
        const editedRows = partiallyEditedGridData({
          event,
          prevRows: mutationState?.data,
          initialRows: initialGridData.current,
        });

        if (editedRows?.length) {
          setMutationState({
            type: 'update-row',
            data: editedRows[0],
          });
        }
      }

      setIsUpdate((prevState) => ({
        ...prevState,
        isCellActive: false,
      }));
    },
    [mutationState],
  );

  const handleRemoveRow = async () => {
    if (selectedRowForDeletion.id != 0) {
      await deleteUser({ variables: { userId: selectedRowForDeletion.id } });
      await refetch();
    }

    setSelectedRowForDeletion({ id: 0 });
    hideModal();
    setIsUpdate((prevState) => ({
      ...prevState,
      isCreateRowActive: false,
    }));
  };

  const RemoveRenderer = (props: ICellRendererParams) => {
    const handlePrepareRemoveRow = (props: ICellRendererParams) => {
      setSelectedRowForDeletion(props.node.data);
      showModal();
    };

    return (
      <NakedButton
        disabled={isUpdate.isCreateRowActive || hasUpdate}
        type="text"
        onClick={() => handlePrepareRemoveRow(props)}
      >
        <DeleteOutlined style={{ cursor: 'pointer', textAlign: 'center' }} />
      </NakedButton>
    );
  };

  const columnDefs: ColDef[] = [
    {
      field: 'username',
      headerName: t('userManagement.username'),
      valueSetter: forbidEmptyCellValueSetter,
    },
    {
      field: 'email',
      comparator: sortEmpty,
      headerName: t('userManagement.email'),
      valueSetter: forbidEmptyCellValueSetter,
      editable: isUpdate.isCreateRowActive,
    },
    {
      field: 'jobDescription',
      headerName: t('userManagement.job_description'),
      valueSetter: forbidEmptyCellValueSetter,
    },
    {
      field: 'userRoleType',
      headerName: t('userManagement.role'),
      valueSetter: forbidEmptyCellValueSetter,
      editable: false,
      cellRenderer(params: ICellRendererParams) {
        return (
          <StyledSelect
            defaultValue={mutationState?.data.id === params.data.id ? mutationState?.data.userRoleType : params.value}
            bordered={false}
            showArrow={true}
            showSearch={false}
            clearIcon={null}
            dropdownMatchSelectWidth={false}
            onChange={(value) => {
              setMutationState((prevState) =>
                prevState
                  ? { ...prevState, data: { ...prevState?.data, userRoleType: value } }
                  : {
                      type: 'update-row',
                      data: { userRoleType: value, id: params.data.id },
                    },
              );
            }}
          >
            {Object.keys(UserRoleType).map((role) => (
              <StyledSelect.Option value={UserRoleType[role as keyof typeof UserRoleType]} key={role}>
                {t(`userManagement.${role}`)}
              </StyledSelect.Option>
            ))}
          </StyledSelect>
        );
      },
    },
    {
      suppressMenu: true,
      sortable: false,
      cellStyle: { textAlign: 'center' },
      maxWidth: 65,
      editable: false,
      cellRenderer: RemoveRenderer,
      pinned: 'right',
    },
  ];

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100,
      editable: true,
      suppressMovable: true,
      sortable: true,
    };
  }, []);

  const handleCancelButton = () => {
    if (mutationState?.type === 'create-row') {
      const lastRowIndex = gridRef.current!.api.getLastDisplayedRow();
      const lastRow = gridRef.current!.api.getDisplayedRowAtIndex(lastRowIndex);

      gridRef.current?.api.applyTransaction({ remove: [lastRow?.data!] });

      const mutableData =
        data &&
        data!.users?.map((x) => ({
          id: x?.id,
          email: x?.email,
          username: x?.username,
          userRoleType: x?.userRoleId,
          jobDescription: x?.jobDescription,
        }));

      mutableData && gridRef.current?.api.setRowData(mutableData);

      setRowData(mutableData);
    } else {
      const mutableData =
        data &&
        data!.users?.map((x) => ({
          id: x?.id,
          email: x?.email,
          username: x?.username,
          userRoleType: x?.userRoleId,
          jobDescription: x?.jobDescription,
        }));

      mutableData && gridRef.current?.api.setRowData(mutableData);

      setRowData(mutableData);
    }

    setIsUpdate((prevState) => ({
      ...prevState,
      isCreateRowActive: false,
      isUpdateRowActive: false,
      isCellActive: false,
    }));

    setMutationState(null);
    setErrors(null);
  };

  useEffect(() => {
    const mutableData: GridDataProps =
      data &&
      data!.users?.map((x) => ({
        id: x?.id,
        email: x?.email,
        username: x?.username,
        userRoleType: x?.userRoleId,
        jobDescription: x?.jobDescription,
      }));

    const gridData =
      data &&
      data!.users?.map((x) => ({
        id: x?.id,
        email: x?.email,
        username: x?.username,
        userRoleType: x?.userRoleId,
        jobDescription: x?.jobDescription,
      }));

    initialGridData.current = gridData;
    data && setRowData(mutableData);
  }, [data]);

  useEffect(() => {
    createError?.graphQLErrors || updateError?.graphQLErrors ? setErrors(true) : setErrors(null);
  }, [createError?.graphQLErrors, updateError?.graphQLErrors]);

  return loading ? (
    <Spinner size="large" />
  ) : (
    <GridWrapper>
      <AgGridReact
        containerStyle={{ width: '100%', marginTop: 15, marginBottom: 30 }}
        ref={gridRef}
        columnDefs={columnDefs}
        rowData={rowData}
        defaultColDef={defaultColDef}
        domLayout={'autoHeight'}
        suppressRowVirtualisation={true}
        stopEditingWhenCellsLoseFocus={true}
        onCellEditingStarted={onCellEditingStarted}
        onCellEditingStopped={onCellEditingStopped}
        columnTypes={COLUMN_TYPES}
        enableRangeSelection={true}
      />

      <ActionButtonsWrapper style={{ justifyContent: 'space-between' }}>
        <NakedButton
          disabled={isUpdate.isCreateRowActive || errors}
          type="link"
          onClick={handleAddRow}
          text={`${t('userManagement.add_new_user')}`}
          style={{ color: '#ffffff' }}
        ></NakedButton>

        {isUpdate.isCreateRowActive || hasUpdate || errors ? (
          <SubActionButtonsWrapper>
            <NakedButton
              type="text"
              onClick={handleCancelButton}
              textKey="common.cancel"
              style={{ color: '#ffffff' }}
            />

            {(isUpdate.isCreateRowActive || hasUpdate) && (
              <PrimaryButton onClick={handleSaveMutation} textKey="common.save" disabled={isUpdate.isCellActive} />
            )}
          </SubActionButtonsWrapper>
        ) : null}
      </ActionButtonsWrapper>

      <ConfirmModal
        customTitle={t('userManagement.delete_user_warning_message')}
        onOk={handleRemoveRow}
        open={isDeleteRowModalOpen}
        onCancel={hideModal}
      />
    </GridWrapper>
  );
};

export default UsersManagement;
