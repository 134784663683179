import moment from 'moment';
import { TFunction } from 'react-i18next';

import { GetStockSalesTableQuery } from 'services/graphql/main';
import { StatisticsSupplierOptimalStockDto } from 'services/graphql/main';

import { CUSTOM_FIELD_PREFIX } from '../constants/common';
import {
  ROW_HEADER_ID_FIELD,
  TOTAL_CUSTOM_FIELD,
  TYPE_CUSTOM_FIELD,
  ROW_PARENT_CUSTOM_FIELD,
  IS_GROUPING_ROW_CUSTOM_FIELD,
  DISTRIBUTION_ROW_ID,
  TOTAL_SALES_ROW_ID,
  PHYSICAL_SALES_ROW_ID,
  WEB_SALES_ROW_ID,
  WEEKS_TOTAL_ROW_ID,
  WEEKS_FIRST_ROW_ID,
  WEEKS_SECOND_ROW_ID,
  WEEKS_THIRD_ROW_ID,
  WEEKS_FOURTH_ROW_ID,
  WEEKS_FIFTH_ROW_ID,
  STOCK_AVAILABLE_AND_IN_TRANSIT_ROW_ID,
  PICKING_QUANTITY_ROW_ID,
  WAREHOUSE_QUANTITY_ROW_ID,
  REMAINING_QUANTITY_ROW_ID,
  REMAINING_QUANTITY_ADDITIONAL_ROW_ID,
  DISCOUNT_CUSTOM_FIELD,
} from '../constants/previousDaySummaryTable';
import { Row } from '../types';

export const generateRows = (data: GetStockSalesTableQuery, t: TFunction<'translation', undefined>) => {
  if (!data) return [];

  const dataColumns = data.stocksSalesTable?.columns?.filter((column) => !!column?.sKU);
  const dataHeaders = data.stocksSalesTable?.headers;
  const dataDiscountColumn = data.stocksSalesTable.discountColumn;

  if (!dataColumns) return [];

  const remainingQuantityRows = dataColumns[0]?.remainingQuantitiesByDateAndSupplier?.length
    ? dataColumns[0]?.remainingQuantitiesByDateAndSupplier?.map((remainingQuantity) => {
        return {
          id: `${REMAINING_QUANTITY_ADDITIONAL_ROW_ID}/${remainingQuantity?.timestamp}/${remainingQuantity?.supplierCode}`,
          [TYPE_CUSTOM_FIELD]: t('productOptimalStock.remaining_quantity_date', {
            date: moment(remainingQuantity?.timestamp).local().format('DD-MM-YYYY'),
          }),
          [ROW_PARENT_CUSTOM_FIELD]: REMAINING_QUANTITY_ROW_ID,
        };
      })
    : [];

  const rows: (Row | any)[] = [
    {
      id: TOTAL_SALES_ROW_ID,
      [TYPE_CUSTOM_FIELD]: t('productOptimalStock.total_sales'),
      [IS_GROUPING_ROW_CUSTOM_FIELD]: true,
      [DISCOUNT_CUSTOM_FIELD]: dataDiscountColumn!.discountStockRatePerSeason,
    },
    {
      id: PHYSICAL_SALES_ROW_ID,
      [TYPE_CUSTOM_FIELD]: t('productOptimalStock.physical_sales'),
      [ROW_PARENT_CUSTOM_FIELD]: TOTAL_SALES_ROW_ID,
      [DISCOUNT_CUSTOM_FIELD]: dataDiscountColumn!.discountStockRatePerPhysical,
    },
    {
      id: WEB_SALES_ROW_ID,
      [TYPE_CUSTOM_FIELD]: t('productOptimalStock.web_sales'),
      [ROW_PARENT_CUSTOM_FIELD]: TOTAL_SALES_ROW_ID,
      [DISCOUNT_CUSTOM_FIELD]: dataDiscountColumn!.discountStockRatePerWeb,
    },
    {
      id: WEEKS_TOTAL_ROW_ID,
      [TYPE_CUSTOM_FIELD]: t('productOptimalStock.all_weeks_total'),
      [IS_GROUPING_ROW_CUSTOM_FIELD]: true,
      [DISCOUNT_CUSTOM_FIELD]: dataDiscountColumn!.discountStockRatePerFiveWeeks,
    },
    {
      id: WEEKS_FIRST_ROW_ID,
      [TYPE_CUSTOM_FIELD]: t('productOptimalStock.sales_weeks', { date: dataHeaders && dataHeaders[0] }),
      [ROW_PARENT_CUSTOM_FIELD]: WEEKS_TOTAL_ROW_ID,
      [DISCOUNT_CUSTOM_FIELD]: dataDiscountColumn!.discountStockRateFirstWeek,
    },
    {
      id: WEEKS_SECOND_ROW_ID,
      [TYPE_CUSTOM_FIELD]: t('productOptimalStock.sales_weeks', { date: dataHeaders && dataHeaders[1] }),
      [ROW_PARENT_CUSTOM_FIELD]: WEEKS_TOTAL_ROW_ID,
      [DISCOUNT_CUSTOM_FIELD]: dataDiscountColumn!.discountStockRateSecondWeek,
    },
    {
      id: WEEKS_THIRD_ROW_ID,
      [TYPE_CUSTOM_FIELD]: t('productOptimalStock.sales_weeks', { date: dataHeaders && dataHeaders[2] }),
      [ROW_PARENT_CUSTOM_FIELD]: WEEKS_TOTAL_ROW_ID,
      [DISCOUNT_CUSTOM_FIELD]: dataDiscountColumn!.discountStockRateThirdWeek,
    },
    {
      id: WEEKS_FOURTH_ROW_ID,
      [TYPE_CUSTOM_FIELD]: t('productOptimalStock.sales_weeks', { date: dataHeaders && dataHeaders[3] }),
      [ROW_PARENT_CUSTOM_FIELD]: WEEKS_TOTAL_ROW_ID,
      [DISCOUNT_CUSTOM_FIELD]: dataDiscountColumn!.discountStockRateFourthWeek,
    },
    {
      id: WEEKS_FIFTH_ROW_ID,
      [TYPE_CUSTOM_FIELD]: t('productOptimalStock.sales_weeks', { date: dataHeaders && dataHeaders[4] }),
      [ROW_PARENT_CUSTOM_FIELD]: WEEKS_TOTAL_ROW_ID,
      [DISCOUNT_CUSTOM_FIELD]: dataDiscountColumn!.discountStockRateFifthWeek,
    },
    {
      id: STOCK_AVAILABLE_AND_IN_TRANSIT_ROW_ID,
      [TYPE_CUSTOM_FIELD]: t('productOptimalStock.stock_available_and_in_transit'),
    },
    {
      id: PICKING_QUANTITY_ROW_ID,
      [TYPE_CUSTOM_FIELD]: t('productOptimalStock.picking_quantity'),
    },
    {
      id: WAREHOUSE_QUANTITY_ROW_ID,
      [TYPE_CUSTOM_FIELD]: t('productOptimalStock.warehouse_quantity'),
    },
    {
      id: REMAINING_QUANTITY_ROW_ID,
      [TYPE_CUSTOM_FIELD]: t('productOptimalStock.remaining_quantity'),
      [IS_GROUPING_ROW_CUSTOM_FIELD]: dataColumns[0]?.remainingQuantitiesByDateAndSupplier?.length! > 0,
    },
    ...remainingQuantityRows,
    {
      id: DISTRIBUTION_ROW_ID,
      [TYPE_CUSTOM_FIELD]: t('productOptimalStock.distribution'),
    },
  ];

  // quantity cells
  dataColumns.forEach(
    (column: { [key: string]: number | string | (StatisticsSupplierOptimalStockDto | null)[] | null } | null) => {
      if (column && column[ROW_HEADER_ID_FIELD]) {
        const columnName = column[ROW_HEADER_ID_FIELD];
        rows.forEach((row) => {
          if (row.id.includes('remainingQuantityDeliveryDate')) {
            const rowTimestamp = row.id.split('/')[1];
            const rowSupplierCode = row.id.split('/')[2];
            const correspondingColumnValue = (
              column.remainingQuantitiesByDateAndSupplier as (StatisticsSupplierOptimalStockDto | null)[]
            )?.find(
              (remainingQuantity) =>
                remainingQuantity?.timestamp === rowTimestamp && remainingQuantity?.supplierCode === rowSupplierCode,
            )?.quantityForDelivery;
            row[columnName as string] = correspondingColumnValue;
          } else {
            const correspondingColumnValue = column[row.id as string] as number;
            row[columnName as string] = correspondingColumnValue;
          }
        });
      }
    },
  );

  // total cell
  rows.forEach((row) => {
    const rowValues = Object.values(row);

    rowValues.pop(); // remove discount field from calculation

    row[TOTAL_CUSTOM_FIELD] = rowValues.reduce((previousValue, currentValue) => {
      return (previousValue as number) + (typeof currentValue === 'number' ? currentValue : 0);
    }, 0);
  });

  return rows;
};

export const generatePercentageRows = (rows: Row[]) => {
  const percentageRows: Row[] = [];

  for (const row of rows) {
    const rowTotalQuantityValue = row[TOTAL_CUSTOM_FIELD] as number;
    const currentRow: Row = {};

    for (const rowDataKey in row) {
      const currentValue = row[rowDataKey];

      if (row.id === DISTRIBUTION_ROW_ID) {
        currentRow[rowDataKey] = currentValue;
        continue;
      }

      if (rowDataKey === TOTAL_CUSTOM_FIELD) {
        currentRow[rowDataKey] = currentValue ? 100 : '';
      } else if (
        rowDataKey === 'id' ||
        rowDataKey === TYPE_CUSTOM_FIELD ||
        rowDataKey.startsWith(CUSTOM_FIELD_PREFIX)
      ) {
        currentRow[rowDataKey] = currentValue;
      } else {
        currentRow[rowDataKey] = rowTotalQuantityValue
          ? (((currentValue as number) / rowTotalQuantityValue) * 100).toFixed(0)
          : '';
      }
    }

    percentageRows.push(currentRow);
  }

  return percentageRows;
};
