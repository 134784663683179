import { Layout } from 'antd';
import styled from 'styled-components';

export const StyledLayout = styled(Layout)`
  padding-top: 40px;
  background: transparent;
  min-height: 100vh;
`;

interface PageWrapperProps {
  noPadding: boolean;
}

export const PageWrapper = styled.div<PageWrapperProps>`
  background-color: ${(p) => p.theme.dark_blue_background};
  width: 100%;
  padding: ${(p) => (p.noPadding ? 0 : '20px')};

  & .ant-upload-list {
    width: 300px;
  }
`;
