//@ts-nocheck
import { cloneDeep } from 'lodash';

import { COLLECTION_ID_COL_DEF } from 'constants/collections';
import { AnyColDef, CollectionColDef } from 'types/collections';

export const isCollectionColDef = (colDef: AnyColDef) => {
  return COLLECTION_ID_COL_DEF in colDef;
};

export const prepareRowData = (rowData: any, allColDefs: AnyColDef[] | null) => {
  if (!rowData || !allColDefs) return [];

  const adaptedStoresData = cloneDeep(rowData);

  const collectionsColDefs = allColDefs.filter((colDef) => isCollectionColDef(colDef)) as CollectionColDef[];

  collectionsColDefs.forEach((collectionColDef) => {
    adaptedStoresData.forEach((row) => {
      const selectedCollectionGroups = row?.storeToStoreGroups?.filter(
        (storeGroup) => storeGroup?.collectionId === collectionColDef[COLLECTION_ID_COL_DEF],
      );

      if (collectionColDef.field && selectedCollectionGroups) {
        row[collectionColDef.field] = selectedCollectionGroups.map((group) => group!.storeGroupId);
      }
    });
  });

  return adaptedStoresData;
};
