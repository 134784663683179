declare global {
  interface Window {
    env: any;
  }
}

export const env = { ...process.env, ...window.env };

export default {
  apiAddress: env.REACT_APP_API,
  apolloClientAddress: env.REACT_APP_BACKEND_URI + '/graphql/',
  authenticationUrl: env.REACT_APP_BACKEND_URI + '/account',
  fileUploadUrl: env.REACT_APP_BACKEND_URI + '/file',
  agGridKey: env.REACT_APP_AG_GRID_KEY,
};
