import React, { useState, useEffect, useRef, useMemo, useContext, useCallback } from 'react';

import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { SeasonContext, UniverseContext } from 'context';
import { isEmpty, isNumber } from 'lodash';
import { useTranslation } from 'react-i18next';
import { getFormattedCurrentDateTime, getCurrentWeek } from 'utils/moment';

import { ConciseGridContainer } from 'components/AgGridComponents';
import { numberWithSpaceFormatter } from 'components/AgGridComponents/valueFormatters';
import ImportExportXlsxComponent from 'components/ImportExportXlsxComponent';
import { Spinner } from 'components/UI';
import {
  CENTERED_COL_DEF,
  HIGHLIGHTED_COLUMN_COL_DEFS,
  COLUMN_TYPES,
  GRID_DEFAULT_COL_DEFS,
  SIDE_BAR,
} from 'constants/gridConstants';
import { getContextMenu } from 'helpers/gridContextMenu';
import {
  StorePerformancePermutationSummaryDto,
  useGetStorePerformancePermutationSummaryLazyQuery,
} from 'services/graphql/main';
import { useError } from 'services/utils';

import { calculatePermutationSummaryData } from './common/helpers';
import { ExportButtonWrapper, StyledAdaptiveGridResizer } from './styles';

const StorePerformanceFamiliesTable: React.FC = () => {
  const { t } = useTranslation('translation');
  const { addError } = useError();

  const { selectedUniverse } = useContext(UniverseContext);
  const { selectedSeason } = useContext(SeasonContext);
  const seasonId = selectedSeason?.id;
  const universeId = selectedUniverse?.id;

  const gridRef = useRef<AgGridReact>(null);

  const [isGridReady, setIsGridReady] = useState(false);
  const [rowData, setRowData] = useState<StorePerformancePermutationSummaryDto[]>();
  const [filteredData, setFilteredData] = useState<StorePerformancePermutationSummaryDto[]>();

  const [
    getStorePerformancePermutationSummary,
    { loading: summaryLoading, data: summaryData, refetch: summaryRefetch, called: summaryCalled },
  ] = useGetStorePerformancePermutationSummaryLazyQuery({
    onError: (err) => addError(err, 'error'),
  });

  useEffect(() => {
    rowData && setFilteredData(rowData);
  }, [rowData]);

  useEffect(() => {
    if (!universeId || !seasonId) return;

    if (summaryCalled) {
      summaryRefetch({ seasonId, universeId });
    } else {
      getStorePerformancePermutationSummary({ variables: { seasonId, universeId } });
    }
  }, [summaryCalled, getStorePerformancePermutationSummary, summaryRefetch, seasonId, universeId]);

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        field: 'permutationsCode',
        sort: 'asc',
        sortable: true,
        headerName: t('common.permutationCodes'),
        valueGetter: function fullNameGetter(params) {
          return params.data.permutationField2
            ? `${params.data.permutationField1} - ${params.data.permutationField2}`
            : params.data.permutationField1!;
        },
        type: 'TEXT_COLUMN',
      },
      {
        field: 'permutations',
        sort: 'asc',
        sortable: true,
        headerName: t('storePerformanceAnalytics.analytical_axis'),
        valueGetter: function fullNameGetter(params) {
          return params.data.permutationFieldLabel2
            ? `${params.data.permutationFieldLabel1} - ${params.data.permutationFieldLabel2}`
            : params.data.permutationFieldLabel1!;
        },
        type: 'TEXT_COLUMN',
      },
      {
        field: 'numberOfProductsWithStock',
        headerName: t('storePerformance.number_of_products_in_store'),
      },
      {
        field: 'numberOfProductsWithOptimalStock',
        headerName: t('storePerformance.number_of_products_with_ideal_stock_in_store'),
      },
      {
        field: 'numberOfProductsWithSalesLastWeek',
        headerName: t(`storePerformance.number_of_articles_last_week`, { week: getCurrentWeek() - 1 }),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'salesQuantityLastWeek',
        valueFormatter: numberWithSpaceFormatter,
        headerName: t('storePerformance.previous_week_sales', { week: getCurrentWeek() - 1 }),
        ...HIGHLIGHTED_COLUMN_COL_DEFS,
      },
      {
        field: 'stockQuantityLastWeek',
        headerName: t('storePerformance.previous_week_stocks', { week: getCurrentWeek() - 1 }),
        valueFormatter: numberWithSpaceFormatter,
        ...HIGHLIGHTED_COLUMN_COL_DEFS,
      },
      {
        field: 'coverageOneWeekBefore',
        headerName: t('storePerformance.coverage_one_week_before', { week: getCurrentWeek() - 1 }),
        valueFormatter: numberWithSpaceFormatter,
        ...HIGHLIGHTED_COLUMN_COL_DEFS,
      },
      {
        field: 'coverageTwoWeeksBefore',
        headerName: t('storePerformance.coverage_two_weeks_before', { week: getCurrentWeek() - 2 }),
        valueFormatter: numberWithSpaceFormatter,
        ...HIGHLIGHTED_COLUMN_COL_DEFS,
      },
      {
        field: 'coverageThreeWeeksBefore',
        headerName: t('storePerformance.coverage_three_weeks_before', { week: getCurrentWeek() - 3 }),
        valueFormatter: numberWithSpaceFormatter,
        ...HIGHLIGHTED_COLUMN_COL_DEFS,
      },
      {
        field: 'averageCoverage',
        headerName: t('storePerformance.average_coverage'),
        valueFormatter: numberWithSpaceFormatter,
        ...HIGHLIGHTED_COLUMN_COL_DEFS,
      },
      {
        field: 'optimalStock',
        headerName: t('storePerformance.optimal_stock'),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'optimalStockOneWeekBefore',
        headerName: t('storePerformance.optimal_stock_week_before', { week: getCurrentWeek() - 1 }),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'estimatedPicking',
        headerName: t('storePerformance.estimated_picking'),
        valueFormatter: numberWithSpaceFormatter,
      },
    ],
    [t],
  );

  const createData = useCallback(() => {
    const calculatedData = filteredData && calculatePermutationSummaryData(filteredData);

    const result = [
      {
        permutationField1: t('storePerformanceAnalytics.total'),
        permutationField2: '',
        valueFormatter: numberWithSpaceFormatter,
        numberOfProductsWithStock: (filteredData && Math.round(calculatedData?.numberOfProductsWithStockSumTotal)) || 0,
        numberOfProductsWithOptimalStock:
          (filteredData && Math.round(calculatedData?.numberOfProductsWithOptimalStockSumTotal)) || 0,
        numberOfProductsWithSalesLastWeek:
          (filteredData && Math.round(calculatedData?.numberOfProductsWithSalesLastWeekSumTotal)) || 0,
        salesQuantityLastWeek: calculatedData?.salesQuantityLastWeekSumTotal,
        stockQuantityLastWeek: calculatedData?.stockQuantityLastWeekSumTotal,
        coverageOneWeekBefore:
          (filteredData && Number(calculatedData?.coverageOneWeekBeforeSumTotal) / Number(filteredData.length)) || 0,
        coverageTwoWeeksBefore:
          (filteredData && Number(calculatedData?.coverageTwoWeeksBeforeSumTotal) / Number(filteredData.length)) || 0,
        coverageThreeWeeksBefore:
          (filteredData && Number(calculatedData?.coverageThreeWeeksBeforeSumTotal) / Number(filteredData.length)) || 0,
        averageCoverage:
          (filteredData && Number(calculatedData?.averageCoverageSumTotal) / Number(filteredData.length)) || 0,
        optimalStock: calculatedData?.optimalStockSumTotal,
        optimalStockOneWeekBefore: calculatedData?.optimalStockOneWeekBeforeSumTotal,
        estimatedPicking: calculatedData?.estimatedPickingSumTotal,
      },
    ];

    return result;
  }, [filteredData, t]);

  const pinnedBottomRowData = useMemo<any>(() => {
    return createData();
  }, [createData]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100,
      ...CENTERED_COL_DEF,
      ...GRID_DEFAULT_COL_DEFS,
    };
  }, []);

  useEffect(() => {
    summaryData?.storePerformancePermutationSummary && setRowData(summaryData?.storePerformancePermutationSummary);
  }, [summaryData]);

  const onExport = useCallback(() => {
    gridRef.current &&
      gridRef.current.api.exportDataAsExcel({
        processCellCallback: (params) => {
          if (isNumber(params.value) && params?.value % 1 != 0) {
            return params.value.toFixed(1);
          }

          return params.value;
        },
        fileName: `${t(
          'storePerformanceAnalytics.store_performance_totals_per_analytical_axis',
        )}_${getFormattedCurrentDateTime()}`,
      });
  }, [t]);

  const onCSVExport = useCallback(() => {
    gridRef.current!.api.exportDataAsCsv();
  }, []);

  return summaryLoading ? (
    <Spinner size="large" />
  ) : rowData?.length ? (
    <ConciseGridContainer isGridReady={isGridReady}>
      <ExportButtonWrapper>
        <ImportExportXlsxComponent exportClickHandler={onExport} noImportOption />
      </ExportButtonWrapper>
      <StyledAdaptiveGridResizer>
        <AgGridReact
          containerStyle={{ width: '100%', marginBottom: 30, height: 'auto' }}
          ref={gridRef}
          columnDefs={columnDefs}
          headerHeight={55}
          suppressMenuHide
          rowData={rowData}
          defaultColDef={defaultColDef}
          domLayout={'autoHeight'}
          pinnedBottomRowData={pinnedBottomRowData}
          columnTypes={COLUMN_TYPES}
          onGridReady={() => {
            isEmpty(summaryData?.storePerformancePermutationSummary) && setIsGridReady(true);
          }}
          onFirstDataRendered={() => setIsGridReady(true)}
          onFilterChanged={(e) => {
            setFilteredData(e.api?.getRenderedNodes().map((node) => node.data));
          }}
          enableRangeSelection={true}
          getContextMenuItems={() => getContextMenu(onExport, onCSVExport)}
          sideBar={SIDE_BAR}
        />
      </StyledAdaptiveGridResizer>
    </ConciseGridContainer>
  ) : null;
};

export default StorePerformanceFamiliesTable;
