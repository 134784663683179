import styled from 'styled-components';

export const StyledModal = styled.div`
  background: #fff;
  position: fixed;
  top: 5px;
  left: 5px;
  bottom: 5px;
  right: 5px;
  display: flex;
  z-index: 1000;
  flex-direction: column;

  .ant-modal-header {
    padding: 8px 24px;
  }

  .ant-modal-title {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
  }

  .ant-modal-close-x {
    height: 39px;
    line-height: 39px;
  }

  .ant-modal-body {
    padding: 8px 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .tableWrapper {
    flex: 1;
  }

  & .ant-modal-content,
  & .ant-modal-body,
  & .ant-modal-header,
  & .ant-modal-footer {
    background-color: ${(p) => p.theme.dark_blue_background};
    border-color: ${(p) => p.theme.light_blue_background};
  }

  & .ant-modal-close-icon,
  & .ant-modal-title {
    color: ${(p) => p.theme.white};
  }
`;
