import React from 'react';

import Navbar from './components/Navbar';
import { HeaderWrapper } from './styles';

const Header: React.FC = () => {
  return (
    <HeaderWrapper>
      <Navbar />
    </HeaderWrapper>
  );
};

export default Header;
