import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import DistributionGrid from 'components/Grids/DistributionGrid/DistributionGrid';
import { Tabs } from 'components/UI';
import { SizeSystemContext } from 'context/size-system-context';
import { OriginType } from 'services/graphql/main';

import SelectedFamilies from './components/SelectedFamilies';
import SizeSystemsTable from './components/SizeSystemsTable';
import { SectionTitle } from './styles';

const SizeSystem: React.FC = () => {
  const { t } = useTranslation('translation');
  const { selectedSizeSystemOrigin } = useContext(SizeSystemContext);

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <SectionTitle>{t('operationalSettings.size_system_management')}</SectionTitle>
      <SizeSystemsTable />
      <SectionTitle style={{ marginTop: 10 }}>{t('operationalSettings.size_system_details')}</SectionTitle>
      <Tabs
        additionalStyles={{ flex: '1 1 auto' }}
        fullContentHeight
        defaultActiveKey="1"
        itemsWithTextKeys={
          selectedSizeSystemOrigin === OriginType.Imported
            ? [
                {
                  labelKey: 'operationalSettings.distribution_grid',
                  key: '1',
                  children: <DistributionGrid />,
                },
              ]
            : [
                {
                  labelKey: 'operationalSettings.distribution_grid',
                  key: '1',
                  children: <DistributionGrid />,
                },
                {
                  labelKey: 'operationalSettings.selected_families',
                  key: '2',
                  children: <SelectedFamilies />,
                },
              ]
        }
      />
    </div>
  );
};

export default SizeSystem;
