import { InputNumber } from 'antd';
import styled from 'styled-components';

export const SectionTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  color: ${(p) => p.theme.white};
`;

export const StyledLabel = styled.label`
  color: ${(p) => p.theme.white};
  text-decoration: none;
  font-size: 12px;
  align-items: center;
  cursor: pointer;
  display: block;
  margin-bottom: 5px;
`;

export const StyledSection = styled.div`
  display: flex;

  & > div:not(:last-child) {
    margin-right: 40px;
  }
`;

export const StyledInputNumber = styled(InputNumber)`
  width: 120px;
`;
