export const GRID_NAMES = {
  LEFT_GRID: 'leftGrid',
  MIDDLE_GRID: 'middleGrid',
  RIGHT_GRID: 'rightGrid',
};

export const HIGH_DISCOUNT_SEASON_LIMIT = 20;

export const CONDITIONAL_FORMATTING_COLORS = {
  BLUE: 'blue',
  RED: 'red',
  LIGHT_RED: '#FBDEE3',
  LIGHT_GREEN: '#E2F2E3',
  YELLOW: '#FFEB9C',
  ORANGE: '#F8CBAD',
  PINK: '#FFC7CE',
  SHOWCASE_PRODUCT: '#5aa4d0',
};
