import styled from 'styled-components';

export const StyledActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const ActionElementContainer = styled.div`
  display: flex;
  gap: 40px;
  width: 100%;
`;

export const ActionsElementWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;

  & .ant-checkbox-wrapper > span:not(:first-child) {
    color: ${(p) => p.theme.white};
  }
`;

export const ActionsElementTitle = styled.h3`
  font-size: 12px;
  font-weight: 400;
  color: ${(p) => p.theme.white};
`;
