import { QuestionOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const CreateButtonContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 20px;
`;

export const StyledQuestionIcon = styled(QuestionOutlined)`
  svg {
    color: ${(p) => p.theme.primary_yellow};
    height: 130px;
    width: 130px;
  }
`;

export const StyledGridWrapper = styled.div`
  .ag-body-horizontal-scroll {
    display: none;
  }
`;
