import React, { useState } from 'react';

import { OriginType } from 'services/graphql/main';

type SizeSystemContextType = {
  isDistributionTableUpdateActive: boolean;
  isSizeSystemUpdateActive: boolean;
  selectedSizeSystem: number | null;
  selectedSizeSystemOrigin: OriginType | null;
  setIsDistributionTableUpdateActive: (value: boolean) => void;
  setIsSizeSystemUpdateActive: (value: boolean) => void;
  setSelectedSizeSystem: (value: number | null) => void;
  setSelectedSizeSystemOrigin: (value: OriginType | null) => void;
};

export const SizeSystemContext = React.createContext<SizeSystemContextType>({} as SizeSystemContextType);

type ContextProviderType = {
  children: React.ReactNode;
};

export const SizeSystemContextProvider: React.FC<ContextProviderType> = ({ children }) => {
  const [isDistributionTableUpdateActive, setIsDistributionTableUpdateActive] = useState(false);
  const [isSizeSystemUpdateActive, setIsSizeSystemUpdateActive] = useState(false);
  const [selectedSizeSystem, setSelectedSizeSystem] = useState<number | null>(null);
  const [selectedSizeSystemOrigin, setSelectedSizeSystemOrigin] = useState<OriginType | null>(null);

  return (
    <SizeSystemContext.Provider
      value={{
        isDistributionTableUpdateActive,
        isSizeSystemUpdateActive,
        setIsDistributionTableUpdateActive,
        selectedSizeSystem,
        setIsSizeSystemUpdateActive,
        setSelectedSizeSystem,
        selectedSizeSystemOrigin,
        setSelectedSizeSystemOrigin,
      }}
    >
      {children}
    </SizeSystemContext.Provider>
  );
};
