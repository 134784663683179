import React from 'react';

import { TableViewsContextProvider } from 'context';
import { useTranslation } from 'react-i18next';

import StyledModal from '../StyledModal/StyledModal';
import { AnalyticsModalProps } from './common/types';
import StorePerformanceAnalyticsTable from './StorePerformanceAnalyticsTable';
import StorePerformanceFamiliesTable from './StorePerformanceFamiliesTable';
import { StyledTabs } from './styles';
import './styles.css';

const StorePerformanceAnalyticsModal: React.FC<AnalyticsModalProps> = ({ isOpen, handleCancel }) => {
  const { t } = useTranslation('translation');

  return (
    <StyledModal
      title={t('storePerformanceAnalytics.store_performance_analytics_modal_title')}
      open={isOpen}
      onCancel={handleCancel}
      className="additional-modal-styles"
      footer={null}
      width="100%"
      centered={true}
    >
      <StyledTabs
        renderTabBar={(props, TabNavList) => <TabNavList {...props} mobile={true} />}
        defaultActiveKey="1"
        itemsWithTextKeys={[
          {
            key: '1',
            labelKey: 'storePerformanceAnalytics.analytical_axis',
            children: <StorePerformanceFamiliesTable />,
          },
          {
            key: '2',
            labelKey: 'storePerformanceAnalytics.store_performance_analytics_store_tab_title',
            children: (
              <TableViewsContextProvider tableName="storePerformanceAnalyticsTable">
                <StorePerformanceAnalyticsTable />
              </TableViewsContextProvider>
            ),
          },
        ]}
      />
    </StyledModal>
  );
};

export default StorePerformanceAnalyticsModal;
