import { Divider, Checkbox, Select } from 'antd';
import styled from 'styled-components';

export const StyledDivider = styled(Divider)`
  background-color: ${(p) => p.theme.light_gray};
  height: 2px;
  margin-top: 0px;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SlideShowWrapper = styled.div`
  .slick-track {
    height: unset !important;
  }
  .slideshow-content {
    color: #e0e0e0;
    display: flex;
    justify-content: flex-start;
    flex-flow: column wrap;
    align-content: center;
  }
  .slideshow-content > img {
    height: 150px;
    margin-bottom: 20px;
  }
  .default-background-camera {
    color: #e0e0e0;

    svg {
      width: 100px;
      height: 100px;
    }
  }

  .default-background-image-text {
    color: #e0e0e0;
    font-size: 12px;
    margin-bottom: 0px;
    margin-bottom: 20px;
  }

  .ant-carousel .slick-dots li.slick-active,
  .ant-carousel .slick-dots li.slick-active button {
    height: 15px;
    width: 15px;
    background-color: #e0e0e0;
  }

  .ant-carousel .slick-dots li button {
    background-color: #fff;
    border: 2px solid #e0e0e0;
    height: 15px;
    width: 15px;
  }

  .delete-img-btn {
    color: #e0e0e0;
    padding-left: 0;
  }
`;

export const SlideShowActionsWrapper = styled.div`
  height: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .checkbox-wrapper {
    padding: 4px 0;
    display: flex;
    gap: 5px;
    margin-left: 10px;

    label {
      color: ${(p) => p.theme.white};
    }

    p {
      font-weight: 600;
    }
  }
`;

export const StyledLabel = styled.label`
  color: ${(p) => p.theme.white};
  text-decoration: none;
  font-size: 12px !important;
  align-items: center;
  cursor: pointer;
`;

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    border: 2px solid ${(p) => p.theme.light_gray};
    width: 25px;
    height: 25px;
    border-radius: 5px;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: ${(p) => p.theme.light_gray};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border: 2px solid ${(p) => p.theme.light_gray};
    border-radius: 5px;
    background-color: #fff;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    border: 2px solid ${(p) => p.theme.light_gray};
    border-top: 0;
    border-left: 0;
  }
`;

export const StyledCheckboxMini = styled(StyledCheckbox)`
  .ant-checkbox-inner {
    width: 15px;
    height: 15px;
    border-radius: 3px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-radius: 3px;
  }
`;
