import { Modal } from 'antd';
import styled from 'styled-components';

export const StyledAntdModal = styled(Modal)`
  padding: 0px;

  & .ant-modal-content,
  & .ant-modal-header,
  & .ant-modal-footer {
    background-color: ${(p) => p.theme.dark_blue_background};
    border-color: ${(p) => p.theme.light_blue_background};
  }

  & .ant-modal-close-icon,
  & .ant-modal-title {
    color: ${(p) => p.theme.white};
  }
`;
