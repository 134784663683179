import { List, Form } from 'antd';
import styled from 'styled-components';

import { Checkbox } from 'components/UI';

const { Item } = List;

export const ModalContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
`;

export const ListsContainer = styled('div')`
  display: flex;
  gap: 8px;
  width: 100%;
  background-color: ${(p) => p.theme.white};

  .list {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 300px;
    max-height: calc(100vh - 470px);
  }

  .list .ant-list-header {
    background-color: var(--ag-header-background-color);
  }

  .ant-spin-nested-loading {
    flex: 1;
    overflow-y: auto;
  }

  .visibleOnHover .anticon {
    color: ${(p) => p.theme.light_gray};
  }

  .visibleOnHover .anticon:hover {
    color: ${(p) => p.theme.dark_gray};
  }
`;

export const ListHeaderContent = styled('div')`
  display: flex;
  justify-content: center;
  font-weight: bold;

  .itemActions {
    position: absolute;
    right: 8px;
  }
`;

export const StyledItem = styled(Item)`
  display: flex;
  justify-items: space-between;
  word-break: break-word;

  .itemActions {
    z-index: 1;
  }

  .visibleOnHover {
    display: flex;
    gap: 8px;
    transition: opacity 0.15s;
    opacity: 0;
  }

  :hover .visibleOnHover {
    opacity: 1;
  }
`;

export const StyledViewItem = styled(StyledItem)`
  &.selected {
    position: relative;
    z-index: 1;
  }

  &.selected:after {
    content: '';
    z-index: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background-color: ${(p) => p.theme.green};
  }

  :hover {
    background-color: #fbfbfb;
    cursor: pointer;
  }
`;

export const StyledFormItem = styled(Form.Item)`
  & .ant-form-item-label > label {
    color: ${(p) => p.theme.white};
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  span:not(:first-child) {
    color: ${(p) => p.theme.white};
  }
`;
