import React, { useContext, useState } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { DatePicker, Select } from 'antd';
import { SeasonContext } from 'context';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { Checkbox, PrimaryButton } from 'components/UI';
import { VerticalPageContent } from 'components/wrappers';
import { useCommonData } from 'hooks/useCommonData';
import { AuditableObjectType } from 'services/graphql/main';

import LoggingTable from './components/LoggingTable';
import { DatesWrapper, FiltersContainer, WhiteText } from './styles';

const Logging: React.FC = () => {
  const { t } = useTranslation('translation');
  const { activeSeasons } = useContext(SeasonContext);
  const { seasonCode } = useCommonData();

  const [temporaryObjectType, setTemporaryObjectType] = useState<AuditableObjectType | undefined>(
    AuditableObjectType.Product,
  );
  const [temporaryFromDate, setTemporaryFromDate] = useState<string | null>(moment(new Date()).local().toISOString());
  const [temporaryToDate, setTemporaryToDate] = useState<string | null>(null);
  const [temporarySelectedSeason, setTemporarySelectedSeason] = useState<string | null>(seasonCode || null);
  const [objectType, setObjectType] = useState<AuditableObjectType | undefined>(AuditableObjectType.Product);
  const [fromDate, setFromDate] = useState<string | null>(moment(new Date()).local().toISOString());
  const [toDate, setToDate] = useState<string | null>(null);
  const [selectedSeason, setSelectedSeason] = useState<string | null>(seasonCode || null);

  const handleFilterChange = (value: AuditableObjectType) => {
    if (temporaryObjectType === value) {
      setTemporaryObjectType(undefined);
    } else {
      setTemporaryObjectType(value);
    }
  };

  const handleSearchClick = () => {
    setObjectType(temporaryObjectType);
    setFromDate(temporaryFromDate);
    setToDate(temporaryToDate);
    setSelectedSeason(temporarySelectedSeason);
  };

  return (
    <VerticalPageContent>
      <FiltersContainer>
        <div>
          <WhiteText style={{ marginBottom: 5 }}>{t('logging.universe')}</WhiteText>
          <Checkbox
            style={{ fontSize: 12, color: '#ffffff' }}
            textKey="logging.store"
            onChange={() => handleFilterChange(AuditableObjectType.Store)}
            checked={temporaryObjectType === AuditableObjectType.Store}
          />
          <Checkbox
            style={{ fontSize: 12, color: '#ffffff' }}
            textKey="logging.product"
            onChange={() => handleFilterChange(AuditableObjectType.Product)}
            checked={temporaryObjectType === AuditableObjectType.Product}
          />
          <Checkbox
            style={{ fontSize: 12, color: '#ffffff' }}
            textKey="logging.setting"
            onChange={() => handleFilterChange(AuditableObjectType.TenantSettings)}
            checked={temporaryObjectType === AuditableObjectType.TenantSettings}
          />
        </div>
        <DatesWrapper>
          <WhiteText>{t('logging.period_from')}</WhiteText>
          <DatePicker
            defaultValue={moment(temporaryFromDate)}
            onChange={(value) => setTemporaryFromDate(moment(value).local().toISOString())}
          />
          <WhiteText>{t('logging.to')}</WhiteText>
          <DatePicker onChange={(value) => setTemporaryToDate(moment(value).local().toISOString())} />
          <WhiteText>{t('common.season')}</WhiteText>
          <Select
            placeholder={t('common.select_season')}
            style={{ width: 138 }}
            options={activeSeasons?.map((season) => ({ value: season?.code, label: season?.code }))}
            allowClear
            onChange={(value) => setTemporarySelectedSeason(value)}
            value={temporarySelectedSeason}
            onClear={() => setTemporarySelectedSeason(null)}
          />
        </DatesWrapper>
        <PrimaryButton
          className="searchButton"
          size="small"
          textKey="optimalStockAndEstimatedPicking.button_search"
          icon={<SearchOutlined />}
          onClick={handleSearchClick}
          style={{ margin: '15px 0 0 40px', height: 32 }}
        />
      </FiltersContainer>
      <LoggingTable fromDate={fromDate} toDate={toDate} objectType={objectType} selectedSeason={selectedSeason} />
    </VerticalPageContent>
  );
};

export default Logging;
