import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import {
  CellEditingStartedEvent,
  CellEditingStoppedEvent,
  ColDef,
  GetRowIdFunc,
  GetRowIdParams,
  ICellEditorParams,
  RowClassParams,
  RowNode,
  RowSelectedEvent,
  MenuItemDef,
  GetContextMenuItemsParams,
  EditableCallbackParams,
  CellClassParams,
  IRowNode,
  ExcelStyle,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Input } from 'antd';
import { ProductContext, StoreManagementContext, TableViewsContext, PermissionsContext } from 'context';
import { cloneDeep, isArray, isEqual, isString } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { isAllowedNumericFieldKey } from 'utils/validation';

import { ClearFiltersButton, ConciseGridContainer, NumericCellEditor, Views } from 'components/AgGridComponents';
import { numberWithSpaceFormatter } from 'components/AgGridComponents/valueFormatters';
import { ConfirmModal } from 'components/Modals';
import { NakedButton, Spinner } from 'components/UI';
import { VerticalPageContent } from 'components/wrappers';
import { CENTERED_COL_DEF, GRID_DEFAULT_PROPS } from 'constants/gridConstants';
import { hasFiltersChanged } from 'helpers/grid';
import { getVisibleColDefs } from 'helpers/gridCols';
import { useCommonData } from 'hooks/useCommonData';
import { useGridCols } from 'hooks/useGridCols';
import {
  ExceptionOptimalStockDto,
  ExceptionOptimalStockHeaderDto,
  ExceptionOptimalStockStockEnum,
  ExceptionOptimalStockUpdateInput,
  HeaderValuePair,
  useDeleteStoreOptimalStockOverrideMutation,
  useStoreCustomFieldsQuery,
  useUpdateBulkStoreOptimalStockOverrideMutation,
  useUpdateStoreOptimalStockExceptionMutation,
  useUpdateStoreOptimalStockOverrideMutation,
  PermissionsEnum,
  useRemoveStoreOptimalStockExceptionMutation,
  useDeleteSelectedStoreOptimalStockOverrideMutation,
  useGetProductOptimalStockProductSummaryLazyQuery,
} from 'services/graphql/main';
import { useError } from 'services/utils';
import { GridName } from 'types/views';

import { GridsContainer, OverridingContainer, ViewsContainer } from './styles';
import { BulkEditorColsData, ExceptionsTableProps, RowsProps, SelectedRowCellProps } from './types';

const ExceptionsTable: React.FC<ExceptionsTableProps> = ({
  tablesData,
  tableDataSettings,
  productId,
  isProductArchived,
}) => {
  const { t, i18n } = useTranslation('translation');
  const { addError } = useError();

  const StocksOptionsAsArray = ExceptionOptimalStockStockEnum;

  const { visibleSizeSystemsColsIds } = useContext(ProductContext);
  const { selectedViewId, getView } = useContext(TableViewsContext);
  const {
    refetchExceptionOptimalStockTable,
    errorExceptionOptimalStockTable,
    includedStoresRows,
    excludedStoresRows,
    refetchStoreStrengthTable,
  } = useContext(StoreManagementContext);
  const { permissions } = useContext(PermissionsContext);

  const { data: customFieldsData } = useStoreCustomFieldsQuery({
    onError: (err) => addError(err, 'error'),
  });
  const [getProductSummary, { data: dataProductSummary }] = useGetProductOptimalStockProductSummaryLazyQuery({
    onError: (err) => addError(err, 'error'),
  });
  const [updateStoreOptimalStockOverride] = useUpdateStoreOptimalStockOverrideMutation({
    onError: (err) => addError(err, 'warning'),
  });
  const [updateBulkStoreOptimalStockOverrideMutation] = useUpdateBulkStoreOptimalStockOverrideMutation({
    onError: (err) => addError(err, 'warning'),
  });

  const [updateStoreOptimalStockException] = useUpdateStoreOptimalStockExceptionMutation({
    onError: (err) => addError(err, 'warning'),
  });

  const [removeStoreOptimalStockException] = useRemoveStoreOptimalStockExceptionMutation({
    onError: (err) => addError(err, 'warning'),
  });

  const [deleteStoreOptimalStockOverride] = useDeleteStoreOptimalStockOverrideMutation({
    onError: (err) => addError(err, 'warning'),
  });

  const [deleteSelectedStoreOptimalStockOverride] = useDeleteSelectedStoreOptimalStockOverrideMutation({
    onError: (err) => addError(err, 'warning'),
  });

  const refetchData = useCallback(async () => {
    await refetchStoreStrengthTable({ productId: productId || 0 });
    await refetchExceptionOptimalStockTable();
  }, [refetchExceptionOptimalStockTable, refetchStoreStrengthTable, productId]);

  const gridRef = useRef<AgGridReact<ExceptionOptimalStockDto>>(null);
  const gridsContainerRef = useRef<HTMLDivElement>(null);

  const [isGridReady, setIsGridReady] = useState(false);

  const [leftTableColumnDefs, setLeftTableColumnDefs] = useState<ColDef[]>();
  const [rightTableColumnDefs, setRightTableColumnDefs] = useState<ColDef[]>();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>();
  const [rowData, setRowData] = useState<RowsProps>([]);
  const [hasFilters, setHasFilters] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<SelectedRowCellProps | [] | {}>([]);
  const [bulkRowsEditorColsData, setBulkRowsEditorColsData] = useState<BulkEditorColsData>({});
  const [selectedNodes, setSelectedNodes] = useState<RowNode[] | null>(null);
  const [loadingBulkStoreOptimalStockOverride, setLoadingBulkStoreOptimalStockOverride] = useState(false);
  const [selectedStoreIds, setSelectedStoreIds] = useState<number[] | undefined>();

  const { seasonName, universeCode } = useCommonData();

  const hiddenExportData = {
    productCode: dataProductSummary?.productOptimalStockProductSummary?.productCode,
    colorCode: dataProductSummary?.productOptimalStockProductSummary?.colorCode,
    season: seasonName,
    universe: universeCode,
  };
  const hiddenExportColumns: ColDef[] = [
    {
      field: 'productCode',
      headerName: t('common.product_code'),
      hide: true,
      width: 130,
      suppressColumnsToolPanel: true,
      suppressSizeToFit: true,
      suppressFiltersToolPanel: true,
    },
    {
      field: 'colorCode',
      headerName: t('common.color_code'),
      hide: true,
      width: 130,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
    {
      field: 'season',
      headerName: t('common.season'),
      hide: true,
      width: 130,
      suppressSizeToFit: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
    {
      field: 'universe',
      headerName: t('common.universe'),
      hide: true,
      width: 130,
      suppressSizeToFit: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState({
    deleteRowOverridesModal: false,
    deleteSelectedOverridesModal: false,
  });

  const [rightTableCellColWidths, setRightTableColWidths] = useState<{ sizeCells: number; totalCell: number }>();
  const showModal = useMemo(
    () => ({
      DELETE_ROW_OVERRIDES_MODAL: () =>
        setIsModalOpen((prevState) => ({ ...prevState, deleteRowOverridesModal: true })),
      DELETE_SELECTED_OVERRIDES_MODAL: () =>
        setIsModalOpen((prevState) => ({ ...prevState, deleteSelectedOverridesModal: true })),
    }),
    [],
  );

  const hideModal = useMemo(
    () => ({
      DELETE_ROW_OVERRIDES_MODAL: () =>
        setIsModalOpen((prevState) => ({ ...prevState, deleteRowOverridesModal: false })),
      DELETE_SELECTED_OVERRIDES_MODAL: () =>
        setIsModalOpen((prevState) => ({ ...prevState, deleteSelectedOverridesModal: false })),
    }),
    [],
  );

  const { handleFilterChanged, handleSelectionChanged, handleSortChanged } = useGridCols({
    gridsContainerRef,
    gridRef,
    isGridReady,
    tableName: 'exceptionsTable',
    ignoreSavedSorting: true,
  });

  const cellEditorSelector = useCallback((params: ICellEditorParams<{ type: string; value: number }>) => {
    if (params.data.type !== 'code' && params.data.type !== 'total') {
      return {
        component: NumericCellEditor,
      };
    }

    return undefined;
  }, []);

  const createTotalRowData = useMemo(() => {
    const calculateColumnDataTotal = (rawData: RowsProps, initialValue: { [key: string]: number }) => {
      const initialValueKeys = Object.keys(initialValue).filter((key) => key !== 'storeStrength');
      const calculatedData = rawData?.reduce((acc, currentValue: any) => {
        const currentRow: any = {};

        initialValueKeys.forEach((key) => {
          currentRow[key as string] = isNaN(acc[key] + currentValue[key]) ? '' : acc[key] + currentValue[key];
        });

        return currentRow;
      }, initialValue);

      return calculatedData;
    };

    const initialRowDataConstructor: any = {};

    columnDefs?.forEach((row) => {
      const colField = row.field;

      initialRowDataConstructor[colField as string] = 0;
    });

    const calculatedTotals = calculateColumnDataTotal(rowData, initialRowDataConstructor);

    return [calculatedTotals];
  }, [leftTableColumnDefs, rightTableColumnDefs, rowData]);

  const handleDeleteRowOverrides = useCallback(async () => {
    if (selectedRowData && isArray(selectedRowData)) {
      const storeId = selectedRowData[0].storeId;

      if (productId && storeId) {
        await deleteStoreOptimalStockOverride({
          variables: { productId, storeId },
        });

        hideModal['DELETE_ROW_OVERRIDES_MODAL']();
        await refetchData();
      }
    }
  }, [deleteStoreOptimalStockOverride, hideModal, productId, refetchData, selectedRowData]);

  const handleDeleteSelectedOverrides = useCallback(async () => {
    if (productId && !!selectedStoreIds?.length) {
      await deleteSelectedStoreOptimalStockOverride({
        variables: { productId, storeIds: selectedStoreIds },
      });
      hideModal['DELETE_SELECTED_OVERRIDES_MODAL']();
      await refetchData();
    }
  }, [hideModal, productId, refetchData, deleteSelectedStoreOptimalStockOverride, selectedStoreIds]);

  const RemoveAllOverrides = useCallback(() => {
    return (
      <div style={{ textAlign: 'center' }}>
        <NakedButton
          disabled={
            tableDataSettings.stockEnum === StocksOptionsAsArray['StockStoreAndTransit'] || !selectedStoreIds?.length
          }
          type="text"
          onClick={showModal['DELETE_SELECTED_OVERRIDES_MODAL']}
        >
          <DeleteOutlined style={{ cursor: 'pointer', fontSize: '20px', marginTop: '-2px' }} />
        </NakedButton>
      </div>
    );
  }, [showModal, selectedStoreIds, tableDataSettings]);

  const RemoveRowOverrides = useCallback(() => {
    return (
      <div style={{ textAlign: 'center' }}>
        <NakedButton
          disabled={tableDataSettings.stockEnum === StocksOptionsAsArray['StockStoreAndTransit']}
          type="text"
          style={{ height: 'auto', marginTop: '-3px' }}
          onClick={showModal['DELETE_ROW_OVERRIDES_MODAL']}
        >
          <DeleteOutlined style={{ cursor: 'pointer', fontSize: '15px' }} />
        </NakedButton>
      </div>
    );
  }, [showModal]);

  const handleUpdateStoreOptimalStockException = useCallback(
    async (props: any) => {
      if (productId && props) {
        if (
          includedStoresRows?.find((row: any) => row.storeCode === props.data.storeCode) ||
          excludedStoresRows?.find((row: any) => row.storeCode === props.data.storeCode)
        ) {
          await removeStoreOptimalStockException({ variables: { productId: productId, storeId: props.data.id } });
        } else {
          await updateStoreOptimalStockException({
            variables: {
              input: {
                productId,
                storeId: props.data.id,
                isIncluded: !props.data.isIncluded,
              },
            },
          });
        }

        await refetchData();
      }
    },
    [
      productId,
      refetchData,
      updateStoreOptimalStockException,
      includedStoresRows,
      excludedStoresRows,
      removeStoreOptimalStockException,
    ],
  );

  const getContextMenu = useCallback(
    (params: GetContextMenuItemsParams) => {
      const result: (string | MenuItemDef)[] = [
        'copy',
        'copyWithHeaders',
        'copyWithGroupHeaders',
        'paste',
        'export',
        !isProductArchived
          ? {
              name: `${
                includedStoresRows?.find((row: any) => row.storeCode === params.node?.data.storeCode) ||
                excludedStoresRows?.find((row: any) => row.storeCode === params.node?.data.storeCode)
                  ? 'Remove'
                  : params.node?.data.isIncluded
                  ? 'Exclude'
                  : 'Include'
              } store`,
              action: () => {
                handleUpdateStoreOptimalStockException(params.node);
              },
            }
          : '',
      ];

      return result;
    },
    [handleUpdateStoreOptimalStockException, isProductArchived, excludedStoresRows, includedStoresRows],
  );

  const onCellEditingStarted = useCallback((event: CellEditingStartedEvent) => {
    if (!event.value && event.value !== 0) {
      event.api.stopEditing();
    }
  }, []);

  const onCellEditingStopped = useCallback(
    async (event: CellEditingStoppedEvent) => {
      if (event.colDef.field && isArray(selectedRowData)) {
        const colId = Number(event.colDef.field.split('right-')[1]);
        const { articleId, storeId } = selectedRowData.find((cell: SelectedRowCellProps) => cell.headerId === colId);

        await updateStoreOptimalStockOverride({
          variables: {
            exceptionOptimalStock: {
              articleId,
              storeId,
              optimalStock: Number(event.newValue),
            },
          },
        });

        await refetchData();
      }
    },
    [refetchData, selectedRowData, updateStoreOptimalStockOverride],
  );

  const isOptimalStockSelected = useMemo(() => {
    return tableDataSettings.stockEnum === StocksOptionsAsArray['OptimalStock'];
  }, [StocksOptionsAsArray, tableDataSettings.stockEnum]);

  const getColumnDefs = useCallback(
    (colsData: any, tableType: 'left' | 'right') => {
      const cellClassRules = {
        'cell-overridden': (params: any) => {
          if (params.data.rightTableCellsRawData) {
            const colId = Number(params.column.colId.split('right-')[1]);
            const isOverridden = params.data.rightTableCellsRawData.find(
              (x: HeaderValuePair) => colId === x.headerId && x.isOverriden === true,
            );

            return isOverridden || false;
          }

          return false;
        },
      };

      const colDefs: ColDef[] =
        colsData?.map((value: any) => {
          return {
            field: `${tableType}-${value!.headerId.toString()}` || undefined,
            cellClassRules: cellClassRules,
            cellEditorSelector: cellEditorSelector,
            editable(params: EditableCallbackParams) {
              return (
                tableType === 'right' &&
                isOptimalStockSelected &&
                (permissions as PermissionsEnum[])?.includes(PermissionsEnum.ProductPerformanceDetailsEdit) &&
                !isProductArchived &&
                params.data?.isIncluded
              );
            },
            headerName: value!.headerName || undefined,
            valueFormatter: numberWithSpaceFormatter,
            ...CENTERED_COL_DEF,
          };
        }) || [];

      colDefs.push({
        field: `${tableType}Total` || undefined,
        headerName: t('exceptionTable.total'),
        editable: false,
        minWidth: 80,
        ...CENTERED_COL_DEF,
      });

      tableType === 'right' &&
        colDefs.push({
          headerComponent: RemoveAllOverrides,
          maxWidth: 59,
          minWidth: 50,
          sortable: false,
          editable: false,
          cellStyle: { textAlign: 'center' },
          suppressMenu: true,
          cellRenderer: RemoveRowOverrides,
          headerCheckboxSelection: tableType === 'right' && isOptimalStockSelected,
          checkboxSelection(params) {
            return tableType === 'right' && isOptimalStockSelected && params.data.isIncluded;
          },
          showDisabledCheckboxes: true,
          pinned: 'right',
        });

      return colDefs;
    },
    [
      RemoveAllOverrides,
      RemoveRowOverrides,
      cellEditorSelector,
      isOptimalStockSelected,
      t,
      visibleSizeSystemsColsIds,
      permissions,
      isProductArchived,
    ],
  );

  const getRowsData = useCallback((rowsRawData: (RowsProps | null)[] | null | undefined) => {
    const rows = rowsRawData?.map((obj: any | null | undefined) => {
      const constructorObject: any = {
        id: obj?.storeId,
        storeCode: obj?.storeCode,
        storeName: obj?.storeName,
        storeGroupName: obj?.storeGroupName,
        storeStrength: obj?.storeStrength,
        storeCustomField1: obj?.storeCustomField1,
        storeCustomField2: obj?.storeCustomField2,
        storeCustomField3: obj?.storeCustomField3,
        storeCustomField4: obj?.storeCustomField4,
        storeCustomField5: obj?.storeCustomField5,
        isIncluded: obj?.isIncluded,
      };

      const totalSumRowLeftTable = obj?.leftTable!.reduce((acc: any, obj: any) => {
        return acc + obj.value;
      }, 0);

      const totalSumRowRightTable = obj?.rightTable!.reduce((acc: any, obj: any) => {
        return acc + obj.value;
      }, 0);

      constructorObject.leftTotal = totalSumRowLeftTable;
      constructorObject.rightTotal = totalSumRowRightTable;

      for (const key in obj?.leftTable) {
        constructorObject[`left-${obj?.leftTable[key]?.headerId}`] = obj?.leftTable[key]?.value;
      }

      const rightTableCellsRawData = [];

      for (const key in obj?.rightTable) {
        rightTableCellsRawData.push(obj?.rightTable[key]);
        constructorObject[`right-${obj?.rightTable[key]?.headerId}`] = obj?.rightTable[key]?.value;
      }

      constructorObject['rightTableCellsRawData'] = rightTableCellsRawData;

      return constructorObject;
    });

    return rows;
  }, []);

  const getRowId = useMemo<GetRowIdFunc>(() => {
    return (params: GetRowIdParams) => params.data.id;
  }, []);

  const getMiddleGridRowClass = useCallback((params: RowClassParams) => {
    return params.data?.storeCode ? 'storeCode' : undefined;
  }, []);

  const defaultColDef: ColDef = useMemo(
    () => ({
      ...CENTERED_COL_DEF,
      flex: 1,
      suppressMovable: true,
      sortable: true,
      filter: 'agNumberColumnFilter',
      resizable: true,
      wrapHeaderText: true,
      tooltipValueGetter(params) {
        const value = params.valueFormatted ? params.valueFormatted : params.value;

        return isString(value) ? value : null;
      },
    }),
    [],
  );

  const getCenterColDefs = (): ColDef[] => {
    const colDefs: ColDef[] = [
      {
        field: 'storeCode',
        headerName: t('exceptionTable.store_code'),
        type: 'TEXT_COLUMN',
        cellClass: 'stringType',
        ...CENTERED_COL_DEF,
      },
      { field: 'storeName', headerName: t('exceptionTable.store_name'), type: 'TEXT_COLUMN', ...CENTERED_COL_DEF },
      {
        field: 'storeGroupName',
        headerName: t('exceptionTable.store_group'),
        type: 'TEXT_COLUMN',
        ...CENTERED_COL_DEF,
      },
      { field: 'storeStrength', headerName: t('exceptionTable.store_strength') },
    ];
    if (customFieldsData?.storeCustomFields?.customFieldName1!)
      colDefs.push({
        field: 'storeCustomField1',
        headerName: customFieldsData!.storeCustomFields?.customFieldName1!,
        type: 'TEXT_COLUMN',
        ...CENTERED_COL_DEF,
      });
    if (customFieldsData?.storeCustomFields?.customFieldName2!)
      colDefs.push({
        field: 'storeCustomField2',
        headerName: customFieldsData!.storeCustomFields?.customFieldName2!,
        type: 'TEXT_COLUMN',
        ...CENTERED_COL_DEF,
      });
    if (customFieldsData?.storeCustomFields?.customFieldName3!)
      colDefs.push({
        field: 'storeCustomField3',
        headerName: customFieldsData!.storeCustomFields?.customFieldName3!,
        type: 'TEXT_COLUMN',
        ...CENTERED_COL_DEF,
      });
    if (customFieldsData?.storeCustomFields?.customFieldName4!)
      colDefs.push({
        field: 'storeCustomField4',
        headerName: customFieldsData!.storeCustomFields?.customFieldName4!,
        type: 'TEXT_COLUMN',
        ...CENTERED_COL_DEF,
      });
    if (customFieldsData?.storeCustomFields?.customFieldName5!)
      colDefs.push({
        field: 'storeCustomField5',
        headerName: customFieldsData!.storeCustomFields?.customFieldName5!,
        type: 'TEXT_COLUMN',
        ...CENTERED_COL_DEF,
      });

    return colDefs;
  };
  useEffect(() => {
    productId && getProductSummary({ variables: { productId } });
  }, [productId]);

  useEffect(() => {
    if (tablesData) {
      const getRightTableClass = (params: CellClassParams<ExceptionOptimalStockDto>) =>
        params?.data?.isIncluded === false || params?.data?.storeStrength === 0 ? 'excluded-store-disable' : '';

      const filteredSizeSystemHeaders: ExceptionOptimalStockHeaderDto = tablesData?.sizeSystemHeaders?.filter(
        (header: ExceptionOptimalStockHeaderDto) => !!header?.articleId,
      );
      const leftTableColumns: ColDef[] = [...getColumnDefs(filteredSizeSystemHeaders, 'left')];
      const rightTableColumns: ColDef[] = [...getColumnDefs(filteredSizeSystemHeaders, 'right')];

      setLeftTableColumnDefs(leftTableColumns.map((colDef) => ({ ...colDef, pinned: 'left' })));
      setRightTableColumnDefs(
        rightTableColumns.map((colDef) => ({ ...colDef, pinned: 'right', cellClass: getRightTableClass })),
      );
    } else {
      setLeftTableColumnDefs([]);
      setRightTableColumnDefs([]);
    }
  }, [customFieldsData, getColumnDefs, getRowsData, selectedViewId, t, tablesData]);

  const centerTableColumns: ColDef[] = useMemo(
    () => (customFieldsData ? getCenterColDefs() : []),
    [customFieldsData, t],
  );

  useEffect(() => {
    if (
      tablesData &&
      customFieldsData &&
      leftTableColumnDefs &&
      rightTableColumnDefs &&
      !!dataProductSummary?.productOptimalStockProductSummary
    ) {
      const getCenterCellClassName = (params: CellClassParams<ExceptionOptimalStockDto>) =>
        `${
          includedStoresRows?.find((row: any) => row.storeCode === params?.data?.storeCode)
            ? ' product-included-store'
            : ''
        } ${
          excludedStoresRows?.find((row: any) => row.storeCode === params?.data?.storeCode)
            ? ' product-excluded-store'
            : ''
        }${
          params?.data?.isIncluded === null ||
          (params?.data?.isIncluded === undefined ? ' not-included-nor-excluded-store' : '')
        }${params?.data?.isIncluded === true ? ' included-store' : ''}${
          params?.data?.isIncluded === false ? ' excluded-store' : ''
        }`;

      const centerDefs = getVisibleColDefs({ allColDefs: centerTableColumns, getView }).map((colDef) => ({
        ...colDef,
        cellClass: getCenterCellClassName,
      }));

      setColumnDefs([...hiddenExportColumns, ...leftTableColumnDefs, ...centerDefs, ...rightTableColumnDefs]);
    } else {
      leftTableColumnDefs && rightTableColumnDefs && setColumnDefs([...leftTableColumnDefs, ...rightTableColumnDefs]);
    }
  }, [
    centerTableColumns,
    customFieldsData,
    getView,
    t,
    tablesData,
    leftTableColumnDefs,
    rightTableColumnDefs,
    dataProductSummary,
    hiddenExportColumns,
  ]);

  useEffect(() => {
    tablesData
      ? setRowData(
          getRowsData(tablesData?.exceptionOptimalStock)?.map((value: any) => ({ ...value, ...hiddenExportData })),
        )
      : setRowData([]);
  }, [customFieldsData, getColumnDefs, getRowsData, selectedViewId, t, tablesData]);

  useEffect(() => {
    setSelectedStoreIds(selectedNodes?.map((node) => node?.data?.id));
  }, [selectedNodes]);

  const handleRowSelection = useCallback(
    (props: RowSelectedEvent, gridName: GridName) => {
      const currentSelectedNodes = props!.api.getSelectedNodes();
      const firstSelectedNode = currentSelectedNodes[0];

      if (firstSelectedNode) {
        const selectedRowRawData = tablesData?.exceptionOptimalStock?.find(
          (row: SelectedRowCellProps) => row.storeId === firstSelectedNode.data.id,
        );

        if (selectedRowRawData.rightTable) {
          const selectedRowState = selectedRowRawData?.rightTable.map((cell: HeaderValuePair) => {
            const articleId = tablesData?.sizeSystemHeaders?.find(
              (header: ExceptionOptimalStockHeaderDto) => header.headerId === cell.headerId,
            ).articleId;

            return {
              storeId: firstSelectedNode.data.id,
              articleId: articleId,
              headerId: cell.headerId,
              value: cell.value,
            };
          });

          setSelectedRowData(selectedRowState);

          isOptimalStockSelected && setSelectedNodes((currentSelectedNodes as unknown as RowNode[]) || null);
        }
      } else {
        setSelectedRowData([]);
        setSelectedNodes([]);
      }

      handleSelectionChanged(gridName);
    },
    [handleSelectionChanged, isOptimalStockSelected, tablesData],
  );

  const getEditableCols = useCallback(() => {
    return rightTableColumnDefs?.filter((colDef) => colDef.editable && !colDef.hide) || [];
  }, [rightTableColumnDefs]);

  useEffect(() => {
    if (!isGridReady || !isOptimalStockSelected || selectedNodes?.length) return;
    const editableColsData: BulkEditorColsData = {};

    getEditableCols().forEach((colDef) => {
      editableColsData[colDef.field!] = null;
    });

    setBulkRowsEditorColsData(editableColsData);
  }, [getEditableCols, isGridReady, isOptimalStockSelected, selectedNodes?.length]);

  useEffect(() => {
    if (isGridReady && gridRef.current && columnDefs) {
      const handleResize = () => {
        gridRef?.current?.api.sizeColumnsToFit();
        const columnState = gridRef?.current?.columnApi?.getColumnState();
        const sizesWidth = columnState?.find((state) => state.colId.includes('right-'))?.width;
        const totalWidth = columnState?.find((state) => state.colId === 'rightTotal')?.width;
        sizesWidth && totalWidth && setRightTableColWidths({ totalCell: totalWidth, sizeCells: sizesWidth });
      };
      handleResize();
      window.addEventListener('resize', handleResize);
    }
  }, [gridRef, isGridReady, columnDefs]);

  const bulkEditableFields = useMemo(() => Object.keys(bulkRowsEditorColsData), [bulkRowsEditorColsData]);

  const lastSelectedNodesRef = useRef<RowNode[] | null>(null);
  // on rows select
  useEffect(() => {
    if (
      !isOptimalStockSelected ||
      !selectedNodes?.length ||
      !bulkRowsEditorColsData ||
      !rightTableColumnDefs ||
      isEqual(lastSelectedNodesRef.current, selectedNodes)
    )
      return;

    const newBulkEditorColsData = cloneDeep(bulkRowsEditorColsData);
    Object.keys(newBulkEditorColsData).forEach((field) => {
      newBulkEditorColsData[field] = null;
    });

    bulkEditableFields.forEach((field) => {
      selectedNodes.forEach((rowNode) => {
        if (newBulkEditorColsData[field] === null) {
          newBulkEditorColsData[field] = rowNode.data[field];
        } else if (newBulkEditorColsData[field] !== rowNode.data[field]) {
          newBulkEditorColsData[field] = 0;
        }
      });
    });

    setBulkRowsEditorColsData(newBulkEditorColsData);
    lastSelectedNodesRef.current = selectedNodes;
  }, [bulkEditableFields, bulkRowsEditorColsData, isOptimalStockSelected, rightTableColumnDefs, selectedNodes]);

  const bulkEditFieldApplyChange = useCallback(
    async (e: any) => {
      if (e.target.value?.trim() === '') return;

      const value = Number(e.target.value);

      if (isNaN(value)) return;

      const getRowData = (row: RowNode) => {
        const storeId = row?.data.id;
        const colId = e.target.name;
        const headerId = Number(e.target.name.split('right-')[1]);
        const articleId = tablesData?.sizeSystemHeaders?.find(
          (header: ExceptionOptimalStockHeaderDto) => header.headerId === headerId,
        )?.articleId;

        return { storeId, articleId, colId };
      };

      const bulkMutationData: ExceptionOptimalStockUpdateInput[] = [];

      selectedNodes?.forEach((selectedRow) => {
        const { articleId, storeId, colId } = getRowData(selectedRow);
        const initialRowData = rowData?.find((row) => row.id === Number(selectedRow.id));
        const hasNewValue = initialRowData?.[colId as keyof RowsProps] !== value;
        if (hasNewValue && e.type === 'blur') {
          bulkMutationData.push({ articleId, storeId, optimalStock: value });
        } else if (e.type !== 'blur') bulkMutationData.push({ articleId, storeId, optimalStock: value });
      });

      if (bulkMutationData.length) {
        setLoadingBulkStoreOptimalStockOverride(true);
        await updateBulkStoreOptimalStockOverrideMutation({
          variables: { exceptionOptimalStocks: bulkMutationData },
        });

        await refetchData();
        setLoadingBulkStoreOptimalStockOverride(false);
      }
    },
    [refetchData, rowData, selectedNodes, tablesData?.sizeSystemHeaders, updateBulkStoreOptimalStockOverrideMutation],
  );

  const excelStyles: ExcelStyle[] = [
    {
      id: 'stringType',
      dataType: 'String',
    },
  ];

  return (
    <VerticalPageContent>
      <ViewsContainer>
        <Views flatColDefs={centerTableColumns} />
        <ClearFiltersButton style={{ marginLeft: 10 }} gridRefs={gridRef} hasFilters={hasFilters} />
      </ViewsContainer>

      {!errorExceptionOptimalStockTable && (
        <ConciseGridContainer isGridReady={isGridReady}>
          <GridsContainer ref={gridsContainerRef}>
            <AgGridReact
              {...GRID_DEFAULT_PROPS}
              containerStyle={{ width: '100%' }}
              headerHeight={45}
              rowHeight={22}
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={rowData}
              pinnedBottomRowData={createTotalRowData}
              onGridReady={() => setIsGridReady(true)}
              onSelectionChanged={(e: RowSelectedEvent) => handleRowSelection(e, 'exceptions')}
              onSortChanged={(e) => handleSortChanged('exceptions', e)}
              onFilterChanged={(e) => {
                handleFilterChanged('exceptions');
                setHasFilters(hasFiltersChanged(e));
              }}
              getRowId={getRowId}
              getRowClass={getMiddleGridRowClass}
              suppressCellFocus
              onCellEditingStarted={onCellEditingStarted}
              onCellEditingStopped={onCellEditingStopped}
              rowSelection="multiple"
              animateRows={false}
              sideBar={false}
              isRowSelectable={(rowNode: IRowNode<ExceptionOptimalStockDto>) =>
                (rowNode?.data?.isIncluded && rowNode.data.storeStrength !== 0) || false
              }
              getContextMenuItems={getContextMenu}
              defaultExcelExportParams={{
                fileName: `Product exceptions_OS_${moment(new Date()).local().format('DD/MM/YYYY')}`,
                allColumns: true,
              }}
              excelStyles={excelStyles}
            />
          </GridsContainer>

          {isOptimalStockSelected && bulkEditableFields?.length && rightTableCellColWidths ? (
            <OverridingContainer>
              <div className="label">{t('exceptionTable.footer_table_text')}:</div>

              <div className="inputsContainer">
                {bulkEditableFields.map((field) => (
                  <div className="inputContainer" style={{ width: rightTableCellColWidths.sizeCells }} key={field}>
                    <Input
                      name={field}
                      autoComplete="off"
                      onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                          bulkEditFieldApplyChange(e);
                        } else {
                          !isAllowedNumericFieldKey(i18n.language, e) && e.preventDefault();
                        }
                      }}
                      onChange={(e) =>
                        setBulkRowsEditorColsData((prev) => ({ ...prev, [field]: Number(e.target.value) }))
                      }
                      onFocus={(e) => {
                        e.target.select();
                      }}
                      onBlur={bulkEditFieldApplyChange}
                      disabled={
                        !selectedNodes?.length ||
                        !(permissions as PermissionsEnum[])?.includes(PermissionsEnum.ProductPerformanceDetailsEdit) ||
                        isProductArchived
                      }
                      className="input"
                    />
                  </div>
                ))}
                <div style={{ width: rightTableCellColWidths.totalCell }} className="dummyTotal spinner">
                  {loadingBulkStoreOptimalStockOverride && <Spinner size="small" />}
                </div>
                <div className="dummyActions" />
              </div>
            </OverridingContainer>
          ) : null}
        </ConciseGridContainer>
      )}

      <ConfirmModal
        customTitle={
          isModalOpen.deleteRowOverridesModal
            ? t('exceptionTable.delete_row_overrides_modal_message')
            : t('exceptionTable.delete_all_overrides_modal_message')
        }
        onOk={isModalOpen.deleteRowOverridesModal ? handleDeleteRowOverrides : handleDeleteSelectedOverrides}
        open={isModalOpen.deleteSelectedOverridesModal || isModalOpen.deleteRowOverridesModal}
        onCancel={
          isModalOpen.deleteSelectedOverridesModal
            ? hideModal['DELETE_SELECTED_OVERRIDES_MODAL']
            : hideModal['DELETE_ROW_OVERRIDES_MODAL']
        }
      />
    </VerticalPageContent>
  );
};

export default ExceptionsTable;
