import React, { useMemo, useContext, useCallback } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { ProductContext, StoreManagementContext } from 'context';
import { useTranslation } from 'react-i18next';

import { numberWithSpaceFormatter } from 'components/AgGridComponents/valueFormatters';
import { Spinner, NakedButton } from 'components/UI';
import { COLUMN_TYPES } from 'constants/gridConstants';
import { useRemoveStoreOptimalStockExceptionMutation } from 'services/graphql/main';
import { useError } from 'services/utils';

import StyledModal from '../StyledModal/StyledModal';
import { AdaptiveGridResizer } from './styles';

const StoreManagementModal: React.FC<any> = ({ isOpen, handleCancel }) => {
  const { t } = useTranslation('translation');
  const { addError } = useError();

  const { productId } = useContext(ProductContext);
  const {
    includedStoresRows,
    includedStoresLoading,
    includedStoresRefetch,
    excludedStoresRows,
    excludedStoresLoading,
    excludedStoresRefetch,
  } = useContext(StoreManagementContext);

  const [removeStoreOptimalStockException] = useRemoveStoreOptimalStockExceptionMutation({
    onError: (err) => addError(err, 'warning'),
  });

  const handleRemoveRow = useCallback(
    async (storeId: number) => {
      if (productId) {
        await removeStoreOptimalStockException({
          variables: { productId: productId, storeId },
        });

        await includedStoresRefetch();
        await excludedStoresRefetch();
      }
    },
    [excludedStoresRefetch, includedStoresRefetch, productId, removeStoreOptimalStockException],
  );

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        field: 'storeCode',
        headerName: t('exceptionTable.store_code'),
      },
      {
        field: 'storeName',
        headerName: t('exceptionTable.store_name'),
      },
      {
        field: 'storeGroupName',
        headerName: t('exceptionTable.store_group'),
      },
      {
        field: 'storeStrength',
        headerName: t('exceptionTable.store_strength'),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        suppressMenu: true,
        sortable: false,
        cellStyle: { textAlign: 'center' },
        maxWidth: 60,
        editable: false,
        cellRenderer: (params: ICellRendererParams) => {
          return (
            <NakedButton type="text" onClick={() => handleRemoveRow(params.data.storeId)}>
              <DeleteOutlined style={{ cursor: 'pointer', textAlign: 'center' }} />
            </NakedButton>
          );
        },
        pinned: 'right',
      },
    ],
    [handleRemoveRow, t],
  );

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100,
      filter: true,
      suppressMovable: true,
      sortable: true,
    };
  }, []);

  return (
    <StyledModal
      title={t('exceptionTable.exceptional_store_management')}
      open={isOpen}
      onCancel={handleCancel}
      footer={null}
      width="75%"
      centered={true}
    >
      {includedStoresLoading || excludedStoresLoading ? (
        <Spinner size="large" />
      ) : includedStoresRows && excludedStoresRows ? (
        <AdaptiveGridResizer>
          <div style={{ width: '100%', marginBottom: 30, height: 'auto' }}>
            <h3 style={{ color: '#ffffff' }}>{t('exceptionTable.outside_of_perimeter')}</h3>
            <AgGridReact
              containerStyle={{ width: '100%', marginTop: 15, marginBottom: 30, height: 'auto' }}
              columnDefs={columnDefs}
              rowData={includedStoresRows}
              defaultColDef={defaultColDef}
              domLayout={'autoHeight'}
              suppressRowVirtualisation={true}
              stopEditingWhenCellsLoseFocus={true}
              rowSelection={'single'}
              columnTypes={COLUMN_TYPES}
            />
          </div>
          <div style={{ width: '100%', marginBottom: 30, height: 'auto' }}>
            <h3 style={{ color: '#ffffff' }}>{t('exceptionTable.excluded')}</h3>
            <AgGridReact
              containerStyle={{ width: '100%', marginTop: 15, marginBottom: 30, height: 'auto' }}
              columnDefs={columnDefs}
              rowData={excludedStoresRows}
              defaultColDef={defaultColDef}
              domLayout={'autoHeight'}
              suppressRowVirtualisation={true}
              stopEditingWhenCellsLoseFocus={true}
              rowSelection={'single'}
              columnTypes={COLUMN_TYPES}
            />
          </div>
        </AdaptiveGridResizer>
      ) : null}
    </StyledModal>
  );
};

export default StoreManagementModal;
