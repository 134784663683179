import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { CloseCircleOutlined, DeleteOutlined, EditFilled } from '@ant-design/icons';
import {
  ColDef,
  ICellRendererParams,
  RowNode,
  CellEditingStoppedEvent,
  EditableCallbackParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { isEqual, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { AlphanumericCellEditor } from 'components/AgGridComponents';
import { ConfirmModal, InputModal } from 'components/Modals';
import { NakedButton, PrimaryButton, Spinner } from 'components/UI';
import { ActionButtonsWrapper } from 'components/wrappers';
import { CENTERED_CELL_STYLE, CENTERED_HEADER_CLASS, COLUMN_TYPES } from 'constants/gridConstants';
import { VALIDATIONS_TYPES } from 'constants/validations';
import { validateGridCustom } from 'helpers/validations';
import {
  ProductCustomFieldsDto,
  useProductCustomFieldsQuery,
  useUpdateProductCustomFieldMutation,
} from 'services/graphql/main';
import { useError } from 'services/utils';

import {
  ColumnProps,
  GridRefsProps,
  RowsProps,
  SelectedRowForDeletionProps,
  SelectedTableForDeletionProps,
} from '../../common/types';
import { StyledTablesWrapper } from './styles';

const ProductCustomFields: React.FC = () => {
  const { t } = useTranslation('translation');
  const { addCustomError, addError } = useError();

  const initialGridData = useRef<ProductCustomFieldsDto | null>(null);
  const gridRefs: GridRefsProps = {
    grid0: useRef<AgGridReact<ProductCustomFieldsDto>>(null),
    grid1: useRef<AgGridReact<ProductCustomFieldsDto>>(null),
    grid2: useRef<AgGridReact<ProductCustomFieldsDto>>(null),
    grid3: useRef<AgGridReact<ProductCustomFieldsDto>>(null),
    grid4: useRef<AgGridReact<ProductCustomFieldsDto>>(null),
  };

  const { loading, data, refetch } = useProductCustomFieldsQuery({ onError: (err) => addError(err, 'error') });
  const [updateCustomFields, { error }] = useUpdateProductCustomFieldMutation({
    onError: (err) => addError(err, 'warning'),
  });

  const [isModalOpen, setIsModalOpen] = useState({
    deleteRowModal: false,
    deleteAllRows: false,
    editColumnHeader: false,
  });

  const [columnDefs, setColumnDefs] = useState<ColumnProps[] | null | undefined>();
  const [rowsRawData, setRowRawData] = useState<ProductCustomFieldsDto[]>();

  const [headerName, setHeaderName] = useState('');
  const [headerData, setHeaderData] = useState({ tableIndex: null, columnField: '' });

  const [selectedRowForDeletion, setSelectedRowForDeletion] = useState<SelectedRowForDeletionProps>({
    id: null,
    tableIndex: null,
  });
  const [selectedTableForDeletion, setSelectedTableForDeletion] = useState<SelectedTableForDeletionProps>({
    tableIndex: null,
  });

  const [mutationState, setMutationState] = useState<ProductCustomFieldsDto | null>(null);
  const hasUpdate = useMemo(() => !isEmpty(mutationState), [mutationState]);

  const [isUpdate, setIsUpdate] = useState({ isCreateRowActive: false, isCellActive: false });
  const [errors, setErrors] = useState<boolean | null>(null);

  const showModal = useMemo(
    () => ({
      EDIT_COLUMN_HEADER: () => setIsModalOpen((prevState) => ({ ...prevState, editColumnHeader: true })),
      DELETE_ROW_MODAL: () => setIsModalOpen((prevState) => ({ ...prevState, deleteRowModal: true })),
      DELETE_ALL_ROWS_MODAL: () => setIsModalOpen((prevState) => ({ ...prevState, deleteAllRows: true })),
    }),
    [],
  );

  const hideModal = useMemo(
    () => ({
      EDIT_COLUMN_HEADER: () => {
        setIsModalOpen((prevState) => ({ ...prevState, editColumnHeader: false }));
        setHeaderName('');
        setHeaderData({ tableIndex: null, columnField: '' });
      },
      DELETE_ROW_MODAL: () => setIsModalOpen((prevState) => ({ ...prevState, deleteRowModal: false })),
      DELETE_ALL_ROWS_MODAL: () => setIsModalOpen((prevState) => ({ ...prevState, deleteAllRows: false })),
    }),
    [],
  );

  const handleSaveMutation = useCallback(async () => {
    const { DUPLICATES, EMPTY_CELL, LENGTH } = VALIDATIONS_TYPES;

    const validations = [DUPLICATES, EMPTY_CELL, LENGTH];

    for (const gridRef in gridRefs) {
      const gridData = gridRefs[gridRef].current!.api.getRenderedNodes().map((x: any) => {
        const { Code, Label } = x.data;

        return { Code, Label };
      });

      const validation = validateGridCustom(validations, gridData);

      if (validation?.isValid === false) {
        addCustomError({ message: validation?.code }, 'warning');

        return;
      }
    }

    if (mutationState) {
      await updateCustomFields({
        variables: {
          productCustomField: mutationState,
        },
      });

      await refetch();

      setMutationState(null);
    }

    !errors &&
      setIsUpdate((prevState) => ({
        ...prevState,
        isCreateRowActive: false,
      }));
  }, [addCustomError, errors, gridRefs, mutationState, refetch, updateCustomFields]);

  const updateGridData = useCallback(() => {
    const gridData: GridRefsProps = {};

    for (const key in gridRefs) {
      const currGrid = gridRefs[key];
      const renderedNodes = currGrid.current!.api.getRenderedNodes();

      const currGridValues = renderedNodes.map((x: RowNode) => {
        const { Code, Label } = x.data;

        return { Code, Label };
      });

      if (renderedNodes[0]) {
        const currentCustomField =
          renderedNodes[0].data.pairToHeaderKey || `customFieldName${Number(key.split('grid')[1]) + 1}`;

        gridData[currentCustomField?.replace('Name', 'Values')] = JSON.stringify(currGridValues)
          .split('","')
          .join('", "');
      }
    }

    return gridData;
  }, [gridRefs]);

  const handleAddRow = (tableIndex: number) => {
    const currGrid = gridRefs[`grid${tableIndex}`];

    const column = currGrid.current.columnApi.getAllGridColumns()[0].colDef;

    const constructorObject: { [key: string]: string } = {
      [column.field]: '',
    };

    setIsUpdate((prevState) => ({
      ...prevState,
      isCreateRowActive: true,
    }));

    currGrid.current!.api.applyTransaction({
      add: [constructorObject],
    });

    const gridData = currGrid.current!.api.getRenderedNodes();

    setTimeout(() => {
      currGrid.current!.api.startEditingCell({
        rowIndex: gridData.length - 1,
        colKey: column.field,
      });
    }, 1000);
  };

  const handleRemoveAllRows = useCallback(async () => {
    const currGrid = gridRefs[`grid${selectedTableForDeletion.tableIndex}`]
      .current!.api.getRenderedNodes()
      .map((x: RowNode) => x.data);

    gridRefs[`grid${selectedTableForDeletion.tableIndex}`].current?.api.applyTransaction({
      remove: currGrid,
    });

    const gridData = updateGridData();
    const column =
      gridRefs[`grid${selectedTableForDeletion.tableIndex}`].current.columnApi.getAllGridColumns()[0].colDef;

    gridData[column.field] = 'Custom Field Name' + column.field.split('customFieldName')[1];

    const res = await updateCustomFields({
      variables: {
        productCustomField: {
          [`customFieldName${Number(selectedTableForDeletion.tableIndex) + 1}`]: null,
          [`customFieldValues${Number(selectedTableForDeletion.tableIndex) + 1}`]: JSON.stringify([]),
        },
      },
    });

    if (res.errors) {
      gridRefs[`grid${selectedTableForDeletion.tableIndex}`].current?.api.applyTransaction({
        add: currGrid,
      });
    }

    await refetch();
    hideModal['DELETE_ALL_ROWS_MODAL']();
    setIsUpdate((prevState) => ({
      ...prevState,
      isCreateRowActive: false,
    }));
  }, [gridRefs, hideModal, refetch, selectedTableForDeletion.tableIndex, updateCustomFields, updateGridData]);

  const RemoveAllRows = (props: GridRefsProps) => {
    const currGrid = gridRefs[`grid${props.column.colDef.headerClass.split('table-index')[1]}`]
      .current!.api.getRenderedNodes()
      .map((x: RowNode) => x.data);

    const handlePrepareRemoveAllRows = async () => {
      setSelectedTableForDeletion({ tableIndex: props.column.colDef.headerClass.split('table-index')[1] });
      showModal['DELETE_ALL_ROWS_MODAL']();
    };

    return (
      currGrid.length > 0 && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <NakedButton
            disabled={isUpdate.isCreateRowActive || hasUpdate}
            type="text"
            onClick={handlePrepareRemoveAllRows}
          >
            <CloseCircleOutlined style={{ cursor: 'pointer' }} />
          </NakedButton>
        </div>
      )
    );
  };

  const handleRemoveRow = useCallback(async () => {
    if (selectedRowForDeletion.id !== null && selectedRowForDeletion.tableIndex !== null) {
      const currGrid = gridRefs[`grid${selectedRowForDeletion.tableIndex}`]
        .current!.api.getRenderedNodes()
        .map((x: RowNode) => x.data);

      gridRefs[`grid${selectedRowForDeletion.tableIndex}`].current?.api.applyTransaction({
        remove: [currGrid[selectedRowForDeletion.id]],
      });

      const gridData = updateGridData();

      const requestPropertyName = `customFieldValues${Number(selectedRowForDeletion?.tableIndex) + 1}`;

      const res = await updateCustomFields({
        variables: {
          productCustomField: {
            [requestPropertyName]: gridData[requestPropertyName],
          },
        },
      });

      if (res.errors) {
        gridRefs[`grid${selectedRowForDeletion.tableIndex}`].current?.api.applyTransaction({
          addIndex: selectedRowForDeletion.id,
          add: [currGrid[selectedRowForDeletion.id]],
        });
      }

      await refetch();
    }

    setSelectedRowForDeletion({ id: null, tableIndex: null });
    hideModal['DELETE_ROW_MODAL']();
    setIsUpdate((prevState) => ({
      ...prevState,
      isCreateRowActive: false,
    }));
  }, [
    gridRefs,
    hideModal,
    refetch,
    selectedRowForDeletion.id,
    selectedRowForDeletion.tableIndex,
    updateCustomFields,
    updateGridData,
  ]);

  const RemoveRenderer = (props: ICellRendererParams) => {
    const handlePrepareRemoveRow = () => {
      setSelectedRowForDeletion({ id: props.rowIndex, tableIndex: props.data.tableIndex });
      showModal['DELETE_ROW_MODAL']();
    };

    return (
      <NakedButton disabled={isUpdate.isCreateRowActive || hasUpdate} type="text" onClick={handlePrepareRemoveRow}>
        <DeleteOutlined style={{ cursor: 'pointer', textAlign: 'center' }} />
      </NakedButton>
    );
  };

  const onCellEditingStarted = useCallback(() => {
    setIsUpdate((prevState) => ({
      ...prevState,
      isCellActive: true,
    }));
  }, []);

  const onCellEditingStopped = useCallback(
    (event: CellEditingStoppedEvent) => {
      const gridData: GridRefsProps = updateGridData();

      Object.keys(gridData).forEach((currentCustomPropertyValuesKey) => {
        const updateRequestPropertyName = currentCustomPropertyValuesKey;

        const isEqualObj = isEqual(
          initialGridData.current![updateRequestPropertyName as keyof ProductCustomFieldsDto],
          gridData[updateRequestPropertyName],
        );

        if (!isEqualObj) {
          setMutationState((prevState) => ({
            ...prevState,
            [updateRequestPropertyName]: gridData[updateRequestPropertyName],
          }));
        } else {
          mutationState && delete mutationState[updateRequestPropertyName as keyof ProductCustomFieldsDto];

          if (!event.data.tableIndex) {
            const requestCreatePropertyName = `customFieldValues${event.column.getColId().split('customFieldName')[1]}`;

            setMutationState((prevState) => ({
              ...prevState,
              [requestCreatePropertyName]: gridData[requestCreatePropertyName],
            }));
          }
        }
      });

      setIsUpdate((prevState) => ({
        ...prevState,
        isCellActive: false,
      }));
    },
    [mutationState, updateGridData],
  );

  const handleEditColHeader = useCallback(async () => {
    const gridData = updateGridData();
    gridData[headerData.columnField] = headerName;
    const fieldName = 'customFieldName' + (Number(headerData?.tableIndex!) + 1);

    await updateCustomFields({
      variables: {
        productCustomField: { [fieldName]: headerName },
      },
    });

    await refetch();

    hideModal['EDIT_COLUMN_HEADER']();
  }, [
    headerData.columnField,
    headerName,
    hideModal,
    refetch,
    updateCustomFields,
    updateGridData,
    headerData?.tableIndex,
  ]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100,
      editable: true,
      suppressMovable: true,
      sortable: true,
    };
  }, []);

  const getRowsData = useCallback((rowsRawData: RowsProps[number] | null | undefined) => {
    const preparedRows: any[] = [];

    for (const key in rowsRawData) {
      if (Object.prototype.hasOwnProperty.call(rowsRawData, key)) {
        const pairToHeaderKey = `customFieldName${key.split('customFieldValues')[1]}`;
        if (key.includes('customFieldValues')) {
          const tableIndex = Number(pairToHeaderKey.split('customFieldName')[1]);
          const arrayOfValues = JSON.parse(rowsRawData[key]) || [];

          const currTableRows = arrayOfValues.map(({ Code, Label }: { Code: string; Label: string }) => {
            return { Code, Label, pairToHeaderKey, tableIndex: tableIndex - 1 };
          });

          preparedRows.push(currTableRows);
        }
      }
    }

    return preparedRows;
  }, []);

  const handleCancelButton = useCallback(() => {
    for (const gridRef in gridRefs) {
      const currGridRef = gridRefs[gridRef];

      const index = gridRef.split('grid')[1];

      const rows = getRowsData(data?.productCustomFields);
      currGridRef.current?.api.setRowData(rows[index as any]);

      setRowRawData(rows);
    }

    setIsUpdate((prevState) => ({
      ...prevState,
      isCreateRowActive: false,
      isCellActive: false,
    }));

    setMutationState(null);
  }, [data?.productCustomFields, getRowsData, gridRefs]);

  useEffect(() => {
    if (data) {
      const getColumnDefs = (colsRawData: ColumnProps[number] | null | undefined): ColumnProps[] => {
        const EditHeader = (props: GridRefsProps) => {
          const headerData = {
            tableIndex: props.columnApi.columnModel.columnDefs[2].headerClass.split('table-index')[1],
            columnField: props.columnApi.columnModel.columnDefs[1].field,
          };
          const headerName = props.headerName;

          const handlePrepareEditHeader = () => {
            setHeaderName(headerName);
            setHeaderData(headerData);

            showModal['EDIT_COLUMN_HEADER']();
          };

          return (
            <NakedButton
              disabled={isUpdate.isCreateRowActive || hasUpdate}
              type="text"
              onClick={handlePrepareEditHeader}
              style={{ width: '100%', textAlign: headerName === ' ' ? 'center' : 'left' }}
            >
              {headerName === ' ' ? (
                <EditFilled style={{ cursor: 'pointer', fontSize: '20px' }} />
              ) : (
                <span style={{ textDecoration: 'none' }}>{headerName}</span>
              )}
            </NakedButton>
          );
        };

        const preparedHeaders = [];

        for (const key in colsRawData) {
          if (Object.prototype.hasOwnProperty.call(colsRawData, key)) {
            if (key.includes('customFieldName')) preparedHeaders.push({ [key]: colsRawData[key] });
          }
        }

        const colDefs =
          preparedHeaders?.map((value: ColDef) => {
            const kvp = Object.entries(value);

            return [
              {
                field: 'Code',
                headerName: '',
                minWidth: 60,
                editable: (params: EditableCallbackParams) => {
                  return params.node.rowIndex !== null && !params.node.data.pairToHeaderKey;
                },
                cellEditor: AlphanumericCellEditor,
              },
              {
                headerComponent: EditHeader,
                headerComponentParams: { headerName: kvp[0][1] || undefined },
                field: 'Label',
                editable: (params: EditableCallbackParams) => {
                  return params.node.rowIndex !== null;
                },
              },
            ];
          }) || [];

        return colDefs;
      };

      const columns: ColumnProps[] = [...getColumnDefs(data.productCustomFields)];
      const rows: ProductCustomFieldsDto[] = getRowsData(data.productCustomFields);
      const gridData = {
        ...data.productCustomFields,
      };

      delete gridData.__typename;

      initialGridData.current = gridData;

      setColumnDefs(columns);
      setRowRawData(rows);
    } else {
      setColumnDefs([]);
      setRowRawData([]);
    }
  }, [data, getRowsData, showModal, t]);

  useEffect(() => {
    error?.graphQLErrors ? setErrors(true) : setErrors(null);
  }, [error?.graphQLErrors]);

  return loading ? (
    <Spinner size="large" />
  ) : rowsRawData ? (
    <>
      <StyledTablesWrapper>
        {columnDefs?.map((colDefs, i: number) => (
          <div key={i} style={{ display: 'flex', flexFlow: 'column wrap', flex: 'auto' }}>
            <AgGridReact
              containerStyle={{ width: '100%', marginTop: 15, marginBottom: 10, height: 'auto' }}
              ref={gridRefs[`grid${i}`]}
              columnDefs={[
                ...colDefs,
                {
                  headerComponent: RemoveAllRows,
                  headerClass: `${CENTERED_HEADER_CLASS} table-index${i}`,
                  maxWidth: 60,
                  cellStyle: CENTERED_CELL_STYLE,
                  suppressMenu: true,
                  cellRenderer: RemoveRenderer,
                },
              ]}
              rowData={rowsRawData[i] as []}
              defaultColDef={defaultColDef}
              domLayout={'autoHeight'}
              stopEditingWhenCellsLoseFocus={true}
              onCellEditingStarted={onCellEditingStarted}
              onCellEditingStopped={onCellEditingStopped}
              columnTypes={COLUMN_TYPES}
              enableRangeSelection={true}
              pinnedTopRowData={[{ Code: t('operationalSettings.code'), Label: t('operationalSettings.label') }]}
            />
            <NakedButton
              disabled={isUpdate.isCreateRowActive || hasUpdate}
              type="link"
              onClick={() => handleAddRow(i)}
              text={`+${t('operationalSettings.new_subfield')}`}
              style={{ color: '#ffffff' }}
            ></NakedButton>
          </div>
        ))}
      </StyledTablesWrapper>

      <ActionButtonsWrapper>
        {isUpdate.isCreateRowActive || hasUpdate ? (
          <>
            <NakedButton
              type="text"
              onClick={handleCancelButton}
              textKey="common.cancel"
              style={{ color: '#FFFFFF' }}
            />

            <PrimaryButton
              onClick={handleSaveMutation}
              textKey="common.save"
              disabled={isUpdate.isCellActive}
            ></PrimaryButton>
          </>
        ) : null}
      </ActionButtonsWrapper>

      <InputModal
        customTitle={t('operationalSettings.header_name')}
        onOk={handleEditColHeader}
        state={headerName}
        setState={setHeaderName}
        open={isModalOpen.editColumnHeader}
        onCancel={hideModal['EDIT_COLUMN_HEADER']}
      />

      <ConfirmModal
        customTitle={
          isModalOpen.deleteAllRows
            ? t('operationalSettings.delete_all_rows_warning_message')
            : t('operationalSettings.delete_row_warning_message')
        }
        onOk={isModalOpen.deleteAllRows ? handleRemoveAllRows : handleRemoveRow}
        open={isModalOpen.deleteAllRows || isModalOpen.deleteRowModal}
        onCancel={isModalOpen.deleteAllRows ? hideModal['DELETE_ALL_ROWS_MODAL'] : hideModal['DELETE_ROW_MODAL']}
      />
    </>
  ) : null;
};

export default ProductCustomFields;
