import styled from 'styled-components';

export const PageWrapper = styled.div`
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const UnauthorizedText = styled.h2`
  margin: 40px 0;
  font-weight: bold;
  color: ${(p) => p.theme.white};
`;
