import React from 'react';

import { useTranslation } from 'react-i18next';

import { CollectionContextProvider } from 'context/collection-context';

import AssociatedGroupsTable from './components/AssociatedGroupsTable/AssociatedGroupsTable';
import CollectionsTable from './components/CollectionsTable/CollectionsTable';
import { SectionTitle } from './styles';

const CollectionsManagement: React.FC = () => {
  const { t } = useTranslation('translation');

  return (
    <CollectionContextProvider>
      <SectionTitle>{t('operationalSettings.collection_management')}</SectionTitle>
      <CollectionsTable />

      <SectionTitle style={{ marginTop: 10 }}>{t('operationalSettings.associated_groups_and_modules')}</SectionTitle>
      <AssociatedGroupsTable />
    </CollectionContextProvider>
  );
};

export default CollectionsManagement;
