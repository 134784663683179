import { Form } from 'antd';
import styled from 'styled-components';

export const StyledItem = styled(Form.Item)`
  width: 250px;
  margin-bottom: 0;

  label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 22px;
    gap: 10px;
  }

  label > .labelText {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  label svg {
    color: #bbb;
    font-size: 16px;
  }
  label svg:hover {
    color: #333;
    cursor: pointer;
  }
`;
