import styled from 'styled-components';

import { CONDITIONAL_FORMATTING_COLORS } from 'screens/ProductPerformance/constants';

const { RED, BLUE, LIGHT_GREEN, LIGHT_RED, PINK, ORANGE, YELLOW, SHOWCASE_PRODUCT } = CONDITIONAL_FORMATTING_COLORS;

export const GridsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  //.ag-row,
  //.ag-header {
  //  font-size: 9px;
  //  line-height: 1;
  //}
  //
  //.ag-cell {
  //  line-height: 1;
  //  display: flex;
  //  align-items: center;
  //  justify-content: end;
  //}
  //
  //.ag-header-cell .ag-header-cell-text {
  //  -webkit-line-clamp: 3;
  //  text-align: center;
  //}
  //
  .ag-header-cell-comp-wrapper {
    height: 100%;
  }

  .ag-cell-label-container {
    flex-direction: column-reverse;
    justify-content: space-between;
  }

  .ag-center-cols-clipper,
  .ag-header-viewport {
    box-shadow: 0 0 4px 1px #999;
    z-index: 1;
  }

  .ag-header-container {
    background-color: #fff;
  }

  .ag-center-cols-container .ag-row {
    background-color: #f5fbff;
  }

  .ag-center-cols-container .ag-row-odd {
    background-color: #deedf5;
  }

  .ag-center-cols-container .showcaseProductRow {
    background: ${SHOWCASE_PRODUCT};
    color: #fff;
  }

  .boldCell {
    font-weight: bold;
  }

  .normalDiscountSales {
    color: ${BLUE};
  }

  .highDiscountSales {
    color: ${RED};
  }

  .bgColoredCell {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    //position: absolute;
    //top: 0;
    //left: 0;
    //padding: 0 5px;
  }

  .ppProposedGtValidated {
    background-color: ${LIGHT_GREEN};
  }

  .ppValidatedGtProposed,
  .estPickingGtTotalStock {
    background-color: ${LIGHT_RED};
  }

  .warehouseCoverageLeq1 {
    background-color: ${PINK};
  }

  .warehouseCoverageLeq2 {
    background-color: ${ORANGE};
  }

  .warehouseCoverageLeq3 {
    background-color: ${YELLOW};
  }

  .archived-product {
    background-color: ${(p) => p.theme.light_gray};
    opacity: 40%;
  }

  .storefront-product:not([class*='ag-row-hover']) {
    background-color: #90a7d1;
    color: ${(p) => p.theme.white};
  }
`;

export const CurrentWeekLabel = styled.p`
  margin: 0;
  color: ${(p) => p.theme.white};
`;
