import React, { useCallback, useEffect, useState } from 'react';

import { useLocalStorage } from 'react-use';

import { FullscreenSpinner } from 'components/UI';
import { Season } from 'services/graphql/main';
import { useGetSeasonsQuery } from 'services/graphql/main';
import { useError } from 'services/utils';

type SelectedSeason = Season | null;
type SelectSeason = (value: SelectedSeason) => void;

type SeasonContextType = {
  activeSeasons: SelectedSeason[] | null | undefined;
  refetch: () => void;
  seasons: SelectedSeason[] | null | undefined;
  selectSeason: SelectSeason;
  selectedSeason: SelectedSeason;
};

export const SeasonContext = React.createContext<SeasonContextType>({} as SeasonContextType);

type ContextProviderType = {
  children: React.ReactNode;
};

const lsSelectedSeasonIdItemName = 'selectedSeasonId';

export const SeasonContextProvider: React.FC<ContextProviderType> = ({ children }) => {
  const [lsSelectedSeasonId, lsSetSelectedSeasonId] = useLocalStorage<number | undefined>(lsSelectedSeasonIdItemName);

  const [seasons, setSeasons] = useState<SelectedSeason[] | null>();
  const [activeSeasons, setActiveSeasons] = useState<SelectedSeason[] | null>();
  const [selectedSeason, setSelectedSeason] = useState<SelectedSeason>(null);

  const { addError } = useError();
  const { data, loading, refetch } = useGetSeasonsQuery({ onError: (err) => addError(err, 'error') });

  useEffect(() => {
    if (data) {
      data.seasons && setSeasons([...data.seasons, { id: -1, name: 'All', isActive: true }]);

      const seasonsActive = data.seasons?.filter((season) => season.isActive);
      setActiveSeasons(seasonsActive);

      if (seasonsActive?.length && !selectedSeason) {
        const selectedSeason = lsSelectedSeasonId
          ? seasonsActive?.find((season) => season?.id === lsSelectedSeasonId) || seasonsActive[0]
          : seasonsActive[0];

        setSelectedSeason(selectedSeason);
      }
    }
  }, [data, lsSelectedSeasonId, selectedSeason]);

  const selectSeason: SelectSeason = useCallback(
    (season) => {
      setSelectedSeason(season);
      lsSetSelectedSeasonId(season?.id);
    },
    [lsSetSelectedSeasonId],
  );

  return (
    <SeasonContext.Provider
      value={{
        seasons,
        activeSeasons,
        refetch,
        selectedSeason,
        selectSeason,
      }}
    >
      {loading ? <FullscreenSpinner /> : children}
    </SeasonContext.Provider>
  );
};
