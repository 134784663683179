import React from 'react';

import Unauthorized from 'screens/Unauthorized/Unauthorized';
import { getCacheVal, isValidToken } from 'services/utils';

type Props = {
  children: JSX.Element;
};

const ProtectedRoute: React.FC<Props> = ({ children }) => {
  const isLoggedIn = isValidToken(getCacheVal('token'));

  if (!isLoggedIn) {
    return <Unauthorized />;
  }

  return children;
};

export default ProtectedRoute;
