import { useContext } from 'react';

import { SeasonContext, UniverseContext } from 'context';

export const useCommonData = () => {
  const { selectedUniverse } = useContext(UniverseContext);
  const { selectedSeason } = useContext(SeasonContext);

  return {
    seasonId: selectedSeason?.id,
    seasonName: selectedSeason?.name,
    seasonCode: selectedSeason?.code,
    universeId: selectedUniverse?.id,
    universeName: selectedUniverse?.name,
    universeCode: selectedUniverse.code,
  };
};
