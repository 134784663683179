export const calculateData = (rawData: any[]) => {
  const initialValue = {
    capacitySumTotal: 0,
    capacityRatioSumTotal: 0,
    salesQuantityLastWeekSumTotal: 0,
    stockQuantityLastWeekSumTotal: 0,
    coverageOneWeekBeforeSumTotal: 0,
    coverageTwoWeeksBeforeSumTotal: 0,
    coverageThreeWeeksBeforeSumTotal: 0,
    averageCoverageSumTotal: 0,
    optimalStockSumTotal: 0,
    numberOfProductsWithOptimalStockSumTotal: 0,
    numberOfProductsWithSalesLastWeekSumTotal: 0,
    numberOfProductsWithStockSumTotal: 0,
    optimalStockOneWeekBeforeSumTotal: 0,
    estimatedPickingSumTotal: 0,
  };

  return rawData?.reduce((previousValue: any, currentValue: any) => {
    return {
      capacitySumTotal: Number(previousValue?.capacitySumTotal) + Number(currentValue.capacity),
      capacityRatioSumTotal: Number(previousValue?.capacityRatioSumTotal) + Number(currentValue.capacityRatio * 100),
      salesQuantityLastWeekSumTotal:
        Number(previousValue?.salesQuantityLastWeekSumTotal) + Number(currentValue.salesQuantityLastWeek),
      stockQuantityLastWeekSumTotal:
        Number(previousValue?.stockQuantityLastWeekSumTotal) + Number(currentValue.stockQuantityLastWeek),
      coverageOneWeekBeforeSumTotal:
        Number(previousValue?.coverageOneWeekBeforeSumTotal) + Number(currentValue.coverageOneWeekBefore),
      coverageTwoWeeksBeforeSumTotal:
        Number(previousValue?.coverageTwoWeeksBeforeSumTotal) + Number(currentValue.coverageTwoWeeksBefore),
      coverageThreeWeeksBeforeSumTotal:
        Number(previousValue?.coverageThreeWeeksBeforeSumTotal) + Number(currentValue.coverageThreeWeeksBefore),
      averageCoverageSumTotal: Number(previousValue?.averageCoverageSumTotal) + Number(currentValue.averageCoverage),
      optimalStockSumTotal: Number(previousValue?.optimalStockSumTotal) + Number(currentValue.optimalStock),
      numberOfProductsWithOptimalStockSumTotal:
        Number(previousValue?.numberOfProductsWithOptimalStockSumTotal) +
        Number(currentValue.numberOfProductsWithOptimalStock),
      numberOfProductsWithSalesLastWeekSumTotal:
        Number(previousValue?.numberOfProductsWithSalesLastWeekSumTotal) +
        Number(currentValue.numberOfProductsWithSalesLastWeek),
      numberOfProductsWithStockSumTotal:
        Number(previousValue?.numberOfProductsWithStockSumTotal) + Number(currentValue.numberOfProductsWithStock),
      optimalStockOneWeekBeforeSumTotal:
        Number(previousValue?.optimalStockOneWeekBeforeSumTotal) + Number(currentValue.optimalStockOneWeekBefore),
      estimatedPickingSumTotal: Number(previousValue?.estimatedPickingSumTotal) + Number(currentValue.estimatedPicking),
    };
  }, initialValue);
};

export const calculatePermutationSummaryData = (rawData: any[]) => {
  const initialValue = {
    numberOfProductsWithStockSumTotal: 0,
    numberOfProductsWithOptimalStockSumTotal: 0,
    numberOfProductsWithSalesLastWeekSumTotal: 0,
    salesQuantityLastWeekSumTotal: 0,
    stockQuantityLastWeekSumTotal: 0,
    coverageOneWeekBeforeSumTotal: 0,
    coverageTwoWeeksBeforeSumTotal: 0,
    coverageThreeWeeksBeforeSumTotal: 0,
    averageCoverageSumTotal: 0,
    optimalStockSumTotal: 0,
    optimalStockOneWeekBeforeSumTotal: 0,
    estimatedPickingSumTotal: 0,
  };

  return rawData?.reduce((previousValue: any, currentValue: any) => {
    return {
      numberOfProductsWithStockSumTotal:
        Number(previousValue?.numberOfProductsWithStockSumTotal) + Number(currentValue.numberOfProductsWithStock),
      numberOfProductsWithOptimalStockSumTotal:
        Number(previousValue?.numberOfProductsWithOptimalStockSumTotal) +
        Number(currentValue.numberOfProductsWithOptimalStock),
      numberOfProductsWithSalesLastWeekSumTotal:
        Number(previousValue?.numberOfProductsWithSalesLastWeekSumTotal) +
        Number(currentValue.numberOfProductsWithSalesLastWeek),
      salesQuantityLastWeekSumTotal:
        Number(previousValue?.salesQuantityLastWeekSumTotal) + Number(currentValue.salesQuantityLastWeek),
      stockQuantityLastWeekSumTotal:
        Number(previousValue?.stockQuantityLastWeekSumTotal) + Number(currentValue.stockQuantityLastWeek),
      coverageOneWeekBeforeSumTotal:
        Number(previousValue?.coverageOneWeekBeforeSumTotal) + Number(currentValue.coverageOneWeekBefore),
      coverageTwoWeeksBeforeSumTotal:
        Number(previousValue?.coverageTwoWeeksBeforeSumTotal) + Number(currentValue.coverageTwoWeeksBefore),
      coverageThreeWeeksBeforeSumTotal:
        Number(previousValue?.coverageThreeWeeksBeforeSumTotal) + Number(currentValue.coverageThreeWeeksBefore),
      averageCoverageSumTotal: Number(previousValue?.averageCoverageSumTotal) + Number(currentValue.averageCoverage),
      optimalStockSumTotal: Number(previousValue?.optimalStockSumTotal) + Number(currentValue.optimalStock),
      optimalStockOneWeekBeforeSumTotal:
        Number(previousValue?.optimalStockOneWeekBeforeSumTotal) + Number(currentValue.optimalStockOneWeekBefore),
      estimatedPickingSumTotal: Number(previousValue?.estimatedPickingSumTotal) + Number(currentValue.estimatedPicking),
    };
  }, initialValue);
};
