import styled from 'styled-components';

import { Tabs } from 'components/UI';

export const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    padding-top: 0px;
    padding-bottom: 5px;
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #000000;
    }
  }

  .ant-tabs-nav,
  .ant-tabs-content-holder {
    margin: 0 !important;
  }

  .ant-tabs-ink-bar {
    background-color: #ffcf00;
  }
`;
