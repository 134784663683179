import { ValueFormatterParams } from 'ag-grid-community';
import { isNumber } from 'lodash';

export const decimalNumberFormatter =
  (digits = 1) =>
  (params: ValueFormatterParams) => {
    return params.value.toFixed(digits);
  };

export const percentFormatter = (params: ValueFormatterParams) => {
  return params.value + ' %';
};

export const currencyFormatter = (params: ValueFormatterParams) => {
  return (
    params.value
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' €'
  );
};

export const numberWithSpaceFormatter = (params: ValueFormatterParams) =>
  isNumber(params.value)
    ? params?.value % 1 != 0
      ? Number(params.value)
          .toFixed(1)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      : Number(params.value)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    : '';
export const numberWithSpaceHideZeroFormatter = (params: ValueFormatterParams) =>
  isNumber(!!params.value && params.value)
    ? params?.value % 1 != 0
      ? Number(params.value)
          .toFixed(1)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      : Number(params.value)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    : '';
export const formatNumberWithSpace = (value: number | undefined) =>
  value
    ? isNumber(value) && value % 1 != 0
      ? value
          .toFixed(1)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      : value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    : '';
