import styled from 'styled-components';

export const HintRow = styled.div`
  padding: 5px;
  display: flex;
  gap: 20px;
  align-items: center;

  p {
    margin: 0;
  }
`;

export const ColorBox = styled.div`
  height: 30px;
  width: 60px;
`;
