import { useCallback, useContext, useEffect, useRef } from 'react';

import {
  ApplyColumnStateParams,
  ColDef,
  ColumnMovedEvent,
  ColumnPinnedEvent,
  ColumnResizedEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { TableViewsContext } from 'context';
import { useLocalStorage } from 'react-use';

import { GridName } from 'types/views';

type Props = {
  gridRef?: React.RefObject<AgGridReact>;
  gridsContainerRef?: React.RefObject<HTMLDivElement>;
  ignoreSavedSorting?: boolean;
  isGridReady?: boolean;
  leftGridRef?: React.RefObject<AgGridReact>;
  middleGridRef?: React.RefObject<AgGridReact>;
  rightGridRef?: React.RefObject<AgGridReact>;
  tableName: string;
};

interface TableState {
  filters: { [key: string]: any } | undefined | null;
  sort: ApplyColumnStateParams | null;
}

const lsTableStatePrefix = 'tableState_';

export const useGridCols = ({
  leftGridRef,
  middleGridRef,
  rightGridRef,
  gridsContainerRef,
  isGridReady,
  tableName,
  gridRef,
  ignoreSavedSorting = true,
}: Props) => {
  const { moveColumn, setColumnWidth, getGridCols, getColumnsWidths, pinColumn } = useContext(TableViewsContext);

  const handleColumnResized = useCallback(
    (event: ColumnResizedEvent) => {
      if (!event.finished || event.source !== 'uiColumnDragged') return;

      const { column } = event;
      const colId = column?.getColId();
      const newWidth = column?.getActualWidth();

      colId && newWidth && setColumnWidth(colId, newWidth);
    },
    [setColumnWidth],
  );

  const handleColumnMoved = useCallback(
    (event: ColumnMovedEvent, offset = 0) => {
      if (!event.finished || event.source !== 'uiColumnMoved') return;
      const { column, toIndex } = event;
      const field = column?.getColId();

      field && typeof toIndex === 'number' && toIndex >= 0 && moveColumn(field, toIndex + offset);
    },
    [moveColumn],
  );
  const handleColumnPinned = useCallback(
    (event: ColumnPinnedEvent) => {
      const field = event.column?.getColId();

      field && pinColumn(field, event.pinned);
    },
    [pinColumn],
  );

  const getGrids = useCallback(
    (currentGridName?: GridName) => {
      switch (currentGridName) {
        case 'leftGrid':
          return {
            currentGrid: leftGridRef!.current,
            otherGrids: [middleGridRef!.current, rightGridRef!.current],
            otherTypes: ['middleGrid', 'rightGrid'],
          };
        case 'middleGrid':
          return {
            currentGrid: middleGridRef!.current,
            otherGrids: [leftGridRef!.current, rightGridRef!.current],
            otherTypes: ['leftGrid', 'rightGrid'],
          };
        case 'rightGrid':
          return {
            currentGrid: rightGridRef!.current,
            otherGrids: [leftGridRef!.current, middleGridRef!.current],
            otherTypes: ['leftGrid', 'middleGrid'],
          };
        default:
          return {
            otherGrids: [leftGridRef?.current || null, middleGridRef?.current || null, rightGridRef?.current || null],
            otherTypes: ['leftGrid', 'middleGrid', 'rightGrid'],
          };
      }
    },
    [leftGridRef, middleGridRef, rightGridRef],
  );

  useEffect(() => {
    if (!leftGridRef || !middleGridRef || !rightGridRef || !gridsContainerRef || !isGridReady) return;

    const gridViewportCls = '.ag-body-viewport';
    const { otherTypes } = getGrids();

    const leftGridViewport = gridsContainerRef.current?.querySelector(`.${otherTypes[0]} ${gridViewportCls}`);
    const middleGridViewport = gridsContainerRef.current?.querySelector(`.${otherTypes[1]} ${gridViewportCls}`);
    const rightGridViewport = gridsContainerRef.current?.querySelector(`.${otherTypes[2]} ${gridViewportCls}`);
    if (!leftGridViewport || !middleGridViewport || !rightGridViewport) return;

    const leftGridScrollHandler = () => {
      middleGridViewport.scrollTop = rightGridViewport.scrollTop = leftGridViewport.scrollTop;
    };
    const middleGridScrollHandler = () => {
      rightGridViewport.scrollTop = leftGridViewport.scrollTop = middleGridViewport.scrollTop;
    };
    const rightGridScrollHandler = () => {
      middleGridViewport.scrollTop = leftGridViewport.scrollTop = rightGridViewport.scrollTop;
    };

    leftGridViewport.addEventListener('scroll', leftGridScrollHandler, { passive: true });
    middleGridViewport.addEventListener('scroll', middleGridScrollHandler, { passive: true });
    rightGridViewport.addEventListener('scroll', rightGridScrollHandler, { passive: true });

    return () => {
      leftGridViewport.removeEventListener('scroll', leftGridScrollHandler);
      middleGridViewport.removeEventListener('scroll', middleGridScrollHandler);
      rightGridViewport.removeEventListener('scroll', rightGridScrollHandler);
    };
  }, [getGrids, gridsContainerRef, isGridReady, leftGridRef, middleGridRef, rightGridRef]);

  const handleSelectionChanged = useCallback(
    (gridName: GridName) => {
      if (!leftGridRef || !middleGridRef || !rightGridRef || !gridsContainerRef) return;

      const { currentGrid, otherGrids } = getGrids(gridName);
      const selectedNodesIds = currentGrid!.api.getSelectedNodes().map((node) => node.id);
      otherGrids.forEach((grid) => {
        grid?.api.getRenderedNodes().forEach((node) => {
          node?.setSelected(selectedNodesIds.includes(node.id));
        });
      });
    },
    [getGrids, gridsContainerRef, leftGridRef, middleGridRef, rightGridRef],
  );

  const [lsTableState, lsSetTableState] = useLocalStorage<TableState>(`${lsTableStatePrefix}${tableName}`, {
    filters: null,
    sort: null,
  });
  const tableStateInitializedRef = useRef(false);

  useEffect(() => {
    if (isGridReady && tableName && lsTableState && !tableStateInitializedRef.current) {
      const { filters, sort } = lsTableState;

      if (gridRef?.current) {
        sort && gridRef.current.columnApi?.applyColumnState(sort);
        filters && gridRef.current.api?.setFilterModel(filters);
      } else {
        const { otherGrids } = getGrids();

        otherGrids.forEach((grid) => {
          sort && grid?.columnApi.applyColumnState(sort);
          filters && grid?.api.setFilterModel(filters);

          (sort || filters) && grid?.api.deselectAll();
        });
      }

      tableStateInitializedRef.current = true;
    }
  }, [getGrids, gridRef, isGridReady, lsTableState, tableName]);

  const handleSortChanged = useCallback(
    (gridName: GridName | null, event: SortChangedEvent) => {
      const colState = event.columnApi.getColumnState();
      const sortState = colState.filter(function (s) {
        return s.sort != null;
      });
      const sortedByCol = sortState[0];
      const columnState = {
        state: sortedByCol?.colId ? [{ colId: sortedByCol.colId, sort: sortedByCol.sort }] : undefined,
        defaultState: { sort: null },
      };

      if (tableName) {
        lsSetTableState({ ...lsTableState!, sort: ignoreSavedSorting ? null : columnState });
      }

      if (gridName) {
        if (!leftGridRef || !middleGridRef || !rightGridRef || !gridsContainerRef) return;

        const { currentGrid, otherGrids } = getGrids(gridName);

        otherGrids.forEach((grid) => {
          grid?.columnApi.applyColumnState(columnState);
          grid?.api.deselectAll();
        });
        currentGrid?.api.deselectAll();
      }
    },
    [getGrids, gridsContainerRef, leftGridRef, lsSetTableState, lsTableState, middleGridRef, rightGridRef, tableName],
  );

  const handleFilterChanged = useCallback(
    (gridName?: GridName) => {
      let filterModel: {
        [key: string]: any;
      };
      const { currentGrid, otherGrids } = getGrids(gridName);

      if (gridRef?.current) {
        filterModel = gridRef.current.api.getFilterModel();
      } else if (gridName) {
        filterModel = currentGrid!.api.getFilterModel();
      }

      if (tableName) {
        lsSetTableState({ ...lsTableState!, filters: filterModel! });
      }

      if (gridName) {
        if (!leftGridRef || !middleGridRef || !rightGridRef || !gridsContainerRef) return;

        otherGrids!.forEach((grid) => {
          grid?.api.setFilterModel(filterModel);
          grid?.api.deselectAll();
        });
        currentGrid?.api.deselectAll();
      }
    },
    [
      getGrids,
      gridRef,
      gridsContainerRef,
      leftGridRef,
      lsSetTableState,
      lsTableState,
      middleGridRef,
      rightGridRef,
      tableName,
    ],
  );

  const getVisibleColDefs = useCallback(
    ({ allColDefs, gridName }: { allColDefs: ColDef[]; gridName: GridName }) => {
      let visibleColDefs: ColDef[] = [];
      const viewCols = getGridCols(gridName);

      if (viewCols?.length) {
        viewCols.forEach((col) => {
          const correspondingColDef = allColDefs.find((colDef) => colDef.field === col.field);
          visibleColDefs.push({ ...correspondingColDef, hide: !!col.hidden });
        });
      } else {
        visibleColDefs = allColDefs;
      }

      return visibleColDefs || [];
    },
    [getGridCols],
  );

  const applyColWidths = useCallback(
    (gridRef: React.RefObject<AgGridReact>, adaptPinnedCols?: boolean) => {
      const gridColumnApi = gridRef.current?.columnApi;
      if (!gridColumnApi) return;

      const colWidths = getColumnsWidths();

      if (colWidths) {
        gridColumnApi.setColumnWidths(colWidths);
      } else {
        if (!adaptPinnedCols) {
          gridColumnApi.resetColumnState();
        } else {
          gridRef.current.api.sizeColumnsToFit();
        }
      }

      if (adaptPinnedCols) {
        const pinnedCols = gridColumnApi.getDisplayedLeftColumns();
        pinnedCols?.forEach((col) => {
          if (!colWidths?.find((viewCol) => viewCol.key === col.getColId())) {
            gridColumnApi.setColumnWidth(col, col.getColDef().filter === 'agTextColumnFilter' ? 100 : 60);
          }
        });
      }

      gridRef.current.api.refreshHeader();
    },
    [getColumnsWidths],
  );

  return {
    applyColWidths,
    getVisibleColDefs,
    handleColumnMoved,
    handleColumnResized,
    handleSelectionChanged,
    handleSortChanged,
    handleFilterChanged,
    handleColumnPinned,
  };
};
