import React, { useCallback, useEffect, useRef, useState } from 'react';

import { BaseSelectRef } from 'rc-select';

import { useProductIdParam } from 'screens/ProductDetails/common/hooks';
import { ProductModule } from 'services/graphql/main';

type ProductId = number | null;
export type SizeSystemRowId = number | null;
export type SizeSystemRowName = string | null;
type CollectionId = number | undefined | null;
export type ProductModuleId = number | undefined | null;
type ProductModules = ProductModule[] | null;
type VisibleSizeSystemsColsIds = number[];

type ProductContextType = {
  clearContext: () => void;
  productId: ProductId;
  productModules: ProductModules;
  productModulesSelectRef: React.RefObject<BaseSelectRef>;
  selectedCollectionId: CollectionId;
  selectedProductModuleId: ProductModuleId;
  setProductId: (id: ProductId) => void;
  setProductModules: (modules: ProductModules) => void;
  setSelectedCollectionId: (id: CollectionId) => void;
  setSelectedProductModuleId: (id: ProductModuleId) => void;
  setSizeSystemRowId: (id: SizeSystemRowId) => void;
  setSizeSystemRowName: (id: SizeSystemRowName) => void;
  setVisibleSizeSystemsColsIds: (ids: VisibleSizeSystemsColsIds) => void;
  sizeSystemRowId: SizeSystemRowId;
  sizeSystemRowName: SizeSystemRowName;
  visibleSizeSystemsColsIds: VisibleSizeSystemsColsIds;
};

export const ProductContext = React.createContext<ProductContextType>({} as ProductContextType);

type ContextProviderType = {
  children: React.ReactNode;
};

export const ProductContextProvider: React.FC<ContextProviderType> = ({ children }) => {
  const { productId: productIdParam } = useProductIdParam();

  const [productId, setProductId] = useState<ProductId>(null);
  const [sizeSystemRowId, setSizeSystemRowId] = useState<SizeSystemRowId>(null);
  const [sizeSystemRowName, setSizeSystemRowName] = useState<SizeSystemRowName>(null);
  const [selectedCollectionId, setSelectedCollectionId] = useState<CollectionId>(null);
  const [selectedProductModuleId, setSelectedProductModuleId] = useState<ProductModuleId>(null);
  const [productModules, setProductModules] = useState<ProductModules>(null);
  const [visibleSizeSystemsColsIds, setVisibleSizeSystemsColsIds] = useState<VisibleSizeSystemsColsIds>([]);

  const productModulesSelectRef = useRef<BaseSelectRef>(null);

  const clearContext = useCallback(() => {
    setProductId(null);
    setSizeSystemRowId(null);
    setSizeSystemRowName(null);
    setSelectedCollectionId(null);
    setSelectedProductModuleId(null);
    setProductModules(null);
    setVisibleSizeSystemsColsIds([]);
  }, []);

  useEffect(() => {
    clearContext();
    setProductId(productIdParam);
    productIdParam && window.localStorage.setItem('editedProductId', String(productIdParam));

    return () => {
      clearContext();
    };
  }, [clearContext, productIdParam]);

  return (
    <ProductContext.Provider
      value={{
        clearContext,
        productId,
        productModules,
        productModulesSelectRef,
        setProductId,
        setProductModules,
        sizeSystemRowId,
        setSizeSystemRowId,
        sizeSystemRowName,
        setSizeSystemRowName,
        selectedCollectionId,
        setSelectedCollectionId,
        selectedProductModuleId,
        setSelectedProductModuleId,
        setVisibleSizeSystemsColsIds,
        visibleSizeSystemsColsIds,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};
