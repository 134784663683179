import { Descriptions } from 'antd';
import styled from 'styled-components';

import { TOTAL_FIELD } from '../../../common/constants/optimalStockCalculationTable';

export const StyledDescriptions = styled(Descriptions)`
  margin-right: 85px;

  .ant-descriptions-row {
    display: flex;
    flex-direction: column;
  }

  .ant-descriptions-item {
    padding-bottom: 1px !important;
  }

  .ant-descriptions-item-label {
    font-size: 12px;
    color: ${(p) => p.theme.white};
  }

  .ant-description-item-container {
    display: flex;
    align-items: center;
  }

  .ant-input-number {
    height: 18px;
    display: flex;
    align-items: center;
    width: 58px;
  }

  .ant-checkbox-inner,
  .ant-checkbox-checked:after {
    width: 13px;
    height: 13px;
    bottom: 3px;
    top: unset;
  }

  .ant-descriptions-view > table {
    table-layout: auto;
  }

  .ant-descriptions-item-content,
  .ant-descriptions-item-content input {
    font-weight: bold;
    font-size: 12px;
  }

  .ant-descriptions-item-content {
    word-break: normal;
    padding-right: 15px;
    color: ${(p) => p.theme.white};
  }
`;

export const StyledGridsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 5px;

  .ant-checkbox-inner,
  .ant-checkbox-checked:after {
    width: 13px;
    height: 13px;
    bottom: 3px;
    top: unset;
  }

  .ag-header-cell {
    padding: 0 5px;
  }

  .ag-layout-auto-height .ag-center-cols-container,
  .ag-layout-auto-height .ag-center-cols-clipper {
    min-height: 0;
  }

  .ag-row:last-of-type {
    border-bottom: none;
  }

  .rowNameCell {
    font-size: calc(var(--ag-font-size));
    font-weight: bold;
  }

  .overridesNameCell {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .controlWrapper {
    width: 70px;
    text-align: right;

    .ant-checkbox-inner {
      top: unset;
      bottom: 2px;
    }
  }

  .controlWrapper > .ant-input-number {
    max-width: 100%;
  }

  .overridesGrid .ag-header,
  .summaryGrid .ag-header {
    display: none;
  }

  .overridesGrid [col-id='${TOTAL_FIELD}'] {
    padding: 0;
    border-top: 0;
  }

  .ag-cell-inline-editing {
    height: 95%;
  }

  .ag-cell-inline-editing > input {
    margin-left: -3px;
    text-align: center;
    width: 105%;
  }

  .summaryGrid .ag-cell {
    font-weight: bold;
  }
`;

export const GridOverlay = styled.div<{ visible: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.02);
  z-index: 1000;
  display: ${(p) => (p.visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;
