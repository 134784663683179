import React from 'react';

import App from 'App';
import { SizeSystemContextProvider, StoreManagementContextProvider } from 'context';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import PermissionsProtectedRoute from 'components/PermissionsProtectedRoute';
import MainLayout from 'layouts/MainLayout/MainLayout';
import ProductPerformance from 'screens/ProductPerformance/ProductPerformance';

import AllRoutes from './AllRoutes';
import Home from './Dashboard/Home';
import ExportSettings from './ExportSettings/ExportSettings';
import FunctionalSettings from './GeneralSettings/FunctionalSettings';
import MassImport from './ImportSettings/MassImport';
import Logging from './Logging/Logging';
import LoginRedirect from './Login/LoginRedirect';
import ProtectedRoute from './Login/ProtectedRoute';
import RedirectCallback from './Login/RedirectCallback';
import CollectionsManagement from './OperationalSettings/CollectionsManagement/CollectionsManagement';
import ProductPerformanceSettings from './OperationalSettings/ProductPerformance/ProductPerformance';
import SizeSystem from './OperationalSettings/SizeSystem/SizeSystem';
import TableViewsManagement from './OperationalSettings/TableViewsManagement/TableViewsManagement';
import ProductDetails from './ProductDetails/ProductDetails';
import StoreFleet from './StoreFleet/StoreFleet';
import StorePerformance from './StorePerformance/StorePerformance';
import TestScreen from './TestScreen/TestScreen';
import Unauthorized from './Unauthorized/Unauthorized';
import UsersManagement from './UsersManagement/UsersManagement';

const Routing: React.FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route path={AllRoutes.Login} element={<LoginRedirect />} />
        <Route path={AllRoutes.AuthorizationCallback} element={<RedirectCallback />} />
        <Route path={AllRoutes.Unauthorized} element={<Unauthorized />} />
        <Route
          element={
            <ProtectedRoute>
              <MainLayout hasSettingsSlider={true} noPadding={false} />
            </ProtectedRoute>
          }
        >
          <Route
            path={AllRoutes.SizeSystemSettings}
            element={
              <PermissionsProtectedRoute page="settings">
                <SizeSystemContextProvider>
                  <SizeSystem />
                </SizeSystemContextProvider>
              </PermissionsProtectedRoute>
            }
          />
          <Route path={AllRoutes.ProductPerformanceSettings} element={<ProductPerformanceSettings />} />
          <Route
            path={AllRoutes.CollectionsManagementSettings}
            element={
              <PermissionsProtectedRoute page="settings">
                <CollectionsManagement />
              </PermissionsProtectedRoute>
            }
          />
          <Route
            path={AllRoutes.TableViewsManagementSettings}
            element={
              <PermissionsProtectedRoute page="settings">
                <TableViewsManagement />
              </PermissionsProtectedRoute>
            }
          />
          <Route
            path={AllRoutes.ImportSettings}
            element={
              <PermissionsProtectedRoute page="settings">
                <MassImport />
              </PermissionsProtectedRoute>
            }
          />
          <Route
            path={AllRoutes.ExportSettings}
            element={
              <PermissionsProtectedRoute page="settings">
                <ExportSettings />
              </PermissionsProtectedRoute>
            }
          />
          <Route
            path={AllRoutes.GeneralSettings}
            element={
              <PermissionsProtectedRoute page="generalSettings">
                <FunctionalSettings />
              </PermissionsProtectedRoute>
            }
          />
          <Route
            path={AllRoutes.UserManagementSettings}
            element={
              <PermissionsProtectedRoute page="userManagement">
                <UsersManagement />
              </PermissionsProtectedRoute>
            }
          />
        </Route>
        <Route
          element={
            <ProtectedRoute>
              <MainLayout hasSettingsSlider={false} noPadding={false} />
            </ProtectedRoute>
          }
        >
          <Route path={AllRoutes.Dashboard} element={<Home />} />
          <Route path={AllRoutes.StoreFleet} element={<StoreFleet />} />
          <Route path={AllRoutes.StorePerformance} element={<StorePerformance />} />
          <Route path={AllRoutes.ProductPerformance} element={<ProductPerformance />} />
          <Route path={AllRoutes.Logging} element={<Logging />} />
          <Route path="/testing" element={<TestScreen />} />
          <Route path="*" element={<Home />} />
        </Route>
        <Route
          element={
            <ProtectedRoute>
              <MainLayout hasSettingsSlider={false} noPadding={true} />
            </ProtectedRoute>
          }
        >
          <Route
            path={AllRoutes.ProductDetails}
            element={
              <StoreManagementContextProvider>
                <ProductDetails />
              </StoreManagementContextProvider>
            }
          />
        </Route>
      </Route>
    </Routes>
  </BrowserRouter>
);

export default Routing;
