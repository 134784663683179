import React from 'react';

import ProductCustomFields from './ProductCustomFields/ProductCustomFields';
import SeasonProductTable from './SeasonProductTable/SeasonProductTable';
import { SecondaryStyledTabs } from './styles';

const Product: React.FC = () => {
  return (
    <SecondaryStyledTabs
      defaultActiveKey="1"
      itemsWithTextKeys={[
        {
          labelKey: 'operationalSettings.product_season',
          key: '1',
          children: <SeasonProductTable />,
        },
        {
          labelKey: 'operationalSettings.product_additional_fields',
          key: '2',
          children: <ProductCustomFields />,
        },
      ]}
    />
  );
};

export default Product;
