import React, { useCallback, useEffect, useMemo, useRef, useState, useContext } from 'react';

import {
  CellEditingStoppedEvent,
  ColDef,
  ICellRendererParams,
  RowDoubleClickedEvent,
  ExcelStyle,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import SimulationPickingImg from 'assets/images/simulation-picking-image.png';
import { PermissionsContext, TableViewsContext } from 'context';
import { cloneDeep, isBoolean, isEmpty, isNumber } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getFormattedCurrentDateTime } from 'utils/moment';

import { ClearFiltersButton, ConciseGridContainer, Views } from 'components/AgGridComponents';
import CellCheckbox from 'components/AgGridComponents/CellCheckbox';
import ImportExportXlsxComponent from 'components/ImportExportXlsxComponent';
import { OptimalStockAndEstimatedPickingModal } from 'components/Modals';
import NoSeasonEmptyState from 'components/NoSeasonEmptyState';
import { Button, Spinner } from 'components/UI';
import { ActionButtonsWrapper, VerticalPageContent } from 'components/wrappers';
import { GRID_DEFAULT_COL_DEFS, GRID_DEFAULT_PROPS } from 'constants/gridConstants';
import { hasFiltersChanged } from 'helpers/grid';
import { applyColWidths } from 'helpers/gridCols';
import { getContextMenu } from 'helpers/gridContextMenu';
import { setGridRows } from 'helpers/gridRows';
import { useCommonData } from 'hooks/useCommonData';
import { useGridCols } from 'hooks/useGridCols';
import { useProductIds } from 'hooks/useProductIds';
import AllRoutes from 'screens/AllRoutes';
import { useColDefs } from 'screens/ProductPerformance/hooks/useColDefs';
import { GridsContainer, CurrentWeekLabel } from 'screens/ProductPerformance/ProductPerformanceTable/styles';
import {
  ProductFleetPerformanceDto,
  useGetProductPerformanceLazyQuery,
  useUpdateBulkOptimalStockOverrideFlagsMutation,
  useUpdateProductDetailsMutation,
  useUpdateProductModuleMutation,
  PermissionsEnum,
  useUpdateProductValidatedStrengthMutation,
} from 'services/graphql/main';
import { useError } from 'services/utils';

const EDITABLE_BOOLEAN_FIELDS = [
  'isOnShowcase',
  'isSynced',
  'hasStockMinOverride',
  'hasStockMaxOverride',
  'hasCondition',
];

const ProductPerformanceTable = () => {
  const navigate = useNavigate();
  const { addError } = useError();
  const { t } = useTranslation('translation');
  const { seasonId, universeId, seasonName, universeCode } = useCommonData();
  const { permissions } = useContext(PermissionsContext);
  const { getColumnsWidths, selectedViewId } = useContext(TableViewsContext);

  const [
    getProductPerformanceLazyQuery,
    { loading: loadingProductPerformance, error: errorProductPerformance, refetch: refetchProductPerformance },
  ] = useGetProductPerformanceLazyQuery({
    onError: (err) => addError(err, 'warning'),
  });
  const [updateProductDetailsMutation] = useUpdateProductDetailsMutation({
    onError: (err) => addError(err, 'warning'),
  });
  const [updateBulkOptimalStockOverrideFlagsMutation] = useUpdateBulkOptimalStockOverrideFlagsMutation({
    onError: (err) => addError(err, 'warning'),
  });
  const [updateProductValidatedStrength] = useUpdateProductValidatedStrengthMutation({
    onError: (err) => addError(err, 'warning'),
  });
  const [updateProductModuleMutation] = useUpdateProductModuleMutation({ onError: (err) => addError(err, 'warning') });

  const gridRef = useRef<AgGridReact<ProductFleetPerformanceDto>>(null);
  const updatedRowIdRef = useRef(0);
  const loadedDataForSeasonRef = useRef<number | null>(null);

  const { setProductIds } = useProductIds(gridRef);

  const [rowData, setRowData] = useState<ProductFleetPerformanceDto[]>([]);
  const [isGridReady, setIsGridReady] = useState(false);
  const [dataProductPerformance, setDataProductPerformance] = useState<ProductFleetPerformanceDto[] | null>(null);
  const [isModalOpen, setIsModalOpen] = useState({ optimalStockModal: false });
  const [hasFilters, setHasFilters] = useState(false);
  const [refetchLoading, setRefetchLoading] = useState(false);
  const updateProductPerformanceData = useCallback(
    async (productId: number, colId: string, newValue: number | boolean) => {
      let resultData;

      if (colId === 'isOnShowcase' || colId === 'isSynced') {
        const result = await updateProductDetailsMutation({
          variables: { productDetails: { id: productId, [colId]: newValue } },
        });

        resultData = result.data?.updateProductDetails.id;
      } else if (colId === 'hasStockMinOverride' || colId === 'hasStockMaxOverride' || colId === 'hasCondition') {
        colId;
        if (colId === 'hasStockMinOverride') colId = 'hasStockMin';
        if (colId === 'hasStockMaxOverride') colId = 'hasStockMax';

        const result = await updateBulkOptimalStockOverrideFlagsMutation({
          variables: { stockOverrideBulk: { productId, [colId]: newValue } },
        });

        resultData = result.data?.updateBulkOptimalStockOverrideFlags[0]?.id;
      } else if (colId === 'moduleId') {
        const result = await updateProductModuleMutation({ variables: { productId, moduleId: newValue as number } });

        resultData = result.data?.updateProductDetails.productModuleId;
      }

      if (resultData) {
        updatedRowIdRef.current = productId;
        const refetchedData = await refetchProductPerformance();
        setDataProductPerformance((refetchedData.data?.productPerformance as ProductFleetPerformanceDto[]) || null);
      }
    },
    [
      refetchProductPerformance,
      updateBulkOptimalStockOverrideFlagsMutation,
      updateProductDetailsMutation,
      updateProductModuleMutation,
    ],
  );
  const updateValidatedStrength = useCallback(
    async (e: CellEditingStoppedEvent<ProductFleetPerformanceDto>) => {
      if (!e.valueChanged) return;

      const result = await updateProductValidatedStrength({
        variables: {
          productId: e.data.id,
          validatedProductStrength: e.newValue,
        },
      });

      if (result.data?.updateProduct.id) {
        await refetchProductPerformance();
      }
    },
    [refetchProductPerformance, updateProductValidatedStrength],
  );

  const { handleFilterChanged, handleSortChanged, handleColumnMoved, handleColumnResized, handleColumnPinned } =
    useGridCols({
      gridRef,
      isGridReady,
      tableName: 'productPerformanceWip',
    });

  const { allVisibleColumnDefs, allColDefs } = useColDefs({
    dataProductPerformance,
    errorProductPerformance,
    updatedRowIdRef,
    selectedUniverseId: universeId,
    updateProductPerformanceData,
  });

  const loadProductPerformanceData = useCallback(async () => {
    if (!seasonId || !universeId) return;
    const editedProductId = window.localStorage.getItem('editedProductId');
    let result;

    if (!loadedDataForSeasonRef.current) {
      result = await getProductPerformanceLazyQuery({ variables: { universeId, seasonId } });
    } else if (editedProductId) {
      updatedRowIdRef.current = Number(editedProductId);
      window.localStorage.removeItem('editedProductId');
      result = await refetchProductPerformance({ universeId, seasonId });
    } else {
      result = await refetchProductPerformance({ universeId, seasonId });
    }

    if (result.data?.productPerformance)
      setDataProductPerformance(cloneDeep(result.data.productPerformance as ProductFleetPerformanceDto[]));

    loadedDataForSeasonRef.current = seasonId;
  }, [getProductPerformanceLazyQuery, refetchProductPerformance, seasonId, universeId, gridRef]);

  useEffect(() => {
    loadProductPerformanceData();
    setProductIds();
  }, [setProductIds, seasonId]);

  useEffect(() => {
    isGridReady && setProductIds();
  }, [isGridReady, setProductIds]);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      cellStyle: { textAlign: 'center' },
      initialWidth: 35,
      ...GRID_DEFAULT_COL_DEFS,
      cellRenderer(params: ICellRendererParams) {
        const value = params.valueFormatted ? params.valueFormatted : params.value;
        const field = params.colDef!.field!;

        if (isBoolean(value)) {
          return (
            <CellCheckbox
              checked={value}
              onChange={(checked) => {
                field === 'isOnShowcase' &&
                  gridRef.current?.api.getDisplayedRowAtIndex(params.rowIndex)?.setDataValue(field, checked);
                updateProductPerformanceData(params.data.id, field, checked);
              }}
              disabled={
                !EDITABLE_BOOLEAN_FIELDS.includes(field) ||
                !(permissions as PermissionsEnum[])?.includes(PermissionsEnum.ProductPerformanceMainTableEdit)
              }
              changeColor={field === 'isSynced'}
            />
          );
        }

        return value;
      },
    };
  }, [permissions, updateProductPerformanceData]);

  useEffect(() => {
    if (!dataProductPerformance) return;
    const productPerformanceData = cloneDeep(dataProductPerformance);
    setGridRows(productPerformanceData, setRowData, gridRef, updatedRowIdRef);
  }, [dataProductPerformance, rowData?.length]);

  useEffect(() => {
    gridRef.current?.api && applyColWidths(gridRef, getColumnsWidths);
  }, [getColumnsWidths, gridRef, allVisibleColumnDefs]);

  useEffect(() => {
    allVisibleColumnDefs?.length && !selectedViewId && gridRef?.current?.api.sizeColumnsToFit();
  }, [getColumnsWidths, gridRef, allVisibleColumnDefs, selectedViewId]);

  const handleRowDoubleClick = useCallback(
    (params: RowDoubleClickedEvent) => {
      const { id } = params.data;
      navigate(AllRoutes.ProductDetails.replace(':id', id));
    },
    [navigate],
  );

  const showModal = useMemo(
    () => ({
      OPTIMAL_STOCK_MODAL: () => setIsModalOpen((prevState) => ({ ...prevState, optimalStockModal: true })),
    }),
    [],
  );

  const hideModal = useMemo(
    () => ({
      OPTIMAL_STOCK_MODAL: () => setIsModalOpen((prevState) => ({ ...prevState, optimalStockModal: false })),
    }),
    [],
  );

  const excelStyles: ExcelStyle[] = [
    {
      id: 'stringType',
      dataType: 'String',
    },
  ];

  const generateColumnsForExport = () => {
    const keys = gridRef.current?.columnApi.getAllDisplayedColumns().map((column) => column.getColId());
    keys?.unshift('season');
    keys?.unshift('universe');

    return keys;
  };

  const onExport = useCallback(() => {
    gridRef.current &&
      gridRef.current.api.exportDataAsExcel({
        columnKeys: generateColumnsForExport(),
        processCellCallback: function (params) {
          if (params.column.getColId() === 'season') {
            return seasonName;
          }
          if (params.column.getColId() === 'universe') {
            return universeCode;
          }

          if (isNumber(params.value) && params?.value % 1 != 0) {
            return params.value.toFixed(1);
          }

          return params.value;
        },
        fileName: `${t('productPerformance.product_performance')}_${getFormattedCurrentDateTime()}`,
      });
  }, [universeCode, seasonName, t]);

  const onCSVExport = useCallback(() => {
    gridRef.current!.api.exportDataAsCsv();
  }, []);

  const handleOptimalStockButtonClicked = useCallback(() => {
    showModal['OPTIMAL_STOCK_MODAL']();
  }, [showModal]);

  const rowClassRules = {
    'archived-product': (params: any) => {
      return params.data.isArchived;
    },
    'storefront-product': (params: any) => {
      return params.data.isOnShowcase;
    },
  };

  if (!seasonId) return <NoSeasonEmptyState />;
  if (loadingProductPerformance) return <Spinner />;
  const getCurrentAndWeekBefore = () => {
    const now = new Date();
    const currentWeek = moment(now).add(-1, 'days');
    const weekAgo = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 8));

    return (
      <div>
        <CurrentWeekLabel>{`${t(
          'productPerformance.current_week',
        )} ${currentWeek.isoWeek()}/${currentWeek.year()}`}</CurrentWeekLabel>
        <CurrentWeekLabel>{`${t(
          'productPerformance.current_week-1',
        )} ${weekAgo.isoWeek()}/${weekAgo.year()}`}</CurrentWeekLabel>
      </div>
    );
  };

  return (
    <>
      <VerticalPageContent>
        <ActionButtonsWrapper style={{ justifyContent: 'flex-start', margin: '0 0 5px' }}>
          <Views flatColDefs={allColDefs} />

          <Button
            style={{ padding: 0, height: 'auto' }}
            onClick={handleOptimalStockButtonClicked}
            size="small"
            tooltipKey="optimalStockAndEstimatedPicking.optimal_stock_and_estimated_picking_modal_title"
          >
            <img width={24} alt="Simulation picking img" src={SimulationPickingImg} />
          </Button>
          <ClearFiltersButton gridRefs={gridRef} hasFilters={hasFilters} />
          {getCurrentAndWeekBefore()}
          <div style={{ marginLeft: 'auto' }}>
            <ImportExportXlsxComponent
              exportClickHandler={onExport}
              refetchData={async () => {
                setRefetchLoading(true);
                await loadProductPerformanceData();
                await gridRef.current?.api.redrawRows();
                setRefetchLoading(false);
              }}
            />
          </div>
        </ActionButtonsWrapper>

        {refetchLoading ? (
          <Spinner />
        ) : (
          !errorProductPerformance && (
            <ConciseGridContainer isGridReady={isGridReady}>
              <GridsContainer>
                <AgGridReact
                  {...GRID_DEFAULT_PROPS}
                  containerStyle={{ width: '100%', height: '100%' }}
                  ref={gridRef}
                  headerHeight={55}
                  rowHeight={25}
                  suppressMenuHide
                  onGridReady={() => {
                    isEmpty(dataProductPerformance) && setIsGridReady(true);
                  }}
                  onFirstDataRendered={() => {
                    setIsGridReady(true);
                  }}
                  columnDefs={allVisibleColumnDefs}
                  defaultColDef={defaultColDef}
                  rowData={rowData}
                  onRowDoubleClicked={handleRowDoubleClick}
                  enterMovesDownAfterEdit={true}
                  singleClickEdit
                  onCellEditingStopped={updateValidatedStrength}
                  stopEditingWhenCellsLoseFocus
                  onColumnMoved={handleColumnMoved}
                  onColumnResized={handleColumnResized}
                  onColumnPinned={handleColumnPinned}
                  onSortChanged={(e) => handleSortChanged(null, e)}
                  onFilterChanged={(e) => {
                    setHasFilters(hasFiltersChanged(e));
                    handleFilterChanged();
                    setProductIds();
                  }}
                  postSortRows={setProductIds}
                  getContextMenuItems={() => getContextMenu(onExport, onCSVExport)}
                  rowClassRules={rowClassRules}
                  excelStyles={excelStyles}
                />
              </GridsContainer>
            </ConciseGridContainer>
          )
        )}
      </VerticalPageContent>

      <OptimalStockAndEstimatedPickingModal
        isOpen={isModalOpen.optimalStockModal}
        handleCancel={hideModal['OPTIMAL_STOCK_MODAL']}
      />
    </>
  );
};

export default ProductPerformanceTable;
