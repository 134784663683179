import React, { useContext, useEffect, useMemo, useState } from 'react';

import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { ProductContext } from 'context';
import { useTranslation } from 'react-i18next';

import { Spinner } from 'components/UI';
import { CENTERED_COL_DEF, CENTERED_HEADER_CLASS, COLUMN_TYPES } from 'constants/gridConstants';
import {
  ExceptionOptimalStockRetailStockDto,
  useGetWarehousesLazyQuery,
  useRetailWarehouseStockLazyQuery,
  Warehouse,
} from 'services/graphql/main';
import { useError } from 'services/utils';

import StyledModal from '../StyledModal/StyledModal';
import { StyledTableWrapper } from './styles';
import { ColumnProps, RowsProps, StockDepotRetailModalProps } from './types';

const StockDepotRetailModal: React.FC<StockDepotRetailModalProps> = ({ isOpen, handleCancel }) => {
  const { t } = useTranslation('translation');
  const { addError } = useError();

  const [columnDefs, setColumnDefs] = useState<ColumnProps | null | undefined>();
  const [rowsRawData, setRowRawData] = useState<ExceptionOptimalStockRetailStockDto[]>();
  const { productId } = useContext(ProductContext);

  const [retailWarehouseStockLazyQuery, { loading: loadingRetailWarehouseStock, data: dataRetailWarehouseStock }] =
    useRetailWarehouseStockLazyQuery({
      variables: {
        productId: productId || 0,
      },
      onError: (err) => addError(err, 'error'),
    });

  const [getWarehousesLazyQuery, { loading: loadingWarehouses, data: dataWarehouses }] = useGetWarehousesLazyQuery({
    onError: (err) => addError(err, 'error'),
  });

  useEffect(() => {
    if (isOpen) {
      retailWarehouseStockLazyQuery();
      getWarehousesLazyQuery();
    }
  }, [getWarehousesLazyQuery, isOpen, retailWarehouseStockLazyQuery]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 60,
      suppressMovable: true,
      sortable: true,
    };
  }, []);

  useEffect(() => {
    if (dataRetailWarehouseStock) {
      const getRowsData = (rowsRawData: RowsProps[number] | null | undefined, codeDepotObject: Warehouse) => {
        const rows = [
          Object.assign(
            {},
            codeDepotObject,
            ...rowsRawData!.map((item: RowsProps) => ({ [item!.headerId]: item!.warehouseStockQuantity })),
          ),
        ];

        return rows;
      };

      const getColumnDefs = (colsRawData: ColumnProps[number] | null | undefined): ColumnProps => {
        const colDefs =
          colsRawData?.map((value: ExceptionOptimalStockRetailStockDto | null) => ({
            field: value!.headerId.toString() || undefined,
            headerName: value!.headerName || undefined,
            ...CENTERED_COL_DEF,
          })) || [];

        return colDefs;
      };

      const columns: ColumnProps = [
        {
          field: 'code',
          headerName: t('exceptionTable.code_depot'),
          minWidth: 120,
          headerClass: CENTERED_HEADER_CLASS,
        },
        ...getColumnDefs(dataRetailWarehouseStock.retailWarehouseStock),
      ];

      const codeDepotObject = dataWarehouses?.warehouses?.find((x) => x.isRetail === true);
      const rows: ExceptionOptimalStockRetailStockDto[] = getRowsData(
        dataRetailWarehouseStock.retailWarehouseStock,
        codeDepotObject as Warehouse,
      );

      setColumnDefs(columns);
      setRowRawData(rows);
    } else {
      setColumnDefs([]);
      setRowRawData([]);
    }
  }, [dataRetailWarehouseStock, dataWarehouses?.warehouses, t]);

  return (
    <div>
      <StyledModal
        title={t('exceptionTable.stock_depot_retail')}
        open={isOpen}
        onCancel={handleCancel}
        footer={[]}
        width="50%"
        centered={true}
      >
        {loadingRetailWarehouseStock || loadingWarehouses ? (
          <Spinner size="large" />
        ) : (
          <StyledTableWrapper>
            <AgGridReact
              containerStyle={{ width: '100%', marginTop: 15, marginBottom: 10, height: 'auto' }}
              columnDefs={columnDefs}
              rowData={rowsRawData}
              defaultColDef={defaultColDef}
              domLayout={'autoHeight'}
              columnTypes={COLUMN_TYPES}
            />
          </StyledTableWrapper>
        )}
      </StyledModal>
    </div>
  );
};

export default StockDepotRetailModal;
