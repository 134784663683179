import React from 'react';

import { StyledSwitch } from './styles';
import { SwitchProps } from './types';

const Switch: React.FC<SwitchProps> = ({ alwaysActive, ...rest }) => {
  return <StyledSwitch $alwaysActive={alwaysActive} {...rest} />;
};

export default Switch;
