import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';

import { GRID_MAX_HEIGHT } from 'constants/gridConstants';

export const SizeSystemsTableWrapper = styled.div`
  width: 100%;
  margin-top: 15px;
  margin-bottom: 30px;
`;

export const TableWrapper = styled.div`
  margin-right: 10px;
  margin-bottom: 100px;

  .panel-body input {
    margin-right: 2px !important;
  }

  .panel-body label {
    margin-right: 10px;
  }
`;

export const GridWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  margin-top: 5px;

  .panel {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .panel-body {
    flex: 1 1 auto;
    overflow: hidden;
    padding: 0;
    display: flex;
    height: 400px;
  }

  .panel-body > div {
    width: 100%;
  }
`;

export const DistributionGridSelectWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 5px;

  .ant-select {
    font-size: 12px;
    width: 60px !important;
  }

  .ant-select-selector {
    height: unset !important;
  }

  p {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 12px;
  }
`;

export const StyledTotalStatusCheckSuccess = styled(CheckCircleFilled)`
  svg {
    font-size: 12px;
    fill: #52c41a;
  }
`;

export const StyledTotalStatusCheckWarning = styled(ExclamationCircleFilled)`
  svg {
    font-size: 12px;
    fill: #faad14;
  }
`;

export const NoSizeSystemSelectedMessage = styled.div`
  display: flex;
  justify-content: center;
  height: 310px;
  align-items: center;

  p {
    font-size: 26px;
    font-weight: 500;
  }
`;

export const AdaptiveGridResizer = styled.div`
  div.ag-root .ag-body-viewport {
    height: ${GRID_MAX_HEIGHT};
    overflow-y: scroll;
  }

  .ag-header-viewport {
    flex: unset;
  }
`;
