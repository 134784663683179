import { MenuItemDef } from 'ag-grid-community';

export const getContextMenu = (onExport: () => void, onCSVExport: () => void) => {
  const result: (string | MenuItemDef)[] = [
    'copy',
    'copyWithHeaders',
    'copyWithGroupHeaders',
    'paste',
    {
      name: 'Export',
      icon: '<span class="ag-icon ag-icon-save" unselectable="on" role="presentation"></span>',
      subMenu: [
        {
          name: 'CSV Export',
          action: () => {
            onCSVExport();
          },
          icon: '<span class="ag-icon ag-icon-csv" unselectable="on" role="presentation"></span>',
        },
        {
          name: 'Excel Export',
          action: () => {
            onExport();
          },
          icon: '<span class="ag-icon ag-icon-excel" unselectable="on" role="presentation"></span>',
        },
      ],
    },
  ];

  return result;
};
export const getContextExcelExportMenu = (onExport: () => void) => {
  const result: (string | MenuItemDef)[] = [
    'copy',
    'copyWithHeaders',
    'copyWithGroupHeaders',
    'paste',
    {
      name: 'Excel Export',
      icon: '<span class="ag-icon ag-icon-csv" unselectable="on" role="presentation"></span>',
      action: () => {
        onExport();
      },
    },
  ];

  return result;
};
export const getContextCsvExportMenu = (onExport: () => void) => {
  const result: (string | MenuItemDef)[] = [
    'copy',
    'copyWithHeaders',
    'copyWithGroupHeaders',
    'paste',
    {
      name: 'Csv Export',
      icon: '<span class="ag-icon ag-icon-excel" unselectable="on" role="presentation"/>',
      action: () => {
        onExport();
      },
    },
  ];

  return result;
};
