import { ColDef } from 'ag-grid-community';

import { CUSTOM_FIELD_PREFIX } from './common';

export const STORE_STRENGTH_FIELD = 'storeStrength';
export const NUMBER_OF_STORES_FIELD = 'numberOfStores';
export const TOTAL_FIELD = 'total';
export const ROW_TYPE_CUSTOM_FIELD = `${CUSTOM_FIELD_PREFIX}rowType`;

export const STOCK_MIN_FIELD = 'stockMin';
export const HAS_STOCK_MIN_FIELD = 'hasStockMin';
export const STOCK_MAX_FIELD = 'stockMax';
export const HAS_STOCK_MAX_FIELD = 'hasStockMax';
export const HAS_CONDITION_FIELD = 'hasCondition';
export const MIN_CONDITION_FIELD = 'stockMinStoreStrengthCondition';
export const MIN_CONDITION_FIELD_VALUE = 'stockMinStoreStrengthConditionValue';
export const MAX_CONDITION_FIELD = 'stockMaxStoreStrengthCondition';
export const MAX_CONDITION_FIELD_VALUE = 'stockMaxStoreStrengthConditionValue';

export const COMMON_DEFAULT_COL_DEFS: ColDef = { flex: 1, minWidth: 80 };
