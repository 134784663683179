import React, { useRef, useState } from 'react';

import type { DraggableData, DraggableEvent } from 'react-draggable';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';

import StyledModal from '../StyledModal/StyledModal';

interface Props {
  children: React.ReactNode;
  destroyOnClose?: boolean;
  footer?: React.ReactNode;
  onCancel: (e: React.MouseEvent<HTMLElement>) => void;
  onOk?: (e: React.MouseEvent<HTMLElement>) => void;
  open: boolean;
  style?: React.CSSProperties;
  title?: string;
  titleKey?: string;
  width?: number;
}

const DraggableModal: React.FC<Props> = ({
  title,
  titleKey,
  open,
  onOk,
  onCancel,
  footer,
  width,
  destroyOnClose,
  style,
  children,
}) => {
  const { t } = useTranslation('translation');

  const draggleRef = useRef<HTMLDivElement>(null);

  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();

    if (!targetRect) return;

    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  return (
    <StyledModal
      title={
        <div
          style={{ width: '100%', cursor: 'move' }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          // fix eslintjsx-a11y/mouse-events-have-key-events
          // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onFocus={() => {}}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onBlur={() => {}}
        >
          {titleKey ? t(titleKey) : title}
        </div>
      }
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      footer={footer}
      width={width}
      destroyOnClose={destroyOnClose}
      style={style}
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
          nodeRef={draggleRef}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
    >
      {children}
    </StyledModal>
  );
};

export default DraggableModal;
