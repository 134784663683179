import { Tabs } from 'antd';
import styled from 'styled-components';

export const StyledTabs = styled(Tabs)<{ fullContentHeight?: boolean }>`
  color: ${(p) => p.theme.white};
  .ant-tabs-content,
  .ant-tabs-content-holder,
  .ant-tabs-tabpane {
    height: ${(p) => p.fullContentHeight && '100%'};
  }
  .ant-tabs-tab:hover {
    color: ${(p) => p.theme.white};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(p) => p.theme.white};
    font-weight: 600;
  }

  .ant-tabs-ink-bar {
    background: ${(p) => p.theme.primary_yellow};
  }

  .ant-tabs-nav-wrap {
    background-color: ${(p) => p.theme.dark_blue_background};
  }
`;
