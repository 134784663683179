import styled from 'styled-components';

import { GRID_MAX_HEIGHT } from 'constants/gridConstants';

export const AdaptiveGridResizer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 50px;

  div.ag-root .ag-body-viewport {
    height: ${GRID_MAX_HEIGHT};
  }

  .ag-header-viewport {
    flex: unset;
  }
`;
