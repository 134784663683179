import React from 'react';

import { Spinner } from 'components/UI';

import { Container } from './styles';

interface Props {
  children?: React.ReactNode;
  isGridReady: boolean;
}

const ConciseGridContainer: React.FC<Props> = ({ children, isGridReady }) => {
  return (
    <>
      {!isGridReady && <Spinner />}
      <Container
        style={{
          transition: 'opacity .4s',
          opacity: isGridReady ? 1 : 0,
          pointerEvents: isGridReady ? 'auto' : 'none',
        }}
      >
        {children}
      </Container>
    </>
  );
};

export default ConciseGridContainer;
