import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ImportModal } from 'components/Modals';
import { NakedButton } from 'components/UI';

type Props = {
  exportClickHandler: () => void;
  noImportOption?: boolean;
  refetchData?: () => void;
};

const ImportExportXlsxComponent: React.FC<Props> = ({ noImportOption, exportClickHandler, refetchData }) => {
  const { t } = useTranslation('translation');

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      {!noImportOption && (
        <NakedButton type="text" style={{ marginRight: 15, color: '#ffffff' }} onClick={() => setIsModalOpen(true)}>
          {t('common.import_file')}
        </NakedButton>
      )}
      <NakedButton type="text" style={{ color: '#ffffff' }} onClick={exportClickHandler}>
        {t('common.export_xlsx')}
      </NakedButton>
      {isModalOpen && (
        <ImportModal isOpen={isModalOpen} handleCancel={() => setIsModalOpen(false)} refetchData={refetchData} />
      )}
    </>
  );
};

export default ImportExportXlsxComponent;
