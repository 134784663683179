import React, { useEffect } from 'react';

import Spinner from '../Spinner/Spinner';
import { SpinnerContainer } from './styles';

const FullscreenSpinner = () => {
  useEffect(() => {
    const initialOverflowValue = window.document.body.style.overflow;
    window.document.body.style.overflow = 'hidden';

    return () => {
      window.document.body.style.overflow = initialOverflowValue;
    };
  }, []);

  return (
    <SpinnerContainer>
      <Spinner />
    </SpinnerContainer>
  );
};

export default FullscreenSpinner;
