import React from 'react';

import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';

import { ColorBox, HintRow } from './styles';

interface Props {
  children: React.ReactNode;
  hints: { color: string; description?: string; descriptionKey?: string }[];
  titleKey: string;
}

const CellColoringHint: React.FC<Props> = ({ titleKey, hints = [], children }) => {
  const { t } = useTranslation('translation');

  return (
    <Popover
      title={t(titleKey)}
      content={
        <div>
          {hints.map(({ color, description, descriptionKey }) => {
            const descriptionText = description ? description : descriptionKey ? t(descriptionKey) : '';

            return (
              <HintRow key={`${color}_${descriptionText}`}>
                <ColorBox style={{ background: color }} />
                <p>{descriptionText}</p>
              </HintRow>
            );
          })}
        </div>
      }
      placement="leftTop"
      align={{ offset: [0, 35] }}
      showArrow={false}
      mouseEnterDelay={1}
    >
      {children}
    </Popover>
  );
};

export default CellColoringHint;
