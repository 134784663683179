import React from 'react';

import { Button } from 'antd';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import PrimaryButton from 'components/UI/PrimaryButton/PrimaryButton';

import StyledModal from '../StyledModal/StyledModal';
import { TitleContainer, InputContainer } from './styles';

interface PropsInputModal {
  customTitle: string;
  icon?: JSX.Element | JSX.Element[];
  inputPrefix?: string;
  onCancel: () => void;
  onOk: () => void;
  open: boolean;
  setState: React.Dispatch<React.SetStateAction<string>>;
  state?: string;
}

const InputModal: React.FC<PropsInputModal> = ({ state, setState, customTitle, open, onCancel, onOk, inputPrefix }) => {
  const { t } = useTranslation('translation');

  const handleChange = (e: { target: HTMLInputElement }) => {
    setState(e.target.value);
  };

  return (
    <div>
      <StyledModal
        centered
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        footer={[
          <Button key="cancel" onClick={onCancel}>
            {t('common.cancel')}
          </Button>,
          <PrimaryButton
            onClick={onOk}
            disabled={state?.trim() === ''}
            key="confirm"
            text={t('common.confirm')}
          ></PrimaryButton>,
        ]}
      >
        <Form autoComplete="off">
          <Form.Item>
            <TitleContainer>{customTitle}</TitleContainer>
            <InputContainer>
              {inputPrefix && <div className="prefix-container">{inputPrefix}</div>}
              <Input value={state} onChange={handleChange} placeholder={t('operationalSettings.header_name')} />
            </InputContainer>
          </Form.Item>
        </Form>
      </StyledModal>
    </div>
  );
};

export default InputModal;
