import React, { useState, useMemo, useEffect, useCallback } from 'react';

import { InboxOutlined, ShopOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';

import StockDepotRetailModal from 'components/Modals/StockDepotRetailModal/StockDepotRetailModal';
import StoreManagementModal from 'components/Modals/StoreManagementModal/StoreManagementModal';
import { PrimaryButton, Checkbox } from 'components/UI';
import {
  ExceptionOptimalStockSalesEnum,
  ExceptionOptimalStockStockEnum,
  ExceptionOptimalStockTableDto,
} from 'services/graphql/main';

import { StyledActionsWrapper, ActionsElementTitle, ActionsElementWrapper, ActionElementContainer } from './styles';
import { ActionWrapperProps, SalesOptionsLabelsProps, StocksOptionsLabelsProps } from './types';

const ActionsWrapper: React.FC<ActionWrapperProps> = ({
  numberOfWeeksInSale,
  tableDataSettings,
  setTableDataSettings,
  tablesData,
}) => {
  const { t } = useTranslation('translation');
  const { Option } = Select;

  const SalesOptionsAsArray = Object.entries(ExceptionOptimalStockSalesEnum);
  const StocksOptionsAsArray = Object.entries(ExceptionOptimalStockStockEnum);

  const SalesOptionsLabels: SalesOptionsLabelsProps = {
    THREE_WEEK_AVERAGE: t('exceptionTable.three_week_average'),
    SEASON_TOTAL: t('exceptionTable.season_total'),
    ESTIMATION_PICKING: t('exceptionTable.estimated_picking'),
    STOCK_STORE: t('exceptionTable.stock_store'),
    STOCK_STORE_AND_TRANSIT: t('exceptionTable.stock_store_and_transit'),
  };

  const StocksOptionsLabels: StocksOptionsLabelsProps = {
    OPTIMAL_STOCK: t('exceptionTable.ideal_stock'),
    STOCK_STORE_AND_TRANSIT: t('exceptionTable.stock_store_and_transit'),
  };
  const [, lsSetFilterState] = useLocalStorage<{
    sales?: string;
    stocks?: string;
  }>('exceptionsFilters', {
    sales: tableDataSettings?.salesEnum,
    stocks: tableDataSettings?.stockEnum,
  });

  const [isEveryStoreIncluded, setIsEveryStoreIncluded] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState({
    stockDepotRetail: false,
    storeManagement: false,
  });

  const showModal = useMemo(
    () => ({
      STOCK_DEPOT_RETAIL: () =>
        setIsModalOpen((prevState) => ({
          ...prevState,
          stockDepotRetail: true,
        })),
      STORE_MANAGEMENT: () =>
        setIsModalOpen((prevState) => ({
          ...prevState,
          storeManagement: true,
        })),
    }),
    [],
  );

  const hideModal = useMemo(
    () => ({
      STOCK_DEPOT_RETAIL: () =>
        setIsModalOpen((prevState) => ({
          ...prevState,
          stockDepotRetail: false,
        })),
      STORE_MANAGEMENT: () =>
        setIsModalOpen((prevState) => ({
          ...prevState,
          storeManagement: false,
        })),
    }),
    [],
  );

  const handleSalesSelectChange = (value: string) => {
    setTableDataSettings((prevState: ExceptionOptimalStockTableDto) => ({
      ...prevState,
      salesEnum: value,
    }));
    lsSetFilterState((prevState) => ({ sales: value, stocks: prevState?.stocks }));
  };

  const handleStockSelectChange = (value: string) => {
    setTableDataSettings((prevState: ExceptionOptimalStockTableDto) => ({
      ...prevState,
      stockEnum: value,
    }));
    lsSetFilterState((prevState) => ({ stocks: value, sales: prevState?.sales }));
  };

  const handleFilterStoreExceptionChange = (event: CheckboxChangeEvent) => {
    setTableDataSettings((prevState: ExceptionOptimalStockTableDto) => ({
      ...prevState,
      hasException: event.target.checked,
    }));
  };

  const checkEveryStoreIncluded = useCallback(() => {
    const isEveryStoreIncludedChecker = tablesData?.exceptionOptimalStock.every((x: any) => x.isIncluded);

    setIsEveryStoreIncluded(isEveryStoreIncludedChecker);
  }, [tablesData]);

  useEffect(() => {
    checkEveryStoreIncluded();
  }, [checkEveryStoreIncluded]);

  return (
    <>
      <StyledActionsWrapper>
        <ActionElementContainer>
          <ActionsElementWrapper>
            <ActionsElementTitle>{t('exceptionTable.sales')}</ActionsElementTitle>
            <Select
              value={SalesOptionsLabels[tableDataSettings?.salesEnum]}
              style={{ width: '150px', fontSize: 12 }}
              onChange={handleSalesSelectChange}
              size="small"
            >
              {SalesOptionsAsArray?.map((option) => (
                <Option key={option[0]} value={option[1]}>
                  {SalesOptionsLabels[option[1]]}
                </Option>
              ))}
            </Select>
          </ActionsElementWrapper>

          <ActionsElementWrapper>
            <ActionsElementTitle>{t('exceptionTable.number_of_weeks_of_sales')}</ActionsElementTitle>
            <span style={{ fontWeight: 'bold' }}>{numberOfWeeksInSale}</span>
          </ActionsElementWrapper>
        </ActionElementContainer>

        <ActionElementContainer style={{ justifyContent: 'center' }}>
          <div style={{ display: 'flex', gap: '20px' }}>
            <PrimaryButton
              onClick={showModal['STOCK_DEPOT_RETAIL']}
              style={{ fontSize: 12 }}
              key="stock_depot_retail"
              icon={<InboxOutlined />}
              text={t('exceptionTable.stock_depot_retail')}
            ></PrimaryButton>
            <PrimaryButton
              onClick={showModal['STORE_MANAGEMENT']}
              disabled={isEveryStoreIncluded}
              style={{ fontSize: 12 }}
              key="store_management"
              icon={<ShopOutlined />}
              text={t('exceptionTable.store_management')}
            ></PrimaryButton>
          </div>
        </ActionElementContainer>

        <ActionElementContainer style={{ justifyContent: 'space-between' }}>
          <ActionsElementWrapper>
            <ActionsElementTitle>{t('exceptionTable.stocks')}</ActionsElementTitle>
            <Select
              defaultValue={StocksOptionsLabels[tableDataSettings?.stockEnum]}
              style={{ width: '150px', fontSize: 12 }}
              onChange={handleStockSelectChange}
              size="small"
            >
              {StocksOptionsAsArray?.map((option) => (
                <Option key={option[0]} value={option[1]}>
                  {StocksOptionsLabels[option[1]]}
                </Option>
              ))}
            </Select>
          </ActionsElementWrapper>
          <ActionsElementWrapper>
            <Checkbox
              style={{ fontSize: 12 }}
              textKey="exceptionTable.filter_mag_exception"
              onChange={handleFilterStoreExceptionChange}
              checked={tableDataSettings?.hasException}
            />
          </ActionsElementWrapper>
        </ActionElementContainer>
      </StyledActionsWrapper>
      <StockDepotRetailModal isOpen={isModalOpen.stockDepotRetail} handleCancel={hideModal['STOCK_DEPOT_RETAIL']} />
      <StoreManagementModal isOpen={isModalOpen.storeManagement} handleCancel={hideModal['STORE_MANAGEMENT']} />
    </>
  );
};

export default ActionsWrapper;
