import React, { useCallback } from 'react';
import { useContext } from 'react';

import { ProductContext, PermissionsContext } from 'context';

import DistributionGrid from 'components/Grids/DistributionGrid/DistributionGrid';
import { COMMON_DEFAULT_COL_DEFS } from 'screens/ProductDetails/common/constants/optimalStockCalculationTable';
import { SizeSystemRowHeader, useUpdateProductSizeSystemMutation, PermissionsEnum } from 'services/graphql/main';
import { useError } from 'services/utils';

type Props = {
  isProductArchived: boolean;
};

const DistributionBySizeTable: React.FC<Props> = ({ isProductArchived }) => {
  const { addError } = useError();
  const { setSizeSystemRowId, setSizeSystemRowName, sizeSystemRowName, productId, setVisibleSizeSystemsColsIds } =
    useContext(ProductContext);
  const { permissions } = useContext(PermissionsContext);

  const [updateProductSizeSystemMutation] = useUpdateProductSizeSystemMutation({
    onError: (err) => addError(err, 'warning'),
  });

  const handleRowSelected = useCallback(
    async (row: any, sizeSystemRowHeaders: SizeSystemRowHeader[] | undefined | null) => {
      if (!productId || !row || !sizeSystemRowHeaders) return;

      const { id, name } = row;

      (permissions as PermissionsEnum[])?.includes(PermissionsEnum.ProductPerformanceDetailsEdit) &&
        !isProductArchived &&
        (await updateProductSizeSystemMutation({
          variables: {
            productId,
            sizeSystemRowId: id,
          },
        }));

      setSizeSystemRowId(id);
      name && setSizeSystemRowName(name);

      const visibleSizeCols: number[] = [];
      sizeSystemRowHeaders.forEach(({ id }) => {
        // if (row[id] && row[id] > 0) {
        visibleSizeCols.push(id);
        // }
      });

      setVisibleSizeSystemsColsIds(visibleSizeCols);
    },
    [
      productId,
      setSizeSystemRowId,
      setSizeSystemRowName,
      setVisibleSizeSystemsColsIds,
      updateProductSizeSystemMutation,
      permissions,
      isProductArchived,
    ],
  );

  return (
    <DistributionGrid
      hideItemSelector
      hideAddDeleteControls
      disableEditableMode
      additionalColDefs={[{}, { minWidth: 100 }]}
      defaultColDefOverrides={{ ...COMMON_DEFAULT_COL_DEFS }}
      onRowSelect={handleRowSelected}
      sizeSystemRowName={sizeSystemRowName}
      setVisibleSizeSystemsColsIds={setVisibleSizeSystemsColsIds}
      disableNewRowAdd={
        !(permissions as PermissionsEnum[])?.includes(PermissionsEnum.ProductPerformanceDetailsEdit) ||
        isProductArchived
      }
      domLayout="autoHeight"
    />
  );
};

export default DistributionBySizeTable;
