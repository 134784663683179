import React from 'react';

import {
  ProductContextProvider,
  UniverseContextProvider,
  SeasonContextProvider,
  PermissionsContextProvider,
} from 'context';
import { Outlet } from 'react-router-dom';

import Header from 'layouts/Header/Header';
import { SettingsSider } from 'layouts/Siders';

import { PageWrapper, StyledLayout } from './styles';

type Props = {
  children?: React.ReactNode;
  hasSettingsSlider: boolean;
  noPadding: boolean;
};

const MainLayout = (props: Props) => {
  return (
    <UniverseContextProvider>
      <SeasonContextProvider>
        <PermissionsContextProvider>
          <ProductContextProvider>
            <Header />

            <StyledLayout hasSider>
              {props.hasSettingsSlider && <SettingsSider />}

              <PageWrapper noPadding={props.noPadding}>{props.children ? props.children : <Outlet />}</PageWrapper>
            </StyledLayout>
          </ProductContextProvider>
        </PermissionsContextProvider>
      </SeasonContextProvider>
    </UniverseContextProvider>
  );
};

export default MainLayout;
