import styled from 'styled-components';

export const StyledFiltersContainer = styled.div`
  display: inline-block;
  margin-bottom: 12px;

  .productFilters {
    margin-bottom: 8px;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-size: 12px;
    font-weight: 600;
    padding: 0;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow .ant-form-item-label {
    margin-right: 6px;
  }

  .ant-collapse-header .ant-collapse-arrow {
    margin-right: 5px !important;
  }

  .ant-collapse-header .anticon svg {
    font-size: 10px !important;
  }

  .filtersRow {
    display: flex;
    flex-wrap: nowrap;
    gap: 12px;
  }

  .filtersRow > * {
    flex: 1;
    min-width: 150px;
    max-width: 200px;
  }

  .ant-collapse-content-box {
    padding: 0 0 8px 0 !important;
  }

  .ant-form-item-label {
    padding-bottom: 0;
  }

  .ant-form-item-label > label {
    font-size: 12px;
  }

  .ant-form-item-label > label svg {
    font-size: 16px;
  }

  .storeFilters .ant-space-item:last-of-type {
    align-self: flex-end;
  }

  .searchButton {
    float: right;
  }

  & .ant-collapse-header-text,
  & .ant-collapse-expand-icon,
  & .labelText {
    color: ${(p) => p.theme.white};
  }
`;
