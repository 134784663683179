import React, { useState, useMemo, useContext, useEffect, useRef, useCallback } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { ColDef, ICellRendererParams, CellEditingStartedEvent, CellEditingStoppedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { AlphanumericCellEditor } from 'components/AgGridComponents';
import { ConfirmModal } from 'components/Modals';
import { Spinner, PrimaryButton, NakedButton } from 'components/UI';
import { ActionButtonsWrapper } from 'components/wrappers';
import { VALIDATIONS_TYPES } from 'constants/validations';
import { SizeSystemContext } from 'context/size-system-context';
import { UniverseContext } from 'context/universe-context';
import { forbidEmptyCellValueSetter } from 'helpers/gridCells';
import { sortEmpty } from 'helpers/gridCols';
import { partiallyEditedGridData } from 'helpers/gridData';
import { validateGridCustom } from 'helpers/validations';
import {
  useGetSizeSystemsQuery,
  useCreateSizeSystemMutation,
  useDeleteSizeSystemMutation,
  useBulkUpdateSizeSystemsMutation,
  SizeSystem,
  useUpdateSizeSystemsMutation,
  OriginType,
} from 'services/graphql/main';
import { useError } from 'services/utils';

import { MutationStateProps, GridDataProps, ConstructorObjectProps } from '../common/types';
import { AdaptiveGridResizer } from './styles';

const SizeSystemsTable: React.FC = () => {
  const { t } = useTranslation('translation');
  const { addCustomError, addError } = useError();
  const { selectedUniverse } = useContext(UniverseContext);
  const {
    setSelectedSizeSystem,
    isDistributionTableUpdateActive,
    setIsSizeSystemUpdateActive,
    setSelectedSizeSystemOrigin,
  } = useContext(SizeSystemContext);

  const initialGridData = useRef<GridDataProps>(null);
  const gridRef = useRef<AgGridReact<SizeSystem>>(null);

  const { loading, data, refetch } = useGetSizeSystemsQuery({
    onError: (err) => addError(err, 'error'),
  });
  const [createSizeSystem, { error: createError }] = useCreateSizeSystemMutation({
    onError: (err) => addError(err, 'warning'),
  });
  const [bulkUpdateSizeSystems, { error: bulkUpdateError }] = useBulkUpdateSizeSystemsMutation({
    onError: (err) => addError(err, 'warning'),
  });
  const [updateSizeSystems, { error: updateError }] = useUpdateSizeSystemsMutation({
    onError: (err) => addError(err, 'warning'),
  });

  const [deleteSizeSystem] = useDeleteSizeSystemMutation({
    onError: (err) => addError(err, 'warning'),
  });

  const [rowData, setRowData] = useState<(SizeSystem | null)[] | GridDataProps | null | undefined>();

  const [currentEditedRow, setCurrentEditedRow] = useState<number | null>(null);

  const [selectedRowForDeletion, setSelectedRowForDeletion] = useState({ id: 0, sortNumber: 0 });
  const [isDeleteRowModalOpen, setIsDeleteRowModalOpen] = useState(false);

  const [mutationState, setMutationState] = useState<MutationStateProps | null>(null);

  const [isUpdate, setIsUpdate] = useState({ isCreateRowActive: false, isCellActive: false });
  const hasUpdate = !isEmpty(mutationState?.data);

  const [errors, setErrors] = useState<any>(null);

  const showModal = () => {
    setIsDeleteRowModalOpen(true);
  };

  const hideModal = () => {
    setIsDeleteRowModalOpen(false);
  };

  const handleSaveMutation = useCallback(async () => {
    const gridData = gridRef
      .current!.api.getRenderedNodes()
      .map((x) => x.data)
      .map((y) => {
        return {
          code: y?.code,
          name: y?.name,
        };
      });

    const { DUPLICATES, EMPTY_CELL, LENGTH } = VALIDATIONS_TYPES;

    const validations = [DUPLICATES, EMPTY_CELL, LENGTH];
    const validation = validateGridCustom(validations, gridData);

    if (validation?.isValid === false) {
      addCustomError({ message: validation?.code }, 'warning');
    } else if (validation?.isValid) {
      if (mutationState) {
        switch (mutationState.type) {
          case 'create-row':
            await createSizeSystem({
              variables: { sizeSystem: mutationState.data },
            });

            await refetch();

            setMutationState(null);
            break;
          case 'update-row':
            if (mutationState?.data?.length > 1)
              await bulkUpdateSizeSystems({
                variables: {
                  inputSizeSystems: mutationState.data,
                },
              });
            else await updateSizeSystems({ variables: { inputSizeSystem: mutationState?.data[0] } });
            await refetch();

            setMutationState(null);
            break;
          default:
            break;
        }

        setIsUpdate((prevState) => ({
          ...prevState,
          isCreateRowActive: false,
        }));

        setIsSizeSystemUpdateActive(false);
        setCurrentEditedRow(null);
      }
    }
  }, [addCustomError, bulkUpdateSizeSystems, createSizeSystem, mutationState, refetch, setIsSizeSystemUpdateActive]);

  const onSelectionChanged = useCallback(() => {
    if (!isDistributionTableUpdateActive) {
      const selectedRows = gridRef.current!.api.getSelectedRows();

      if (selectedRows[0]) {
        setSelectedSizeSystem(selectedRows[0].id);
        setSelectedSizeSystemOrigin(selectedRows[0].origin);
      }
    }
  }, [isDistributionTableUpdateActive, setSelectedSizeSystem, setSelectedSizeSystemOrigin]);

  const handleAddRow = useCallback(() => {
    const constructorObject: ConstructorObjectProps = {
      code: '',
      name: '',
      universeId: selectedUniverse.id,
    };

    setIsUpdate((prevState) => ({
      ...prevState,
      isCreateRowActive: true,
    }));

    setIsSizeSystemUpdateActive(true);

    setMutationState({
      type: 'create-row',
      data: {
        code: '',
        name: '',
        universeId: selectedUniverse.id,
      },
    });

    gridRef.current!.api.applyTransaction({
      // @ts-ignore
      add: [constructorObject],
    });

    const gridData = gridRef.current!.api.getRenderedNodes();

    setTimeout(() => {
      gridRef.current!.api.startEditingCell({
        rowIndex: gridData.length - 1,
        colKey: 'code',
      });
    }, 1);
  }, [selectedUniverse.id, setIsSizeSystemUpdateActive]);

  const onCellEditingStarted = useCallback(
    (event: CellEditingStartedEvent) => {
      if (event.data.origin === OriginType.Imported && event.colDef.field === 'code') {
        gridRef.current!.api.stopEditing();

        return;
      }

      if (currentEditedRow === null) {
        setCurrentEditedRow(event.rowIndex);
      }

      setIsUpdate((prevState) => ({
        ...prevState,
        isCellActive: true,
      }));
    },
    [currentEditedRow],
  );

  const onCellEditingStopped = useCallback(
    (event: CellEditingStoppedEvent) => {
      if (mutationState?.type === 'create-row') {
        setMutationState({
          type: 'create-row',
          data: {
            code: event.data.code,
            name: event.data.name,
            universeId: selectedUniverse.id,
          },
        });
      } else if (mutationState === null || mutationState?.type === 'update-row') {
        const editedRows = partiallyEditedGridData({
          event,
          prevRows: mutationState?.data,
          initialRows: initialGridData.current,
        });
        if (editedRows) {
          setMutationState({
            type: 'update-row',
            data: editedRows,
          });
        }
      }

      setIsUpdate((prevState) => ({
        ...prevState,
        isCellActive: false,
      }));
    },
    [mutationState, selectedUniverse.id],
  );

  const handleRemoveRow = useCallback(async () => {
    if (selectedRowForDeletion.id != 0) {
      await deleteSizeSystem({ variables: { id: selectedRowForDeletion.id } });
      await refetch();

      setSelectedSizeSystem(null);
      setSelectedSizeSystemOrigin(null);
    }

    setSelectedRowForDeletion({ id: 0, sortNumber: 0 });
    hideModal();
    setIsUpdate((prevState) => ({
      ...prevState,
      isCreateRowActive: false,
    }));
  }, [deleteSizeSystem, refetch, selectedRowForDeletion.id, setSelectedSizeSystem, setSelectedSizeSystemOrigin]);

  const RemoveRenderer = useCallback(
    (props: ICellRendererParams) => {
      const handlePrepareRemoveRow = (props: ICellRendererParams) => {
        setSelectedRowForDeletion(props.node.data);
        showModal();
      };

      return (
        <NakedButton
          disabled={isUpdate.isCreateRowActive || isDistributionTableUpdateActive || hasUpdate}
          type="text"
          onClick={() => handlePrepareRemoveRow(props)}
        >
          <DeleteOutlined style={{ cursor: 'pointer', textAlign: 'center' }} />
        </NakedButton>
      );
    },
    [hasUpdate, isDistributionTableUpdateActive, isUpdate.isCreateRowActive],
  );

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        field: 'code',
        ...(!mutationState === null && { sort: 'asc' }),
        comparator: sortEmpty,
        headerName: t('operationalSettings.code'),
        valueSetter: forbidEmptyCellValueSetter,
        cellEditor: AlphanumericCellEditor,
      },
      {
        field: 'name',
        comparator: sortEmpty,
        headerName: t('operationalSettings.long_label'),
        valueSetter: forbidEmptyCellValueSetter,
      },
      {
        suppressMenu: true,
        sortable: false,
        cellStyle: { textAlign: 'center' },
        maxWidth: 60,
        editable: false,
        cellRenderer: RemoveRenderer,
        pinned: 'right',
      },
    ],
    [RemoveRenderer, mutationState, t],
  );

  const columnTypes = useMemo<{
    [key: string]: ColDef;
  }>(() => {
    return {
      valueColumn: {
        editable: true,
        valueParser: 'newValue',
      },
    };
  }, []);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100,
      editable: true,
      suppressMovable: true,
      sortable: true,
    };
  }, []);

  const handleCancelButton = useCallback(() => {
    if (mutationState?.type === 'create-row') {
      const lastRowIndex = gridRef.current!.api.getLastDisplayedRow();
      const lastRow = gridRef.current!.api.getDisplayedRowAtIndex(lastRowIndex);

      gridRef.current?.api.applyTransaction({ remove: [lastRow?.data!] });

      const mutableData =
        data &&
        data!.sizeSystems?.map((x) => ({
          id: x?.id,
          code: x?.code,
          name: x?.name,
          origin: x?.origin,
        }));

      mutableData && gridRef.current?.api.setRowData(mutableData);

      setIsSizeSystemUpdateActive(false);

      setRowData(mutableData);
    } else {
      const mutableData =
        data &&
        data!.sizeSystems?.map((x) => ({
          id: x?.id,
          code: x?.code,
          name: x?.name,
          origin: x?.origin,
        }));

      mutableData && gridRef.current?.api.setRowData(mutableData);

      setRowData(mutableData);
    }

    setIsUpdate((prevState) => ({
      ...prevState,
      isCreateRowActive: false,
      isUpdateRowActive: false,
      isCellActive: false,
    }));

    setMutationState(null);
  }, [data, mutationState?.type, setIsSizeSystemUpdateActive]);

  useEffect(() => {
    const mutableData: GridDataProps =
      data &&
      data!.sizeSystems?.map((x) => ({
        id: x?.id,
        code: x?.code,
        name: x?.name,
        origin: x?.origin,
      }));

    const gridData =
      data &&
      data!.sizeSystems?.map((x) => ({
        id: x?.id,
        code: x?.code,
        name: x?.name,
      }));

    initialGridData.current = gridData;
    data && setRowData(mutableData);
  }, [data]);

  useEffect(() => {
    createError?.graphQLErrors || bulkUpdateError?.graphQLErrors || updateError?.graphQLErrors
      ? setErrors(true)
      : setErrors(null);
  }, [bulkUpdateError?.graphQLErrors, createError?.graphQLErrors, updateError?.graphQLErrors]);

  return loading ? (
    <Spinner size="large" />
  ) : rowData?.length ? (
    <AdaptiveGridResizer>
      <AgGridReact
        containerStyle={{ width: '100%', marginTop: 15, marginBottom: 5, height: 'auto' }}
        ref={gridRef}
        columnTypes={columnTypes}
        columnDefs={columnDefs}
        rowData={rowData}
        defaultColDef={defaultColDef}
        domLayout={'autoHeight'}
        suppressRowVirtualisation={true}
        suppressRowClickSelection={isDistributionTableUpdateActive}
        stopEditingWhenCellsLoseFocus={true}
        onCellEditingStarted={onCellEditingStarted}
        onCellEditingStopped={onCellEditingStopped}
        onSelectionChanged={onSelectionChanged}
        rowSelection={'single'}
        enableRangeSelection={true}
      />

      <ActionButtonsWrapper>
        <NakedButton
          type="text"
          textKey={t('operationalSettings.add_size_system')}
          disabled={isUpdate.isCreateRowActive || isDistributionTableUpdateActive || hasUpdate || errors}
          onClick={handleAddRow}
          style={{ color: '#ffffff', marginRight: 'auto' }}
        />
        {isUpdate.isCreateRowActive || hasUpdate ? (
          <>
            <NakedButton
              type="text"
              onClick={handleCancelButton}
              textKey="common.cancel"
              style={{ color: '#ffffff' }}
            />

            <PrimaryButton
              onClick={handleSaveMutation}
              textKey="common.save"
              disabled={isUpdate.isCellActive}
            ></PrimaryButton>
          </>
        ) : null}
      </ActionButtonsWrapper>

      <ConfirmModal
        customTitle={t('operationalSettings.delete_row_warning_message')}
        onOk={handleRemoveRow}
        open={isDeleteRowModalOpen}
        onCancel={hideModal}
      />
    </AdaptiveGridResizer>
  ) : null;
};

export default SizeSystemsTable;
