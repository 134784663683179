import React, { useCallback, useEffect, useState, useContext, useMemo } from 'react';

import { CloseCircleOutlined } from '@ant-design/icons';
import {
  ColDef,
  GetRowIdFunc,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  RowDragEndEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';

import { Spinner, NakedButton } from 'components/UI';
import { COLUMN_TYPES } from 'constants/gridConstants';
import { SizeSystemContext } from 'context/size-system-context';
import {
  useGetFamiliesQuery,
  useGetFamilyToSizeSystemQuery,
  useAssignFamilyToSizeSystemMutation,
  useRemoveFamilyFromSizeSystemMutation,
  Family,
} from 'services/graphql/main';
import { useError } from 'services/utils';

import { TableWrapper, GridWrapper, NoSizeSystemSelectedMessage } from './styles';

const SelectedFamilies: React.FC = () => {
  const { t } = useTranslation('translation');
  const { addError } = useError();

  const { selectedSizeSystem } = useContext(SizeSystemContext);

  const {
    loading: loadingFamilies,
    data: dataFamilies,
    refetch: refetchFamilies,
  } = useGetFamiliesQuery({ onError: (err) => addError(err, 'error') });

  const {
    loading: loadingSizeSystemFamilies,
    data: dataSizeSystemFamilies,
    refetch: refetchSizeSystemFamilies,
  } = useGetFamilyToSizeSystemQuery({
    variables: { sizeSystemId: selectedSizeSystem ? selectedSizeSystem : 0 },
    onError: (err) => addError(err, 'error'),
  });

  const [assignFamilyToSizeSystem] = useAssignFamilyToSizeSystemMutation({
    onError: (err) => addError(err, 'warning'),
  });
  const [removeFamilyFromSizeSystem] = useRemoveFamilyFromSizeSystemMutation({
    onError: (err) => addError(err, 'warning'),
  });

  const [leftApi, setLeftApi] = useState<GridApi | null>(null);
  const [rightApi, setRightApi] = useState<GridApi | null>(null);
  const [families, setFamilies] = useState<(Family | null)[] | null | undefined>();
  const [sizeSystemFamilies, setSizeSystemFamilies] = useState<(Family | null)[] | null | undefined>();
  const [leftRowData, setLeftRowData] = useState<(Family | null)[] | null | undefined>(null);
  const [rightRowData, setRightRowData] = useState<(Family | null)[] | null | undefined>();

  useEffect(() => {
    dataSizeSystemFamilies && setSizeSystemFamilies(dataSizeSystemFamilies?.familyToSizeSystem);

    dataFamilies && setFamilies(dataFamilies.families);
  }, [dataFamilies, dataSizeSystemFamilies]);

  const RemoveAllRenderer = (props: ICellRendererParams) => {
    const handleRemoveFamilies = async (props: ICellRendererParams) => {
      if (selectedSizeSystem) {
        sizeSystemFamilies?.forEach(async (node) => {
          await removeFamilyFromSizeSystem({
            variables: { familyId: node!.id, sizeSystemId: selectedSizeSystem },
          });
        });

        await refetchFamilies();
        await refetchSizeSystemFamilies();

        props.api.setRowData([]);
      }
    };

    return sizeSystemFamilies!.length > 0 ? (
      <NakedButton type="text" onClick={() => handleRemoveFamilies(props)}>
        <CloseCircleOutlined style={{ cursor: 'pointer' }} />
      </NakedButton>
    ) : null;
  };

  const RemoveRenderer = (props: ICellRendererParams) => {
    const handleRemoveFamily = async (props: ICellRendererParams) => {
      if (selectedSizeSystem) {
        await removeFamilyFromSizeSystem({
          variables: { familyId: props.node.data.id, sizeSystemId: selectedSizeSystem },
        });

        await refetchFamilies();
        await refetchSizeSystemFamilies();
        props.api.applyTransaction({ remove: [props.node.data] });
      }
    };

    return (
      <NakedButton type="text" onClick={() => handleRemoveFamily(props)}>
        <CloseCircleOutlined style={{ cursor: 'pointer' }} />
      </NakedButton>
    );
  };

  const leftColumns: ColDef[] = [
    {
      colId: 'checkbox',
      maxWidth: 50,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    { field: 'code', headerName: t('operationalSettings.family_code') },
    { field: 'name', headerName: t('operationalSettings.family_label') },
    {
      rowDrag: true,
      maxWidth: 50,
      suppressMenu: true,
      rowDragText: (params, dragItemCount) => {
        if (dragItemCount > 1) {
          return dragItemCount + ` ${t('operationalSettings.families')}`;
        }

        return params.rowNode?.data.label;
      },
    },
  ];

  const rightColumns: ColDef[] = [
    { field: 'code', headerName: t('operationalSettings.family_code') },
    { field: 'name', headerName: t('operationalSettings.family_label') },
    {
      headerComponent: RemoveAllRenderer,
      suppressMenu: true,
      maxWidth: 60,
      cellRenderer: RemoveRenderer,
    },
  ];

  const defaultColDef: ColDef = {
    flex: 1,
    minWidth: 100,
    sortable: true,
    suppressMovable: true,
  };

  const loadGrids = useCallback(() => {
    const filteredFamilies = families && families.filter((x) => !sizeSystemFamilies?.find((y) => x?.id === y?.id));

    setLeftRowData(filteredFamilies ? [...filteredFamilies] : []);
    setRightRowData(sizeSystemFamilies ? [...sizeSystemFamilies] : []);
    leftApi?.deselectAll();
  }, [leftApi, families, sizeSystemFamilies]);

  useEffect(() => {
    if (families?.length) {
      loadGrids();
    }
  }, [families, sizeSystemFamilies, loadGrids]);

  const getRowId = useMemo<GetRowIdFunc>(() => {
    return (params: GetRowIdParams) => params.data.code;
  }, []);

  const onDragStop = useCallback(
    async (params: RowDragEndEvent) => {
      const nodes = params.nodes;

      if (selectedSizeSystem) {
        nodes?.forEach(async (node) => {
          await assignFamilyToSizeSystem({
            variables: { familyId: node.data.id, sizeSystemId: selectedSizeSystem },
          });
        });

        await refetchFamilies();
        await refetchSizeSystemFamilies();

        leftApi?.applyTransaction({
          remove: nodes.map(function (node) {
            return node.data;
          }),
        });
      }
    },
    [assignFamilyToSizeSystem, leftApi, refetchFamilies, refetchSizeSystemFamilies, selectedSizeSystem],
  );

  useEffect(() => {
    if (!leftApi || !rightApi) {
      return;
    }
    const dropZoneParams = rightApi.getRowDropZoneParams({ onDragStop });

    leftApi.removeRowDropZone(dropZoneParams);
    leftApi.addRowDropZone(dropZoneParams);
  }, [leftApi, rightApi, onDragStop]);

  const onGridReady = (params: GridReadyEvent, side: number) => {
    if (side === 0) {
      setLeftApi(params.api);
    }

    if (side === 1) {
      setRightApi(params.api);
    }
  };

  const getGridWrapper = (id: number) => (
    <TableWrapper className="panel panel-primary">
      <p>{t(id === 0 ? 'operationalSettings.available_families' : 'operationalSettings.selected_families')}</p>
      <div className="panel-body">
        <AgGridReact
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          rowDragManaged={true}
          animateRows={true}
          rowSelection={id === 0 ? 'multiple' : undefined}
          rowDragMultiRow={id === 0}
          suppressRowClickSelection={id === 0}
          suppressMoveWhenRowDragging={id === 0}
          rowData={id === 0 ? leftRowData : rightRowData}
          columnDefs={id === 0 ? leftColumns : rightColumns}
          onGridReady={(params) => onGridReady(params, id)}
          columnTypes={COLUMN_TYPES}
          enableRangeSelection={true}
        />
      </div>
    </TableWrapper>
  );

  return selectedSizeSystem === null ? (
    <NoSizeSystemSelectedMessage>
      <p>{t('operationalSettings.no_size_system_selected')}</p>
    </NoSizeSystemSelectedMessage>
  ) : loadingFamilies || loadingSizeSystemFamilies ? (
    <Spinner size="large" />
  ) : (
    <GridWrapper>
      {getGridWrapper(0)}
      {getGridWrapper(1)}
    </GridWrapper>
  );
};

export default SelectedFamilies;
