import { Select } from 'antd';
import styled from 'styled-components';

import { GRID_MAX_HEIGHT } from 'constants/gridConstants';

export const GridWrapper = styled.div`
  div.ag-root .ag-body-viewport {
    max-height: ${GRID_MAX_HEIGHT};
    overflow-y: scroll;
  }

  .ag-header-viewport {
    flex: unset;
  }

  .ag-pinned-right-header {
    min-width: unset !important;
    max-width: unset !important;
  }
`;

export const StyledSelect = styled(Select)`
  width: 100%;

  .ant-select-selector {
    padding-left: 0 !important;
  }
`;
