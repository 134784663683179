import React, { useCallback, useContext, useState } from 'react';

import { Segmented, Space } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import { TableViewsContext } from 'context';
import { isEmpty } from 'lodash';
import styled from 'styled-components';

import { PrimaryButton } from 'components/UI';
import { ViewId, ViewsColDefProps } from 'types/views';

import ViewsConfigurationModal from './ViewsConfigurationModal/ViewsConfigurationModal';

const StyledSegmented = styled(Segmented)`
  & .ant-segmented-item {
    background-color: ${(p) => p.theme.dark_blue_background};
    color: ${(p) => p.theme.white};
  }

  & .ant-segmented-item-selected {
    background-color: ${(p) => p.theme.white};
    color: ${(p) => p.theme.black};
  }
`;

const Views: React.FC<ViewsColDefProps> = ({ flatColDefs, separatedColDefs, suppressColMovingForGrids }) => {
  const [isViewsConfigurationModalOpen, setIsViewsConfigurationModalOpen] = useState(false);

  const { selectedViewId, setSelectedViewId, clearSelectedViewId, views, getView } = useContext(TableViewsContext);

  const handleViewChange = useCallback(
    (viewId: SegmentedValue) => {
      setSelectedViewId(viewId as ViewId);
    },
    [setSelectedViewId],
  );

  const handleViewClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const target = e.target as HTMLDivElement;
      const clickedViewName = target.textContent;

      const currentView = getView();

      if (clickedViewName === currentView?.name) {
        clearSelectedViewId();
        e.preventDefault();
      }
    },
    [clearSelectedViewId, getView],
  );

  return (
    <>
      <Space>
        <PrimaryButton
          style={{ fontSize: 12 }}
          onClick={() => setIsViewsConfigurationModalOpen(true)}
          textKey="storeFleet.select_columns"
          size="small"
        />

        {!isEmpty(views) && (
          <StyledSegmented
            value={selectedViewId || ''}
            options={views.map((view) => ({ value: view.id, label: view.name }))}
            onChange={handleViewChange}
            onClick={handleViewClick}
            size="small"
          />
        )}
      </Space>

      <ViewsConfigurationModal
        isOpen={isViewsConfigurationModalOpen}
        close={() => setIsViewsConfigurationModalOpen(false)}
        flatColDefs={flatColDefs}
        separatedColDefs={separatedColDefs}
        suppressColMovingForGrids={suppressColMovingForGrids}
      />
    </>
  );
};

export default Views;
