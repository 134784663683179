import React, { useState, useEffect, useContext } from 'react';

import { ProductContext, TableViewsContextProvider, StoreManagementContext } from 'context';
import { useTranslation } from 'react-i18next';

import { Spinner } from 'components/UI';
import {
  GetExceptionOptimalStockTableQuery,
  ExceptionOptimalStockSalesEnum,
  ExceptionOptimalStockStockEnum,
} from 'services/graphql/main';

import ActionsWrapper from './ActionsWrapper/ActionsWrapper';
import ExceptionsTable from './ExceptionsTable/ExceptionsTable';

type Props = {
  isProductArchived: boolean;
};

const ExceptionStockContent: React.FC<Props> = ({ isProductArchived }) => {
  const { t } = useTranslation('translation');

  const { productId, selectedProductModuleId } = useContext(ProductContext);
  const {
    getExceptionOptimalStockTableLazyQuery,
    dataExceptionOptimalStockTable,
    loadingExceptionOptimalStockTable,
    refetchExceptionOptimalStockTable,
  } = useContext(StoreManagementContext);

  const [tableData, setTableData] = useState<GetExceptionOptimalStockTableQuery | null>(null);
  const [tableDataSettings, setTableDataSettings] = useState({
    salesEnum: ExceptionOptimalStockSalesEnum.SeasonTotal,
    stockEnum: ExceptionOptimalStockStockEnum.OptimalStock,
    hasException: false,
  });

  useEffect(() => {
    if (productId) {
      getExceptionOptimalStockTableLazyQuery({
        variables: {
          productId: productId,
          salesEnum: tableDataSettings.salesEnum,
          stockEnum: tableDataSettings.stockEnum,
          hasException: tableDataSettings.hasException,
        },
      });

      dataExceptionOptimalStockTable && setTableData(dataExceptionOptimalStockTable);
    }
  }, [getExceptionOptimalStockTableLazyQuery, productId, selectedProductModuleId, tableDataSettings]);

  useEffect(() => {
    !loadingExceptionOptimalStockTable &&
      dataExceptionOptimalStockTable &&
      productId &&
      refetchExceptionOptimalStockTable({
        productId: productId,
        salesEnum: tableDataSettings.salesEnum,
        stockEnum: tableDataSettings.stockEnum,
        hasException: tableDataSettings.hasException,
      });

    dataExceptionOptimalStockTable && setTableData(dataExceptionOptimalStockTable);
  }, [
    tableDataSettings,
    productId,
    refetchExceptionOptimalStockTable,
    dataExceptionOptimalStockTable,
    loadingExceptionOptimalStockTable,
  ]);

  return loadingExceptionOptimalStockTable ? (
    <Spinner size="large" />
  ) : (
    <TableViewsContextProvider tableName="productDetailsExceptions">
      <ActionsWrapper
        setTableDataSettings={setTableDataSettings}
        tableDataSettings={tableDataSettings}
        tablesData={tableData?.exceptionOptimalStockTable}
        numberOfWeeksInSale={tableData?.exceptionOptimalStockTable.numberOfWeeksInSale}
      />
      <ExceptionsTable
        productId={productId}
        tableDataSettings={tableDataSettings}
        tablesData={tableData?.exceptionOptimalStockTable}
        isProductArchived={isProductArchived}
      />
    </TableViewsContextProvider>
  );
};

export default ExceptionStockContent;
