import React, { useState, useEffect, useContext } from 'react';

import { ProductContext } from 'context';

import {
  useGetExceptionOptimalStockTableLazyQuery,
  useGetStoreOptimalStockExceptionsLazyQuery,
  useGetStoreStrengthTableLazyQuery,
  ProductOptimalStockTableWrapperDto,
} from 'services/graphql/main';
import { useError } from 'services/utils';

export const StoreManagementContext = React.createContext<any>({} as any);

type StoreManagementProviderType = {
  children: React.ReactNode;
};

export const StoreManagementContextProvider: React.FC<StoreManagementProviderType> = ({ children }) => {
  const { addError } = useError();

  const { productId, selectedProductModuleId } = useContext(ProductContext);

  const [includedStoresRows, setIncludedStoresRowsData] = useState<any>();
  const [excludedStoresRows, setExcludedStoresRowsData] = useState<any>();
  const [storeStrengthTableData, setStoreStrengthTableData] = useState<ProductOptimalStockTableWrapperDto>();

  const [
    getExceptionOptimalStockTableLazyQuery,
    {
      loading: loadingExceptionOptimalStockTable,
      data: dataExceptionOptimalStockTable,
      refetch: refetchExceptionOptimalStockTable,
      error: errorExceptionOptimalStockTable,
    },
  ] = useGetExceptionOptimalStockTableLazyQuery({
    onError: (err) => addError(err, 'error'),
  });

  const [
    getStoreStrengthTableLazy,
    {
      data: dataStoreStrengthTable,
      loading: loadingStoreStrengthTable,
      error: errorStoreStrengthTable,
      refetch: refetchStoreStrengthTable,
    },
  ] = useGetStoreStrengthTableLazyQuery({ onError: (err) => addError(err, 'error') });

  const [
    getStoreOptimalStockExceptionsIncluded,
    { loading: includedStoresLoading, data: includedStoresData, refetch: includedStoresRefetch },
  ] = useGetStoreOptimalStockExceptionsLazyQuery({
    onError: (err) => addError(err, 'error'),
  });

  const [
    getStoreOptimalStockExceptionsExcluded,
    { loading: excludedStoresLoading, data: excludedStoresData, refetch: excludedStoresRefetch },
  ] = useGetStoreOptimalStockExceptionsLazyQuery({
    onError: (err) => addError(err, 'error'),
  });

  useEffect(() => {
    if (productId) {
      getStoreOptimalStockExceptionsExcluded({
        variables: {
          productId: productId,
          isIncluded: false,
        },
      });
      getStoreOptimalStockExceptionsIncluded({
        variables: {
          productId: productId,
          isIncluded: true,
        },
      });
    }
  }, [productId, getStoreOptimalStockExceptionsIncluded, getStoreOptimalStockExceptionsExcluded]);

  useEffect(() => {
    const mutableData: any =
      includedStoresData &&
      includedStoresData!.storeOptimalStockExceptions?.map((x) => ({
        storeCode: x?.storeCode,
        storeGroupName: x?.storeGroupName,
        storeName: x?.storeName,
        storeStrength: x?.storeStrength,
        storeGroupId: x?.storeGroupId,
        storeId: x?.storeId,
      }));

    if (includedStoresData) {
      setIncludedStoresRowsData(mutableData);
      refetchExceptionOptimalStockTable();
    }
  }, [includedStoresData, refetchExceptionOptimalStockTable]);

  useEffect(() => {
    const mutableData: any =
      excludedStoresData &&
      excludedStoresData!.storeOptimalStockExceptions?.map((x) => ({
        storeCode: x?.storeCode,
        storeGroupName: x?.storeGroupName,
        storeName: x?.storeName,
        storeStrength: x?.storeStrength,
        storeGroupId: x?.storeGroupId,
        storeId: x?.storeId,
      }));

    if (excludedStoresData) {
      setExcludedStoresRowsData(mutableData);
      refetchExceptionOptimalStockTable();
    }
  }, [excludedStoresData, refetchExceptionOptimalStockTable]);

  useEffect(() => {
    if (selectedProductModuleId) {
      refetchExceptionOptimalStockTable();
      includedStoresRefetch();
      excludedStoresRefetch();
    }
  }, [excludedStoresRefetch, includedStoresRefetch, refetchExceptionOptimalStockTable, selectedProductModuleId]);

  useEffect(() => {
    includedStoresRefetch();
    excludedStoresRefetch();
  }, [dataExceptionOptimalStockTable, excludedStoresRefetch, includedStoresRefetch]);

  useEffect(() => {
    dataStoreStrengthTable?.storeStrengthTable &&
      setStoreStrengthTableData(dataStoreStrengthTable.storeStrengthTable as ProductOptimalStockTableWrapperDto);
  }, [dataStoreStrengthTable]);

  return (
    <StoreManagementContext.Provider
      value={{
        getExceptionOptimalStockTableLazyQuery,
        dataExceptionOptimalStockTable,
        refetchExceptionOptimalStockTable,
        errorExceptionOptimalStockTable,
        loadingExceptionOptimalStockTable,
        includedStoresRows,
        includedStoresLoading,
        includedStoresRefetch,
        excludedStoresRows,
        excludedStoresLoading,
        excludedStoresRefetch,
        getStoreStrengthTableLazy,
        storeStrengthTableData,
        loadingStoreStrengthTable,
        errorStoreStrengthTable,
        refetchStoreStrengthTable,
      }}
    >
      {children}
    </StoreManagementContext.Provider>
  );
};
