import React, { useContext, useEffect, useState } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { SeasonContext, TableViewsContextProvider } from 'context';
import { useTranslation } from 'react-i18next';

import { Spinner } from 'components/UI';
import { useProductCustomFieldsLazyQuery, useStoreCustomFieldsLazyQuery } from 'services/graphql/main';
import { useError } from 'services/utils';

import OptimalStockAndEstimatedPickingFilters from './components/OptimalStockAndEstimatedPickingFilters/OptimalStockAndEstimatedPickingFilters';
import OptimalStockAndEstimatedPickingTable from './components/OptimalStockAndEstimatedPickingTable/OptimalStockAndEstimatedPickingTable';
import { StyledModal } from './styles';
import { ChangedFilters } from './types';

export interface Props {
  handleCancel: () => void;
  isOpen: boolean;
}

const OptimalStockAndEstimatedPickingModal: React.FC<Props> = ({ isOpen, handleCancel }) => {
  const { t } = useTranslation('translation');
  const { addError } = useError();

  const { selectedSeason } = useContext(SeasonContext);

  const [getProductCustomFieldsLazyQuery, { data: dataProductCustomFields, loading: loadingProductCustomFields }] =
    useProductCustomFieldsLazyQuery({
      onError: (err) => addError(err, 'error'),
    });
  const [getStoreCustomFieldsLazyQuery, { data: dataStoreCustomFields, loading: loadingStoreCustomFields }] =
    useStoreCustomFieldsLazyQuery({
      onError: (err) => addError(err, 'error'),
    });

  const productCustomFieldsData = dataProductCustomFields?.productCustomFields;
  const storeCustomFieldsData = dataStoreCustomFields?.storeCustomFields;

  const [changedFilters, setChangedFilters] = useState<ChangedFilters>({});
  const [shouldApplyFilters, setShouldApplyFilters] = useState(false);

  useEffect(() => {
    if (isOpen) {
      getProductCustomFieldsLazyQuery();
      getStoreCustomFieldsLazyQuery();
      selectedSeason?.name && setChangedFilters({ seasons: selectedSeason?.name });
    }
  }, [getProductCustomFieldsLazyQuery, getStoreCustomFieldsLazyQuery, isOpen, selectedSeason?.name]);

  return isOpen ? (
    <>
      <div className="ant-modal-mask" />
      <StyledModal>
        <div className="ant-modal-header">
          <div className="ant-modal-title">
            <span>{t('optimalStockAndEstimatedPicking.optimal_stock_and_estimated_picking_modal_title')}</span>
            <div className="ant-modal-close">
              <span className="ant-modal-close-x">
                <CloseOutlined onClick={handleCancel} className="ant-modal-close-icon" />
              </span>
            </div>
          </div>
        </div>

        <div className="ant-modal-body">
          {loadingProductCustomFields || loadingStoreCustomFields ? (
            <Spinner />
          ) : (
            <OptimalStockAndEstimatedPickingFilters setChangedFilters={setChangedFilters} />
          )}

          <div className="tableWrapper">
            <TableViewsContextProvider tableName="optimalStockAndEstimatedPicking">
              <OptimalStockAndEstimatedPickingTable
                productCustomFieldsData={productCustomFieldsData}
                storeCustomFieldsData={storeCustomFieldsData}
                changedFilters={changedFilters}
                shouldApplyFilters={shouldApplyFilters}
                setShouldApplyFilters={setShouldApplyFilters}
              />
            </TableViewsContextProvider>
          </div>
        </div>
      </StyledModal>
    </>
  ) : null;
};

export default OptimalStockAndEstimatedPickingModal;
