import styled from 'styled-components';

export const StyledTablesWrapper = styled.div`
  display: flex;

  .ag-center-cols-clipper {
    min-height: 41px !important;
  }

  .ag-center-cols-container {
    min-height: 41px !important;
  }

  .ag-header-cell[col-id='Code']:after {
    display: none;
  }

  .ag-row-pinned:first-of-type {
    font-weight: 600;
    font-size: 12px;
    pointer-events: none;
    border-right-color: #ddd;

    .ant-btn {
      display: none;
    }
  }
`;
