import { Layout, Modal } from 'antd';
import styled from 'styled-components';

import { Tabs } from 'components/UI';

export const StyledModal = styled(Modal)`
  top: 0;
  margin: 0;
  max-width: 100vw;

  .ant-modal-body {
    padding: 0;
  }
`;

export const StyledTabs = styled(Tabs)`
  height: 100%;

  .ant-tabs-nav-wrap {
    position: fixed !important;
    width: 100%;
    margin-top: 8px;
    z-index: 99;
    background-color: white;
  }

  .ant-tabs-tab-btn {
    font-size: 12px;
  }

  .ant-tabs-tab {
    padding: 5px 0;
  }

  .ant-tabs-content-holder {
    margin-top: 45px;
    height: 100%;
  }

  .ant-tabs-content {
    height: 100%;
  }

  .ant-tabs-tabpane-active {
    height: 80%;
  }

  .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-nav:before {
    display: none;
  }

  .ant-tabs-nav-wrap {
    background-color: ${(p) => p.theme.dark_blue_background};
  }
`;

const { Content } = Layout;

export const StyledContent = styled(Content)`
  position: relative;
  padding: 0 20px;
  height: 100%;
`;

export const FloatingNavButtonsWrapper = styled.div`
  position: fixed;
  right: 10px;
  top: 55px;
  background-color: transparent;
  z-index: 999;
  display: flex;
  gap: 12px;

  & > button {
    font-size: 11px;
    height: fit-content;
  }

  & > button:first-of-type {
    margin-right: 10px;
  }

  & > button:not(:first-of-type) {
    font-weight: bold;
  }
`;

export const ExceptionStockLabelWrapper = styled.div`
  display: flex;
  align-items: center;

  & div {
    margin-left: 8px;
    border-radius: 50%;
    background-color: ${(p) => p.theme.primary_yellow};
    color: ${(p) => p.theme.black};
    width: 23px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
