import React, { useCallback, useContext, useMemo } from 'react';

import { Collapse, Form } from 'antd';
import { SeasonContext } from 'context';
import { useTranslation } from 'react-i18next';

import { ChangedFilters, CustomField } from '../../types';
import Filter from '../Filter/Filter';
import { StyledFiltersContainer } from './styles';

const { Panel } = Collapse;

interface Props {
  setChangedFilters: React.Dispatch<React.SetStateAction<ChangedFilters>>;
}

const OptimalStockAndEstimatedPickingFilters: React.FC<Props> = ({ setChangedFilters }) => {
  const { t } = useTranslation('translation');
  const { activeSeasons, selectedSeason } = useContext(SeasonContext);

  const handleFilterChange = useCallback(
    (value: string[], fieldName: string) => {
      setChangedFilters((prevState) => {
        const newState = { ...prevState };

        if (value.length === 0) {
          if (fieldName in newState) delete newState[fieldName];
        } else {
          newState[fieldName] = value;
        }

        return newState;
      });
    },
    [setChangedFilters],
  );

  const seasonsFilter = useMemo(() => {
    return {
      defaultValue: selectedSeason?.name,
      name: 'seasons',
      label: t('optimalStockAndEstimatedPicking.seasons'),
      values: activeSeasons
        ?.map((season) => {
          return { Code: season!.id, Label: season!.name };
        })
        .concat({ Label: t('optimalStockAndEstimatedPicking.all'), Code: -1 }),
      singleSelect: true,
    };
  }, [activeSeasons, selectedSeason?.name, t]) as CustomField;

  const getFilters = (customFields: CustomField[] | null) =>
    customFields?.length && (
      <div className="filtersRow">
        {customFields.map(({ name, values, label, singleSelect, defaultValue }) => (
          <Filter
            defaultValue={defaultValue}
            key={name}
            label={label}
            onChange={(value) => handleFilterChange(value, name)}
            options={values.map(({ Label, Code }) => ({ value: Code, label: Label }))}
            singleSelect={singleSelect}
          />
        ))}
      </div>
    );

  return (
    <StyledFiltersContainer>
      <Form layout="vertical">
        <Collapse defaultActiveKey={['productLevel', 'storeLevel']} ghost>
          <Panel header={t('optimalStockAndEstimatedPicking.product_level')} key="productLevel">
            {getFilters([seasonsFilter])}
          </Panel>
        </Collapse>
      </Form>
    </StyledFiltersContainer>
  );
};

export default OptimalStockAndEstimatedPickingFilters;
