import { useCallback } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { useLocalStorage } from 'react-use';

import { LS_KEY_SORTED_AND_FILTERED_PRODUCT_PERFORMANCE_IDS } from 'constants/gridConstants';

export const useProductIds = (gridRef?: React.RefObject<AgGridReact>) => {
  const [lsPPIds, lsSetPPIds] = useLocalStorage<string[]>(LS_KEY_SORTED_AND_FILTERED_PRODUCT_PERFORMANCE_IDS);

  const setProductIds = useCallback(() => {
    if (!gridRef?.current?.api) return;

    const ids: string[] = [];

    gridRef.current.api.forEachNodeAfterFilterAndSort((node) => {
      return node.data.id && ids.push(node.data.id);
    });

    lsSetPPIds(ids);
  }, [gridRef, lsSetPPIds]);

  return { productIds: lsPPIds, setProductIds };
};
