import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { PrimaryButton } from 'components/UI';
import { useGenerateExportMutation } from 'services/graphql/main';
import { useError } from 'services/utils';

import { PageTitle } from './styles';

const ExportSettings: React.FC = () => {
  const { t } = useTranslation('translation');
  const { addError } = useError();

  const [generateExport, { data, loading }] = useGenerateExportMutation({
    onError: (err) => addError(err, 'warning'),
  });

  useEffect(() => {
    data?.generateExport && toast.success(t('exportSettings.successful_manual_export'), { theme: 'colored' });
  }, [data, t]);

  return (
    <>
      <PageTitle>{t('exportSettings.manual_export')}</PageTitle>
      <PrimaryButton text={t('exportSettings.export')} onClick={async () => await generateExport()} loading={loading} />
    </>
  );
};

export default ExportSettings;
