import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Decimal: any;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER'
}

export type ArticleOptimalStockException = {
  __typename?: 'ArticleOptimalStockException';
  articleId: Scalars['Int'];
  optimalStock: Scalars['Int'];
  storeId: Scalars['Int'];
};

export type ArticleOptimalStockOverride = {
  __typename?: 'ArticleOptimalStockOverride';
  articleId: Scalars['Int'];
  hasCondition: Scalars['Boolean'];
  hasStockMax: Scalars['Boolean'];
  hasStockMin: Scalars['Boolean'];
  id: Scalars['Int'];
  stockMax: Scalars['Int'];
  stockMaxStoreStrengthCondition: Scalars['Int'];
  stockMaxStoreStrengthConditionValue: Scalars['Int'];
  stockMin: Scalars['Int'];
  stockMinStoreStrengthCondition: Scalars['Int'];
  stockMinStoreStrengthConditionValue: Scalars['Int'];
};

export type AuditableInfoDto = {
  __typename?: 'AuditableInfoDto';
  action?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  newValue?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
  user?: Maybe<Scalars['String']>;
};

export type AuditableInfoDtoCollectionSegment = {
  __typename?: 'AuditableInfoDtoCollectionSegment';
  items?: Maybe<Array<Maybe<AuditableInfoDto>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export enum AuditableObjectType {
  Article = 'ARTICLE',
  ArticleOptimalStockException = 'ARTICLE_OPTIMAL_STOCK_EXCEPTION',
  ArticleOptimalStockOverride = 'ARTICLE_OPTIMAL_STOCK_OVERRIDE',
  Collection = 'COLLECTION',
  FamilyToSizeSystem = 'FAMILY_TO_SIZE_SYSTEM',
  Product = 'PRODUCT',
  ProductModule = 'PRODUCT_MODULE',
  SizeSystem = 'SIZE_SYSTEM',
  SizeSystemRow = 'SIZE_SYSTEM_ROW',
  SizeSystemRowHeader = 'SIZE_SYSTEM_ROW_HEADER',
  SizeSystemRowValue = 'SIZE_SYSTEM_ROW_VALUE',
  Store = 'STORE',
  StoreGroup = 'STORE_GROUP',
  StoreGroupToProductModule = 'STORE_GROUP_TO_PRODUCT_MODULE',
  StoreOptimalStockException = 'STORE_OPTIMAL_STOCK_EXCEPTION',
  StoreStrength = 'STORE_STRENGTH',
  StoreStrengthOverride = 'STORE_STRENGTH_OVERRIDE',
  StoreToStoreGroup = 'STORE_TO_STORE_GROUP',
  StoreType = 'STORE_TYPE',
  StoreUniverseInfo = 'STORE_UNIVERSE_INFO',
  TenantSettings = 'TENANT_SETTINGS',
  Warehouse = 'WAREHOUSE'
}

export enum CapacityType {
  NotSet = 'NOT_SET',
  Pieces = 'PIECES',
  SquareMeters = 'SQUARE_METERS'
}

export type Collection = {
  __typename?: 'Collection';
  code?: Maybe<Scalars['String']>;
  combinationType: CombinationType;
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  productModules?: Maybe<Array<Maybe<ProductModule>>>;
  storeGroups?: Maybe<Array<Maybe<StoreGroup>>>;
  universeId: Scalars['Int'];
};

export type CollectionCreateInput = {
  code: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  universeId: Scalars['Int'];
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
};

export type CollectionUpdateInput = {
  code?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export enum CombinationType {
  Exclusive = 'EXCLUSIVE',
  Multiple = 'MULTIPLE'
}

export type DashboardDto = {
  __typename?: 'DashboardDto';
  exportDate: Scalars['DateTime'];
  exportId: Scalars['Int'];
  importLogs?: Maybe<Array<Maybe<FileImportLog>>>;
  newsletterBody?: Maybe<Scalars['String']>;
  newsletterImgUrl?: Maybe<Scalars['String']>;
  newsletterSubtitle?: Maybe<Scalars['String']>;
  newsletterTitle?: Maybe<Scalars['String']>;
};

export type ExceptionOptimalStockDto = {
  __typename?: 'ExceptionOptimalStockDto';
  hasExceptions: Scalars['Boolean'];
  isIncluded: Scalars['Boolean'];
  leftTable?: Maybe<Array<Maybe<HeaderValuePair>>>;
  rightTable?: Maybe<Array<Maybe<HeaderValuePair>>>;
  storeCode?: Maybe<Scalars['String']>;
  storeCustomField1?: Maybe<Scalars['String']>;
  storeCustomField2?: Maybe<Scalars['String']>;
  storeCustomField3?: Maybe<Scalars['String']>;
  storeCustomField4?: Maybe<Scalars['String']>;
  storeCustomField5?: Maybe<Scalars['String']>;
  storeGroupName?: Maybe<Scalars['String']>;
  storeId: Scalars['Int'];
  storeName?: Maybe<Scalars['String']>;
  storeStrength: Scalars['Int'];
};

export type ExceptionOptimalStockHeaderDto = {
  __typename?: 'ExceptionOptimalStockHeaderDto';
  articleId?: Maybe<Scalars['Int']>;
  headerId: Scalars['Int'];
  headerName?: Maybe<Scalars['String']>;
};

export type ExceptionOptimalStockRetailStockDto = {
  __typename?: 'ExceptionOptimalStockRetailStockDto';
  articleId?: Maybe<Scalars['Int']>;
  headerId: Scalars['Int'];
  headerName?: Maybe<Scalars['String']>;
  warehouseStockQuantity?: Maybe<Scalars['Int']>;
};

export enum ExceptionOptimalStockSalesEnum {
  EstimationPicking = 'ESTIMATION_PICKING',
  SeasonTotal = 'SEASON_TOTAL',
  StockStore = 'STOCK_STORE',
  StockStoreAndTransit = 'STOCK_STORE_AND_TRANSIT',
  ThreeWeekAverage = 'THREE_WEEK_AVERAGE'
}

export enum ExceptionOptimalStockStockEnum {
  OptimalStock = 'OPTIMAL_STOCK',
  StockStoreAndTransit = 'STOCK_STORE_AND_TRANSIT'
}

export type ExceptionOptimalStockTableDto = {
  __typename?: 'ExceptionOptimalStockTableDto';
  exceptionOptimalStock?: Maybe<Array<Maybe<ExceptionOptimalStockDto>>>;
  numberOfWeeksInSale: Scalars['Int'];
  sizeSystemHeaders?: Maybe<Array<Maybe<ExceptionOptimalStockHeaderDto>>>;
};

export type ExceptionOptimalStockUpdateInput = {
  articleId: Scalars['Int'];
  optimalStock: Scalars['Int'];
  storeId: Scalars['Int'];
};

export type Family = {
  __typename?: 'Family';
  code?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type FileImportLog = {
  __typename?: 'FileImportLog';
  errors?: Maybe<Scalars['String']>;
  errorsCount: Scalars['Int'];
  id: Scalars['Int'];
  importDate: Scalars['DateTime'];
  isSuccessful: Scalars['Boolean'];
  object?: Maybe<Scalars['String']>;
};

export enum FileImportType {
  IdealStock = 'IDEAL_STOCK',
  ProductColor = 'PRODUCT_COLOR',
  ProductColorSize = 'PRODUCT_COLOR_SIZE',
  ProductColorSizeStore = 'PRODUCT_COLOR_SIZE_STORE',
  ProductColorStore = 'PRODUCT_COLOR_STORE',
  Store = 'STORE',
  StoreSeason = 'STORE_SEASON',
  StoreSeasonPermutationFields = 'STORE_SEASON_PERMUTATION_FIELDS'
}

export enum FileType {
  Csv = 'CSV',
  Excel = 'EXCEL'
}

export type HeaderValuePair = {
  __typename?: 'HeaderValuePair';
  headerId: Scalars['Int'];
  isOverriden: Scalars['Boolean'];
  value: Scalars['Int'];
};

export type OptimalStockDataDto = {
  __typename?: 'OptimalStockDataDto';
  actualStockDemand: Scalars['Int'];
  estimatedPicking: Scalars['Int'];
  optimalStock: Scalars['Int'];
};

export type OptimalStockOverrideUpdateBulkInput = {
  hasCondition?: InputMaybe<Scalars['Boolean']>;
  hasStockMax?: InputMaybe<Scalars['Boolean']>;
  hasStockMin?: InputMaybe<Scalars['Boolean']>;
  productId: Scalars['Int'];
  stockMaxStoreStrengthCondition?: InputMaybe<Scalars['Int']>;
  stockMinStoreStrengthCondition?: InputMaybe<Scalars['Int']>;
};

export type OptimalStockOverrideUpdateInput = {
  id: Scalars['Int'];
  stockMax?: InputMaybe<Scalars['Int']>;
  stockMaxStoreStrengthConditionValue?: InputMaybe<Scalars['Int']>;
  stockMin?: InputMaybe<Scalars['Int']>;
  stockMinStoreStrengthConditionValue?: InputMaybe<Scalars['Int']>;
};

export type OptimalStockSimulationDto = {
  __typename?: 'OptimalStockSimulationDto';
  barcode?: Maybe<Scalars['String']>;
  colorCode?: Maybe<Scalars['String']>;
  colorName?: Maybe<Scalars['String']>;
  estimationPicking: Scalars['Int'];
  familyName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  optimalStock: Scalars['Int'];
  permutationField1?: Maybe<Scalars['String']>;
  permutationField2?: Maybe<Scalars['String']>;
  productCode?: Maybe<Scalars['String']>;
  productCustomField1?: Maybe<Scalars['String']>;
  productCustomField2?: Maybe<Scalars['String']>;
  productCustomField3?: Maybe<Scalars['String']>;
  productCustomField4?: Maybe<Scalars['String']>;
  productCustomField5?: Maybe<Scalars['String']>;
  productIsSynced: Scalars['Boolean'];
  productName?: Maybe<Scalars['String']>;
  sizeHeader?: Maybe<Scalars['String']>;
  storeCode?: Maybe<Scalars['String']>;
  storeCustomField1?: Maybe<Scalars['String']>;
  storeCustomField2?: Maybe<Scalars['String']>;
  storeCustomField3?: Maybe<Scalars['String']>;
  storeCustomField4?: Maybe<Scalars['String']>;
  storeCustomField5?: Maybe<Scalars['String']>;
  storeIsSynced: Scalars['Boolean'];
  storeName?: Maybe<Scalars['String']>;
};

export enum OptimalStockSimulationField {
  Barcode = 'BARCODE',
  ColorCode = 'COLOR_CODE',
  ColorName = 'COLOR_NAME',
  EstimationPicking = 'ESTIMATION_PICKING',
  FamilyName = 'FAMILY_NAME',
  OptimalStock = 'OPTIMAL_STOCK',
  PermutationField1 = 'PERMUTATION_FIELD1',
  PermutationField2 = 'PERMUTATION_FIELD2',
  ProductCode = 'PRODUCT_CODE',
  ProductCustomField1 = 'PRODUCT_CUSTOM_FIELD1',
  ProductCustomField2 = 'PRODUCT_CUSTOM_FIELD2',
  ProductCustomField3 = 'PRODUCT_CUSTOM_FIELD3',
  ProductCustomField4 = 'PRODUCT_CUSTOM_FIELD4',
  ProductCustomField5 = 'PRODUCT_CUSTOM_FIELD5',
  ProductIsSynced = 'PRODUCT_IS_SYNCED',
  ProductName = 'PRODUCT_NAME',
  SizeHeader = 'SIZE_HEADER',
  StoreCode = 'STORE_CODE',
  StoreCustomField1 = 'STORE_CUSTOM_FIELD1',
  StoreCustomField2 = 'STORE_CUSTOM_FIELD2',
  StoreCustomField3 = 'STORE_CUSTOM_FIELD3',
  StoreCustomField4 = 'STORE_CUSTOM_FIELD4',
  StoreCustomField5 = 'STORE_CUSTOM_FIELD5',
  StoreIsSynced = 'STORE_IS_SYNCED',
  StoreName = 'STORE_NAME'
}

export type OptimalStockSimulationFilterDtoInput = {
  type?: InputMaybe<OptimalStockSimulationField>;
  value?: InputMaybe<Scalars['String']>;
};

export enum OriginType {
  Imported = 'IMPORTED',
  Web = 'WEB'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
};

export type PaginationInfoOfOptimalStockSimulationDto = {
  __typename?: 'PaginationInfoOfOptimalStockSimulationDto';
  items?: Maybe<Array<Maybe<OptimalStockSimulationDto>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars['Int'];
};

export enum PermissionsEnum {
  ProductPerformanceDetailsEdit = 'PRODUCT_PERFORMANCE_DETAILS_EDIT',
  ProductPerformanceMainTableEdit = 'PRODUCT_PERFORMANCE_MAIN_TABLE_EDIT',
  SettingsEdit = 'SETTINGS_EDIT',
  SettingsGeneralSettingsEdit = 'SETTINGS_GENERAL_SETTINGS_EDIT',
  SettingsGeneralSettingsView = 'SETTINGS_GENERAL_SETTINGS_VIEW',
  SettingsUserManagementEdit = 'SETTINGS_USER_MANAGEMENT_EDIT',
  SettingsUserManagementView = 'SETTINGS_USER_MANAGEMENT_VIEW',
  SettingsView = 'SETTINGS_VIEW',
  StoreFleetMainTableDelete = 'STORE_FLEET_MAIN_TABLE_DELETE',
  StoreFleetMainTableEdit = 'STORE_FLEET_MAIN_TABLE_EDIT',
  StorePerformanceEdit = 'STORE_PERFORMANCE_EDIT'
}

export enum PermutationFieldType {
  CustomField1 = 'CUSTOM_FIELD1',
  CustomField2 = 'CUSTOM_FIELD2',
  CustomField3 = 'CUSTOM_FIELD3',
  CustomField4 = 'CUSTOM_FIELD4',
  CustomField5 = 'CUSTOM_FIELD5',
  Family = 'FAMILY'
}

export type Product = {
  __typename?: 'Product';
  code?: Maybe<Scalars['String']>;
  colorCode?: Maybe<Scalars['String']>;
  customFieldValue1?: Maybe<Scalars['String']>;
  customFieldValue2?: Maybe<Scalars['String']>;
  customFieldValue3?: Maybe<Scalars['String']>;
  customFieldValue4?: Maybe<Scalars['String']>;
  customFieldValue5?: Maybe<Scalars['String']>;
  familyId: Scalars['Int'];
  id: Scalars['Int'];
  isOnShowcase: Scalars['Boolean'];
  isSynced: Scalars['Boolean'];
  photoUrls?: Maybe<Scalars['String']>;
  price: Scalars['Decimal'];
  productModuleId?: Maybe<Scalars['Int']>;
  seasonId: Scalars['Int'];
  sizeSystemId: Scalars['Int'];
  sizeSystemRowId?: Maybe<Scalars['Int']>;
};

export enum ProductColorImportType {
  ColorCode = 'COLOR_CODE',
  CustomFieldValue_1 = 'CUSTOM_FIELD_VALUE_1',
  CustomFieldValue_2 = 'CUSTOM_FIELD_VALUE_2',
  CustomFieldValue_3 = 'CUSTOM_FIELD_VALUE_3',
  CustomFieldValue_4 = 'CUSTOM_FIELD_VALUE_4',
  CustomFieldValue_5 = 'CUSTOM_FIELD_VALUE_5',
  GenericReference = 'GENERIC_REFERENCE',
  HasCondition = 'HAS_CONDITION',
  HasStockMax = 'HAS_STOCK_MAX',
  HasStockMin = 'HAS_STOCK_MIN',
  IsOnShowcase = 'IS_ON_SHOWCASE',
  IsSync = 'IS_SYNC',
  NewSeasonCode = 'NEW_SEASON_CODE',
  ProductCode = 'PRODUCT_CODE',
  ProductModuleName = 'PRODUCT_MODULE_NAME',
  ProductStrength = 'PRODUCT_STRENGTH',
  SeasonCode = 'SEASON_CODE',
  SizeSystemRowName = 'SIZE_SYSTEM_ROW_NAME',
  StockMaxStoreStrengthCondition = 'STOCK_MAX_STORE_STRENGTH_CONDITION',
  StockMinStoreStrengthCondition = 'STOCK_MIN_STORE_STRENGTH_CONDITION'
}

export enum ProductColorSizeImportType {
  ColorCode = 'COLOR_CODE',
  ProductCode = 'PRODUCT_CODE',
  SeasonCode = 'SEASON_CODE',
  SizeSystemHeaderName = 'SIZE_SYSTEM_HEADER_NAME',
  StockMaxStoreStrengthConditionValue = 'STOCK_MAX_STORE_STRENGTH_CONDITION_VALUE',
  StockMaxValue = 'STOCK_MAX_VALUE',
  StockMinStoreStrengthConditionValue = 'STOCK_MIN_STORE_STRENGTH_CONDITION_VALUE',
  StockMinValue = 'STOCK_MIN_VALUE'
}

export enum ProductColorSizeStoreImportType {
  ArticleOptimalStockException = 'ARTICLE_OPTIMAL_STOCK_EXCEPTION',
  ColorCode = 'COLOR_CODE',
  ProductCode = 'PRODUCT_CODE',
  SeasonCode = 'SEASON_CODE',
  SizeSystemHeaderName = 'SIZE_SYSTEM_HEADER_NAME',
  StoreCode = 'STORE_CODE',
  UniverseCode = 'UNIVERSE_CODE'
}

export enum ProductColorStoreImportType {
  ColorCode = 'COLOR_CODE',
  ProductCode = 'PRODUCT_CODE',
  SeasonCode = 'SEASON_CODE',
  StoreAction = 'STORE_ACTION',
  StoreCode = 'STORE_CODE',
  UniverseCode = 'UNIVERSE_CODE'
}

export type ProductCustomFieldUpdateInput = {
  customFieldName1?: InputMaybe<Scalars['String']>;
  customFieldName2?: InputMaybe<Scalars['String']>;
  customFieldName3?: InputMaybe<Scalars['String']>;
  customFieldName4?: InputMaybe<Scalars['String']>;
  customFieldName5?: InputMaybe<Scalars['String']>;
  customFieldValues1?: InputMaybe<Scalars['String']>;
  customFieldValues2?: InputMaybe<Scalars['String']>;
  customFieldValues3?: InputMaybe<Scalars['String']>;
  customFieldValues4?: InputMaybe<Scalars['String']>;
  customFieldValues5?: InputMaybe<Scalars['String']>;
};

export type ProductCustomFieldsDto = {
  __typename?: 'ProductCustomFieldsDto';
  customFieldName1?: Maybe<Scalars['String']>;
  customFieldName2?: Maybe<Scalars['String']>;
  customFieldName3?: Maybe<Scalars['String']>;
  customFieldName4?: Maybe<Scalars['String']>;
  customFieldName5?: Maybe<Scalars['String']>;
  customFieldValues1?: Maybe<Scalars['String']>;
  customFieldValues2?: Maybe<Scalars['String']>;
  customFieldValues3?: Maybe<Scalars['String']>;
  customFieldValues4?: Maybe<Scalars['String']>;
  customFieldValues5?: Maybe<Scalars['String']>;
};

export type ProductDetailsDto = {
  __typename?: 'ProductDetailsDto';
  collectionId: Scalars['Int'];
  customFieldValue1?: Maybe<Scalars['String']>;
  customFieldValue2?: Maybe<Scalars['String']>;
  customFieldValue3?: Maybe<Scalars['String']>;
  customFieldValue4?: Maybe<Scalars['String']>;
  customFieldValue5?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  isOnShowcase: Scalars['Boolean'];
  isSynced: Scalars['Boolean'];
  photoUrls?: Maybe<Scalars['String']>;
  price: Scalars['Decimal'];
  productModuleId?: Maybe<Scalars['Int']>;
  seasonId: Scalars['Int'];
  sizeSystemId: Scalars['Int'];
  sizeSystemRowId?: Maybe<Scalars['Int']>;
  universeId?: Maybe<Scalars['Int']>;
  validatedStrength: Scalars['Decimal'];
};

export type ProductDetailsUpdateInput = {
  customFieldValue1?: InputMaybe<Scalars['String']>;
  customFieldValue2?: InputMaybe<Scalars['String']>;
  customFieldValue3?: InputMaybe<Scalars['String']>;
  customFieldValue4?: InputMaybe<Scalars['String']>;
  customFieldValue5?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  isOnShowcase?: InputMaybe<Scalars['Boolean']>;
  isSynced?: InputMaybe<Scalars['Boolean']>;
  photoUrls?: InputMaybe<Scalars['String']>;
  productModuleId?: InputMaybe<Scalars['Int']>;
  seasonId?: InputMaybe<Scalars['Int']>;
  sizeSystemId?: InputMaybe<Scalars['Int']>;
  sizeSystemRowId?: InputMaybe<Scalars['Int']>;
  validatedStrength?: InputMaybe<Scalars['Decimal']>;
};

export type ProductFleetPerformanceDto = {
  __typename?: 'ProductFleetPerformanceDto';
  averagePastThreeWeeksSalesAllStores: Scalars['Int'];
  collectionName?: Maybe<Scalars['String']>;
  colorCode?: Maybe<Scalars['String']>;
  colorName?: Maybe<Scalars['String']>;
  customFieldValue1?: Maybe<Scalars['String']>;
  customFieldValue2?: Maybe<Scalars['String']>;
  customFieldValue3?: Maybe<Scalars['String']>;
  customFieldValue4?: Maybe<Scalars['String']>;
  customFieldValue5?: Maybe<Scalars['String']>;
  estimationPicking: Scalars['Int'];
  excludedStoreExceptionsCount: Scalars['Int'];
  familyName?: Maybe<Scalars['String']>;
  hasCondition: Scalars['Boolean'];
  hasException: Scalars['Boolean'];
  hasStockMaxOverride: Scalars['Boolean'];
  hasStockMinOverride: Scalars['Boolean'];
  id: Scalars['Int'];
  implantedStrength: Scalars['Int'];
  includedStoreExceptionsCount: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  isOnShowcase: Scalars['Boolean'];
  isSynced: Scalars['Boolean'];
  numberOfStoresInWhichProductIsPresent: Scalars['Int'];
  numberOfWeeksTheProductIsSold: Scalars['Int'];
  optimalStock: Scalars['Int'];
  optimalStockPastWeek: Scalars['Int'];
  pastThreeWeekSales: Scalars['Int'];
  pastTwoWeekSales: Scalars['Int'];
  pastWeekSales: Scalars['Int'];
  permutationField1?: Maybe<Scalars['String']>;
  permutationField2?: Maybe<Scalars['String']>;
  photoUrls?: Maybe<Scalars['String']>;
  physicalStoreSales: Scalars['Int'];
  potentialSalesPastWeekAllStores: Scalars['Decimal'];
  potentialWeeklySalesFlow: Scalars['Decimal'];
  productCode?: Maybe<Scalars['String']>;
  productModuleName?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  proposedStrength: Scalars['Decimal'];
  quantityForDelivery: Scalars['Int'];
  quantityInTransit: Scalars['Int'];
  quantityPicking: Scalars['Int'];
  salesPrice: Scalars['Decimal'];
  sizeSystemName?: Maybe<Scalars['String']>;
  sizeSystemRowName?: Maybe<Scalars['String']>;
  stockFlowRate: Scalars['Decimal'];
  stockQuantityInPhysicalStore: Scalars['Int'];
  stockQuantityInWebStore: Scalars['Int'];
  stockQuantityTotal: Scalars['Int'];
  stockTotal: Scalars['Int'];
  stockWarehouse: Scalars['Int'];
  totalQuantitySold: Scalars['Int'];
  validatedStrength: Scalars['Decimal'];
  warehouseCoverage: Scalars['Int'];
  warehouseStockRetails: Scalars['Int'];
  warehouseStockWholesale: Scalars['Int'];
  webPercent: Scalars['Decimal'];
  webStoreSales: Scalars['Int'];
  weekDiscountStockRate: Scalars['Int'];
  weekSales: Scalars['Int'];
};

export type ProductModule = {
  __typename?: 'ProductModule';
  collectionId: Scalars['Int'];
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  storeGroupToProductModules?: Maybe<Array<Maybe<StoreGroupToProductModule>>>;
  universeId: Scalars['Int'];
};

export type ProductModuleCreateInput = {
  collectionId: Scalars['Int'];
  name: Scalars['String'];
  universeId: Scalars['Int'];
};

export type ProductOptimalSalesTableColumnDto = {
  __typename?: 'ProductOptimalSalesTableColumnDto';
  allWeeksTotal: Scalars['Int'];
  distribution: Scalars['Int'];
  physicalSales: Scalars['Int'];
  remainingQuantitiesByDateAndSupplier?: Maybe<Array<Maybe<StatisticsSupplierOptimalStockDto>>>;
  remainingQuantity: Scalars['Int'];
  sKU?: Maybe<Scalars['String']>;
  salesFifthWeek: Scalars['Int'];
  salesFirstWeek: Scalars['Int'];
  salesFourthWeek: Scalars['Int'];
  salesSecondWeek: Scalars['Int'];
  salesThirdWeek: Scalars['Int'];
  sizeSystemRowHeaderId: Scalars['Int'];
  sizeSystemRowHeaderName?: Maybe<Scalars['String']>;
  stockAvailableAndInTransit: Scalars['Int'];
  stockQuantityPicking: Scalars['Int'];
  totalSales: Scalars['Int'];
  warehouseQuantity: Scalars['Int'];
  webSales: Scalars['Int'];
};

export type ProductOptimalSalesTableDto = {
  __typename?: 'ProductOptimalSalesTableDto';
  columns?: Maybe<Array<Maybe<ProductOptimalSalesTableColumnDto>>>;
  discountColumn?: Maybe<ProductOptimalStockSalesTableDiscountColumnDto>;
  headers?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ProductOptimalStockHeaderDto = {
  __typename?: 'ProductOptimalStockHeaderDto';
  id: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  optimalStockOverrideId?: Maybe<Scalars['Int']>;
};

export type ProductOptimalStockMetadata = {
  __typename?: 'ProductOptimalStockMetadata';
  isOnShowcase: Scalars['Boolean'];
  numberOfStores: Scalars['Int'];
  numberOfStoresWithShowcase: Scalars['Int'];
  proposedStrength: Scalars['Decimal'];
  theoreticalSalesFlow: Scalars['Decimal'];
  validatedProductStrength: Scalars['Decimal'];
  weeklySalesFlow: Scalars['Decimal'];
};

export type ProductOptimalStockSalesTableDiscountColumnDto = {
  __typename?: 'ProductOptimalStockSalesTableDiscountColumnDto';
  discountStockRateFifthWeek: Scalars['Int'];
  discountStockRateFirstWeek: Scalars['Int'];
  discountStockRateFourthWeek: Scalars['Int'];
  discountStockRatePerFiveWeeks: Scalars['Int'];
  discountStockRatePerPhysical: Scalars['Int'];
  discountStockRatePerSeason: Scalars['Int'];
  discountStockRatePerWeb: Scalars['Int'];
  discountStockRateSecondWeek: Scalars['Int'];
  discountStockRateThirdWeek: Scalars['Int'];
};

export type ProductOptimalStockSummaryDto = {
  __typename?: 'ProductOptimalStockSummaryDto';
  collectionId: Scalars['Int'];
  colorCode?: Maybe<Scalars['String']>;
  colorName?: Maybe<Scalars['String']>;
  customFieldValue1?: Maybe<Scalars['String']>;
  customFieldValue2?: Maybe<Scalars['String']>;
  customFieldValue3?: Maybe<Scalars['String']>;
  customFieldValue4?: Maybe<Scalars['String']>;
  customFieldValue5?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  permutationField1?: Maybe<Scalars['String']>;
  permutationField2?: Maybe<Scalars['String']>;
  photoUrls?: Maybe<Scalars['String']>;
  price: Scalars['Decimal'];
  productCode?: Maybe<Scalars['String']>;
  productModuleId?: Maybe<Scalars['Int']>;
  productName?: Maybe<Scalars['String']>;
  sizeSystemId: Scalars['Int'];
  sizeSystemName?: Maybe<Scalars['String']>;
  sizeSystemRowId?: Maybe<Scalars['Int']>;
  sizeSystemRowName?: Maybe<Scalars['String']>;
};

export type ProductOptimalStockTableRowDto = {
  __typename?: 'ProductOptimalStockTableRowDto';
  numberOfStores: Scalars['Int'];
  optimalStock?: Maybe<Array<Maybe<Scalars['Int']>>>;
  storeStrength: Scalars['Int'];
  total: Scalars['Int'];
};

export type ProductOptimalStockTableWrapperDto = {
  __typename?: 'ProductOptimalStockTableWrapperDto';
  headers?: Maybe<Array<Maybe<ProductOptimalStockHeaderDto>>>;
  metadata?: Maybe<ProductOptimalStockMetadata>;
  overrides?: Maybe<Array<Maybe<ArticleOptimalStockOverride>>>;
  rows?: Maybe<Array<Maybe<ProductOptimalStockTableRowDto>>>;
  summary?: Maybe<Array<Maybe<OptimalStockDataDto>>>;
};

export type ProductOptimalStockUpdateInput = {
  isOnShowcase?: InputMaybe<Scalars['Boolean']>;
  productId: Scalars['Int'];
  productModuleId?: InputMaybe<Scalars['Int']>;
  sizeSystemRowId?: InputMaybe<Scalars['Int']>;
  validatedProductStrength?: InputMaybe<Scalars['Decimal']>;
};

export type Season = {
  __typename?: 'Season';
  code?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
};

export type SeasonCreateInput = {
  code: Scalars['String'];
  name: Scalars['String'];
};

export type SeasonUpdateInput = {
  code?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  universeId?: InputMaybe<Scalars['Int']>;
};

export type SizeSystem = {
  __typename?: 'SizeSystem';
  code?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  origin: OriginType;
  sizeSystemRowHeaders?: Maybe<Array<Maybe<SizeSystemRowHeader>>>;
  sizeSystemRows?: Maybe<Array<Maybe<SizeSystemRow>>>;
};

export type SizeSystemCreateInput = {
  code: Scalars['String'];
  name: Scalars['String'];
};

export type SizeSystemRow = {
  __typename?: 'SizeSystemRow';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  sizeSystemRowValues?: Maybe<Array<Maybe<SizeSystemRowValue>>>;
  sortNumber: Scalars['Int'];
};

export type SizeSystemRowCreateInput = {
  name: Scalars['String'];
  sizeSystemId: Scalars['Int'];
  sizeSystemRowValues?: InputMaybe<Array<SizeSystemRowValueCreateInput>>;
  sortNumber: Scalars['Int'];
};

export type SizeSystemRowHeader = {
  __typename?: 'SizeSystemRowHeader';
  id: Scalars['Int'];
  isOnShowcase: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  sortNumber: Scalars['Int'];
};

export type SizeSystemRowHeaderCreateInput = {
  isOnShowcase: Scalars['Boolean'];
  name: Scalars['String'];
  sizeSystemId: Scalars['Int'];
  sortNumber: Scalars['Int'];
};

export type SizeSystemRowHeaderUpdateInput = {
  id: Scalars['Int'];
  isOnShowcase: Scalars['Boolean'];
};

export type SizeSystemRowUpdateInput = {
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  sizeSystemRowValues?: InputMaybe<Array<InputMaybe<SizeSystemRowValueUpdateInput>>>;
};

export type SizeSystemRowValue = {
  __typename?: 'SizeSystemRowValue';
  distribution: Scalars['Int'];
  id: Scalars['Int'];
  sizeSystemRowHeaderId: Scalars['Int'];
  sizeSystemRowId: Scalars['Int'];
};

export type SizeSystemRowValueCreateInput = {
  distribution: Scalars['Int'];
  sizeSystemRowHeaderId: Scalars['Int'];
};

export type SizeSystemRowValueUpdateInput = {
  distribution: Scalars['Int'];
  id: Scalars['Int'];
};

export type SizeSystemUpdateInput = {
  code?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
};

export type StatisticsSupplierOptimalStockDto = {
  __typename?: 'StatisticsSupplierOptimalStockDto';
  quantityForDelivery: Scalars['Int'];
  supplierCode?: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
};

export type StoreCustomFieldUpdateInput = {
  customFieldName1?: InputMaybe<Scalars['String']>;
  customFieldName2?: InputMaybe<Scalars['String']>;
  customFieldName3?: InputMaybe<Scalars['String']>;
  customFieldName4?: InputMaybe<Scalars['String']>;
  customFieldName5?: InputMaybe<Scalars['String']>;
  customFieldValues1?: InputMaybe<Scalars['String']>;
  customFieldValues2?: InputMaybe<Scalars['String']>;
  customFieldValues3?: InputMaybe<Scalars['String']>;
  customFieldValues4?: InputMaybe<Scalars['String']>;
  customFieldValues5?: InputMaybe<Scalars['String']>;
};

export type StoreCustomFieldsDto = {
  __typename?: 'StoreCustomFieldsDto';
  customFieldName1?: Maybe<Scalars['String']>;
  customFieldName2?: Maybe<Scalars['String']>;
  customFieldName3?: Maybe<Scalars['String']>;
  customFieldName4?: Maybe<Scalars['String']>;
  customFieldName5?: Maybe<Scalars['String']>;
  customFieldValues1?: Maybe<Scalars['String']>;
  customFieldValues2?: Maybe<Scalars['String']>;
  customFieldValues3?: Maybe<Scalars['String']>;
  customFieldValues4?: Maybe<Scalars['String']>;
  customFieldValues5?: Maybe<Scalars['String']>;
};

export type StoreDetailsDto = {
  __typename?: 'StoreDetailsDto';
  capacity: Scalars['Int'];
  capacityType?: Maybe<CapacityType>;
  code?: Maybe<Scalars['String']>;
  customFieldValue1?: Maybe<Scalars['String']>;
  customFieldValue2?: Maybe<Scalars['String']>;
  customFieldValue3?: Maybe<Scalars['String']>;
  customFieldValue4?: Maybe<Scalars['String']>;
  customFieldValue5?: Maybe<Scalars['String']>;
  handlesShowcase: Scalars['Boolean'];
  id: Scalars['Int'];
  ignoreMinMax: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  isSynced: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  originType?: Maybe<OriginType>;
  photoUrls?: Maybe<Scalars['String']>;
  pickingPriority: Scalars['Int'];
  regionId?: Maybe<Scalars['Int']>;
  regionName?: Maybe<Scalars['String']>;
  storeTypeId?: Maybe<Scalars['Int']>;
};

export type StoreDetailsUpdateInput = {
  capacity?: InputMaybe<Scalars['Int']>;
  capacityType?: InputMaybe<CapacityType>;
  code?: InputMaybe<Scalars['String']>;
  customFieldValue1?: InputMaybe<Scalars['String']>;
  customFieldValue2?: InputMaybe<Scalars['String']>;
  customFieldValue3?: InputMaybe<Scalars['String']>;
  customFieldValue4?: InputMaybe<Scalars['String']>;
  customFieldValue5?: InputMaybe<Scalars['String']>;
  handlesShowcase?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  ignoreMinMax?: InputMaybe<Scalars['Boolean']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isSynced?: InputMaybe<Scalars['Boolean']>;
  photoUrls?: InputMaybe<Scalars['String']>;
  pickingPriority?: InputMaybe<Scalars['Int']>;
  regionId?: InputMaybe<Scalars['Int']>;
  seasonId: Scalars['Int'];
  storeTypeId?: InputMaybe<Scalars['Int']>;
  targetTurnover?: InputMaybe<Scalars['Decimal']>;
  universeId: Scalars['Int'];
};

export type StoreFleetCollectionDto = {
  __typename?: 'StoreFleetCollectionDto';
  collectionId: Scalars['Int'];
  storeGroupId: Scalars['Int'];
  storeGroupName?: Maybe<Scalars['String']>;
};

export type StoreFleetDto = {
  __typename?: 'StoreFleetDto';
  capacity: Scalars['Int'];
  capacityRatio: Scalars['Decimal'];
  handlesShowcase: Scalars['Boolean'];
  id: Scalars['Int'];
  ignoreMinMax: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  isSynced: Scalars['Boolean'];
  pickingPriority: Scalars['Int'];
  storeCode?: Maybe<Scalars['String']>;
  storeCustomField1?: Maybe<Scalars['String']>;
  storeCustomField2?: Maybe<Scalars['String']>;
  storeCustomField3?: Maybe<Scalars['String']>;
  storeCustomField4?: Maybe<Scalars['String']>;
  storeCustomField5?: Maybe<Scalars['String']>;
  storeName?: Maybe<Scalars['String']>;
  storeRegion?: Maybe<Scalars['String']>;
  storeStrength: Scalars['Decimal'];
  storeStrengthImplant: Scalars['Decimal'];
  storeToStoreGroups?: Maybe<Array<Maybe<StoreFleetCollectionDto>>>;
  storeType?: Maybe<Scalars['String']>;
  targetTurnover: Scalars['Decimal'];
};

export type StoreFleetInput = {
  capacity?: InputMaybe<Scalars['Int']>;
  handlesShowcase?: InputMaybe<Scalars['Boolean']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  pickingPriority?: InputMaybe<Scalars['Int']>;
  seasonId: Scalars['Int'];
  storeGroupId?: InputMaybe<Scalars['Int']>;
  storeId: Scalars['Int'];
  targetTurnover?: InputMaybe<Scalars['Decimal']>;
  universeId: Scalars['Int'];
};

export type StoreFleetSummaryStoreGroupsDto = {
  __typename?: 'StoreFleetSummaryStoreGroupsDto';
  groupName?: Maybe<Scalars['String']>;
  numberOfStores: Scalars['Int'];
  storesTargetTurnover: Scalars['Decimal'];
};

export type StoreFleetSummaryStorePerformanceDto = {
  __typename?: 'StoreFleetSummaryStorePerformanceDto';
  percentageOfTotal: Scalars['Decimal'];
  storePerformance: Scalars['Decimal'];
  storePerformanceStoreCount: Scalars['Int'];
};

export type StoreFleetSummaryTableDto = {
  __typename?: 'StoreFleetSummaryTableDto';
  storePerformanceTable?: Maybe<Array<Maybe<StoreFleetSummaryStorePerformanceDto>>>;
  targetTurnoverTable?: Maybe<Array<Maybe<StoreFleetSummaryTargetTurnoverDto>>>;
};

export type StoreFleetSummaryTargetTurnoverDto = {
  __typename?: 'StoreFleetSummaryTargetTurnoverDto';
  collectionName?: Maybe<Scalars['String']>;
  storeGroups?: Maybe<Array<Maybe<StoreFleetSummaryStoreGroupsDto>>>;
};

export type StoreGroup = {
  __typename?: 'StoreGroup';
  collectionId: Scalars['Int'];
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  storeGroupToProductModules?: Maybe<Array<Maybe<StoreGroupToProductModule>>>;
  universeId: Scalars['Int'];
};

export type StoreGroupCreateInput = {
  collectionId: Scalars['Int'];
  name: Scalars['String'];
  universeId: Scalars['Int'];
};

export type StoreGroupToProductModule = {
  __typename?: 'StoreGroupToProductModule';
  id: Scalars['Int'];
  isSelected: Scalars['Boolean'];
  productModuleId: Scalars['Int'];
  storeGroupId: Scalars['Int'];
};

export type StoreGroupToProductModuleUpdateInput = {
  collectionId: Scalars['Int'];
  combinationType: CombinationType;
  values?: InputMaybe<Array<StoreGroupToProductModuleValueUpdateInput>>;
};

export type StoreGroupToProductModuleValueUpdateInput = {
  isSelected: Scalars['Boolean'];
  productModuleId: Scalars['Int'];
  storeGroupId: Scalars['Int'];
  storeGroupToProductModuleId: Scalars['Int'];
};

export enum StoreImportType {
  CustomFieldValue_1 = 'CUSTOM_FIELD_VALUE_1',
  CustomFieldValue_2 = 'CUSTOM_FIELD_VALUE_2',
  CustomFieldValue_3 = 'CUSTOM_FIELD_VALUE_3',
  CustomFieldValue_4 = 'CUSTOM_FIELD_VALUE_4',
  CustomFieldValue_5 = 'CUSTOM_FIELD_VALUE_5',
  HandleShowcase = 'HANDLE_SHOWCASE',
  IsActive = 'IS_ACTIVE',
  PickingPriority = 'PICKING_PRIORITY',
  StoreCode = 'STORE_CODE',
  StoreType = 'STORE_TYPE',
  UniverseCode = 'UNIVERSE_CODE'
}

export type StoreOptimalStockExceptionDto = {
  __typename?: 'StoreOptimalStockExceptionDto';
  storeCode?: Maybe<Scalars['String']>;
  storeGroupId: Scalars['Int'];
  storeGroupName?: Maybe<Scalars['String']>;
  storeId: Scalars['Int'];
  storeName?: Maybe<Scalars['String']>;
  storeStrength: Scalars['Decimal'];
};

export type StoreOptimalStockExceptionInput = {
  isIncluded: Scalars['Boolean'];
  productId: Scalars['Int'];
  storeId: Scalars['Int'];
};

export type StorePerformanceDto = {
  __typename?: 'StorePerformanceDto';
  averageCoverage: Scalars['Int'];
  capacity: Scalars['Int'];
  capacityRatio: Scalars['Decimal'];
  code?: Maybe<Scalars['String']>;
  coverageOneWeekBefore: Scalars['Int'];
  coverageThreeWeeksBefore: Scalars['Int'];
  coverageTwoWeeksBefore: Scalars['Int'];
  customField1?: Maybe<Scalars['String']>;
  customField2?: Maybe<Scalars['String']>;
  customField3?: Maybe<Scalars['String']>;
  customField4?: Maybe<Scalars['String']>;
  customField5?: Maybe<Scalars['String']>;
  estimatedPicking: Scalars['Int'];
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  numberOfProductsWithOptimalStock: Scalars['Int'];
  numberOfProductsWithSalesLastWeek: Scalars['Int'];
  numberOfProductsWithStock: Scalars['Int'];
  optimalStock: Scalars['Int'];
  optimalStockOneWeekBefore: Scalars['Int'];
  permutationFieldId1?: Maybe<Scalars['String']>;
  permutationFieldId2?: Maybe<Scalars['String']>;
  permutationLabel1?: Maybe<Scalars['String']>;
  permutationLabel2?: Maybe<Scalars['String']>;
  salesQuantityLastWeek: Scalars['Int'];
  stockQuantityLastWeek: Scalars['Int'];
  storeStrengthPerPermutationHistory: Scalars['Int'];
  storeStrengthPerPermutationImplemented: Scalars['Decimal'];
  storeStrengthPerPermutationProposed: Scalars['Int'];
  storeStrengthPerPermutationValidated: Scalars['Int'];
  storeToStoreGroups?: Maybe<Array<Maybe<StoreFleetCollectionDto>>>;
};

export type StorePerformancePermutationSummaryDto = {
  __typename?: 'StorePerformancePermutationSummaryDto';
  averageCoverage: Scalars['Int'];
  coverageOneWeekBefore: Scalars['Int'];
  coverageThreeWeeksBefore: Scalars['Int'];
  coverageTwoWeeksBefore: Scalars['Int'];
  estimatedPicking: Scalars['Int'];
  numberOfProductsWithOptimalStock: Scalars['Int'];
  numberOfProductsWithSalesLastWeek: Scalars['Int'];
  numberOfProductsWithStock: Scalars['Int'];
  optimalStock: Scalars['Int'];
  optimalStockOneWeekBefore: Scalars['Int'];
  permutationField1?: Maybe<Scalars['String']>;
  permutationField2?: Maybe<Scalars['String']>;
  permutationFieldLabel1?: Maybe<Scalars['String']>;
  permutationFieldLabel2?: Maybe<Scalars['String']>;
  salesQuantityLastWeek: Scalars['Int'];
  stockQuantityLastWeek: Scalars['Int'];
};

export type StorePerformanceStoreSummaryDto = {
  __typename?: 'StorePerformanceStoreSummaryDto';
  averageCoverage: Scalars['Int'];
  capacity: Scalars['Int'];
  capacityRatio: Scalars['Decimal'];
  code?: Maybe<Scalars['String']>;
  coverageOneWeekBefore: Scalars['Int'];
  coverageThreeWeeksBefore: Scalars['Int'];
  coverageTwoWeeksBefore: Scalars['Int'];
  customField1?: Maybe<Scalars['String']>;
  customField2?: Maybe<Scalars['String']>;
  customField3?: Maybe<Scalars['String']>;
  customField4?: Maybe<Scalars['String']>;
  customField5?: Maybe<Scalars['String']>;
  estimatedPicking: Scalars['Int'];
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  numberOfProductsWithOptimalStock: Scalars['Int'];
  numberOfProductsWithSalesLastWeek: Scalars['Int'];
  numberOfProductsWithStock: Scalars['Int'];
  optimalStock: Scalars['Int'];
  optimalStockOneWeekBefore: Scalars['Int'];
  salesQuantityLastWeek: Scalars['Int'];
  stockQuantityLastWeek: Scalars['Int'];
  storeToStoreGroups?: Maybe<Array<Maybe<StoreFleetCollectionDto>>>;
};

export type StorePerformanceUpdateInput = {
  permutationFieldId1?: InputMaybe<Scalars['String']>;
  permutationFieldId2?: InputMaybe<Scalars['String']>;
  seasonId: Scalars['Int'];
  storeId: Scalars['Int'];
  universeId: Scalars['Int'];
  validatedStrength: Scalars['Int'];
};

export enum StoreSeasonImportType {
  Capacity = 'CAPACITY',
  IgnoreMinMax = 'IGNORE_MIN_MAX',
  IsSynced = 'IS_SYNCED',
  SeasonCode = 'SEASON_CODE',
  StoreCode = 'STORE_CODE',
  StoreGroupName = 'STORE_GROUP_NAME',
  TargetTurnover = 'TARGET_TURNOVER',
  UniverseCode = 'UNIVERSE_CODE'
}

export enum StoreSeasonPermutationFieldsImportType {
  PermutationFields = 'PERMUTATION_FIELDS',
  SeasonCode = 'SEASON_CODE',
  StoreCode = 'STORE_CODE',
  UniverseCode = 'UNIVERSE_CODE',
  ValidatedStrength = 'VALIDATED_STRENGTH'
}

export type StoreStrengthOverride = {
  __typename?: 'StoreStrengthOverride';
  id: Scalars['Int'];
  permutationFieldId1?: Maybe<Scalars['String']>;
  permutationFieldId2?: Maybe<Scalars['String']>;
  validatedStrength: Scalars['Int'];
};

export type StoreToStoreGroupUpdateInput = {
  collectionId: Scalars['Int'];
  seasonId: Scalars['Int'];
  storeGroupIds?: InputMaybe<Array<Scalars['Int']>>;
  storeId: Scalars['Int'];
  universeId: Scalars['Int'];
};

export type StoreType = {
  __typename?: 'StoreType';
  code?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isMain: Scalars['Boolean'];
  isWeb: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
};

export type StoreTypeCreateInput = {
  code: Scalars['String'];
  isMain: Scalars['Boolean'];
  isWeb: Scalars['Boolean'];
  name: Scalars['String'];
};

export type StoreTypeUpdateInput = {
  code?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  isMain?: InputMaybe<Scalars['Boolean']>;
  isWeb?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type StoreUniverseInfoDto = {
  __typename?: 'StoreUniverseInfoDto';
  capacityType?: Maybe<CapacityType>;
  code?: Maybe<Scalars['String']>;
  customFieldValue1?: Maybe<Scalars['String']>;
  customFieldValue2?: Maybe<Scalars['String']>;
  customFieldValue3?: Maybe<Scalars['String']>;
  customFieldValue4?: Maybe<Scalars['String']>;
  customFieldValue5?: Maybe<Scalars['String']>;
  handlesShowcase: Scalars['Boolean'];
  id: Scalars['Int'];
  ignoreMinMax: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  isSynced: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  origin: OriginType;
  photoUrls?: Maybe<Scalars['String']>;
  pickingPriority: Scalars['Int'];
  regionId?: Maybe<Scalars['Int']>;
  storeTypeId?: Maybe<Scalars['Int']>;
  supplierId?: Maybe<Scalars['Int']>;
};

export type TenantSettings = {
  __typename?: 'TenantSettings';
  id: Scalars['Int'];
  isErpMasterOfCreationAndModification: Scalars['Boolean'];
  productCoefficient: Scalars['Decimal'];
  productCoverage: Scalars['Int'];
  productCustomFieldName1?: Maybe<Scalars['String']>;
  productCustomFieldName2?: Maybe<Scalars['String']>;
  productCustomFieldName3?: Maybe<Scalars['String']>;
  productCustomFieldName4?: Maybe<Scalars['String']>;
  productCustomFieldName5?: Maybe<Scalars['String']>;
  productCustomFieldValues1?: Maybe<Scalars['String']>;
  productCustomFieldValues2?: Maybe<Scalars['String']>;
  productCustomFieldValues3?: Maybe<Scalars['String']>;
  productCustomFieldValues4?: Maybe<Scalars['String']>;
  productCustomFieldValues5?: Maybe<Scalars['String']>;
  productMaxStrength: Scalars['Int'];
  storeAverageStrength: Scalars['Int'];
  storeCapacityType: CapacityType;
  storeCoefficient: Scalars['Decimal'];
  storeCustomFieldName1?: Maybe<Scalars['String']>;
  storeCustomFieldName2?: Maybe<Scalars['String']>;
  storeCustomFieldName3?: Maybe<Scalars['String']>;
  storeCustomFieldName4?: Maybe<Scalars['String']>;
  storeCustomFieldName5?: Maybe<Scalars['String']>;
  storeCustomFieldValues1?: Maybe<Scalars['String']>;
  storeCustomFieldValues2?: Maybe<Scalars['String']>;
  storeCustomFieldValues3?: Maybe<Scalars['String']>;
  storeCustomFieldValues4?: Maybe<Scalars['String']>;
  storeCustomFieldValues5?: Maybe<Scalars['String']>;
  storeMaxStrength: Scalars['Int'];
  storeNumberOfWeeksStrength: Scalars['Int'];
  storePerformancePermutationField1?: Maybe<PermutationFieldType>;
  storePerformancePermutationField2?: Maybe<PermutationFieldType>;
};

export type TenantSettingsUpdateInput = {
  id: Scalars['Int'];
  productCoefficient?: InputMaybe<Scalars['Decimal']>;
  productCoverage?: InputMaybe<Scalars['Int']>;
  productMaxStrength?: InputMaybe<Scalars['Int']>;
  storeAverageStrength?: InputMaybe<Scalars['Int']>;
  storeCapacityType?: InputMaybe<CapacityType>;
  storeCoefficient?: InputMaybe<Scalars['Decimal']>;
  storeMaxStrength?: InputMaybe<Scalars['Int']>;
  storeNumberOfWeeksStrength?: InputMaybe<Scalars['Int']>;
  storePerformancePermutationField1?: InputMaybe<PermutationFieldType>;
  storePerformancePermutationField2?: InputMaybe<PermutationFieldType>;
};

export type Universe = {
  __typename?: 'Universe';
  code?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  jobDescription?: Maybe<Scalars['String']>;
  userRoleId: UserRoleType;
  userSettings?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type UserCreateInput = {
  email: Scalars['String'];
  jobDescription: Scalars['String'];
  userRoleType: UserRoleType;
  username: Scalars['String'];
};

export type UserInfoDto = {
  __typename?: 'UserInfoDto';
  permissions?: Maybe<Array<PermissionsEnum>>;
  userSettings?: Maybe<Scalars['String']>;
};

export type UserRole = {
  __typename?: 'UserRole';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export enum UserRoleType {
  Admin = 'ADMIN',
  Editor = 'EDITOR',
  Viewer = 'VIEWER'
}

export type UserUpdateInput = {
  id: Scalars['Int'];
  jobDescription?: InputMaybe<Scalars['String']>;
  userRoleType?: InputMaybe<UserRoleType>;
  userSettings?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type Warehouse = {
  __typename?: 'Warehouse';
  code?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isRetail: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  supplierId?: Maybe<Scalars['Int']>;
};

export type WarehouseCreateInput = {
  code: Scalars['String'];
  isRetail: Scalars['Boolean'];
  name: Scalars['String'];
  supplierId: Scalars['Int'];
};

export type WarehouseUpdateInput = {
  code?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  isRetail?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  supplierId?: InputMaybe<Scalars['Int']>;
};

export type Mutations = {
  __typename?: 'mutations';
  assignFamilyToSizeSystem: Scalars['Boolean'];
  bulkUpdateUser: Array<Maybe<User>>;
  changeProductSeason: Scalars['Boolean'];
  createCollection: Collection;
  createProductModule: ProductModule;
  createSeason: Season;
  createSizeSystem: SizeSystem;
  createSizeSystemRow: SizeSystemRow;
  createSizeSystemRowHeader: SizeSystemRowHeader;
  createStoreGroup: StoreGroup;
  createStoreType: StoreType;
  createUser: User;
  createWarehouse: Warehouse;
  deleteBulkStoreOptimalStockOverride: Scalars['Boolean'];
  deleteCollection: Scalars['Boolean'];
  deleteProductModule: Scalars['Boolean'];
  deleteSeason: Scalars['Boolean'];
  deleteSelectedStoreOptimalStockOverride: Scalars['Boolean'];
  deleteSizeSystem: Scalars['Boolean'];
  deleteSizeSystemRow: Scalars['Boolean'];
  deleteSizeSystemRowHeader: Scalars['Boolean'];
  deleteStoreGroup: Scalars['Boolean'];
  deleteStoreGroupToProductModule: Scalars['Boolean'];
  deleteStoreOptimalStockOverride: Scalars['Boolean'];
  deleteStoreType: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  deleteWarehouse: Scalars['Boolean'];
  deselectSizeSystemIsOnShowcase: Scalars['Boolean'];
  generateExport: Scalars['Boolean'];
  removeFamilyFromSizeSystem: Scalars['Boolean'];
  removeStoreOptimalStockException: Scalars['Boolean'];
  storeReplaceValidatedWithImplantedStrength: Scalars['Boolean'];
  updateBulkCollections: Array<Maybe<Collection>>;
  updateBulkOptimalStockOverride: Array<Maybe<ArticleOptimalStockOverride>>;
  updateBulkOptimalStockOverrideFlags: Array<Maybe<ArticleOptimalStockOverride>>;
  updateBulkSeasons: Array<Maybe<Season>>;
  updateBulkSizeSystemRows: Array<Maybe<SizeSystemRow>>;
  updateBulkSizeSystems: Array<Maybe<SizeSystem>>;
  updateBulkStoreOptimalStockOverride: Array<Maybe<ArticleOptimalStockException>>;
  updateBulkStoreTypes: Array<Maybe<StoreType>>;
  updateBulkWarehouses: Array<Maybe<Warehouse>>;
  updateCollection: Collection;
  updateOptimalStockOverride: ArticleOptimalStockOverride;
  updateProduct: Product;
  updateProductCustomField: ProductCustomFieldsDto;
  updateProductDetails: Product;
  updateSeason: Season;
  updateSizeSystem: SizeSystem;
  updateSizeSystemRow: SizeSystemRow;
  updateSizeSystemRowHeader: SizeSystemRowHeader;
  updateStoreCustomField: StoreCustomFieldsDto;
  updateStoreDetails: StoreUniverseInfoDto;
  updateStoreFleet: StoreUniverseInfoDto;
  updateStoreGroupToProductModule: Scalars['Boolean'];
  updateStoreOptimalStockException: Scalars['Boolean'];
  updateStoreOptimalStockOverride: ArticleOptimalStockException;
  updateStorePerformance: StoreStrengthOverride;
  updateStoreToStoreGroup: Scalars['Boolean'];
  updateStoreType: StoreType;
  updateTenantSettings: Scalars['Boolean'];
  updateUser: User;
  updateUserSettings?: Maybe<Scalars['String']>;
  updateWarehouse: Warehouse;
};


export type MutationsAssignFamilyToSizeSystemArgs = {
  familyId: Scalars['Int'];
  sizeSystemId: Scalars['Int'];
};


export type MutationsBulkUpdateUserArgs = {
  usersInput?: InputMaybe<Array<InputMaybe<UserUpdateInput>>>;
};


export type MutationsChangeProductSeasonArgs = {
  newSeasonId: Scalars['Int'];
  productId: Scalars['Int'];
};


export type MutationsCreateCollectionArgs = {
  collection?: InputMaybe<CollectionCreateInput>;
};


export type MutationsCreateProductModuleArgs = {
  productModule?: InputMaybe<ProductModuleCreateInput>;
};


export type MutationsCreateSeasonArgs = {
  season?: InputMaybe<SeasonCreateInput>;
};


export type MutationsCreateSizeSystemArgs = {
  sizeSystem?: InputMaybe<SizeSystemCreateInput>;
};


export type MutationsCreateSizeSystemRowArgs = {
  sizeSystemRow?: InputMaybe<SizeSystemRowCreateInput>;
};


export type MutationsCreateSizeSystemRowHeaderArgs = {
  sizeSystemRowHeader?: InputMaybe<SizeSystemRowHeaderCreateInput>;
};


export type MutationsCreateStoreGroupArgs = {
  storeGroup?: InputMaybe<StoreGroupCreateInput>;
};


export type MutationsCreateStoreTypeArgs = {
  storeType?: InputMaybe<StoreTypeCreateInput>;
};


export type MutationsCreateUserArgs = {
  userInput?: InputMaybe<UserCreateInput>;
};


export type MutationsCreateWarehouseArgs = {
  warehouse?: InputMaybe<WarehouseCreateInput>;
};


export type MutationsDeleteBulkStoreOptimalStockOverrideArgs = {
  productId: Scalars['Int'];
};


export type MutationsDeleteCollectionArgs = {
  id: Scalars['Int'];
};


export type MutationsDeleteProductModuleArgs = {
  id: Scalars['Int'];
};


export type MutationsDeleteSeasonArgs = {
  id: Scalars['Int'];
};


export type MutationsDeleteSelectedStoreOptimalStockOverrideArgs = {
  productId: Scalars['Int'];
  storeIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationsDeleteSizeSystemArgs = {
  id: Scalars['Int'];
};


export type MutationsDeleteSizeSystemRowArgs = {
  id: Scalars['Int'];
  sizeSystemId: Scalars['Int'];
};


export type MutationsDeleteSizeSystemRowHeaderArgs = {
  id: Scalars['Int'];
  sizeSystemId: Scalars['Int'];
};


export type MutationsDeleteStoreGroupArgs = {
  id: Scalars['Int'];
};


export type MutationsDeleteStoreGroupToProductModuleArgs = {
  id: Scalars['Int'];
};


export type MutationsDeleteStoreOptimalStockOverrideArgs = {
  productId: Scalars['Int'];
  storeId: Scalars['Int'];
};


export type MutationsDeleteStoreTypeArgs = {
  id: Scalars['Int'];
};


export type MutationsDeleteUserArgs = {
  userId: Scalars['Int'];
};


export type MutationsDeleteWarehouseArgs = {
  id: Scalars['Int'];
};


export type MutationsDeselectSizeSystemIsOnShowcaseArgs = {
  sizeSystemId: Scalars['Int'];
};


export type MutationsRemoveFamilyFromSizeSystemArgs = {
  familyId: Scalars['Int'];
  sizeSystemId: Scalars['Int'];
};


export type MutationsRemoveStoreOptimalStockExceptionArgs = {
  productId: Scalars['Int'];
  storeId: Scalars['Int'];
};


export type MutationsStoreReplaceValidatedWithImplantedStrengthArgs = {
  replaceNonZeroValues: Scalars['Boolean'];
  seasonId: Scalars['Int'];
  universeId: Scalars['Int'];
};


export type MutationsUpdateBulkCollectionsArgs = {
  inputCollections?: InputMaybe<Array<InputMaybe<CollectionUpdateInput>>>;
};


export type MutationsUpdateBulkOptimalStockOverrideArgs = {
  stockOverrideBulk?: InputMaybe<Array<InputMaybe<OptimalStockOverrideUpdateInput>>>;
};


export type MutationsUpdateBulkOptimalStockOverrideFlagsArgs = {
  stockOverrideBulk?: InputMaybe<OptimalStockOverrideUpdateBulkInput>;
};


export type MutationsUpdateBulkSeasonsArgs = {
  inputSeasons?: InputMaybe<Array<InputMaybe<SeasonUpdateInput>>>;
};


export type MutationsUpdateBulkSizeSystemRowsArgs = {
  inputSizeSystemRows?: InputMaybe<Array<InputMaybe<SizeSystemRowUpdateInput>>>;
};


export type MutationsUpdateBulkSizeSystemsArgs = {
  inputSizeSystems?: InputMaybe<Array<InputMaybe<SizeSystemUpdateInput>>>;
};


export type MutationsUpdateBulkStoreOptimalStockOverrideArgs = {
  exceptionOptimalStocks?: InputMaybe<Array<InputMaybe<ExceptionOptimalStockUpdateInput>>>;
};


export type MutationsUpdateBulkStoreTypesArgs = {
  inputStoreTypes?: InputMaybe<Array<InputMaybe<StoreTypeUpdateInput>>>;
};


export type MutationsUpdateBulkWarehousesArgs = {
  inputWarehouses?: InputMaybe<Array<InputMaybe<WarehouseUpdateInput>>>;
};


export type MutationsUpdateCollectionArgs = {
  collection?: InputMaybe<CollectionUpdateInput>;
};


export type MutationsUpdateOptimalStockOverrideArgs = {
  stockOverride?: InputMaybe<OptimalStockOverrideUpdateInput>;
};


export type MutationsUpdateProductArgs = {
  productDetails?: InputMaybe<ProductOptimalStockUpdateInput>;
};


export type MutationsUpdateProductCustomFieldArgs = {
  productCustomField?: InputMaybe<ProductCustomFieldUpdateInput>;
};


export type MutationsUpdateProductDetailsArgs = {
  productDetails?: InputMaybe<ProductDetailsUpdateInput>;
};


export type MutationsUpdateSeasonArgs = {
  season?: InputMaybe<SeasonUpdateInput>;
};


export type MutationsUpdateSizeSystemArgs = {
  sizeSystem?: InputMaybe<SizeSystemUpdateInput>;
};


export type MutationsUpdateSizeSystemRowArgs = {
  sizeSystemRow?: InputMaybe<SizeSystemRowUpdateInput>;
};


export type MutationsUpdateSizeSystemRowHeaderArgs = {
  sizeSystemRowHeader?: InputMaybe<SizeSystemRowHeaderUpdateInput>;
};


export type MutationsUpdateStoreCustomFieldArgs = {
  storeCustomField?: InputMaybe<StoreCustomFieldUpdateInput>;
};


export type MutationsUpdateStoreDetailsArgs = {
  storeDetails?: InputMaybe<StoreDetailsUpdateInput>;
};


export type MutationsUpdateStoreFleetArgs = {
  storeFleet?: InputMaybe<StoreFleetInput>;
};


export type MutationsUpdateStoreGroupToProductModuleArgs = {
  storeGroupToProductModule?: InputMaybe<StoreGroupToProductModuleUpdateInput>;
};


export type MutationsUpdateStoreOptimalStockExceptionArgs = {
  input?: InputMaybe<StoreOptimalStockExceptionInput>;
};


export type MutationsUpdateStoreOptimalStockOverrideArgs = {
  exceptionOptimalStock?: InputMaybe<ExceptionOptimalStockUpdateInput>;
};


export type MutationsUpdateStorePerformanceArgs = {
  storePerformance?: InputMaybe<StorePerformanceUpdateInput>;
};


export type MutationsUpdateStoreToStoreGroupArgs = {
  storeToStoreGroups?: InputMaybe<StoreToStoreGroupUpdateInput>;
};


export type MutationsUpdateStoreTypeArgs = {
  storeType?: InputMaybe<StoreTypeUpdateInput>;
};


export type MutationsUpdateTenantSettingsArgs = {
  tenantSettings?: InputMaybe<TenantSettingsUpdateInput>;
};


export type MutationsUpdateUserArgs = {
  userInput?: InputMaybe<UserUpdateInput>;
};


export type MutationsUpdateUserSettingsArgs = {
  userSettings?: InputMaybe<Scalars['String']>;
};


export type MutationsUpdateWarehouseArgs = {
  warehouse?: InputMaybe<WarehouseUpdateInput>;
};

export type Queries = {
  __typename?: 'queries';
  auditableInfos?: Maybe<AuditableInfoDtoCollectionSegment>;
  collection?: Maybe<Collection>;
  collections?: Maybe<Array<Collection>>;
  dashboard: DashboardDto;
  exceptionOptimalStockTable: ExceptionOptimalStockTableDto;
  families?: Maybe<Array<Family>>;
  familyToSizeSystem?: Maybe<Array<Family>>;
  fileImportLog: FileImportLog;
  fileImportLogs?: Maybe<Array<FileImportLog>>;
  optimalStockOverride?: Maybe<Array<ArticleOptimalStockOverride>>;
  optimalStockSimulation?: Maybe<PaginationInfoOfOptimalStockSimulationDto>;
  possiblePermutations?: Maybe<Array<Scalars['String']>>;
  productCustomFields: ProductCustomFieldsDto;
  productDetails: ProductDetailsDto;
  productOptimalStockProductSummary?: Maybe<ProductOptimalStockSummaryDto>;
  productPerformance?: Maybe<Array<ProductFleetPerformanceDto>>;
  retailWarehouseStock?: Maybe<Array<ExceptionOptimalStockRetailStockDto>>;
  seasons?: Maybe<Array<Season>>;
  sizeSystemRowHeaders?: Maybe<Array<SizeSystemRowHeader>>;
  sizeSystemRows?: Maybe<Array<SizeSystemRow>>;
  sizeSystems?: Maybe<Array<SizeSystem>>;
  stocksSalesTable: ProductOptimalSalesTableDto;
  storeCustomFields: StoreCustomFieldsDto;
  storeDetails?: Maybe<StoreDetailsDto>;
  storeFleet?: Maybe<Array<StoreFleetDto>>;
  storeFleetSummary: StoreFleetSummaryTableDto;
  storeGroups?: Maybe<Array<StoreGroup>>;
  storeOptimalStockExceptions?: Maybe<Array<StoreOptimalStockExceptionDto>>;
  storePerformance?: Maybe<Array<StorePerformanceDto>>;
  storePerformancePermutationSummary?: Maybe<Array<StorePerformancePermutationSummaryDto>>;
  storePerformanceStoreSummary?: Maybe<Array<StorePerformanceStoreSummaryDto>>;
  storeStrengthTable: ProductOptimalStockTableWrapperDto;
  storeTypes?: Maybe<Array<StoreType>>;
  tenantSettings: TenantSettings;
  universes?: Maybe<Array<Universe>>;
  user?: Maybe<Array<User>>;
  userRoles?: Maybe<Array<UserRole>>;
  userSettings: UserInfoDto;
  users?: Maybe<Array<User>>;
  warehouses?: Maybe<Array<Warehouse>>;
};


export type QueriesAuditableInfosArgs = {
  dateRangeEnd?: InputMaybe<Scalars['DateTime']>;
  dateRangeStart?: InputMaybe<Scalars['DateTime']>;
  objectType?: InputMaybe<AuditableObjectType>;
  seasonCode?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  userGuid?: InputMaybe<Scalars['String']>;
};


export type QueriesCollectionArgs = {
  id: Scalars['Int'];
  isActive?: InputMaybe<Scalars['Boolean']>;
};


export type QueriesCollectionsArgs = {
  isActive?: InputMaybe<Scalars['Boolean']>;
  universeId: Scalars['Int'];
};


export type QueriesExceptionOptimalStockTableArgs = {
  hasException: Scalars['Boolean'];
  productId: Scalars['Int'];
  salesEnum: ExceptionOptimalStockSalesEnum;
  stockEnum: ExceptionOptimalStockStockEnum;
};


export type QueriesFamilyToSizeSystemArgs = {
  sizeSystemId: Scalars['Int'];
};


export type QueriesFileImportLogArgs = {
  fileImportLogId: Scalars['Int'];
};


export type QueriesOptimalStockOverrideArgs = {
  articleId: Scalars['Int'];
};


export type QueriesOptimalStockSimulationArgs = {
  customField1?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  customField2?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  customField3?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  customField4?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  customField5?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filters?: InputMaybe<Array<OptimalStockSimulationFilterDtoInput>>;
  isAsc?: InputMaybe<Scalars['Boolean']>;
  permutations?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  seasonId?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sortType?: InputMaybe<OptimalStockSimulationField>;
  take?: InputMaybe<Scalars['Int']>;
  universeId: Scalars['Int'];
};


export type QueriesProductDetailsArgs = {
  id: Scalars['Int'];
};


export type QueriesProductOptimalStockProductSummaryArgs = {
  productId: Scalars['Int'];
};


export type QueriesProductPerformanceArgs = {
  seasonId: Scalars['Int'];
  universeId: Scalars['Int'];
};


export type QueriesRetailWarehouseStockArgs = {
  productId: Scalars['Int'];
};


export type QueriesSizeSystemRowHeadersArgs = {
  sizeSystemId: Scalars['Int'];
};


export type QueriesSizeSystemRowsArgs = {
  sizeSystemId: Scalars['Int'];
};


export type QueriesStocksSalesTableArgs = {
  productId: Scalars['Int'];
};


export type QueriesStoreDetailsArgs = {
  id?: InputMaybe<Scalars['Int']>;
  seasonId: Scalars['Int'];
  universeId: Scalars['Int'];
};


export type QueriesStoreFleetArgs = {
  seasonId: Scalars['Int'];
  universeId: Scalars['Int'];
};


export type QueriesStoreFleetSummaryArgs = {
  seasonId: Scalars['Int'];
  universeId: Scalars['Int'];
};


export type QueriesStoreGroupsArgs = {
  collectionId: Scalars['Int'];
};


export type QueriesStoreOptimalStockExceptionsArgs = {
  isIncluded?: InputMaybe<Scalars['Boolean']>;
  productId: Scalars['Int'];
};


export type QueriesStorePerformanceArgs = {
  seasonId: Scalars['Int'];
  universeId: Scalars['Int'];
};


export type QueriesStorePerformancePermutationSummaryArgs = {
  seasonId: Scalars['Int'];
  universeId: Scalars['Int'];
};


export type QueriesStorePerformanceStoreSummaryArgs = {
  seasonId: Scalars['Int'];
  universeId: Scalars['Int'];
};


export type QueriesStoreStrengthTableArgs = {
  productId: Scalars['Int'];
};


export type QueriesUserArgs = {
  userGuid: Scalars['String'];
};

export type DeleteWarehousesMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteWarehousesMutation = { __typename?: 'mutations', deleteWarehouse: boolean };

export type BulkUpdateWarehousesMutationVariables = Exact<{
  inputWarehouses?: InputMaybe<Array<InputMaybe<WarehouseUpdateInput>> | InputMaybe<WarehouseUpdateInput>>;
}>;


export type BulkUpdateWarehousesMutation = { __typename?: 'mutations', updateBulkWarehouses: Array<{ __typename?: 'Warehouse', id: number, isRetail: boolean, code?: string | null, name?: string | null } | null> };

export type CreateWarehouseMutationVariables = Exact<{
  warehouse?: InputMaybe<WarehouseCreateInput>;
}>;


export type CreateWarehouseMutation = { __typename?: 'mutations', createWarehouse: { __typename?: 'Warehouse', id: number, isRetail: boolean, code?: string | null, name?: string | null } };

export type CreateCollectionMutationVariables = Exact<{
  collection?: InputMaybe<CollectionCreateInput>;
}>;


export type CreateCollectionMutation = { __typename?: 'mutations', createCollection: { __typename?: 'Collection', name?: string | null, code?: string | null, combinationType: CombinationType, universeId: number } };

export type BulkUpdateCollectionsMutationVariables = Exact<{
  inputCollections?: InputMaybe<Array<InputMaybe<CollectionUpdateInput>> | InputMaybe<CollectionUpdateInput>>;
}>;


export type BulkUpdateCollectionsMutation = { __typename?: 'mutations', updateBulkCollections: Array<{ __typename?: 'Collection', id: number, code?: string | null, name?: string | null, isActive: boolean } | null> };

export type UpdateCollectionMutationVariables = Exact<{
  inputCollection?: InputMaybe<CollectionUpdateInput>;
}>;


export type UpdateCollectionMutation = { __typename?: 'mutations', updateCollection: { __typename?: 'Collection', id: number, code?: string | null, name?: string | null, isActive: boolean } };

export type UpdateStoreOptimalStockOverrideMutationVariables = Exact<{
  exceptionOptimalStock?: InputMaybe<ExceptionOptimalStockUpdateInput>;
}>;


export type UpdateStoreOptimalStockOverrideMutation = { __typename?: 'mutations', updateStoreOptimalStockOverride: { __typename?: 'ArticleOptimalStockException', storeId: number } };

export type UpdateBulkStoreOptimalStockOverrideMutationVariables = Exact<{
  exceptionOptimalStocks?: InputMaybe<Array<InputMaybe<ExceptionOptimalStockUpdateInput>> | InputMaybe<ExceptionOptimalStockUpdateInput>>;
}>;


export type UpdateBulkStoreOptimalStockOverrideMutation = { __typename?: 'mutations', updateBulkStoreOptimalStockOverride: Array<{ __typename?: 'ArticleOptimalStockException', storeId: number } | null> };

export type UpdateStoreOptimalStockExceptionMutationVariables = Exact<{
  input?: InputMaybe<StoreOptimalStockExceptionInput>;
}>;


export type UpdateStoreOptimalStockExceptionMutation = { __typename?: 'mutations', updateStoreOptimalStockException: boolean };

export type RemoveStoreOptimalStockExceptionMutationVariables = Exact<{
  productId: Scalars['Int'];
  storeId: Scalars['Int'];
}>;


export type RemoveStoreOptimalStockExceptionMutation = { __typename?: 'mutations', removeStoreOptimalStockException: boolean };

export type DeleteStoreOptimalStockOverrideMutationVariables = Exact<{
  storeId: Scalars['Int'];
  productId: Scalars['Int'];
}>;


export type DeleteStoreOptimalStockOverrideMutation = { __typename?: 'mutations', deleteStoreOptimalStockOverride: boolean };

export type DeleteBulkStoreOptimalStockOverrideMutationVariables = Exact<{
  productId: Scalars['Int'];
}>;


export type DeleteBulkStoreOptimalStockOverrideMutation = { __typename?: 'mutations', deleteBulkStoreOptimalStockOverride: boolean };

export type DeleteSelectedStoreOptimalStockOverrideMutationVariables = Exact<{
  productId: Scalars['Int'];
  storeIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type DeleteSelectedStoreOptimalStockOverrideMutation = { __typename?: 'mutations', deleteSelectedStoreOptimalStockOverride: boolean };

export type DeselectSizeSystemIsOnShowcaseMutationVariables = Exact<{
  sizeSystemId: Scalars['Int'];
}>;


export type DeselectSizeSystemIsOnShowcaseMutation = { __typename?: 'mutations', deselectSizeSystemIsOnShowcase: boolean };

export type DeleteCollectionMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteCollectionMutation = { __typename?: 'mutations', deleteCollection: boolean };

export type CreateSizeSystemMutationVariables = Exact<{
  sizeSystem?: InputMaybe<SizeSystemCreateInput>;
}>;


export type CreateSizeSystemMutation = { __typename?: 'mutations', createSizeSystem: { __typename?: 'SizeSystem', code?: string | null, name?: string | null } };

export type BulkUpdateSizeSystemsMutationVariables = Exact<{
  inputSizeSystems?: InputMaybe<Array<InputMaybe<SizeSystemUpdateInput>> | InputMaybe<SizeSystemUpdateInput>>;
}>;


export type BulkUpdateSizeSystemsMutation = { __typename?: 'mutations', updateBulkSizeSystems: Array<{ __typename?: 'SizeSystem', id: number, code?: string | null, name?: string | null } | null> };

export type UpdateSizeSystemsMutationVariables = Exact<{
  inputSizeSystem?: InputMaybe<SizeSystemUpdateInput>;
}>;


export type UpdateSizeSystemsMutation = { __typename?: 'mutations', updateSizeSystem: { __typename?: 'SizeSystem', id: number, code?: string | null, name?: string | null } };

export type DeleteSizeSystemMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteSizeSystemMutation = { __typename?: 'mutations', deleteSizeSystem: boolean };

export type AssignFamilyToSizeSystemMutationVariables = Exact<{
  familyId: Scalars['Int'];
  sizeSystemId: Scalars['Int'];
}>;


export type AssignFamilyToSizeSystemMutation = { __typename?: 'mutations', assignFamilyToSizeSystem: boolean };

export type RemoveFamilyFromSizeSystemMutationVariables = Exact<{
  familyId: Scalars['Int'];
  sizeSystemId: Scalars['Int'];
}>;


export type RemoveFamilyFromSizeSystemMutation = { __typename?: 'mutations', removeFamilyFromSizeSystem: boolean };

export type CreateSizeSystemRowHeaderMutationVariables = Exact<{
  sizeSystemRowHeader?: InputMaybe<SizeSystemRowHeaderCreateInput>;
}>;


export type CreateSizeSystemRowHeaderMutation = { __typename?: 'mutations', createSizeSystemRowHeader: { __typename?: 'SizeSystemRowHeader', name?: string | null } };

export type CreateSizeSystemRowMutationVariables = Exact<{
  sizeSystemRow?: InputMaybe<SizeSystemRowCreateInput>;
}>;


export type CreateSizeSystemRowMutation = { __typename?: 'mutations', createSizeSystemRow: { __typename?: 'SizeSystemRow', name?: string | null, sortNumber: number } };

export type UpdateSizeSystemRowHeaderMutationVariables = Exact<{
  sizeSystemRowHeader?: InputMaybe<SizeSystemRowHeaderUpdateInput>;
}>;


export type UpdateSizeSystemRowHeaderMutation = { __typename?: 'mutations', updateSizeSystemRowHeader: { __typename?: 'SizeSystemRowHeader', id: number, isOnShowcase: boolean } };

export type BulkUpdateSizeSystemRowsMutationVariables = Exact<{
  inputSizeSystemRows?: InputMaybe<Array<InputMaybe<SizeSystemRowUpdateInput>> | InputMaybe<SizeSystemRowUpdateInput>>;
}>;


export type BulkUpdateSizeSystemRowsMutation = { __typename?: 'mutations', updateBulkSizeSystemRows: Array<{ __typename?: 'SizeSystemRow', name?: string | null, sortNumber: number } | null> };

export type DeleteSizeSystemRowMutationVariables = Exact<{
  id: Scalars['Int'];
  sizeSystemId: Scalars['Int'];
}>;


export type DeleteSizeSystemRowMutation = { __typename?: 'mutations', deleteSizeSystemRow: boolean };

export type DeleteSizeSystemRowHeaderMutationVariables = Exact<{
  id: Scalars['Int'];
  sizeSystemId: Scalars['Int'];
}>;


export type DeleteSizeSystemRowHeaderMutation = { __typename?: 'mutations', deleteSizeSystemRowHeader: boolean };

export type UpdateProductModuleMutationVariables = Exact<{
  productId: Scalars['Int'];
  moduleId: Scalars['Int'];
}>;


export type UpdateProductModuleMutation = { __typename?: 'mutations', updateProductDetails: { __typename?: 'Product', productModuleId?: number | null } };

export type UpdateProductShowcaseMutationVariables = Exact<{
  productId: Scalars['Int'];
  isOnShowcase: Scalars['Boolean'];
}>;


export type UpdateProductShowcaseMutation = { __typename?: 'mutations', updateProductDetails: { __typename?: 'Product', isOnShowcase: boolean } };

export type UpdateProductSeasonMutationVariables = Exact<{
  productId: Scalars['Int'];
  seasonId?: InputMaybe<Scalars['Int']>;
}>;


export type UpdateProductSeasonMutation = { __typename?: 'mutations', updateProductDetails: { __typename?: 'Product', seasonId: number } };

export type CreateProductModuleMutationVariables = Exact<{
  productModule?: InputMaybe<ProductModuleCreateInput>;
}>;


export type CreateProductModuleMutation = { __typename?: 'mutations', createProductModule: { __typename?: 'ProductModule', id: number, name?: string | null, collectionId: number, storeGroupToProductModules?: Array<{ __typename?: 'StoreGroupToProductModule', id: number } | null> | null } };

export type DeleteProductModuleMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteProductModuleMutation = { __typename?: 'mutations', deleteProductModule: boolean };

export type DeleteStoreGroupMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteStoreGroupMutation = { __typename?: 'mutations', deleteStoreGroup: boolean };

export type CreateStoreGroupMutationVariables = Exact<{
  storeGroup?: InputMaybe<StoreGroupCreateInput>;
}>;


export type CreateStoreGroupMutation = { __typename?: 'mutations', createStoreGroup: { __typename?: 'StoreGroup', id: number, name?: string | null, storeGroupToProductModules?: Array<{ __typename?: 'StoreGroupToProductModule', id: number, isSelected: boolean } | null> | null } };

export type UpdateStoreGroupToProductModuleMutationVariables = Exact<{
  storeGroupToProductModule?: InputMaybe<StoreGroupToProductModuleUpdateInput>;
}>;


export type UpdateStoreGroupToProductModuleMutation = { __typename?: 'mutations', updateStoreGroupToProductModule: boolean };

export type UpdateProductValidatedStrengthMutationVariables = Exact<{
  productId: Scalars['Int'];
  validatedProductStrength: Scalars['Decimal'];
}>;


export type UpdateProductValidatedStrengthMutation = { __typename?: 'mutations', updateProduct: { __typename?: 'Product', id: number } };

export type UpdateProductSizeSystemMutationVariables = Exact<{
  productId: Scalars['Int'];
  sizeSystemRowId: Scalars['Int'];
}>;


export type UpdateProductSizeSystemMutation = { __typename?: 'mutations', updateProduct: { __typename?: 'Product', sizeSystemRowId?: number | null } };

export type CreateSeasonMutationVariables = Exact<{
  season?: InputMaybe<SeasonCreateInput>;
}>;


export type CreateSeasonMutation = { __typename?: 'mutations', createSeason: { __typename?: 'Season', code?: string | null, name?: string | null } };

export type CreateStoreTypeMutationVariables = Exact<{
  storeType?: InputMaybe<StoreTypeCreateInput>;
}>;


export type CreateStoreTypeMutation = { __typename?: 'mutations', createStoreType: { __typename?: 'StoreType', code?: string | null, name?: string | null, isMain: boolean, isWeb: boolean } };

export type UpdateStoreTypeMutationVariables = Exact<{
  storeType?: InputMaybe<StoreTypeUpdateInput>;
}>;


export type UpdateStoreTypeMutation = { __typename?: 'mutations', updateStoreType: { __typename?: 'StoreType', id: number, code?: string | null, name?: string | null, isMain: boolean, isWeb: boolean } };

export type StoreReplaceValidatedWithImplantedStrengthMutationVariables = Exact<{
  replaceNonZeroValues: Scalars['Boolean'];
  seasonId: Scalars['Int'];
  universeId: Scalars['Int'];
}>;


export type StoreReplaceValidatedWithImplantedStrengthMutation = { __typename?: 'mutations', storeReplaceValidatedWithImplantedStrength: boolean };

export type UpdateBulkStoreTypesMutationVariables = Exact<{
  inputStoreTypes?: InputMaybe<Array<InputMaybe<StoreTypeUpdateInput>> | InputMaybe<StoreTypeUpdateInput>>;
}>;


export type UpdateBulkStoreTypesMutation = { __typename?: 'mutations', updateBulkStoreTypes: Array<{ __typename?: 'StoreType', id: number, code?: string | null, name?: string | null, isMain: boolean, isWeb: boolean } | null> };

export type BulkUpdateSeasonsMutationVariables = Exact<{
  inputSeasons?: InputMaybe<Array<InputMaybe<SeasonUpdateInput>> | InputMaybe<SeasonUpdateInput>>;
}>;


export type BulkUpdateSeasonsMutation = { __typename?: 'mutations', updateBulkSeasons: Array<{ __typename?: 'Season', id: number, name?: string | null, code?: string | null, isActive: boolean } | null> };

export type UpdateSeasonMutationVariables = Exact<{
  inputSeason?: InputMaybe<SeasonUpdateInput>;
}>;


export type UpdateSeasonMutation = { __typename?: 'mutations', updateSeason: { __typename?: 'Season', id: number, name?: string | null, code?: string | null, isActive: boolean } };

export type DeleteSeasonMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteSeasonMutation = { __typename?: 'mutations', deleteSeason: boolean };

export type DeleteStoreTypeMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteStoreTypeMutation = { __typename?: 'mutations', deleteStoreType: boolean };

export type UpdateOptimalStockOverrideMutationVariables = Exact<{
  stockOverride?: InputMaybe<OptimalStockOverrideUpdateInput>;
}>;


export type UpdateOptimalStockOverrideMutation = { __typename?: 'mutations', updateOptimalStockOverride: { __typename?: 'ArticleOptimalStockOverride', id: number } };

export type UpdateBulkOptimalStockOverrideFlagsMutationVariables = Exact<{
  stockOverrideBulk?: InputMaybe<OptimalStockOverrideUpdateBulkInput>;
}>;


export type UpdateBulkOptimalStockOverrideFlagsMutation = { __typename?: 'mutations', updateBulkOptimalStockOverrideFlags: Array<{ __typename?: 'ArticleOptimalStockOverride', id: number, hasStockMin: boolean, hasStockMax: boolean, hasCondition: boolean, stockMinStoreStrengthCondition: number, stockMaxStoreStrengthCondition: number } | null> };

export type UpdateBulkOptimalStockOverrideMutationVariables = Exact<{
  stockOverrideBulk?: InputMaybe<Array<InputMaybe<OptimalStockOverrideUpdateInput>> | InputMaybe<OptimalStockOverrideUpdateInput>>;
}>;


export type UpdateBulkOptimalStockOverrideMutation = { __typename?: 'mutations', updateBulkOptimalStockOverride: Array<{ __typename?: 'ArticleOptimalStockOverride', id: number, stockMin: number, stockMax: number, stockMinStoreStrengthConditionValue: number, stockMaxStoreStrengthConditionValue: number } | null> };

export type UpdateProductCustomFieldMutationVariables = Exact<{
  productCustomField?: InputMaybe<ProductCustomFieldUpdateInput>;
}>;


export type UpdateProductCustomFieldMutation = { __typename?: 'mutations', updateProductCustomField: { __typename?: 'ProductCustomFieldsDto', customFieldName1?: string | null, customFieldValues1?: string | null, customFieldName2?: string | null, customFieldValues2?: string | null, customFieldName3?: string | null, customFieldValues3?: string | null, customFieldName4?: string | null, customFieldValues4?: string | null, customFieldName5?: string | null, customFieldValues5?: string | null } };

export type UpdateStoreCustomFieldMutationVariables = Exact<{
  storeCustomField?: InputMaybe<StoreCustomFieldUpdateInput>;
}>;


export type UpdateStoreCustomFieldMutation = { __typename?: 'mutations', updateStoreCustomField: { __typename?: 'StoreCustomFieldsDto', customFieldName1?: string | null, customFieldValues1?: string | null, customFieldName2?: string | null, customFieldValues2?: string | null, customFieldName3?: string | null, customFieldValues3?: string | null, customFieldName4?: string | null, customFieldValues4?: string | null, customFieldName5?: string | null, customFieldValues5?: string | null } };

export type UpdateProductDetailsMutationVariables = Exact<{
  productDetails?: InputMaybe<ProductDetailsUpdateInput>;
}>;


export type UpdateProductDetailsMutation = { __typename?: 'mutations', updateProductDetails: { __typename?: 'Product', id: number } };

export type UpdateStoreDetailsMutationVariables = Exact<{
  storeDetails?: InputMaybe<StoreDetailsUpdateInput>;
}>;


export type UpdateStoreDetailsMutation = { __typename?: 'mutations', updateStoreDetails: { __typename?: 'StoreUniverseInfoDto', id: number } };

export type UpdateStoreFleetMutationVariables = Exact<{
  storeFleet?: InputMaybe<StoreFleetInput>;
}>;


export type UpdateStoreFleetMutation = { __typename?: 'mutations', updateStoreFleet: { __typename?: 'StoreUniverseInfoDto', id: number } };

export type UpdateStoreToStoreGroupMutationVariables = Exact<{
  storeToStoreGroups?: InputMaybe<StoreToStoreGroupUpdateInput>;
}>;


export type UpdateStoreToStoreGroupMutation = { __typename?: 'mutations', updateStoreToStoreGroup: boolean };

export type UpdateStorePerformanceMutationVariables = Exact<{
  storePerformance?: InputMaybe<StorePerformanceUpdateInput>;
}>;


export type UpdateStorePerformanceMutation = { __typename?: 'mutations', updateStorePerformance: { __typename?: 'StoreStrengthOverride', id: number } };

export type UpdateTenantSettingsMutationVariables = Exact<{
  tenantSettings?: InputMaybe<TenantSettingsUpdateInput>;
}>;


export type UpdateTenantSettingsMutation = { __typename?: 'mutations', updateTenantSettings: boolean };

export type CreateUserMutationVariables = Exact<{
  userInput?: InputMaybe<UserCreateInput>;
}>;


export type CreateUserMutation = { __typename?: 'mutations', createUser: { __typename?: 'User', id: number } };

export type UpdateUserMutationVariables = Exact<{
  userInput?: InputMaybe<UserUpdateInput>;
}>;


export type UpdateUserMutation = { __typename?: 'mutations', updateUser: { __typename?: 'User', id: number } };

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type DeleteUserMutation = { __typename?: 'mutations', deleteUser: boolean };

export type UpdateUserSettingsMutationVariables = Exact<{
  userSettings?: InputMaybe<Scalars['String']>;
}>;


export type UpdateUserSettingsMutation = { __typename?: 'mutations', updateUserSettings?: string | null };

export type GenerateExportMutationVariables = Exact<{ [key: string]: never; }>;


export type GenerateExportMutation = { __typename?: 'mutations', generateExport: boolean };

export type GetUniversesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUniversesQuery = { __typename?: 'queries', universes?: Array<{ __typename?: 'Universe', id: number, name?: string | null, code?: string | null }> | null };

export type GetSizeSystemsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSizeSystemsQuery = { __typename?: 'queries', sizeSystems?: Array<{ __typename?: 'SizeSystem', id: number, code?: string | null, name?: string | null, origin: OriginType }> | null };

export type GetFamiliesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFamiliesQuery = { __typename?: 'queries', families?: Array<{ __typename?: 'Family', id: number, name?: string | null, code?: string | null }> | null };

export type GetWarehousesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWarehousesQuery = { __typename?: 'queries', warehouses?: Array<{ __typename?: 'Warehouse', id: number, code?: string | null, name?: string | null, isRetail: boolean, supplierId?: number | null }> | null };

export type GetSizeSystemRowHeadersQueryVariables = Exact<{
  sizeSystemId: Scalars['Int'];
}>;


export type GetSizeSystemRowHeadersQuery = { __typename?: 'queries', sizeSystemRowHeaders?: Array<{ __typename?: 'SizeSystemRowHeader', id: number, name?: string | null, sortNumber: number, isOnShowcase: boolean }> | null };

export type GetSizeSystemRowQueryVariables = Exact<{
  sizeSystemId: Scalars['Int'];
}>;


export type GetSizeSystemRowQuery = { __typename?: 'queries', sizeSystemRows?: Array<{ __typename?: 'SizeSystemRow', id: number, name?: string | null, sortNumber: number, sizeSystemRowValues?: Array<{ __typename?: 'SizeSystemRowValue', id: number, sizeSystemRowHeaderId: number, sizeSystemRowId: number, distribution: number } | null> | null }> | null };

export type GetProductOptimalStockProductSummaryQueryVariables = Exact<{
  productId: Scalars['Int'];
}>;


export type GetProductOptimalStockProductSummaryQuery = { __typename?: 'queries', productOptimalStockProductSummary?: { __typename?: 'ProductOptimalStockSummaryDto', photoUrls?: string | null, productCode?: string | null, productName?: string | null, colorCode?: string | null, colorName?: string | null, price: any, collectionId: number, productModuleId?: number | null, sizeSystemId: number, sizeSystemRowName?: string | null, customFieldValue1?: string | null, customFieldValue2?: string | null, customFieldValue3?: string | null, customFieldValue4?: string | null, customFieldValue5?: string | null, permutationField1?: string | null, permutationField2?: string | null } | null };

export type GetCollectionsQueryVariables = Exact<{
  universeId: Scalars['Int'];
}>;


export type GetCollectionsQuery = { __typename?: 'queries', collections?: Array<{ __typename?: 'Collection', id: number, code?: string | null, name?: string | null, isActive: boolean, combinationType: CombinationType, productModules?: Array<{ __typename?: 'ProductModule', id: number, name?: string | null } | null> | null }> | null };

export type GetCollectionsNamesQueryVariables = Exact<{
  universeId: Scalars['Int'];
  isActive?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetCollectionsNamesQuery = { __typename?: 'queries', collections?: Array<{ __typename?: 'Collection', id: number, name?: string | null, combinationType: CombinationType }> | null };

export type GetCollectionQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetCollectionQuery = { __typename?: 'queries', collection?: { __typename?: 'Collection', id: number, code?: string | null, name?: string | null, isActive: boolean, combinationType: CombinationType, productModules?: Array<{ __typename?: 'ProductModule', name?: string | null, id: number, collectionId: number } | null> | null, storeGroups?: Array<{ __typename?: 'StoreGroup', id: number, name?: string | null, collectionId: number, storeGroupToProductModules?: Array<{ __typename?: 'StoreGroupToProductModule', id: number, productModuleId: number, storeGroupId: number, isSelected: boolean } | null> | null } | null> | null } | null };

export type GetFamilyToSizeSystemQueryVariables = Exact<{
  sizeSystemId: Scalars['Int'];
}>;


export type GetFamilyToSizeSystemQuery = { __typename?: 'queries', familyToSizeSystem?: Array<{ __typename?: 'Family', id: number, name?: string | null, code?: string | null }> | null };

export type GetStockSalesTableQueryVariables = Exact<{
  productId: Scalars['Int'];
}>;


export type GetStockSalesTableQuery = { __typename?: 'queries', stocksSalesTable: { __typename?: 'ProductOptimalSalesTableDto', headers?: Array<string | null> | null, discountColumn?: { __typename?: 'ProductOptimalStockSalesTableDiscountColumnDto', discountStockRatePerSeason: number, discountStockRatePerPhysical: number, discountStockRatePerWeb: number, discountStockRatePerFiveWeeks: number, discountStockRateFirstWeek: number, discountStockRateSecondWeek: number, discountStockRateThirdWeek: number, discountStockRateFourthWeek: number, discountStockRateFifthWeek: number } | null, columns?: Array<{ __typename?: 'ProductOptimalSalesTableColumnDto', sizeSystemRowHeaderId: number, sizeSystemRowHeaderName?: string | null, totalSales: number, webSales: number, physicalSales: number, allWeeksTotal: number, salesFirstWeek: number, salesSecondWeek: number, salesThirdWeek: number, salesFourthWeek: number, salesFifthWeek: number, stockAvailableAndInTransit: number, stockQuantityPicking: number, warehouseQuantity: number, remainingQuantity: number, distribution: number, sKU?: string | null, remainingQuantitiesByDateAndSupplier?: Array<{ __typename?: 'StatisticsSupplierOptimalStockDto', supplierCode?: string | null, quantityForDelivery: number, timestamp: any } | null> | null } | null> | null } };

export type ProductCustomFieldsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductCustomFieldsQuery = { __typename?: 'queries', productCustomFields: { __typename?: 'ProductCustomFieldsDto', customFieldName1?: string | null, customFieldValues1?: string | null, customFieldName2?: string | null, customFieldValues2?: string | null, customFieldName3?: string | null, customFieldValues3?: string | null, customFieldName4?: string | null, customFieldValues4?: string | null, customFieldName5?: string | null, customFieldValues5?: string | null } };

export type StoreCustomFieldsQueryVariables = Exact<{ [key: string]: never; }>;


export type StoreCustomFieldsQuery = { __typename?: 'queries', storeCustomFields: { __typename?: 'StoreCustomFieldsDto', customFieldName1?: string | null, customFieldValues1?: string | null, customFieldName2?: string | null, customFieldValues2?: string | null, customFieldName3?: string | null, customFieldValues3?: string | null, customFieldName4?: string | null, customFieldValues4?: string | null, customFieldName5?: string | null, customFieldValues5?: string | null } };

export type GetStoreStrengthTableQueryVariables = Exact<{
  productId: Scalars['Int'];
}>;


export type GetStoreStrengthTableQuery = { __typename?: 'queries', storeStrengthTable: { __typename?: 'ProductOptimalStockTableWrapperDto', metadata?: { __typename?: 'ProductOptimalStockMetadata', isOnShowcase: boolean, numberOfStores: number, weeklySalesFlow: any, theoreticalSalesFlow: any, proposedStrength: any, validatedProductStrength: any, numberOfStoresWithShowcase: number } | null, headers?: Array<{ __typename?: 'ProductOptimalStockHeaderDto', id: number, name?: string | null, label?: string | null, optimalStockOverrideId?: number | null } | null> | null, rows?: Array<{ __typename?: 'ProductOptimalStockTableRowDto', storeStrength: number, numberOfStores: number, optimalStock?: Array<number | null> | null, total: number } | null> | null, overrides?: Array<{ __typename?: 'ArticleOptimalStockOverride', id: number, stockMin: number, hasStockMin: boolean, stockMax: number, hasStockMax: boolean, hasCondition: boolean, stockMinStoreStrengthCondition: number, stockMinStoreStrengthConditionValue: number, stockMaxStoreStrengthCondition: number, stockMaxStoreStrengthConditionValue: number } | null> | null, summary?: Array<{ __typename?: 'OptimalStockDataDto', optimalStock: number, estimatedPicking: number, actualStockDemand: number } | null> | null } };

export type GetProductPerformanceQueryVariables = Exact<{
  universeId: Scalars['Int'];
  seasonId: Scalars['Int'];
}>;


export type GetProductPerformanceQuery = { __typename?: 'queries', productPerformance?: Array<{ __typename?: 'ProductFleetPerformanceDto', id: number, salesPrice: any, physicalStoreSales: number, colorName?: string | null, isOnShowcase: boolean, productCode?: string | null, productName?: string | null, familyName?: string | null, collectionName?: string | null, productModuleName?: string | null, isSynced: boolean, weekSales: number, stockQuantityTotal: number, quantityForDelivery: number, warehouseStockWholesale: number, warehouseStockRetails: number, stockWarehouse: number, quantityPicking: number, quantityInTransit: number, stockQuantityInWebStore: number, stockQuantityInPhysicalStore: number, numberOfStoresInWhichProductIsPresent: number, numberOfWeeksTheProductIsSold: number, totalQuantitySold: number, webStoreSales: number, webPercent: any, stockFlowRate: any, colorCode?: string | null, weekDiscountStockRate: number, pastWeekSales: number, pastTwoWeekSales: number, pastThreeWeekSales: number, sizeSystemName?: string | null, sizeSystemRowName?: string | null, proposedStrength: any, validatedStrength: any, optimalStock: number, implantedStrength: number, hasStockMinOverride: boolean, hasStockMaxOverride: boolean, hasCondition: boolean, hasException: boolean, warehouseCoverage: number, potentialWeeklySalesFlow: any, estimationPicking: number, customFieldValue1?: string | null, customFieldValue2?: string | null, customFieldValue3?: string | null, customFieldValue4?: string | null, customFieldValue5?: string | null, stockTotal: number, permutationField1?: string | null, permutationField2?: string | null, excludedStoreExceptionsCount: number, includedStoreExceptionsCount: number, photoUrls?: string | null, averagePastThreeWeeksSalesAllStores: number, potentialSalesPastWeekAllStores: any, isArchived: boolean }> | null };

export type GetProductPerformanceIdsQueryVariables = Exact<{
  universeId: Scalars['Int'];
  seasonId: Scalars['Int'];
}>;


export type GetProductPerformanceIdsQuery = { __typename?: 'queries', productPerformance?: Array<{ __typename?: 'ProductFleetPerformanceDto', id: number }> | null };

export type ProductDetailsQueryVariables = Exact<{
  productId: Scalars['Int'];
}>;


export type ProductDetailsQuery = { __typename?: 'queries', productDetails: { __typename?: 'ProductDetailsDto', id: number, universeId?: number | null, customFieldValue1?: string | null, customFieldValue2?: string | null, customFieldValue3?: string | null, customFieldValue4?: string | null, customFieldValue5?: string | null, seasonId: number, collectionId: number, sizeSystemId: number, sizeSystemRowId?: number | null, productModuleId?: number | null, validatedStrength: any, isOnShowcase: boolean, isSynced: boolean, price: any } };

export type ProductSeasonArchivedDetailsQueryVariables = Exact<{
  productId: Scalars['Int'];
}>;


export type ProductSeasonArchivedDetailsQuery = { __typename?: 'queries', productDetails: { __typename?: 'ProductDetailsDto', isArchived: boolean, seasonId: number } };

export type GetStoreFleetQueryVariables = Exact<{
  universeId: Scalars['Int'];
  seasonId: Scalars['Int'];
}>;


export type GetStoreFleetQuery = { __typename?: 'queries', storeFleet?: Array<{ __typename?: 'StoreFleetDto', id: number, storeType?: string | null, storeCode?: string | null, storeRegion?: string | null, storeName?: string | null, storeStrength: any, targetTurnover: any, storeStrengthImplant: any, storeCustomField1?: string | null, storeCustomField2?: string | null, storeCustomField3?: string | null, storeCustomField4?: string | null, storeCustomField5?: string | null, isSynced: boolean, ignoreMinMax: boolean, pickingPriority: number, capacity: number, handlesShowcase: boolean, isActive: boolean, storeToStoreGroups?: Array<{ __typename?: 'StoreFleetCollectionDto', collectionId: number, storeGroupId: number, storeGroupName?: string | null } | null> | null }> | null };

export type GetCollectionStoreGroupsQueryVariables = Exact<{
  collectionId: Scalars['Int'];
}>;


export type GetCollectionStoreGroupsQuery = { __typename?: 'queries', storeGroups?: Array<{ __typename?: 'StoreGroup', id: number, name?: string | null }> | null };

export type StoreDetailsQueryVariables = Exact<{
  storeId: Scalars['Int'];
  universeId: Scalars['Int'];
  seasonId: Scalars['Int'];
}>;


export type StoreDetailsQuery = { __typename?: 'queries', storeDetails?: { __typename?: 'StoreDetailsDto', id: number, code?: string | null, handlesShowcase: boolean, name?: string | null, photoUrls?: string | null, capacity: number, capacityType?: CapacityType | null, pickingPriority: number, isActive: boolean, isSynced: boolean, ignoreMinMax: boolean, regionId?: number | null, regionName?: string | null, storeTypeId?: number | null, originType?: OriginType | null, customFieldValue1?: string | null, customFieldValue2?: string | null, customFieldValue3?: string | null, customFieldValue4?: string | null, customFieldValue5?: string | null } | null };

export type GetStoreTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStoreTypesQuery = { __typename?: 'queries', storeTypes?: Array<{ __typename?: 'StoreType', id: number, code?: string | null, name?: string | null, isMain: boolean, isWeb: boolean }> | null };

export type GetStoreFleetSummaryQueryVariables = Exact<{
  universeId: Scalars['Int'];
  seasonId: Scalars['Int'];
}>;


export type GetStoreFleetSummaryQuery = { __typename?: 'queries', storeFleetSummary: { __typename?: 'StoreFleetSummaryTableDto', storePerformanceTable?: Array<{ __typename?: 'StoreFleetSummaryStorePerformanceDto', storePerformance: any, storePerformanceStoreCount: number, percentageOfTotal: any } | null> | null } };

export type GetTargetTurnoverTableQueryVariables = Exact<{
  universeId: Scalars['Int'];
  seasonId: Scalars['Int'];
}>;


export type GetTargetTurnoverTableQuery = { __typename?: 'queries', storeFleetSummary: { __typename?: 'StoreFleetSummaryTableDto', targetTurnoverTable?: Array<{ __typename?: 'StoreFleetSummaryTargetTurnoverDto', collectionName?: string | null, storeGroups?: Array<{ __typename?: 'StoreFleetSummaryStoreGroupsDto', groupName?: string | null, numberOfStores: number, storesTargetTurnover: any } | null> | null } | null> | null } };

export type GetExceptionOptimalStockTableQueryVariables = Exact<{
  productId: Scalars['Int'];
  salesEnum: ExceptionOptimalStockSalesEnum;
  stockEnum: ExceptionOptimalStockStockEnum;
  hasException: Scalars['Boolean'];
}>;


export type GetExceptionOptimalStockTableQuery = { __typename?: 'queries', exceptionOptimalStockTable: { __typename?: 'ExceptionOptimalStockTableDto', numberOfWeeksInSale: number, sizeSystemHeaders?: Array<{ __typename?: 'ExceptionOptimalStockHeaderDto', articleId?: number | null, headerName?: string | null, headerId: number } | null> | null, exceptionOptimalStock?: Array<{ __typename?: 'ExceptionOptimalStockDto', storeId: number, storeCode?: string | null, storeName?: string | null, storeGroupName?: string | null, storeStrength: number, storeCustomField1?: string | null, storeCustomField2?: string | null, storeCustomField3?: string | null, storeCustomField4?: string | null, storeCustomField5?: string | null, isIncluded: boolean, hasExceptions: boolean, leftTable?: Array<{ __typename?: 'HeaderValuePair', headerId: number, value: number } | null> | null, rightTable?: Array<{ __typename?: 'HeaderValuePair', headerId: number, value: number, isOverriden: boolean } | null> | null } | null> | null } };

export type GetStoreOptimalStockExceptionsQueryVariables = Exact<{
  productId: Scalars['Int'];
  isIncluded?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetStoreOptimalStockExceptionsQuery = { __typename?: 'queries', storeOptimalStockExceptions?: Array<{ __typename?: 'StoreOptimalStockExceptionDto', storeId: number, storeName?: string | null, storeCode?: string | null, storeStrength: any, storeGroupId: number, storeGroupName?: string | null }> | null };

export type GetStorePerformanceQueryVariables = Exact<{
  universeId: Scalars['Int'];
  seasonId: Scalars['Int'];
}>;


export type GetStorePerformanceQuery = { __typename?: 'queries', storePerformance?: Array<{ __typename?: 'StorePerformanceDto', id: number, code?: string | null, name?: string | null, permutationFieldId1?: string | null, permutationFieldId2?: string | null, permutationLabel1?: string | null, permutationLabel2?: string | null, customField1?: string | null, customField2?: string | null, customField3?: string | null, customField4?: string | null, customField5?: string | null, numberOfProductsWithStock: number, numberOfProductsWithOptimalStock: number, numberOfProductsWithSalesLastWeek: number, salesQuantityLastWeek: number, stockQuantityLastWeek: number, coverageOneWeekBefore: number, coverageTwoWeeksBefore: number, coverageThreeWeeksBefore: number, averageCoverage: number, capacity: number, capacityRatio: any, storeStrengthPerPermutationHistory: number, storeStrengthPerPermutationImplemented: any, storeStrengthPerPermutationProposed: number, storeStrengthPerPermutationValidated: number, optimalStock: number, optimalStockOneWeekBefore: number, estimatedPicking: number, storeToStoreGroups?: Array<{ __typename?: 'StoreFleetCollectionDto', collectionId: number, storeGroupId: number, storeGroupName?: string | null } | null> | null }> | null };

export type RetailWarehouseStockQueryVariables = Exact<{
  productId: Scalars['Int'];
}>;


export type RetailWarehouseStockQuery = { __typename?: 'queries', retailWarehouseStock?: Array<{ __typename?: 'ExceptionOptimalStockRetailStockDto', articleId?: number | null, headerId: number, headerName?: string | null, warehouseStockQuantity?: number | null }> | null };

export type StorePerformanceStoreSummaryQueryVariables = Exact<{
  universeId: Scalars['Int'];
  seasonId: Scalars['Int'];
}>;


export type StorePerformanceStoreSummaryQuery = { __typename?: 'queries', storePerformanceStoreSummary?: Array<{ __typename?: 'StorePerformanceStoreSummaryDto', id: number, salesQuantityLastWeek: number, stockQuantityLastWeek: number, coverageOneWeekBefore: number, coverageTwoWeeksBefore: number, coverageThreeWeeksBefore: number, averageCoverage: number, capacity: number, capacityRatio: any, optimalStock: number, optimalStockOneWeekBefore: number, code?: string | null, estimatedPicking: number, name?: string | null, customField1?: string | null, customField2?: string | null, customField3?: string | null, customField4?: string | null, customField5?: string | null, numberOfProductsWithStock: number, numberOfProductsWithOptimalStock: number, numberOfProductsWithSalesLastWeek: number, storeToStoreGroups?: Array<{ __typename?: 'StoreFleetCollectionDto', collectionId: number, storeGroupId: number, storeGroupName?: string | null } | null> | null }> | null };

export type GetSeasonsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSeasonsQuery = { __typename?: 'queries', seasons?: Array<{ __typename?: 'Season', id: number, code?: string | null, name?: string | null, isActive: boolean }> | null };

export type GetTenantSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTenantSettingsQuery = { __typename?: 'queries', tenantSettings: { __typename?: 'TenantSettings', id: number, productCoefficient: any, productCoverage: number, storeCoefficient: any, productMaxStrength: number, storeAverageStrength: number, storeCapacityType: CapacityType, storeMaxStrength: number, storeNumberOfWeeksStrength: number, isErpMasterOfCreationAndModification: boolean } };

export type GetIsErpMasterQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIsErpMasterQuery = { __typename?: 'queries', tenantSettings: { __typename?: 'TenantSettings', isErpMasterOfCreationAndModification: boolean } };

export type GetCapacityTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCapacityTypeQuery = { __typename?: 'queries', tenantSettings: { __typename?: 'TenantSettings', storeCapacityType: CapacityType } };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'queries', users?: Array<{ __typename?: 'User', id: number, email?: string | null, username?: string | null, userRoleId: UserRoleType, jobDescription?: string | null }> | null };

export type UserRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type UserRolesQuery = { __typename?: 'queries', userRoles?: Array<{ __typename?: 'UserRole', id: number, name?: string | null }> | null };

export type GetOptimalStockSimulationQueryVariables = Exact<{
  universeId: Scalars['Int'];
  seasonId?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  customField1?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  customField2?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  customField3?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  customField4?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  customField5?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  sortType?: InputMaybe<OptimalStockSimulationField>;
  filters?: InputMaybe<Array<OptimalStockSimulationFilterDtoInput> | OptimalStockSimulationFilterDtoInput>;
  isAsc?: InputMaybe<Scalars['Boolean']>;
  permutations?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type GetOptimalStockSimulationQuery = { __typename?: 'queries', optimalStockSimulation?: { __typename?: 'PaginationInfoOfOptimalStockSimulationDto', totalCount: number, items?: Array<{ __typename?: 'OptimalStockSimulationDto', id: number, storeCode?: string | null, storeName?: string | null, productCode?: string | null, productName?: string | null, colorCode?: string | null, colorName?: string | null, permutationField1?: string | null, permutationField2?: string | null, sizeHeader?: string | null, barcode?: string | null, familyName?: string | null, storeCustomField1?: string | null, storeCustomField2?: string | null, storeCustomField3?: string | null, storeCustomField4?: string | null, storeCustomField5?: string | null, productCustomField1?: string | null, productCustomField2?: string | null, productCustomField3?: string | null, productCustomField4?: string | null, productCustomField5?: string | null, storeIsSynced: boolean, productIsSynced: boolean, optimalStock: number, estimationPicking: number } | null> | null, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean } | null } | null };

export type GetStorePerformancePermutationSummaryQueryVariables = Exact<{
  universeId: Scalars['Int'];
  seasonId: Scalars['Int'];
}>;


export type GetStorePerformancePermutationSummaryQuery = { __typename?: 'queries', storePerformancePermutationSummary?: Array<{ __typename?: 'StorePerformancePermutationSummaryDto', permutationField1?: string | null, permutationFieldLabel1?: string | null, optimalStock: number, optimalStockOneWeekBefore: number, estimatedPicking: number, permutationField2?: string | null, permutationFieldLabel2?: string | null, numberOfProductsWithStock: number, numberOfProductsWithOptimalStock: number, numberOfProductsWithSalesLastWeek: number, salesQuantityLastWeek: number, stockQuantityLastWeek: number, coverageOneWeekBefore: number, coverageTwoWeeksBefore: number, coverageThreeWeeksBefore: number, averageCoverage: number }> | null };

export type GetUserSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserSettingsQuery = { __typename?: 'queries', userSettings: { __typename?: 'UserInfoDto', userSettings?: string | null, permissions?: Array<PermissionsEnum> | null } };

export type GetPossiblePermutationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPossiblePermutationsQuery = { __typename?: 'queries', possiblePermutations?: Array<string> | null };

export type GetAuditableInfoQueryVariables = Exact<{
  objectType?: InputMaybe<AuditableObjectType>;
  dateRangeStart?: InputMaybe<Scalars['DateTime']>;
  dateRangeEnd?: InputMaybe<Scalars['DateTime']>;
  seasonCode?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
}>;


export type GetAuditableInfoQuery = { __typename?: 'queries', auditableInfos?: { __typename?: 'AuditableInfoDtoCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'AuditableInfoDto', timestamp: any, user?: string | null, code?: string | null, action?: string | null, object?: string | null, property?: string | null, level?: string | null, oldValue?: string | null, newValue?: string | null } | null> | null, pageInfo: { __typename?: 'CollectionSegmentInfo', hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type GetFileImportLogsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFileImportLogsQuery = { __typename?: 'queries', fileImportLogs?: Array<{ __typename?: 'FileImportLog', id: number, importDate: any, object?: string | null, errorsCount: number, isSuccessful: boolean }> | null };

export type GetDashboardInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDashboardInfoQuery = { __typename?: 'queries', dashboard: { __typename?: 'DashboardDto', exportId: number, exportDate: any, newsletterTitle?: string | null, newsletterSubtitle?: string | null, newsletterBody?: string | null, newsletterImgUrl?: string | null, importLogs?: Array<{ __typename?: 'FileImportLog', id: number, importDate: any, object?: string | null, errorsCount: number, isSuccessful: boolean, errors?: string | null } | null> | null } };

export type GetFileImportDetailsQueryVariables = Exact<{
  fileImportLogId: Scalars['Int'];
}>;


export type GetFileImportDetailsQuery = { __typename?: 'queries', fileImportLog: { __typename?: 'FileImportLog', errors?: string | null } };


export const DeleteWarehousesDocument = gql`
    mutation DeleteWarehouses($id: Int!) {
  deleteWarehouse(id: $id)
}
    `;
export type DeleteWarehousesMutationFn = Apollo.MutationFunction<DeleteWarehousesMutation, DeleteWarehousesMutationVariables>;

/**
 * __useDeleteWarehousesMutation__
 *
 * To run a mutation, you first call `useDeleteWarehousesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWarehousesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWarehousesMutation, { data, loading, error }] = useDeleteWarehousesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWarehousesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWarehousesMutation, DeleteWarehousesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWarehousesMutation, DeleteWarehousesMutationVariables>(DeleteWarehousesDocument, options);
      }
export type DeleteWarehousesMutationHookResult = ReturnType<typeof useDeleteWarehousesMutation>;
export type DeleteWarehousesMutationResult = Apollo.MutationResult<DeleteWarehousesMutation>;
export type DeleteWarehousesMutationOptions = Apollo.BaseMutationOptions<DeleteWarehousesMutation, DeleteWarehousesMutationVariables>;
export const BulkUpdateWarehousesDocument = gql`
    mutation BulkUpdateWarehouses($inputWarehouses: [WarehouseUpdateInput]) {
  updateBulkWarehouses(inputWarehouses: $inputWarehouses) {
    id
    isRetail
    code
    name
  }
}
    `;
export type BulkUpdateWarehousesMutationFn = Apollo.MutationFunction<BulkUpdateWarehousesMutation, BulkUpdateWarehousesMutationVariables>;

/**
 * __useBulkUpdateWarehousesMutation__
 *
 * To run a mutation, you first call `useBulkUpdateWarehousesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateWarehousesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateWarehousesMutation, { data, loading, error }] = useBulkUpdateWarehousesMutation({
 *   variables: {
 *      inputWarehouses: // value for 'inputWarehouses'
 *   },
 * });
 */
export function useBulkUpdateWarehousesMutation(baseOptions?: Apollo.MutationHookOptions<BulkUpdateWarehousesMutation, BulkUpdateWarehousesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUpdateWarehousesMutation, BulkUpdateWarehousesMutationVariables>(BulkUpdateWarehousesDocument, options);
      }
export type BulkUpdateWarehousesMutationHookResult = ReturnType<typeof useBulkUpdateWarehousesMutation>;
export type BulkUpdateWarehousesMutationResult = Apollo.MutationResult<BulkUpdateWarehousesMutation>;
export type BulkUpdateWarehousesMutationOptions = Apollo.BaseMutationOptions<BulkUpdateWarehousesMutation, BulkUpdateWarehousesMutationVariables>;
export const CreateWarehouseDocument = gql`
    mutation CreateWarehouse($warehouse: WarehouseCreateInput) {
  createWarehouse(warehouse: $warehouse) {
    id
    isRetail
    code
    name
  }
}
    `;
export type CreateWarehouseMutationFn = Apollo.MutationFunction<CreateWarehouseMutation, CreateWarehouseMutationVariables>;

/**
 * __useCreateWarehouseMutation__
 *
 * To run a mutation, you first call `useCreateWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWarehouseMutation, { data, loading, error }] = useCreateWarehouseMutation({
 *   variables: {
 *      warehouse: // value for 'warehouse'
 *   },
 * });
 */
export function useCreateWarehouseMutation(baseOptions?: Apollo.MutationHookOptions<CreateWarehouseMutation, CreateWarehouseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWarehouseMutation, CreateWarehouseMutationVariables>(CreateWarehouseDocument, options);
      }
export type CreateWarehouseMutationHookResult = ReturnType<typeof useCreateWarehouseMutation>;
export type CreateWarehouseMutationResult = Apollo.MutationResult<CreateWarehouseMutation>;
export type CreateWarehouseMutationOptions = Apollo.BaseMutationOptions<CreateWarehouseMutation, CreateWarehouseMutationVariables>;
export const CreateCollectionDocument = gql`
    mutation CreateCollection($collection: CollectionCreateInput) {
  createCollection(collection: $collection) {
    name
    code
    combinationType
    universeId
  }
}
    `;
export type CreateCollectionMutationFn = Apollo.MutationFunction<CreateCollectionMutation, CreateCollectionMutationVariables>;

/**
 * __useCreateCollectionMutation__
 *
 * To run a mutation, you first call `useCreateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCollectionMutation, { data, loading, error }] = useCreateCollectionMutation({
 *   variables: {
 *      collection: // value for 'collection'
 *   },
 * });
 */
export function useCreateCollectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateCollectionMutation, CreateCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCollectionMutation, CreateCollectionMutationVariables>(CreateCollectionDocument, options);
      }
export type CreateCollectionMutationHookResult = ReturnType<typeof useCreateCollectionMutation>;
export type CreateCollectionMutationResult = Apollo.MutationResult<CreateCollectionMutation>;
export type CreateCollectionMutationOptions = Apollo.BaseMutationOptions<CreateCollectionMutation, CreateCollectionMutationVariables>;
export const BulkUpdateCollectionsDocument = gql`
    mutation BulkUpdateCollections($inputCollections: [CollectionUpdateInput]) {
  updateBulkCollections(inputCollections: $inputCollections) {
    id
    code
    name
    isActive
  }
}
    `;
export type BulkUpdateCollectionsMutationFn = Apollo.MutationFunction<BulkUpdateCollectionsMutation, BulkUpdateCollectionsMutationVariables>;

/**
 * __useBulkUpdateCollectionsMutation__
 *
 * To run a mutation, you first call `useBulkUpdateCollectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateCollectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateCollectionsMutation, { data, loading, error }] = useBulkUpdateCollectionsMutation({
 *   variables: {
 *      inputCollections: // value for 'inputCollections'
 *   },
 * });
 */
export function useBulkUpdateCollectionsMutation(baseOptions?: Apollo.MutationHookOptions<BulkUpdateCollectionsMutation, BulkUpdateCollectionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUpdateCollectionsMutation, BulkUpdateCollectionsMutationVariables>(BulkUpdateCollectionsDocument, options);
      }
export type BulkUpdateCollectionsMutationHookResult = ReturnType<typeof useBulkUpdateCollectionsMutation>;
export type BulkUpdateCollectionsMutationResult = Apollo.MutationResult<BulkUpdateCollectionsMutation>;
export type BulkUpdateCollectionsMutationOptions = Apollo.BaseMutationOptions<BulkUpdateCollectionsMutation, BulkUpdateCollectionsMutationVariables>;
export const UpdateCollectionDocument = gql`
    mutation UpdateCollection($inputCollection: CollectionUpdateInput) {
  updateCollection(collection: $inputCollection) {
    id
    code
    name
    isActive
  }
}
    `;
export type UpdateCollectionMutationFn = Apollo.MutationFunction<UpdateCollectionMutation, UpdateCollectionMutationVariables>;

/**
 * __useUpdateCollectionMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionMutation, { data, loading, error }] = useUpdateCollectionMutation({
 *   variables: {
 *      inputCollection: // value for 'inputCollection'
 *   },
 * });
 */
export function useUpdateCollectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCollectionMutation, UpdateCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCollectionMutation, UpdateCollectionMutationVariables>(UpdateCollectionDocument, options);
      }
export type UpdateCollectionMutationHookResult = ReturnType<typeof useUpdateCollectionMutation>;
export type UpdateCollectionMutationResult = Apollo.MutationResult<UpdateCollectionMutation>;
export type UpdateCollectionMutationOptions = Apollo.BaseMutationOptions<UpdateCollectionMutation, UpdateCollectionMutationVariables>;
export const UpdateStoreOptimalStockOverrideDocument = gql`
    mutation UpdateStoreOptimalStockOverride($exceptionOptimalStock: ExceptionOptimalStockUpdateInput) {
  updateStoreOptimalStockOverride(exceptionOptimalStock: $exceptionOptimalStock) {
    storeId
  }
}
    `;
export type UpdateStoreOptimalStockOverrideMutationFn = Apollo.MutationFunction<UpdateStoreOptimalStockOverrideMutation, UpdateStoreOptimalStockOverrideMutationVariables>;

/**
 * __useUpdateStoreOptimalStockOverrideMutation__
 *
 * To run a mutation, you first call `useUpdateStoreOptimalStockOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoreOptimalStockOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoreOptimalStockOverrideMutation, { data, loading, error }] = useUpdateStoreOptimalStockOverrideMutation({
 *   variables: {
 *      exceptionOptimalStock: // value for 'exceptionOptimalStock'
 *   },
 * });
 */
export function useUpdateStoreOptimalStockOverrideMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoreOptimalStockOverrideMutation, UpdateStoreOptimalStockOverrideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoreOptimalStockOverrideMutation, UpdateStoreOptimalStockOverrideMutationVariables>(UpdateStoreOptimalStockOverrideDocument, options);
      }
export type UpdateStoreOptimalStockOverrideMutationHookResult = ReturnType<typeof useUpdateStoreOptimalStockOverrideMutation>;
export type UpdateStoreOptimalStockOverrideMutationResult = Apollo.MutationResult<UpdateStoreOptimalStockOverrideMutation>;
export type UpdateStoreOptimalStockOverrideMutationOptions = Apollo.BaseMutationOptions<UpdateStoreOptimalStockOverrideMutation, UpdateStoreOptimalStockOverrideMutationVariables>;
export const UpdateBulkStoreOptimalStockOverrideDocument = gql`
    mutation UpdateBulkStoreOptimalStockOverride($exceptionOptimalStocks: [ExceptionOptimalStockUpdateInput]) {
  updateBulkStoreOptimalStockOverride(
    exceptionOptimalStocks: $exceptionOptimalStocks
  ) {
    storeId
  }
}
    `;
export type UpdateBulkStoreOptimalStockOverrideMutationFn = Apollo.MutationFunction<UpdateBulkStoreOptimalStockOverrideMutation, UpdateBulkStoreOptimalStockOverrideMutationVariables>;

/**
 * __useUpdateBulkStoreOptimalStockOverrideMutation__
 *
 * To run a mutation, you first call `useUpdateBulkStoreOptimalStockOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBulkStoreOptimalStockOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBulkStoreOptimalStockOverrideMutation, { data, loading, error }] = useUpdateBulkStoreOptimalStockOverrideMutation({
 *   variables: {
 *      exceptionOptimalStocks: // value for 'exceptionOptimalStocks'
 *   },
 * });
 */
export function useUpdateBulkStoreOptimalStockOverrideMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBulkStoreOptimalStockOverrideMutation, UpdateBulkStoreOptimalStockOverrideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBulkStoreOptimalStockOverrideMutation, UpdateBulkStoreOptimalStockOverrideMutationVariables>(UpdateBulkStoreOptimalStockOverrideDocument, options);
      }
export type UpdateBulkStoreOptimalStockOverrideMutationHookResult = ReturnType<typeof useUpdateBulkStoreOptimalStockOverrideMutation>;
export type UpdateBulkStoreOptimalStockOverrideMutationResult = Apollo.MutationResult<UpdateBulkStoreOptimalStockOverrideMutation>;
export type UpdateBulkStoreOptimalStockOverrideMutationOptions = Apollo.BaseMutationOptions<UpdateBulkStoreOptimalStockOverrideMutation, UpdateBulkStoreOptimalStockOverrideMutationVariables>;
export const UpdateStoreOptimalStockExceptionDocument = gql`
    mutation UpdateStoreOptimalStockException($input: StoreOptimalStockExceptionInput) {
  updateStoreOptimalStockException(input: $input)
}
    `;
export type UpdateStoreOptimalStockExceptionMutationFn = Apollo.MutationFunction<UpdateStoreOptimalStockExceptionMutation, UpdateStoreOptimalStockExceptionMutationVariables>;

/**
 * __useUpdateStoreOptimalStockExceptionMutation__
 *
 * To run a mutation, you first call `useUpdateStoreOptimalStockExceptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoreOptimalStockExceptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoreOptimalStockExceptionMutation, { data, loading, error }] = useUpdateStoreOptimalStockExceptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStoreOptimalStockExceptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoreOptimalStockExceptionMutation, UpdateStoreOptimalStockExceptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoreOptimalStockExceptionMutation, UpdateStoreOptimalStockExceptionMutationVariables>(UpdateStoreOptimalStockExceptionDocument, options);
      }
export type UpdateStoreOptimalStockExceptionMutationHookResult = ReturnType<typeof useUpdateStoreOptimalStockExceptionMutation>;
export type UpdateStoreOptimalStockExceptionMutationResult = Apollo.MutationResult<UpdateStoreOptimalStockExceptionMutation>;
export type UpdateStoreOptimalStockExceptionMutationOptions = Apollo.BaseMutationOptions<UpdateStoreOptimalStockExceptionMutation, UpdateStoreOptimalStockExceptionMutationVariables>;
export const RemoveStoreOptimalStockExceptionDocument = gql`
    mutation RemoveStoreOptimalStockException($productId: Int!, $storeId: Int!) {
  removeStoreOptimalStockException(productId: $productId, storeId: $storeId)
}
    `;
export type RemoveStoreOptimalStockExceptionMutationFn = Apollo.MutationFunction<RemoveStoreOptimalStockExceptionMutation, RemoveStoreOptimalStockExceptionMutationVariables>;

/**
 * __useRemoveStoreOptimalStockExceptionMutation__
 *
 * To run a mutation, you first call `useRemoveStoreOptimalStockExceptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStoreOptimalStockExceptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStoreOptimalStockExceptionMutation, { data, loading, error }] = useRemoveStoreOptimalStockExceptionMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useRemoveStoreOptimalStockExceptionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveStoreOptimalStockExceptionMutation, RemoveStoreOptimalStockExceptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveStoreOptimalStockExceptionMutation, RemoveStoreOptimalStockExceptionMutationVariables>(RemoveStoreOptimalStockExceptionDocument, options);
      }
export type RemoveStoreOptimalStockExceptionMutationHookResult = ReturnType<typeof useRemoveStoreOptimalStockExceptionMutation>;
export type RemoveStoreOptimalStockExceptionMutationResult = Apollo.MutationResult<RemoveStoreOptimalStockExceptionMutation>;
export type RemoveStoreOptimalStockExceptionMutationOptions = Apollo.BaseMutationOptions<RemoveStoreOptimalStockExceptionMutation, RemoveStoreOptimalStockExceptionMutationVariables>;
export const DeleteStoreOptimalStockOverrideDocument = gql`
    mutation DeleteStoreOptimalStockOverride($storeId: Int!, $productId: Int!) {
  deleteStoreOptimalStockOverride(storeId: $storeId, productId: $productId)
}
    `;
export type DeleteStoreOptimalStockOverrideMutationFn = Apollo.MutationFunction<DeleteStoreOptimalStockOverrideMutation, DeleteStoreOptimalStockOverrideMutationVariables>;

/**
 * __useDeleteStoreOptimalStockOverrideMutation__
 *
 * To run a mutation, you first call `useDeleteStoreOptimalStockOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStoreOptimalStockOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStoreOptimalStockOverrideMutation, { data, loading, error }] = useDeleteStoreOptimalStockOverrideMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useDeleteStoreOptimalStockOverrideMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStoreOptimalStockOverrideMutation, DeleteStoreOptimalStockOverrideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStoreOptimalStockOverrideMutation, DeleteStoreOptimalStockOverrideMutationVariables>(DeleteStoreOptimalStockOverrideDocument, options);
      }
export type DeleteStoreOptimalStockOverrideMutationHookResult = ReturnType<typeof useDeleteStoreOptimalStockOverrideMutation>;
export type DeleteStoreOptimalStockOverrideMutationResult = Apollo.MutationResult<DeleteStoreOptimalStockOverrideMutation>;
export type DeleteStoreOptimalStockOverrideMutationOptions = Apollo.BaseMutationOptions<DeleteStoreOptimalStockOverrideMutation, DeleteStoreOptimalStockOverrideMutationVariables>;
export const DeleteBulkStoreOptimalStockOverrideDocument = gql`
    mutation DeleteBulkStoreOptimalStockOverride($productId: Int!) {
  deleteBulkStoreOptimalStockOverride(productId: $productId)
}
    `;
export type DeleteBulkStoreOptimalStockOverrideMutationFn = Apollo.MutationFunction<DeleteBulkStoreOptimalStockOverrideMutation, DeleteBulkStoreOptimalStockOverrideMutationVariables>;

/**
 * __useDeleteBulkStoreOptimalStockOverrideMutation__
 *
 * To run a mutation, you first call `useDeleteBulkStoreOptimalStockOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBulkStoreOptimalStockOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBulkStoreOptimalStockOverrideMutation, { data, loading, error }] = useDeleteBulkStoreOptimalStockOverrideMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useDeleteBulkStoreOptimalStockOverrideMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBulkStoreOptimalStockOverrideMutation, DeleteBulkStoreOptimalStockOverrideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBulkStoreOptimalStockOverrideMutation, DeleteBulkStoreOptimalStockOverrideMutationVariables>(DeleteBulkStoreOptimalStockOverrideDocument, options);
      }
export type DeleteBulkStoreOptimalStockOverrideMutationHookResult = ReturnType<typeof useDeleteBulkStoreOptimalStockOverrideMutation>;
export type DeleteBulkStoreOptimalStockOverrideMutationResult = Apollo.MutationResult<DeleteBulkStoreOptimalStockOverrideMutation>;
export type DeleteBulkStoreOptimalStockOverrideMutationOptions = Apollo.BaseMutationOptions<DeleteBulkStoreOptimalStockOverrideMutation, DeleteBulkStoreOptimalStockOverrideMutationVariables>;
export const DeleteSelectedStoreOptimalStockOverrideDocument = gql`
    mutation DeleteSelectedStoreOptimalStockOverride($productId: Int!, $storeIds: [Int!]) {
  deleteSelectedStoreOptimalStockOverride(
    productId: $productId
    storeIds: $storeIds
  )
}
    `;
export type DeleteSelectedStoreOptimalStockOverrideMutationFn = Apollo.MutationFunction<DeleteSelectedStoreOptimalStockOverrideMutation, DeleteSelectedStoreOptimalStockOverrideMutationVariables>;

/**
 * __useDeleteSelectedStoreOptimalStockOverrideMutation__
 *
 * To run a mutation, you first call `useDeleteSelectedStoreOptimalStockOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSelectedStoreOptimalStockOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSelectedStoreOptimalStockOverrideMutation, { data, loading, error }] = useDeleteSelectedStoreOptimalStockOverrideMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      storeIds: // value for 'storeIds'
 *   },
 * });
 */
export function useDeleteSelectedStoreOptimalStockOverrideMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSelectedStoreOptimalStockOverrideMutation, DeleteSelectedStoreOptimalStockOverrideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSelectedStoreOptimalStockOverrideMutation, DeleteSelectedStoreOptimalStockOverrideMutationVariables>(DeleteSelectedStoreOptimalStockOverrideDocument, options);
      }
export type DeleteSelectedStoreOptimalStockOverrideMutationHookResult = ReturnType<typeof useDeleteSelectedStoreOptimalStockOverrideMutation>;
export type DeleteSelectedStoreOptimalStockOverrideMutationResult = Apollo.MutationResult<DeleteSelectedStoreOptimalStockOverrideMutation>;
export type DeleteSelectedStoreOptimalStockOverrideMutationOptions = Apollo.BaseMutationOptions<DeleteSelectedStoreOptimalStockOverrideMutation, DeleteSelectedStoreOptimalStockOverrideMutationVariables>;
export const DeselectSizeSystemIsOnShowcaseDocument = gql`
    mutation DeselectSizeSystemIsOnShowcase($sizeSystemId: Int!) {
  deselectSizeSystemIsOnShowcase(sizeSystemId: $sizeSystemId)
}
    `;
export type DeselectSizeSystemIsOnShowcaseMutationFn = Apollo.MutationFunction<DeselectSizeSystemIsOnShowcaseMutation, DeselectSizeSystemIsOnShowcaseMutationVariables>;

/**
 * __useDeselectSizeSystemIsOnShowcaseMutation__
 *
 * To run a mutation, you first call `useDeselectSizeSystemIsOnShowcaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeselectSizeSystemIsOnShowcaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deselectSizeSystemIsOnShowcaseMutation, { data, loading, error }] = useDeselectSizeSystemIsOnShowcaseMutation({
 *   variables: {
 *      sizeSystemId: // value for 'sizeSystemId'
 *   },
 * });
 */
export function useDeselectSizeSystemIsOnShowcaseMutation(baseOptions?: Apollo.MutationHookOptions<DeselectSizeSystemIsOnShowcaseMutation, DeselectSizeSystemIsOnShowcaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeselectSizeSystemIsOnShowcaseMutation, DeselectSizeSystemIsOnShowcaseMutationVariables>(DeselectSizeSystemIsOnShowcaseDocument, options);
      }
export type DeselectSizeSystemIsOnShowcaseMutationHookResult = ReturnType<typeof useDeselectSizeSystemIsOnShowcaseMutation>;
export type DeselectSizeSystemIsOnShowcaseMutationResult = Apollo.MutationResult<DeselectSizeSystemIsOnShowcaseMutation>;
export type DeselectSizeSystemIsOnShowcaseMutationOptions = Apollo.BaseMutationOptions<DeselectSizeSystemIsOnShowcaseMutation, DeselectSizeSystemIsOnShowcaseMutationVariables>;
export const DeleteCollectionDocument = gql`
    mutation DeleteCollection($id: Int!) {
  deleteCollection(id: $id)
}
    `;
export type DeleteCollectionMutationFn = Apollo.MutationFunction<DeleteCollectionMutation, DeleteCollectionMutationVariables>;

/**
 * __useDeleteCollectionMutation__
 *
 * To run a mutation, you first call `useDeleteCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCollectionMutation, { data, loading, error }] = useDeleteCollectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCollectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCollectionMutation, DeleteCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCollectionMutation, DeleteCollectionMutationVariables>(DeleteCollectionDocument, options);
      }
export type DeleteCollectionMutationHookResult = ReturnType<typeof useDeleteCollectionMutation>;
export type DeleteCollectionMutationResult = Apollo.MutationResult<DeleteCollectionMutation>;
export type DeleteCollectionMutationOptions = Apollo.BaseMutationOptions<DeleteCollectionMutation, DeleteCollectionMutationVariables>;
export const CreateSizeSystemDocument = gql`
    mutation CreateSizeSystem($sizeSystem: SizeSystemCreateInput) {
  createSizeSystem(sizeSystem: $sizeSystem) {
    code
    name
  }
}
    `;
export type CreateSizeSystemMutationFn = Apollo.MutationFunction<CreateSizeSystemMutation, CreateSizeSystemMutationVariables>;

/**
 * __useCreateSizeSystemMutation__
 *
 * To run a mutation, you first call `useCreateSizeSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSizeSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSizeSystemMutation, { data, loading, error }] = useCreateSizeSystemMutation({
 *   variables: {
 *      sizeSystem: // value for 'sizeSystem'
 *   },
 * });
 */
export function useCreateSizeSystemMutation(baseOptions?: Apollo.MutationHookOptions<CreateSizeSystemMutation, CreateSizeSystemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSizeSystemMutation, CreateSizeSystemMutationVariables>(CreateSizeSystemDocument, options);
      }
export type CreateSizeSystemMutationHookResult = ReturnType<typeof useCreateSizeSystemMutation>;
export type CreateSizeSystemMutationResult = Apollo.MutationResult<CreateSizeSystemMutation>;
export type CreateSizeSystemMutationOptions = Apollo.BaseMutationOptions<CreateSizeSystemMutation, CreateSizeSystemMutationVariables>;
export const BulkUpdateSizeSystemsDocument = gql`
    mutation BulkUpdateSizeSystems($inputSizeSystems: [SizeSystemUpdateInput]) {
  updateBulkSizeSystems(inputSizeSystems: $inputSizeSystems) {
    id
    code
    name
  }
}
    `;
export type BulkUpdateSizeSystemsMutationFn = Apollo.MutationFunction<BulkUpdateSizeSystemsMutation, BulkUpdateSizeSystemsMutationVariables>;

/**
 * __useBulkUpdateSizeSystemsMutation__
 *
 * To run a mutation, you first call `useBulkUpdateSizeSystemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateSizeSystemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateSizeSystemsMutation, { data, loading, error }] = useBulkUpdateSizeSystemsMutation({
 *   variables: {
 *      inputSizeSystems: // value for 'inputSizeSystems'
 *   },
 * });
 */
export function useBulkUpdateSizeSystemsMutation(baseOptions?: Apollo.MutationHookOptions<BulkUpdateSizeSystemsMutation, BulkUpdateSizeSystemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUpdateSizeSystemsMutation, BulkUpdateSizeSystemsMutationVariables>(BulkUpdateSizeSystemsDocument, options);
      }
export type BulkUpdateSizeSystemsMutationHookResult = ReturnType<typeof useBulkUpdateSizeSystemsMutation>;
export type BulkUpdateSizeSystemsMutationResult = Apollo.MutationResult<BulkUpdateSizeSystemsMutation>;
export type BulkUpdateSizeSystemsMutationOptions = Apollo.BaseMutationOptions<BulkUpdateSizeSystemsMutation, BulkUpdateSizeSystemsMutationVariables>;
export const UpdateSizeSystemsDocument = gql`
    mutation UpdateSizeSystems($inputSizeSystem: SizeSystemUpdateInput) {
  updateSizeSystem(sizeSystem: $inputSizeSystem) {
    id
    code
    name
  }
}
    `;
export type UpdateSizeSystemsMutationFn = Apollo.MutationFunction<UpdateSizeSystemsMutation, UpdateSizeSystemsMutationVariables>;

/**
 * __useUpdateSizeSystemsMutation__
 *
 * To run a mutation, you first call `useUpdateSizeSystemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSizeSystemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSizeSystemsMutation, { data, loading, error }] = useUpdateSizeSystemsMutation({
 *   variables: {
 *      inputSizeSystem: // value for 'inputSizeSystem'
 *   },
 * });
 */
export function useUpdateSizeSystemsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSizeSystemsMutation, UpdateSizeSystemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSizeSystemsMutation, UpdateSizeSystemsMutationVariables>(UpdateSizeSystemsDocument, options);
      }
export type UpdateSizeSystemsMutationHookResult = ReturnType<typeof useUpdateSizeSystemsMutation>;
export type UpdateSizeSystemsMutationResult = Apollo.MutationResult<UpdateSizeSystemsMutation>;
export type UpdateSizeSystemsMutationOptions = Apollo.BaseMutationOptions<UpdateSizeSystemsMutation, UpdateSizeSystemsMutationVariables>;
export const DeleteSizeSystemDocument = gql`
    mutation DeleteSizeSystem($id: Int!) {
  deleteSizeSystem(id: $id)
}
    `;
export type DeleteSizeSystemMutationFn = Apollo.MutationFunction<DeleteSizeSystemMutation, DeleteSizeSystemMutationVariables>;

/**
 * __useDeleteSizeSystemMutation__
 *
 * To run a mutation, you first call `useDeleteSizeSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSizeSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSizeSystemMutation, { data, loading, error }] = useDeleteSizeSystemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSizeSystemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSizeSystemMutation, DeleteSizeSystemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSizeSystemMutation, DeleteSizeSystemMutationVariables>(DeleteSizeSystemDocument, options);
      }
export type DeleteSizeSystemMutationHookResult = ReturnType<typeof useDeleteSizeSystemMutation>;
export type DeleteSizeSystemMutationResult = Apollo.MutationResult<DeleteSizeSystemMutation>;
export type DeleteSizeSystemMutationOptions = Apollo.BaseMutationOptions<DeleteSizeSystemMutation, DeleteSizeSystemMutationVariables>;
export const AssignFamilyToSizeSystemDocument = gql`
    mutation AssignFamilyToSizeSystem($familyId: Int!, $sizeSystemId: Int!) {
  assignFamilyToSizeSystem(familyId: $familyId, sizeSystemId: $sizeSystemId)
}
    `;
export type AssignFamilyToSizeSystemMutationFn = Apollo.MutationFunction<AssignFamilyToSizeSystemMutation, AssignFamilyToSizeSystemMutationVariables>;

/**
 * __useAssignFamilyToSizeSystemMutation__
 *
 * To run a mutation, you first call `useAssignFamilyToSizeSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignFamilyToSizeSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignFamilyToSizeSystemMutation, { data, loading, error }] = useAssignFamilyToSizeSystemMutation({
 *   variables: {
 *      familyId: // value for 'familyId'
 *      sizeSystemId: // value for 'sizeSystemId'
 *   },
 * });
 */
export function useAssignFamilyToSizeSystemMutation(baseOptions?: Apollo.MutationHookOptions<AssignFamilyToSizeSystemMutation, AssignFamilyToSizeSystemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignFamilyToSizeSystemMutation, AssignFamilyToSizeSystemMutationVariables>(AssignFamilyToSizeSystemDocument, options);
      }
export type AssignFamilyToSizeSystemMutationHookResult = ReturnType<typeof useAssignFamilyToSizeSystemMutation>;
export type AssignFamilyToSizeSystemMutationResult = Apollo.MutationResult<AssignFamilyToSizeSystemMutation>;
export type AssignFamilyToSizeSystemMutationOptions = Apollo.BaseMutationOptions<AssignFamilyToSizeSystemMutation, AssignFamilyToSizeSystemMutationVariables>;
export const RemoveFamilyFromSizeSystemDocument = gql`
    mutation RemoveFamilyFromSizeSystem($familyId: Int!, $sizeSystemId: Int!) {
  removeFamilyFromSizeSystem(familyId: $familyId, sizeSystemId: $sizeSystemId)
}
    `;
export type RemoveFamilyFromSizeSystemMutationFn = Apollo.MutationFunction<RemoveFamilyFromSizeSystemMutation, RemoveFamilyFromSizeSystemMutationVariables>;

/**
 * __useRemoveFamilyFromSizeSystemMutation__
 *
 * To run a mutation, you first call `useRemoveFamilyFromSizeSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFamilyFromSizeSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFamilyFromSizeSystemMutation, { data, loading, error }] = useRemoveFamilyFromSizeSystemMutation({
 *   variables: {
 *      familyId: // value for 'familyId'
 *      sizeSystemId: // value for 'sizeSystemId'
 *   },
 * });
 */
export function useRemoveFamilyFromSizeSystemMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFamilyFromSizeSystemMutation, RemoveFamilyFromSizeSystemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFamilyFromSizeSystemMutation, RemoveFamilyFromSizeSystemMutationVariables>(RemoveFamilyFromSizeSystemDocument, options);
      }
export type RemoveFamilyFromSizeSystemMutationHookResult = ReturnType<typeof useRemoveFamilyFromSizeSystemMutation>;
export type RemoveFamilyFromSizeSystemMutationResult = Apollo.MutationResult<RemoveFamilyFromSizeSystemMutation>;
export type RemoveFamilyFromSizeSystemMutationOptions = Apollo.BaseMutationOptions<RemoveFamilyFromSizeSystemMutation, RemoveFamilyFromSizeSystemMutationVariables>;
export const CreateSizeSystemRowHeaderDocument = gql`
    mutation CreateSizeSystemRowHeader($sizeSystemRowHeader: SizeSystemRowHeaderCreateInput) {
  createSizeSystemRowHeader(sizeSystemRowHeader: $sizeSystemRowHeader) {
    name
  }
}
    `;
export type CreateSizeSystemRowHeaderMutationFn = Apollo.MutationFunction<CreateSizeSystemRowHeaderMutation, CreateSizeSystemRowHeaderMutationVariables>;

/**
 * __useCreateSizeSystemRowHeaderMutation__
 *
 * To run a mutation, you first call `useCreateSizeSystemRowHeaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSizeSystemRowHeaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSizeSystemRowHeaderMutation, { data, loading, error }] = useCreateSizeSystemRowHeaderMutation({
 *   variables: {
 *      sizeSystemRowHeader: // value for 'sizeSystemRowHeader'
 *   },
 * });
 */
export function useCreateSizeSystemRowHeaderMutation(baseOptions?: Apollo.MutationHookOptions<CreateSizeSystemRowHeaderMutation, CreateSizeSystemRowHeaderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSizeSystemRowHeaderMutation, CreateSizeSystemRowHeaderMutationVariables>(CreateSizeSystemRowHeaderDocument, options);
      }
export type CreateSizeSystemRowHeaderMutationHookResult = ReturnType<typeof useCreateSizeSystemRowHeaderMutation>;
export type CreateSizeSystemRowHeaderMutationResult = Apollo.MutationResult<CreateSizeSystemRowHeaderMutation>;
export type CreateSizeSystemRowHeaderMutationOptions = Apollo.BaseMutationOptions<CreateSizeSystemRowHeaderMutation, CreateSizeSystemRowHeaderMutationVariables>;
export const CreateSizeSystemRowDocument = gql`
    mutation CreateSizeSystemRow($sizeSystemRow: SizeSystemRowCreateInput) {
  createSizeSystemRow(sizeSystemRow: $sizeSystemRow) {
    name
    sortNumber
  }
}
    `;
export type CreateSizeSystemRowMutationFn = Apollo.MutationFunction<CreateSizeSystemRowMutation, CreateSizeSystemRowMutationVariables>;

/**
 * __useCreateSizeSystemRowMutation__
 *
 * To run a mutation, you first call `useCreateSizeSystemRowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSizeSystemRowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSizeSystemRowMutation, { data, loading, error }] = useCreateSizeSystemRowMutation({
 *   variables: {
 *      sizeSystemRow: // value for 'sizeSystemRow'
 *   },
 * });
 */
export function useCreateSizeSystemRowMutation(baseOptions?: Apollo.MutationHookOptions<CreateSizeSystemRowMutation, CreateSizeSystemRowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSizeSystemRowMutation, CreateSizeSystemRowMutationVariables>(CreateSizeSystemRowDocument, options);
      }
export type CreateSizeSystemRowMutationHookResult = ReturnType<typeof useCreateSizeSystemRowMutation>;
export type CreateSizeSystemRowMutationResult = Apollo.MutationResult<CreateSizeSystemRowMutation>;
export type CreateSizeSystemRowMutationOptions = Apollo.BaseMutationOptions<CreateSizeSystemRowMutation, CreateSizeSystemRowMutationVariables>;
export const UpdateSizeSystemRowHeaderDocument = gql`
    mutation UpdateSizeSystemRowHeader($sizeSystemRowHeader: SizeSystemRowHeaderUpdateInput) {
  updateSizeSystemRowHeader(sizeSystemRowHeader: $sizeSystemRowHeader) {
    id
    isOnShowcase
  }
}
    `;
export type UpdateSizeSystemRowHeaderMutationFn = Apollo.MutationFunction<UpdateSizeSystemRowHeaderMutation, UpdateSizeSystemRowHeaderMutationVariables>;

/**
 * __useUpdateSizeSystemRowHeaderMutation__
 *
 * To run a mutation, you first call `useUpdateSizeSystemRowHeaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSizeSystemRowHeaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSizeSystemRowHeaderMutation, { data, loading, error }] = useUpdateSizeSystemRowHeaderMutation({
 *   variables: {
 *      sizeSystemRowHeader: // value for 'sizeSystemRowHeader'
 *   },
 * });
 */
export function useUpdateSizeSystemRowHeaderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSizeSystemRowHeaderMutation, UpdateSizeSystemRowHeaderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSizeSystemRowHeaderMutation, UpdateSizeSystemRowHeaderMutationVariables>(UpdateSizeSystemRowHeaderDocument, options);
      }
export type UpdateSizeSystemRowHeaderMutationHookResult = ReturnType<typeof useUpdateSizeSystemRowHeaderMutation>;
export type UpdateSizeSystemRowHeaderMutationResult = Apollo.MutationResult<UpdateSizeSystemRowHeaderMutation>;
export type UpdateSizeSystemRowHeaderMutationOptions = Apollo.BaseMutationOptions<UpdateSizeSystemRowHeaderMutation, UpdateSizeSystemRowHeaderMutationVariables>;
export const BulkUpdateSizeSystemRowsDocument = gql`
    mutation BulkUpdateSizeSystemRows($inputSizeSystemRows: [SizeSystemRowUpdateInput]) {
  updateBulkSizeSystemRows(inputSizeSystemRows: $inputSizeSystemRows) {
    name
    sortNumber
  }
}
    `;
export type BulkUpdateSizeSystemRowsMutationFn = Apollo.MutationFunction<BulkUpdateSizeSystemRowsMutation, BulkUpdateSizeSystemRowsMutationVariables>;

/**
 * __useBulkUpdateSizeSystemRowsMutation__
 *
 * To run a mutation, you first call `useBulkUpdateSizeSystemRowsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateSizeSystemRowsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateSizeSystemRowsMutation, { data, loading, error }] = useBulkUpdateSizeSystemRowsMutation({
 *   variables: {
 *      inputSizeSystemRows: // value for 'inputSizeSystemRows'
 *   },
 * });
 */
export function useBulkUpdateSizeSystemRowsMutation(baseOptions?: Apollo.MutationHookOptions<BulkUpdateSizeSystemRowsMutation, BulkUpdateSizeSystemRowsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUpdateSizeSystemRowsMutation, BulkUpdateSizeSystemRowsMutationVariables>(BulkUpdateSizeSystemRowsDocument, options);
      }
export type BulkUpdateSizeSystemRowsMutationHookResult = ReturnType<typeof useBulkUpdateSizeSystemRowsMutation>;
export type BulkUpdateSizeSystemRowsMutationResult = Apollo.MutationResult<BulkUpdateSizeSystemRowsMutation>;
export type BulkUpdateSizeSystemRowsMutationOptions = Apollo.BaseMutationOptions<BulkUpdateSizeSystemRowsMutation, BulkUpdateSizeSystemRowsMutationVariables>;
export const DeleteSizeSystemRowDocument = gql`
    mutation DeleteSizeSystemRow($id: Int!, $sizeSystemId: Int!) {
  deleteSizeSystemRow(id: $id, sizeSystemId: $sizeSystemId)
}
    `;
export type DeleteSizeSystemRowMutationFn = Apollo.MutationFunction<DeleteSizeSystemRowMutation, DeleteSizeSystemRowMutationVariables>;

/**
 * __useDeleteSizeSystemRowMutation__
 *
 * To run a mutation, you first call `useDeleteSizeSystemRowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSizeSystemRowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSizeSystemRowMutation, { data, loading, error }] = useDeleteSizeSystemRowMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sizeSystemId: // value for 'sizeSystemId'
 *   },
 * });
 */
export function useDeleteSizeSystemRowMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSizeSystemRowMutation, DeleteSizeSystemRowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSizeSystemRowMutation, DeleteSizeSystemRowMutationVariables>(DeleteSizeSystemRowDocument, options);
      }
export type DeleteSizeSystemRowMutationHookResult = ReturnType<typeof useDeleteSizeSystemRowMutation>;
export type DeleteSizeSystemRowMutationResult = Apollo.MutationResult<DeleteSizeSystemRowMutation>;
export type DeleteSizeSystemRowMutationOptions = Apollo.BaseMutationOptions<DeleteSizeSystemRowMutation, DeleteSizeSystemRowMutationVariables>;
export const DeleteSizeSystemRowHeaderDocument = gql`
    mutation DeleteSizeSystemRowHeader($id: Int!, $sizeSystemId: Int!) {
  deleteSizeSystemRowHeader(id: $id, sizeSystemId: $sizeSystemId)
}
    `;
export type DeleteSizeSystemRowHeaderMutationFn = Apollo.MutationFunction<DeleteSizeSystemRowHeaderMutation, DeleteSizeSystemRowHeaderMutationVariables>;

/**
 * __useDeleteSizeSystemRowHeaderMutation__
 *
 * To run a mutation, you first call `useDeleteSizeSystemRowHeaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSizeSystemRowHeaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSizeSystemRowHeaderMutation, { data, loading, error }] = useDeleteSizeSystemRowHeaderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sizeSystemId: // value for 'sizeSystemId'
 *   },
 * });
 */
export function useDeleteSizeSystemRowHeaderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSizeSystemRowHeaderMutation, DeleteSizeSystemRowHeaderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSizeSystemRowHeaderMutation, DeleteSizeSystemRowHeaderMutationVariables>(DeleteSizeSystemRowHeaderDocument, options);
      }
export type DeleteSizeSystemRowHeaderMutationHookResult = ReturnType<typeof useDeleteSizeSystemRowHeaderMutation>;
export type DeleteSizeSystemRowHeaderMutationResult = Apollo.MutationResult<DeleteSizeSystemRowHeaderMutation>;
export type DeleteSizeSystemRowHeaderMutationOptions = Apollo.BaseMutationOptions<DeleteSizeSystemRowHeaderMutation, DeleteSizeSystemRowHeaderMutationVariables>;
export const UpdateProductModuleDocument = gql`
    mutation UpdateProductModule($productId: Int!, $moduleId: Int!) {
  updateProductDetails(
    productDetails: {id: $productId, productModuleId: $moduleId}
  ) {
    productModuleId
  }
}
    `;
export type UpdateProductModuleMutationFn = Apollo.MutationFunction<UpdateProductModuleMutation, UpdateProductModuleMutationVariables>;

/**
 * __useUpdateProductModuleMutation__
 *
 * To run a mutation, you first call `useUpdateProductModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductModuleMutation, { data, loading, error }] = useUpdateProductModuleMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useUpdateProductModuleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductModuleMutation, UpdateProductModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductModuleMutation, UpdateProductModuleMutationVariables>(UpdateProductModuleDocument, options);
      }
export type UpdateProductModuleMutationHookResult = ReturnType<typeof useUpdateProductModuleMutation>;
export type UpdateProductModuleMutationResult = Apollo.MutationResult<UpdateProductModuleMutation>;
export type UpdateProductModuleMutationOptions = Apollo.BaseMutationOptions<UpdateProductModuleMutation, UpdateProductModuleMutationVariables>;
export const UpdateProductShowcaseDocument = gql`
    mutation UpdateProductShowcase($productId: Int!, $isOnShowcase: Boolean!) {
  updateProductDetails(
    productDetails: {id: $productId, isOnShowcase: $isOnShowcase}
  ) {
    isOnShowcase
  }
}
    `;
export type UpdateProductShowcaseMutationFn = Apollo.MutationFunction<UpdateProductShowcaseMutation, UpdateProductShowcaseMutationVariables>;

/**
 * __useUpdateProductShowcaseMutation__
 *
 * To run a mutation, you first call `useUpdateProductShowcaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductShowcaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductShowcaseMutation, { data, loading, error }] = useUpdateProductShowcaseMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      isOnShowcase: // value for 'isOnShowcase'
 *   },
 * });
 */
export function useUpdateProductShowcaseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductShowcaseMutation, UpdateProductShowcaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductShowcaseMutation, UpdateProductShowcaseMutationVariables>(UpdateProductShowcaseDocument, options);
      }
export type UpdateProductShowcaseMutationHookResult = ReturnType<typeof useUpdateProductShowcaseMutation>;
export type UpdateProductShowcaseMutationResult = Apollo.MutationResult<UpdateProductShowcaseMutation>;
export type UpdateProductShowcaseMutationOptions = Apollo.BaseMutationOptions<UpdateProductShowcaseMutation, UpdateProductShowcaseMutationVariables>;
export const UpdateProductSeasonDocument = gql`
    mutation UpdateProductSeason($productId: Int!, $seasonId: Int) {
  updateProductDetails(productDetails: {id: $productId, seasonId: $seasonId}) {
    seasonId
  }
}
    `;
export type UpdateProductSeasonMutationFn = Apollo.MutationFunction<UpdateProductSeasonMutation, UpdateProductSeasonMutationVariables>;

/**
 * __useUpdateProductSeasonMutation__
 *
 * To run a mutation, you first call `useUpdateProductSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductSeasonMutation, { data, loading, error }] = useUpdateProductSeasonMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useUpdateProductSeasonMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductSeasonMutation, UpdateProductSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductSeasonMutation, UpdateProductSeasonMutationVariables>(UpdateProductSeasonDocument, options);
      }
export type UpdateProductSeasonMutationHookResult = ReturnType<typeof useUpdateProductSeasonMutation>;
export type UpdateProductSeasonMutationResult = Apollo.MutationResult<UpdateProductSeasonMutation>;
export type UpdateProductSeasonMutationOptions = Apollo.BaseMutationOptions<UpdateProductSeasonMutation, UpdateProductSeasonMutationVariables>;
export const CreateProductModuleDocument = gql`
    mutation CreateProductModule($productModule: ProductModuleCreateInput) {
  createProductModule(productModule: $productModule) {
    id
    name
    collectionId
    storeGroupToProductModules {
      id
    }
  }
}
    `;
export type CreateProductModuleMutationFn = Apollo.MutationFunction<CreateProductModuleMutation, CreateProductModuleMutationVariables>;

/**
 * __useCreateProductModuleMutation__
 *
 * To run a mutation, you first call `useCreateProductModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductModuleMutation, { data, loading, error }] = useCreateProductModuleMutation({
 *   variables: {
 *      productModule: // value for 'productModule'
 *   },
 * });
 */
export function useCreateProductModuleMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductModuleMutation, CreateProductModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductModuleMutation, CreateProductModuleMutationVariables>(CreateProductModuleDocument, options);
      }
export type CreateProductModuleMutationHookResult = ReturnType<typeof useCreateProductModuleMutation>;
export type CreateProductModuleMutationResult = Apollo.MutationResult<CreateProductModuleMutation>;
export type CreateProductModuleMutationOptions = Apollo.BaseMutationOptions<CreateProductModuleMutation, CreateProductModuleMutationVariables>;
export const DeleteProductModuleDocument = gql`
    mutation DeleteProductModule($id: Int!) {
  deleteProductModule(id: $id)
}
    `;
export type DeleteProductModuleMutationFn = Apollo.MutationFunction<DeleteProductModuleMutation, DeleteProductModuleMutationVariables>;

/**
 * __useDeleteProductModuleMutation__
 *
 * To run a mutation, you first call `useDeleteProductModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductModuleMutation, { data, loading, error }] = useDeleteProductModuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductModuleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductModuleMutation, DeleteProductModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductModuleMutation, DeleteProductModuleMutationVariables>(DeleteProductModuleDocument, options);
      }
export type DeleteProductModuleMutationHookResult = ReturnType<typeof useDeleteProductModuleMutation>;
export type DeleteProductModuleMutationResult = Apollo.MutationResult<DeleteProductModuleMutation>;
export type DeleteProductModuleMutationOptions = Apollo.BaseMutationOptions<DeleteProductModuleMutation, DeleteProductModuleMutationVariables>;
export const DeleteStoreGroupDocument = gql`
    mutation DeleteStoreGroup($id: Int!) {
  deleteStoreGroup(id: $id)
}
    `;
export type DeleteStoreGroupMutationFn = Apollo.MutationFunction<DeleteStoreGroupMutation, DeleteStoreGroupMutationVariables>;

/**
 * __useDeleteStoreGroupMutation__
 *
 * To run a mutation, you first call `useDeleteStoreGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStoreGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStoreGroupMutation, { data, loading, error }] = useDeleteStoreGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStoreGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStoreGroupMutation, DeleteStoreGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStoreGroupMutation, DeleteStoreGroupMutationVariables>(DeleteStoreGroupDocument, options);
      }
export type DeleteStoreGroupMutationHookResult = ReturnType<typeof useDeleteStoreGroupMutation>;
export type DeleteStoreGroupMutationResult = Apollo.MutationResult<DeleteStoreGroupMutation>;
export type DeleteStoreGroupMutationOptions = Apollo.BaseMutationOptions<DeleteStoreGroupMutation, DeleteStoreGroupMutationVariables>;
export const CreateStoreGroupDocument = gql`
    mutation CreateStoreGroup($storeGroup: StoreGroupCreateInput) {
  createStoreGroup(storeGroup: $storeGroup) {
    id
    name
    storeGroupToProductModules {
      id
      isSelected
    }
  }
}
    `;
export type CreateStoreGroupMutationFn = Apollo.MutationFunction<CreateStoreGroupMutation, CreateStoreGroupMutationVariables>;

/**
 * __useCreateStoreGroupMutation__
 *
 * To run a mutation, you first call `useCreateStoreGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoreGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoreGroupMutation, { data, loading, error }] = useCreateStoreGroupMutation({
 *   variables: {
 *      storeGroup: // value for 'storeGroup'
 *   },
 * });
 */
export function useCreateStoreGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateStoreGroupMutation, CreateStoreGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStoreGroupMutation, CreateStoreGroupMutationVariables>(CreateStoreGroupDocument, options);
      }
export type CreateStoreGroupMutationHookResult = ReturnType<typeof useCreateStoreGroupMutation>;
export type CreateStoreGroupMutationResult = Apollo.MutationResult<CreateStoreGroupMutation>;
export type CreateStoreGroupMutationOptions = Apollo.BaseMutationOptions<CreateStoreGroupMutation, CreateStoreGroupMutationVariables>;
export const UpdateStoreGroupToProductModuleDocument = gql`
    mutation UpdateStoreGroupToProductModule($storeGroupToProductModule: StoreGroupToProductModuleUpdateInput) {
  updateStoreGroupToProductModule(
    storeGroupToProductModule: $storeGroupToProductModule
  )
}
    `;
export type UpdateStoreGroupToProductModuleMutationFn = Apollo.MutationFunction<UpdateStoreGroupToProductModuleMutation, UpdateStoreGroupToProductModuleMutationVariables>;

/**
 * __useUpdateStoreGroupToProductModuleMutation__
 *
 * To run a mutation, you first call `useUpdateStoreGroupToProductModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoreGroupToProductModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoreGroupToProductModuleMutation, { data, loading, error }] = useUpdateStoreGroupToProductModuleMutation({
 *   variables: {
 *      storeGroupToProductModule: // value for 'storeGroupToProductModule'
 *   },
 * });
 */
export function useUpdateStoreGroupToProductModuleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoreGroupToProductModuleMutation, UpdateStoreGroupToProductModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoreGroupToProductModuleMutation, UpdateStoreGroupToProductModuleMutationVariables>(UpdateStoreGroupToProductModuleDocument, options);
      }
export type UpdateStoreGroupToProductModuleMutationHookResult = ReturnType<typeof useUpdateStoreGroupToProductModuleMutation>;
export type UpdateStoreGroupToProductModuleMutationResult = Apollo.MutationResult<UpdateStoreGroupToProductModuleMutation>;
export type UpdateStoreGroupToProductModuleMutationOptions = Apollo.BaseMutationOptions<UpdateStoreGroupToProductModuleMutation, UpdateStoreGroupToProductModuleMutationVariables>;
export const UpdateProductValidatedStrengthDocument = gql`
    mutation UpdateProductValidatedStrength($productId: Int!, $validatedProductStrength: Decimal!) {
  updateProduct(
    productDetails: {productId: $productId, validatedProductStrength: $validatedProductStrength}
  ) {
    id
  }
}
    `;
export type UpdateProductValidatedStrengthMutationFn = Apollo.MutationFunction<UpdateProductValidatedStrengthMutation, UpdateProductValidatedStrengthMutationVariables>;

/**
 * __useUpdateProductValidatedStrengthMutation__
 *
 * To run a mutation, you first call `useUpdateProductValidatedStrengthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductValidatedStrengthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductValidatedStrengthMutation, { data, loading, error }] = useUpdateProductValidatedStrengthMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      validatedProductStrength: // value for 'validatedProductStrength'
 *   },
 * });
 */
export function useUpdateProductValidatedStrengthMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductValidatedStrengthMutation, UpdateProductValidatedStrengthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductValidatedStrengthMutation, UpdateProductValidatedStrengthMutationVariables>(UpdateProductValidatedStrengthDocument, options);
      }
export type UpdateProductValidatedStrengthMutationHookResult = ReturnType<typeof useUpdateProductValidatedStrengthMutation>;
export type UpdateProductValidatedStrengthMutationResult = Apollo.MutationResult<UpdateProductValidatedStrengthMutation>;
export type UpdateProductValidatedStrengthMutationOptions = Apollo.BaseMutationOptions<UpdateProductValidatedStrengthMutation, UpdateProductValidatedStrengthMutationVariables>;
export const UpdateProductSizeSystemDocument = gql`
    mutation UpdateProductSizeSystem($productId: Int!, $sizeSystemRowId: Int!) {
  updateProduct(
    productDetails: {productId: $productId, sizeSystemRowId: $sizeSystemRowId}
  ) {
    sizeSystemRowId
  }
}
    `;
export type UpdateProductSizeSystemMutationFn = Apollo.MutationFunction<UpdateProductSizeSystemMutation, UpdateProductSizeSystemMutationVariables>;

/**
 * __useUpdateProductSizeSystemMutation__
 *
 * To run a mutation, you first call `useUpdateProductSizeSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductSizeSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductSizeSystemMutation, { data, loading, error }] = useUpdateProductSizeSystemMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      sizeSystemRowId: // value for 'sizeSystemRowId'
 *   },
 * });
 */
export function useUpdateProductSizeSystemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductSizeSystemMutation, UpdateProductSizeSystemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductSizeSystemMutation, UpdateProductSizeSystemMutationVariables>(UpdateProductSizeSystemDocument, options);
      }
export type UpdateProductSizeSystemMutationHookResult = ReturnType<typeof useUpdateProductSizeSystemMutation>;
export type UpdateProductSizeSystemMutationResult = Apollo.MutationResult<UpdateProductSizeSystemMutation>;
export type UpdateProductSizeSystemMutationOptions = Apollo.BaseMutationOptions<UpdateProductSizeSystemMutation, UpdateProductSizeSystemMutationVariables>;
export const CreateSeasonDocument = gql`
    mutation CreateSeason($season: SeasonCreateInput) {
  createSeason(season: $season) {
    code
    name
  }
}
    `;
export type CreateSeasonMutationFn = Apollo.MutationFunction<CreateSeasonMutation, CreateSeasonMutationVariables>;

/**
 * __useCreateSeasonMutation__
 *
 * To run a mutation, you first call `useCreateSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSeasonMutation, { data, loading, error }] = useCreateSeasonMutation({
 *   variables: {
 *      season: // value for 'season'
 *   },
 * });
 */
export function useCreateSeasonMutation(baseOptions?: Apollo.MutationHookOptions<CreateSeasonMutation, CreateSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSeasonMutation, CreateSeasonMutationVariables>(CreateSeasonDocument, options);
      }
export type CreateSeasonMutationHookResult = ReturnType<typeof useCreateSeasonMutation>;
export type CreateSeasonMutationResult = Apollo.MutationResult<CreateSeasonMutation>;
export type CreateSeasonMutationOptions = Apollo.BaseMutationOptions<CreateSeasonMutation, CreateSeasonMutationVariables>;
export const CreateStoreTypeDocument = gql`
    mutation CreateStoreType($storeType: StoreTypeCreateInput) {
  createStoreType(storeType: $storeType) {
    code
    name
    isMain
    isWeb
  }
}
    `;
export type CreateStoreTypeMutationFn = Apollo.MutationFunction<CreateStoreTypeMutation, CreateStoreTypeMutationVariables>;

/**
 * __useCreateStoreTypeMutation__
 *
 * To run a mutation, you first call `useCreateStoreTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoreTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoreTypeMutation, { data, loading, error }] = useCreateStoreTypeMutation({
 *   variables: {
 *      storeType: // value for 'storeType'
 *   },
 * });
 */
export function useCreateStoreTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateStoreTypeMutation, CreateStoreTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStoreTypeMutation, CreateStoreTypeMutationVariables>(CreateStoreTypeDocument, options);
      }
export type CreateStoreTypeMutationHookResult = ReturnType<typeof useCreateStoreTypeMutation>;
export type CreateStoreTypeMutationResult = Apollo.MutationResult<CreateStoreTypeMutation>;
export type CreateStoreTypeMutationOptions = Apollo.BaseMutationOptions<CreateStoreTypeMutation, CreateStoreTypeMutationVariables>;
export const UpdateStoreTypeDocument = gql`
    mutation UpdateStoreType($storeType: StoreTypeUpdateInput) {
  updateStoreType(storeType: $storeType) {
    id
    code
    name
    isMain
    isWeb
  }
}
    `;
export type UpdateStoreTypeMutationFn = Apollo.MutationFunction<UpdateStoreTypeMutation, UpdateStoreTypeMutationVariables>;

/**
 * __useUpdateStoreTypeMutation__
 *
 * To run a mutation, you first call `useUpdateStoreTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoreTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoreTypeMutation, { data, loading, error }] = useUpdateStoreTypeMutation({
 *   variables: {
 *      storeType: // value for 'storeType'
 *   },
 * });
 */
export function useUpdateStoreTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoreTypeMutation, UpdateStoreTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoreTypeMutation, UpdateStoreTypeMutationVariables>(UpdateStoreTypeDocument, options);
      }
export type UpdateStoreTypeMutationHookResult = ReturnType<typeof useUpdateStoreTypeMutation>;
export type UpdateStoreTypeMutationResult = Apollo.MutationResult<UpdateStoreTypeMutation>;
export type UpdateStoreTypeMutationOptions = Apollo.BaseMutationOptions<UpdateStoreTypeMutation, UpdateStoreTypeMutationVariables>;
export const StoreReplaceValidatedWithImplantedStrengthDocument = gql`
    mutation StoreReplaceValidatedWithImplantedStrength($replaceNonZeroValues: Boolean!, $seasonId: Int!, $universeId: Int!) {
  storeReplaceValidatedWithImplantedStrength(
    replaceNonZeroValues: $replaceNonZeroValues
    seasonId: $seasonId
    universeId: $universeId
  )
}
    `;
export type StoreReplaceValidatedWithImplantedStrengthMutationFn = Apollo.MutationFunction<StoreReplaceValidatedWithImplantedStrengthMutation, StoreReplaceValidatedWithImplantedStrengthMutationVariables>;

/**
 * __useStoreReplaceValidatedWithImplantedStrengthMutation__
 *
 * To run a mutation, you first call `useStoreReplaceValidatedWithImplantedStrengthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreReplaceValidatedWithImplantedStrengthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeReplaceValidatedWithImplantedStrengthMutation, { data, loading, error }] = useStoreReplaceValidatedWithImplantedStrengthMutation({
 *   variables: {
 *      replaceNonZeroValues: // value for 'replaceNonZeroValues'
 *      seasonId: // value for 'seasonId'
 *      universeId: // value for 'universeId'
 *   },
 * });
 */
export function useStoreReplaceValidatedWithImplantedStrengthMutation(baseOptions?: Apollo.MutationHookOptions<StoreReplaceValidatedWithImplantedStrengthMutation, StoreReplaceValidatedWithImplantedStrengthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StoreReplaceValidatedWithImplantedStrengthMutation, StoreReplaceValidatedWithImplantedStrengthMutationVariables>(StoreReplaceValidatedWithImplantedStrengthDocument, options);
      }
export type StoreReplaceValidatedWithImplantedStrengthMutationHookResult = ReturnType<typeof useStoreReplaceValidatedWithImplantedStrengthMutation>;
export type StoreReplaceValidatedWithImplantedStrengthMutationResult = Apollo.MutationResult<StoreReplaceValidatedWithImplantedStrengthMutation>;
export type StoreReplaceValidatedWithImplantedStrengthMutationOptions = Apollo.BaseMutationOptions<StoreReplaceValidatedWithImplantedStrengthMutation, StoreReplaceValidatedWithImplantedStrengthMutationVariables>;
export const UpdateBulkStoreTypesDocument = gql`
    mutation UpdateBulkStoreTypes($inputStoreTypes: [StoreTypeUpdateInput]) {
  updateBulkStoreTypes(inputStoreTypes: $inputStoreTypes) {
    id
    code
    name
    isMain
    isWeb
  }
}
    `;
export type UpdateBulkStoreTypesMutationFn = Apollo.MutationFunction<UpdateBulkStoreTypesMutation, UpdateBulkStoreTypesMutationVariables>;

/**
 * __useUpdateBulkStoreTypesMutation__
 *
 * To run a mutation, you first call `useUpdateBulkStoreTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBulkStoreTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBulkStoreTypesMutation, { data, loading, error }] = useUpdateBulkStoreTypesMutation({
 *   variables: {
 *      inputStoreTypes: // value for 'inputStoreTypes'
 *   },
 * });
 */
export function useUpdateBulkStoreTypesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBulkStoreTypesMutation, UpdateBulkStoreTypesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBulkStoreTypesMutation, UpdateBulkStoreTypesMutationVariables>(UpdateBulkStoreTypesDocument, options);
      }
export type UpdateBulkStoreTypesMutationHookResult = ReturnType<typeof useUpdateBulkStoreTypesMutation>;
export type UpdateBulkStoreTypesMutationResult = Apollo.MutationResult<UpdateBulkStoreTypesMutation>;
export type UpdateBulkStoreTypesMutationOptions = Apollo.BaseMutationOptions<UpdateBulkStoreTypesMutation, UpdateBulkStoreTypesMutationVariables>;
export const BulkUpdateSeasonsDocument = gql`
    mutation BulkUpdateSeasons($inputSeasons: [SeasonUpdateInput]) {
  updateBulkSeasons(inputSeasons: $inputSeasons) {
    id
    name
    code
    isActive
  }
}
    `;
export type BulkUpdateSeasonsMutationFn = Apollo.MutationFunction<BulkUpdateSeasonsMutation, BulkUpdateSeasonsMutationVariables>;

/**
 * __useBulkUpdateSeasonsMutation__
 *
 * To run a mutation, you first call `useBulkUpdateSeasonsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateSeasonsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateSeasonsMutation, { data, loading, error }] = useBulkUpdateSeasonsMutation({
 *   variables: {
 *      inputSeasons: // value for 'inputSeasons'
 *   },
 * });
 */
export function useBulkUpdateSeasonsMutation(baseOptions?: Apollo.MutationHookOptions<BulkUpdateSeasonsMutation, BulkUpdateSeasonsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUpdateSeasonsMutation, BulkUpdateSeasonsMutationVariables>(BulkUpdateSeasonsDocument, options);
      }
export type BulkUpdateSeasonsMutationHookResult = ReturnType<typeof useBulkUpdateSeasonsMutation>;
export type BulkUpdateSeasonsMutationResult = Apollo.MutationResult<BulkUpdateSeasonsMutation>;
export type BulkUpdateSeasonsMutationOptions = Apollo.BaseMutationOptions<BulkUpdateSeasonsMutation, BulkUpdateSeasonsMutationVariables>;
export const UpdateSeasonDocument = gql`
    mutation UpdateSeason($inputSeason: SeasonUpdateInput) {
  updateSeason(season: $inputSeason) {
    id
    name
    code
    isActive
  }
}
    `;
export type UpdateSeasonMutationFn = Apollo.MutationFunction<UpdateSeasonMutation, UpdateSeasonMutationVariables>;

/**
 * __useUpdateSeasonMutation__
 *
 * To run a mutation, you first call `useUpdateSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSeasonMutation, { data, loading, error }] = useUpdateSeasonMutation({
 *   variables: {
 *      inputSeason: // value for 'inputSeason'
 *   },
 * });
 */
export function useUpdateSeasonMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSeasonMutation, UpdateSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSeasonMutation, UpdateSeasonMutationVariables>(UpdateSeasonDocument, options);
      }
export type UpdateSeasonMutationHookResult = ReturnType<typeof useUpdateSeasonMutation>;
export type UpdateSeasonMutationResult = Apollo.MutationResult<UpdateSeasonMutation>;
export type UpdateSeasonMutationOptions = Apollo.BaseMutationOptions<UpdateSeasonMutation, UpdateSeasonMutationVariables>;
export const DeleteSeasonDocument = gql`
    mutation DeleteSeason($id: Int!) {
  deleteSeason(id: $id)
}
    `;
export type DeleteSeasonMutationFn = Apollo.MutationFunction<DeleteSeasonMutation, DeleteSeasonMutationVariables>;

/**
 * __useDeleteSeasonMutation__
 *
 * To run a mutation, you first call `useDeleteSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSeasonMutation, { data, loading, error }] = useDeleteSeasonMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSeasonMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSeasonMutation, DeleteSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSeasonMutation, DeleteSeasonMutationVariables>(DeleteSeasonDocument, options);
      }
export type DeleteSeasonMutationHookResult = ReturnType<typeof useDeleteSeasonMutation>;
export type DeleteSeasonMutationResult = Apollo.MutationResult<DeleteSeasonMutation>;
export type DeleteSeasonMutationOptions = Apollo.BaseMutationOptions<DeleteSeasonMutation, DeleteSeasonMutationVariables>;
export const DeleteStoreTypeDocument = gql`
    mutation DeleteStoreType($id: Int!) {
  deleteStoreType(id: $id)
}
    `;
export type DeleteStoreTypeMutationFn = Apollo.MutationFunction<DeleteStoreTypeMutation, DeleteStoreTypeMutationVariables>;

/**
 * __useDeleteStoreTypeMutation__
 *
 * To run a mutation, you first call `useDeleteStoreTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStoreTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStoreTypeMutation, { data, loading, error }] = useDeleteStoreTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStoreTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStoreTypeMutation, DeleteStoreTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStoreTypeMutation, DeleteStoreTypeMutationVariables>(DeleteStoreTypeDocument, options);
      }
export type DeleteStoreTypeMutationHookResult = ReturnType<typeof useDeleteStoreTypeMutation>;
export type DeleteStoreTypeMutationResult = Apollo.MutationResult<DeleteStoreTypeMutation>;
export type DeleteStoreTypeMutationOptions = Apollo.BaseMutationOptions<DeleteStoreTypeMutation, DeleteStoreTypeMutationVariables>;
export const UpdateOptimalStockOverrideDocument = gql`
    mutation UpdateOptimalStockOverride($stockOverride: OptimalStockOverrideUpdateInput) {
  updateOptimalStockOverride(stockOverride: $stockOverride) {
    id
  }
}
    `;
export type UpdateOptimalStockOverrideMutationFn = Apollo.MutationFunction<UpdateOptimalStockOverrideMutation, UpdateOptimalStockOverrideMutationVariables>;

/**
 * __useUpdateOptimalStockOverrideMutation__
 *
 * To run a mutation, you first call `useUpdateOptimalStockOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOptimalStockOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOptimalStockOverrideMutation, { data, loading, error }] = useUpdateOptimalStockOverrideMutation({
 *   variables: {
 *      stockOverride: // value for 'stockOverride'
 *   },
 * });
 */
export function useUpdateOptimalStockOverrideMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOptimalStockOverrideMutation, UpdateOptimalStockOverrideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOptimalStockOverrideMutation, UpdateOptimalStockOverrideMutationVariables>(UpdateOptimalStockOverrideDocument, options);
      }
export type UpdateOptimalStockOverrideMutationHookResult = ReturnType<typeof useUpdateOptimalStockOverrideMutation>;
export type UpdateOptimalStockOverrideMutationResult = Apollo.MutationResult<UpdateOptimalStockOverrideMutation>;
export type UpdateOptimalStockOverrideMutationOptions = Apollo.BaseMutationOptions<UpdateOptimalStockOverrideMutation, UpdateOptimalStockOverrideMutationVariables>;
export const UpdateBulkOptimalStockOverrideFlagsDocument = gql`
    mutation UpdateBulkOptimalStockOverrideFlags($stockOverrideBulk: OptimalStockOverrideUpdateBulkInput) {
  updateBulkOptimalStockOverrideFlags(stockOverrideBulk: $stockOverrideBulk) {
    id
    hasStockMin
    hasStockMax
    hasCondition
    stockMinStoreStrengthCondition
    stockMaxStoreStrengthCondition
  }
}
    `;
export type UpdateBulkOptimalStockOverrideFlagsMutationFn = Apollo.MutationFunction<UpdateBulkOptimalStockOverrideFlagsMutation, UpdateBulkOptimalStockOverrideFlagsMutationVariables>;

/**
 * __useUpdateBulkOptimalStockOverrideFlagsMutation__
 *
 * To run a mutation, you first call `useUpdateBulkOptimalStockOverrideFlagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBulkOptimalStockOverrideFlagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBulkOptimalStockOverrideFlagsMutation, { data, loading, error }] = useUpdateBulkOptimalStockOverrideFlagsMutation({
 *   variables: {
 *      stockOverrideBulk: // value for 'stockOverrideBulk'
 *   },
 * });
 */
export function useUpdateBulkOptimalStockOverrideFlagsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBulkOptimalStockOverrideFlagsMutation, UpdateBulkOptimalStockOverrideFlagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBulkOptimalStockOverrideFlagsMutation, UpdateBulkOptimalStockOverrideFlagsMutationVariables>(UpdateBulkOptimalStockOverrideFlagsDocument, options);
      }
export type UpdateBulkOptimalStockOverrideFlagsMutationHookResult = ReturnType<typeof useUpdateBulkOptimalStockOverrideFlagsMutation>;
export type UpdateBulkOptimalStockOverrideFlagsMutationResult = Apollo.MutationResult<UpdateBulkOptimalStockOverrideFlagsMutation>;
export type UpdateBulkOptimalStockOverrideFlagsMutationOptions = Apollo.BaseMutationOptions<UpdateBulkOptimalStockOverrideFlagsMutation, UpdateBulkOptimalStockOverrideFlagsMutationVariables>;
export const UpdateBulkOptimalStockOverrideDocument = gql`
    mutation UpdateBulkOptimalStockOverride($stockOverrideBulk: [OptimalStockOverrideUpdateInput]) {
  updateBulkOptimalStockOverride(stockOverrideBulk: $stockOverrideBulk) {
    id
    stockMin
    stockMax
    stockMinStoreStrengthConditionValue
    stockMaxStoreStrengthConditionValue
  }
}
    `;
export type UpdateBulkOptimalStockOverrideMutationFn = Apollo.MutationFunction<UpdateBulkOptimalStockOverrideMutation, UpdateBulkOptimalStockOverrideMutationVariables>;

/**
 * __useUpdateBulkOptimalStockOverrideMutation__
 *
 * To run a mutation, you first call `useUpdateBulkOptimalStockOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBulkOptimalStockOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBulkOptimalStockOverrideMutation, { data, loading, error }] = useUpdateBulkOptimalStockOverrideMutation({
 *   variables: {
 *      stockOverrideBulk: // value for 'stockOverrideBulk'
 *   },
 * });
 */
export function useUpdateBulkOptimalStockOverrideMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBulkOptimalStockOverrideMutation, UpdateBulkOptimalStockOverrideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBulkOptimalStockOverrideMutation, UpdateBulkOptimalStockOverrideMutationVariables>(UpdateBulkOptimalStockOverrideDocument, options);
      }
export type UpdateBulkOptimalStockOverrideMutationHookResult = ReturnType<typeof useUpdateBulkOptimalStockOverrideMutation>;
export type UpdateBulkOptimalStockOverrideMutationResult = Apollo.MutationResult<UpdateBulkOptimalStockOverrideMutation>;
export type UpdateBulkOptimalStockOverrideMutationOptions = Apollo.BaseMutationOptions<UpdateBulkOptimalStockOverrideMutation, UpdateBulkOptimalStockOverrideMutationVariables>;
export const UpdateProductCustomFieldDocument = gql`
    mutation UpdateProductCustomField($productCustomField: ProductCustomFieldUpdateInput) {
  updateProductCustomField(productCustomField: $productCustomField) {
    customFieldName1
    customFieldValues1
    customFieldName2
    customFieldValues2
    customFieldName3
    customFieldValues3
    customFieldName4
    customFieldValues4
    customFieldName5
    customFieldValues5
  }
}
    `;
export type UpdateProductCustomFieldMutationFn = Apollo.MutationFunction<UpdateProductCustomFieldMutation, UpdateProductCustomFieldMutationVariables>;

/**
 * __useUpdateProductCustomFieldMutation__
 *
 * To run a mutation, you first call `useUpdateProductCustomFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductCustomFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductCustomFieldMutation, { data, loading, error }] = useUpdateProductCustomFieldMutation({
 *   variables: {
 *      productCustomField: // value for 'productCustomField'
 *   },
 * });
 */
export function useUpdateProductCustomFieldMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductCustomFieldMutation, UpdateProductCustomFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductCustomFieldMutation, UpdateProductCustomFieldMutationVariables>(UpdateProductCustomFieldDocument, options);
      }
export type UpdateProductCustomFieldMutationHookResult = ReturnType<typeof useUpdateProductCustomFieldMutation>;
export type UpdateProductCustomFieldMutationResult = Apollo.MutationResult<UpdateProductCustomFieldMutation>;
export type UpdateProductCustomFieldMutationOptions = Apollo.BaseMutationOptions<UpdateProductCustomFieldMutation, UpdateProductCustomFieldMutationVariables>;
export const UpdateStoreCustomFieldDocument = gql`
    mutation UpdateStoreCustomField($storeCustomField: StoreCustomFieldUpdateInput) {
  updateStoreCustomField(storeCustomField: $storeCustomField) {
    customFieldName1
    customFieldValues1
    customFieldName2
    customFieldValues2
    customFieldName3
    customFieldValues3
    customFieldName4
    customFieldValues4
    customFieldName5
    customFieldValues5
  }
}
    `;
export type UpdateStoreCustomFieldMutationFn = Apollo.MutationFunction<UpdateStoreCustomFieldMutation, UpdateStoreCustomFieldMutationVariables>;

/**
 * __useUpdateStoreCustomFieldMutation__
 *
 * To run a mutation, you first call `useUpdateStoreCustomFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoreCustomFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoreCustomFieldMutation, { data, loading, error }] = useUpdateStoreCustomFieldMutation({
 *   variables: {
 *      storeCustomField: // value for 'storeCustomField'
 *   },
 * });
 */
export function useUpdateStoreCustomFieldMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoreCustomFieldMutation, UpdateStoreCustomFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoreCustomFieldMutation, UpdateStoreCustomFieldMutationVariables>(UpdateStoreCustomFieldDocument, options);
      }
export type UpdateStoreCustomFieldMutationHookResult = ReturnType<typeof useUpdateStoreCustomFieldMutation>;
export type UpdateStoreCustomFieldMutationResult = Apollo.MutationResult<UpdateStoreCustomFieldMutation>;
export type UpdateStoreCustomFieldMutationOptions = Apollo.BaseMutationOptions<UpdateStoreCustomFieldMutation, UpdateStoreCustomFieldMutationVariables>;
export const UpdateProductDetailsDocument = gql`
    mutation UpdateProductDetails($productDetails: ProductDetailsUpdateInput) {
  updateProductDetails(productDetails: $productDetails) {
    id
  }
}
    `;
export type UpdateProductDetailsMutationFn = Apollo.MutationFunction<UpdateProductDetailsMutation, UpdateProductDetailsMutationVariables>;

/**
 * __useUpdateProductDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateProductDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductDetailsMutation, { data, loading, error }] = useUpdateProductDetailsMutation({
 *   variables: {
 *      productDetails: // value for 'productDetails'
 *   },
 * });
 */
export function useUpdateProductDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductDetailsMutation, UpdateProductDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductDetailsMutation, UpdateProductDetailsMutationVariables>(UpdateProductDetailsDocument, options);
      }
export type UpdateProductDetailsMutationHookResult = ReturnType<typeof useUpdateProductDetailsMutation>;
export type UpdateProductDetailsMutationResult = Apollo.MutationResult<UpdateProductDetailsMutation>;
export type UpdateProductDetailsMutationOptions = Apollo.BaseMutationOptions<UpdateProductDetailsMutation, UpdateProductDetailsMutationVariables>;
export const UpdateStoreDetailsDocument = gql`
    mutation UpdateStoreDetails($storeDetails: StoreDetailsUpdateInput) {
  updateStoreDetails(storeDetails: $storeDetails) {
    id
  }
}
    `;
export type UpdateStoreDetailsMutationFn = Apollo.MutationFunction<UpdateStoreDetailsMutation, UpdateStoreDetailsMutationVariables>;

/**
 * __useUpdateStoreDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateStoreDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoreDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoreDetailsMutation, { data, loading, error }] = useUpdateStoreDetailsMutation({
 *   variables: {
 *      storeDetails: // value for 'storeDetails'
 *   },
 * });
 */
export function useUpdateStoreDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoreDetailsMutation, UpdateStoreDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoreDetailsMutation, UpdateStoreDetailsMutationVariables>(UpdateStoreDetailsDocument, options);
      }
export type UpdateStoreDetailsMutationHookResult = ReturnType<typeof useUpdateStoreDetailsMutation>;
export type UpdateStoreDetailsMutationResult = Apollo.MutationResult<UpdateStoreDetailsMutation>;
export type UpdateStoreDetailsMutationOptions = Apollo.BaseMutationOptions<UpdateStoreDetailsMutation, UpdateStoreDetailsMutationVariables>;
export const UpdateStoreFleetDocument = gql`
    mutation UpdateStoreFleet($storeFleet: StoreFleetInput) {
  updateStoreFleet(storeFleet: $storeFleet) {
    id
  }
}
    `;
export type UpdateStoreFleetMutationFn = Apollo.MutationFunction<UpdateStoreFleetMutation, UpdateStoreFleetMutationVariables>;

/**
 * __useUpdateStoreFleetMutation__
 *
 * To run a mutation, you first call `useUpdateStoreFleetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoreFleetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoreFleetMutation, { data, loading, error }] = useUpdateStoreFleetMutation({
 *   variables: {
 *      storeFleet: // value for 'storeFleet'
 *   },
 * });
 */
export function useUpdateStoreFleetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoreFleetMutation, UpdateStoreFleetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoreFleetMutation, UpdateStoreFleetMutationVariables>(UpdateStoreFleetDocument, options);
      }
export type UpdateStoreFleetMutationHookResult = ReturnType<typeof useUpdateStoreFleetMutation>;
export type UpdateStoreFleetMutationResult = Apollo.MutationResult<UpdateStoreFleetMutation>;
export type UpdateStoreFleetMutationOptions = Apollo.BaseMutationOptions<UpdateStoreFleetMutation, UpdateStoreFleetMutationVariables>;
export const UpdateStoreToStoreGroupDocument = gql`
    mutation UpdateStoreToStoreGroup($storeToStoreGroups: StoreToStoreGroupUpdateInput) {
  updateStoreToStoreGroup(storeToStoreGroups: $storeToStoreGroups)
}
    `;
export type UpdateStoreToStoreGroupMutationFn = Apollo.MutationFunction<UpdateStoreToStoreGroupMutation, UpdateStoreToStoreGroupMutationVariables>;

/**
 * __useUpdateStoreToStoreGroupMutation__
 *
 * To run a mutation, you first call `useUpdateStoreToStoreGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoreToStoreGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoreToStoreGroupMutation, { data, loading, error }] = useUpdateStoreToStoreGroupMutation({
 *   variables: {
 *      storeToStoreGroups: // value for 'storeToStoreGroups'
 *   },
 * });
 */
export function useUpdateStoreToStoreGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoreToStoreGroupMutation, UpdateStoreToStoreGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoreToStoreGroupMutation, UpdateStoreToStoreGroupMutationVariables>(UpdateStoreToStoreGroupDocument, options);
      }
export type UpdateStoreToStoreGroupMutationHookResult = ReturnType<typeof useUpdateStoreToStoreGroupMutation>;
export type UpdateStoreToStoreGroupMutationResult = Apollo.MutationResult<UpdateStoreToStoreGroupMutation>;
export type UpdateStoreToStoreGroupMutationOptions = Apollo.BaseMutationOptions<UpdateStoreToStoreGroupMutation, UpdateStoreToStoreGroupMutationVariables>;
export const UpdateStorePerformanceDocument = gql`
    mutation UpdateStorePerformance($storePerformance: StorePerformanceUpdateInput) {
  updateStorePerformance(storePerformance: $storePerformance) {
    id
  }
}
    `;
export type UpdateStorePerformanceMutationFn = Apollo.MutationFunction<UpdateStorePerformanceMutation, UpdateStorePerformanceMutationVariables>;

/**
 * __useUpdateStorePerformanceMutation__
 *
 * To run a mutation, you first call `useUpdateStorePerformanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStorePerformanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStorePerformanceMutation, { data, loading, error }] = useUpdateStorePerformanceMutation({
 *   variables: {
 *      storePerformance: // value for 'storePerformance'
 *   },
 * });
 */
export function useUpdateStorePerformanceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStorePerformanceMutation, UpdateStorePerformanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStorePerformanceMutation, UpdateStorePerformanceMutationVariables>(UpdateStorePerformanceDocument, options);
      }
export type UpdateStorePerformanceMutationHookResult = ReturnType<typeof useUpdateStorePerformanceMutation>;
export type UpdateStorePerformanceMutationResult = Apollo.MutationResult<UpdateStorePerformanceMutation>;
export type UpdateStorePerformanceMutationOptions = Apollo.BaseMutationOptions<UpdateStorePerformanceMutation, UpdateStorePerformanceMutationVariables>;
export const UpdateTenantSettingsDocument = gql`
    mutation UpdateTenantSettings($tenantSettings: TenantSettingsUpdateInput) {
  updateTenantSettings(tenantSettings: $tenantSettings)
}
    `;
export type UpdateTenantSettingsMutationFn = Apollo.MutationFunction<UpdateTenantSettingsMutation, UpdateTenantSettingsMutationVariables>;

/**
 * __useUpdateTenantSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateTenantSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantSettingsMutation, { data, loading, error }] = useUpdateTenantSettingsMutation({
 *   variables: {
 *      tenantSettings: // value for 'tenantSettings'
 *   },
 * });
 */
export function useUpdateTenantSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTenantSettingsMutation, UpdateTenantSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTenantSettingsMutation, UpdateTenantSettingsMutationVariables>(UpdateTenantSettingsDocument, options);
      }
export type UpdateTenantSettingsMutationHookResult = ReturnType<typeof useUpdateTenantSettingsMutation>;
export type UpdateTenantSettingsMutationResult = Apollo.MutationResult<UpdateTenantSettingsMutation>;
export type UpdateTenantSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateTenantSettingsMutation, UpdateTenantSettingsMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($userInput: UserCreateInput) {
  createUser(userInput: $userInput) {
    id
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($userInput: UserUpdateInput) {
  updateUser(userInput: $userInput) {
    id
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($userId: Int!) {
  deleteUser(userId: $userId)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const UpdateUserSettingsDocument = gql`
    mutation UpdateUserSettings($userSettings: String) {
  updateUserSettings(userSettings: $userSettings)
}
    `;
export type UpdateUserSettingsMutationFn = Apollo.MutationFunction<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>;

/**
 * __useUpdateUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSettingsMutation, { data, loading, error }] = useUpdateUserSettingsMutation({
 *   variables: {
 *      userSettings: // value for 'userSettings'
 *   },
 * });
 */
export function useUpdateUserSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>(UpdateUserSettingsDocument, options);
      }
export type UpdateUserSettingsMutationHookResult = ReturnType<typeof useUpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationResult = Apollo.MutationResult<UpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>;
export const GenerateExportDocument = gql`
    mutation GenerateExport {
  generateExport
}
    `;
export type GenerateExportMutationFn = Apollo.MutationFunction<GenerateExportMutation, GenerateExportMutationVariables>;

/**
 * __useGenerateExportMutation__
 *
 * To run a mutation, you first call `useGenerateExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateExportMutation, { data, loading, error }] = useGenerateExportMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateExportMutation(baseOptions?: Apollo.MutationHookOptions<GenerateExportMutation, GenerateExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateExportMutation, GenerateExportMutationVariables>(GenerateExportDocument, options);
      }
export type GenerateExportMutationHookResult = ReturnType<typeof useGenerateExportMutation>;
export type GenerateExportMutationResult = Apollo.MutationResult<GenerateExportMutation>;
export type GenerateExportMutationOptions = Apollo.BaseMutationOptions<GenerateExportMutation, GenerateExportMutationVariables>;
export const GetUniversesDocument = gql`
    query GetUniverses {
  universes {
    id
    name
    code
  }
}
    `;

/**
 * __useGetUniversesQuery__
 *
 * To run a query within a React component, call `useGetUniversesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUniversesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUniversesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUniversesQuery(baseOptions?: Apollo.QueryHookOptions<GetUniversesQuery, GetUniversesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUniversesQuery, GetUniversesQueryVariables>(GetUniversesDocument, options);
      }
export function useGetUniversesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUniversesQuery, GetUniversesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUniversesQuery, GetUniversesQueryVariables>(GetUniversesDocument, options);
        }
export type GetUniversesQueryHookResult = ReturnType<typeof useGetUniversesQuery>;
export type GetUniversesLazyQueryHookResult = ReturnType<typeof useGetUniversesLazyQuery>;
export type GetUniversesQueryResult = Apollo.QueryResult<GetUniversesQuery, GetUniversesQueryVariables>;
export const GetSizeSystemsDocument = gql`
    query GetSizeSystems {
  sizeSystems {
    id
    code
    name
    origin
  }
}
    `;

/**
 * __useGetSizeSystemsQuery__
 *
 * To run a query within a React component, call `useGetSizeSystemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSizeSystemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSizeSystemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSizeSystemsQuery(baseOptions?: Apollo.QueryHookOptions<GetSizeSystemsQuery, GetSizeSystemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSizeSystemsQuery, GetSizeSystemsQueryVariables>(GetSizeSystemsDocument, options);
      }
export function useGetSizeSystemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSizeSystemsQuery, GetSizeSystemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSizeSystemsQuery, GetSizeSystemsQueryVariables>(GetSizeSystemsDocument, options);
        }
export type GetSizeSystemsQueryHookResult = ReturnType<typeof useGetSizeSystemsQuery>;
export type GetSizeSystemsLazyQueryHookResult = ReturnType<typeof useGetSizeSystemsLazyQuery>;
export type GetSizeSystemsQueryResult = Apollo.QueryResult<GetSizeSystemsQuery, GetSizeSystemsQueryVariables>;
export const GetFamiliesDocument = gql`
    query GetFamilies {
  families {
    id
    name
    code
  }
}
    `;

/**
 * __useGetFamiliesQuery__
 *
 * To run a query within a React component, call `useGetFamiliesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFamiliesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFamiliesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFamiliesQuery(baseOptions?: Apollo.QueryHookOptions<GetFamiliesQuery, GetFamiliesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFamiliesQuery, GetFamiliesQueryVariables>(GetFamiliesDocument, options);
      }
export function useGetFamiliesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFamiliesQuery, GetFamiliesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFamiliesQuery, GetFamiliesQueryVariables>(GetFamiliesDocument, options);
        }
export type GetFamiliesQueryHookResult = ReturnType<typeof useGetFamiliesQuery>;
export type GetFamiliesLazyQueryHookResult = ReturnType<typeof useGetFamiliesLazyQuery>;
export type GetFamiliesQueryResult = Apollo.QueryResult<GetFamiliesQuery, GetFamiliesQueryVariables>;
export const GetWarehousesDocument = gql`
    query GetWarehouses {
  warehouses {
    id
    code
    name
    isRetail
    supplierId
  }
}
    `;

/**
 * __useGetWarehousesQuery__
 *
 * To run a query within a React component, call `useGetWarehousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehousesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWarehousesQuery(baseOptions?: Apollo.QueryHookOptions<GetWarehousesQuery, GetWarehousesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWarehousesQuery, GetWarehousesQueryVariables>(GetWarehousesDocument, options);
      }
export function useGetWarehousesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWarehousesQuery, GetWarehousesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWarehousesQuery, GetWarehousesQueryVariables>(GetWarehousesDocument, options);
        }
export type GetWarehousesQueryHookResult = ReturnType<typeof useGetWarehousesQuery>;
export type GetWarehousesLazyQueryHookResult = ReturnType<typeof useGetWarehousesLazyQuery>;
export type GetWarehousesQueryResult = Apollo.QueryResult<GetWarehousesQuery, GetWarehousesQueryVariables>;
export const GetSizeSystemRowHeadersDocument = gql`
    query GetSizeSystemRowHeaders($sizeSystemId: Int!) {
  sizeSystemRowHeaders(sizeSystemId: $sizeSystemId) {
    id
    name
    sortNumber
    isOnShowcase
  }
}
    `;

/**
 * __useGetSizeSystemRowHeadersQuery__
 *
 * To run a query within a React component, call `useGetSizeSystemRowHeadersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSizeSystemRowHeadersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSizeSystemRowHeadersQuery({
 *   variables: {
 *      sizeSystemId: // value for 'sizeSystemId'
 *   },
 * });
 */
export function useGetSizeSystemRowHeadersQuery(baseOptions: Apollo.QueryHookOptions<GetSizeSystemRowHeadersQuery, GetSizeSystemRowHeadersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSizeSystemRowHeadersQuery, GetSizeSystemRowHeadersQueryVariables>(GetSizeSystemRowHeadersDocument, options);
      }
export function useGetSizeSystemRowHeadersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSizeSystemRowHeadersQuery, GetSizeSystemRowHeadersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSizeSystemRowHeadersQuery, GetSizeSystemRowHeadersQueryVariables>(GetSizeSystemRowHeadersDocument, options);
        }
export type GetSizeSystemRowHeadersQueryHookResult = ReturnType<typeof useGetSizeSystemRowHeadersQuery>;
export type GetSizeSystemRowHeadersLazyQueryHookResult = ReturnType<typeof useGetSizeSystemRowHeadersLazyQuery>;
export type GetSizeSystemRowHeadersQueryResult = Apollo.QueryResult<GetSizeSystemRowHeadersQuery, GetSizeSystemRowHeadersQueryVariables>;
export const GetSizeSystemRowDocument = gql`
    query GetSizeSystemRow($sizeSystemId: Int!) {
  sizeSystemRows(sizeSystemId: $sizeSystemId) {
    id
    name
    sizeSystemRowValues {
      id
      sizeSystemRowHeaderId
      sizeSystemRowId
      distribution
    }
    sortNumber
  }
}
    `;

/**
 * __useGetSizeSystemRowQuery__
 *
 * To run a query within a React component, call `useGetSizeSystemRowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSizeSystemRowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSizeSystemRowQuery({
 *   variables: {
 *      sizeSystemId: // value for 'sizeSystemId'
 *   },
 * });
 */
export function useGetSizeSystemRowQuery(baseOptions: Apollo.QueryHookOptions<GetSizeSystemRowQuery, GetSizeSystemRowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSizeSystemRowQuery, GetSizeSystemRowQueryVariables>(GetSizeSystemRowDocument, options);
      }
export function useGetSizeSystemRowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSizeSystemRowQuery, GetSizeSystemRowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSizeSystemRowQuery, GetSizeSystemRowQueryVariables>(GetSizeSystemRowDocument, options);
        }
export type GetSizeSystemRowQueryHookResult = ReturnType<typeof useGetSizeSystemRowQuery>;
export type GetSizeSystemRowLazyQueryHookResult = ReturnType<typeof useGetSizeSystemRowLazyQuery>;
export type GetSizeSystemRowQueryResult = Apollo.QueryResult<GetSizeSystemRowQuery, GetSizeSystemRowQueryVariables>;
export const GetProductOptimalStockProductSummaryDocument = gql`
    query GetProductOptimalStockProductSummary($productId: Int!) {
  productOptimalStockProductSummary(productId: $productId) {
    photoUrls
    productCode
    productName
    colorCode
    colorName
    price
    collectionId
    productModuleId
    sizeSystemId
    sizeSystemRowName
    customFieldValue1
    customFieldValue2
    customFieldValue3
    customFieldValue4
    customFieldValue5
    permutationField1
    permutationField2
  }
}
    `;

/**
 * __useGetProductOptimalStockProductSummaryQuery__
 *
 * To run a query within a React component, call `useGetProductOptimalStockProductSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductOptimalStockProductSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductOptimalStockProductSummaryQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetProductOptimalStockProductSummaryQuery(baseOptions: Apollo.QueryHookOptions<GetProductOptimalStockProductSummaryQuery, GetProductOptimalStockProductSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductOptimalStockProductSummaryQuery, GetProductOptimalStockProductSummaryQueryVariables>(GetProductOptimalStockProductSummaryDocument, options);
      }
export function useGetProductOptimalStockProductSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductOptimalStockProductSummaryQuery, GetProductOptimalStockProductSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductOptimalStockProductSummaryQuery, GetProductOptimalStockProductSummaryQueryVariables>(GetProductOptimalStockProductSummaryDocument, options);
        }
export type GetProductOptimalStockProductSummaryQueryHookResult = ReturnType<typeof useGetProductOptimalStockProductSummaryQuery>;
export type GetProductOptimalStockProductSummaryLazyQueryHookResult = ReturnType<typeof useGetProductOptimalStockProductSummaryLazyQuery>;
export type GetProductOptimalStockProductSummaryQueryResult = Apollo.QueryResult<GetProductOptimalStockProductSummaryQuery, GetProductOptimalStockProductSummaryQueryVariables>;
export const GetCollectionsDocument = gql`
    query GetCollections($universeId: Int!) {
  collections(universeId: $universeId) {
    id
    code
    name
    isActive
    combinationType
    productModules {
      id
      name
    }
  }
}
    `;

/**
 * __useGetCollectionsQuery__
 *
 * To run a query within a React component, call `useGetCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionsQuery({
 *   variables: {
 *      universeId: // value for 'universeId'
 *   },
 * });
 */
export function useGetCollectionsQuery(baseOptions: Apollo.QueryHookOptions<GetCollectionsQuery, GetCollectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCollectionsQuery, GetCollectionsQueryVariables>(GetCollectionsDocument, options);
      }
export function useGetCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCollectionsQuery, GetCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCollectionsQuery, GetCollectionsQueryVariables>(GetCollectionsDocument, options);
        }
export type GetCollectionsQueryHookResult = ReturnType<typeof useGetCollectionsQuery>;
export type GetCollectionsLazyQueryHookResult = ReturnType<typeof useGetCollectionsLazyQuery>;
export type GetCollectionsQueryResult = Apollo.QueryResult<GetCollectionsQuery, GetCollectionsQueryVariables>;
export const GetCollectionsNamesDocument = gql`
    query GetCollectionsNames($universeId: Int!, $isActive: Boolean) {
  collections(universeId: $universeId, isActive: $isActive) {
    id
    name
    combinationType
  }
}
    `;

/**
 * __useGetCollectionsNamesQuery__
 *
 * To run a query within a React component, call `useGetCollectionsNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionsNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionsNamesQuery({
 *   variables: {
 *      universeId: // value for 'universeId'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useGetCollectionsNamesQuery(baseOptions: Apollo.QueryHookOptions<GetCollectionsNamesQuery, GetCollectionsNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCollectionsNamesQuery, GetCollectionsNamesQueryVariables>(GetCollectionsNamesDocument, options);
      }
export function useGetCollectionsNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCollectionsNamesQuery, GetCollectionsNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCollectionsNamesQuery, GetCollectionsNamesQueryVariables>(GetCollectionsNamesDocument, options);
        }
export type GetCollectionsNamesQueryHookResult = ReturnType<typeof useGetCollectionsNamesQuery>;
export type GetCollectionsNamesLazyQueryHookResult = ReturnType<typeof useGetCollectionsNamesLazyQuery>;
export type GetCollectionsNamesQueryResult = Apollo.QueryResult<GetCollectionsNamesQuery, GetCollectionsNamesQueryVariables>;
export const GetCollectionDocument = gql`
    query GetCollection($id: Int!) {
  collection(id: $id) {
    id
    code
    name
    isActive
    combinationType
    productModules {
      name
      id
      collectionId
    }
    storeGroups {
      id
      name
      collectionId
      storeGroupToProductModules {
        id
        productModuleId
        storeGroupId
        isSelected
      }
    }
  }
}
    `;

/**
 * __useGetCollectionQuery__
 *
 * To run a query within a React component, call `useGetCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCollectionQuery(baseOptions: Apollo.QueryHookOptions<GetCollectionQuery, GetCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCollectionQuery, GetCollectionQueryVariables>(GetCollectionDocument, options);
      }
export function useGetCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCollectionQuery, GetCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCollectionQuery, GetCollectionQueryVariables>(GetCollectionDocument, options);
        }
export type GetCollectionQueryHookResult = ReturnType<typeof useGetCollectionQuery>;
export type GetCollectionLazyQueryHookResult = ReturnType<typeof useGetCollectionLazyQuery>;
export type GetCollectionQueryResult = Apollo.QueryResult<GetCollectionQuery, GetCollectionQueryVariables>;
export const GetFamilyToSizeSystemDocument = gql`
    query GetFamilyToSizeSystem($sizeSystemId: Int!) {
  familyToSizeSystem(sizeSystemId: $sizeSystemId) {
    id
    name
    code
  }
}
    `;

/**
 * __useGetFamilyToSizeSystemQuery__
 *
 * To run a query within a React component, call `useGetFamilyToSizeSystemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFamilyToSizeSystemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFamilyToSizeSystemQuery({
 *   variables: {
 *      sizeSystemId: // value for 'sizeSystemId'
 *   },
 * });
 */
export function useGetFamilyToSizeSystemQuery(baseOptions: Apollo.QueryHookOptions<GetFamilyToSizeSystemQuery, GetFamilyToSizeSystemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFamilyToSizeSystemQuery, GetFamilyToSizeSystemQueryVariables>(GetFamilyToSizeSystemDocument, options);
      }
export function useGetFamilyToSizeSystemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFamilyToSizeSystemQuery, GetFamilyToSizeSystemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFamilyToSizeSystemQuery, GetFamilyToSizeSystemQueryVariables>(GetFamilyToSizeSystemDocument, options);
        }
export type GetFamilyToSizeSystemQueryHookResult = ReturnType<typeof useGetFamilyToSizeSystemQuery>;
export type GetFamilyToSizeSystemLazyQueryHookResult = ReturnType<typeof useGetFamilyToSizeSystemLazyQuery>;
export type GetFamilyToSizeSystemQueryResult = Apollo.QueryResult<GetFamilyToSizeSystemQuery, GetFamilyToSizeSystemQueryVariables>;
export const GetStockSalesTableDocument = gql`
    query GetStockSalesTable($productId: Int!) {
  stocksSalesTable(productId: $productId) {
    headers
    discountColumn {
      discountStockRatePerSeason
      discountStockRatePerPhysical
      discountStockRatePerWeb
      discountStockRatePerFiveWeeks
      discountStockRateFirstWeek
      discountStockRateSecondWeek
      discountStockRateThirdWeek
      discountStockRateFourthWeek
      discountStockRateFifthWeek
    }
    columns {
      sizeSystemRowHeaderId
      sizeSystemRowHeaderName
      totalSales
      webSales
      physicalSales
      allWeeksTotal
      salesFirstWeek
      salesSecondWeek
      salesThirdWeek
      salesFourthWeek
      salesFifthWeek
      stockAvailableAndInTransit
      stockQuantityPicking
      warehouseQuantity
      remainingQuantity
      remainingQuantitiesByDateAndSupplier {
        supplierCode
        quantityForDelivery
        timestamp
      }
      distribution
      sKU
    }
  }
}
    `;

/**
 * __useGetStockSalesTableQuery__
 *
 * To run a query within a React component, call `useGetStockSalesTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockSalesTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockSalesTableQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetStockSalesTableQuery(baseOptions: Apollo.QueryHookOptions<GetStockSalesTableQuery, GetStockSalesTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStockSalesTableQuery, GetStockSalesTableQueryVariables>(GetStockSalesTableDocument, options);
      }
export function useGetStockSalesTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStockSalesTableQuery, GetStockSalesTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStockSalesTableQuery, GetStockSalesTableQueryVariables>(GetStockSalesTableDocument, options);
        }
export type GetStockSalesTableQueryHookResult = ReturnType<typeof useGetStockSalesTableQuery>;
export type GetStockSalesTableLazyQueryHookResult = ReturnType<typeof useGetStockSalesTableLazyQuery>;
export type GetStockSalesTableQueryResult = Apollo.QueryResult<GetStockSalesTableQuery, GetStockSalesTableQueryVariables>;
export const ProductCustomFieldsDocument = gql`
    query ProductCustomFields {
  productCustomFields {
    customFieldName1
    customFieldValues1
    customFieldName2
    customFieldValues2
    customFieldName3
    customFieldValues3
    customFieldName4
    customFieldValues4
    customFieldName5
    customFieldValues5
  }
}
    `;

/**
 * __useProductCustomFieldsQuery__
 *
 * To run a query within a React component, call `useProductCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCustomFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductCustomFieldsQuery(baseOptions?: Apollo.QueryHookOptions<ProductCustomFieldsQuery, ProductCustomFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductCustomFieldsQuery, ProductCustomFieldsQueryVariables>(ProductCustomFieldsDocument, options);
      }
export function useProductCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductCustomFieldsQuery, ProductCustomFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductCustomFieldsQuery, ProductCustomFieldsQueryVariables>(ProductCustomFieldsDocument, options);
        }
export type ProductCustomFieldsQueryHookResult = ReturnType<typeof useProductCustomFieldsQuery>;
export type ProductCustomFieldsLazyQueryHookResult = ReturnType<typeof useProductCustomFieldsLazyQuery>;
export type ProductCustomFieldsQueryResult = Apollo.QueryResult<ProductCustomFieldsQuery, ProductCustomFieldsQueryVariables>;
export const StoreCustomFieldsDocument = gql`
    query StoreCustomFields {
  storeCustomFields {
    customFieldName1
    customFieldValues1
    customFieldName2
    customFieldValues2
    customFieldName3
    customFieldValues3
    customFieldName4
    customFieldValues4
    customFieldName5
    customFieldValues5
  }
}
    `;

/**
 * __useStoreCustomFieldsQuery__
 *
 * To run a query within a React component, call `useStoreCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreCustomFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStoreCustomFieldsQuery(baseOptions?: Apollo.QueryHookOptions<StoreCustomFieldsQuery, StoreCustomFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoreCustomFieldsQuery, StoreCustomFieldsQueryVariables>(StoreCustomFieldsDocument, options);
      }
export function useStoreCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoreCustomFieldsQuery, StoreCustomFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoreCustomFieldsQuery, StoreCustomFieldsQueryVariables>(StoreCustomFieldsDocument, options);
        }
export type StoreCustomFieldsQueryHookResult = ReturnType<typeof useStoreCustomFieldsQuery>;
export type StoreCustomFieldsLazyQueryHookResult = ReturnType<typeof useStoreCustomFieldsLazyQuery>;
export type StoreCustomFieldsQueryResult = Apollo.QueryResult<StoreCustomFieldsQuery, StoreCustomFieldsQueryVariables>;
export const GetStoreStrengthTableDocument = gql`
    query GetStoreStrengthTable($productId: Int!) {
  storeStrengthTable(productId: $productId) {
    metadata {
      isOnShowcase
      numberOfStores
      weeklySalesFlow
      theoreticalSalesFlow
      proposedStrength
      validatedProductStrength
      numberOfStoresWithShowcase
    }
    headers {
      id
      name
      label
      optimalStockOverrideId
    }
    rows {
      storeStrength
      numberOfStores
      optimalStock
      total
    }
    overrides {
      id
      stockMin
      hasStockMin
      stockMax
      hasStockMax
      hasCondition
      stockMinStoreStrengthCondition
      stockMinStoreStrengthConditionValue
      stockMaxStoreStrengthCondition
      stockMaxStoreStrengthConditionValue
    }
    summary {
      optimalStock
      estimatedPicking
      actualStockDemand
    }
  }
}
    `;

/**
 * __useGetStoreStrengthTableQuery__
 *
 * To run a query within a React component, call `useGetStoreStrengthTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreStrengthTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreStrengthTableQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetStoreStrengthTableQuery(baseOptions: Apollo.QueryHookOptions<GetStoreStrengthTableQuery, GetStoreStrengthTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoreStrengthTableQuery, GetStoreStrengthTableQueryVariables>(GetStoreStrengthTableDocument, options);
      }
export function useGetStoreStrengthTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoreStrengthTableQuery, GetStoreStrengthTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoreStrengthTableQuery, GetStoreStrengthTableQueryVariables>(GetStoreStrengthTableDocument, options);
        }
export type GetStoreStrengthTableQueryHookResult = ReturnType<typeof useGetStoreStrengthTableQuery>;
export type GetStoreStrengthTableLazyQueryHookResult = ReturnType<typeof useGetStoreStrengthTableLazyQuery>;
export type GetStoreStrengthTableQueryResult = Apollo.QueryResult<GetStoreStrengthTableQuery, GetStoreStrengthTableQueryVariables>;
export const GetProductPerformanceDocument = gql`
    query GetProductPerformance($universeId: Int!, $seasonId: Int!) {
  productPerformance(universeId: $universeId, seasonId: $seasonId) {
    id
    salesPrice
    physicalStoreSales
    colorName
    isOnShowcase
    productCode
    productName
    familyName
    collectionName
    productModuleName
    isSynced
    weekSales
    stockQuantityTotal
    quantityForDelivery
    warehouseStockWholesale
    warehouseStockRetails
    stockWarehouse
    quantityPicking
    quantityInTransit
    stockQuantityInWebStore
    stockQuantityInPhysicalStore
    numberOfStoresInWhichProductIsPresent
    numberOfWeeksTheProductIsSold
    totalQuantitySold
    webStoreSales
    webPercent
    stockFlowRate
    colorCode
    weekDiscountStockRate
    pastWeekSales
    pastTwoWeekSales
    pastThreeWeekSales
    sizeSystemName
    sizeSystemRowName
    proposedStrength
    validatedStrength
    optimalStock
    implantedStrength
    hasStockMinOverride
    hasStockMaxOverride
    hasCondition
    hasException
    warehouseCoverage
    potentialWeeklySalesFlow
    estimationPicking
    customFieldValue1
    customFieldValue2
    customFieldValue3
    customFieldValue4
    customFieldValue5
    stockTotal
    permutationField1
    permutationField2
    excludedStoreExceptionsCount
    includedStoreExceptionsCount
    photoUrls
    averagePastThreeWeeksSalesAllStores
    potentialSalesPastWeekAllStores
    isArchived
  }
}
    `;

/**
 * __useGetProductPerformanceQuery__
 *
 * To run a query within a React component, call `useGetProductPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductPerformanceQuery({
 *   variables: {
 *      universeId: // value for 'universeId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useGetProductPerformanceQuery(baseOptions: Apollo.QueryHookOptions<GetProductPerformanceQuery, GetProductPerformanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductPerformanceQuery, GetProductPerformanceQueryVariables>(GetProductPerformanceDocument, options);
      }
export function useGetProductPerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductPerformanceQuery, GetProductPerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductPerformanceQuery, GetProductPerformanceQueryVariables>(GetProductPerformanceDocument, options);
        }
export type GetProductPerformanceQueryHookResult = ReturnType<typeof useGetProductPerformanceQuery>;
export type GetProductPerformanceLazyQueryHookResult = ReturnType<typeof useGetProductPerformanceLazyQuery>;
export type GetProductPerformanceQueryResult = Apollo.QueryResult<GetProductPerformanceQuery, GetProductPerformanceQueryVariables>;
export const GetProductPerformanceIdsDocument = gql`
    query GetProductPerformanceIds($universeId: Int!, $seasonId: Int!) {
  productPerformance(universeId: $universeId, seasonId: $seasonId) {
    id
  }
}
    `;

/**
 * __useGetProductPerformanceIdsQuery__
 *
 * To run a query within a React component, call `useGetProductPerformanceIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductPerformanceIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductPerformanceIdsQuery({
 *   variables: {
 *      universeId: // value for 'universeId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useGetProductPerformanceIdsQuery(baseOptions: Apollo.QueryHookOptions<GetProductPerformanceIdsQuery, GetProductPerformanceIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductPerformanceIdsQuery, GetProductPerformanceIdsQueryVariables>(GetProductPerformanceIdsDocument, options);
      }
export function useGetProductPerformanceIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductPerformanceIdsQuery, GetProductPerformanceIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductPerformanceIdsQuery, GetProductPerformanceIdsQueryVariables>(GetProductPerformanceIdsDocument, options);
        }
export type GetProductPerformanceIdsQueryHookResult = ReturnType<typeof useGetProductPerformanceIdsQuery>;
export type GetProductPerformanceIdsLazyQueryHookResult = ReturnType<typeof useGetProductPerformanceIdsLazyQuery>;
export type GetProductPerformanceIdsQueryResult = Apollo.QueryResult<GetProductPerformanceIdsQuery, GetProductPerformanceIdsQueryVariables>;
export const ProductDetailsDocument = gql`
    query ProductDetails($productId: Int!) {
  productDetails(id: $productId) {
    id
    universeId
    customFieldValue1
    customFieldValue2
    customFieldValue3
    customFieldValue4
    customFieldValue5
    seasonId
    collectionId
    sizeSystemId
    sizeSystemRowId
    productModuleId
    validatedStrength
    isOnShowcase
    isSynced
    price
  }
}
    `;

/**
 * __useProductDetailsQuery__
 *
 * To run a query within a React component, call `useProductDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductDetailsQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useProductDetailsQuery(baseOptions: Apollo.QueryHookOptions<ProductDetailsQuery, ProductDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductDetailsQuery, ProductDetailsQueryVariables>(ProductDetailsDocument, options);
      }
export function useProductDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductDetailsQuery, ProductDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductDetailsQuery, ProductDetailsQueryVariables>(ProductDetailsDocument, options);
        }
export type ProductDetailsQueryHookResult = ReturnType<typeof useProductDetailsQuery>;
export type ProductDetailsLazyQueryHookResult = ReturnType<typeof useProductDetailsLazyQuery>;
export type ProductDetailsQueryResult = Apollo.QueryResult<ProductDetailsQuery, ProductDetailsQueryVariables>;
export const ProductSeasonArchivedDetailsDocument = gql`
    query ProductSeasonArchivedDetails($productId: Int!) {
  productDetails(id: $productId) {
    isArchived
    seasonId
  }
}
    `;

/**
 * __useProductSeasonArchivedDetailsQuery__
 *
 * To run a query within a React component, call `useProductSeasonArchivedDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductSeasonArchivedDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductSeasonArchivedDetailsQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useProductSeasonArchivedDetailsQuery(baseOptions: Apollo.QueryHookOptions<ProductSeasonArchivedDetailsQuery, ProductSeasonArchivedDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductSeasonArchivedDetailsQuery, ProductSeasonArchivedDetailsQueryVariables>(ProductSeasonArchivedDetailsDocument, options);
      }
export function useProductSeasonArchivedDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductSeasonArchivedDetailsQuery, ProductSeasonArchivedDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductSeasonArchivedDetailsQuery, ProductSeasonArchivedDetailsQueryVariables>(ProductSeasonArchivedDetailsDocument, options);
        }
export type ProductSeasonArchivedDetailsQueryHookResult = ReturnType<typeof useProductSeasonArchivedDetailsQuery>;
export type ProductSeasonArchivedDetailsLazyQueryHookResult = ReturnType<typeof useProductSeasonArchivedDetailsLazyQuery>;
export type ProductSeasonArchivedDetailsQueryResult = Apollo.QueryResult<ProductSeasonArchivedDetailsQuery, ProductSeasonArchivedDetailsQueryVariables>;
export const GetStoreFleetDocument = gql`
    query GetStoreFleet($universeId: Int!, $seasonId: Int!) {
  storeFleet(universeId: $universeId, seasonId: $seasonId) {
    id
    storeType
    storeCode
    storeRegion
    storeName
    storeStrength
    targetTurnover
    storeStrengthImplant
    storeCustomField1
    storeCustomField2
    storeCustomField3
    storeCustomField4
    storeCustomField5
    isSynced
    ignoreMinMax
    pickingPriority
    capacity
    handlesShowcase
    isActive
    storeToStoreGroups {
      collectionId
      storeGroupId
      storeGroupName
    }
  }
}
    `;

/**
 * __useGetStoreFleetQuery__
 *
 * To run a query within a React component, call `useGetStoreFleetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreFleetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreFleetQuery({
 *   variables: {
 *      universeId: // value for 'universeId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useGetStoreFleetQuery(baseOptions: Apollo.QueryHookOptions<GetStoreFleetQuery, GetStoreFleetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoreFleetQuery, GetStoreFleetQueryVariables>(GetStoreFleetDocument, options);
      }
export function useGetStoreFleetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoreFleetQuery, GetStoreFleetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoreFleetQuery, GetStoreFleetQueryVariables>(GetStoreFleetDocument, options);
        }
export type GetStoreFleetQueryHookResult = ReturnType<typeof useGetStoreFleetQuery>;
export type GetStoreFleetLazyQueryHookResult = ReturnType<typeof useGetStoreFleetLazyQuery>;
export type GetStoreFleetQueryResult = Apollo.QueryResult<GetStoreFleetQuery, GetStoreFleetQueryVariables>;
export const GetCollectionStoreGroupsDocument = gql`
    query GetCollectionStoreGroups($collectionId: Int!) {
  storeGroups(collectionId: $collectionId) {
    id
    name
  }
}
    `;

/**
 * __useGetCollectionStoreGroupsQuery__
 *
 * To run a query within a React component, call `useGetCollectionStoreGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionStoreGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionStoreGroupsQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useGetCollectionStoreGroupsQuery(baseOptions: Apollo.QueryHookOptions<GetCollectionStoreGroupsQuery, GetCollectionStoreGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCollectionStoreGroupsQuery, GetCollectionStoreGroupsQueryVariables>(GetCollectionStoreGroupsDocument, options);
      }
export function useGetCollectionStoreGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCollectionStoreGroupsQuery, GetCollectionStoreGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCollectionStoreGroupsQuery, GetCollectionStoreGroupsQueryVariables>(GetCollectionStoreGroupsDocument, options);
        }
export type GetCollectionStoreGroupsQueryHookResult = ReturnType<typeof useGetCollectionStoreGroupsQuery>;
export type GetCollectionStoreGroupsLazyQueryHookResult = ReturnType<typeof useGetCollectionStoreGroupsLazyQuery>;
export type GetCollectionStoreGroupsQueryResult = Apollo.QueryResult<GetCollectionStoreGroupsQuery, GetCollectionStoreGroupsQueryVariables>;
export const StoreDetailsDocument = gql`
    query StoreDetails($storeId: Int!, $universeId: Int!, $seasonId: Int!) {
  storeDetails(id: $storeId, universeId: $universeId, seasonId: $seasonId) {
    id
    code
    handlesShowcase
    name
    photoUrls
    capacity
    capacityType
    pickingPriority
    isActive
    isSynced
    ignoreMinMax
    regionId
    regionName
    storeTypeId
    originType
    customFieldValue1
    customFieldValue2
    customFieldValue3
    customFieldValue4
    customFieldValue5
  }
}
    `;

/**
 * __useStoreDetailsQuery__
 *
 * To run a query within a React component, call `useStoreDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreDetailsQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      universeId: // value for 'universeId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useStoreDetailsQuery(baseOptions: Apollo.QueryHookOptions<StoreDetailsQuery, StoreDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoreDetailsQuery, StoreDetailsQueryVariables>(StoreDetailsDocument, options);
      }
export function useStoreDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoreDetailsQuery, StoreDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoreDetailsQuery, StoreDetailsQueryVariables>(StoreDetailsDocument, options);
        }
export type StoreDetailsQueryHookResult = ReturnType<typeof useStoreDetailsQuery>;
export type StoreDetailsLazyQueryHookResult = ReturnType<typeof useStoreDetailsLazyQuery>;
export type StoreDetailsQueryResult = Apollo.QueryResult<StoreDetailsQuery, StoreDetailsQueryVariables>;
export const GetStoreTypesDocument = gql`
    query GetStoreTypes {
  storeTypes {
    id
    code
    name
    isMain
    isWeb
  }
}
    `;

/**
 * __useGetStoreTypesQuery__
 *
 * To run a query within a React component, call `useGetStoreTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStoreTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetStoreTypesQuery, GetStoreTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoreTypesQuery, GetStoreTypesQueryVariables>(GetStoreTypesDocument, options);
      }
export function useGetStoreTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoreTypesQuery, GetStoreTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoreTypesQuery, GetStoreTypesQueryVariables>(GetStoreTypesDocument, options);
        }
export type GetStoreTypesQueryHookResult = ReturnType<typeof useGetStoreTypesQuery>;
export type GetStoreTypesLazyQueryHookResult = ReturnType<typeof useGetStoreTypesLazyQuery>;
export type GetStoreTypesQueryResult = Apollo.QueryResult<GetStoreTypesQuery, GetStoreTypesQueryVariables>;
export const GetStoreFleetSummaryDocument = gql`
    query GetStoreFleetSummary($universeId: Int!, $seasonId: Int!) {
  storeFleetSummary(universeId: $universeId, seasonId: $seasonId) {
    storePerformanceTable {
      storePerformance
      storePerformanceStoreCount
      percentageOfTotal
    }
  }
}
    `;

/**
 * __useGetStoreFleetSummaryQuery__
 *
 * To run a query within a React component, call `useGetStoreFleetSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreFleetSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreFleetSummaryQuery({
 *   variables: {
 *      universeId: // value for 'universeId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useGetStoreFleetSummaryQuery(baseOptions: Apollo.QueryHookOptions<GetStoreFleetSummaryQuery, GetStoreFleetSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoreFleetSummaryQuery, GetStoreFleetSummaryQueryVariables>(GetStoreFleetSummaryDocument, options);
      }
export function useGetStoreFleetSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoreFleetSummaryQuery, GetStoreFleetSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoreFleetSummaryQuery, GetStoreFleetSummaryQueryVariables>(GetStoreFleetSummaryDocument, options);
        }
export type GetStoreFleetSummaryQueryHookResult = ReturnType<typeof useGetStoreFleetSummaryQuery>;
export type GetStoreFleetSummaryLazyQueryHookResult = ReturnType<typeof useGetStoreFleetSummaryLazyQuery>;
export type GetStoreFleetSummaryQueryResult = Apollo.QueryResult<GetStoreFleetSummaryQuery, GetStoreFleetSummaryQueryVariables>;
export const GetTargetTurnoverTableDocument = gql`
    query GetTargetTurnoverTable($universeId: Int!, $seasonId: Int!) {
  storeFleetSummary(universeId: $universeId, seasonId: $seasonId) {
    targetTurnoverTable {
      collectionName
      storeGroups {
        groupName
        numberOfStores
        storesTargetTurnover
      }
    }
  }
}
    `;

/**
 * __useGetTargetTurnoverTableQuery__
 *
 * To run a query within a React component, call `useGetTargetTurnoverTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTargetTurnoverTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTargetTurnoverTableQuery({
 *   variables: {
 *      universeId: // value for 'universeId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useGetTargetTurnoverTableQuery(baseOptions: Apollo.QueryHookOptions<GetTargetTurnoverTableQuery, GetTargetTurnoverTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTargetTurnoverTableQuery, GetTargetTurnoverTableQueryVariables>(GetTargetTurnoverTableDocument, options);
      }
export function useGetTargetTurnoverTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTargetTurnoverTableQuery, GetTargetTurnoverTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTargetTurnoverTableQuery, GetTargetTurnoverTableQueryVariables>(GetTargetTurnoverTableDocument, options);
        }
export type GetTargetTurnoverTableQueryHookResult = ReturnType<typeof useGetTargetTurnoverTableQuery>;
export type GetTargetTurnoverTableLazyQueryHookResult = ReturnType<typeof useGetTargetTurnoverTableLazyQuery>;
export type GetTargetTurnoverTableQueryResult = Apollo.QueryResult<GetTargetTurnoverTableQuery, GetTargetTurnoverTableQueryVariables>;
export const GetExceptionOptimalStockTableDocument = gql`
    query GetExceptionOptimalStockTable($productId: Int!, $salesEnum: ExceptionOptimalStockSalesEnum!, $stockEnum: ExceptionOptimalStockStockEnum!, $hasException: Boolean!) {
  exceptionOptimalStockTable(
    productId: $productId
    salesEnum: $salesEnum
    stockEnum: $stockEnum
    hasException: $hasException
  ) {
    sizeSystemHeaders {
      articleId
      headerName
      headerId
    }
    exceptionOptimalStock {
      storeId
      storeCode
      storeName
      storeGroupName
      storeStrength
      storeCustomField1
      storeCustomField2
      storeCustomField3
      storeCustomField4
      storeCustomField5
      isIncluded
      hasExceptions
      leftTable {
        headerId
        value
      }
      rightTable {
        headerId
        value
        isOverriden
      }
    }
    numberOfWeeksInSale
  }
}
    `;

/**
 * __useGetExceptionOptimalStockTableQuery__
 *
 * To run a query within a React component, call `useGetExceptionOptimalStockTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExceptionOptimalStockTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExceptionOptimalStockTableQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      salesEnum: // value for 'salesEnum'
 *      stockEnum: // value for 'stockEnum'
 *      hasException: // value for 'hasException'
 *   },
 * });
 */
export function useGetExceptionOptimalStockTableQuery(baseOptions: Apollo.QueryHookOptions<GetExceptionOptimalStockTableQuery, GetExceptionOptimalStockTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExceptionOptimalStockTableQuery, GetExceptionOptimalStockTableQueryVariables>(GetExceptionOptimalStockTableDocument, options);
      }
export function useGetExceptionOptimalStockTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExceptionOptimalStockTableQuery, GetExceptionOptimalStockTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExceptionOptimalStockTableQuery, GetExceptionOptimalStockTableQueryVariables>(GetExceptionOptimalStockTableDocument, options);
        }
export type GetExceptionOptimalStockTableQueryHookResult = ReturnType<typeof useGetExceptionOptimalStockTableQuery>;
export type GetExceptionOptimalStockTableLazyQueryHookResult = ReturnType<typeof useGetExceptionOptimalStockTableLazyQuery>;
export type GetExceptionOptimalStockTableQueryResult = Apollo.QueryResult<GetExceptionOptimalStockTableQuery, GetExceptionOptimalStockTableQueryVariables>;
export const GetStoreOptimalStockExceptionsDocument = gql`
    query GetStoreOptimalStockExceptions($productId: Int!, $isIncluded: Boolean) {
  storeOptimalStockExceptions(productId: $productId, isIncluded: $isIncluded) {
    storeId
    storeName
    storeCode
    storeStrength
    storeGroupId
    storeGroupName
  }
}
    `;

/**
 * __useGetStoreOptimalStockExceptionsQuery__
 *
 * To run a query within a React component, call `useGetStoreOptimalStockExceptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreOptimalStockExceptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreOptimalStockExceptionsQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      isIncluded: // value for 'isIncluded'
 *   },
 * });
 */
export function useGetStoreOptimalStockExceptionsQuery(baseOptions: Apollo.QueryHookOptions<GetStoreOptimalStockExceptionsQuery, GetStoreOptimalStockExceptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoreOptimalStockExceptionsQuery, GetStoreOptimalStockExceptionsQueryVariables>(GetStoreOptimalStockExceptionsDocument, options);
      }
export function useGetStoreOptimalStockExceptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoreOptimalStockExceptionsQuery, GetStoreOptimalStockExceptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoreOptimalStockExceptionsQuery, GetStoreOptimalStockExceptionsQueryVariables>(GetStoreOptimalStockExceptionsDocument, options);
        }
export type GetStoreOptimalStockExceptionsQueryHookResult = ReturnType<typeof useGetStoreOptimalStockExceptionsQuery>;
export type GetStoreOptimalStockExceptionsLazyQueryHookResult = ReturnType<typeof useGetStoreOptimalStockExceptionsLazyQuery>;
export type GetStoreOptimalStockExceptionsQueryResult = Apollo.QueryResult<GetStoreOptimalStockExceptionsQuery, GetStoreOptimalStockExceptionsQueryVariables>;
export const GetStorePerformanceDocument = gql`
    query GetStorePerformance($universeId: Int!, $seasonId: Int!) {
  storePerformance(universeId: $universeId, seasonId: $seasonId) {
    id
    code
    name
    permutationFieldId1
    permutationFieldId2
    permutationLabel1
    permutationLabel2
    customField1
    customField2
    customField3
    customField4
    customField5
    numberOfProductsWithStock
    numberOfProductsWithOptimalStock
    numberOfProductsWithSalesLastWeek
    salesQuantityLastWeek
    stockQuantityLastWeek
    coverageOneWeekBefore
    coverageTwoWeeksBefore
    coverageThreeWeeksBefore
    averageCoverage
    capacity
    capacityRatio
    storeStrengthPerPermutationHistory
    storeStrengthPerPermutationImplemented
    storeStrengthPerPermutationProposed
    storeStrengthPerPermutationValidated
    optimalStock
    optimalStockOneWeekBefore
    estimatedPicking
    storeToStoreGroups {
      collectionId
      storeGroupId
      storeGroupName
    }
  }
}
    `;

/**
 * __useGetStorePerformanceQuery__
 *
 * To run a query within a React component, call `useGetStorePerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStorePerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStorePerformanceQuery({
 *   variables: {
 *      universeId: // value for 'universeId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useGetStorePerformanceQuery(baseOptions: Apollo.QueryHookOptions<GetStorePerformanceQuery, GetStorePerformanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStorePerformanceQuery, GetStorePerformanceQueryVariables>(GetStorePerformanceDocument, options);
      }
export function useGetStorePerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStorePerformanceQuery, GetStorePerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStorePerformanceQuery, GetStorePerformanceQueryVariables>(GetStorePerformanceDocument, options);
        }
export type GetStorePerformanceQueryHookResult = ReturnType<typeof useGetStorePerformanceQuery>;
export type GetStorePerformanceLazyQueryHookResult = ReturnType<typeof useGetStorePerformanceLazyQuery>;
export type GetStorePerformanceQueryResult = Apollo.QueryResult<GetStorePerformanceQuery, GetStorePerformanceQueryVariables>;
export const RetailWarehouseStockDocument = gql`
    query RetailWarehouseStock($productId: Int!) {
  retailWarehouseStock(productId: $productId) {
    articleId
    headerId
    headerName
    warehouseStockQuantity
  }
}
    `;

/**
 * __useRetailWarehouseStockQuery__
 *
 * To run a query within a React component, call `useRetailWarehouseStockQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetailWarehouseStockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetailWarehouseStockQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useRetailWarehouseStockQuery(baseOptions: Apollo.QueryHookOptions<RetailWarehouseStockQuery, RetailWarehouseStockQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RetailWarehouseStockQuery, RetailWarehouseStockQueryVariables>(RetailWarehouseStockDocument, options);
      }
export function useRetailWarehouseStockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RetailWarehouseStockQuery, RetailWarehouseStockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RetailWarehouseStockQuery, RetailWarehouseStockQueryVariables>(RetailWarehouseStockDocument, options);
        }
export type RetailWarehouseStockQueryHookResult = ReturnType<typeof useRetailWarehouseStockQuery>;
export type RetailWarehouseStockLazyQueryHookResult = ReturnType<typeof useRetailWarehouseStockLazyQuery>;
export type RetailWarehouseStockQueryResult = Apollo.QueryResult<RetailWarehouseStockQuery, RetailWarehouseStockQueryVariables>;
export const StorePerformanceStoreSummaryDocument = gql`
    query StorePerformanceStoreSummary($universeId: Int!, $seasonId: Int!) {
  storePerformanceStoreSummary(universeId: $universeId, seasonId: $seasonId) {
    id
    salesQuantityLastWeek
    stockQuantityLastWeek
    coverageOneWeekBefore
    coverageTwoWeeksBefore
    coverageThreeWeeksBefore
    averageCoverage
    capacity
    capacityRatio
    optimalStock
    optimalStockOneWeekBefore
    code
    estimatedPicking
    storeToStoreGroups {
      collectionId
      storeGroupId
      storeGroupName
    }
    name
    customField1
    customField2
    customField3
    customField4
    customField5
    numberOfProductsWithStock
    numberOfProductsWithOptimalStock
    numberOfProductsWithSalesLastWeek
  }
}
    `;

/**
 * __useStorePerformanceStoreSummaryQuery__
 *
 * To run a query within a React component, call `useStorePerformanceStoreSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorePerformanceStoreSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorePerformanceStoreSummaryQuery({
 *   variables: {
 *      universeId: // value for 'universeId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useStorePerformanceStoreSummaryQuery(baseOptions: Apollo.QueryHookOptions<StorePerformanceStoreSummaryQuery, StorePerformanceStoreSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StorePerformanceStoreSummaryQuery, StorePerformanceStoreSummaryQueryVariables>(StorePerformanceStoreSummaryDocument, options);
      }
export function useStorePerformanceStoreSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StorePerformanceStoreSummaryQuery, StorePerformanceStoreSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StorePerformanceStoreSummaryQuery, StorePerformanceStoreSummaryQueryVariables>(StorePerformanceStoreSummaryDocument, options);
        }
export type StorePerformanceStoreSummaryQueryHookResult = ReturnType<typeof useStorePerformanceStoreSummaryQuery>;
export type StorePerformanceStoreSummaryLazyQueryHookResult = ReturnType<typeof useStorePerformanceStoreSummaryLazyQuery>;
export type StorePerformanceStoreSummaryQueryResult = Apollo.QueryResult<StorePerformanceStoreSummaryQuery, StorePerformanceStoreSummaryQueryVariables>;
export const GetSeasonsDocument = gql`
    query GetSeasons {
  seasons {
    id
    code
    name
    isActive
  }
}
    `;

/**
 * __useGetSeasonsQuery__
 *
 * To run a query within a React component, call `useGetSeasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSeasonsQuery(baseOptions?: Apollo.QueryHookOptions<GetSeasonsQuery, GetSeasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSeasonsQuery, GetSeasonsQueryVariables>(GetSeasonsDocument, options);
      }
export function useGetSeasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSeasonsQuery, GetSeasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSeasonsQuery, GetSeasonsQueryVariables>(GetSeasonsDocument, options);
        }
export type GetSeasonsQueryHookResult = ReturnType<typeof useGetSeasonsQuery>;
export type GetSeasonsLazyQueryHookResult = ReturnType<typeof useGetSeasonsLazyQuery>;
export type GetSeasonsQueryResult = Apollo.QueryResult<GetSeasonsQuery, GetSeasonsQueryVariables>;
export const GetTenantSettingsDocument = gql`
    query GetTenantSettings {
  tenantSettings {
    id
    productCoefficient
    productCoverage
    storeCoefficient
    productMaxStrength
    storeAverageStrength
    storeCapacityType
    storeMaxStrength
    storeNumberOfWeeksStrength
    isErpMasterOfCreationAndModification
  }
}
    `;

/**
 * __useGetTenantSettingsQuery__
 *
 * To run a query within a React component, call `useGetTenantSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenantSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetTenantSettingsQuery, GetTenantSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTenantSettingsQuery, GetTenantSettingsQueryVariables>(GetTenantSettingsDocument, options);
      }
export function useGetTenantSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTenantSettingsQuery, GetTenantSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTenantSettingsQuery, GetTenantSettingsQueryVariables>(GetTenantSettingsDocument, options);
        }
export type GetTenantSettingsQueryHookResult = ReturnType<typeof useGetTenantSettingsQuery>;
export type GetTenantSettingsLazyQueryHookResult = ReturnType<typeof useGetTenantSettingsLazyQuery>;
export type GetTenantSettingsQueryResult = Apollo.QueryResult<GetTenantSettingsQuery, GetTenantSettingsQueryVariables>;
export const GetIsErpMasterDocument = gql`
    query GetIsERPMaster {
  tenantSettings {
    isErpMasterOfCreationAndModification
  }
}
    `;

/**
 * __useGetIsErpMasterQuery__
 *
 * To run a query within a React component, call `useGetIsErpMasterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIsErpMasterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsErpMasterQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIsErpMasterQuery(baseOptions?: Apollo.QueryHookOptions<GetIsErpMasterQuery, GetIsErpMasterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIsErpMasterQuery, GetIsErpMasterQueryVariables>(GetIsErpMasterDocument, options);
      }
export function useGetIsErpMasterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIsErpMasterQuery, GetIsErpMasterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIsErpMasterQuery, GetIsErpMasterQueryVariables>(GetIsErpMasterDocument, options);
        }
export type GetIsErpMasterQueryHookResult = ReturnType<typeof useGetIsErpMasterQuery>;
export type GetIsErpMasterLazyQueryHookResult = ReturnType<typeof useGetIsErpMasterLazyQuery>;
export type GetIsErpMasterQueryResult = Apollo.QueryResult<GetIsErpMasterQuery, GetIsErpMasterQueryVariables>;
export const GetCapacityTypeDocument = gql`
    query GetCapacityType {
  tenantSettings {
    storeCapacityType
  }
}
    `;

/**
 * __useGetCapacityTypeQuery__
 *
 * To run a query within a React component, call `useGetCapacityTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCapacityTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCapacityTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCapacityTypeQuery(baseOptions?: Apollo.QueryHookOptions<GetCapacityTypeQuery, GetCapacityTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCapacityTypeQuery, GetCapacityTypeQueryVariables>(GetCapacityTypeDocument, options);
      }
export function useGetCapacityTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCapacityTypeQuery, GetCapacityTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCapacityTypeQuery, GetCapacityTypeQueryVariables>(GetCapacityTypeDocument, options);
        }
export type GetCapacityTypeQueryHookResult = ReturnType<typeof useGetCapacityTypeQuery>;
export type GetCapacityTypeLazyQueryHookResult = ReturnType<typeof useGetCapacityTypeLazyQuery>;
export type GetCapacityTypeQueryResult = Apollo.QueryResult<GetCapacityTypeQuery, GetCapacityTypeQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers {
  users {
    id
    email
    username
    userRoleId
    jobDescription
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const UserRolesDocument = gql`
    query UserRoles {
  userRoles {
    id
    name
  }
}
    `;

/**
 * __useUserRolesQuery__
 *
 * To run a query within a React component, call `useUserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserRolesQuery(baseOptions?: Apollo.QueryHookOptions<UserRolesQuery, UserRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserRolesQuery, UserRolesQueryVariables>(UserRolesDocument, options);
      }
export function useUserRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserRolesQuery, UserRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserRolesQuery, UserRolesQueryVariables>(UserRolesDocument, options);
        }
export type UserRolesQueryHookResult = ReturnType<typeof useUserRolesQuery>;
export type UserRolesLazyQueryHookResult = ReturnType<typeof useUserRolesLazyQuery>;
export type UserRolesQueryResult = Apollo.QueryResult<UserRolesQuery, UserRolesQueryVariables>;
export const GetOptimalStockSimulationDocument = gql`
    query GetOptimalStockSimulation($universeId: Int!, $seasonId: Int, $skip: Int, $take: Int, $customField1: [String], $customField2: [String], $customField3: [String], $customField4: [String], $customField5: [String], $sortType: OptimalStockSimulationField, $filters: [OptimalStockSimulationFilterDtoInput!], $isAsc: Boolean, $permutations: [String]) {
  optimalStockSimulation(
    universeId: $universeId
    seasonId: $seasonId
    skip: $skip
    take: $take
    customField1: $customField1
    customField2: $customField2
    customField3: $customField3
    customField4: $customField4
    customField5: $customField5
    permutations: $permutations
    isAsc: $isAsc
    sortType: $sortType
    filters: $filters
  ) {
    items {
      id
      storeCode
      storeName
      productCode
      productName
      colorCode
      colorName
      permutationField1
      permutationField2
      sizeHeader
      barcode
      familyName
      storeCustomField1
      storeCustomField2
      storeCustomField3
      storeCustomField4
      storeCustomField5
      productCustomField1
      productCustomField2
      productCustomField3
      productCustomField4
      productCustomField5
      storeIsSynced
      productIsSynced
      optimalStock
      estimationPicking
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

/**
 * __useGetOptimalStockSimulationQuery__
 *
 * To run a query within a React component, call `useGetOptimalStockSimulationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOptimalStockSimulationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOptimalStockSimulationQuery({
 *   variables: {
 *      universeId: // value for 'universeId'
 *      seasonId: // value for 'seasonId'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      customField1: // value for 'customField1'
 *      customField2: // value for 'customField2'
 *      customField3: // value for 'customField3'
 *      customField4: // value for 'customField4'
 *      customField5: // value for 'customField5'
 *      sortType: // value for 'sortType'
 *      filters: // value for 'filters'
 *      isAsc: // value for 'isAsc'
 *      permutations: // value for 'permutations'
 *   },
 * });
 */
export function useGetOptimalStockSimulationQuery(baseOptions: Apollo.QueryHookOptions<GetOptimalStockSimulationQuery, GetOptimalStockSimulationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOptimalStockSimulationQuery, GetOptimalStockSimulationQueryVariables>(GetOptimalStockSimulationDocument, options);
      }
export function useGetOptimalStockSimulationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOptimalStockSimulationQuery, GetOptimalStockSimulationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOptimalStockSimulationQuery, GetOptimalStockSimulationQueryVariables>(GetOptimalStockSimulationDocument, options);
        }
export type GetOptimalStockSimulationQueryHookResult = ReturnType<typeof useGetOptimalStockSimulationQuery>;
export type GetOptimalStockSimulationLazyQueryHookResult = ReturnType<typeof useGetOptimalStockSimulationLazyQuery>;
export type GetOptimalStockSimulationQueryResult = Apollo.QueryResult<GetOptimalStockSimulationQuery, GetOptimalStockSimulationQueryVariables>;
export const GetStorePerformancePermutationSummaryDocument = gql`
    query GetStorePerformancePermutationSummary($universeId: Int!, $seasonId: Int!) {
  storePerformancePermutationSummary(universeId: $universeId, seasonId: $seasonId) {
    permutationField1
    permutationFieldLabel1
    optimalStock
    optimalStockOneWeekBefore
    estimatedPicking
    permutationField2
    permutationFieldLabel2
    numberOfProductsWithStock
    numberOfProductsWithOptimalStock
    numberOfProductsWithOptimalStock
    numberOfProductsWithSalesLastWeek
    salesQuantityLastWeek
    stockQuantityLastWeek
    coverageOneWeekBefore
    coverageTwoWeeksBefore
    coverageThreeWeeksBefore
    averageCoverage
  }
}
    `;

/**
 * __useGetStorePerformancePermutationSummaryQuery__
 *
 * To run a query within a React component, call `useGetStorePerformancePermutationSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStorePerformancePermutationSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStorePerformancePermutationSummaryQuery({
 *   variables: {
 *      universeId: // value for 'universeId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useGetStorePerformancePermutationSummaryQuery(baseOptions: Apollo.QueryHookOptions<GetStorePerformancePermutationSummaryQuery, GetStorePerformancePermutationSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStorePerformancePermutationSummaryQuery, GetStorePerformancePermutationSummaryQueryVariables>(GetStorePerformancePermutationSummaryDocument, options);
      }
export function useGetStorePerformancePermutationSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStorePerformancePermutationSummaryQuery, GetStorePerformancePermutationSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStorePerformancePermutationSummaryQuery, GetStorePerformancePermutationSummaryQueryVariables>(GetStorePerformancePermutationSummaryDocument, options);
        }
export type GetStorePerformancePermutationSummaryQueryHookResult = ReturnType<typeof useGetStorePerformancePermutationSummaryQuery>;
export type GetStorePerformancePermutationSummaryLazyQueryHookResult = ReturnType<typeof useGetStorePerformancePermutationSummaryLazyQuery>;
export type GetStorePerformancePermutationSummaryQueryResult = Apollo.QueryResult<GetStorePerformancePermutationSummaryQuery, GetStorePerformancePermutationSummaryQueryVariables>;
export const GetUserSettingsDocument = gql`
    query GetUserSettings {
  userSettings {
    userSettings
    permissions
  }
}
    `;

/**
 * __useGetUserSettingsQuery__
 *
 * To run a query within a React component, call `useGetUserSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserSettingsQuery, GetUserSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserSettingsQuery, GetUserSettingsQueryVariables>(GetUserSettingsDocument, options);
      }
export function useGetUserSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserSettingsQuery, GetUserSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserSettingsQuery, GetUserSettingsQueryVariables>(GetUserSettingsDocument, options);
        }
export type GetUserSettingsQueryHookResult = ReturnType<typeof useGetUserSettingsQuery>;
export type GetUserSettingsLazyQueryHookResult = ReturnType<typeof useGetUserSettingsLazyQuery>;
export type GetUserSettingsQueryResult = Apollo.QueryResult<GetUserSettingsQuery, GetUserSettingsQueryVariables>;
export const GetPossiblePermutationsDocument = gql`
    query GetPossiblePermutations {
  possiblePermutations
}
    `;

/**
 * __useGetPossiblePermutationsQuery__
 *
 * To run a query within a React component, call `useGetPossiblePermutationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPossiblePermutationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPossiblePermutationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPossiblePermutationsQuery(baseOptions?: Apollo.QueryHookOptions<GetPossiblePermutationsQuery, GetPossiblePermutationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPossiblePermutationsQuery, GetPossiblePermutationsQueryVariables>(GetPossiblePermutationsDocument, options);
      }
export function useGetPossiblePermutationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPossiblePermutationsQuery, GetPossiblePermutationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPossiblePermutationsQuery, GetPossiblePermutationsQueryVariables>(GetPossiblePermutationsDocument, options);
        }
export type GetPossiblePermutationsQueryHookResult = ReturnType<typeof useGetPossiblePermutationsQuery>;
export type GetPossiblePermutationsLazyQueryHookResult = ReturnType<typeof useGetPossiblePermutationsLazyQuery>;
export type GetPossiblePermutationsQueryResult = Apollo.QueryResult<GetPossiblePermutationsQuery, GetPossiblePermutationsQueryVariables>;
export const GetAuditableInfoDocument = gql`
    query GetAuditableInfo($objectType: AuditableObjectType, $dateRangeStart: DateTime, $dateRangeEnd: DateTime, $seasonCode: String, $skip: Int, $take: Int) {
  auditableInfos(
    objectType: $objectType
    dateRangeStart: $dateRangeStart
    dateRangeEnd: $dateRangeEnd
    seasonCode: $seasonCode
    skip: $skip
    take: $take
  ) {
    items {
      timestamp
      user
      code
      action
      object
      property
      level
      oldValue
      newValue
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;

/**
 * __useGetAuditableInfoQuery__
 *
 * To run a query within a React component, call `useGetAuditableInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuditableInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuditableInfoQuery({
 *   variables: {
 *      objectType: // value for 'objectType'
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *      seasonCode: // value for 'seasonCode'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetAuditableInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetAuditableInfoQuery, GetAuditableInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuditableInfoQuery, GetAuditableInfoQueryVariables>(GetAuditableInfoDocument, options);
      }
export function useGetAuditableInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuditableInfoQuery, GetAuditableInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuditableInfoQuery, GetAuditableInfoQueryVariables>(GetAuditableInfoDocument, options);
        }
export type GetAuditableInfoQueryHookResult = ReturnType<typeof useGetAuditableInfoQuery>;
export type GetAuditableInfoLazyQueryHookResult = ReturnType<typeof useGetAuditableInfoLazyQuery>;
export type GetAuditableInfoQueryResult = Apollo.QueryResult<GetAuditableInfoQuery, GetAuditableInfoQueryVariables>;
export const GetFileImportLogsDocument = gql`
    query GetFileImportLogs {
  fileImportLogs {
    id
    importDate
    object
    errorsCount
    isSuccessful
  }
}
    `;

/**
 * __useGetFileImportLogsQuery__
 *
 * To run a query within a React component, call `useGetFileImportLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileImportLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileImportLogsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFileImportLogsQuery(baseOptions?: Apollo.QueryHookOptions<GetFileImportLogsQuery, GetFileImportLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFileImportLogsQuery, GetFileImportLogsQueryVariables>(GetFileImportLogsDocument, options);
      }
export function useGetFileImportLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFileImportLogsQuery, GetFileImportLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFileImportLogsQuery, GetFileImportLogsQueryVariables>(GetFileImportLogsDocument, options);
        }
export type GetFileImportLogsQueryHookResult = ReturnType<typeof useGetFileImportLogsQuery>;
export type GetFileImportLogsLazyQueryHookResult = ReturnType<typeof useGetFileImportLogsLazyQuery>;
export type GetFileImportLogsQueryResult = Apollo.QueryResult<GetFileImportLogsQuery, GetFileImportLogsQueryVariables>;
export const GetDashboardInfoDocument = gql`
    query GetDashboardInfo {
  dashboard {
    importLogs {
      id
      importDate
      object
      errorsCount
      isSuccessful
      errors
    }
    exportId
    exportDate
    newsletterTitle
    newsletterSubtitle
    newsletterBody
    newsletterImgUrl
  }
}
    `;

/**
 * __useGetDashboardInfoQuery__
 *
 * To run a query within a React component, call `useGetDashboardInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDashboardInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetDashboardInfoQuery, GetDashboardInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDashboardInfoQuery, GetDashboardInfoQueryVariables>(GetDashboardInfoDocument, options);
      }
export function useGetDashboardInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardInfoQuery, GetDashboardInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDashboardInfoQuery, GetDashboardInfoQueryVariables>(GetDashboardInfoDocument, options);
        }
export type GetDashboardInfoQueryHookResult = ReturnType<typeof useGetDashboardInfoQuery>;
export type GetDashboardInfoLazyQueryHookResult = ReturnType<typeof useGetDashboardInfoLazyQuery>;
export type GetDashboardInfoQueryResult = Apollo.QueryResult<GetDashboardInfoQuery, GetDashboardInfoQueryVariables>;
export const GetFileImportDetailsDocument = gql`
    query GetFileImportDetails($fileImportLogId: Int!) {
  fileImportLog(fileImportLogId: $fileImportLogId) {
    errors
  }
}
    `;

/**
 * __useGetFileImportDetailsQuery__
 *
 * To run a query within a React component, call `useGetFileImportDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileImportDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileImportDetailsQuery({
 *   variables: {
 *      fileImportLogId: // value for 'fileImportLogId'
 *   },
 * });
 */
export function useGetFileImportDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetFileImportDetailsQuery, GetFileImportDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFileImportDetailsQuery, GetFileImportDetailsQueryVariables>(GetFileImportDetailsDocument, options);
      }
export function useGetFileImportDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFileImportDetailsQuery, GetFileImportDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFileImportDetailsQuery, GetFileImportDetailsQueryVariables>(GetFileImportDetailsDocument, options);
        }
export type GetFileImportDetailsQueryHookResult = ReturnType<typeof useGetFileImportDetailsQuery>;
export type GetFileImportDetailsLazyQueryHookResult = ReturnType<typeof useGetFileImportDetailsLazyQuery>;
export type GetFileImportDetailsQueryResult = Apollo.QueryResult<GetFileImportDetailsQuery, GetFileImportDetailsQueryVariables>;