import styled from 'styled-components';

export const FiltersContainer = styled.div`
  display: inline-flex;
  border: 1px solid ${(p) => p.theme.light_gray};
  padding: 10px 15px;
`;

export const DatesWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-left: 30px;

  & p {
    margin: 0 10px;
  }
`;

export const WhiteText = styled.p`
  color: ${(p) => p.theme.white};
`;
