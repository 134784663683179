import { CellEditingStoppedEvent } from 'ag-grid-community';
import { cloneDeep } from 'lodash';
interface Props {
  data?: {
    columnId: string;
    newValue: any;
    oldValue: any;
    rowId: number;
  };
  event?: CellEditingStoppedEvent;
  initialRows: any[] | null | undefined;
  prevRows: any[];
}

export const partiallyEditedGridData = ({ event, data, prevRows, initialRows }: Props) => {
  if (!event && !data) return;

  const newValue = event ? event.newValue : data!.newValue;
  const oldValue = event ? event.oldValue : data!.oldValue;
  const columnId = event ? event.column.getColId() : data!.columnId;
  const rowId = event ? event.data.id : data!.rowId;

  if (newValue === oldValue) return;

  const initialCurrentRow = initialRows?.find((row) => row.id === rowId);

  let editedRows = cloneDeep(prevRows) || [];
  const editedRow = editedRows?.find((row) => row.id === rowId);

  if (newValue === initialCurrentRow?.[columnId]) {
    if (!editedRow) {
      return null;
    } else {
      if (columnId in editedRow) {
        delete editedRow[columnId];
      }

      if (Object.keys(editedRow).length < 2) {
        editedRows = editedRows?.filter((row) => row.id !== editedRow.id);
      }
    }
  } else {
    if (!editedRow) {
      editedRows?.push({ id: rowId, [columnId]: newValue });
    } else if (newValue !== editedRow[columnId]) {
      editedRow[columnId] = newValue;
    } else {
      return null;
    }
  }

  // console.log(prevRows, event);
  // console.log({ rowId, columnId, newValue });
  // console.log(editedRows);

  return editedRows;
};
