import React, { useState, useEffect, useContext } from 'react';

import {
  ControlOutlined,
  ApartmentOutlined,
  ImportOutlined,
  UsergroupAddOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import { PermissionsContext } from 'context';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { Sider } from 'components/UI';
import AllRoutes from 'screens/AllRoutes';
import { PermissionsEnum } from 'services/graphql/main';

import { StyledMenu } from './styles';

const SettingsSider: React.FC = () => {
  const { t } = useTranslation('translation');
  const location = useLocation();
  const { permissions } = useContext(PermissionsContext);

  const [collapsed, setCollapsed] = useState(false);
  const [currentSelected, setCurrentSelected] = useState(location.pathname);

  useEffect(() => {
    if (location) {
      if (currentSelected !== location.pathname) {
        setCurrentSelected(location.pathname);
      }
    }
  }, [location, currentSelected]);

  const items = [
    {
      key: AllRoutes.OperationalSettings,
      icon: <ApartmentOutlined />,
      label: t('operationalSettings.operational_settings'),
      title: '',
      children: [
        {
          key: AllRoutes.SizeSystemSettings,
          label: <Link to={AllRoutes.SizeSystemSettings}>{t('operationalSettings.size_system')}</Link>,
        },
        {
          key: AllRoutes.CollectionsManagementSettings,
          label: (
            <Link to={AllRoutes.CollectionsManagementSettings}>{t('operationalSettings.collection_management')}</Link>
          ),
        },
        {
          key: AllRoutes.TableViewsManagementSettings,
          label: (
            <Link to={AllRoutes.TableViewsManagementSettings}>{t('operationalSettings.table_views_management')}</Link>
          ),
        },
      ],
    },
    (permissions as PermissionsEnum[])?.includes(PermissionsEnum.SettingsGeneralSettingsView)
      ? {
          key: AllRoutes.GeneralSettings,
          icon: <ControlOutlined />,
          label: <Link to={AllRoutes.GeneralSettings}>{t('generalSettings.general_settings')}</Link>,
          title: '',
        }
      : null,
    {
      key: AllRoutes.ImportSettings,
      icon: <ImportOutlined />,
      label: <Link to={AllRoutes.ImportSettings}>{t('importSettings.import_settings')}</Link>,
      title: '',
    },
    (permissions as PermissionsEnum[])?.includes(PermissionsEnum.SettingsUserManagementView)
      ? {
          key: AllRoutes.UserManagementSettings,
          icon: <UsergroupAddOutlined />,
          label: <Link to={AllRoutes.UserManagementSettings}>{t('userManagement.user_management')}</Link>,
          title: '',
        }
      : null,
    {
      key: AllRoutes.ExportSettings,
      icon: <ExportOutlined />,
      label: <Link to={AllRoutes.ExportSettings}>{t('exportSettings.export_settings')}</Link>,
      title: '',
    },
  ];

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      style={{ paddingTop: 0 }}
      onCollapse={(value) => setCollapsed(value)}
      width={300}
    >
      <StyledMenu
        mode="inline"
        defaultOpenKeys={[AllRoutes.OperationalSettings]}
        items={items}
        selectedKeys={[currentSelected]}
        openKeys={collapsed ? [] : undefined}
        $isCollapsed={collapsed}
      />
    </Sider>
  );
};

export default SettingsSider;
