import { Divider, Button } from 'antd';
import styled from 'styled-components';

export const StyledBackButton = styled(Button)`
  position: fixed;
  right: 10px;
  top: 85px;
  background-color: #fff;
  z-index: 999;
`;

export const StyledDivider = styled(Divider)`
  background-color: ${(p) => p.theme.light_gray};
  height: 2px;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Label = styled.span`
  color: ${(p) => p.theme.white};
  text-decoration: none;
  font-size: 12px;
  align-items: center;
`;
