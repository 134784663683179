import jwtDecode from 'jwt-decode';
import { JwtPayload } from 'jwt-decode/index';
import moment from 'moment';

import envconfig from 'config/envconfig';
import AllRoutes from 'screens/AllRoutes';
import { getCacheVal, setCacheVal, removeCacheVal } from 'services/utils';

export const logout = () => {
  removeCacheVal('token');
  removeCacheVal('refreshToken');
  window.location.pathname !== AllRoutes.Unauthorized && window.location.replace(AllRoutes.Unauthorized);
};

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded: JwtPayload = jwtDecode(accessToken);
  const currentDate = new Date();

  // Logging user info for debugging purposes
  const { aud, exp, iat, iss, sub, ...tenantInfo } = decoded;
  console.log('User: ', tenantInfo);

  return decoded.exp! * 1000 > currentDate.getTime();
};

const refreshTokenReq = async (refreshToken: string) => {
  const formData = new FormData();
  formData.append('refreshToken', refreshToken);
  const requestOptions = {
    method: 'POST',
    body: formData,
  };

  const response = await fetch(envconfig.authenticationUrl + '/refreshToken', requestOptions);
  if (!response.ok) {
    return null;
  }
  const data = await response.json();

  return data;
};

export const isTokenCloseToExpiration = async (currentAccessToken: string) => {
  const currentRefreshToken = getCacheVal('refreshToken');
  const currentTokenExpirationTime = (jwtDecode(currentAccessToken) as JwtPayload).exp!;
  const currentTimeInSeconds = moment(Math.floor(Date.now() / 1000));
  const tokenExpirationTimeInSeconds = currentTokenExpirationTime - Number(currentTimeInSeconds);
  const isRefreshTokenCalled = getCacheVal('refreshTokenCalled');

  if (
    currentTokenExpirationTime &&
    tokenExpirationTimeInSeconds < 5 &&
    currentRefreshToken &&
    isRefreshTokenCalled !== '1'
  ) {
    setCacheVal('refreshTokenCalled', '1');
    const res = await refreshTokenReq(currentRefreshToken);
    if (res) {
      setCacheVal('refreshToken', res.refreshToken);
      setCacheVal('token', res.accessToken);
      setCacheVal('refreshTokenCalled', '0');
    } else {
      setCacheVal('refreshTokenCalled', '0');
      logout();
    }
  }
};
