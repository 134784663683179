export default {
  Dashboard: '/',
  Login: '/login',
  AuthorizationCallback: '/authorization-callback',
  Unauthorized: '/unauthorized',
  Settings: '/settings',
  OperationalSettings: '/settings/operational-settings',
  SizeSystemSettings: '/settings/operational-settings/size-system',
  ProductPerformanceSettings: '/settings/operational-settings/product-performance',
  CollectionsManagementSettings: '/settings/operational-settings/collections-management',
  TableViewsManagementSettings: '/settings/operational-settings/table-views-management',
  GeneralSettings: '/settings/general-settings',
  ImportSettings: '/settings/import-settings',
  ExportSettings: '/settings/export-settings',
  UserManagementSettings: '/settings/user-management',
  StoreFleet: '/store-fleet',
  StorePerformance: '/store-performance',
  ProductPerformance: '/product-performance',
  Warehouses: '/settings/operational-settings/table-views-management/warehouses',
  ProductDetails: '/product-performance/products/:id',
  Logging: '/logging',
};
