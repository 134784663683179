import styled from 'styled-components';

import { Tabs } from 'components/UI';

export const SecondaryStyledTabs = styled(Tabs)`
  margin-top: 10px;

  .ant-tabs-nav:before,
  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-nav {
    margin: 0 !important;
  }

  .ant-tabs-tab-btn {
    font-size: 14px !important;
    color: ${(p) => p.theme.white};
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #ffcf00 !important;
      font-size: 14px !important;
    }
  }

  .ant-tabs-tab {
    color: #808080 !important;
    font-size: 14px !important;
  }

  .ant-tabs-ink-bar {
    background-color: #ffcf00;
  }
`;
