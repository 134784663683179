import React, { useCallback, useContext, useEffect, useState } from 'react';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import {
  ProductContext,
  SeasonContext,
  SizeSystemContextProvider,
  StoreManagementContext,
  UniverseContext,
} from 'context';
import { t } from 'i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from 'components/UI';
import { useProductIds } from 'hooks/useProductIds';
import AllRoutes from 'screens/AllRoutes';
import { useProductIdParam } from 'screens/ProductDetails/common/hooks';
import {
  useGetProductPerformanceIdsLazyQuery,
  useProductSeasonArchivedDetailsQuery,
  ExceptionOptimalStockDto,
} from 'services/graphql/main';
import { useError } from 'services/utils';

import DetailStockContent from './components/DetailStockContent/DetailStockContent';
import ExceptionStockContent from './components/ExceptionStockContent/ExceptionStockContent';
import GeneralInformation from './components/GeneralInformation/GeneralInformation';
import ProductDetailsSider from './components/ProductDetailsSider/ProductDetailsSider';
import { StyledContainer } from './components/ProductDetailsSider/styles';
import { FloatingNavButtonsWrapper, StyledContent, StyledTabs, ExceptionStockLabelWrapper } from './styles';

const ProductDetails: React.FC = () => {
  const { addError } = useError();

  const { productIds } = useProductIds();
  const { productId } = useProductIdParam();
  const { clearContext } = useContext(ProductContext);
  const { selectedUniverse } = useContext(UniverseContext);
  const { selectedSeason } = useContext(SeasonContext);
  const { dataExceptionOptimalStockTable } = useContext(StoreManagementContext);
  const seasonId = selectedSeason?.id || 0;
  const universeId = selectedUniverse?.id;
  const { hash } = useLocation();
  const navigate = useNavigate();

  const [getProductPerformanceIdsLazyQuery] = useGetProductPerformanceIdsLazyQuery({
    variables: { seasonId, universeId },
    onError: (err) => addError(err, 'warning'),
  });
  const { data: productSeasonArchivedDetailsData, refetch: refetchProductSeasonArchivedDetails } =
    useProductSeasonArchivedDetailsQuery({
      variables: { productId: productId || 0 },
      onError: (err) => addError(err, 'error'),
    });

  const [activeTab, setActiveTab] = useState('detail-stock');
  const [siblingProductIds, setSiblingProductIds] = useState<{ next: string | null; previous: string | null } | null>(
    null,
  );
  const [isProductArchived, setIsProductArchived] = useState(false);
  const [productSeasonId, setProductSeasonId] = useState<number>();
  const [exceptionStoresNumber, setExceptionStoresNumber] = useState<number>();

  useEffect(() => {
    setExceptionStoresNumber(
      dataExceptionOptimalStockTable?.exceptionOptimalStockTable?.exceptionOptimalStock?.filter(
        (store: ExceptionOptimalStockDto) => store?.hasExceptions,
      ).length || 0,
    );
  }, [dataExceptionOptimalStockTable]);

  useEffect(() => {
    if (productSeasonArchivedDetailsData) {
      setIsProductArchived(productSeasonArchivedDetailsData.productDetails.isArchived);
      setProductSeasonId(productSeasonArchivedDetailsData.productDetails.seasonId);
    }
  }, [productSeasonArchivedDetailsData]);

  useEffect(() => {
    if (!productId) return;

    const loadDefaultOrderedProductIds = async () => {
      let ids = productIds?.length ? [...productIds] : null;

      if (!ids) {
        const result = await getProductPerformanceIdsLazyQuery();

        ids = result.data?.productPerformance?.map((product) => String(product.id)) || null;
      }

      if (!ids?.length) return;

      const productIdIndex = ids.findIndex((id) => id == String(productId));

      if (productIdIndex > -1) {
        setSiblingProductIds({
          previous: productIdIndex === 0 ? null : ids[productIdIndex - 1],
          next: productIdIndex === ids.length - 1 ? null : ids[productIdIndex + 1],
        });
      }
    };

    loadDefaultOrderedProductIds();
  }, [getProductPerformanceIdsLazyQuery, productId, productIds]);

  const handleTabChange = useCallback(
    (activeKey: string) => {
      navigate('#' + activeKey, { replace: true });
    },
    [navigate],
  );

  const handlePrevious = useCallback(() => {
    if (siblingProductIds?.previous) {
      clearContext();
      navigate(AllRoutes.ProductDetails.replace(':id', siblingProductIds?.previous), { replace: true });
    }
  }, [clearContext, navigate, siblingProductIds?.previous]);

  const handleNext = useCallback(() => {
    if (siblingProductIds?.next) {
      clearContext();
      navigate(AllRoutes.ProductDetails.replace(':id', siblingProductIds?.next), { replace: true });
    }
  }, [clearContext, navigate, siblingProductIds?.next]);

  useEffect(() => {
    const keyCondition = hash?.replace('#', '');

    hash && setActiveTab(keyCondition);
  }, [hash]);

  return (
    <SizeSystemContextProvider>
      <StyledContainer>
        <ProductDetailsSider
          isProductArchived={isProductArchived}
          productSeasonId={productSeasonId}
          refetchSeason={refetchProductSeasonArchivedDetails}
        />

        <StyledContent>
          <FloatingNavButtonsWrapper>
            <Button
              textKey="common.previous"
              onClick={handlePrevious}
              icon={<LeftOutlined />}
              size="small"
              disabled={!siblingProductIds?.previous}
            />
            <Button
              textKey="common.next"
              onClick={handleNext}
              icon={<RightOutlined />}
              size="small"
              disabled={!siblingProductIds?.next}
            />
          </FloatingNavButtonsWrapper>

          <StyledTabs
            onChange={handleTabChange}
            activeKey={activeTab}
            items={[
              {
                key: 'detail-stock',
                label: <>{t('productOptimalStock.detail_stock')}</>,
                children: <DetailStockContent isProductArchived={isProductArchived} />,
                forceRender: true,
              },
              {
                key: 'exception-stock',
                label: (
                  <ExceptionStockLabelWrapper>
                    <>
                      {t('productOptimalStock.exception_stock')}
                      <div>{exceptionStoresNumber}</div>
                    </>
                  </ExceptionStockLabelWrapper>
                ),
                children: <ExceptionStockContent isProductArchived={isProductArchived} />,
              },
              {
                key: 'general-information',
                label: <>{t('productOptimalStock.general_information')}</>,
                children: <GeneralInformation isProductArchived={isProductArchived} />,
              },
            ]}
          />
        </StyledContent>
      </StyledContainer>
    </SizeSystemContextProvider>
  );
};

export default ProductDetails;
