import React from 'react';

import GeneralShopTable from './GeneralShopTable/GeneralShopTable';
import ShopCustomFields from './ShopCustomFields/ShopCustomFields';
import { SecondaryStyledTabs } from './styles';

const Shop: React.FC = () => {
  return (
    <SecondaryStyledTabs
      defaultActiveKey="1"
      itemsWithTextKeys={[
        {
          labelKey: 'operationalSettings.shop_general',
          key: '1',
          children: <GeneralShopTable />,
        },
        {
          labelKey: 'operationalSettings.additional_characteristics',
          key: '2',
          children: <ShopCustomFields />,
        },
      ]}
    />
  );
};

export default Shop;
