import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { CellEditingStartedEvent, CellEditingStoppedEvent, ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Checkbox, Radio } from 'antd';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';

import { AlphanumericCellEditor } from 'components/AgGridComponents';
import ConfirmModal from 'components/Modals/ConfirmModal/ConfirmModal';
import { NakedButton, PrimaryButton, Spinner } from 'components/UI';
import { ActionButtonsWrapper } from 'components/wrappers';
import { CENTERED_COL_DEF, COLUMN_TYPES } from 'constants/gridConstants';
import { VALIDATIONS_TYPES } from 'constants/validations';
import { forbidEmptyCellValueSetter } from 'helpers/gridCells';
import { sortEmpty } from 'helpers/gridCols';
import { validateGridCustom } from 'helpers/validations';
import {
  StoreType,
  GetStoreTypesQuery,
  useGetStoreTypesQuery,
  useCreateStoreTypeMutation,
  useUpdateBulkStoreTypesMutation,
  useUpdateStoreTypeMutation,
  useDeleteStoreTypeMutation,
} from 'services/graphql/main';
import { useError } from 'services/utils';

import { DeleteStoreTypeIconProps, MutationStateProps, IsWebCheckboxProps, IsMainRadioProps } from '../../common/types';

const GeneralShopTable: React.FC = () => {
  const { t } = useTranslation('translation');
  const { addCustomError, addError } = useError();

  const { loading, data, refetch } = useGetStoreTypesQuery({ onError: (err) => addError(err, 'error') });

  const [createStoreTypeMutation, { error: createError }] = useCreateStoreTypeMutation({
    onError: (err) => addError(err, 'warning'),
  });

  const [updateStoreType] = useUpdateStoreTypeMutation({
    onError: (err) => addError(err, 'warning'),
  });

  const [updateBulkStoreType, { error: updateBulkError }] = useUpdateBulkStoreTypesMutation({
    onError: (err) => addError(err, 'warning'),
  });

  const [deleteStoreTypeMutation] = useDeleteStoreTypeMutation({ onError: (err) => addError(err, 'warning') });

  const initialGridData = useRef<any>(null);
  const gridRef = useRef<AgGridReact<StoreType>>(null);

  const [currentEditedRow, setCurrentEditedRow] = useState<number | null>(null);
  const [isUpdate, setIsUpdate] = useState({ isCreateRowActive: false, isUpdateRowActive: false, isCellActive: false });

  const [rowData, setRowData] = useState<(GetStoreTypesQuery | null)[] | null | undefined>();
  const [mutationState, setMutationState] = useState<MutationStateProps | null>(null);

  const [errors, setErrors] = useState<any>(null);

  const onCellEditingStarted = useCallback(
    (event: CellEditingStartedEvent | null) => {
      if (currentEditedRow === null) {
        setCurrentEditedRow(event!.rowIndex);
      }

      setIsUpdate((prevState) => ({
        ...prevState,
        isCellActive: true,
      }));
    },
    [currentEditedRow],
  );

  const onCellEditingStopped = useCallback(
    (event: CellEditingStoppedEvent | null) => {
      const defaultCreateChanges = {
        code: event!.data.code,
        name: event!.data.name,
        isWeb: event!.data.isWeb,
        isMain: event!.data.isMain,
      };

      const gridData = gridRef
        .current!.api.getRenderedNodes()
        .map((x) => x.data)
        .map((y) => {
          return {
            id: y?.id,
            code: y?.code,
            name: y?.name,
          };
        });

      if (mutationState?.type == 'create-row') {
        setMutationState({
          type: 'create-row',
          data: {
            ...defaultCreateChanges,
          },
        });
      } else {
        setMutationState({
          type: 'update-row',
          data: gridData,
        });
      }

      if (!isEqual(initialGridData.current, gridData)) {
        setIsUpdate((prevState) => ({
          ...prevState,
          isCellActive: false,
          isUpdateRowActive: true,
        }));
      }

      setIsUpdate((prevState) => ({
        ...prevState,
        isCellActive: false,
      }));
    },
    [mutationState?.type],
  );

  const handleSaveClick = useCallback(async () => {
    const gridData = gridRef
      .current!.api.getRenderedNodes()
      .map((x) => x.data)
      .map((y) => {
        return {
          code: y?.code,
          name: y?.name,
        };
      });

    const { DUPLICATES, EMPTY_CELL, LENGTH } = VALIDATIONS_TYPES;

    const validations = [DUPLICATES, EMPTY_CELL, LENGTH];
    const validation = validateGridCustom(validations, gridData);

    if (validation?.isValid === false) {
      addCustomError({ message: validation?.code }, 'warning');
    } else if (mutationState && validation?.isValid) {
      switch (mutationState?.type) {
        case 'create-row':
          await createStoreTypeMutation({
            variables: {
              storeType: mutationState.data,
            },
          });

          await refetch();

          setMutationState(null);
          break;
        case 'update-row': {
          const changes: any = [];
          const initialData: any = initialGridData.current;

          mutationState.data.forEach((row: any) => {
            const initialRowData = initialData.find((initialRow: any) => initialRow.id === row.id);
            const difference: { code?: string; name?: string } = {};

            if (row.code !== initialRowData.code) {
              difference.code = row.code;
            }
            if (row.name !== initialRowData.name) {
              difference.name = row.name;
            }

            if (Object.keys(difference).length) {
              changes.push({ id: row.id, ...difference });
            }
          });

          await updateBulkStoreType({
            variables: {
              inputStoreTypes: changes,
            },
          });

          await refetch();

          setMutationState(null);
          break;
        }
        default:
          break;
      }

      setIsUpdate((prevState) => ({
        ...prevState,
        isCreateRowActive: false,
        isUpdateRowActive: false,
      }));

      setCurrentEditedRow(null);
    }
  }, [addCustomError, createStoreTypeMutation, mutationState, refetch, updateBulkStoreType]);

  const handleCreateStoreTypeClick = useCallback(() => {
    const agGridConstructorObject: any = {
      code: '',
      name: '',
      isWeb: false,
      isMain: false,
    };

    gridRef.current!.api.applyTransaction({
      add: [agGridConstructorObject],
      addIndex: rowData?.length,
    });

    const gridData = gridRef.current!.api.getRenderedNodes();

    setTimeout(() => {
      gridRef.current!.api.startEditingCell({
        rowIndex: gridData.length - 1,
        colKey: 'code',
      });
    }, 1);

    const mutationStateNew = {
      type: 'create-row',
      data: { code: '', name: '', isWeb: false, isMain: false },
    };

    setIsUpdate((prevState) => ({
      ...prevState,
      isCreateRowActive: true,
    }));

    setMutationState(mutationStateNew);
  }, [rowData?.length]);

  const columnDefs: ColDef[] = useMemo(() => {
    const DeleteIcon: React.FC<DeleteStoreTypeIconProps> = (props) => {
      const { t } = useTranslation('translation');

      const [isModalOpen, setIsModalOpen] = useState(false);

      const showModal = () => {
        setIsModalOpen(true);
      };

      const hideModal = () => {
        setIsModalOpen(false);
      };

      const clickAction = () => {
        props.delete(props.id, props?.storeType);
        hideModal();
      };

      return (
        <div>
          <NakedButton
            disabled={isUpdate.isCreateRowActive || isUpdate.isUpdateRowActive}
            type="text"
            onClick={showModal}
          >
            <DeleteOutlined style={{ cursor: 'pointer', textAlign: 'center' }} />
          </NakedButton>

          <ConfirmModal
            customTitle={t('operationalSettings.are_you_sure_you_want_to_delete_store_type')}
            onOk={clickAction}
            open={isModalOpen}
            onCancel={hideModal}
          />
        </div>
      );
    };

    const IsWebCheckbox: React.FC<IsWebCheckboxProps> = (props) => {
      const [isCheckedBox, setIsCheckedBox] = useState(props.isWeb);

      const clickAction = async () => {
        await props.edit(props.id, !isCheckedBox);
        setIsCheckedBox(!isCheckedBox);
      };

      return (
        <Checkbox
          defaultChecked={props.isWeb}
          disabled={isUpdate.isCreateRowActive || isUpdate.isUpdateRowActive}
          onClick={clickAction}
        />
      );
    };

    const IsMainRadio: React.FC<IsMainRadioProps> = (props) => {
      const [isCheckedBox, setIsCheckedBox] = useState(props.isMain);

      const clickAction = async () => {
        await props.edit(props.id, !isCheckedBox);
        setIsCheckedBox(!isCheckedBox);
      };

      return (
        <Radio
          defaultChecked={props.isMain}
          disabled={isUpdate.isCreateRowActive || isUpdate.isUpdateRowActive}
          onClick={clickAction}
        />
      );
    };

    const updateIsWeb = async (id: number, isWeb: boolean) => {
      await updateStoreType({
        variables: {
          storeType: {
            id: id,
            isWeb: isWeb,
          },
        },
      });

      await refetch();
    };

    const updateIsMain = async (id: number, isMain: boolean) => {
      await updateStoreType({
        variables: {
          storeType: {
            id: id,
            isMain: isMain,
          },
        },
      });

      await refetch();
    };

    const deleteStoreType = async (id: number, storeType: StoreType) => {
      if (mutationState == null) {
        await deleteStoreTypeMutation({
          variables: {
            id: id,
          },
        });

        await refetch();
      } else {
        gridRef.current?.api.applyTransaction({ remove: [storeType] });
        setMutationState(null);
        setCurrentEditedRow(null);
      }
    };

    return [
      {
        field: 'code',
        sort: 'asc',
        ...(!mutationState === null && { sort: 'asc' }),
        comparator: sortEmpty,
        width: 150,
        headerName: t('operationalSettings.code'),
        editable: true,
        valueSetter: forbidEmptyCellValueSetter,
        cellEditor: AlphanumericCellEditor,
      },
      {
        field: 'name',
        comparator: sortEmpty,
        flex: 1,
        headerName: t('operationalSettings.label'),
        editable: true,
        valueSetter: forbidEmptyCellValueSetter,
      },
      {
        field: 'isWeb',
        sortable: false,
        maxWidth: 150,
        pinned: 'right',
        suppressMenu: true,
        headerName: t('operationalSettings.is_web'),
        ...CENTERED_COL_DEF,
        cellRendererSelector: (params) => {
          return {
            component: IsWebCheckbox,
            params: {
              isWeb: params.data.isWeb,
              id: params.data.id,
              edit: updateIsWeb,
              currentEditedRow: currentEditedRow,
              rowIndex: params.rowIndex,
              mutationState: mutationState,
            },
          };
        },
      },
      {
        field: 'isMain',
        sortable: false,
        maxWidth: 150,
        pinned: 'right',
        suppressMenu: true,
        headerName: t('operationalSettings.is_main'),
        ...CENTERED_COL_DEF,
        cellRendererSelector: (params) => {
          return {
            component: IsMainRadio,
            params: {
              isMain: params.data.isMain,
              id: params.data.id,
              edit: updateIsMain,
              currentEditedRow: currentEditedRow,
              rowIndex: params.rowIndex,
              mutationState: mutationState,
            },
          };
        },
      },
      {
        maxWidth: 60,
        ...CENTERED_COL_DEF,
        sortable: false,
        pinned: 'right',
        suppressMenu: true,
        cellRendererSelector: (params) => {
          return {
            component: DeleteIcon,
            params: {
              id: params.data.id,
              delete: deleteStoreType,
              storeType: params.data,
              currentEditedRow: currentEditedRow,
              setCurrentEditedRow: setCurrentEditedRow,
              rowIndex: params.rowIndex,
            },
          };
        },
      },
    ];
  }, [
    currentEditedRow,
    deleteStoreTypeMutation,
    isUpdate.isCreateRowActive,
    isUpdate.isUpdateRowActive,
    mutationState,
    refetch,
    t,
    updateStoreType,
  ]);

  const handleCancelButton = useCallback(() => {
    if (mutationState?.type === 'create-row') {
      const lastRowIndex = gridRef.current!.api.getLastDisplayedRow();
      const lastRow = gridRef.current!.api.getDisplayedRowAtIndex(lastRowIndex);

      gridRef.current?.api.applyTransaction({ remove: [lastRow?.data!] });

      const mutableData =
        data &&
        data!.storeTypes?.map((x) => {
          return {
            id: x?.id,
            code: x?.code,
            name: x?.name,
            isWeb: x?.isWeb,
            isMain: x?.isMain,
          };
        });

      mutableData && gridRef.current?.api.setRowData(mutableData);

      setRowData(mutableData as GetStoreTypesQuery[]);
    } else {
      const mutableData =
        data &&
        data!.storeTypes?.map((x) => {
          return {
            id: x?.id,
            code: x?.code,
            name: x?.name,
            isWeb: x?.isWeb,
            isMain: x?.isMain,
          };
        });

      mutableData && gridRef.current?.api.setRowData(mutableData);

      setRowData(mutableData as GetStoreTypesQuery[]);
    }

    setIsUpdate((prevState) => ({
      ...prevState,
      isCreateRowActive: false,
      isUpdateRowActive: false,
      isCellActive: false,
    }));

    setMutationState(null);
  }, [data, mutationState?.type]);

  const defaultColDef: ColDef = useMemo(
    () => ({
      suppressMovable: true,
      editable: true,
      sortable: true,
      minWidth: 100,
    }),
    [],
  );

  useEffect(() => {
    const mutableData =
      data &&
      data!.storeTypes?.map((x) => {
        return {
          id: x?.id,
          code: x?.code,
          name: x?.name,
          isWeb: x?.isWeb,
          isMain: x?.isMain,
        };
      });

    const gridData =
      data &&
      data!.storeTypes?.map((x) => {
        return {
          id: x?.id,
          code: x?.code,
          name: x?.name,
          isWeb: x?.isWeb,
          isMain: x?.isMain,
        };
      });

    initialGridData.current = gridData;

    data && setRowData(mutableData as GetStoreTypesQuery[]);
  }, [data]);

  useEffect(() => {
    createError?.graphQLErrors || updateBulkError?.graphQLErrors ? setErrors(true) : setErrors(null);
  }, [updateBulkError?.graphQLErrors, createError?.graphQLErrors]);

  return (
    <>
      {loading ? (
        <Spinner size="large" />
      ) : rowData?.length ? (
        <AgGridReact
          ref={gridRef}
          containerStyle={{ width: '100%' }}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          domLayout={'autoHeight'}
          stopEditingWhenCellsLoseFocus={true}
          onCellEditingStopped={onCellEditingStopped}
          onCellEditingStarted={onCellEditingStarted}
          columnTypes={COLUMN_TYPES}
          enableRangeSelection={true}
        />
      ) : null}

      <ActionButtonsWrapper>
        <NakedButton
          type="text"
          textKey="operationalSettings.create_store_type"
          onClick={handleCreateStoreTypeClick}
          disabled={isUpdate.isCreateRowActive || isUpdate.isUpdateRowActive || errors}
          style={{ color: '#FFFFFF', marginRight: 'auto' }}
        />
        {isUpdate.isCreateRowActive || isUpdate.isUpdateRowActive ? (
          <>
            <NakedButton
              type="text"
              onClick={handleCancelButton}
              textKey="common.cancel"
              style={{ color: '#FFFFFF' }}
            />

            <PrimaryButton
              onClick={handleSaveClick}
              textKey="common.save"
              disabled={isUpdate.isCellActive}
            ></PrimaryButton>
          </>
        ) : null}
      </ActionButtonsWrapper>
    </>
  );
};

export default GeneralShopTable;
