import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { ColDef, ICellRendererParams, ValueFormatterParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { ProductContext } from 'context';
import { useTranslation } from 'react-i18next';

import { Spinner } from 'components/UI';
import { CENTERED_COL_DEF, COLUMN_TYPES } from 'constants/gridConstants';
import {
  StyledTotalStatusCheckSuccess,
  StyledTotalStatusCheckWarning,
} from 'screens/OperationalSettings/SizeSystem/components/styles';
import { COMMON_DEFAULT_COL_DEFS } from 'screens/ProductDetails/common/constants/optimalStockCalculationTable';
import {
  DISCOUNT_CUSTOM_FIELD,
  DISTRIBUTION_ROW_ID,
  IS_GROUPING_ROW_CUSTOM_FIELD,
  ROW_HEADER_ID_FIELD,
  ROW_HEADER_NAME_FIELD,
  ROW_PARENT_CUSTOM_FIELD,
  SKIP_FILTER_TEXT,
  TOTAL_CUSTOM_FIELD,
  TYPE_CUSTOM_FIELD,
} from 'screens/ProductDetails/common/constants/previousDaySummaryTable';
import { generatePercentageRows, generateRows } from 'screens/ProductDetails/common/helpers/previousDaySummaryTable';
import { useProductIdParam } from 'screens/ProductDetails/common/hooks';
import { DataVariant, Row } from 'screens/ProductDetails/common/types';
import { useGetStockSalesTableQuery } from 'services/graphql/main';
import { useError } from 'services/utils';

import { GroupingGridWrapper } from './styles';

interface PreviousDaySummaryTableProps {
  dataVariant: DataVariant;
}

const PreviousDaySummaryTable: React.FC<PreviousDaySummaryTableProps> = ({ dataVariant }) => {
  const { t } = useTranslation('translation');
  const { addError } = useError();
  const { sizeSystemRowId, visibleSizeSystemsColsIds } = useContext(ProductContext);

  const { productId } = useProductIdParam();

  const { loading, data, refetch } = useGetStockSalesTableQuery({
    variables: { productId },
    onError: (err) => addError(err, 'error'),
  });

  const [rowData, setRowData] = useState<Row[]>([]);
  const [rowDataPercentage, setRowDataPercentage] = useState<Row[]>([]);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const [expandedGroups, setExpandedGroups] = useState<Array<string>>([]);
  const [isGridFirstDataRendered, setIsGridFirstDataRendered] = useState(false);

  const gridRef = useRef<AgGridReact>(null);

  const defaultColDef: ColDef = useMemo(() => {
    const isTypeCell = (params: ICellRendererParams | ValueFormatterParams) =>
      params.column?.getColId() === TYPE_CUSTOM_FIELD;

    const quantityValueFormatter = (params: ValueFormatterParams) => {
      const { value, data, column } = params;
      if (!value) return ' ';

      const { id: rowId } = data;
      const colId = column.getColId();
      let formattedValue = value;

      if ((rowId === DISTRIBUTION_ROW_ID || colId === DISCOUNT_CUSTOM_FIELD) && typeof value === 'number') {
        formattedValue += ' %';
      }

      return formattedValue;
    };

    const percentageValueFormatter = (params: ValueFormatterParams) => {
      const { value, data, column } = params;
      if (!value) return ' ';

      const { id: rowId } = data;
      const colId = column.getColId();

      if (rowId === DISTRIBUTION_ROW_ID || colId === DISCOUNT_CUSTOM_FIELD) {
        return typeof value === 'number' ? `${value} %` : value;
      }

      return isTypeCell(params) ? `% ${value}` : `${value} %`;
    };

    return {
      ...COMMON_DEFAULT_COL_DEFS,
      suppressMovable: true,
      valueFormatter: dataVariant === 'quantity' ? quantityValueFormatter : percentageValueFormatter,
      cellRenderer: (params: ICellRendererParams) => {
        const value = params.valueFormatted ? params.valueFormatted : params.value;

        return <span className={params.data[ROW_PARENT_CUSTOM_FIELD] ? 'childRowCell' : 'parentRowCell'}>{value}</span>;
      },
      ...CENTERED_COL_DEF,
    };
  }, [dataVariant]);

  const dataColumns = useMemo(() => {
    return data?.stocksSalesTable?.columns?.filter((column) => !!column?.sKU);
  }, [data]);

  useEffect(() => {
    if (!dataColumns || !dataColumns.length) return;

    let defs: ColDef[] = [];

    const firstColumnDef: ColDef = {
      field: TYPE_CUSTOM_FIELD,
      headerName: t('productOptimalStock.type'),
      minWidth: 300,
      maxWidth: 300,
      pinned: 'left',
      getQuickFilterText: (params) => {
        const parentField = params.data[ROW_PARENT_CUSTOM_FIELD];

        return !parentField || expandedGroups.includes(parentField) ? SKIP_FILTER_TEXT : '';
      },
      cellRenderer: (params: ICellRendererParams) => {
        const value = params.valueFormatted ? params.valueFormatted : params.value;
        const rowId = params.data.id;

        if (!params.data[IS_GROUPING_ROW_CUSTOM_FIELD])
          return (
            <div className={params.data[ROW_PARENT_CUSTOM_FIELD] ? 'childTypeCellContent' : 'parentTypeCellContent'}>
              <span>{value}</span>

              {rowId === DISTRIBUTION_ROW_ID && sizeSystemRowId && params.data[TOTAL_CUSTOM_FIELD] !== 100 ? (
                <StyledTotalStatusCheckWarning />
              ) : (
                <StyledTotalStatusCheckSuccess />
              )}
            </div>
          );

        const isExpanded = expandedGroups.includes(rowId);
        const ExpandingIcon = isExpanded ? MinusSquareOutlined : PlusSquareOutlined;

        const typeGroupingCellContent = (
          <div className="groupingTypeCellContent">
            <span>{value}</span>

            <ExpandingIcon
              onMouseDown={(e) => {
                e.preventDefault();
              }}
              onClick={(e) => {
                e.preventDefault();

                const updatedExpandedGroups = isExpanded
                  ? expandedGroups.filter((group) => group !== rowId)
                  : [...expandedGroups, rowId];

                setExpandedGroups(updatedExpandedGroups);
              }}
            />
          </div>
        );

        return typeGroupingCellContent;
      },
    };

    // quantity columns
    const columnDefs = dataColumns.reduce(
      (defs, column) => {
        if (column) {
          const field = String(column[ROW_HEADER_ID_FIELD]);
          const headerName = column[ROW_HEADER_NAME_FIELD];

          if (field && headerName) {
            defs.push({
              field,
              headerName,
              cellRenderer: (params: ICellRendererParams) => {
                const value = params.valueFormatted ? params.valueFormatted : params.value;

                return (
                  <span className={params.data[ROW_PARENT_CUSTOM_FIELD] ? 'childRowCell' : 'parentRowCell'}>
                    {value}
                  </span>
                );
              },
            });
          }
        }

        return defs;
      },
      [firstColumnDef],
    );

    const additionalColumnDefs: ColDef[] = [
      {
        field: TOTAL_CUSTOM_FIELD,
        headerName: t('productOptimalStock.total_caps'),
      },
      {
        field: DISCOUNT_CUSTOM_FIELD,
        headerName: t('productOptimalStock.discount'),
        minWidth: 100,
      },
    ];

    defs = [...columnDefs, ...additionalColumnDefs];

    setColumnDefs(defs);

    applyGroupingState();
  }, [data, dataColumns, expandedGroups, t, visibleSizeSystemsColsIds, sizeSystemRowId]);

  useEffect(() => {
    if (!dataColumns || !dataColumns.length || !data) return;

    const rows = generateRows(data, t);
    const percentageRows = generatePercentageRows(rows);

    setRowData(rows);
    setRowDataPercentage(percentageRows);
  }, [data, dataColumns, t]);

  useEffect(() => {
    if (sizeSystemRowId) refetch();
  }, [sizeSystemRowId, refetch]);

  const currentRowData = useMemo(() => {
    return dataVariant === 'quantity' ? rowData : rowDataPercentage;
  }, [rowData, rowDataPercentage, dataVariant]);

  const handleFirstDataRendered = useCallback(() => {
    applyGroupingState();
    setIsGridFirstDataRendered(true);
  }, []);

  const applyGroupingState = () => {
    gridRef.current?.api?.setQuickFilter(SKIP_FILTER_TEXT);
  };

  if (loading) return <Spinner />;

  return (
    <GroupingGridWrapper>
      <AgGridReact
        ref={gridRef}
        rowData={currentRowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        domLayout="autoHeight"
        rowHeight={22}
        headerHeight={22}
        onFirstDataRendered={handleFirstDataRendered}
        containerStyle={{ opacity: isGridFirstDataRendered ? 1 : 0 }}
        animateRows
        columnTypes={COLUMN_TYPES}
        enableRangeSelection={true}
      />
    </GroupingGridWrapper>
  );
};

export default PreviousDaySummaryTable;
