import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Col, Form, Row, Select, Switch, InputNumber } from 'antd';
import { ProductContext, PermissionsContext, StoreManagementContext } from 'context';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { PrimaryButton, Spinner, NakedButton } from 'components/UI';
import { ActionButtonsWrapper } from 'components/wrappers';
import { UniverseContext } from 'context/universe-context';
import { partiallyEditedData } from 'helpers/common';
import { useProductIdParam } from 'screens/ProductDetails/common/hooks';
import {
  Collection,
  ProductDetailsDto,
  ProductModule,
  Season,
  SizeSystemRow,
  Universe,
  useGetCollectionsLazyQuery,
  useUpdateProductModuleMutation,
  useGetSeasonsQuery,
  useGetSizeSystemRowQuery,
  useGetSizeSystemsQuery,
  useProductCustomFieldsQuery,
  useProductDetailsQuery,
  useUpdateProductDetailsMutation,
  PermissionsEnum,
} from 'services/graphql/main';
import { useError } from 'services/utils';
import { BasicFieldsState } from 'types/common';

import { ProductCustomFieldsProps } from '../../common/types';
import { ItemContainer, Label } from './styles';

type Props = {
  isProductArchived: boolean;
};

const GeneralInformation: React.FC<Props> = ({ isProductArchived }) => {
  const { t } = useTranslation('translation');
  const { addError } = useError();
  const { productId } = useProductIdParam();

  const { selectedUniverse } = useContext(UniverseContext);
  const {
    selectedCollectionId,
    selectedProductModuleId,
    setSelectedCollectionId,
    setSelectedProductModuleId,
    productModulesSelectRef,
    productModules,
    setProductModules,
  } = useContext(ProductContext);
  const { permissions } = useContext(PermissionsContext);
  const { refetchStoreStrengthTable } = useContext(StoreManagementContext);

  const [productDetails, setProductDetails] = useState<ProductDetailsDto>();
  const [seasons, setSeasonsData] = useState<Season[]>();
  const [collections, setCollections] = useState<Collection[]>();
  const [sizeSystemRows, setSizeSystemRows] = useState<SizeSystemRow[]>();
  const [customFields, setCustomFields] = useState<ProductCustomFieldsProps>();

  const [mutationState, setMutationState] = useState<any>();
  const [modificationsState, setModificationsState] = useState<BasicFieldsState>({});

  const hasUpdates = useMemo(() => !isEmpty(modificationsState), [modificationsState]);

  const {
    loading: productDetailsLoading,
    data: productDetailsData,
    refetch: productDetailsRefetch,
  } = useProductDetailsQuery({
    variables: { productId },
    onError: (err) => addError(err, 'error'),
  });

  const { data: seasonsData } = useGetSeasonsQuery({ onError: (err) => addError(err, 'error') });

  const [getCollections, { data: collectionsData, loading: collectionsLoading, called: collectionsCalled }] =
    useGetCollectionsLazyQuery({ onError: (err) => addError(err, 'error') });

  const [updateProductModuleMutation, { data: updateProductModuleData, error: updateProductModuleError }] =
    useUpdateProductModuleMutation({ onError: (err) => addError(err, 'warning') });

  const { data: sizeSystemData } = useGetSizeSystemsQuery({
    onError: (err) => addError(err, 'error'),
  });

  const { data: sizeSystemRowsData } = useGetSizeSystemRowQuery({
    variables: { sizeSystemId: (productDetailsData?.productDetails.sizeSystemId as number) || 0 },
    onError: (err) => addError(err, 'error'),
  });

  const { data: customFieldsData } = useProductCustomFieldsQuery({ onError: (err) => addError(err, 'error') });

  const [updateProductDetails] = useUpdateProductDetailsMutation({ onError: (err) => addError(err, 'warning') });

  const handleSaveMutation = useCallback(async () => {
    if (hasUpdates) {
      await updateProductDetails({
        variables: {
          productDetails: { id: productId, ...modificationsState },
        },
      });

      await productDetailsRefetch();

      setModificationsState({});
    }
  }, [hasUpdates, modificationsState, productDetailsRefetch, productId, updateProductDetails]);

  const handleOnChange = (value: string | number | undefined, objectProp: string) => {
    if (objectProp.includes('customFieldValue') && value === undefined) {
      setMutationState((prevState: ProductDetailsDto) => ({
        ...prevState,
        [objectProp]: null,
      }));
    } else {
      setMutationState((prevState: ProductDetailsDto) => ({
        ...prevState,
        [objectProp]: value,
      }));
    }

    const newModificationState = partiallyEditedData(
      objectProp,
      value,
      modificationsState,
      productDetailsData?.productDetails,
    );

    if (newModificationState) {
      setModificationsState(newModificationState);
    }
  };

  const selectCollection = useCallback(
    (collectionId: number) => {
      setSelectedCollectionId(collectionId);
      setSelectedProductModuleId(null);

      const collection = collectionsData?.collections?.find((collection) => collection?.id === collectionId);

      if (collection?.productModules) setProductModules(collection?.productModules as Array<ProductModule>);
    },
    [collectionsData?.collections, setProductModules, setSelectedCollectionId, setSelectedProductModuleId],
  );

  const handleCollectionChange = useCallback(
    (selectedCollectionId: number) => {
      selectCollection(selectedCollectionId);
      productModulesSelectRef.current?.focus();
    },
    [productModulesSelectRef, selectCollection],
  );

  const handleModuleChange = useCallback(
    async (value: number) => {
      await updateProductModuleMutation({ variables: { productId, moduleId: value } });
      setSelectedProductModuleId(value);
    },
    [productId, setSelectedProductModuleId, updateProductModuleMutation],
  );

  const handleShowcaseChange = async (checked: boolean) => {
    await updateProductDetails({
      variables: {
        productDetails: { id: productId, isOnShowcase: checked },
      },
    });

    await productDetailsRefetch();
    await refetchStoreStrengthTable({ productId: productId || 0 });
  };

  const handleSyncedChange = useCallback(
    async (checked: boolean) => {
      await updateProductDetails({
        variables: {
          productDetails: { id: productId, isSynced: checked },
        },
      });

      await productDetailsRefetch();
    },
    [productDetailsRefetch, productId, updateProductDetails],
  );

  const prepareCustomFieldsData = (preparedCustomFields: any, customFields: any) => {
    for (const key in customFields) {
      if (Object.prototype.hasOwnProperty.call(customFields, key)) {
        if (key.includes('customFieldValues')) {
          const arrayOfValues = JSON.parse(customFields[key]);

          preparedCustomFields[key] = arrayOfValues;
        } else {
          preparedCustomFields[key] = customFields[key];
        }
      }
    }
  };

  const prepareAllData = useCallback(() => {
    const preparedCustomFields = {};
    const customFields: any = customFieldsData?.productCustomFields;

    let prepareProductDetailsData: any = {};

    if (productDetailsData?.productDetails && customFields) {
      const customFieldValue1Result =
        customFields?.customFieldValues1 &&
        productDetailsData.productDetails?.customFieldValue1 &&
        JSON.parse(customFields?.customFieldValues1).find(
          (obj: { Code: string; Label: string }) =>
            obj.Code === JSON.parse(productDetailsData.productDetails?.customFieldValue1!).code,
        );
      const customFieldValue2Result =
        customFields?.customFieldValues2 &&
        productDetailsData.productDetails?.customFieldValue2 &&
        JSON.parse(customFields?.customFieldValues2).find(
          (obj: { Code: string; Label: string }) =>
            obj.Code === JSON.parse(productDetailsData.productDetails?.customFieldValue2!).code,
        );
      const customFieldValue3Result =
        customFields?.customFieldValues3 &&
        productDetailsData.productDetails?.customFieldValue3 &&
        JSON.parse(customFields?.customFieldValues3).find(
          (obj: { Code: string; Label: string }) =>
            obj.Code === JSON.parse(productDetailsData.productDetails?.customFieldValue3!).code,
        );
      const customFieldValue4Result =
        customFields?.customFieldValues4 &&
        productDetailsData.productDetails?.customFieldValue4 &&
        JSON.parse(customFields?.customFieldValues4).find(
          (obj: { Code: string; Label: string }) =>
            obj.Code === JSON.parse(productDetailsData.productDetails?.customFieldValue4!).code,
        );
      const customFieldValue5Result =
        customFields?.customFieldValues5 &&
        productDetailsData.productDetails?.customFieldValue5 &&
        JSON.parse(customFields?.customFieldValues5).find(
          (obj: { Code: string; Label: string }) =>
            obj.Code === JSON.parse(productDetailsData.productDetails?.customFieldValue5!).code,
        );

      if (customFieldValue1Result) {
        prepareProductDetailsData.customFieldValue1 = JSON.stringify([customFieldValue1Result]);
      }

      if (customFieldValue2Result) {
        prepareProductDetailsData.customFieldValue2 = JSON.stringify([customFieldValue2Result]);
      }

      if (customFieldValue3Result) {
        prepareProductDetailsData.customFieldValue3 = JSON.stringify([customFieldValue3Result]);
      }

      if (customFieldValue4Result) {
        prepareProductDetailsData.customFieldValue4 = JSON.stringify([customFieldValue4Result]);
      }

      if (customFieldValue5Result) {
        prepareProductDetailsData.customFieldValue5 = JSON.stringify([customFieldValue5Result]);
      }
    }

    prepareProductDetailsData = {
      ...prepareProductDetailsData,
      id: productDetailsData?.productDetails?.id,
      isOnShowcase: productDetailsData?.productDetails?.isOnShowcase,
      isSynced: productDetailsData?.productDetails?.isSynced,
      price: productDetailsData?.productDetails?.price,
      productModuleId: productDetailsData?.productDetails?.productModuleId,
      seasonId: productDetailsData?.productDetails?.seasonId,
      sizeSystemId: productDetailsData?.productDetails?.sizeSystemId,
      sizeSystemRowId: productDetailsData?.productDetails?.sizeSystemRowId,
      validatedStrength: productDetailsData?.productDetails?.validatedStrength,
      collectionId: productDetailsData?.productDetails?.collectionId,
    };

    if (preparedCustomFields && customFields) {
      prepareCustomFieldsData(preparedCustomFields, customFields);
    }

    productDetailsData && setProductDetails(prepareProductDetailsData as ProductDetailsDto);
    customFieldsData && setCustomFields(preparedCustomFields);
    seasonsData && setSeasonsData(seasonsData.seasons as Season[]);

    selectedUniverse?.id && !collectionsCalled && getCollections({ variables: { universeId: selectedUniverse?.id } });
    sizeSystemRowsData && setSizeSystemRows(sizeSystemRowsData?.sizeSystemRows as SizeSystemRow[]);
    setMutationState(prepareProductDetailsData);
  }, [
    collectionsCalled,
    customFieldsData,
    getCollections,
    productDetailsData,
    seasonsData,
    selectedUniverse?.id,
    sizeSystemRowsData,
  ]);

  const handleCancelButton = useCallback(() => {
    prepareAllData();

    setModificationsState({});
  }, [prepareAllData]);

  const isModuleSelectionExpected = productModules?.length && !collectionsLoading && !selectedProductModuleId;
  let moduleValidationStatus: '' | 'success' | 'error' | 'warning' | 'validating' | undefined;
  let moduleStatusHelp: string | undefined;

  if (isModuleSelectionExpected) {
    moduleValidationStatus = 'warning';
    moduleStatusHelp = t('productOptimalStock.warning_select_product_module');
  } else if (updateProductModuleData?.updateProductDetails.productModuleId === selectedProductModuleId) {
    moduleValidationStatus = 'success';
    moduleStatusHelp = t('productOptimalStock.success_product_module_updated');
  } else if (updateProductModuleError) {
    moduleValidationStatus = 'error';
    moduleStatusHelp = t('productOptimalStock.error_product_module_not_updated');
  }

  useEffect(() => {
    prepareAllData();
  }, [
    productDetailsData,
    seasonsData,
    collectionsData,
    sizeSystemData,
    sizeSystemRowsData,
    customFieldsData,
    prepareAllData,
  ]);

  useEffect(() => {
    if (!collectionsCalled) return;

    if (!collections?.length) {
      collectionsData && setCollections(collectionsData?.collections as Array<Collection>);
    }

    const collectionId = productDetailsData?.productDetails?.collectionId;
    collectionId && selectCollection(collectionId);

    const productModuleId = productDetailsData?.productDetails?.productModuleId;

    if (productModuleId && collectionId) {
      setSelectedProductModuleId(productModuleId);
    }
  }, [
    collections?.length,
    collectionsCalled,
    collectionsData,
    productDetailsData,
    selectCollection,
    setSelectedProductModuleId,
  ]);
  productDetailsRefetch();

  return (
    <div>
      <Form
        wrapperCol={{
          span: 14,
        }}
        layout="horizontal"
        disabled={
          !(permissions as PermissionsEnum[])?.includes(PermissionsEnum.ProductPerformanceDetailsEdit) ||
          isProductArchived
        }
      >
        {productDetailsLoading ? (
          <Spinner size="large" />
        ) : (
          <Row>
            <Col span={12}>
              <Form.Item>
                <Label>{t('productGeneralInformation.universe')}</Label>
                <Select placeholder={t('common.select_a_value')} value={selectedUniverse?.id} disabled size="small">
                  {[selectedUniverse]?.map((universe: Universe) => (
                    <Select.Option key={universe?.id} value={universe?.id}>
                      {universe?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Label>{t('productGeneralInformation.season')}</Label>
                <Select
                  placeholder={t('common.select_a_value')}
                  value={mutationState?.seasonId}
                  onChange={(value) => handleOnChange(value, 'seasonId')}
                  size="small"
                >
                  {seasons?.map((season: Season) => (
                    <Select.Option key={season?.id} value={season?.id}>
                      {season?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Label>{customFields?.customFieldName1}</Label>
                <Select
                  placeholder={t('common.select_a_value')}
                  onChange={(value) => handleOnChange(value, 'customFieldValue1')}
                  value={
                    mutationState?.customFieldValue1 !== undefined
                      ? mutationState?.customFieldValue1
                      : productDetails?.customFieldValue1
                      ? JSON.stringify([productDetails?.customFieldValue1])
                      : null
                  }
                  size="small"
                  allowClear
                >
                  {customFields?.customFieldValues1?.map((value: { Label: string; Value: string }, i: number) => (
                    <Select.Option key={i} value={JSON.stringify([value])}>
                      {value.Label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Label>{customFields?.customFieldName2}</Label>
                <Select
                  placeholder={t('common.select_a_value')}
                  value={
                    mutationState?.customFieldValue2 !== undefined
                      ? mutationState?.customFieldValue2
                      : productDetails?.customFieldValue2
                      ? JSON.stringify([productDetails?.customFieldValue2])
                      : null
                  }
                  onChange={(value) => handleOnChange(value, 'customFieldValue2')}
                  size="small"
                  allowClear
                >
                  {customFields?.customFieldValues2?.map((value: { Label: string; Value: string }, i: number) => (
                    <Select.Option key={i} value={JSON.stringify([value])}>
                      {value.Label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Label>{customFields?.customFieldName3}</Label>
                <Select
                  placeholder={t('common.select_a_value')}
                  value={
                    mutationState?.customFieldValue3 !== undefined
                      ? mutationState?.customFieldValue3
                      : productDetails?.customFieldValue3
                      ? JSON.stringify([productDetails?.customFieldValue3])
                      : null
                  }
                  onChange={(value) => handleOnChange(value, 'customFieldValue3')}
                  size="small"
                  allowClear
                >
                  {customFields?.customFieldValues3?.map((value: { Label: string; Value: string }, i: number) => (
                    <Select.Option key={i} value={JSON.stringify([value])}>
                      {value.Label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Label>{customFields?.customFieldName4}</Label>
                <Select
                  placeholder={t('common.select_a_value')}
                  value={
                    mutationState?.customFieldValue4 !== undefined
                      ? mutationState?.customFieldValue4
                      : productDetails?.customFieldValue4
                      ? JSON.stringify([productDetails?.customFieldValue4])
                      : null
                  }
                  onChange={(value) => handleOnChange(value, 'customFieldValue4')}
                  size="small"
                  allowClear
                >
                  {customFields?.customFieldValues4?.map((value: { Label: string; Value: string }, i: number) => (
                    <Select.Option key={i} value={JSON.stringify([value])}>
                      {value.Label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Label>{customFields?.customFieldName5}</Label>
                <Select
                  placeholder={t('common.select_a_value')}
                  value={
                    mutationState?.customFieldValue5 !== undefined
                      ? mutationState?.customFieldValue5
                      : productDetails?.customFieldValue5
                      ? JSON.stringify([productDetails?.customFieldValue5])
                      : null
                  }
                  onChange={(value) => handleOnChange(value, 'customFieldValue5')}
                  size="small"
                  allowClear
                >
                  {customFields?.customFieldValues5?.map((value: { Label: string; Value: string }, i: number) => (
                    <Select.Option key={i} value={JSON.stringify([value])}>
                      {value.Label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <ItemContainer>
                  <Label>{t('productGeneralInformation.selling_price')} (€)</Label>
                  <span style={{ color: '#ffffff' }}>{productDetails?.price} €</span>
                </ItemContainer>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Label>{t('productGeneralInformation.collection')}</Label>
                {collectionsLoading && <Select disabled loading></Select>}
                {collections && (
                  <Select value={selectedCollectionId} onChange={handleCollectionChange} size="small">
                    {collections.map((collection) => {
                      return (
                        collection?.id &&
                        collection.name && (
                          <Select.Option key={collection.id} value={collection.id}>
                            {collection.name}
                          </Select.Option>
                        )
                      );
                    })}
                  </Select>
                )}
              </Form.Item>

              <Form.Item hasFeedback validateStatus={moduleValidationStatus} help={moduleStatusHelp}>
                <Label>{t('productGeneralInformation.module')}</Label>
                {collectionsLoading ? (
                  <Select disabled loading></Select>
                ) : (
                  <Select
                    onChange={handleModuleChange}
                    value={selectedProductModuleId}
                    ref={productModulesSelectRef}
                    size="small"
                  >
                    {productModules?.map((module) => {
                      return (
                        <Select.Option key={module.id} value={module.id}>
                          {module.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>

              <Form.Item>
                <Label>{t('productGeneralInformation.product_power_validated')}</Label>
                <InputNumber
                  min={0}
                  step={0.1}
                  style={{ width: '100%' }}
                  value={mutationState?.validatedStrength}
                  onChange={(value) => {
                    return !isNaN(value) && handleOnChange(value, 'validatedStrength');
                  }}
                  size="small"
                />
              </Form.Item>
              <Form.Item>
                <Label>{t('productGeneralInformation.validated_grid')}</Label>
                <Select
                  placeholder={t('common.select_a_value')}
                  value={mutationState?.sizeSystemRowId}
                  onChange={(value) => handleOnChange(value, 'sizeSystemRowId')}
                  size="small"
                >
                  {sizeSystemRows?.map((sizeSystemRow: SizeSystemRow) => (
                    <Select.Option key={sizeSystemRow?.id} value={sizeSystemRow?.id}>
                      {sizeSystemRow?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <ItemContainer>
                  <Label>{t('productGeneralInformation.showcase')}</Label>
                  <Switch
                    disabled={
                      hasUpdates ||
                      !(permissions as PermissionsEnum[])?.includes(PermissionsEnum.ProductPerformanceDetailsEdit) ||
                      isProductArchived
                    }
                    checked={productDetails?.isOnShowcase}
                    onChange={handleShowcaseChange}
                  />
                </ItemContainer>
              </Form.Item>
              <Form.Item>
                <ItemContainer>
                  <Label>{t('productGeneralInformation.trans_ideal_stock')}</Label>
                  <Switch
                    disabled={
                      hasUpdates ||
                      !(permissions as PermissionsEnum[])?.includes(PermissionsEnum.ProductPerformanceDetailsEdit) ||
                      isProductArchived
                    }
                    checked={productDetails?.isSynced}
                    onChange={handleSyncedChange}
                  />
                </ItemContainer>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>

      <ActionButtonsWrapper>
        {hasUpdates ? (
          <>
            <NakedButton type="text" onClick={handleCancelButton} textKey="common.cancel" />

            <PrimaryButton onClick={handleSaveMutation} textKey="common.save"></PrimaryButton>
          </>
        ) : null}
      </ActionButtonsWrapper>
    </div>
  );
};

export default GeneralInformation;
