import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';

import { PlusCircleOutlined, CloseCircleOutlined, CheckCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Row, Col } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getFormattedCurrentDateTime } from 'utils/moment';

import { Spinner, NakedButton } from 'components/UI';
import { getContextExcelExportMenu } from 'helpers/gridContextMenu';
import { composeTranslatedMessage } from 'helpers/translations';
import { useGetDashboardInfoQuery } from 'services/graphql/main';
import { useError } from 'services/utils';

import LastImportDetails from './components/LastImportDetails';
import {
  StyledTitle,
  DetailsBox,
  BoxTitle,
  TitleWrapper,
  SubTitle,
  NewsTitle,
  NewsSubtitle,
  NewsBody,
  NewsImage,
} from './styles';

const Home: React.FC = () => {
  const { t } = useTranslation('translation');
  const { addError } = useError();
  const gridRef = useRef<AgGridReact<{ object: string }[]>>(null);

  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [selectedLineId, setSelectedLineId] = useState<number>();
  const [errorsData, setErrorsData] = useState();

  const { data: dashboardData } = useGetDashboardInfoQuery({
    onError: (err) => addError(err, 'warning'),
  });

  useEffect(() => {
    if (selectedLineId) {
      const errors = dashboardData?.dashboard?.importLogs?.find((log) => log?.id === selectedLineId)?.errors;
      setErrorsData(errors ? JSON.parse(errors) : []);
    }
  }, [selectedLineId, dashboardData?.dashboard?.importLogs]);

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        field: 'importDate',
        headerName: t('dashboard.import_date'),
        valueFormatter: (params) => {
          return moment(params.value).local().format('DD-MM-YYYY HH:mm:ss');
        },
      },
      {
        field: 'object',
        headerName: t('dashboard.lib_interface'),
      },
      {
        field: 'errorsCount',
        headerName: t('dashboard.errors_count'),
      },
      {
        field: 'isSuccessful',
        headerName: t('dashboard.integration'),
        cellRenderer: (props: ICellRendererParams) => {
          return props.data.isSuccessful ? (
            <CheckCircleOutlined style={{ color: 'green' }} />
          ) : (
            <CloseCircleOutlined style={{ color: 'red' }} />
          );
        },
      },
      {
        suppressMenu: true,
        sortable: false,
        cellStyle: { textAlign: 'center' },
        maxWidth: 60,
        editable: false,
        cellRenderer: (props: ICellRendererParams) => {
          return (
            <NakedButton type="text" onClick={() => setSelectedLineId(props.data.id)}>
              <PlusCircleOutlined style={{ cursor: 'pointer', textAlign: 'center' }} />
            </NakedButton>
          );
        },
        pinned: 'right',
      },
    ],
    [t],
  );

  const columnErrorsDefs: ColDef[] = [
    {
      field: 'lineIndex',
      headerName: t('dashboard.line_index'),
    },
    {
      field: 'code',
      headerName: t('dashboard.error_message'),
      valueFormatter: (params) => {
        if (params.data.args?.length) {
          return composeTranslatedMessage(t(`errors.${params.value}`), params.data.args);
        } else {
          return t(`errors.${params.value}`);
        }
      },
    },
    {
      field: 'field',
      headerName: t('dashboard.field'),
      valueFormatter: (params) => {
        if (params.data.field) {
          return params.data.field;
        } else if (params.data.args?.length) {
          return params.data.args.join(', ');
        }
      },
    },
  ];

  const onExport = useCallback(() => {
    gridRef.current &&
      gridRef.current.api.exportDataAsExcel({
        processCellCallback: function (params) {
          if (params.column.getColId() === 'field') {
            if (params?.node?.data.field) {
              return params?.node?.data.field;
            } else if (params?.node?.data.args?.length) {
              return params?.node?.data.args.join(', ');
            }
          }

          return params.value;
        },
        fileName: `${t('dashboard.data_import_errors')}_${getFormattedCurrentDateTime()}`,
      });
  }, [t]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100,
      sortable: true,
    };
  }, []);

  return dashboardData ? (
    <>
      <StyledTitle>{t('dashboard.dashboard_title')}</StyledTitle>
      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <DetailsBox>
            <TitleWrapper>
              <BoxTitle>
                {dashboardData?.dashboard?.importLogs
                  ? moment(dashboardData?.dashboard?.importLogs[0]?.importDate).local().format('DD-MM-YYYY HH:mm:ss')
                  : ''}
              </BoxTitle>
              <SubTitle>{t('dashboard.last_data_import')}</SubTitle>
              <PlusCircleOutlined onClick={() => setDetailsModalOpen(true)} />
            </TitleWrapper>
            {errorsData ? (
              <>
                <NakedButton
                  type="text"
                  onClick={() => {
                    setErrorsData(undefined);
                    setSelectedLineId(undefined);
                  }}
                >
                  <ArrowLeftOutlined style={{ cursor: 'pointer', textAlign: 'center', fontSize: 20, marginTop: 20 }} />
                </NakedButton>
                <AgGridReact
                  containerStyle={{ width: '100%', marginTop: 10, height: 287 }}
                  ref={gridRef}
                  columnDefs={columnErrorsDefs}
                  rowData={errorsData}
                  defaultColDef={defaultColDef}
                  domLayout={'normal'}
                  getContextMenuItems={() => getContextExcelExportMenu(onExport)}
                />
              </>
            ) : (
              <AgGridReact
                containerStyle={{ width: '100%', marginTop: 20 }}
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={dashboardData.dashboard.importLogs}
                defaultColDef={defaultColDef}
                domLayout={'autoHeight'}
              />
            )}
          </DetailsBox>
          <DetailsBox style={{ marginTop: 20 }}>
            <BoxTitle>
              {dashboardData?.dashboard.exportDate
                ? moment(dashboardData?.dashboard.exportDate).local().format('DD-MM-YYYY HH:mm:ss')
                : ''}
            </BoxTitle>
            <SubTitle>{t('dashboard.last_output_file_sent')}</SubTitle>
          </DetailsBox>
        </Col>
        <Col className="gutter-row" span={12}>
          <DetailsBox>
            <BoxTitle>{t('dashboard.news')}</BoxTitle>
            {dashboardData?.dashboard?.newsletterTitle && (
              <NewsTitle>{dashboardData?.dashboard.newsletterTitle}</NewsTitle>
            )}
            {dashboardData?.dashboard?.newsletterSubtitle && (
              <NewsSubtitle>{dashboardData?.dashboard.newsletterSubtitle}</NewsSubtitle>
            )}
            {dashboardData?.dashboard?.newsletterBody && <NewsBody>{dashboardData?.dashboard.newsletterBody}</NewsBody>}
            {dashboardData?.dashboard?.newsletterImgUrl && (
              <NewsImage src={dashboardData?.dashboard.newsletterImgUrl} />
            )}
          </DetailsBox>
        </Col>
      </Row>
      <LastImportDetails isOpen={detailsModalOpen} handleCancel={() => setDetailsModalOpen(false)} />
    </>
  ) : (
    <Spinner />
  );
};

export default Home;
