import React, { CSSProperties, useCallback } from 'react';

import { AgGridReact } from 'ag-grid-react';
import ClearFiltersIcon from 'assets/images/clear-filter.png';

import { Button } from 'components/UI';
import { clearFilters } from 'helpers/grid';

interface Props {
  gridRefs: React.RefObject<AgGridReact> | React.RefObject<AgGridReact>[];
  hasFilters: boolean;
  style?: CSSProperties;
}

const ClearFiltersButton: React.FC<Props> = ({ hasFilters, gridRefs, style }) => {
  const handleClearFilters = useCallback(() => {
    const refs = !Array.isArray(gridRefs) ? [gridRefs] : gridRefs;

    refs.forEach((ref) => clearFilters(ref));
  }, [gridRefs]);

  return hasFilters ? (
    <Button style={style} onClick={handleClearFilters} size="small">
      <img src={ClearFiltersIcon} style={{ width: 16 }} />
    </Button>
  ) : null;
};

export default ClearFiltersButton;
