import { CUSTOM_FIELD_PREFIX } from './common';

export const ROW_HEADER_ID_FIELD = 'sizeSystemRowHeaderId';
export const ROW_HEADER_NAME_FIELD = 'sizeSystemRowHeaderName';

export const TYPE_CUSTOM_FIELD = `${CUSTOM_FIELD_PREFIX}type`;
export const TOTAL_CUSTOM_FIELD = `${CUSTOM_FIELD_PREFIX}total`;
export const DISCOUNT_CUSTOM_FIELD = `${CUSTOM_FIELD_PREFIX}discount`;
export const IS_GROUPING_ROW_CUSTOM_FIELD = `${CUSTOM_FIELD_PREFIX}isGrouping`;
export const ROW_PARENT_CUSTOM_FIELD = `${CUSTOM_FIELD_PREFIX}parent`;

export const TOTAL_SALES_ROW_ID = 'totalSales';
export const PHYSICAL_SALES_ROW_ID = 'physicalSales';
export const WEB_SALES_ROW_ID = 'webSales';
export const WEEKS_TOTAL_ROW_ID = 'allWeeksTotal';
export const WEEKS_FIRST_ROW_ID = 'salesFirstWeek';
export const WEEKS_SECOND_ROW_ID = 'salesSecondWeek';
export const WEEKS_THIRD_ROW_ID = 'salesThirdWeek';
export const WEEKS_FOURTH_ROW_ID = 'salesFourthWeek';
export const WEEKS_FIFTH_ROW_ID = 'salesFifthWeek';
export const STOCK_AVAILABLE_AND_IN_TRANSIT_ROW_ID = 'stockAvailableAndInTransit';
export const PICKING_QUANTITY_ROW_ID = 'stockQuantityPicking';
export const WAREHOUSE_QUANTITY_ROW_ID = 'warehouseQuantity';
export const REMAINING_QUANTITY_ROW_ID = 'remainingQuantity';
export const REMAINING_QUANTITY_ADDITIONAL_ROW_ID = 'remainingQuantityDeliveryDate';
export const DISTRIBUTION_ROW_ID = 'distribution';

export const SKIP_FILTER_TEXT = '_dummyDoNotFilterMeText_';
