import styled from 'styled-components';

export const GridsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  .ag-checkbox-input-wrapper {
    font-size: 12px !important;
    width: 11.5px;
    height: 10.5px;
    vertical-align: middle;
    margin-top: -2px;

    :after {
      top: -3px;
    }
  }

  .ag-floating-bottom {
    pointer-events: none;
  }

  .ag-row,
  .ag-header {
    font-size: 12px !important;
  }

  .cell-overridden {
    color: red !important;
  }

  .ag-row-selected .included-store {
    background-color: rgba(0, 145, 234, 0.28);
  }

  .included-store {
    background-color: ${(p) => p.theme.dark_blue_background};
    color: white;
  }

  .excluded-store {
    background-color: #d8d6d4 !important;
  }

  .excluded-store-disable,
  .excluded-store-disable input {
    cursor: not-allowed;
  }

  .product-included-store {
    background-color: ${(p) => p.theme.dark_blue_background} !important;
    color: green !important;
  }

  .product-excluded-store {
    background-color: #d8d6d4 !important;
    color: red !important;
  }

  .ag-center-cols-clipper {
    box-shadow: 0 0 4px 3px #999;
    z-index: 1;
  }
  .ag-pinned-right-floating-bottom .ant-btn {
    display: none;
  }

  .ag-header-container {
    background-color: #fff;
  }

  .ag-row {
    background-color: #f5fbff;
  }

  .ag-row-odd {
    background-color: #deedf5;
  }

  .ag-center-cols-clipper .showcaseProductRow {
    background: #5aa4d0;
    color: #fff;
  }
`;

export const OverridingContainer = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  min-height: 70px;
  justify-content: flex-start;

  .label {
    flex: 2;
    font-weight: 600;
    font-size: 16px;
    text-align: right;
    padding-right: 20px;
  }

  .inputsContainer {
    display: flex;
  }

  .inputContainer {
    flex: 1;
    padding: 0 2px;
    display: flex;
  }

  .input {
    max-width: 90%;
    text-align: center;
  }

  .dummyTotal {
    flex: 1;
    min-width: 80px;
  }

  .dummyActions {
    width: 59px;
  }

  .spinner {
    position: relative;
  }

  .spinner > div {
    position: absolute;
  }

  .spinner .ant-spin {
    padding: 7px;
  }
`;

export const ViewsContainer = styled.div`
  position: absolute;
  top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
`;
