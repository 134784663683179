import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { ColDef, CellEditingStoppedEvent, CellEditingStartedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { AlphanumericCellEditor } from 'components/AgGridComponents';
import ConfirmModal from 'components/Modals/ConfirmModal/ConfirmModal';
import { Spinner, NakedButton, PrimaryButton } from 'components/UI';
import { ActionButtonsWrapper } from 'components/wrappers';
import { CENTERED_COL_DEF, COLUMN_TYPES } from 'constants/gridConstants';
import { VALIDATIONS_TYPES } from 'constants/validations';
import { forbidEmptyCellValueSetter } from 'helpers/gridCells';
import { sortEmpty } from 'helpers/gridCols';
import { partiallyEditedGridData } from 'helpers/gridData';
import { validateGridCustom } from 'helpers/validations';
import {
  useGetWarehousesQuery,
  useBulkUpdateWarehousesMutation,
  useDeleteWarehousesMutation,
  useCreateWarehouseMutation,
} from 'services/graphql/main';
import { Warehouse as Wr } from 'services/graphql/main';
import { useError } from 'services/utils';

import { CreateButtonContainer, StyledGridWrapper } from './styles';

type RadioButtonProps = {
  checked: boolean;
  currentEditedRow: null | number;
  edit: (id: number, rowIndex: number) => any;
  id: number;
  rowIndex: number;
  setCurrentEditedRow: () => any;
};

type DeleteIconProps = {
  currentEditedRow: null | number;
  delete: (id: number, warehouse: Wr) => any;
  id: number;
  rowIndex: number;
  setCurrentEditedRow: () => any;
  warehouse: Wr;
};

type MutationStateProps = {
  data: any;
  type: string;
};

const Warehouses: React.FC = () => {
  const { t } = useTranslation('translation');
  const { addCustomError, addError } = useError();

  const { loading, data, refetch } = useGetWarehousesQuery({ onError: (err) => addError(err, 'error') });

  const [updateWarehouseMutation, { error: bulkUpdateError }] = useBulkUpdateWarehousesMutation({
    onError: (err) => addError(err, 'warning'),
  });
  const [deleteWarehousesMutation] = useDeleteWarehousesMutation({ onError: (err) => addError(err, 'warning') });
  const [createWarehouseMutation, { error: createError }] = useCreateWarehouseMutation({
    onError: (err) => addError(err, 'warning'),
  });

  const initialGridData = useRef<any>(null);
  const gridRef = useRef<AgGridReact<Wr>>(null);

  const [currentEditedRow, setCurrentEditedRow] = useState<number | null>(null);
  const [rowData, setRowData] = useState<(Wr | null)[] | null | undefined>();
  const [mutationState, setMutationState] = useState<MutationStateProps | null>(null);

  const [isUpdate, setIsUpdate] = useState({ isCreateRowActive: false, isUpdateRowActive: false, isCellActive: false });
  const hasUpdate = !isEmpty(mutationState?.data);

  const [errors, setErrors] = useState<any>(null);

  const onCellEditingStarted = useCallback(
    (event: CellEditingStartedEvent | any) => {
      if (currentEditedRow === null) {
        setCurrentEditedRow(event.rowIndex);
      }

      setIsUpdate((prevState) => ({
        ...prevState,
        isCellActive: true,
      }));
    },
    [currentEditedRow],
  );

  const onCellEditingStopped = useCallback(
    (event: CellEditingStoppedEvent | any) => {
      const defaultChanges = {
        code: event.data.code,
        name: event.data.name,
        supplierId: event.data.supplierId,
      };

      if (mutationState?.type == 'create-row') {
        setMutationState({
          type: 'create-row',
          data: {
            isRetail: event.data.isRetail,
            ...defaultChanges,
          },
        });
      } else {
        const editedRows = partiallyEditedGridData({
          event,
          prevRows: mutationState?.data,
          initialRows: initialGridData.current,
        });
        if (editedRows) {
          setMutationState({
            type: 'update-row',
            data: editedRows,
          });
        }
      }

      setIsUpdate((prevState) => ({
        ...prevState,
        isCellActive: false,
      }));

      setIsUpdate((prevState) => ({
        ...prevState,
        isCellActive: false,
      }));
    },
    [mutationState?.data, mutationState?.type],
  );

  const handleCreateWarehouseClick = useCallback(() => {
    let idRow;
    const lastElement = rowData?.at(-1);
    (lastElement && (idRow = lastElement.id + 1)) ?? (idRow = 0);
    const agGridConstructorObject = {
      id: idRow,
      code: '',
      isRetail: false,
      name: '',
      supplierId: 1,
    };
    gridRef.current!.api.applyTransaction({
      add: [agGridConstructorObject],
      addIndex: rowData?.length,
    });

    const gridData = gridRef.current!.api.getRenderedNodes();

    setTimeout(() => {
      gridRef.current!.api.startEditingCell({
        rowIndex: gridData.length - 1,
        colKey: 'code',
      });
    }, 1);

    const mutationStateNew = {
      type: 'create-row',
      data: { code: '', isRetail: false, name: '', supplierId: 1 },
    };
    setMutationState(mutationStateNew);
  }, [rowData]);

  const handleSaveClick = useCallback(async () => {
    const gridData = gridRef
      .current!.api.getRenderedNodes()
      .map((x) => x.data)
      .map((y) => {
        return {
          code: y?.code,
          name: y?.name,
        };
      });

    const { DUPLICATES, EMPTY_CELL, LENGTH } = VALIDATIONS_TYPES;

    const validations = [DUPLICATES, EMPTY_CELL, LENGTH];
    const validation = validateGridCustom(validations, gridData);

    if (validation?.isValid === false) {
      addCustomError({ message: validation?.code }, 'warning');
    } else if (mutationState) {
      switch (mutationState?.type) {
        case 'update-row':
          await updateWarehouseMutation({
            variables: {
              inputWarehouses: mutationState.data,
            },
          });

          await refetch();

          setMutationState(null);
          break;
        case 'create-row':
          await createWarehouseMutation({
            variables: {
              warehouse: mutationState.data,
            },
          });

          await refetch();

          setMutationState(null);
          break;
        default:
          break;
      }

      setIsUpdate((prevState) => ({
        ...prevState,
        isCreateRowActive: false,
        isUpdateRowActive: false,
      }));

      setCurrentEditedRow(null);
    }
  }, [addCustomError, createWarehouseMutation, mutationState, refetch, updateWarehouseMutation]);

  const columnDefs: ColDef[] = useMemo(() => {
    const RadioButton: React.FC<RadioButtonProps> = (props) => {
      const clickAction = () => {
        props.edit(props.id, props.rowIndex);
      };

      return (
        <input
          type="radio"
          disabled={isUpdate.isCreateRowActive || hasUpdate}
          name="radio"
          onClick={clickAction}
          defaultChecked={props.checked}
        />
      );
    };
    const DeleteIcon: React.FC<DeleteIconProps> = (props) => {
      const { t } = useTranslation('translation');
      const [ismModalOpen, setIsModalOpen] = useState(false);
      const showModal = () => {
        if (props.rowIndex !== props.currentEditedRow && props.currentEditedRow !== null) {
          return;
        }
        setIsModalOpen(true);
      };
      const hideModal = () => {
        setIsModalOpen(false);
      };
      const clickAction = () => {
        props.delete(props.id, props.warehouse);
        hideModal();
      };

      return (
        <div>
          <NakedButton disabled={isUpdate.isCreateRowActive || hasUpdate} type="text" onClick={showModal}>
            <DeleteOutlined style={{ cursor: 'pointer', textAlign: 'center' }} />
          </NakedButton>

          <ConfirmModal
            customTitle={t('operationalSettings.are_you_sure_you_want_to_delete_warehouse')}
            onOk={clickAction}
            open={ismModalOpen}
            onCancel={hideModal}
          />
        </div>
      );
    };

    const updateIsRetailToTrue = async (id: number) => {
      const gridData = gridRef
        .current!.api.getRenderedNodes()
        .map((x) => x.data)
        .map((y) => {
          return {
            id: y!.id,
            isRetail: y!.id === id,
            name: y?.name,
            code: y?.code,
            supplierId: y?.supplierId,
          };
        });

      await updateWarehouseMutation({
        variables: {
          inputWarehouses: gridData,
        },
      });

      await refetch();
    };

    const deleteWarehouse = async (id: number, warehouse: Wr) => {
      if (mutationState == null) {
        await deleteWarehousesMutation({
          variables: {
            id: id,
          },
        });

        await refetch();
      } else {
        gridRef.current?.api.applyTransaction({ remove: [warehouse] });
        setMutationState(null);
        setCurrentEditedRow(null);
      }
    };

    return [
      {
        field: 'code',
        ...(!mutationState === null && { sort: 'asc' }),
        comparator: sortEmpty,
        width: 300,
        headerName: t('operationalSettings.code'),
        editable: true,
        valueSetter: forbidEmptyCellValueSetter,
        cellEditor: AlphanumericCellEditor,
      },
      {
        field: 'name',
        comparator: sortEmpty,
        width: 300,
        headerName: t('operationalSettings.label'),
        editable: true,
        valueSetter: forbidEmptyCellValueSetter,
      },
      {
        ...CENTERED_COL_DEF,
        field: 'isRetail',
        sortable: false,
        width: 300,
        headerName: t('operationalSettings.warehouse_type'),
        cellRendererSelector: (params) => {
          return {
            component: RadioButton,
            params: {
              checked: params.data.isRetail,
              id: params.data.id,
              edit: updateIsRetailToTrue,
              warehouse: params.data,
              currentEditedRow: currentEditedRow,
              setCurrentEditedRow: setCurrentEditedRow,
              rowIndex: params.rowIndex,
            },
          };
        },
      },
      {
        width: 60,
        pinned: 'right',
        sortable: false,
        cellStyle: { textAlign: 'center' },
        cellRendererSelector: (params) => {
          return {
            component: DeleteIcon,
            params: {
              id: params.data.id,
              delete: deleteWarehouse,
              warehouse: params.data,
              currentEditedRow: currentEditedRow,
              setCurrentEditedRow: setCurrentEditedRow,
              rowIndex: params.rowIndex,
            },
          };
        },
      },
    ];
  }, [
    currentEditedRow,
    deleteWarehousesMutation,
    hasUpdate,
    isUpdate.isCreateRowActive,
    mutationState,
    refetch,
    t,
    updateWarehouseMutation,
  ]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      editable: true,
      suppressMovable: true,
      sortable: true,
    };
  }, []);

  const handleCancelButton = useCallback(() => {
    if (mutationState?.type === 'create-row') {
      const lastRowIndex = gridRef.current!.api.getLastDisplayedRow();
      const lastRow = gridRef.current!.api.getDisplayedRowAtIndex(lastRowIndex);

      gridRef.current?.api.applyTransaction({ remove: [lastRow?.data!] });

      const mutableData: any =
        data &&
        data.warehouses?.map((x) => {
          return {
            id: x?.id,
            isRetail: x?.isRetail,
            name: x?.name,
            code: x?.code,
            supplierId: x?.supplierId,
          };
        });

      mutableData && gridRef.current?.api.setRowData(mutableData as any);

      setRowData(mutableData);
    } else {
      const mutableData: any =
        data &&
        data.warehouses?.map((x) => {
          return {
            id: x?.id,
            isRetail: x?.isRetail,
            name: x?.name,
            code: x?.code,
            supplierId: x?.supplierId,
          };
        });

      mutableData && gridRef.current?.api.setRowData(mutableData as any);

      setRowData(mutableData);
    }

    setIsUpdate((prevState) => ({
      ...prevState,
      isCreateRowActive: false,
      isUpdateRowActive: false,
      isCellActive: false,
    }));

    setMutationState(null);
  }, [data, mutationState?.type]);

  useEffect(() => {
    const mutableData: any =
      data &&
      data.warehouses?.map((x) => {
        return {
          id: x?.id,
          isRetail: x?.isRetail,
          name: x?.name,
          code: x?.code,
          supplierId: x?.supplierId,
        };
      });

    const gridData =
      data &&
      data.warehouses?.map((y: any) => {
        return {
          id: y?.id,
          isRetail: y?.isRetail,
          name: y?.name,
          code: y?.code,
        };
      });

    initialGridData.current = gridData;

    data && setRowData(mutableData);
  }, [data]);

  useEffect(() => {
    createError?.graphQLErrors || bulkUpdateError?.graphQLErrors ? setErrors(true) : setErrors(null);
  }, [bulkUpdateError?.graphQLErrors, createError?.graphQLErrors]);

  return (
    <div>
      {loading ? (
        <Spinner size="large" />
      ) : rowData?.length ? (
        <StyledGridWrapper>
          <AgGridReact
            ref={gridRef}
            containerStyle={{ width: '100%', marginTop: 15 }}
            rowData={rowData}
            columnDefs={columnDefs}
            domLayout={'autoHeight'}
            defaultColDef={defaultColDef}
            stopEditingWhenCellsLoseFocus={true}
            onCellEditingStopped={onCellEditingStopped}
            onCellEditingStarted={onCellEditingStarted}
            columnTypes={COLUMN_TYPES}
            enableRangeSelection={true}
          />
        </StyledGridWrapper>
      ) : null}
      <ActionButtonsWrapper>
        <NakedButton
          type="text"
          text={t('operationalSettings.create_warehouse')}
          onClick={handleCreateWarehouseClick}
          disabled={isUpdate.isCreateRowActive || hasUpdate || (rowData && rowData!.length >= 2) || errors}
          style={{ color: '#FFFFFF', marginRight: 'auto' }}
        />
        {isUpdate.isCreateRowActive || hasUpdate ? (
          <>
            <NakedButton
              type="text"
              onClick={handleCancelButton}
              textKey="common.cancel"
              style={{ color: '#FFFFFF' }}
            />

            <PrimaryButton
              onClick={handleSaveClick}
              textKey="common.save"
              disabled={isUpdate.isCellActive}
            ></PrimaryButton>
          </>
        ) : null}
      </ActionButtonsWrapper>
    </div>
  );
};

export default Warehouses;
