import React, { useContext, useEffect, useRef, useState, useCallback, useMemo } from 'react';

import { CloseCircleOutlined, DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Radio, RadioChangeEvent } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { cloneDeep, differenceWith, isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';

import { ConfirmModal, InputModal } from 'components/Modals';
import { NakedButton, PrimaryButton, PrimaryCheckbox, Spinner } from 'components/UI';
import { SubActionButtonsWrapper } from 'components/wrappers';
import { CENTERED_COL_DEF, COLUMN_TYPES } from 'constants/gridConstants';
import { CollectionContext } from 'context/collection-context';
import { UniverseContext } from 'context/universe-context';
import { sortEmpty } from 'helpers/gridCols';
import {
  CombinationType,
  StoreGroupToProductModuleValueUpdateInput,
  useCreateProductModuleMutation,
  useCreateStoreGroupMutation,
  useDeleteProductModuleMutation,
  useDeleteStoreGroupMutation,
  useGetCollectionQuery,
  useUpdateStoreGroupToProductModuleMutation,
} from 'services/graphql/main';
import { useError } from 'services/utils';

import {
  ColRawDataProps,
  GetColumnDefs,
  GetRowsObjectsProps,
  GetRowsProps,
  StoreGroupProps,
  UpdateMutationStateProps,
} from '../../common/types';
import { AdaptiveGridResizer, GridButtonsWrapper, GridTypeWrapper, NoCollectionSelectedMessage } from './styles';

const AssociatedGroupsTable: React.FC = () => {
  const { t } = useTranslation('translation');
  const { addError } = useError();

  const { selectedCollection, setIsAssociatedGroupsTableUpdateActive, isCollectionsTableUpdateActive } =
    useContext(CollectionContext);
  const { selectedUniverse } = useContext(UniverseContext);

  const { loading, data, refetch } = useGetCollectionQuery({
    variables: { id: selectedCollection !== null ? selectedCollection.id : 0 },
    onError: (err) => addError(err, 'error'),
  });
  const [createProductModule] = useCreateProductModuleMutation({
    onError: (err) => addError(err, 'warning'),
  });
  const [createStoreGroup] = useCreateStoreGroupMutation({ onError: (err) => addError(err, 'warning') });
  const [updateStoreGroupToProductModule] = useUpdateStoreGroupToProductModuleMutation({
    onError: (err) => addError(err, 'warning'),
  });
  const [deleteProductModule] = useDeleteProductModuleMutation({
    onError: (err) => addError(err, 'warning'),
  });
  const [deleteStoreGroup] = useDeleteStoreGroupMutation({
    onError: (err) => addError(err, 'warning'),
  });

  const gridRef = useRef<AgGridReact>(null);
  const [combinationType, setCombinationType] = useState<CombinationType>(CombinationType.Multiple);
  const isGridEmpty = useRef<boolean | undefined>();

  const [isModalOpen, setIsModalOpen] = useState({
    headerInputModal: false,
    rowInputModal: false,
    deleteRowModal: false,
    deleteRowHeaderModal: false,
  });

  const [isUpdate, setIsUpdate] = useState({ isCreateRowActive: false, isUpdateRowActive: false, isCellActive: false });

  const [columnDefs, setColumnDefs] = useState<GetColumnDefs[]>();
  const [rowsRawData, setRowRawData] = useState<GetRowsProps[]>();

  const [newHeaderName, setNewHeaderName] = useState('');
  const [newRowName, setNewRowName] = useState('');

  const [selectedRowHeaderForDeletion, setSelectedRowHeaderForDeletion] = useState({ id: 0 });
  const [selectedRowForDeletion, setSelectedRowForDeletion] = useState({ id: 0, sortNumber: 0 });

  const [updateMutationState, setUpdateMutationState] = useState<UpdateMutationStateProps | null>(null);

  const showModal = useMemo(
    () => ({
      HEADER_INPUT_MODAL: () => {
        setIsModalOpen((prevState) => ({
          ...prevState,
          headerInputModal: true,
        }));

        setNewHeaderName('');
      },
      ROW_INPUT_MODAL: () => {
        setIsModalOpen((prevState) => ({
          ...prevState,
          rowInputModal: true,
        }));

        setNewRowName('');
      },
      DELETE_ROW_MODAL: () =>
        setIsModalOpen((prevState) => ({
          ...prevState,
          deleteRowModal: true,
        })),
      DELETE_ROW_HEADER_MODAL: () =>
        setIsModalOpen((prevState) => ({
          ...prevState,
          deleteRowHeaderModal: true,
        })),
    }),
    [],
  );

  const hideModal = useMemo(
    () => ({
      HEADER_INPUT_MODAL: () => {
        setIsModalOpen((prevState) => ({
          ...prevState,
          headerInputModal: false,
        }));
        setNewHeaderName('');
        setIsAssociatedGroupsTableUpdateActive(false);
      },
      ROW_INPUT_MODAL: () => {
        setIsModalOpen((prevState) => ({
          ...prevState,
          rowInputModal: false,
        }));
        setNewRowName('');
        setIsAssociatedGroupsTableUpdateActive(false);
      },
      DELETE_ROW_MODAL: () =>
        setIsModalOpen((prevState) => ({
          ...prevState,
          deleteRowModal: false,
        })),
      DELETE_ROW_HEADER_MODAL: () =>
        setIsModalOpen((prevState) => ({
          ...prevState,
          deleteRowHeaderModal: false,
        })),
    }),
    [setIsAssociatedGroupsTableUpdateActive],
  );

  const checkIfAllCheckboxesAreUnchecked = useCallback(() => {
    const gridData = gridRef
      .current!.api.getRenderedNodes()
      .map((x) => Object.values(x.data))
      .flat(1);
    if (gridData.includes(true)) {
      isGridEmpty.current = false;
    } else {
      isGridEmpty.current = true;
    }
  }, []);

  const handleChangeCombinationType = useCallback(
    (e: RadioChangeEvent) => {
      const rowKeys: string[][] = [];

      gridRef.current?.api.forEachNode((node) => {
        const currentArrOfStringKeys = Object.keys(node.data).slice(0, data!.collection!.productModules!.length);

        rowKeys.push(currentArrOfStringKeys);
      });

      rowKeys.forEach((row: string[]) => {
        row.forEach((key: string) => {
          gridRef.current?.api.forEachNode((node) => {
            node.setDataValue(key.toString(), false);
          });
        });
      });

      setCombinationType(e.target.value);

      checkIfAllCheckboxesAreUnchecked();

      setIsUpdate((prevState) => ({
        ...prevState,
      }));
    },
    [checkIfAllCheckboxesAreUnchecked, data],
  );

  const handleSaveMutation = useCallback(async () => {
    if (selectedCollection && updateMutationState) {
      const changedValues = differenceWith(
        updateMutationState.storeGroupToProductModule.values,
        initialValuesData.current!,
        isEqual,
      );

      const updateState = cloneDeep(updateMutationState.storeGroupToProductModule);
      updateState.values = changedValues;

      await updateStoreGroupToProductModule({
        variables: {
          storeGroupToProductModule: updateState,
        },
      });
      await refetch();
      setIsUpdate((prevState) => ({
        ...prevState,
        isCreateRowActive: false,
        isUpdateRowActive: false,
      }));
    }

    setIsAssociatedGroupsTableUpdateActive(false);
    setUpdateMutationState(null);
  }, [
    refetch,
    selectedCollection,
    setIsAssociatedGroupsTableUpdateActive,
    updateMutationState,
    updateStoreGroupToProductModule,
  ]);

  const handleCheckboxChange = useCallback(
    (e: CheckboxChangeEvent, props: any) => {
      const colId = props?.colDef?.field;

      const gridData = gridRef.current!.api.getRenderedNodes().map((x) => x.data);
      const rowNode = gridRef.current!.api.getRowNode(props.rowIndex.toString())!;

      if (combinationType && combinationType === 'MULTIPLE' && data?.collection?.productModules?.length) {
        const rowData = rowNode.data;

        for (const key in rowData) {
          const element = rowData[key];

          if (element === true || element === false) {
            rowNode.setDataValue(key, false);
          }
        }

        rowNode.setDataValue(colId!.toString(), e.target.checked);
      } else {
        rowNode.setDataValue(colId!.toString(), e.target.checked);
      }

      const finalValues: StoreGroupToProductModuleValueUpdateInput[][] = gridData.map((row: GetRowsProps) => {
        const values: StoreGroupToProductModuleValueUpdateInput[] = [];

        for (const key in row.rowValueIds) {
          if (Object.prototype.hasOwnProperty.call(row, key)) {
            const element = row.rowValueIds[key];
            element.isSelected = row[key];
            values.push(element);
          }
        }

        return values;
      });

      const finalValuesFlattened = finalValues.flat(1);

      setUpdateMutationState({
        storeGroupToProductModule: {
          collectionId: selectedCollection.id,
          combinationType: combinationType,
          values: finalValuesFlattened,
        },
      });

      setIsUpdate((prevState) => ({
        ...prevState,
        isUpdateRowActive: true,
      }));
      setIsAssociatedGroupsTableUpdateActive(true);

      checkIfAllCheckboxesAreUnchecked();
    },
    [
      checkIfAllCheckboxesAreUnchecked,
      combinationType,
      data?.collection?.productModules?.length,
      selectedCollection?.id,
      setIsAssociatedGroupsTableUpdateActive,
    ],
  );
  const CheckboxRenderer = useCallback(
    (props: ICellRendererParams) => {
      const colId = props?.colDef?.field;

      return (
        <PrimaryCheckbox
          isChecked={props.data[Number(colId)]}
          onChange={(e) => handleCheckboxChange(e, props)}
          disabled={isCollectionsTableUpdateActive}
          style={{ cursor: 'pointer', textAlign: 'center' }}
        />
      );
    },
    [handleCheckboxChange, isCollectionsTableUpdateActive],
  );

  const handleAddColumnButton = useCallback(() => {
    setIsAssociatedGroupsTableUpdateActive(true);
    showModal['HEADER_INPUT_MODAL']();
  }, [setIsAssociatedGroupsTableUpdateActive, showModal]);

  const AddColumnButton = useCallback(() => {
    return (
      <NakedButton
        disabled={isUpdate.isCreateRowActive || isUpdate.isUpdateRowActive || isCollectionsTableUpdateActive}
        type="text"
        onClick={handleAddColumnButton}
      >
        <PlusCircleOutlined style={{ cursor: 'pointer', fontSize: 16 }} />
      </NakedButton>
    );
  }, [handleAddColumnButton, isCollectionsTableUpdateActive, isUpdate.isCreateRowActive, isUpdate.isUpdateRowActive]);

  const handlePrepareRemoveRowHeader = useCallback(
    async (props: any) => {
      const headerId = Number(props!.api!.getColumnDef(props.column).field);

      setIsAssociatedGroupsTableUpdateActive(true);
      setSelectedRowHeaderForDeletion({ id: headerId });
      showModal['DELETE_ROW_HEADER_MODAL']();
    },
    [setIsAssociatedGroupsTableUpdateActive, showModal],
  );

  const RemoveHeaderRenderer = useCallback(
    (props: any) => {
      const headerName = props!.api!.getColumnDef(props.column)!.headerName;

      return (
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', alignItems: 'center' }}>
          {headerName}
          <NakedButton
            disabled={isUpdate.isCreateRowActive || isUpdate.isUpdateRowActive || isCollectionsTableUpdateActive}
            type="text"
            onClick={() => handlePrepareRemoveRowHeader(props)}
          >
            <CloseCircleOutlined style={{ cursor: 'pointer' }} />
          </NakedButton>
        </div>
      );
    },
    [
      handlePrepareRemoveRowHeader,
      isCollectionsTableUpdateActive,
      isUpdate.isCreateRowActive,
      isUpdate.isUpdateRowActive,
    ],
  );

  const handlePrepareRemoveRow = useCallback(
    (props: ICellRendererParams) => {
      setIsAssociatedGroupsTableUpdateActive(true);
      setSelectedRowForDeletion(props.node.data);
      showModal['DELETE_ROW_MODAL']();
    },
    [setIsAssociatedGroupsTableUpdateActive, showModal],
  );

  const RemoveRenderer = useCallback(
    (props: ICellRendererParams) => {
      return (
        <NakedButton
          disabled={isUpdate.isCreateRowActive || isUpdate.isUpdateRowActive || isCollectionsTableUpdateActive}
          type="text"
          onClick={() => handlePrepareRemoveRow(props)}
        >
          <DeleteOutlined style={{ cursor: 'pointer', textAlign: 'center' }} />
        </NakedButton>
      );
    },
    [handlePrepareRemoveRow, isCollectionsTableUpdateActive, isUpdate.isCreateRowActive, isUpdate.isUpdateRowActive],
  );

  const prepareValuesBeforeRequest = useCallback(() => {
    const gridData = gridRef.current!.api.getRenderedNodes().map((x) => x.data);

    const finalValues: StoreGroupToProductModuleValueUpdateInput[][] = gridData.map((row: GetRowsProps) => {
      const values: StoreGroupToProductModuleValueUpdateInput[] = [];

      for (const key in row.rowValueIds) {
        if (Object.prototype.hasOwnProperty.call(row, key)) {
          const element = row.rowValueIds[key];
          element.isSelected = row[key];
          values.push(element);
        }
      }

      return values;
    });

    const finalValuesFlattened = finalValues.flat(1);

    return finalValuesFlattened;
  }, []);

  const handleAddCol = useCallback(async () => {
    if (selectedCollection && newHeaderName) {
      await createProductModule({
        variables: {
          productModule: {
            name: selectedCollection?.code + ' - ' + newHeaderName.trim(),
            collectionId: selectedCollection.id,
            universeId: selectedUniverse.id,
          },
        },
      });

      const gridValues = prepareValuesBeforeRequest();

      await updateStoreGroupToProductModule({
        variables: {
          storeGroupToProductModule: {
            collectionId: selectedCollection?.id,
            combinationType: combinationType,
            values: gridValues,
          },
        },
      });

      await refetch();
    }

    setIsAssociatedGroupsTableUpdateActive(false);

    hideModal['HEADER_INPUT_MODAL']();
  }, [
    combinationType,
    createProductModule,
    hideModal,
    newHeaderName,
    prepareValuesBeforeRequest,
    refetch,
    selectedCollection,
    selectedUniverse.id,
    setIsAssociatedGroupsTableUpdateActive,
    updateStoreGroupToProductModule,
  ]);

  const handleAddRow = useCallback(async () => {
    if (selectedCollection && newRowName) {
      await createStoreGroup({
        variables: {
          storeGroup: {
            name: selectedCollection?.code + ' - ' + newRowName.trim(),
            collectionId: selectedCollection.id,
            universeId: selectedUniverse.id,
          },
        },
      });

      const gridValues = prepareValuesBeforeRequest();

      await updateStoreGroupToProductModule({
        variables: {
          storeGroupToProductModule: {
            collectionId: selectedCollection?.id,
            combinationType: combinationType,
            values: gridValues,
          },
        },
      });

      await refetch();
    }

    setIsAssociatedGroupsTableUpdateActive(false);

    hideModal['ROW_INPUT_MODAL']();
  }, [
    combinationType,
    createStoreGroup,
    hideModal,
    newRowName,
    prepareValuesBeforeRequest,
    refetch,
    selectedCollection,
    selectedUniverse.id,
    setIsAssociatedGroupsTableUpdateActive,
    updateStoreGroupToProductModule,
  ]);

  const handleRemoveRowHeader = useCallback(async () => {
    if (selectedCollection && selectedRowHeaderForDeletion.id != 0) {
      await deleteProductModule({
        variables: { id: selectedRowHeaderForDeletion.id },
      });

      await refetch();
    }

    setSelectedRowHeaderForDeletion({ id: 0 });
    hideModal['DELETE_ROW_HEADER_MODAL']();
    setIsUpdate((prevState) => ({
      ...prevState,
      isCreateRowActive: false,
    }));
    setIsAssociatedGroupsTableUpdateActive(false);
  }, [
    deleteProductModule,
    hideModal,
    refetch,
    selectedCollection,
    selectedRowHeaderForDeletion.id,
    setIsAssociatedGroupsTableUpdateActive,
  ]);

  const handleRemoveRow = useCallback(async () => {
    if (selectedCollection && selectedRowForDeletion.id != 0) {
      await deleteStoreGroup({ variables: { id: selectedRowForDeletion.id } });

      await refetch();
    }

    setSelectedRowForDeletion({ id: 0, sortNumber: 0 });
    hideModal['DELETE_ROW_MODAL']();
    setIsUpdate((prevState) => ({
      ...prevState,
      isCreateRowActive: false,
    }));
    setIsAssociatedGroupsTableUpdateActive(false);
  }, [
    deleteStoreGroup,
    hideModal,
    refetch,
    selectedCollection,
    selectedRowForDeletion.id,
    setIsAssociatedGroupsTableUpdateActive,
  ]);

  const getColumnDefs = useCallback(
    (colsRawData: (ColRawDataProps | null)[] | null | undefined): GetColumnDefs[] => {
      const colDefs =
        colsRawData?.map((value: ColRawDataProps | null) => ({
          headerComponent: RemoveHeaderRenderer,
          field: value!.id.toString() || undefined,
          headerName: value!.name || undefined,
          cellRenderer: CheckboxRenderer,
          ...CENTERED_COL_DEF,
        })) || [];

      return colDefs;
    },
    [CheckboxRenderer, RemoveHeaderRenderer],
  );

  const getRowsData = useCallback((rowsRawData: (StoreGroupProps | null)[] | null | undefined) => {
    const rows = rowsRawData
      ?.map((obj: StoreGroupProps | null) => {
        return {
          id: obj?.id,
          name: obj?.name,
          ...obj?.storeGroupToProductModules,
        };
      })
      .map((obj: GetRowsObjectsProps) => {
        const constructorObject: GetRowsProps = {
          id: obj?.id,
          name: obj?.name,
          magOrModule: obj?.name,
          rowValueIds: {},
        };

        for (const key in obj) {
          if (obj[key].productModuleId) {
            constructorObject[obj[key]?.productModuleId] = obj[key]?.isSelected;
            constructorObject.rowValueIds[obj[key]?.productModuleId] = {
              storeGroupToProductModuleId: obj[key].id,
              isSelected: obj[key].isSelected,
              productModuleId: obj[key].productModuleId,
              storeGroupId: obj[key].storeGroupId,
            };
          }
        }

        return constructorObject;
      });

    return rows;
  }, []);

  const defaultColDef: ColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 100,
      suppressMovable: true,
    }),
    [],
  );

  const handleCancelButton = useCallback(() => {
    const rows = getRowsData(data?.collection?.storeGroups);

    rows && gridRef.current?.api.setRowData(rows);

    setCombinationType(data?.collection?.combinationType!);
    setRowRawData(rows);

    setIsUpdate((prevState) => ({
      ...prevState,
      isCreateRowActive: false,
      isUpdateRowActive: false,
      isCellActive: false,
    }));

    setUpdateMutationState(null);

    setIsAssociatedGroupsTableUpdateActive(false);
  }, [
    data?.collection?.combinationType,
    data?.collection?.storeGroups,
    getRowsData,
    setIsAssociatedGroupsTableUpdateActive,
  ]);

  useEffect(() => {
    if (data) {
      const columns: GetColumnDefs[] = [
        {
          headerName: t('operationalSettings.grp_mag_module'),
          field: 'magOrModule',
          ...(!updateMutationState === null && { sort: 'asc' }),
          comparator: sortEmpty,
          sortable: true,
          // pinned: 'left',
        },
        ...getColumnDefs(data.collection?.productModules),
        {
          headerComponent: AddColumnButton,
          maxWidth: 45,
          sortable: false,
          cellStyle: { textAlign: 'center' },
          suppressMenu: true,
          cellRenderer: RemoveRenderer,
          pinned: 'right',
        },
      ];

      setColumnDefs(columns);
    } else {
      setColumnDefs([]);
    }
  }, [data, isCollectionsTableUpdateActive, t, updateMutationState, AddColumnButton, RemoveRenderer, getColumnDefs]);

  const initialValuesData = useRef<StoreGroupToProductModuleValueUpdateInput[] | null>(null);

  useEffect(() => {
    if (data) {
      const rows = getRowsData(data.collection?.storeGroups);

      setRowRawData(rows);

      if (rows) {
        initialValuesData.current = [];
        cloneDeep(rows).forEach((row) => {
          initialValuesData.current?.push(...Object.values(row.rowValueIds));
        });
      }
    } else {
      setRowRawData([]);
    }
  }, [data, getRowsData]);

  useEffect(() => {
    if (data) {
      setCombinationType(data?.collection?.combinationType!);
    }
  }, [data]);

  return selectedCollection === null ? (
    <NoCollectionSelectedMessage>
      <p>{t('operationalSettings.no_collection_selected')}</p>
    </NoCollectionSelectedMessage>
  ) : loading ? (
    <Spinner size="large" />
  ) : (
    <AdaptiveGridResizer>
      <div>
        <p style={{ color: '#ffffff' }}>{t('operationalSettings.type_association_collection_store_group')}</p>
        <GridTypeWrapper>
          <Radio.Group
            disabled={isCollectionsTableUpdateActive}
            onChange={handleChangeCombinationType}
            value={combinationType}
          >
            <Radio value={'EXCLUSIVE'} style={{ color: '#ffffff' }}>
              {t('operationalSettings.exclusive')}
            </Radio>
            <Radio value={'MULTIPLE'} style={{ color: '#ffffff' }}>
              {t('operationalSettings.multiple')}
            </Radio>
          </Radio.Group>
        </GridTypeWrapper>
      </div>

      <AgGridReact
        containerStyle={{ width: '100%', marginTop: 15, height: 'auto' }}
        ref={gridRef}
        columnDefs={columnDefs}
        rowData={rowsRawData}
        defaultColDef={defaultColDef}
        groupDefaultExpanded={1}
        suppressAggFuncInHeader={true}
        animateRows={true}
        domLayout={'autoHeight'}
        columnTypes={COLUMN_TYPES}
        enableRangeSelection={true}
      />

      <GridButtonsWrapper>
        <NakedButton
          disabled={isUpdate.isCreateRowActive || isUpdate.isUpdateRowActive || isCollectionsTableUpdateActive}
          type="link"
          onClick={showModal['ROW_INPUT_MODAL']}
          text={`+${t('operationalSettings.add_new_store_group')}`}
          style={{ color: '#ffffff' }}
        />

        {isUpdate.isCreateRowActive || isUpdate.isUpdateRowActive ? (
          <SubActionButtonsWrapper>
            <NakedButton type="text" onClick={handleCancelButton} textKey="common.cancel" />

            <PrimaryButton
              onClick={handleSaveMutation}
              textKey="common.save"
              disabled={isUpdate.isCellActive || isGridEmpty.current || isCollectionsTableUpdateActive}
            />
          </SubActionButtonsWrapper>
        ) : null}
      </GridButtonsWrapper>

      <InputModal
        inputPrefix={selectedCollection?.code + ' - '}
        customTitle={
          isModalOpen.headerInputModal ? t('operationalSettings.header_name') : t('operationalSettings.row_name')
        }
        onOk={isModalOpen.headerInputModal ? handleAddCol : handleAddRow}
        state={isModalOpen.headerInputModal ? newHeaderName : newRowName}
        setState={isModalOpen.headerInputModal ? setNewHeaderName : setNewRowName}
        open={isModalOpen.headerInputModal || isModalOpen.rowInputModal}
        onCancel={isModalOpen.headerInputModal ? hideModal['HEADER_INPUT_MODAL'] : hideModal['ROW_INPUT_MODAL']}
      />

      <ConfirmModal
        customTitle={
          isModalOpen.deleteRowHeaderModal
            ? t('operationalSettings.delete_row_header_warning_message')
            : t('operationalSettings.delete_row_warning_message')
        }
        onOk={isModalOpen.deleteRowHeaderModal ? handleRemoveRowHeader : handleRemoveRow}
        open={isModalOpen.deleteRowHeaderModal || isModalOpen.deleteRowModal}
        onCancel={
          isModalOpen.deleteRowHeaderModal ? hideModal['DELETE_ROW_HEADER_MODAL'] : hideModal['DELETE_ROW_MODAL']
        }
      />
    </AdaptiveGridResizer>
  );
};

export default AssociatedGroupsTable;
