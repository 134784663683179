import React from 'react';

import { OptionProps } from 'antd/lib/select';

import { StyledSelect } from './styles';

const PrimarySelect: React.FC<OptionProps> = ({ children, ...rest }) => {
  return <StyledSelect {...rest}>{children}</StyledSelect>;
};

export default PrimarySelect;
