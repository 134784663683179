import React from 'react';

import { TableViewsContextProvider } from 'context';

import ProductPerformanceTable from 'screens/ProductPerformance/ProductPerformanceTable/ProductPerformanceTable';

const ProductPerformance: React.FC = () => {
  return (
    <TableViewsContextProvider tableName="productPerformanceWip">
      <ProductPerformanceTable />
    </TableViewsContextProvider>
  );
};

export default ProductPerformance;
