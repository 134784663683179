import { Space } from 'antd';
import styled from 'styled-components';

import { TYPE_CUSTOM_FIELD } from 'screens/ProductDetails/common/constants/previousDaySummaryTable';

export const GroupingGridWrapper = styled('div')`
  [col-id='${TYPE_CUSTOM_FIELD}'] {
    font-size: calc(var(--ag-font-size));
  }

  .ag-header-cell,
  .ag-header-group-cell {
    padding-left: 10px;
    padding-right: 10px;
  }

  .groupingTypeCellContent,
  .parentTypeCellContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }

  .groupingTypeCellContent,
  .parentTypeCellContent {
    font-weight: bold;
  }

  .childTypeCellContent {
    padding-left: 20px;
    text-align: left;
  }

  .parentRowCell {
    font-weight: bold;
  }

  .childRowCell {
    font-weight: normal;
  }
`;

export const StyledSpace = styled(Space)`
  width: 100%;
`;
