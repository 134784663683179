import React, { useEffect, useState } from 'react';

import { FullscreenSpinner } from 'components/UI';
import { useGetUserSettingsQuery, PermissionsEnum } from 'services/graphql/main';
import { useError } from 'services/utils';

type PermissionsContextType = {
  permissions: PermissionsEnum[] | [] | null | undefined;
};

export const PermissionsContext = React.createContext<PermissionsContextType>({} as PermissionsContextType);

type ContextProviderType = {
  children: React.ReactNode;
};

export const PermissionsContextProvider: React.FC<ContextProviderType> = ({ children }) => {
  const [permissions, setPermissions] = useState<PermissionsEnum[] | [] | null>();

  const { addError } = useError();
  const { data, loading } = useGetUserSettingsQuery({ onError: (err) => addError(err, 'error') });

  useEffect(() => {
    if (data) {
      setPermissions(data.userSettings.permissions);
    }
  }, [data]);

  return (
    <PermissionsContext.Provider
      value={{
        permissions,
      }}
    >
      {loading ? <FullscreenSpinner /> : children}
    </PermissionsContext.Provider>
  );
};
