import React from 'react';

import { TableViewsContextProvider } from 'context';

import StoreFleetTable from './StoreFleetTable/StoreFleetTable';

const StoreFleet: React.FC = () => {
  return (
    <TableViewsContextProvider tableName="storeFleet">
      <StoreFleetTable />
    </TableViewsContextProvider>
  );
};

export default StoreFleet;
