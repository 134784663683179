import React from 'react';

import { TableViewsContextProvider } from 'context';

import StorePerformanceTable from './StorePerformanceTable/StorePerformanceTable';

const StorePerformance: React.FC = () => {
  return (
    <TableViewsContextProvider tableName="storePerformance">
      <StorePerformanceTable />
    </TableViewsContextProvider>
  );
};

export default StorePerformance;
