import React from 'react';

import { ICellRendererParams } from 'ag-grid-community';

import { Switch } from 'components/UI';

export const booleanValueRenderer = (params: ICellRendererParams) => {
  const value = params.valueFormatted ? params.valueFormatted : params.value;

  return (
    <div className="switchContainer">
      <Switch size="small" defaultChecked={!!value} disabled />
    </div>
  );
};
export const checkValueRenderer = (params: ICellRendererParams) => {
  const value = params.valueFormatted ? params.valueFormatted : params.value;
  if (value === null || value === undefined) return null;

  return (
    <span
      style={{ fontSize: 24, color: value ? 'green' : 'red' }}
      className={`ag-icon ${value ? 'ag-icon-tick' : 'ag-icon-cross'}`}
    />
  );
};
