import React, { useState } from 'react';

import { Button } from 'antd';
import * as FileSaver from 'file-saver';
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';

import { ConfirmModal, StoreDetailModal } from 'components/Modals';

import UsersManagement from '../UsersManagement/UsersManagement';
import StoreFleetTable from './components/ProductIdealStockTable/ProductIdealStockTable';
import { ActionWrapper } from './styles';

const TestScreen: React.FC = () => {
  const [isProductViewModalOpen, setIsProductViewModalOpen] = useState(false);
  const [isStoreDetailModalOpen, setIsStoreDetailModalOpen] = useState(false);
  const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);

  const showCustomModal = () => {
    setIsCustomModalOpen(true);
  };

  const hideCustomModal = () => {
    setIsCustomModalOpen(false);
  };

  const showModal = {
    PRODUCT_OVERVIEW_MODAL: () => setIsProductViewModalOpen(true),
    STORE_DETAIL_MODAL: () => setIsStoreDetailModalOpen(true),
  };

  const handleCancel = {
    PRODUCT_OVERVIEW_MODAL: () => setIsProductViewModalOpen(false),
    STORE_DETAIL_MODAL: () => setIsStoreDetailModalOpen(false),
  };
  const csvData: any = [
    {
      id: 1,
      code: 'ATU',
      name: 'ATU',
      universeId: 1,
    },
    {
      id: 2,
      code: 'BB',
      name: 'BB',
      universeId: 1,
    },
    {
      id: 8,
      code: 'GC',
      name: 'GC',
      universeId: 1,
    },
    {
      id: 9,
      code: 'GCH',
      name: 'GCH',
      universeId: 1,
    },
    {
      id: 11,
      code: 'GE',
      name: 'GE',
      universeId: 1,
    },
    {
      id: 12,
      code: 'GF',
      name: 'GF',
      universeId: 1,
    },
    {
      id: 13,
      code: 'GFD',
      name: 'GFD',
      universeId: 1,
    },
    {
      id: 14,
      code: 'GH',
      name: 'GH',
      universeId: 1,
    },
    {
      id: 15,
      code: 'GHA',
      name: 'GHA',
      universeId: 1,
    },
    {
      id: 19,
      code: 'GMF',
      name: 'GMF',
      universeId: 1,
    },
    {
      id: 20,
      code: 'GMH',
      name: 'GMH',
      universeId: 1,
    },
    {
      id: 21,
      code: 'GMI',
      name: 'GMI',
      universeId: 1,
    },
    {
      id: 22,
      code: 'GP',
      name: 'GP',
      universeId: 1,
    },
    {
      id: 24,
      code: 'GTU',
      name: 'GTU',
      universeId: 1,
    },
    {
      id: 25,
      code: 'HTA',
      name: 'HTA',
      universeId: 1,
    },
    {
      id: 31,
      code: 'ASD',
      name: 'ASD',
      universeId: 1,
    },
    {
      id: 32,
      code: '231',
      name: '123',
      universeId: 1,
    },
    {
      id: 41,
      code: 'asdasdasdasdasdasddadsasddssad',
      name: 'fefr',
      universeId: 1,
    },
    {
      id: 46,
      code: 'regrege',
      name: 'ergreg',
      universeId: 1,
    },
  ];

  const jsonCsvData = JSON.stringify(csvData);
  const fileName = 'ExampleFileName';
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (jsonCsvData: any, fileName: any) => {
    const ws = XLSX.utils.json_to_sheet(jsonCsvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <div>
      {/* <UsersManagement /> */}
      {/* <StoreFleetTable />
      <br />
      <ActionWrapper>
        <Button type="primary" onClick={showModal['PRODUCT_OVERVIEW_MODAL']}>
          Open Modal
        </Button>

        <Button className="asd" type="primary" onClick={showModal['STORE_DETAIL_MODAL']}>
          Store Detail
        </Button>

        <Button type="primary" onClick={showCustomModal}>
          Antd modal
        </Button>
      </ActionWrapper>

      <ProductOverviewModal isOpen={isProductViewModalOpen} handleCancel={handleCancel['PRODUCT_OVERVIEW_MODAL']} />
      <StoreDetailModal isOpen={isStoreDetailModalOpen} handleCancel={handleCancel['STORE_DETAIL_MODAL']} />
      <ConfirmModal
        customTitle="Antd modal"
        onOk={() => {
          console.log('confirm');
        }}
        text="Some text we will push here!"
        open={isCustomModalOpen}
        onCancel={hideCustomModal}
      />

      <Button>
        <CSVLink data={csvData} filename={fileName}>
          Export TPL
        </CSVLink>
      </Button>
      <Button onClick={(e) => exportToCSV(csvData, fileName)}>Export XLSX</Button> */}
    </div>
  );
};

export default TestScreen;
