import styled from 'styled-components';

export const ActionButtonsWrapper = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;

  .iconButton svg {
    font-size: 20px;
    width: 20px;
    height: 20px;
  }
`;
