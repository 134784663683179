import React, { useCallback, useState } from 'react';

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { useTranslation } from 'react-i18next';

import { StyledItem } from './styles';

interface Props {
  defaultValue?: string | string[];
  label: string;
  onChange: (value: string[]) => void;
  options?: DefaultOptionType[];
  singleSelect?: boolean;
  value?: string | string[] | null | undefined;
}

const Filter: React.FC<Props> = ({ defaultValue, label, onChange, options, singleSelect }) => {
  const { t } = useTranslation('translation');

  const [value, setValue] = useState<string | string[]>(() => defaultValue || []);

  const applyValue = useCallback(
    (value: string[]) => {
      setValue(value);
      onChange(value);
    },
    [onChange],
  );

  const handleSelectAll = useCallback(() => {
    const newValue = options?.map((option) => option.value as string) || [];

    applyValue(newValue);
  }, [applyValue, options]);

  const handleClearAll = useCallback(() => {
    applyValue([]);
  }, [applyValue]);

  const allSelected = value.length === options?.length;

  return (
    <StyledItem
      label={
        <>
          <span className="labelText">
            {/* <Tooltip title={label} showArrow={false}> */}
            {label}
            {/* </Tooltip> */}
            &nbsp;
          </span>

          {/*<CheckCircleOutlined*/}
          {/*  onClick={handleSelectAll}*/}
          {/*  style={allSelected ? { opacity: 0.3, pointerEvents: 'none' } : undefined}*/}
          {/*/>*/}
          {/*<CloseCircleOutlined*/}
          {/*  onClick={handleClearAll}*/}
          {/*  style={!value.length ? { opacity: 0.3, pointerEvents: 'none' } : undefined}*/}
          {/*/>*/}
        </>
      }
    >
      <Select
        mode={singleSelect ? undefined : 'multiple'}
        placeholder={t('common.all')}
        defaultValue={defaultValue as string[]}
        onChange={applyValue}
        value={value as string[]}
        options={options}
        showArrow
        maxTagCount="responsive"
        maxTagPlaceholder={(omittedItems) => {
          return (
            <span className="maxTag" title={omittedItems.map((item) => item.label).join('; ')}>
              +{omittedItems.length}
            </span>
          );
        }}
      />
    </StyledItem>
  );
};

export default Filter;
