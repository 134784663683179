import React, { useContext, useMemo, useState } from 'react';

import { Collapse } from 'antd';
import { ProductContext } from 'context';
import { useTranslation } from 'react-i18next';

import { DataVariant } from 'screens/ProductDetails/common/types';

import DistributionBySizeTable from './DistributionBySizeTable/DistributionBySizeTable';
import OptimalStockCalculationTable from './OptimalStockCalculationTable/OptimalStockCalculationTable';
import PreviousDaySummaryTable from './PreviousDaySummaryTable/PreviousDaySummaryTable';
import { StyledPanel, StyledSwitch, StyledTag } from './styles';

type Props = {
  isProductArchived: boolean;
};

const DetailStockContent: React.FC<Props> = ({ isProductArchived }) => {
  const { t } = useTranslation('translation');
  const { sizeSystemRowName } = useContext(ProductContext);

  const [previousDateSummaryTableDataType, setPreviousDateSummaryTableDataType] = useState<DataVariant>('quantity');

  const DistributionBySizeHeader = useMemo(
    () => (
      <>
        {t('productOptimalStock.distribution_by_size')}

        <StyledTag>{sizeSystemRowName}</StyledTag>
      </>
    ),
    [sizeSystemRowName, t],
  );

  const PreviousDaySummaryHeader = (
    <>
      {t('productOptimalStock.previous_day_summary')}

      <StyledSwitch
        alwaysActive
        checkedChildren={t('productOptimalStock.quantity_abbreviation')}
        unCheckedChildren="%"
        defaultChecked
        size="small"
        onChange={(checked, e) => {
          e.stopPropagation();
          setPreviousDateSummaryTableDataType(checked ? 'quantity' : 'percent');
        }}
      />
    </>
  );

  return (
    <Collapse defaultActiveKey={['previousDaySummaryTable', 'optimalStockCalculationTable']} ghost>
      <StyledPanel header={DistributionBySizeHeader} key="distributionTable" forceRender>
        <DistributionBySizeTable isProductArchived={isProductArchived} />
      </StyledPanel>

      <StyledPanel header={PreviousDaySummaryHeader} key="previousDaySummaryTable">
        <PreviousDaySummaryTable dataVariant={previousDateSummaryTableDataType} />
      </StyledPanel>

      <StyledPanel header={t('productOptimalStock.optimal_stock_calculation')} key="optimalStockCalculationTable">
        <OptimalStockCalculationTable isProductArchived={isProductArchived} />
      </StyledPanel>
    </Collapse>
  );
};

export default DetailStockContent;
