import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-use';

import AllRoutes from 'screens/AllRoutes';

import Product from './Product/Product';
import Shop from './Shop/Shop';
import { StyledTabs } from './styles';
import Warehouses from './Warehouses/Warehouses';

const TableViewsManagement: React.FC = () => {
  const [activeTab, setActiveTab] = useState('#product');
  const { hash } = useLocation();
  const navigate = useNavigate();
  const onChangeTabs = (key: string) => {
    navigate(`${AllRoutes.TableViewsManagementSettings}${key}`);
  };

  useEffect(() => {
    hash && setActiveTab(hash);
  }, [hash]);

  return (
    <div>
      <StyledTabs
        activeKey={activeTab}
        onChange={onChangeTabs}
        itemsWithTextKeys={[
          {
            labelKey: 'operationalSettings.product',
            key: '#product',
            children: <Product />,
          },
          {
            labelKey: 'operationalSettings.shop',
            key: '#shop',
            children: <Shop />,
          },
          {
            labelKey: 'operationalSettings.warehouse',
            key: '#warehouse',
            children: <Warehouses />,
          },
        ]}
      />
    </div>
  );
};

export default TableViewsManagement;
