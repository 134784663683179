import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { CloseCircleOutlined, DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { ColDef, ICellEditorParams, ICellRendererParams, RowEditingStoppedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Select } from 'antd';
import { differenceWith, isEqual, isNumber } from 'lodash';
import { useTranslation } from 'react-i18next';
import { getFormattedCurrentDateTime } from 'utils/moment';

import { AlphanumericCellEditor, NumericCellEditor } from 'components/AgGridComponents';
import { percentFormatter } from 'components/AgGridComponents/valueFormatters';
import { ConfirmModal, InputModal } from 'components/Modals';
import { NakedButton, PrimaryButton, PrimarySelect, Spinner } from 'components/UI';
import { ActionButtonsWrapper, SubActionButtonsWrapper } from 'components/wrappers';
import { CENTERED_COL_DEF, SIDE_BAR } from 'constants/gridConstants';
import { VALIDATIONS_TYPES } from 'constants/validations';
import { SizeSystemContext } from 'context/size-system-context';
import { forbidEmptyCellValueSetter } from 'helpers/gridCells';
import { sortEmpty } from 'helpers/gridCols';
import { getContextMenu } from 'helpers/gridContextMenu';
import { validateGridCustom } from 'helpers/validations';
import {
  ConstructorRowsProps,
  DistributionGridProps,
  GetColumnDefs,
  GetRowsProps,
  GridDataDistributionProps,
  GridRefsProps,
  MutationStateProps,
} from 'screens/OperationalSettings/SizeSystem/common/types';
import {
  DistributionGridSelectWrapper,
  NoSizeSystemSelectedMessage,
  StyledTotalStatusCheckSuccess,
  StyledTotalStatusCheckWarning,
} from 'screens/OperationalSettings/SizeSystem/components/styles';
import { COMMON_DEFAULT_COL_DEFS } from 'screens/ProductDetails/common/constants/optimalStockCalculationTable';
import {
  SizeSystemRow,
  SizeSystemRowCreateInput,
  SizeSystemRowHeader,
  SizeSystemRowUpdateInput,
  useBulkUpdateSizeSystemRowsMutation,
  useCreateSizeSystemRowHeaderMutation,
  useCreateSizeSystemRowMutation,
  useDeleteSizeSystemRowHeaderMutation,
  useDeleteSizeSystemRowMutation,
  useGetSizeSystemRowHeadersQuery,
  useGetSizeSystemRowQuery,
  useUpdateSizeSystemRowHeaderMutation,
  useDeselectSizeSystemIsOnShowcaseMutation,
} from 'services/graphql/main';
import { useError } from 'services/utils';

const DistributionGrid: React.FC<DistributionGridProps> = ({
  hideItemSelector,
  disableEditableMode,
  hideAddDeleteControls,
  additionalColDefs,
  defaultColDefOverrides,
  onRowSelect,
  sizeSystemRowName,
  disableNewRowAdd,
  domLayout,
}) => {
  const { t } = useTranslation('translation');
  const { addCustomError, addError } = useError();
  const { Option } = Select;

  const initialGridData = useRef<GridDataDistributionProps>(null);
  const gridRef = useRef<AgGridReact>(null);

  const { selectedSizeSystem, setIsDistributionTableUpdateActive, isSizeSystemUpdateActive } =
    useContext(SizeSystemContext);

  const [rowsRawData, setRowsRawData] = useState<(SizeSystemRow | null)[] | null | undefined>();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>();

  const [newHeaderName, setNewHeaderName] = useState('');

  const [isModalOpen, setIsModalOpen] = useState({
    inputModal: false,
    deleteRowModal: false,
    deleteRowHeaderModal: false,
  });

  const [selectedRowForDeletion, setSelectedRowForDeletion] = useState({ id: 0, sortNumber: 0 });
  const [selectedRowHeaderForDeletion, setSelectedRowHeaderForDeletion] = useState({ id: 0 });

  const [mutationState, setMutationState] = useState<MutationStateProps | null>(null);

  const [isUpdate, setIsUpdate] = useState({ isCreateRowActive: false, isUpdateRowActive: false, isCellActive: false });

  const [errors, setErrors] = useState<any>(null);
  const [isGridReady, setIsGridReady] = useState(false);

  const {
    loading: headersLoading,
    data: headersData,
    refetch: headersRefetch,
  } = useGetSizeSystemRowHeadersQuery({
    variables: { sizeSystemId: selectedSizeSystem || 0 },
    onError: (err) => addError(err, 'error'),
  });

  const {
    loading: rowsLoading,
    data: rowsData,
    refetch: rowsRefetch,
  } = useGetSizeSystemRowQuery({
    variables: { sizeSystemId: selectedSizeSystem || 0 },
    onError: (err) => addError(err, 'error'),
  });

  const [createSizeSystemRowHeader] = useCreateSizeSystemRowHeaderMutation({
    onError: (err) => addError(err, 'warning'),
  });
  const [createSizeSystemRow, { error: createError }] = useCreateSizeSystemRowMutation({
    onError: (err) => addError(err, 'warning'),
  });

  const [updateSizeSystemRowHeader] = useUpdateSizeSystemRowHeaderMutation({
    onError: (err) => addError(err, 'warning'),
  });
  const [updateBulkSizeSystemRows, { error: bulkUpdateError }] = useBulkUpdateSizeSystemRowsMutation({
    onError: (err) => addError(err, 'warning'),
  });

  const [deleteSizeSystemRow] = useDeleteSizeSystemRowMutation({
    onError: (err) => addError(err, 'warning'),
  });

  const [deleteSizeSystemRowHeader] = useDeleteSizeSystemRowHeaderMutation({
    onError: (err) => addError(err, 'warning'),
  });

  const [deselectSizeSystemIsOnShowcase] = useDeselectSizeSystemIsOnShowcaseMutation({
    onError: (err) => addError(err, 'warning'),
  });

  const showModal = useMemo(
    () => ({
      INPUT_MODAL: () => setIsModalOpen((prevState) => ({ ...prevState, inputModal: true })),
      DELETE_ROW_MODAL: () => setIsModalOpen((prevState) => ({ ...prevState, deleteRowModal: true })),
      DELETE_ROW_HEADER_MODAL: () => setIsModalOpen((prevState) => ({ ...prevState, deleteRowHeaderModal: true })),
    }),
    [],
  );

  const hideModal = useMemo(
    () => ({
      INPUT_MODAL: () => {
        setIsModalOpen((prevState) => ({ ...prevState, inputModal: false }));
        setNewHeaderName('');
      },
      DELETE_ROW_MODAL: () => setIsModalOpen((prevState) => ({ ...prevState, deleteRowModal: false })),
      DELETE_ROW_HEADER_MODAL: () => setIsModalOpen((prevState) => ({ ...prevState, deleteRowHeaderModal: false })),
    }),
    [],
  );

  const RemoveHeaderRenderer = useCallback(
    (props: GridRefsProps) => {
      const headerName = props!.api!.getColumnDef(props.column)!.headerName;

      const handlePrepareRemoveRowHeader = async () => {
        const headerId = Number(props!.api!.getColumnDef(props.column).field);

        setIsDistributionTableUpdateActive(true);
        setSelectedRowHeaderForDeletion({ id: headerId });
        showModal['DELETE_ROW_HEADER_MODAL']();
      };

      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '15px' }}>
          {headerName}
          {!hideAddDeleteControls && (
            <NakedButton
              type="text"
              onClick={handlePrepareRemoveRowHeader}
              disabled={isUpdate.isCreateRowActive || isUpdate.isUpdateRowActive || isSizeSystemUpdateActive}
            >
              <CloseCircleOutlined style={{ cursor: 'pointer' }} />
            </NakedButton>
          )}
        </div>
      );
    },
    [
      hideAddDeleteControls,
      isSizeSystemUpdateActive,
      isUpdate.isCreateRowActive,
      isUpdate.isUpdateRowActive,
      setIsDistributionTableUpdateActive,
      showModal,
    ],
  );

  const TotalStatusChecker = ({ value }: ICellRendererParams) => {
    const cellRender = value ? (
      Number(value) === 100 ? (
        <>
          <span>{value}%</span>
          <StyledTotalStatusCheckSuccess style={{ fontSize: '20px' }} />
        </>
      ) : (
        <>
          <span>{value}%</span>
          <StyledTotalStatusCheckWarning style={{ fontSize: '20px' }} />
        </>
      )
    ) : (
      <>
        <span>0%</span>
        <StyledTotalStatusCheckWarning style={{ fontSize: '20px' }} />
      </>
    );

    return cellRender;
  };

  const RemoveRenderer = useCallback(
    (props: ICellRendererParams) => {
      const handlePrepareRemoveRow = (props: ICellRendererParams) => {
        setIsDistributionTableUpdateActive(true);
        setSelectedRowForDeletion(props.node.data);
        showModal['DELETE_ROW_MODAL']();
      };

      return (
        <NakedButton
          disabled={isUpdate.isCreateRowActive || isUpdate.isUpdateRowActive || isSizeSystemUpdateActive}
          type="text"
          style={{ height: '100%', display: 'flex', alignItems: 'center' }}
          onClick={() => handlePrepareRemoveRow(props)}
        >
          <DeleteOutlined style={{ cursor: 'pointer', textAlign: 'center', height: '100%' }} />
        </NakedButton>
      );
    },
    [
      isSizeSystemUpdateActive,
      isUpdate.isCreateRowActive,
      isUpdate.isUpdateRowActive,
      setIsDistributionTableUpdateActive,
      showModal,
    ],
  );

  const AddColumnButton = useCallback(() => {
    const handleAddColumnButton = () => {
      setIsDistributionTableUpdateActive(true);
      showModal['INPUT_MODAL']();
    };

    return (
      <NakedButton
        disabled={isUpdate.isCreateRowActive || isUpdate.isUpdateRowActive || isSizeSystemUpdateActive}
        type="text"
        onClick={handleAddColumnButton}
      >
        <PlusCircleOutlined style={{ cursor: 'pointer', fontSize: 16 }} />
      </NakedButton>
    );
  }, [
    isSizeSystemUpdateActive,
    isUpdate.isCreateRowActive,
    isUpdate.isUpdateRowActive,
    setIsDistributionTableUpdateActive,
    showModal,
  ]);
  const focusLastRow = () => {
    const lastIndex = gridRef.current!.api.getRenderedNodes().length - 1;
    const lastNode = gridRef.current!.api.getRowNode(lastIndex.toString());
    gridRef?.current?.api?.ensureIndexVisible(lastIndex);
    lastNode?.setSelected(true);
  };

  const handleSaveMutation = useCallback(async () => {
    const gridDataCommon = gridRef
      .current!.api.getRenderedNodes()
      .map((x) => x.data)
      .map((y) => {
        return {
          name: y?.name,
        };
      });

    const gridDataTotal = gridRef
      .current!.api.getRenderedNodes()
      .map((x) => x.data)
      .map((y) => {
        return {
          total: y?.total,
        };
      });

    const { DUPLICATES, EMPTY_CELL, LENGTH, TOTAL } = VALIDATIONS_TYPES;

    const commonValidations = [DUPLICATES, EMPTY_CELL, LENGTH];
    const totalValidations = [TOTAL];

    const commonValidation = validateGridCustom(commonValidations, gridDataCommon);
    const totalValidation = validateGridCustom(totalValidations, gridDataTotal);

    if (commonValidation?.isValid === false || totalValidation?.isValid === false) {
      switch (false) {
        case commonValidation?.isValid:
          addCustomError({ message: commonValidation?.code }, 'warning');

          break;
        case totalValidation?.isValid:
          addCustomError({ message: totalValidation?.code }, 'warning');

          break;

        default:
          break;
      }
    } else if (selectedSizeSystem && mutationState) {
      switch (mutationState.type) {
        case 'create-row':
          await createSizeSystemRow({
            variables: {
              sizeSystemRow: mutationState.data as SizeSystemRowCreateInput,
            },
          });

          await rowsRefetch();
          setMutationState(null);

          break;
        case 'update-row': {
          const changes: GridDataDistributionProps = [];
          const initialData: any = initialGridData.current;

          mutationState.data.forEach((row: any) => {
            const initialRowData = initialData.find((initialRow: any) => initialRow.id === row.id);
            const sizeSystemRowValuesDifference = differenceWith(
              row.sizeSystemRowValues,
              initialRowData.sizeSystemRowValues,
              isEqual,
            );

            if (sizeSystemRowValuesDifference.length) {
              changes.push(row);
              // changes?.push({ ...row, sizeSystemRowValues: sizeSystemRowValuesDifference });
            }
          });

          await updateBulkSizeSystemRows({
            variables: {
              inputSizeSystemRows: changes,
            },
          });

          await rowsRefetch();
          setMutationState(null);

          break;
        }

        default:
          break;
      }

      setIsUpdate((prevState) => ({
        ...prevState,
        isCreateRowActive: false,
        isUpdateRowActive: false,
      }));
      setIsDistributionTableUpdateActive(false);
      setTimeout(() => {
        focusLastRow();
      }, 1.5);
    }
  }, [
    addCustomError,
    createSizeSystemRow,
    mutationState,
    rowsRefetch,
    selectedSizeSystem,
    setIsDistributionTableUpdateActive,
    updateBulkSizeSystemRows,
    focusLastRow,
  ]);

  const handleRemoveRowHeader = useCallback(async () => {
    if (selectedSizeSystem && selectedRowHeaderForDeletion.id != 0) {
      await deleteSizeSystemRowHeader({
        variables: { id: selectedRowHeaderForDeletion.id, sizeSystemId: selectedSizeSystem },
      });

      await headersRefetch();
    }

    setSelectedRowHeaderForDeletion({ id: 0 });
    hideModal['DELETE_ROW_HEADER_MODAL']();
    setIsUpdate((prevState) => ({
      ...prevState,
      isCreateRowActive: false,
    }));
    setIsDistributionTableUpdateActive(false);
  }, [
    deleteSizeSystemRowHeader,
    headersRefetch,
    hideModal,
    selectedRowHeaderForDeletion.id,
    selectedSizeSystem,
    setIsDistributionTableUpdateActive,
  ]);

  const handleRemoveRow = useCallback(async () => {
    if (selectedSizeSystem && selectedRowForDeletion.id != 0) {
      await deleteSizeSystemRow({ variables: { id: selectedRowForDeletion.id, sizeSystemId: selectedSizeSystem } });

      await rowsRefetch();
    }

    setSelectedRowForDeletion({ id: 0, sortNumber: 0 });
    hideModal['DELETE_ROW_MODAL']();
    setIsUpdate((prevState) => ({
      ...prevState,
      isCreateRowActive: false,
    }));
    setIsDistributionTableUpdateActive(false);
  }, [
    deleteSizeSystemRow,
    hideModal,
    rowsRefetch,
    selectedRowForDeletion.id,
    selectedSizeSystem,
    setIsDistributionTableUpdateActive,
  ]);

  const handleUpdateHeaderIsOnShowcase = useCallback(
    async (value: number) => {
      if (value) {
        await updateSizeSystemRowHeader({
          variables: {
            sizeSystemRowHeader: {
              id: value,
              isOnShowcase: true,
            },
          },
        });

        await headersRefetch();
      }
    },
    [headersRefetch, updateSizeSystemRowHeader],
  );

  const handleDeselectHeaderIsOnShowcase = useCallback(async () => {
    if (selectedSizeSystem) {
      await deselectSizeSystemIsOnShowcase({
        variables: {
          sizeSystemId: selectedSizeSystem,
        },
      });

      await headersRefetch();
    }
  }, [deselectSizeSystemIsOnShowcase, headersRefetch, selectedSizeSystem]);

  const handleAddCol = useCallback(async () => {
    if (selectedSizeSystem && newHeaderName && headersData?.sizeSystemRowHeaders) {
      await createSizeSystemRowHeader({
        variables: {
          sizeSystemRowHeader: {
            sizeSystemId: selectedSizeSystem,
            name: newHeaderName.trim(),
            isOnShowcase: false,
            sortNumber: headersData?.sizeSystemRowHeaders?.length + 1,
          },
        },
      });

      await headersRefetch();
      await rowsRefetch();
    }

    setIsDistributionTableUpdateActive(false);

    hideModal['INPUT_MODAL']();
  }, [
    createSizeSystemRowHeader,
    headersData?.sizeSystemRowHeaders,
    headersRefetch,
    hideModal,
    newHeaderName,
    rowsRefetch,
    selectedSizeSystem,
    setIsDistributionTableUpdateActive,
  ]);

  const handleAddRow = useCallback(() => {
    setIsDistributionTableUpdateActive(true);

    const lastSortNumber = gridRef.current!.props.rowData![gridRef.current!.props.rowData!.length - 1]?.sortNumber;
    const agGridConstructorObject: ConstructorRowsProps = {
      id: gridRef.current!.props.rowData!.length + 1 || 1,
      sortNumber: lastSortNumber ? lastSortNumber + 1 : 1,
      name: '',
      rowValueIds: {},
      total: 0,
    };

    setIsUpdate((prevState) => ({
      ...prevState,
      isCreateRowActive: true,
    }));

    headersData?.sizeSystemRowHeaders?.forEach((header) => {
      if (header?.id) {
        agGridConstructorObject[header.id] = 0;
      }
    });

    setMutationState({
      type: 'create-row',
      total: 0,
      data: {
        sizeSystemId: selectedSizeSystem,
        name: '',
        sortNumber: lastSortNumber ? lastSortNumber + 1 : 1,
        sizeSystemRowValues: headersData?.sizeSystemRowHeaders?.map((header) => ({
          distribution: 0,
          sizeSystemRowHeaderId: header?.id,
        })),
      } as SizeSystemRowCreateInput,
    });

    gridRef.current!.api.applyTransaction({
      add: [agGridConstructorObject],
    });

    const gridData = gridRef.current!.api.getRenderedNodes();

    setTimeout(() => {
      gridRef.current!.api.startEditingCell({
        rowIndex: gridData.length - 1,
        colKey: 'name',
      });
    }, 1);
  }, [headersData?.sizeSystemRowHeaders, selectedSizeSystem, setIsDistributionTableUpdateActive, gridRef]);

  const cellEditorSelector = useCallback((params: ICellEditorParams<{ type: string; value: number }>) => {
    if (params.data.type !== 'code' && params.data.type !== 'total') {
      return {
        component: NumericCellEditor,
      };
    }

    return undefined;
  }, []);

  const createValueGetter = useCallback(() => {
    const valueGetterString: string[] = [];

    headersData?.sizeSystemRowHeaders?.forEach((header) => {
      valueGetterString.push(`Number(data[${header?.id}])`);
    });

    return valueGetterString.join(' + ');
  }, [headersData?.sizeSystemRowHeaders]);

  const getColumnDefs = useCallback(
    (colsRawData: (SizeSystemRowHeader | null)[] | null | undefined): GetColumnDefs[] => {
      const colDefs: GetColumnDefs[] & ColDef[] =
        colsRawData?.map((value: SizeSystemRowHeader | null) => ({
          // headerComponent: hideAddDeleteControls ? undefined : RemoveHeaderRenderer,
          field: value!.id.toString() || undefined,
          headerName: value!.name || undefined,
          cellEditorSelector: cellEditorSelector,
          valueFormatter: percentFormatter,
          editable: disableEditableMode ? undefined : true,
          suppressMovable: false,
          type: 'valueColumn',
          sortable: true,
          filter: true,
          ...CENTERED_COL_DEF,
        })) || [];

      if (additionalColDefs) {
        colDefs.push(...additionalColDefs);
      }

      return colDefs;
    },
    [RemoveHeaderRenderer, additionalColDefs, cellEditorSelector, disableEditableMode, hideAddDeleteControls],
  );

  useEffect(() => {
    if (isGridReady) {
      gridRef.current?.api?.deselectAll();

      if (sizeSystemRowName) {
        setTimeout(() => {
          gridRef.current?.api?.forEachNode((rowNode) => {
            if (rowNode.data.name === sizeSystemRowName) {
              rowNode.setSelected(true);
            }
          });
        }, 500);
      }
    }
  }, [sizeSystemRowName, isGridReady]);

  const getRowsData = useCallback((rowsRawData: (SizeSystemRow | null)[] | null | undefined) => {
    const rows = rowsRawData
      ?.map((obj: SizeSystemRow | null) => {
        return {
          id: obj?.id,
          name: obj?.name,
          sortNumber: obj?.sortNumber,
          ...obj?.sizeSystemRowValues,
        };
      })
      .map((obj: GetRowsProps, i: number) => {
        const constructorObject: ConstructorRowsProps = {
          name: obj?.name,
          id: obj?.id,
          sortNumber: obj?.sortNumber,
          [obj[i]?.sizeSystemRowHeaderId]: obj[i]?.distribution,
          rowValueIds: {},
          total: 0,
        };

        for (const key in obj) {
          obj[key].distribution &&
            (constructorObject.total = Number(constructorObject.total) + Number(obj[key].distribution));

          if (obj[key].sizeSystemRowHeaderId) {
            constructorObject[obj[key]?.sizeSystemRowHeaderId] = obj[key]?.distribution;
            constructorObject.rowValueIds[obj[key]?.sizeSystemRowHeaderId] = obj[key]?.id;
          }
        }

        return constructorObject;
      });

    return rows;
  }, []);

  const onSelectionChanged = useCallback(() => {
    if (typeof onRowSelect !== 'function') return;

    const selectedRows = gridRef.current!.api.getSelectedRows();

    selectedRows[0] && onRowSelect(selectedRows[0], headersData?.sizeSystemRowHeaders);
  }, [headersData?.sizeSystemRowHeaders, onRowSelect]);

  const onCellEditingStarted = useCallback(() => {
    setIsUpdate((prevState) => ({
      ...prevState,
      isCellActive: true,
    }));
  }, []);

  const onCellEditingStopped = useCallback(
    (event: RowEditingStoppedEvent) => {
      const sizeSystemRowValues = [];
      const entries = Object.entries(event.data);
      let total = 0;

      if (headersData?.sizeSystemRowHeaders) {
        for (let i = 0; i < headersData?.sizeSystemRowHeaders?.length; i++) {
          let rowValue = {};

          if (mutationState?.type === 'create-row') {
            rowValue = {
              distribution: Number(entries[i][1]),
              sizeSystemRowHeaderId: Number(entries[i][0]),
            };
          }

          total += Number(entries[i][1]);

          sizeSystemRowValues.push(rowValue);
        }

        const rowNode = gridRef.current!.api.getRowNode(event!.rowIndex!.toString())!;
        rowNode && rowNode.setDataValue('total', total);
      }

      const gridData = gridRef
        .current!.api.getRenderedNodes()
        .map((x) => {
          return x.data;
        })
        .map((y) => {
          return {
            id: y?.id,
            name: y?.name,
            sizeSystemRowValues: (() => {
              if (headersData?.sizeSystemRowHeaders) {
                const rowValues = [];
                for (let i = 0; i < headersData?.sizeSystemRowHeaders?.length; i++) {
                  rowValues.push({
                    id: y.rowValueIds[Number(headersData?.sizeSystemRowHeaders[i]?.id)],
                    distribution: Number(y[headersData!.sizeSystemRowHeaders![i]!.id]),
                  });
                }

                return rowValues;
              }
            })(),
          };
        });

      if (mutationState?.type === 'create-row') {
        setMutationState({
          type: 'create-row',
          total: total,
          data: {
            sizeSystemId: selectedSizeSystem,
            name: event!.data.name,
            sortNumber: event!.data.sortNumber,
            sizeSystemRowValues: sizeSystemRowValues,
          } as SizeSystemRowCreateInput,
        });
      } else if (mutationState === null || mutationState?.type === 'update-row') {
        const gridData = gridRef
          .current!.api.getRenderedNodes()
          .map((x) => {
            return x.data;
          })
          .map((y) => {
            return {
              id: y?.id,
              name: y?.name,
              sizeSystemRowValues: (() => {
                if (headersData?.sizeSystemRowHeaders) {
                  const rowValues = [];
                  for (let i = 0; i < headersData?.sizeSystemRowHeaders?.length; i++) {
                    rowValues.push({
                      id: y.rowValueIds[Number(headersData?.sizeSystemRowHeaders[i]?.id)],
                      distribution: Number(y[headersData!.sizeSystemRowHeaders![i]!.id]),
                    });
                  }

                  return rowValues;
                }
              })(),
            };
          });

        setMutationState({
          type: 'update-row',
          total: total,
          data: gridData as SizeSystemRowUpdateInput[],
        });
      }

      if (!isEqual(initialGridData.current, gridData)) {
        setIsUpdate((prevState) => ({
          ...prevState,
          isCellActive: false,
          isUpdateRowActive: true,
        }));

        setIsUpdate((prevState) => ({
          ...prevState,
          isCellActive: false,
        }));

        setIsDistributionTableUpdateActive(true);
      }
    },
    [headersData, mutationState, selectedSizeSystem, setIsDistributionTableUpdateActive],
  );

  const createColumnsDefinitionStructure = useCallback(() => {
    const columns: GetColumnDefs[] = [
      {
        headerName: t('operationalSettings.code'),
        ...(!mutationState === null && { sort: 'asc' }),
        comparator: sortEmpty,

        field: 'name',
        sortable: true,
        pinned: 'left',
        filter: true,
        valueSetter: forbidEmptyCellValueSetter,
        cellEditor: AlphanumericCellEditor,
      },
      {
        headerName: t('operationalSettings.total'),
        field: 'total',
        sortable: true,
        filter: true,
        editable: false,
        cellStyle: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
        cellRenderer: TotalStatusChecker,
        maxWidth: 100,
        valueGetter: createValueGetter(),
        pinned: 'left',
      },
      ...getColumnDefs(headersData?.sizeSystemRowHeaders),
    ];

    if (!hideAddDeleteControls) {
      columns.push({
        headerComponent: AddColumnButton,
        maxWidth: 60,
        sortable: false,
        editable: false,
        cellStyle: { textAlign: 'center' },
        suppressMenu: true,
        cellRenderer: RemoveRenderer,
        pinned: 'right',
      });
    }

    return columns;
  }, [
    AddColumnButton,
    RemoveRenderer,
    createValueGetter,
    getColumnDefs,
    headersData?.sizeSystemRowHeaders,
    hideAddDeleteControls,
    mutationState,
    t,
  ]);

  const defaultColDef: ColDef = useMemo(
    () => ({
      ...COMMON_DEFAULT_COL_DEFS,
      suppressMovable: true,
      editable: (params) => {
        return params.node.lastChild && mutationState !== null && mutationState !== undefined;
      },
      sortable: true,
      ...(defaultColDefOverrides || {}),
    }),
    [defaultColDefOverrides, mutationState],
  );

  const columnTypes = useMemo<{
    [key: string]: ColDef;
  }>(() => {
    return {
      valueColumn: {
        valueParser: 'Number(newValue)',
      },
    };
  }, []);

  const handleCancelButton = useCallback(() => {
    if (mutationState?.type === 'create-row') {
      const lastRowIndex = gridRef.current!.api.getLastDisplayedRow();
      const lastRow = gridRef.current!.api.getDisplayedRowAtIndex(lastRowIndex);

      gridRef.current?.api.applyTransaction({ remove: [lastRow?.data!] });
    }

    const rows = getRowsData(rowsData?.sizeSystemRows);

    rows && gridRef.current?.api.setRowData(rows);

    setRowsRawData(rows);

    setIsUpdate((prevState) => ({
      ...prevState,
      isCreateRowActive: false,
      isUpdateRowActive: false,
      isCellActive: false,
    }));

    setMutationState(null);

    setIsDistributionTableUpdateActive(false);
  }, [getRowsData, mutationState?.type, rowsData?.sizeSystemRows, setIsDistributionTableUpdateActive]);

  useEffect(() => {
    if (headersData) {
      const columns: GetColumnDefs[] = createColumnsDefinitionStructure();

      setColumnDefs(columns);
    } else {
      setColumnDefs([]);
    }

    if (rowsData) {
      const rows = getRowsData(rowsData.sizeSystemRows);

      const gridData = rows?.map((y: any) => {
        return {
          id: y?.id,
          name: y?.name,
          sizeSystemRowValues: (() => {
            if (headersData?.sizeSystemRowHeaders) {
              const rowValues = [];
              for (let i = 0; i < headersData?.sizeSystemRowHeaders?.length; i++) {
                rowValues.push({
                  id: y.rowValueIds[Number(headersData?.sizeSystemRowHeaders[i]?.id)],
                  distribution: Number(y[headersData!.sizeSystemRowHeaders![i]!.id]),
                });
              }

              return rowValues;
            }
          })(),
        };
      });

      initialGridData.current = gridData;
      setRowsRawData(rows);
    } else {
      setRowsRawData([]);
    }
  }, [headersData, rowsData, getRowsData]);

  useEffect(() => {
    const columns: GetColumnDefs[] = createColumnsDefinitionStructure();

    setColumnDefs(columns);
  }, [createColumnsDefinitionStructure, isUpdate.isUpdateRowActive]);

  useEffect(() => {
    createError?.graphQLErrors || bulkUpdateError?.graphQLErrors ? setErrors(true) : setErrors(null);
  }, [bulkUpdateError?.graphQLErrors, createError?.graphQLErrors]);

  const onExport = useCallback(() => {
    gridRef.current &&
      gridRef.current.api.exportDataAsExcel({
        processCellCallback: (params) => {
          if (isNumber(params.value) && params?.value % 1 != 0) {
            return params.value.toFixed(1);
          }

          return params.value;
        },
        fileName: `${t('operationalSettings.distribution_grid')}_${getFormattedCurrentDateTime()}`,
      });
  }, [t]);

  const onCSVExport = useCallback(() => {
    gridRef.current!.api.exportDataAsCsv();
  }, []);

  return !selectedSizeSystem ? (
    <NoSizeSystemSelectedMessage>
      <p>{t('operationalSettings.no_size_system_selected')}</p>
    </NoSizeSystemSelectedMessage>
  ) : headersLoading || rowsLoading ? (
    <Spinner size="large" />
  ) : (
    <>
      {!hideItemSelector && (
        <DistributionGridSelectWrapper>
          <p>{t('operationalSettings.showcase_size')}</p>
          <PrimarySelect
            value={headersData?.sizeSystemRowHeaders?.find((c) => c?.isOnShowcase)?.name || 'Select showcase'}
            style={{ width: 70 }}
            allowClear={headersData?.sizeSystemRowHeaders?.find((c) => c?.isOnShowcase)?.name}
            onChange={handleUpdateHeaderIsOnShowcase}
            onClear={handleDeselectHeaderIsOnShowcase}
          >
            {headersData?.sizeSystemRowHeaders?.map((column) => (
              <Option key={column?.id} value={column?.id}>
                {column?.name}
              </Option>
            ))}
          </PrimarySelect>
        </DistributionGridSelectWrapper>
      )}
      <AgGridReact
        containerStyle={{ width: '100%', height: '85%' }}
        ref={gridRef}
        headerHeight={22}
        columnTypes={columnTypes}
        columnDefs={columnDefs}
        rowData={rowsRawData}
        defaultColDef={defaultColDef}
        onCellEditingStarted={onCellEditingStarted}
        onCellEditingStopped={onCellEditingStopped}
        onSelectionChanged={onSelectionChanged}
        rowSelection={'single'}
        rowHeight={20}
        onGridReady={() => {
          setIsGridReady(true);
        }}
        groupDefaultExpanded={1}
        suppressAggFuncInHeader={true}
        suppressRowVirtualisation
        suppressRowClickSelection={mutationState !== null && mutationState !== undefined}
        animateRows={true}
        stopEditingWhenCellsLoseFocus={true}
        domLayout={domLayout}
        enableRangeSelection={true}
        sideBar={SIDE_BAR}
        getContextMenuItems={() => getContextMenu(onExport, onCSVExport)}
      />

      <ActionButtonsWrapper style={{ justifyContent: 'space-between', margin: '0 0 -10px 0' }}>
        <NakedButton
          disabled={
            isUpdate.isCreateRowActive ||
            headersData?.sizeSystemRowHeaders?.length === 0 ||
            isUpdate.isUpdateRowActive ||
            isSizeSystemUpdateActive ||
            errors ||
            disableNewRowAdd
          }
          style={{ fontSize: 12, color: '#ffffff' }}
          type="link"
          onClick={handleAddRow}
          text={`+${t('operationalSettings.new_distribution_grid')}`}
        />
        {/*<button onClick={focusLastRow}>FOCUS LAST</button>*/}
        {(isUpdate.isCreateRowActive || isUpdate.isUpdateRowActive) && (
          <SubActionButtonsWrapper>
            <NakedButton
              type="text"
              onClick={handleCancelButton}
              textKey="common.cancel"
              style={{ color: '#ffffff' }}
            />

            <PrimaryButton
              onClick={handleSaveMutation}
              textKey="common.save"
              disabled={isUpdate.isCellActive}
            ></PrimaryButton>
          </SubActionButtonsWrapper>
        )}
      </ActionButtonsWrapper>

      <InputModal
        customTitle={t('operationalSettings.header_name')}
        onOk={handleAddCol}
        state={newHeaderName}
        setState={setNewHeaderName}
        open={isModalOpen.inputModal}
        onCancel={hideModal['INPUT_MODAL']}
      />

      <ConfirmModal
        customTitle={
          isModalOpen.deleteRowHeaderModal
            ? t('operationalSettings.delete_row_header_warning_message')
            : t('operationalSettings.delete_row_warning_message')
        }
        onOk={isModalOpen.deleteRowHeaderModal ? handleRemoveRowHeader : handleRemoveRow}
        open={isModalOpen.deleteRowHeaderModal || isModalOpen.deleteRowModal}
        onCancel={
          isModalOpen.deleteRowHeaderModal ? hideModal['DELETE_ROW_HEADER_MODAL'] : hideModal['DELETE_ROW_MODAL']
        }
      />
    </>
  );
};

export default DistributionGrid;
