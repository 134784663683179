import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';

import { ColDef, RowClassParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { getFormattedCurrentDateTime } from 'utils/moment';

import ImportExportXlsxComponent from 'components/ImportExportXlsxComponent';
import { COLUMN_TYPES, FOOTER_ROW_CLASS } from 'constants/gridConstants';
import { getContextMenu } from 'helpers/gridContextMenu';
import { useCommonData } from 'hooks/useCommonData';
import { useGetTargetTurnoverTableLazyQuery } from 'services/graphql/main';
import { useError } from 'services/utils';

import { SeasonGoalsSummaryProps, SeasonGoalsSummaryPreparedDataProps } from './common/types';
import { AdaptiveGridResizer, ExportButtonWrapper } from './styles';

const SeasonGoalsSummary: React.FC = () => {
  const { t } = useTranslation('translation');
  const { addError } = useError();

  const { seasonId, universeId } = useCommonData();

  const gridRef = useRef<AgGridReact>(null);

  const [rowData, setRowData] = useState<SeasonGoalsSummaryProps[]>();

  const [getTargetTurnoverTableLazyQuery, { data }] = useGetTargetTurnoverTableLazyQuery({
    onError: (err) => addError(err, 'error'),
  });

  useEffect(() => {
    if (seasonId && universeId) getTargetTurnoverTableLazyQuery({ variables: { seasonId, universeId } });
  }, [getTargetTurnoverTableLazyQuery, seasonId, universeId]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100,
      filter: true,
    };
  }, []);

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        field: 'collectionName',
        sortable: false,
        headerName: t('seasonGoalSummary.season_goal_summary_collection'),
      },
      {
        field: 'groupName',
        headerName: t('seasonGoalSummary.group'),
      },
      {
        field: 'numberOfStores',
        headerName: t('seasonGoalSummary.number_of_stores'),
      },
      {
        field: 'storesTargetTurnover',
        headerName: t('seasonGoalSummary.stores_target_turnover'),
        valueGetter(params) {
          const value = params?.data?.storesTargetTurnover.toFixed(0) + ' %';

          return value ? value : null;
        },
      },
    ],
    [t],
  );

  const getRowClass = useCallback((params: RowClassParams) => {
    if (params.data.groupName === 'Total') {
      return FOOTER_ROW_CLASS;
    }
  }, []);

  const prepareData = useCallback((rawData: SeasonGoalsSummaryPreparedDataProps[]) => {
    const calculateData = (rawData: SeasonGoalsSummaryProps[]) => {
      const initialValue = { groupName: 'Total', numberOfStores: 0, storesTargetTurnover: 0 };
      const calculatedData = rawData?.reduce(
        (previousValue: SeasonGoalsSummaryProps, currentValue: SeasonGoalsSummaryProps) => {
          return {
            groupName: 'Total',
            numberOfStores: previousValue.numberOfStores + currentValue.numberOfStores,
            storesTargetTurnover: previousValue.storesTargetTurnover + currentValue.storesTargetTurnover,
          };
        },
        initialValue,
      );

      return { ...calculatedData, storesTargetTurnover: Number(calculatedData.storesTargetTurnover.toFixed(2)) };
    };

    const preparedData = rawData?.map((x: SeasonGoalsSummaryPreparedDataProps) => {
      const currentCollection: SeasonGoalsSummaryProps[] = [];

      x.storeGroups?.forEach((y: SeasonGoalsSummaryProps, i: number) => {
        if (i === 0) {
          currentCollection.push({
            collectionName: x.collectionName,
            groupName: y.groupName,
            numberOfStores: y.numberOfStores,
            storesTargetTurnover: y.storesTargetTurnover,
          });
        } else {
          if (i === x.storeGroups.length - 1) {
            currentCollection.push({
              groupName: y.groupName,
              numberOfStores: y.numberOfStores,
              storesTargetTurnover: y.storesTargetTurnover,
            });
            currentCollection.push(calculateData(currentCollection));
          } else {
            currentCollection.push({
              groupName: y.groupName,
              numberOfStores: y.numberOfStores,
              storesTargetTurnover: y.storesTargetTurnover,
            });
          }
        }
      });

      return currentCollection;
    });

    return preparedData?.flat();
  }, []);

  useEffect(() => {
    if (data?.storeFleetSummary) {
      const mutableData: any =
        data &&
        data?.storeFleetSummary?.targetTurnoverTable?.map((x) => ({
          collectionName: x?.collectionName,
          storeGroups: x?.storeGroups?.map((y) => ({
            groupName: y?.groupName,
            numberOfStores: y?.numberOfStores,
            storesTargetTurnover: y?.storesTargetTurnover,
          })),
        }));

      const preparedData = prepareData(mutableData);

      data && setRowData(preparedData);
    } else {
      setRowData([]);
    }
  }, [data, prepareData]);

  const onExport = useCallback(() => {
    gridRef.current &&
      gridRef.current.api.exportDataAsExcel({
        fileName: `${t('seasonGoalSummary.stores_target_turnover')}_${getFormattedCurrentDateTime()}`,
      });
  }, [t]);

  const onCSVExport = useCallback(() => {
    gridRef.current!.api.exportDataAsCsv();
  }, []);

  return (
    <AdaptiveGridResizer>
      <ExportButtonWrapper>
        <ImportExportXlsxComponent exportClickHandler={onExport} noImportOption />
      </ExportButtonWrapper>
      <AgGridReact
        containerStyle={{ width: '100%', marginBottom: 30, height: 'auto' }}
        ref={gridRef}
        columnDefs={columnDefs}
        rowData={rowData}
        defaultColDef={defaultColDef}
        domLayout={'autoHeight'}
        getRowClass={getRowClass}
        columnTypes={COLUMN_TYPES}
        enableRangeSelection={true}
        getContextMenuItems={() => getContextMenu(onExport, onCSVExport)}
      />
    </AdaptiveGridResizer>
  );
};

export default SeasonGoalsSummary;
