import React, { useEffect, useState } from 'react';

import { Select } from 'antd';
import { omit, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { PrimaryButton, Spinner } from 'components/UI';
import {
  CapacityType,
  useGetTenantSettingsQuery,
  TenantSettings,
  useUpdateTenantSettingsMutation,
} from 'services/graphql/main';
import { useError } from 'services/utils';

import { SectionTitle, StyledLabel, StyledSection, StyledInputNumber } from './styles';

const FunctionalSettings: React.FC = () => {
  const { t } = useTranslation('translation');
  const { addError } = useError();
  const { Option } = Select;

  const [tenantSettings, setTenantSettings] = useState<TenantSettings>();
  const [mutationState, setMutationState] = useState<TenantSettings | {}>({});

  const { data, loading, refetch } = useGetTenantSettingsQuery({
    onError: (err) => addError(err, 'error'),
  });

  const [updateTenantSettings, { error: mutationError }] = useUpdateTenantSettingsMutation({
    onError: (err) => addError(err, 'error'),
  });

  const capacityTypes = Object.values(CapacityType);

  const valueChangeHandler = (field: string, value: number | string) => {
    const newValue = { [field]: value };
    if (tenantSettings && tenantSettings[field as keyof TenantSettings] === value) {
      setMutationState(omit(mutationState, field));

      return;
    }
    setMutationState({ ...mutationState, ...newValue });
  };

  const saveHandler = async () => {
    if (mutationState && tenantSettings) {
      await updateTenantSettings({
        variables: {
          tenantSettings: { ...mutationState, id: tenantSettings.id },
        },
      });
    }

    if (!mutationError) {
      await refetch();
      setMutationState({});
    }
  };

  useEffect(() => {
    if (data) {
      setTenantSettings(data.tenantSettings);
    }
  }, [data]);

  return !loading && tenantSettings ? (
    <>
      <SectionTitle>{t('generalSettings.product_settings')}</SectionTitle>
      <StyledSection>
        <div>
          <StyledLabel htmlFor="productMaxStrength">{t('generalSettings.product_max_strength')}</StyledLabel>
          <StyledInputNumber
            id="productMaxStrength"
            min={0}
            precision={0}
            defaultValue={tenantSettings?.productMaxStrength}
            onChange={(value) => value !== null && valueChangeHandler('productMaxStrength', value)}
            size="small"
          />
        </div>
      </StyledSection>
      <SectionTitle style={{ marginTop: 40 }}>{t('generalSettings.store_settings')}</SectionTitle>
      <StyledSection>
        <div>
          <StyledLabel htmlFor="storeMaxStrength">{t('generalSettings.store_max_strength')}</StyledLabel>
          <StyledInputNumber
            id="storeMaxStrength"
            min={0}
            precision={0}
            defaultValue={tenantSettings?.storeMaxStrength}
            onChange={(value) => value !== null && valueChangeHandler('storeMaxStrength', value)}
            size="small"
          />
        </div>
        <div>
          <StyledLabel htmlFor="storeCapacityType">{t('generalSettings.store_capacity_type')}</StyledLabel>
          <Select
            id="storeCapacityType"
            placeholder={t('generalSettings.select_capacity_type')}
            defaultValue={tenantSettings?.storeCapacityType}
            onChange={(value) => value !== null && valueChangeHandler('storeCapacityType', value)}
            style={{ width: 120 }}
            size="small"
          >
            {capacityTypes?.map((type, i) => {
              return (
                <Option key={i} value={type}>
                  {t(`generalSettings.${type}`)}
                </Option>
              );
            })}
          </Select>
        </div>
      </StyledSection>
      <PrimaryButton
        textKey="common.save"
        onClick={saveHandler}
        style={{ marginTop: 50 }}
        disabled={isEmpty(mutationState)}
      />
    </>
  ) : (
    <Spinner size="large" />
  );
};

export default FunctionalSettings;
