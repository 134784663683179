import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;

  .ag-header-cell,
  .ag-header-group-cell,
  .ag-cell {
    padding: 0 5px;
  }

  .ag-floating-bottom .switchContainer {
    display: none;
  }
`;
