import styled from 'styled-components';

import { PrimaryCheckbox } from 'components/UI';
import { GRID_MAX_HEIGHT } from 'constants/gridConstants';

export const AdaptiveGridResizer = styled.div`
  div.ag-root .ag-body-viewport {
    height: auto;
    max-height: ${GRID_MAX_HEIGHT};
    overflow-y: scroll;
  }

  .ag-pinned-right-header {
    width: 137px !important;
    min-width: unset !important;
    max-width: unset !important;
  }
`;
