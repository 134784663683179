import React from 'react';

import { Button as AntButton, ButtonProps, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

type Props = ButtonProps & {
  textKey?: string;
  tooltipKey?: string;
};

const Button: React.FC<Props> = ({ textKey, tooltipKey, children, ...other }) => {
  const { t } = useTranslation('translation');
  const button = <AntButton {...other}>{textKey ? t(textKey) : children}</AntButton>;

  return tooltipKey ? <Tooltip title={t(tooltipKey)}>{button}</Tooltip> : button;
};

export default Button;
