import React, { useCallback, useEffect, useState } from 'react';

import { useLocalStorage } from 'react-use';

import { FullscreenSpinner } from 'components/UI';
import { Universe } from 'services/graphql/main';
import { useGetUniversesQuery } from 'services/graphql/main';
import { useError } from 'services/utils';

type SelectUniverse = (value: Universe) => void;

type UniverseContextType = {
  selectUniverse: SelectUniverse;
  selectedUniverse: Universe;
  universes: (Universe | null)[] | null | undefined;
};

export const UniverseContext = React.createContext<UniverseContextType>({} as UniverseContextType);

type ContextProviderType = {
  children: React.ReactNode;
};

const lsSelectedUniverseIdItemName = 'selectedUniverseId';

export const UniverseContextProvider: React.FC<ContextProviderType> = ({ children }) => {
  const [lsSelectedUniverseId, lsSetSelectedUniverseId] = useLocalStorage<number | undefined>(
    lsSelectedUniverseIdItemName,
  );

  const [universes, setUniverses] = useState<(Universe | null)[] | null>();
  const [selectedUniverse, setSelectedUniverse] = useState<Universe>({ id: 0, name: undefined });

  const { addError } = useError();
  const { data, loading } = useGetUniversesQuery({ onError: (err) => addError(err, 'error') });

  useEffect(() => {
    if (data) {
      setUniverses(data.universes);

      if (data.universes && data.universes[0]) {
        const selectedUniverse = lsSelectedUniverseId
          ? data.universes?.find((universe) => universe?.id === lsSelectedUniverseId) || data.universes[0]
          : data.universes[0];

        setSelectedUniverse(selectedUniverse);
      } else {
        //TODO make redirect to error page
        throw new Error('No universe id');
      }
    }
  }, [data, lsSelectedUniverseId]);

  const selectUniverse: SelectUniverse = useCallback(
    (universe) => {
      setSelectedUniverse(universe);
      lsSetSelectedUniverseId(universe?.id);
    },
    [lsSetSelectedUniverseId],
  );

  return (
    <UniverseContext.Provider
      value={{
        universes,
        selectedUniverse,
        selectUniverse,
      }}
    >
      {loading ? (
        <FullscreenSpinner />
      ) : !selectedUniverse.id ? (
        <h3 style={{ margin: '100px auto', textAlign: 'center' }}>Missing universe id.</h3>
      ) : (
        children
      )}
    </UniverseContext.Provider>
  );
};
