import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import {
  ColDef,
  GetRowIdFunc,
  GridApi,
  IDatasource,
  IFilterOptionDef,
  INumberFilterParams,
  ITextFilterParams,
  PaginationChangedEvent,
  ExcelStyle,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { SeasonContext, TableViewsContext, UniverseContext } from 'context';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { getFormattedCurrentDateTime } from 'utils/moment';

import { ClearFiltersButton, ConciseGridContainer, Views } from 'components/AgGridComponents';
import { checkValueRenderer } from 'components/AgGridComponents/valueRenderers';
import { NakedButton, Spinner } from 'components/UI';
import { ActionButtonsWrapper, VerticalPageContent } from 'components/wrappers';
import envconfig from 'config/envconfig';
import { GRID_DEFAULT_COL_DEFS, GRID_DEFAULT_PROPS } from 'constants/gridConstants';
import { generateCustomFieldsColDefs } from 'helpers/customFields';
import { hasFiltersChanged } from 'helpers/grid';
import { applyColWidths, getVisibleColDefs } from 'helpers/gridCols';
import { getContextCsvExportMenu } from 'helpers/gridContextMenu';
import { useGridCols } from 'hooks/useGridCols';
import {
  OptimalStockSimulationDto,
  OptimalStockSimulationField,
  OptimalStockSimulationFilterDtoInput,
  ProductCustomFieldsDto,
  StoreCustomFieldsDto,
  useGetOptimalStockSimulationLazyQuery,
} from 'services/graphql/main';
import { getCacheVal, isTokenCloseToExpiration, useError } from 'services/utils';
import { SelectedViewId } from 'types/views';

import { prepareRowData } from '../../helpers/grid';
import { ChangedFilters } from '../../types';

interface Props {
  changedFilters: ChangedFilters;
  productCustomFieldsData: ProductCustomFieldsDto | undefined;
  setShouldApplyFilters: React.Dispatch<React.SetStateAction<boolean>>;
  shouldApplyFilters: boolean;
  storeCustomFieldsData: StoreCustomFieldsDto | undefined;
}

const OptimalStockAndEstimatedPickingTable: React.FC<Props> = ({
  productCustomFieldsData,
  storeCustomFieldsData,
  changedFilters,
}) => {
  const { addError, addCustomError } = useError();
  const { t } = useTranslation('translation');

  const { selectedUniverse } = useContext(UniverseContext);
  const { seasons } = useContext(SeasonContext);
  const universeId = selectedUniverse?.id;
  const PAGE_SIZE = 1000;

  const { selectedViewId, getColumnsWidths, getView, pendingTableRefresh, setPendingTableRefresh } =
    useContext(TableViewsContext);

  const [getOptimalStockLazyQuery, { data: rowData, error: errorOptimalStock, fetchMore, loading: simulationLoading }] =
    useGetOptimalStockSimulationLazyQuery({
      onError: (err) => addError(err, 'error'),
    });

  const [viewColsInitialized, setViewColsInitialized] = useState<SelectedViewId | undefined>();
  const [allColumnDefs, setAllColumnDefs] = useState<ColDef[] | null>(null);
  const [columnDefs, setColumnDefs] = useState<ColDef[] | null>(null);
  const [isGridReady, setIsGridReady] = useState(false);
  const [hasFilters, setHasFilters] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [pinnedBottomRowData, setPinnedBottomRowData] =
    useState<{ estimationPicking?: number; optimalStock?: number; storeCode?: string }[]>();
  const gridRef = useRef<AgGridReact>(null);

  const { handleColumnMoved, handleColumnResized, handleSortChanged, handleFilterChanged, handleColumnPinned } =
    useGridCols({
      isGridReady,
      gridRef,
      tableName: 'optimalStockEstimationPicking',
    });

  const filters = {
    customField1: changedFilters.productCustomField1,
    customField2: changedFilters.productCustomField2,
    customField3: changedFilters.productCustomField3,
    customField4: changedFilters.storeCustomField1,
    customField5: changedFilters.storeCustomField2,
    permutations: changedFilters.permutations,
  };
  const calculateTotalData = (rawData: OptimalStockSimulationDto[]) => {
    const initialValue = {
      estimationPickingSumTotal: 0,
      optimalStockSumTotal: 0,
    };

    const calculatedData = rawData?.reduce(
      (previousValue, currentValue) => ({
        estimationPickingSumTotal:
          Number(previousValue?.estimationPickingSumTotal) + Number(currentValue.estimationPicking),
        optimalStockSumTotal: Number(previousValue?.optimalStockSumTotal) + Number(currentValue.optimalStock),
      }),
      initialValue,
    );
    const result = [
      {
        storeCode: t('optimalStockAndEstimatedPicking.total'),
        estimationPicking: calculatedData?.estimationPickingSumTotal,
        optimalStock: calculatedData?.optimalStockSumTotal,
      },
    ];
    setPinnedBottomRowData(result);
  };

  const getNextData = useCallback(
    async (
      offset: number,
      filter: OptimalStockSimulationFilterDtoInput[],
      sortData: { isAsc: boolean; sortType: OptimalStockSimulationField },
    ) => {
      const getSelectedSeasonId = (selectedSeason: string | string[]) =>
        seasons?.find((season) => season!.name === selectedSeason)?.id;
      const seasonId = getSelectedSeasonId(changedFilters.seasons) || changedFilters.seasons;

      setPinnedBottomRowData([
        {
          storeCode: t('optimalStockAndEstimatedPicking.total'),
          estimationPicking: undefined,
          optimalStock: undefined,
        },
      ]);

      const res = await fetchMore({
        variables: {
          skip: offset,
          take: PAGE_SIZE,
          seasonId: seasonId === -1 ? null : seasonId,
          universeId,
          filters: filter,
          ...filters,
          ...sortData,
        },
      });

      return res;
    },
    [fetchMore, changedFilters],
  );
  const getInitialData = useCallback(
    async (
      filter: OptimalStockSimulationFilterDtoInput[],
      sortData: { isAsc: boolean; sortType: OptimalStockSimulationField },
    ) => {
      const getSelectedSeasonId = (selectedSeason: string | string[]) =>
        seasons?.find((season) => season!.name === selectedSeason)?.id;
      const seasonId = getSelectedSeasonId(changedFilters.seasons) || changedFilters.seasons;

      setPinnedBottomRowData([
        {
          storeCode: t('optimalStockAndEstimatedPicking.total'),
          estimationPicking: undefined,
          optimalStock: undefined,
        },
      ]);

      const res = await getOptimalStockLazyQuery({
        variables: {
          take: PAGE_SIZE,
          seasonId: seasonId === -1 ? null : seasonId,
          universeId,
          filters: filter,
          ...filters,
          ...sortData,
        },
      });

      return res;
    },
    [getOptimalStockLazyQuery, changedFilters],
  );
  const convertColumnIdToField = (colId: string): OptimalStockSimulationField => {
    const capitalised = (colId.charAt(0).toUpperCase() + colId.slice(1)) as keyof typeof OptimalStockSimulationField;

    return OptimalStockSimulationField[capitalised];
  };
  useEffect(() => {
    if (gridApi) {
      const dataSource: IDatasource = {
        getRows: async (params) => {
          const { filterModel, sortModel } = params;
          const filter: OptimalStockSimulationFilterDtoInput[] = [];

          for (const filterModelKey in filterModel) {
            if (filterModel[filterModelKey].type !== 'all') {
              const value =
                filterModel[filterModelKey].filter || filterModel[filterModelKey].filter === 0
                  ? filterModel[filterModelKey]?.filter?.toString()
                  : filterModel[filterModelKey].type === 'false'
                  ? 'false'
                  : 'true';
              filter.push({
                type: convertColumnIdToField(filterModelKey),
                value: value.toLowerCase(),
              });
            }
          }
          const sortData = {
            sortType: sortModel.length
              ? convertColumnIdToField(sortModel[0].colId)
              : OptimalStockSimulationField.EstimationPicking,
            isAsc: sortModel[0]?.sort === 'asc' || false,
          };

          if (params.startRow === 0) {
            const res = await getInitialData(filter, sortData);
            params.successCallback(
              prepareRowData((res.data?.optimalStockSimulation?.items as OptimalStockSimulationDto[]) || []),
              res.data?.optimalStockSimulation?.totalCount,
            );
          } else {
            const res = await getNextData(params.endRow - PAGE_SIZE, filter, sortData);
            params.successCallback(
              res?.data?.optimalStockSimulation?.items as OptimalStockSimulationDto[],
              res.data?.optimalStockSimulation?.totalCount,
            );
          }
        },
      };

      gridApi.setDatasource(dataSource);
    }
  }, [gridApi, getNextData, getInitialData, changedFilters]);

  const defaultColDef: ColDef = useMemo(() => {
    return { ...GRID_DEFAULT_COL_DEFS };
  }, []);

  // colDefs
  useEffect(() => {
    const textColumnFilter = {
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        suppressAndOrCondition: true,
      } as ITextFilterParams,
    };
    const numberColumnFilter = {
      filter: 'agNumberColumnFilter',
      filterParams: {
        filterOptions: [
          'equals',
          'notEqual',
          'lessThan',
          'lessThanOrEqual',
          'greaterThan',
          'greaterThanOrEqual',
          'inRange',
          'blank',
          'notBlank',
          'empty',
        ],
        suppressAndOrCondition: true,
      } as INumberFilterParams,
    };
    const booleanColumnFilter = {
      filter: 'agNumberColumnFilter',
      filterParams: {
        filterOptions: [
          {
            displayKey: 'true',
            displayName: 'True',
            predicate: (_, cellValue) => +cellValue === 1,
            numberOfInputs: 0,
          },
          {
            displayKey: 'false',
            displayName: 'False',
            predicate: (_, cellValue) => +cellValue === 0,
            numberOfInputs: 0,
          },
          {
            displayKey: 'all',
            displayName: 'All',
            predicate: (_, __) => undefined,
            numberOfInputs: 0,
          },
        ] as IFilterOptionDef[],
        suppressAndOrCondition: true,
      },
    };
    let colDefs: ColDef<OptimalStockSimulationDto>[] = [
      {
        field: 'storeCode',
        headerName: t(`optimalStockAndEstimatedPicking.store_code`),
        minWidth: 65,
        type: 'TEXT_COLUMN',
        cellClass: 'stringType',
        ...textColumnFilter,
      },
      {
        field: 'storeName',
        headerName: t(`optimalStockAndEstimatedPicking.store_name`),
        type: 'TEXT_COLUMN',
        ...textColumnFilter,
      },
      {
        field: 'productCode',
        headerName: t(`optimalStockAndEstimatedPicking.product_code`),
        minWidth: 65,
        type: 'TEXT_COLUMN',
        ...textColumnFilter,
      },
      {
        field: 'productName',
        headerName: t(`optimalStockAndEstimatedPicking.product_name`),
        type: 'TEXT_COLUMN',
        ...textColumnFilter,
      },
      {
        field: 'colorCode',
        headerName: t(`optimalStockAndEstimatedPicking.color_code`),
        type: 'TEXT_COLUMN',
        ...textColumnFilter,
      },

      {
        field: 'colorName',
        headerName: t(`optimalStockAndEstimatedPicking.color_name`),
        type: 'TEXT_COLUMN',
        ...textColumnFilter,
      },

      { field: 'permutations', headerName: t('common.permutationFields'), type: 'TEXT_COLUMN', filter: false },
      {
        field: 'sizeHeader',
        headerName: t(`optimalStockAndEstimatedPicking.size_header`),
        type: 'TEXT_COLUMN',
        ...textColumnFilter,
      },
      {
        field: 'barcode',
        headerName: t(`optimalStockAndEstimatedPicking.barcode`),
        type: 'TEXT_COLUMN',
        ...textColumnFilter,
      },
      {
        field: 'familyName',
        headerName: t(`optimalStockAndEstimatedPicking.family_name`),
        type: 'TEXT_COLUMN',
        ...textColumnFilter,
      },
      ...generateCustomFieldsColDefs(storeCustomFieldsData, 'storeCustomField'),
      ...generateCustomFieldsColDefs(productCustomFieldsData, 'productCustomField'),
      {
        field: 'storeIsSynced',
        headerName: t(`optimalStockAndEstimatedPicking.store_is_synced`),
        cellRenderer: checkValueRenderer,
        type: 'BOOLEAN_COLUMN',
        ...booleanColumnFilter,
      },
      {
        field: 'productIsSynced',
        type: 'BOOLEAN_COLUMN',
        headerName: t(`optimalStockAndEstimatedPicking.product_is_synced`),
        cellRenderer: checkValueRenderer,
        ...booleanColumnFilter,
      },
      {
        field: 'estimationPicking',
        sort: 'desc',
        headerName: t(`optimalStockAndEstimatedPicking.estimation_picking`),
        ...numberColumnFilter,
      },
      { field: 'optimalStock', headerName: t(`optimalStockAndEstimatedPicking.optimal_stock`), ...numberColumnFilter },
    ];

    colDefs = colDefs.map((colDef) => ({ ...colDef, headerTooltip: colDef.headerName }));

    setColumnDefs(getVisibleColDefs({ allColDefs: colDefs, getView }));
    setAllColumnDefs(colDefs);
    setViewColsInitialized(selectedViewId);
  }, [getView, productCustomFieldsData, selectedViewId, storeCustomFieldsData, t, viewColsInitialized]);

  // col widths
  useEffect(() => {
    columnDefs?.length && !selectedViewId && gridRef?.current?.columnApi.resetColumnState();
    viewColsInitialized && applyColWidths(gridRef, getColumnsWidths);
  }, [getColumnsWidths, viewColsInitialized]);

  const getRowId = useMemo<GetRowIdFunc>(() => {
    return (params) => {
      const { data } = params;

      return `${data.storeCode}_${data.productCode}_${data.barcode}`;
    };
  }, []);

  const excelStyles: ExcelStyle[] = [
    {
      id: 'stringType',
      dataType: 'String',
    },
  ];

  const onExportHandler = async () => {
    setExportLoading(true);
    const getSelectedSeasonId = (selectedSeason: string | string[]) =>
      seasons?.find((season) => season!.name === selectedSeason)?.id;
    const seasonId = getSelectedSeasonId(changedFilters.seasons) || changedFilters.seasons;
    const token = getCacheVal('token');

    token && isTokenCloseToExpiration(token);
    try {
      const res = await axios.get(
        `${envconfig.fileUploadUrl}/GetOptimalStockSimulationFile?universeId=${universeId} ${
          seasonId > 0 ? `&seasonId=${seasonId}` : ''
        }`,
        {
          responseType: 'blob',
          headers: { authorization: `Bearer ${token}` },
        },
      );
      setExportLoading(false);
      if (res) {
        const href = URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `OptimalStockSimulation_${getFormattedCurrentDateTime()}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      }
    } catch (err) {
      setExportLoading(false);
      addCustomError(err, 'error');
    }
  };

  const onPageChange = (e: PaginationChangedEvent<OptimalStockSimulationDto>) => {
    if (!isGridReady) return;
    const currentPage = e.api.paginationGetCurrentPage() + 1;
    const rangeTo = currentPage * PAGE_SIZE - 1;
    const rangeFrom = rangeTo - PAGE_SIZE + 1;

    const allRows: OptimalStockSimulationDto[] = [];

    e.api.forEachNode((node, index) => {
      node.data && index <= rangeTo && index >= rangeFrom && allRows.push(node.data);
    });

    calculateTotalData(allRows);
  };

  return (
    <VerticalPageContent>
      <ActionButtonsWrapper style={{ justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <Views flatColDefs={allColumnDefs} />
          <ClearFiltersButton style={{ marginLeft: 10 }} gridRefs={gridRef} hasFilters={hasFilters} />
        </div>
        <NakedButton
          type="text"
          style={{ color: '#ffffff' }}
          onClick={onExportHandler}
          disabled={exportLoading}
          loading={exportLoading}
        >
          {t('common.export_csv')}
        </NakedButton>
      </ActionButtonsWrapper>

      {!errorOptimalStock && (
        <ConciseGridContainer isGridReady={isGridReady && !simulationLoading}>
          <AgGridReact
            {...GRID_DEFAULT_PROPS}
            ref={gridRef}
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            pagination
            onPaginationChanged={onPageChange}
            paginationPageSize={PAGE_SIZE}
            cacheBlockSize={PAGE_SIZE}
            enableRangeSelection
            rowModelType="infinite"
            onGridReady={(params) => {
              isEmpty(rowData) && setIsGridReady(true);
              setGridApi(params.api);
            }}
            onFirstDataRendered={() => setIsGridReady(true)}
            onSortChanged={(e) => handleSortChanged(null, e)}
            onFilterChanged={(e) => {
              setHasFilters(hasFiltersChanged(e));
              handleFilterChanged();
            }}
            onColumnMoved={handleColumnMoved}
            onColumnResized={handleColumnResized}
            onColumnPinned={handleColumnPinned}
            getRowId={getRowId}
            loadingOverlayComponent={Spinner}
            pinnedBottomRowData={pinnedBottomRowData}
            suppressCsvExport
            getContextMenuItems={() => getContextCsvExportMenu(onExportHandler)}
            excelStyles={excelStyles}
          />
        </ConciseGridContainer>
      )}
    </VerticalPageContent>
  );
};

export default OptimalStockAndEstimatedPickingTable;
