import React, { useContext, useState, useMemo } from 'react';

import { SettingOutlined, LogoutOutlined, BellOutlined, DownOutlined, GlobalOutlined } from '@ant-design/icons';
import { Select, Tooltip, Dropdown, Space } from 'antd';
import logo from 'assets/images/logo.png';
import { SeasonContext, PermissionsContext, UniverseContext } from 'context';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ConfirmModal } from 'components/Modals';
import AllRoutes from 'screens/AllRoutes';
import { PermissionsEnum } from 'services/graphql/main';
import { logout } from 'services/utils';

import {
  NavbarContainer,
  LeftContainer,
  RightContainer,
  NavbarInnerContainer,
  NavbarLinkContainer,
  NavbarLink,
  Logo,
  StyledSelect,
  LogoutButton,
} from './styles';

const Navbar: React.FC = () => {
  const { t } = useTranslation('translation');
  const { Option } = Select;
  const { permissions } = useContext(PermissionsContext);
  const { activeSeasons, selectedSeason, selectSeason } = useContext(SeasonContext);
  const { universes, selectedUniverse, selectUniverse } = useContext(UniverseContext);

  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);

  const menu = useMemo(
    () => ({
      items: universes?.map((universe) => ({ key: universe?.id || 0, label: universe?.name || '' })),
      onClick: (item: any) => {
        let clickedMenuItem;
        if (universes) {
          clickedMenuItem = universes.find((universe) => universe?.id.toString() == item.key);
        }
        clickedMenuItem && selectUniverse(clickedMenuItem);
      },
    }),
    [selectUniverse, universes],
  );

  return (
    <>
      <NavbarContainer>
        <NavbarInnerContainer>
          <LeftContainer>
            <NavbarLinkContainer>
              <Link to={AllRoutes.Dashboard}>
                <Logo src={logo}></Logo>
              </Link>
              <NavbarLink to={AllRoutes.StoreFleet}>{t('storeFleet.store_fleet')}</NavbarLink>
              <NavbarLink to={AllRoutes.StorePerformance}>{t('storePerformance.store_performance')}</NavbarLink>
              <NavbarLink to={AllRoutes.ProductPerformance}>{t('productPerformance.product_performance')}</NavbarLink>
            </NavbarLinkContainer>
          </LeftContainer>

          <RightContainer>
            <StyledSelect
              value={selectedSeason?.name}
              onChange={(value) => {
                const newSelection = activeSeasons?.find((season) => season?.name === value);
                newSelection && selectSeason(newSelection);
              }}
              placeholder={t('common.select_season')}
              getPopupContainer={(trigger) => trigger.parentNode}
              style={{ marginRight: 15, width: 150 }}
            >
              {activeSeasons?.map((season) => (
                <Option key={season?.id} value={season?.name}>
                  {season?.code}
                </Option>
              ))}
            </StyledSelect>
            <Dropdown menu={menu} trigger={['click']}>
              <a onClick={(e) => e.preventDefault()}>
                <Space style={{ color: '#92ACCA' }}>
                  <GlobalOutlined style={{ color: '#92ACCA', fontSize: 18 }} />
                  {selectedUniverse?.name}
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
            <NavbarLink to={AllRoutes.Logging}>
              <Tooltip title={t('common.logging')}>
                <BellOutlined style={{ fontSize: 18 }} />
              </Tooltip>
            </NavbarLink>
            {(permissions as PermissionsEnum[])?.includes(PermissionsEnum.SettingsView) && (
              <NavbarLink to={AllRoutes.SizeSystemSettings}>
                <Tooltip title={t('common.settings')}>
                  <SettingOutlined style={{ fontSize: 18 }} />
                </Tooltip>
              </NavbarLink>
            )}
            <LogoutButton onClick={() => setConfirmModalOpen(true)}>
              <Tooltip title={t('common.logout')}>
                <LogoutOutlined style={{ fontSize: 18 }} />
              </Tooltip>
            </LogoutButton>
          </RightContainer>
        </NavbarInnerContainer>
      </NavbarContainer>
      <ConfirmModal
        customTitle={t('common.logout_confirmation')}
        onOk={logout}
        open={confirmModalOpen}
        onCancel={() => setConfirmModalOpen(false)}
      />
    </>
  );
};

export default Navbar;
