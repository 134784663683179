import React, { useCallback, useContext, useEffect, useState } from 'react';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Descriptions, Empty, Form, Image, Select } from 'antd';
import { ProductContext, SizeSystemContext, UniverseContext, PermissionsContext, SeasonContext } from 'context';
import { useTranslation } from 'react-i18next';

import { formatNumberWithSpace } from 'components/AgGridComponents/valueFormatters';
import { Spinner } from 'components/UI';
import {
  Collection,
  ProductModule,
  useGetCollectionsLazyQuery,
  useGetProductOptimalStockProductSummaryLazyQuery,
  useUpdateProductModuleMutation,
  PermissionsEnum,
  useGetIsErpMasterLazyQuery,
  useUpdateProductSeasonMutation,
} from 'services/graphql/main';
import { useError } from 'services/utils';

import {
  DescriptionsContentStyle,
  StyledDescriptions,
  StyledNoImageContainer,
  StyledPlaceholder,
  StyledSider,
  SelectWrapper,
} from './styles';

const { Option } = Select;

type Props = {
  isProductArchived: boolean;
  productSeasonId?: number;
  refetchSeason: () => void;
};

const ProductDetailsSider: React.FC<Props> = ({ isProductArchived, productSeasonId, refetchSeason }) => {
  const { t } = useTranslation('translation');
  const { addError } = useError();
  const { selectedUniverse } = useContext(UniverseContext);
  const {
    productId,
    setSizeSystemRowName,
    setSelectedCollectionId,
    setSelectedProductModuleId,
    selectedCollectionId,
    selectedProductModuleId,
    productModulesSelectRef,
    productModules,
    setProductModules,
  } = useContext(ProductContext);
  const { setSelectedSizeSystem } = useContext(SizeSystemContext);
  const { permissions } = useContext(PermissionsContext);
  const { activeSeasons } = useContext(SeasonContext);

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [collections, setCollections] = useState<Array<Collection> | null | undefined>([]);
  const [imageLoadErrorOccurred, setImageLoadErrorOccurred] = useState(false);

  const [
    getProductSummary,
    {
      data: dataProductSummary,
      loading: loadingProductSummary,
      refetch: refetchProductSummary,
      called: calledProductSummary,
    },
  ] = useGetProductOptimalStockProductSummaryLazyQuery({ onError: (err) => addError(err, 'error') });
  const [getCollections, { data: collectionsData, loading: collectionsLoading, called: collectionsCalled }] =
    useGetCollectionsLazyQuery({ onError: (err) => addError(err, 'error') });
  const [getIsErpMasterLazyQuery, { data: isErpMasterData }] = useGetIsErpMasterLazyQuery({
    onError: (err) => addError(err, 'error'),
  });

  const [updateProductModuleMutation, { data: updateProductModuleData, error: updateProductModuleError }] =
    useUpdateProductModuleMutation({ onError: (err) => addError(err, 'warning') });
  const [updateProductSeasonMutation] = useUpdateProductSeasonMutation({ onError: (err) => addError(err, 'warning') });

  useEffect(() => {
    (permissions as PermissionsEnum[])?.includes(PermissionsEnum.ProductPerformanceDetailsEdit) &&
      getIsErpMasterLazyQuery();
  }, [permissions, getIsErpMasterLazyQuery]);

  useEffect(() => {
    selectedUniverse?.id && !collectionsCalled && getCollections({ variables: { universeId: selectedUniverse?.id } });
  }, [collectionsCalled, getCollections, selectedUniverse?.id]);

  useEffect(() => {
    if (productId) {
      if (calledProductSummary) {
        refetchProductSummary({ productId });
      } else {
        getProductSummary({ variables: { productId } });
      }
    }
    setImageLoadErrorOccurred(false);
  }, [calledProductSummary, getProductSummary, productId, refetchProductSummary]);

  useEffect(() => {
    const productSummaryData = dataProductSummary?.productOptimalStockProductSummary;
    if (!productSummaryData) return;

    setSelectedSizeSystem(productSummaryData.sizeSystemId);
    productSummaryData.sizeSystemRowName && setSizeSystemRowName(productSummaryData.sizeSystemRowName);
  }, [dataProductSummary, setSelectedSizeSystem, setSizeSystemRowName]);

  const selectCollection = useCallback(
    (collectionId: number) => {
      setSelectedCollectionId(collectionId);
      setSelectedProductModuleId(null);

      const collection = collectionsData?.collections?.find((collection) => collection?.id === collectionId);

      if (collection?.productModules) setProductModules(collection?.productModules as Array<ProductModule>);
    },
    [collectionsData?.collections, setProductModules, setSelectedCollectionId, setSelectedProductModuleId],
  );

  useEffect(() => {
    if (!collectionsCalled) return;

    if (!collections?.length) {
      collectionsData && setCollections(collectionsData?.collections as Array<Collection>);
    }

    const collectionId = dataProductSummary?.productOptimalStockProductSummary?.collectionId;
    collectionId && selectCollection(collectionId);

    const productModuleId = dataProductSummary?.productOptimalStockProductSummary?.productModuleId;

    if (productModuleId && collectionId) {
      setSelectedProductModuleId(productModuleId);
    }
  }, [
    collections?.length,
    collectionsCalled,
    collectionsData,
    dataProductSummary,
    selectCollection,
    setSelectedProductModuleId,
  ]);

  const handleCollectionChange = useCallback(
    (selectedCollectionId: number) => {
      selectCollection(selectedCollectionId);
      productModulesSelectRef.current?.focus();
    },
    [productModulesSelectRef, selectCollection],
  );

  const handleModuleChange = useCallback(
    async (value: number) => {
      if (!productId) return;

      await updateProductModuleMutation({ variables: { productId, moduleId: value } });
      setSelectedProductModuleId(value);

      refetchProductSummary();
    },
    [productId, refetchProductSummary, setSelectedProductModuleId, updateProductModuleMutation],
  );

  const handleSeasonChange = useCallback(
    async (value: number) => {
      if (!productId) return;

      await updateProductSeasonMutation({ variables: { productId, seasonId: value } });

      refetchSeason();
      refetchProductSummary();
    },
    [productId, refetchProductSummary, updateProductSeasonMutation, refetchSeason],
  );

  const handleImageError = useCallback(() => {
    setImageLoadErrorOccurred(true);
  }, []);

  if (!productId) return null;
  if (loadingProductSummary) return <Spinner />;
  if (!dataProductSummary?.productOptimalStockProductSummary) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  const { productName, productCode, colorName, colorCode, price, photoUrls, permutationField1, permutationField2 } =
    dataProductSummary.productOptimalStockProductSummary;

  const imageSrc = photoUrls ? JSON.parse(photoUrls)[0] : undefined;

  const isModuleSelectionExpected = productModules?.length && !collectionsLoading && !selectedProductModuleId;
  let moduleValidationStatus: '' | 'success' | 'error' | 'warning' | 'validating' | undefined;
  let moduleStatusHelp: string | undefined;

  if (isModuleSelectionExpected) {
    moduleValidationStatus = 'warning';
    moduleStatusHelp = t('productOptimalStock.warning_select_product_module');
  } else if (updateProductModuleData?.updateProductDetails.productModuleId === selectedProductModuleId) {
    moduleValidationStatus = 'success';
    moduleStatusHelp = t('productOptimalStock.success_product_module_updated');
  } else if (updateProductModuleError) {
    moduleValidationStatus = 'error';
    moduleStatusHelp = t('productOptimalStock.error_product_module_not_updated');
  }

  return (
    <StyledSider
      collapsible
      collapsed={isCollapsed}
      onCollapse={setIsCollapsed}
      collapsedWidth={0}
      trigger={isCollapsed ? <RightOutlined /> : <LeftOutlined />}
    >
      {imageLoadErrorOccurred ? (
        <StyledNoImageContainer>{t('productOptimalStock.error_preview_failed_to_load')}</StyledNoImageContainer>
      ) : (
        <Image
          width={170}
          height={170}
          src={imageSrc}
          fallback={t('productOptimalStock.no_preview')}
          placeholder={<StyledPlaceholder>{t('productOptimalStock.preview')}</StyledPlaceholder>}
          preview={!!imageSrc}
          onError={handleImageError}
          style={{ objectFit: 'scale-down', background: '#F1F1F1' }}
        />
      )}

      <StyledDescriptions layout="vertical" column={1} size="small" contentStyle={DescriptionsContentStyle}>
        <Descriptions.Item label={t('productOptimalStock.model_code')}>{productCode || '-'}</Descriptions.Item>
        <Descriptions.Item label={t('productOptimalStock.lib_ref')}>{productName || '-'}</Descriptions.Item>
        <Descriptions.Item label={t('productOptimalStock.color_code')}>{colorCode || '-'}</Descriptions.Item>
        <Descriptions.Item label={t('productOptimalStock.lib_colors')}>{colorName || '-'}</Descriptions.Item>
        <Descriptions.Item label={t('productOptimalStock.selling_price')}>
          {formatNumberWithSpace(price) || '-'}
        </Descriptions.Item>
        <Descriptions.Item label={t('common.permutationFields')}>
          {permutationField1 || permutationField2
            ? permutationField2
              ? `${permutationField1} - ${permutationField2}`
              : permutationField1
            : '-'}
        </Descriptions.Item>
      </StyledDescriptions>

      <Form
        layout="vertical"
        disabled={
          !(permissions as PermissionsEnum[])?.includes(PermissionsEnum.ProductPerformanceDetailsEdit) ||
          isProductArchived
        }
      >
        <SelectWrapper label={t('productOptimalStock.collection')} style={{ marginBottom: 5 }}>
          {collectionsLoading && <Select disabled loading />}
          {collections && (
            <Select
              value={selectedCollectionId}
              style={{ fontSize: 10 }}
              size="small"
              onChange={handleCollectionChange}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {collections.map((collection) => {
                return (
                  collection?.id &&
                  collection.name && (
                    <Option key={collection.id} value={collection.id}>
                      {collection.name}
                    </Option>
                  )
                );
              })}
            </Select>
          )}
        </SelectWrapper>

        <SelectWrapper
          label={t('productOptimalStock.module')}
          hasFeedback
          validateStatus={moduleValidationStatus}
          help={moduleStatusHelp}
          style={{ marginBottom: 5 }}
        >
          {collectionsLoading ? (
            <Select size="small" style={{ fontSize: 10 }} disabled loading></Select>
          ) : (
            <Select
              size="small"
              style={{ fontSize: 10 }}
              onChange={handleModuleChange}
              value={selectedProductModuleId}
              ref={productModulesSelectRef}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {productModules?.map((module) => (
                <Option key={module.id} value={module.id}>
                  {module.name}
                </Option>
              ))}
            </Select>
          )}
        </SelectWrapper>

        {isErpMasterData && !isErpMasterData?.tenantSettings.isErpMasterOfCreationAndModification && (
          <SelectWrapper label={t('common.season')}>
            <Select
              size="small"
              style={{ fontSize: 10 }}
              onChange={handleSeasonChange}
              value={productSeasonId}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {activeSeasons?.map((season) => (
                <Option key={season?.id} value={season?.id}>
                  {season?.code}
                </Option>
              ))}
            </Select>
          </SelectWrapper>
        )}
      </Form>
    </StyledSider>
  );
};

export default ProductDetailsSider;
