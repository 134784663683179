import React, { useCallback, useContext, useEffect, useState } from 'react';

import { CameraFilled, CaretDownOutlined, DeleteFilled, VerticalAlignBottomOutlined } from '@ant-design/icons';
import { Carousel, Col, Form, Input, Row, Select, Switch, Upload, UploadProps } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import axios from 'axios';
import { PermissionsContext, SeasonContext, UniverseContext } from 'context';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { NakedButton, PrimaryButton, Spinner, Button } from 'components/UI';
import { ActionButtonsWrapper } from 'components/wrappers';
import envconfig from 'config/envconfig';
import { partiallyEditedData } from 'helpers/common';
import {
  OriginType,
  PermissionsEnum,
  StoreDetailsDto,
  StoreType,
  useGetStoreTypesQuery,
  useStoreCustomFieldsQuery,
  useStoreDetailsLazyQuery,
  useUpdateStoreDetailsMutation,
} from 'services/graphql/main';
import { useError, getCacheVal, isTokenCloseToExpiration } from 'services/utils';
import { BasicFieldsState } from 'types/common';

import StyledModal from '../StyledModal/StyledModal';
import { ModalProps, StoreCustomFieldsProps } from './common/types';
import {
  ItemContainer,
  SlideShowActionsWrapper,
  SlideShowWrapper,
  StyledCheckboxMini,
  StyledDivider,
  StyledLabel,
} from './styles';

const StoreDetailModal: React.FC<ModalProps> = ({ isOpen, handleCancel, storeId, refetchStoreFleet }) => {
  const { t } = useTranslation('translation');
  const { addError, addCustomError } = useError();
  const token = getCacheVal('token');

  const { selectedUniverse } = useContext(UniverseContext);
  const { selectedSeason } = useContext(SeasonContext);
  const { permissions } = useContext(PermissionsContext);

  const [storeDetails, setStoreDetails] = useState<StoreDetailsDto>();
  const [customFields, setCustomFields] = useState<StoreCustomFieldsProps>();
  const [storeTypes, setStoreTypes] = useState<StoreType[]>();
  const [uploadedImgs, setUploadedImgs] = useState<any[]>([]);
  const [mutationState, setMutationState] = useState<any>();
  const [modificationsState, setModificationsState] = useState<BasicFieldsState>({});
  const [imgsUploading, setImgsUploading] = useState(false);
  const hasUpdates = !isEmpty(modificationsState);

  const [
    getStoreDetails,
    { loading: storeDetailsLoading, data: storeDetailsData, refetch: storeDetailsRefetch, called: storeDetailsCalled },
  ] = useStoreDetailsLazyQuery({ onError: (err) => addError(err, 'error') });

  useEffect(() => {
    if (!selectedUniverse || !selectedSeason || !isOpen) return;

    if (storeDetailsCalled) {
      storeDetailsRefetch({ storeId: storeId || 0, universeId: selectedUniverse.id, seasonId: selectedSeason.id });
    } else {
      getStoreDetails({
        variables: { storeId: storeId || 0, universeId: selectedUniverse.id, seasonId: selectedSeason.id },
      });
    }
  }, [getStoreDetails, selectedSeason, selectedUniverse, storeDetailsCalled, storeDetailsRefetch, storeId, isOpen]);

  const { data: customFieldsData } = useStoreCustomFieldsQuery({ onError: (err) => addError(err, 'error') });
  const { data: storeTypesData } = useGetStoreTypesQuery({ onError: (err) => addError(err, 'error') });
  const [updateStoreDetails] = useUpdateStoreDetailsMutation({ onError: (err) => addError(err, 'warning') });

  const [isAutoplay, setIsAutoplay] = useState(false);
  const isDataComingFromERP = storeDetailsData?.storeDetails?.originType === OriginType.Imported;

  useEffect(() => {
    if (storeDetails && storeDetailsCalled && isOpen) {
      refetchStoreFleet();
    }
  }, [isOpen, refetchStoreFleet, storeDetails, storeDetailsCalled]);

  const toggleAutoplay = (e: CheckboxChangeEvent) => {
    setIsAutoplay(e.target.checked);
  };

  const handleSaveMutation = async () => {
    handleCancel();

    delete mutationState.name;
    delete mutationState.photoUrls;

    if (hasUpdates) {
      if (selectedSeason && selectedUniverse) {
        await updateStoreDetails({
          variables: {
            storeDetails: {
              id: storeId as number,
              universeId: selectedUniverse.id,
              seasonId: selectedSeason?.id,
              ...modificationsState,
            },
          },
        });

        await storeDetailsRefetch();
        refetchStoreFleet();

        setModificationsState({});
      }
    }
  };

  const handleOnChange = (value: string | number | undefined, objectProp: string) => {
    if (objectProp.includes('customFieldValue') && value === undefined) {
      setMutationState((prevState: StoreDetailsDto) => ({
        ...prevState,
        [objectProp]: null,
      }));
    } else {
      setMutationState((prevState: StoreDetailsDto) => ({
        ...prevState,
        [objectProp]: value,
      }));
    }

    const newModificationState = partiallyEditedData(
      objectProp,
      value,
      modificationsState,
      storeDetailsData?.storeDetails,
    );

    if (newModificationState) {
      setModificationsState(newModificationState);
    }
  };

  const handleSyncedChange = async (checked: boolean) => {
    if (storeId && selectedUniverse && selectedSeason) {
      await updateStoreDetails({
        variables: {
          storeDetails: {
            id: storeId,
            isSynced: checked,
            universeId: selectedUniverse.id,
            seasonId: selectedSeason?.id,
          },
        },
      });

      await storeDetailsRefetch();
    }
  };

  const handleExcludeMinMaxChange = async (checked: boolean) => {
    if (storeId && selectedSeason && selectedUniverse) {
      await updateStoreDetails({
        variables: {
          storeDetails: {
            id: storeId,
            ignoreMinMax: checked,
            universeId: selectedUniverse.id,
            seasonId: selectedSeason?.id,
          },
        },
      });

      await storeDetailsRefetch();
    }
  };

  const handleShowcaseManagementChange = async (checked: boolean) => {
    if (storeId && selectedSeason && selectedUniverse) {
      await updateStoreDetails({
        variables: {
          storeDetails: {
            id: storeId,
            handlesShowcase: checked,
            universeId: selectedUniverse.id,
            seasonId: selectedSeason?.id,
          },
        },
      });

      await storeDetailsRefetch();
    }
  };

  const handleActiveChange = async (checked: boolean) => {
    if (storeId && selectedSeason && selectedUniverse) {
      await updateStoreDetails({
        variables: {
          storeDetails: {
            id: storeId,
            isActive: checked,
            universeId: selectedUniverse.id,
            seasonId: selectedSeason?.id,
          },
        },
      });

      await storeDetailsRefetch();
    }
  };

  const handleDeleteImg = async (img: string) => {
    if (storeId && selectedSeason && selectedUniverse) {
      await updateStoreDetails({
        variables: {
          storeDetails: {
            id: storeId,
            universeId: selectedUniverse.id,
            seasonId: selectedSeason?.id,
            photoUrls: JSON.stringify(JSON.parse(storeDetails?.photoUrls!).filter((url: string) => url !== img)),
          },
        },
      });

      await storeDetailsRefetch();
    }
  };

  const prepareCustomFieldsData = (preparedCustomFields: any, customFields: any) => {
    for (const key in customFields) {
      if (Object.prototype.hasOwnProperty.call(customFields, key)) {
        if (key.includes('customFieldValues')) {
          const arrayOfValues = JSON.parse(customFields[key]);

          preparedCustomFields[key] = arrayOfValues;
        } else {
          preparedCustomFields[key] = customFields[key];
        }
      }
    }
  };

  const prepareAllData = useCallback(() => {
    const preparedCustomFields = {};
    const customFields: any = customFieldsData?.storeCustomFields!;

    let prepareStoreDetailsData: any = {};

    if (storeDetailsData?.storeDetails && customFields) {
      const customFieldValue1Result =
        customFields?.customFieldValues1 &&
        storeDetailsData.storeDetails?.customFieldValue1 &&
        JSON.parse(customFields?.customFieldValues1).find(
          (obj: { Code: string; Label: string }) =>
            obj.Code === JSON.parse(storeDetailsData.storeDetails?.customFieldValue1!).code,
        );
      const customFieldValue2Result =
        customFields?.customFieldValues2 &&
        storeDetailsData.storeDetails?.customFieldValue2 &&
        JSON.parse(customFields?.customFieldValues2!).find(
          (obj: { Code: string; Label: string }) =>
            obj.Code === JSON.parse(storeDetailsData.storeDetails?.customFieldValue2!).code,
        );
      const customFieldValue3Result =
        customFields?.customFieldValues3 &&
        storeDetailsData.storeDetails?.customFieldValue3 &&
        JSON.parse(customFields?.customFieldValues3!).find(
          (obj: { Code: string; Label: string }) =>
            obj.Code === JSON.parse(storeDetailsData.storeDetails?.customFieldValue3!).code,
        );
      const customFieldValue4Result =
        customFields?.customFieldValues4 &&
        storeDetailsData.storeDetails?.customFieldValue4 &&
        JSON.parse(customFields?.customFieldValues4!).find(
          (obj: { Code: string; Label: string }) =>
            obj.Code === JSON.parse(storeDetailsData.storeDetails?.customFieldValue4!).code,
        );
      const customFieldValue5Result =
        customFields?.customFieldValues5 &&
        storeDetailsData.storeDetails?.customFieldValue5 &&
        JSON.parse(customFields?.customFieldValues5!).find(
          (obj: { Code: string; Label: string }) =>
            obj.Code === JSON.parse(storeDetailsData.storeDetails?.customFieldValue5!).code,
        );

      if (customFieldValue1Result) {
        prepareStoreDetailsData.customFieldValue1 = JSON.stringify([customFieldValue1Result]);
      }

      if (customFieldValue2Result) {
        prepareStoreDetailsData.customFieldValue2 = JSON.stringify([customFieldValue2Result]);
      }

      if (customFieldValue3Result) {
        prepareStoreDetailsData.customFieldValue3 = JSON.stringify([customFieldValue3Result]);
      }

      if (customFieldValue4Result) {
        prepareStoreDetailsData.customFieldValue4 = JSON.stringify([customFieldValue4Result]);
      }

      if (customFieldValue5Result) {
        prepareStoreDetailsData.customFieldValue5 = JSON.stringify([customFieldValue5Result]);
      }

      prepareStoreDetailsData = {
        ...prepareStoreDetailsData,
        capacity: storeDetailsData?.storeDetails?.capacity,
        capacityType: storeDetailsData?.storeDetails?.capacityType,
        code: storeDetailsData?.storeDetails?.code,
        ignoreMinMax: storeDetailsData?.storeDetails?.ignoreMinMax,
        handlesShowcase: storeDetailsData?.storeDetails?.handlesShowcase,
        id: storeDetailsData?.storeDetails?.id,
        isActive: storeDetailsData?.storeDetails?.isActive,
        isSynced: storeDetailsData?.storeDetails?.isSynced,
        pickingPriority: storeDetailsData?.storeDetails?.pickingPriority,
        regionName: storeDetailsData?.storeDetails?.regionName,
        storeTypeId: storeDetailsData?.storeDetails?.storeTypeId,
        photoUrls: storeDetailsData?.storeDetails?.photoUrls,
      };
    }

    if (preparedCustomFields && customFields) {
      prepareCustomFieldsData(preparedCustomFields, customFields);
    }

    storeDetailsData && setStoreDetails(prepareStoreDetailsData as StoreDetailsDto);
    customFieldsData && setCustomFields(preparedCustomFields);
    storeTypesData && setStoreTypes(storeTypesData.storeTypes as StoreType[]);

    setMutationState(prepareStoreDetailsData);
  }, [customFieldsData, storeDetailsData, storeTypesData]);

  const handleCancelButton = () => {
    prepareAllData();

    setModificationsState({});
  };

  const uploadProps: UploadProps = {
    maxCount: 3,
    accept: '.jpg,.jpeg,.png',
    multiple: true,
    onRemove: (file) => {
      const index = uploadedImgs.indexOf(file);
      const newFileList = uploadedImgs.slice();
      newFileList.splice(index, 1);
      setUploadedImgs(newFileList);
    },
    beforeUpload: (file) => {
      setUploadedImgs([...uploadedImgs, file]);

      return false;
    },
    fileList: uploadedImgs,
  };

  const handleImgUpload = async () => {
    setImgsUploading(true);
    const data = new FormData();
    uploadedImgs.forEach((img) => {
      data.append('files', img);
    });
    const params = new URLSearchParams();
    storeId && params.append('storeId', storeId.toString());
    token && isTokenCloseToExpiration(token);
    try {
      await axios.post(envconfig.fileUploadUrl + '/UploadStoreImages', data, {
        headers: { 'Content-Type': 'multipart/form-data', authorization: `Bearer ${token}` },
        params,
      });
      setImgsUploading(false);
      toast.success(t('storeFleetSheetPopup.images_successfully_uploaded'), { theme: 'colored' });
      setUploadedImgs([]);
      storeDetailsRefetch();
    } catch (err) {
      addCustomError(err, 'error');
      setUploadedImgs([]);
      setImgsUploading(false);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setModificationsState({});

      return;
    }

    prepareAllData();
  }, [storeDetailsData, customFieldsData, storeTypesData, prepareAllData, isOpen]);

  return (
    <StyledModal
      title={t('storeFleetSheetPopup.store_fleet_sheet')}
      open={isOpen}
      onCancel={handleCancel}
      footer={null}
      width="70%"
      centered={true}
    >
      {storeDetailsLoading || !storeDetails ? (
        <Spinner size="large" />
      ) : (
        <>
          <Form
            wrapperCol={{
              span: 15,
            }}
            layout="horizontal"
            disabled={!(permissions as PermissionsEnum[])?.includes(PermissionsEnum.StoreFleetMainTableEdit)}
          >
            <SlideShowWrapper>
              <SlideShowActionsWrapper>
                <Upload {...uploadProps}>
                  <PrimaryButton
                    icon={<VerticalAlignBottomOutlined />}
                    style={{ border: 'none', marginRight: 10 }}
                    text={t('common.import')}
                  />
                </Upload>
                {storeDetails.photoUrls && (
                  <div className="checkbox-wrapper">
                    <StyledCheckboxMini id="toggleAutoplayCheckbox" onChange={toggleAutoplay} />
                    <StyledLabel htmlFor="toggleAutoplayCheckbox">
                      {t('storeFleetSheetPopup.scrolling_photos')}
                    </StyledLabel>
                  </div>
                )}
              </SlideShowActionsWrapper>
              {uploadedImgs.length > 0 && (
                <PrimaryButton
                  onClick={handleImgUpload}
                  textKey="common.save"
                  style={{ marginBottom: 20 }}
                  disabled={imgsUploading}
                  loading={imgsUploading}
                />
              )}
              {!storeDetails.photoUrls || !JSON.parse(storeDetails.photoUrls).length ? (
                <div className="slideshow-content">
                  <CameraFilled className="default-background-camera" />
                  <p className="default-background-image-text">{t('storeFleetSheetPopup.no_image_available')}</p>
                </div>
              ) : (
                <Carousel autoplay={isAutoplay} dotPosition="right">
                  {JSON.parse(storeDetails.photoUrls).map((photo: string, i: number) => (
                    <div className="slideshow-content" key={i}>
                      <img src={photo} alt="Store img content" />
                      <Button type="text" onClick={() => handleDeleteImg(photo)} className="delete-img-btn">
                        <DeleteFilled />
                        {t('storeFleetSheetPopup.delete_image')}
                      </Button>
                    </div>
                  ))}
                </Carousel>
              )}
            </SlideShowWrapper>

            <StyledDivider />

            <Row>
              <Col span={8}>
                <Form.Item>
                  <StyledLabel htmlFor="magasinSelect">{t('storeFleetSheetPopup.type_of_establishment')}</StyledLabel>
                  <Select
                    id="magasinSelect"
                    // disabled={isDataComingFromERP}
                    placeholder={t('common.select_a_value')}
                    suffixIcon={<CaretDownOutlined />}
                    value={storeTypes?.find((value: StoreType) => value?.id === mutationState?.storeTypeId)?.name}
                    onChange={(value) => handleOnChange(Number(value), 'storeTypeId')}
                    size="small"
                  >
                    {storeTypes?.map((value: StoreType, i: number) => (
                      <Select.Option key={i} value={value?.id}>
                        {value?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <StyledLabel htmlFor="magasinCodeInput">{t('storeFleetSheetPopup.store_code')}</StyledLabel>
                  <Input
                    id="magasinCodeInput"
                    // disabled={isDataComingFromERP}
                    title={isDataComingFromERP ? t('storeFleetSheetPopup.data_is_coming_from_erp') : undefined}
                    readOnly
                    value={mutationState?.code}
                    size="small"
                  />
                </Form.Item>
                <Form.Item>
                  <StyledLabel htmlFor="regionInput">{t('storeFleetSheetPopup.region')}</StyledLabel>
                  <Input id="regionInput" readOnly value={mutationState?.regionName} size="small" />
                </Form.Item>
                <Form.Item>
                  <StyledLabel htmlFor="villeInput">{t('storeFleetSheetPopup.name')}</StyledLabel>
                  <Input id="villeInput" readOnly value={mutationState?.name} size="small" />
                </Form.Item>
                {customFields?.customFieldName1 && (
                  <Form.Item>
                    <StyledLabel htmlFor="caractMag1Select">{customFields?.customFieldName1}</StyledLabel>
                    <Select
                      id="caractMag1Select"
                      // disabled={isDataComingFromERP}
                      placeholder={t('common.select_a_value')}
                      suffixIcon={<CaretDownOutlined />}
                      onChange={(value: string) => handleOnChange(value, 'customFieldValue1')}
                      value={
                        mutationState?.customFieldValue1 !== undefined
                          ? mutationState?.customFieldValue1
                          : storeDetails?.customFieldValue1
                          ? JSON.stringify([storeDetails?.customFieldValue1])
                          : null
                      }
                      size="small"
                      allowClear
                    >
                      {customFields?.customFieldValues1?.map((value: { Code: string; Label: string }, i: number) => (
                        <Select.Option key={i} value={JSON.stringify([value])}>
                          {value.Label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Col>
              <Col span={8}>
                {customFields?.customFieldName2 && (
                  <Form.Item>
                    <StyledLabel htmlFor="caractMag2Select">{customFields?.customFieldName2}</StyledLabel>
                    <Select
                      id="caractMag2Select"
                      placeholder={t('common.select_a_value')}
                      suffixIcon={<CaretDownOutlined />}
                      // disabled={isDataComingFromERP}
                      onChange={(value: string) => handleOnChange(value, 'customFieldValue2')}
                      value={
                        mutationState?.customFieldValue2 !== undefined
                          ? mutationState?.customFieldValue2
                          : storeDetails?.customFieldValue2
                          ? JSON.stringify([storeDetails?.customFieldValue2])
                          : null
                      }
                      size="small"
                      allowClear
                    >
                      {customFields?.customFieldValues2?.map((value: { Code: string; Label: string }, i: number) => (
                        <Select.Option key={i} value={JSON.stringify([value])}>
                          {value.Label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
                {customFields?.customFieldName3 && (
                  <Form.Item>
                    <StyledLabel htmlFor="caractMag3Select">{customFields?.customFieldName3}</StyledLabel>
                    <Select
                      id="caractMag3Select"
                      placeholder={t('common.select_a_value')}
                      suffixIcon={<CaretDownOutlined />}
                      // disabled={isDataComingFromERP}
                      onChange={(value: string) => handleOnChange(value, 'customFieldValue3')}
                      value={
                        mutationState?.customFieldValue3 !== undefined
                          ? mutationState?.customFieldValue3
                          : storeDetails?.customFieldValue3
                          ? JSON.stringify([storeDetails?.customFieldValue3])
                          : null
                      }
                      size="small"
                      allowClear
                    >
                      {customFields?.customFieldValues3?.map((value: { Code: string; Label: string }, i: number) => (
                        <Select.Option key={i} value={JSON.stringify([value])}>
                          {value.Label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
                {customFields?.customFieldName4 && (
                  <Form.Item>
                    <StyledLabel htmlFor="caractMag4Select">{customFields?.customFieldName4}</StyledLabel>
                    <Select
                      id="caractMag4Select"
                      placeholder={t('common.select_a_value')}
                      suffixIcon={<CaretDownOutlined />}
                      // disabled={isDataComingFromERP}
                      onChange={(value: string) => handleOnChange(value, 'customFieldValue4')}
                      value={
                        mutationState?.customFieldValue4 !== undefined
                          ? mutationState?.customFieldValue4
                          : storeDetails?.customFieldValue4
                          ? JSON.stringify([storeDetails?.customFieldValue4])
                          : null
                      }
                      size="small"
                      allowClear
                    >
                      {customFields?.customFieldValues4?.map((value: { Code: string; Label: string }, i: number) => (
                        <Select.Option key={i} value={JSON.stringify([value])}>
                          {value.Label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
                {customFields?.customFieldName5 && (
                  <Form.Item>
                    <StyledLabel htmlFor="caractMag4Select">{customFields?.customFieldName5}</StyledLabel>
                    <Select
                      id="caractMag4Select"
                      placeholder={t('common.select_a_value')}
                      suffixIcon={<CaretDownOutlined />}
                      // disabled={isDataComingFromERP}
                      onChange={(value: string) => handleOnChange(value, 'customFieldValue5')}
                      value={
                        mutationState?.customFieldValue5 !== undefined
                          ? mutationState?.customFieldValue5
                          : storeDetails?.customFieldValue5
                          ? JSON.stringify([storeDetails?.customFieldValue5])
                          : null
                      }
                      size="small"
                      allowClear
                    >
                      {customFields?.customFieldValues5?.map((value: { Code: string; Label: string }, i: number) => (
                        <Select.Option key={i} value={JSON.stringify([value])}>
                          {value.Label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
                <Form.Item>
                  <ItemContainer>
                    <StyledLabel htmlFor="transStockSwitch">{t('storeFleetSheetPopup.ideal_stock_trans')}</StyledLabel>
                    <Switch
                      id="transStockSwitch"
                      disabled={
                        hasUpdates ||
                        !(permissions as PermissionsEnum[])?.includes(PermissionsEnum.StoreFleetMainTableEdit)
                      }
                      checked={storeDetails?.isSynced}
                      onChange={handleSyncedChange}
                    />
                  </ItemContainer>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item>
                  <ItemContainer>
                    <StyledLabel htmlFor="exclusGestionCheckbox">
                      {t('storeFleetSheetPopup.excluded_shortage_management')}
                    </StyledLabel>
                    <Switch
                      id="exclusGestionCheckbox"
                      disabled={
                        hasUpdates ||
                        !(permissions as PermissionsEnum[])?.includes(PermissionsEnum.StoreFleetMainTableEdit)
                      }
                      checked={storeDetails?.ignoreMinMax}
                      onChange={handleExcludeMinMaxChange}
                    />
                  </ItemContainer>
                </Form.Item>
                <Form.Item>
                  <StyledLabel htmlFor="rangInput">{t('storeFleetSheetPopup.picking_priority_rank')}</StyledLabel>
                  <Input
                    id="rangInput"
                    // disabled={isDataComingFromERP}
                    title={isDataComingFromERP ? t('storeFleetSheetPopup.data_is_coming_from_erp') : undefined}
                    value={mutationState?.pickingPriority}
                    onChange={(e) => {
                      const value = Number(e.target.value);

                      return !isNaN(value) && handleOnChange(value, 'pickingPriority');
                    }}
                    size="small"
                  />
                </Form.Item>
                <Form.Item>
                  <StyledLabel htmlFor="capaciteInput">
                    {t('storeFleetSheetPopup.capacity')} ({storeDetails?.capacityType})
                  </StyledLabel>
                  <Input
                    id="capaciteInput"
                    value={mutationState?.capacity}
                    onChange={(e) => {
                      const value = Number(e.target.value);

                      return !isNaN(value) && handleOnChange(value, 'capacity');
                    }}
                    size="small"
                  />
                </Form.Item>
                <Form.Item>
                  <ItemContainer>
                    <StyledLabel htmlFor="gestionVitrineCheckbox">
                      {t('storeFleetSheetPopup.showcase_management')}
                    </StyledLabel>
                    <Switch
                      id="gestionVitrineCheckbox"
                      disabled={
                        hasUpdates ||
                        !(permissions as PermissionsEnum[])?.includes(PermissionsEnum.StoreFleetMainTableEdit)
                      }
                      checked={storeDetails?.handlesShowcase}
                      onChange={handleShowcaseManagementChange}
                    />
                  </ItemContainer>
                </Form.Item>
                <Form.Item>
                  <ItemContainer>
                    <StyledLabel htmlFor="actifCheckbox">{t('storeFleetSheetPopup.active')}</StyledLabel>
                    <Switch
                      id="actifCheckbox"
                      disabled={
                        hasUpdates ||
                        !(permissions as PermissionsEnum[])?.includes(PermissionsEnum.StoreFleetMainTableEdit)
                      }
                      checked={storeDetails?.isActive}
                      onChange={handleActiveChange}
                    />
                  </ItemContainer>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <ActionButtonsWrapper>
            {hasUpdates ? (
              <>
                <NakedButton type="text" onClick={handleCancelButton} textKey="common.cancel" />

                <PrimaryButton onClick={handleSaveMutation} textKey="common.save"></PrimaryButton>
              </>
            ) : null}
          </ActionButtonsWrapper>
        </>
      )}
    </StyledModal>
  );
};

export default React.memo(StoreDetailModal);
