type ValidateGridCustom = {
  code?: string;
  isValid: boolean;
};

type GridValidationsTypes = {
  [key: string]: string;
};

const GRID_VALIDATIONS_TYPES: GridValidationsTypes = {
  duplicates: 'doesDuplicatedValuesExist',
  emptyCell: 'doesEmptyCellExist',
  length: 'isCellsValuesLengthValid',
  total: 'isTotalValid',
};

const GRID_VALIDATIONS: any = {
  doesDuplicatedValuesExist: (data: any) => {
    if (data[0]) {
      const keys = Object.keys(data[0]);

      for (const key of keys) {
        const rawData = data?.map((x: any) => x?.[key]?.toLowerCase());
        const noDuplicateData = new Set(rawData);

        if (rawData?.length !== noDuplicateData?.size) {
          const result = {
            isValid: false,
            code: 'DUPLICATES_EXIST_IN_GRID',
          };

          return result;
        }
      }
    }

    return { isValid: true };
  },
  doesEmptyCellExist: (data: any) => {
    for (const row of data) {
      for (const key in row) {
        if (row[key]?.trim() === '') {
          const result = {
            isValid: false,
            code: 'EMPTY_CELLS_EXIST_IN_GRID',
          };

          return result;
        }
      }
    }

    return { isValid: true };
  },
  isCellsValuesLengthValid: (data: any) => {
    for (const row of data) {
      for (const key in row) {
        if (row[key]?.length < 1 || row[key]?.length > 50) {
          const result = {
            isValid: false,
            code: 'FIELD_MAX_MIN_LENGTH',
          };

          return result;
        }
      }
    }

    return { isValid: true };
  },
  isTotalValid: (data: any) => {
    const areTotalsValid = data.every((value: { total: number }) => value?.total === 100);

    if (!areTotalsValid) {
      const result = {
        isValid: false,
        code: 'TOTAL_MUST_BE_100',
      };

      return result;
    }

    return { isValid: true };
  },
};

export const validateGridCustom = (types: string[], data: any): ValidateGridCustom => {
  for (const type of types) {
    const currentValidation = GRID_VALIDATIONS[GRID_VALIDATIONS_TYPES[type]](data);

    if (!currentValidation.isValid) {
      return currentValidation;
    }
  }

  return { isValid: true };
};
