import React, { useState } from 'react';

type SelectedCollection =
  | {
      code: string;
      id: number;
    }
  | any;

type CollectionContextType = {
  isAssociatedGroupsTableUpdateActive: boolean;
  isCollectionsTableUpdateActive: boolean;
  selectedCollection: SelectedCollection | null;
  setIsAssociatedGroupsTableUpdateActive: (value: boolean) => void;
  setIsCollectionsTableUpdateActive: (value: boolean) => void;
  setSelectedCollection: (value: SelectedCollection) => void;
};

export const CollectionContext = React.createContext<CollectionContextType>({} as CollectionContextType);

type ContextProviderType = {
  children: React.ReactNode;
};

export const CollectionContextProvider: React.FC<ContextProviderType> = ({ children }) => {
  const [isAssociatedGroupsTableUpdateActive, setIsAssociatedGroupsTableUpdateActive] = useState(false);
  const [isCollectionsTableUpdateActive, setIsCollectionsTableUpdateActive] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState<number | null>(null);

  return (
    <CollectionContext.Provider
      value={{
        isAssociatedGroupsTableUpdateActive,
        isCollectionsTableUpdateActive,
        setIsAssociatedGroupsTableUpdateActive,
        setIsCollectionsTableUpdateActive,
        selectedCollection,
        setSelectedCollection,
      }}
    >
      {children}
    </CollectionContext.Provider>
  );
};
