import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';

import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { ConciseGridContainer } from 'components/AgGridComponents';
import { ConfirmModal } from 'components/Modals';
import { COLUMN_TYPES, SIDE_BAR } from 'constants/gridConstants';
import { useGetAuditableInfoLazyQuery, AuditableObjectType, AuditableInfoDto } from 'services/graphql/main';
import { useError } from 'services/utils';

type Props = {
  fromDate: string | null;
  objectType: AuditableObjectType | undefined;
  selectedSeason: string | null;
  toDate: string | null;
};

const LoggingTable: React.FC<Props> = ({ fromDate, toDate, objectType, selectedSeason }) => {
  const { t } = useTranslation('translation');
  const gridRef = useRef<AgGridReact>(null);
  const { addError } = useError();
  const pageSize = 25000;
  const [isDataExceededDialogOpen, setIsDataExceededDialogOpen] = useState(false);
  const [rowData, setRowData] = useState<AuditableInfoDto[]>([]);
  const [isGridReady, setIsGridReady] = useState(false);

  const [getAuditableInfoLazyQuery] = useGetAuditableInfoLazyQuery({
    onError: (err) => addError(err, 'error'),
    fetchPolicy: 'no-cache',
  });

  const getInitialData = useCallback(() => {
    return getAuditableInfoLazyQuery({
      variables: {
        take: pageSize,
        objectType,
        dateRangeStart: fromDate,
        dateRangeEnd: toDate,
        seasonCode: selectedSeason,
      },
    });
  }, [getAuditableInfoLazyQuery, objectType, fromDate, toDate, selectedSeason]);

  useEffect(() => {
    const updateRowData = async () => {
      setIsGridReady(false);
      const res = await getInitialData();

      res.data?.auditableInfos?.totalCount &&
        setIsDataExceededDialogOpen(res.data?.auditableInfos?.totalCount > pageSize);
      setRowData(res.data?.auditableInfos?.items as AuditableInfoDto[]);

      setIsGridReady(true);
    };

    updateRowData();
  }, [objectType, fromDate, toDate, selectedSeason, getInitialData]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100,
      editable: false,
      sortable: true,
      filter: true,
      resizable: true,
    };
  }, []);

  const columnDefs: ColDef[] = [
    {
      field: 'timestamp',
      headerName: t('logging.date_hour'),
      valueFormatter: (params) => moment(params.value).local().format('DD-MM-YYYY / HH:mm:ss'),
    },
    {
      field: 'user',
      headerName: t('logging.user'),
    },
    {
      field: 'code',
      headerName: t('logging.code'),
    },
    {
      field: 'action',
      headerName: t('logging.action'),
    },
    {
      field: 'object',
      headerName: t('logging.object'),
    },
    {
      field: 'property',
      headerName: t('logging.property'),
    },
    {
      field: 'level',
      headerName: t('logging.level'),
    },
    {
      field: 'oldValue',
      headerName: t('logging.old_value'),
    },
    {
      field: 'newValue',
      headerName: t('logging.new_value'),
    },
  ];
  const toggleDialogOpen = () => setIsDataExceededDialogOpen(!isDataExceededDialogOpen);

  return (
    <>
      <ConciseGridContainer isGridReady={isGridReady}>
        <AgGridReact
          containerStyle={{ width: '100%', marginTop: 30, height: '95%' }}
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          suppressCellFocus
          rowSelection={'single'}
          columnTypes={COLUMN_TYPES}
          onGridReady={() => setIsGridReady(true)}
          cacheBlockSize={pageSize}
          enableRangeSelection
          sideBar={SIDE_BAR}
        />
      </ConciseGridContainer>
      <ConfirmModal
        customTitleKey="logging.not_all_items_displayed"
        textKey="logging.refine_your_search"
        onCancel={toggleDialogOpen}
        onOk={toggleDialogOpen}
        open={isDataExceededDialogOpen}
        showOnlyCloseBtn
      />
    </>
  );
};

export default LoggingTable;
