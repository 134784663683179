import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { LineChartOutlined, CopyOutlined } from '@ant-design/icons';
import {
  CellEditingStoppedEvent,
  ColDef,
  ExcelStyle,
  GetRowIdFunc,
  GetRowIdParams,
  ICellRendererParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import SimulationPickingImg from 'assets/images/simulation-picking-image.png';
import { TableViewsContext, PermissionsContext } from 'context';
import { isEmpty, isNumber } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { getFormattedCurrentDateTime, getCurrentWeek } from 'utils/moment';

import {
  CellColoringHint,
  ClearFiltersButton,
  ConciseGridContainer,
  NumericCellEditor,
} from 'components/AgGridComponents';
import { numberWithSpaceFormatter } from 'components/AgGridComponents/valueFormatters';
import Views from 'components/AgGridComponents/Views/Views';
import ImportExportXlsxComponent from 'components/ImportExportXlsxComponent';
import { ConfirmModal, OptimalStockAndEstimatedPickingModal, StorePerformanceAnalyticsModal } from 'components/Modals';
import NoSeasonEmptyState from 'components/NoSeasonEmptyState';
import { Button, Checkbox, Spinner } from 'components/UI';
import { ActionButtonsWrapper, VerticalPageContent } from 'components/wrappers';
import { COLLECTION_FIELD_PREFIX, COLLECTION_ID_COL_DEF } from 'constants/collections';
import {
  ALT_HIGHLIGHTED_COLUMN_COL_DEFS,
  ALT_HIGHLIGHTED_HEADER_CLASS,
  EDITABLE_CELL_CONTENT_CLASS,
  GRID_DEFAULT_COL_DEFS,
  GRID_DEFAULT_PROPS,
  HIGHLIGHTED_COLUMN_COL_DEFS,
} from 'constants/gridConstants';
import { prepareRowData } from 'helpers/collections';
import { generateCustomFieldsColDefs } from 'helpers/customFields';
import { hasFiltersChanged } from 'helpers/grid';
import { applyColWidths, getVisibleColDefs, sortEmpty } from 'helpers/gridCols';
import { getContextMenu } from 'helpers/gridContextMenu';
import { setGridRows } from 'helpers/gridRows';
import { useCommonData } from 'hooks/useCommonData';
import { useGridCols } from 'hooks/useGridCols';
import { CONDITIONAL_FORMATTING_COLORS } from 'screens/ProductPerformance/constants';
import {
  StorePerformanceDto,
  useGetCollectionsNamesQuery,
  useGetStorePerformanceLazyQuery,
  useGetTenantSettingsLazyQuery,
  useStoreCustomFieldsQuery,
  useStoreReplaceValidatedWithImplantedStrengthMutation,
  useUpdateStorePerformanceMutation,
  PermissionsEnum,
} from 'services/graphql/main';
import { useError } from 'services/utils';
import { CollectionColDef } from 'types/collections';
import { SelectedViewId } from 'types/views';

const { LIGHT_GREEN, PINK } = CONDITIONAL_FORMATTING_COLORS;

const CurrentWeekLabel = styled.p`
  margin: 0;
  color: ${(p) => p.theme.white};
`;

const StorePerformanceTable: React.FC = () => {
  const { t } = useTranslation('translation');
  const { addError } = useError();

  const { selectedViewId, getColumnsWidths, getView, pendingTableRefresh, setPendingTableRefresh } =
    useContext(TableViewsContext);
  const { permissions } = useContext(PermissionsContext);

  const { seasonId, universeId, seasonName, universeName, universeCode } = useCommonData();

  const [viewColsInitialized, setViewColsInitialized] = useState<SelectedViewId | undefined>();
  const [allColumnDefs, setAllColumnDefs] = useState<ColDef[] | null>(null);
  const [columnDefs, setColumnDefs] = useState<ColDef[] | null>(null);
  const [rowData, setRowData] = useState<StorePerformanceDto[]>([]);
  const [isGridReady, setIsGridReady] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState({ analyticsModal: false, optimalStockModal: false, copyModal: false });
  const [replaceNonZeroValuesOnCopy, setReplaceNonZeroValuesOnCopy] = useState(false);
  const [hasFilters, setHasFilters] = useState(false);
  const [refetchLoading, setRefetchLoading] = useState(false);

  const [updateStorePerformanceMutation] = useUpdateStorePerformanceMutation({
    onError: (err) => addError(err, 'error'),
  });

  const [
    getStorePerformanceLazyQuery,
    {
      data: dataStorePerformance,
      loading: loadingStorePerformance,
      refetch: refetchStorePerformance,
      error: errorStorePerformance,
    },
  ] = useGetStorePerformanceLazyQuery({
    onError: (err) => addError(err, 'error'),
  });
  const [storeReplaceValidatedWithImplantedStrengthMutation] = useStoreReplaceValidatedWithImplantedStrengthMutation({
    onError: (err) => addError(err, 'warning'),
  });
  const {
    data: dataCollections,
    loading: loadingCollections,
    refetch: refetchDataCollections,
  } = useGetCollectionsNamesQuery({
    variables: { universeId, isActive: true },
    onError: (err) => addError(err, 'error'),
  });

  const { data: dataStoreCustomFields } = useStoreCustomFieldsQuery({ onError: (err) => addError(err, 'error') });

  const [getTenantSettingsLazyQuery, { data: dataTenantSettings }] = useGetTenantSettingsLazyQuery({
    onError: (err) => addError(err, 'error'),
  });
  const storeMaxStrength = dataTenantSettings?.tenantSettings.storeMaxStrength;
  const gridRef = useRef<AgGridReact>(null);
  const updatedRowIdRef = useRef('');
  const loadedDataForSeasonRef = useRef<number | null>(null);

  const { handleColumnMoved, handleColumnResized, handleSortChanged, handleFilterChanged, handleColumnPinned } =
    useGridCols({
      isGridReady,
      gridRef,
      tableName: 'storePerformance',
    });

  useEffect(() => {
    (permissions as PermissionsEnum[])?.includes(PermissionsEnum.StorePerformanceEdit) && getTenantSettingsLazyQuery();
  }, [permissions, getTenantSettingsLazyQuery]);

  useEffect(() => {
    if (!universeId || !seasonId) return;

    const loadData = async () => {
      if (!loadedDataForSeasonRef.current) {
        await getStorePerformanceLazyQuery({ variables: { seasonId, universeId } });
      } else {
        await refetchStorePerformance({ seasonId, universeId });

        gridRef.current?.api.redrawRows();
      }

      loadedDataForSeasonRef.current = seasonId;
    };

    loadData();
  }, [getStorePerformanceLazyQuery, refetchStorePerformance, seasonId, universeId]);

  const getCurrentAndWeekBefore = () => {
    const now = new Date();
    const currentWeek = moment(now).add(-1, 'days');
    const weekAgo = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 8));

    return (
      <div>
        <CurrentWeekLabel>{`${t(
          'productPerformance.current_week',
        )} ${currentWeek.isoWeek()}/${currentWeek.year()}`}</CurrentWeekLabel>
        <CurrentWeekLabel>{`${t(
          'productPerformance.current_week-1',
        )} ${weekAgo.isoWeek()}/${weekAgo.year()}`}</CurrentWeekLabel>
      </div>
    );
  };

  const defaultColDef: ColDef = useMemo(() => {
    return {
      ...GRID_DEFAULT_COL_DEFS,
      cellRenderer(params: ICellRendererParams) {
        const value = params.valueFormatted ? params.valueFormatted : params.value;

        return params.colDef?.editable ? <div className={EDITABLE_CELL_CONTENT_CLASS}>{value}</div> : value;
      },
    };
  }, []);

  useEffect(() => {
    const storePerformanceData = dataStorePerformance?.storePerformance;
    const collectionsData = dataCollections?.collections;

    if (
      loadingStorePerformance ||
      loadingCollections ||
      !storePerformanceData ||
      !collectionsData ||
      (selectedViewId !== undefined && viewColsInitialized === selectedViewId && !pendingTableRefresh) ||
      updatedRowIdRef.current
    )
      return;

    let colDefs: ColDef[] = [
      {
        field: 'code',
        headerName: t(`store.code`),
        minWidth: 65,
        sort: 'asc',
        type: 'TEXT_COLUMN',
        cellClass: 'stringType',
      },
      { field: 'name', headerName: t(`store.name`), type: 'TEXT_COLUMN', comparator: sortEmpty },
    ];

    if (!isEmpty(collectionsData)) {
      const collectionsColDefs: ColDef[] = collectionsData!.map((column, index) => {
        const name = column.name || t('store.collection', { index }) || '';

        return {
          [COLLECTION_ID_COL_DEF]: column.id,
          field: `${COLLECTION_FIELD_PREFIX}${column.id}`,
          headerName: name,
          type: 'TEXT_COLUMN',
          valueGetter(params) {
            const currentColDef = params.colDef as CollectionColDef;
            const collectionId = currentColDef?.[COLLECTION_ID_COL_DEF];

            if (params.data) {
              const { storeToStoreGroups } = params.data as StorePerformanceDto;
              const currentRowCollectionGroups = storeToStoreGroups?.filter(
                (collectionStoreGroup) => collectionStoreGroup?.collectionId === collectionId,
              );

              return !isEmpty(currentRowCollectionGroups)
                ? currentRowCollectionGroups?.map((group) => group?.storeGroupName).join(', ')
                : ' ';
            }

            return '';
          },
        };
      });

      colDefs.push(...collectionsColDefs);
    }

    colDefs.push(
      ...generateCustomFieldsColDefs(dataStoreCustomFields?.storeCustomFields),

      {
        field: 'permutationsCode',
        headerName: t(`common.permutationCodes`),
        type: 'TEXT_COLUMN',
      },
      {
        field: 'permutations',
        headerName: t(`common.permutationFields`),
        type: 'TEXT_COLUMN',
        sort: 'asc',
      },
      {
        field: 'numberOfProductsWithStock',
        headerName: t(`storePerformance.number_of_products_in_store`),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'numberOfProductsWithOptimalStock',
        headerName: t(`storePerformance.number_of_products_with_ideal_stock_in_store`),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'numberOfProductsWithSalesLastWeek',
        headerName: t(`storePerformance.number_of_articles_last_week`, { week: getCurrentWeek() - 1 }),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'salesQuantityLastWeek',
        headerName: t(`storePerformance.previous_week_sales`, { week: getCurrentWeek() - 1 }),
        valueFormatter: numberWithSpaceFormatter,
        ...HIGHLIGHTED_COLUMN_COL_DEFS,
      },
      {
        field: 'stockQuantityLastWeek',
        headerName: t(`storePerformance.previous_week_stocks`, { week: getCurrentWeek() - 1 }),
        valueFormatter: numberWithSpaceFormatter,
        ...HIGHLIGHTED_COLUMN_COL_DEFS,
      },
      {
        field: 'coverageOneWeekBefore',
        headerName: t(`storePerformance.coverage_one_week_before`, { week: getCurrentWeek() - 1 }),
        valueFormatter: numberWithSpaceFormatter,
        ...HIGHLIGHTED_COLUMN_COL_DEFS,
      },
      {
        field: 'coverageTwoWeeksBefore',
        headerName: t(`storePerformance.coverage_two_weeks_before`, { week: getCurrentWeek() - 2 }),
        valueFormatter: numberWithSpaceFormatter,
        ...HIGHLIGHTED_COLUMN_COL_DEFS,
      },
      {
        field: 'coverageThreeWeeksBefore',
        headerName: t(`storePerformance.coverage_three_weeks_before`, { week: getCurrentWeek() - 3 }),
        valueFormatter: numberWithSpaceFormatter,
        ...HIGHLIGHTED_COLUMN_COL_DEFS,
      },
      {
        field: 'averageCoverage',
        headerName: t(`storePerformance.average_coverage`),
        valueFormatter: numberWithSpaceFormatter,
        ...HIGHLIGHTED_COLUMN_COL_DEFS,
      },
      { field: 'optimalStock', headerName: t(`storePerformance.optimal_stock`) },
      {
        field: 'optimalStockOneWeekBefore',
        headerName: t(`storePerformance.optimal_stock_week_before`, { week: getCurrentWeek() - 1 }),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'estimatedPicking',
        headerName: t(`storePerformance.estimated_picking`),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'storeStrengthPerPermutationProposed',
        headerName: t(`storePerformance.store_strength_per_permutation_proposed`),
        valueFormatter: numberWithSpaceFormatter,
        headerClass: ALT_HIGHLIGHTED_HEADER_CLASS,
        cellRenderer(params: ICellRendererParams<StorePerformanceDto>) {
          if (params.data) {
            let backgroundColor = 'unset';
            if (params.data?.storeStrengthPerPermutationProposed < params.data?.storeStrengthPerPermutationValidated)
              backgroundColor = PINK;
            if (params.data?.storeStrengthPerPermutationProposed > params.data?.storeStrengthPerPermutationValidated)
              backgroundColor = LIGHT_GREEN;

            return (
              <CellColoringHint
                titleKey="storePerformance.sp_propose_validated_title"
                hints={[
                  {
                    color: PINK,
                    description: t('storePerformance.sp_propose_lt_sp_validated'),
                  },
                  {
                    color: LIGHT_GREEN,
                    description: t('storePerformance.sp_propose_gt_sp_validated'),
                  },
                ]}
              >
                <p
                  style={{
                    fontWeight: 'bold',
                    backgroundColor: backgroundColor,
                  }}
                >
                  {params.valueFormatted ? params.valueFormatted : params.value}
                </p>
              </CellColoringHint>
            );
          }
        },
      },
      {
        field: 'storeStrengthPerPermutationImplemented',
        headerName: t(`storePerformance.store_strength_per_permutation_implemented`),
        valueFormatter: numberWithSpaceFormatter,
        headerClass: ALT_HIGHLIGHTED_HEADER_CLASS,
      },
      {
        field: 'storeStrengthPerPermutationHistory',
        headerName: t(`storePerformance.store_strength_per_permutation_history`),
        valueFormatter: numberWithSpaceFormatter,
        headerClass: ALT_HIGHLIGHTED_HEADER_CLASS,
      },
      {
        field: 'storeStrengthPerPermutationValidated',
        headerName: t(`storePerformance.store_strength_per_permutation_validated`),
        valueFormatter: numberWithSpaceFormatter,
        editable: (permissions as PermissionsEnum[])?.includes(PermissionsEnum.StorePerformanceEdit),
        valueParser: 'Number(newValue)',
        cellEditor: NumericCellEditor,
        valueSetter: (params: ValueSetterParams<StorePerformanceDto>) => {
          const valid = storeMaxStrength !== undefined && params.newValue <= storeMaxStrength;
          if (valid) {
            params.data.storeStrengthPerPermutationValidated = params.newValue;

            return true;
          }

          return false;
        },
        ...ALT_HIGHLIGHTED_COLUMN_COL_DEFS,
      },
    );

    colDefs = colDefs.map((colDef) => ({ ...colDef, headerTooltip: colDef.headerName }));

    setColumnDefs([
      ...getVisibleColDefs({ allColDefs: colDefs, getView }),
      {
        field: 'season',
        headerName: t('common.season'),
        valueFormatter: () => (seasonName ? seasonName : ''),
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
      {
        field: 'universe',
        headerName: t('common.universe'),
        valueFormatter: () => (universeName ? universeName : ''),
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
    ]);
    setAllColumnDefs(colDefs);
    setViewColsInitialized(selectedViewId);
    setPendingTableRefresh(false);
  }, [
    dataCollections?.collections,
    dataStoreCustomFields?.storeCustomFields,
    dataStorePerformance?.storePerformance,
    getView,
    loadingCollections,
    loadingStorePerformance,
    pendingTableRefresh,
    selectedViewId,
    setPendingTableRefresh,
    t,
    viewColsInitialized,
    seasonName,
    universeName,
    storeMaxStrength,
    permissions,
  ]);

  useEffect(() => {
    dataCollections && refetchDataCollections();
  }, [refetchDataCollections, dataCollections]);

  useEffect(() => {
    columnDefs?.length && !selectedViewId && gridRef?.current?.columnApi.resetColumnState();
    viewColsInitialized && applyColWidths(gridRef, getColumnsWidths);
  }, [getColumnsWidths, viewColsInitialized, selectedViewId]);

  useEffect(() => {
    const storePerformanceData = dataStorePerformance?.storePerformance;

    if (!allColumnDefs) return;

    const rows = prepareRowData(storePerformanceData, allColumnDefs) as (StorePerformanceDto & {
      permutations?: string;
      permutationsCode?: string;
    })[];

    rows.forEach((row) => {
      row.permutations = row.permutationLabel2
        ? `${row.permutationLabel1} - ${row.permutationLabel2}`
        : row.permutationLabel1!;
      row.permutationsCode = row.permutationFieldId2
        ? `${row.permutationFieldId1} - ${row.permutationFieldId2}`
        : row.permutationFieldId1!;
    });

    setGridRows(rows, setRowData, gridRef, updatedRowIdRef);
  }, [allColumnDefs, dataStorePerformance?.storePerformance]);

  const excelStyles: ExcelStyle[] = [
    {
      id: 'stringType',
      dataType: 'String',
    },
  ];

  const generateColumnsForExport = () => {
    const keys = gridRef.current?.columnApi.getAllDisplayedColumns().map((column) => column.getColId());
    keys?.unshift('season');
    keys?.unshift('universe');

    return keys;
  };

  const onExport = useCallback(() => {
    gridRef.current &&
      gridRef.current.api.exportDataAsExcel({
        columnKeys: generateColumnsForExport(),
        processCellCallback: function (params) {
          if (params.column.getColId() === 'season') {
            return seasonName;
          }
          if (params.column.getColId() === 'universe') {
            return universeCode;
          }
          if (params.column.getColId() === 'code') {
            return params.value.toString();
          }

          if (isNumber(params.value) && params?.value % 1 != 0) {
            return params.value.toFixed(1);
          }

          return params.value;
        },
        fileName: `${t('storePerformance.store_performance')}_${getFormattedCurrentDateTime()}`,
      });
  }, [universeCode, seasonName, t]);

  const onCSVExport = useCallback(() => {
    gridRef.current!.api.exportDataAsCsv();
  }, []);

  const updateValidatedStrength = useCallback(
    async (storeId: number, permutationFieldId1: string, permutationFieldId2: string, newValue: number) => {
      if (!universeId || !seasonId) return;

      const result = await updateStorePerformanceMutation({
        variables: {
          storePerformance: {
            universeId,
            seasonId,
            storeId,
            permutationFieldId1: permutationFieldId1 || null,
            permutationFieldId2: permutationFieldId2 || null,
            validatedStrength: newValue,
          },
        },
      });

      if (result.data?.updateStorePerformance?.id) {
        updatedRowIdRef.current = `${storeId}_${permutationFieldId1}_${permutationFieldId2}`;
        refetchStorePerformance();
      }
    },
    [refetchStorePerformance, seasonId, universeId, updateStorePerformanceMutation],
  );
  const onCopyModalSubmitHandler = async () => {
    if (seasonId && universeId) {
      const result = await storeReplaceValidatedWithImplantedStrengthMutation({
        variables: { universeId, seasonId, replaceNonZeroValues: replaceNonZeroValuesOnCopy },
      });
      if (result?.data?.storeReplaceValidatedWithImplantedStrength) {
        toast.success(t('storePerformance.successfully_copied'), { theme: 'colored' });
        hideModal['COPY_MODAL']();
        setIsGridReady(false);
        await refetchStorePerformance();
        setIsGridReady(true);
      } else {
        toast.warning(t('storePerformance.not_successfully_copied'), { theme: 'colored' });
        hideModal['COPY_MODAL']();
      }
    }
  };
  const handleCellEditingStopped = useCallback(
    async (e: CellEditingStoppedEvent) => {
      const { id, permutationFieldId1, permutationFieldId2 } = e.data;
      const oldValue = Number(e.oldValue);
      const newValue = Number(e.newValue);

      if (newValue === oldValue) return;

      updateValidatedStrength(id, permutationFieldId1, permutationFieldId2, newValue);
    },
    [updateValidatedStrength],
  );

  const getRowId = useMemo<GetRowIdFunc>(
    () => (params: GetRowIdParams) => {
      const { id, permutations } = params.data;

      return `${id}_${permutations}`;
    },
    [],
  );

  const showModal = useMemo(
    () => ({
      ANALYTICS_MODAL: () => setIsModalOpen((prevState) => ({ ...prevState, analyticsModal: true })),
      OPTIMAL_STOCK_MODAL: () => setIsModalOpen((prevState) => ({ ...prevState, optimalStockModal: true })),
      COPY_MODAL: () => setIsModalOpen((prevState) => ({ ...prevState, copyModal: true })),
    }),
    [],
  );

  const hideModal = useMemo(
    () => ({
      ANALYTICS_MODAL: () => setIsModalOpen((prevState) => ({ ...prevState, analyticsModal: false })),
      OPTIMAL_STOCK_MODAL: () => setIsModalOpen((prevState) => ({ ...prevState, optimalStockModal: false })),
      COPY_MODAL: () => setIsModalOpen((prevState) => ({ ...prevState, copyModal: false })),
    }),
    [],
  );
  const handleAnalyticsButtonClicked = useCallback(() => {
    showModal['ANALYTICS_MODAL']();
  }, [showModal]);

  const handleOptimalStockButtonClicked = useCallback(() => {
    showModal['OPTIMAL_STOCK_MODAL']();
  }, [showModal]);

  if (!seasonId) return <NoSeasonEmptyState />;
  if (loadingStorePerformance) return <Spinner />;

  return (
    <>
      <VerticalPageContent>
        <ActionButtonsWrapper style={{ justifyContent: 'flex-start', margin: '0 0 5px' }}>
          <Views
            flatColDefs={allColumnDefs?.filter((column) => column.field !== 'universe' && column.field !== 'season')}
          />

          <Button
            className="analytics-button"
            onClick={handleAnalyticsButtonClicked}
            size="small"
            tooltipKey="storePerformanceAnalytics.button_tooltip"
          >
            <LineChartOutlined />
          </Button>
          <Button
            style={{ padding: '0 4px', height: 'auto' }}
            onClick={handleOptimalStockButtonClicked}
            size="small"
            tooltipKey="optimalStockAndEstimatedPicking.optimal_stock_and_estimated_picking_modal_title"
          >
            <img width={20} alt="Simulation picking img" src={SimulationPickingImg} />
          </Button>
          <Button onClick={showModal['COPY_MODAL']} size="small" tooltipKey="storePerformance.copy_values">
            <CopyOutlined style={{ fontSize: 16 }} />
          </Button>

          <ClearFiltersButton gridRefs={gridRef} hasFilters={hasFilters} />
          {getCurrentAndWeekBefore()}
          <div style={{ marginLeft: 'auto' }}>
            <ImportExportXlsxComponent
              exportClickHandler={onExport}
              refetchData={async () => {
                setRefetchLoading(true);
                await refetchStorePerformance({ seasonId, universeId });
                await gridRef.current?.api.redrawRows();
                setRefetchLoading(false);
              }}
            />
          </div>
        </ActionButtonsWrapper>

        {refetchLoading ? (
          <Spinner />
        ) : (
          !errorStorePerformance && (
            <ConciseGridContainer isGridReady={isGridReady}>
              <AgGridReact
                {...GRID_DEFAULT_PROPS}
                headerHeight={45}
                ref={gridRef}
                defaultColDef={defaultColDef}
                columnDefs={columnDefs}
                rowData={rowData}
                onGridReady={() => {
                  isEmpty(dataStorePerformance?.storePerformance) && setIsGridReady(true);
                }}
                onFirstDataRendered={() => setIsGridReady(true)}
                onCellEditingStopped={handleCellEditingStopped}
                getRowId={getRowId}
                onSortChanged={(e) => handleSortChanged(null, e)}
                onColumnMoved={handleColumnMoved}
                onColumnResized={handleColumnResized}
                onColumnPinned={handleColumnPinned}
                onFilterChanged={(e) => {
                  setHasFilters(hasFiltersChanged(e));
                  handleFilterChanged();
                }}
                enterMovesDownAfterEdit={true}
                getContextMenuItems={() => getContextMenu(onExport, onCSVExport)}
                excelStyles={excelStyles}
              />
            </ConciseGridContainer>
          )
        )}
      </VerticalPageContent>

      <StorePerformanceAnalyticsModal isOpen={isModalOpen.analyticsModal} handleCancel={hideModal['ANALYTICS_MODAL']} />
      <OptimalStockAndEstimatedPickingModal
        isOpen={isModalOpen.optimalStockModal}
        handleCancel={hideModal['OPTIMAL_STOCK_MODAL']}
      />
      <ConfirmModal
        icon={null}
        onCancel={hideModal['COPY_MODAL']}
        customTitleKey="storePerformance.copy_values"
        onOk={onCopyModalSubmitHandler}
        open={isModalOpen.copyModal}
        customElement={
          <Checkbox
            value={replaceNonZeroValuesOnCopy}
            onChange={(e) => setReplaceNonZeroValuesOnCopy(e.target.checked)}
            textKey="storePerformance.replace_non_zero_values"
          />
        }
      />
    </>
  );
};

export default StorePerformanceTable;
