import React, { useCallback, useEffect, useRef, useState } from 'react';

import { cloneDeep } from 'lodash';

import { SELECTED_VIEW_ID_ITEM_PREFIX, VIEWS_ITEM_PREFIX } from 'constants/tableViews';
import { arrayItemMove } from 'helpers/array';
import { useGetUserSettingsQuery, useUpdateUserSettingsMutation } from 'services/graphql/main';
import { useError } from 'services/utils';
import { UserSettings } from 'types/common';
import {
  ClearSelectedViewId,
  ColumnsWidths,
  ContextProviderType,
  DeleteView,
  GetColumnsWidths,
  GetCurrentViewCols,
  GetGridCols,
  GetView,
  GetViewByName,
  MoveColumn,
  PinColumn,
  SetColumnWidth,
  TableViewsContextType,
  UpsertView,
  View,
  ViewId,
} from 'types/views';

export const TableViewsContext = React.createContext<TableViewsContextType>({} as TableViewsContextType);

export const TableViewsContextProvider: React.FC<ContextProviderType> = ({ children, tableName }) => {
  const { addError } = useError();

  const { data: dataUserSettings, refetch: refetchUserSettings } = useGetUserSettingsQuery({
    onError: (err) => addError(err, 'warning'),
  });

  const [updateUserSettingsMutation] = useUpdateUserSettingsMutation({
    onError: (err) => addError(err, 'warning'),
  });

  const userSettingsData = dataUserSettings?.userSettings.userSettings;
  const tableViewsKey = `${VIEWS_ITEM_PREFIX}${tableName}`;
  const tableSelectedViewIdKey = `${SELECTED_VIEW_ID_ITEM_PREFIX}${tableName}`;

  const [userSettings, setUserSettings] = useState<UserSettings>(null);

  const [views, setViews] = useState<View[]>([]);
  const [selectedViewId, setSelectedViewId] = useState<ViewId | null>(null);
  const [pendingTableRefresh, setPendingTableRefresh] = useState(false);

  const dataLoaded = useRef(false);
  const skipUpdateMutation = useRef(false);

  useEffect(() => {
    if (!userSettingsData) {
      setUserSettings({});

      return;
    }
    if (dataLoaded.current) return;

    skipUpdateMutation.current = true;
    dataLoaded.current = true;

    const settings = JSON.parse(userSettingsData!) || {};
    setUserSettings(settings);

    settings[tableSelectedViewIdKey] && setSelectedViewId(settings[tableSelectedViewIdKey]);
    settings[tableViewsKey] && setViews(settings[tableViewsKey]);
  }, [tableSelectedViewIdKey, tableViewsKey, userSettingsData]);

  useEffect(() => {
    if (userSettingsData === undefined || userSettings === null) {
      return;
    }

    if (skipUpdateMutation.current) {
      skipUpdateMutation.current = false;

      return;
    }

    const newSettings = JSON.stringify(userSettings);

    if (newSettings !== userSettingsData) {
      const updateData = async () => {
        await updateUserSettingsMutation({ variables: { userSettings: newSettings } });
        await refetchUserSettings();
      };

      updateData();
    }
  }, [refetchUserSettings, updateUserSettingsMutation, userSettings]);

  useEffect(() => {
    if (dataLoaded.current) {
      setUserSettings((prevState) => ({ ...prevState, [tableSelectedViewIdKey]: selectedViewId }));
    }
  }, [selectedViewId, tableSelectedViewIdKey]);

  useEffect(() => {
    if (dataLoaded.current) {
      setUserSettings((prevState) => ({ ...prevState, [tableViewsKey]: views }));
    }
  }, [tableViewsKey, views]);

  const getViewByName: GetViewByName = useCallback(
    (viewName) => {
      return views.find((view) => view.name === viewName) || null;
    },
    [views],
  );

  const getView: GetView = useCallback(
    (viewId) => {
      const id = viewId || selectedViewId;

      return views.find((view) => view.id === id) || null;
    },
    [selectedViewId, views],
  );

  const getCurrentViewCols: GetCurrentViewCols = useCallback(() => {
    return getView()?.cols || null;
  }, [getView]);

  const getGridCols: GetGridCols = useCallback(
    (gridName) => {
      const allCols = getCurrentViewCols();

      return allCols?.filter((col) => col.parentGrid === gridName) || null;
    },
    [getCurrentViewCols],
  );

  const upsertView: UpsertView = useCallback(
    (newView) => {
      const viewsCopy = cloneDeep(views);
      const viewIndex = viewsCopy.findIndex((view) => view.id === newView.id);

      if (viewIndex === -1) {
        setViews([...viewsCopy, newView]);
      } else {
        const mergedView = Object.assign(viewsCopy[viewIndex], newView);
        viewsCopy[viewIndex] = mergedView;
        setViews(viewsCopy);
      }
    },
    [views],
  );

  const deleteView: DeleteView = useCallback(
    (viewId) => {
      const viewsCopy = cloneDeep(views);

      setViews(viewsCopy.filter((view) => view.id !== viewId));
    },
    [views],
  );

  const clearSelectedViewId: ClearSelectedViewId = useCallback(() => {
    setSelectedViewId('');
  }, []);

  const setColumnWidth: SetColumnWidth = useCallback(
    (colId, width) => {
      if (!colId || !width) return;

      const view = getView();
      if (!view) return;

      const column = view.cols.find((item) => item.field === colId);
      column!.width = width;

      upsertView(view);
    },
    [getView, upsertView],
  );

  const getColumnsWidths: GetColumnsWidths = useCallback(() => {
    const view = getView();
    if (!view) return null;

    const colWidths = view.cols.reduce<ColumnsWidths>((widths, item) => {
      if (item.width) {
        widths.push({ key: item.field, newWidth: item.width });
      }

      return widths;
    }, []);

    return colWidths;
  }, [getView]);

  const moveColumn: MoveColumn = (fieldName, toIndex) => {
    const view = getView();
    if (!view || !fieldName || typeof toIndex !== 'number') return;

    const currentColIndex = view.cols.findIndex((col) => col.field === fieldName);
    if (currentColIndex > -1) {
      arrayItemMove(view.cols, currentColIndex, toIndex);
    }

    upsertView(view);
  };

  const pinColumn: PinColumn = (fieldName, toPosition) => {
    const view = getView();
    if (!view || !fieldName) return;

    const newViewColumns = view.cols.map((col) => (col.field === fieldName ? { ...col, pinned: toPosition } : col));
    upsertView({ ...view, cols: newViewColumns });
  };

  return (
    <TableViewsContext.Provider
      value={{
        clearSelectedViewId,
        deleteView,
        getColumnsWidths,
        getView,
        getGridCols,
        getViewByName,
        getCurrentViewCols,
        moveColumn,
        pinColumn,
        pendingTableRefresh,
        selectedViewId,
        setColumnWidth,
        setPendingTableRefresh,
        setSelectedViewId,
        upsertView,
        views,
      }}
    >
      {children}
    </TableViewsContext.Provider>
  );
};
