import React from 'react';

import { useTranslation } from 'react-i18next';

import StyledModal from '../StyledModal/StyledModal';
import { AnalyticsModalProps } from './common/types';
import SeasonGoalsSummary from './SeasonGoalsSummary';
import StorePerformanceSummary from './StorePerformanceSummary';
import { SectionTitle } from './styles';

const StoreFleetAnalyticsModal: React.FC<AnalyticsModalProps> = ({ isOpen, handleCancel }) => {
  const { t } = useTranslation('translation');

  return (
    <StyledModal
      title={t('storeFleetSummary.store_fleet_summary_title')}
      open={isOpen}
      onCancel={handleCancel}
      footer={null}
      width="70%"
      centered={true}
    >
      <SectionTitle>{t('storeFleetSummary.store_fleet_summary_performance')}</SectionTitle>
      <StorePerformanceSummary />
      <SectionTitle>{t('seasonGoalSummary.this_season_goals')}</SectionTitle>
      <SeasonGoalsSummary />
    </StyledModal>
  );
};

export default StoreFleetAnalyticsModal;
