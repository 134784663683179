import { FilterChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

import { BasicGridRows } from 'types/gridRows';

export const shouldSuppressRowVirtualisation = (rows: BasicGridRows) => {
  return rows ? rows.length < 150 : false;
};

export const clearFilters = (gridRef: React.RefObject<AgGridReact>) => {
  gridRef?.current?.api?.setFilterModel({});
};

export const hasFiltersChanged = (e: FilterChangedEvent) => {
  return Object.keys(e?.api?.getFilterModel() || {}).length > 0;
};
