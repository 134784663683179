import { KeyboardEvent } from 'react';

import { KEYS } from 'constants/keyboard';

const { KEY_BACKSPACE, KEY_DELETE, KEY_ENTER, KEY_TAB } = KEYS;

export const isLeftOrRight = (event: KeyboardEvent) => {
  return ['ArrowLeft', 'ArrowRight'].indexOf(event.key) > -1;
};

export const isCharNumeric = (charStr: string) => {
  return !!/\d/.test(charStr);
};

export const isKeyPressedNumeric = (event: KeyboardEvent) => {
  const charStr = event.key;

  return isCharNumeric(charStr);
};
export const isKeyPressedNumericOrDecimal = (event: KeyboardEvent) => {
  const charStr = event.key;

  return isCharNumeric(charStr) || charStr === '.';
};

export const deleteOrBackspace = (event: KeyboardEvent) => {
  return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.key) > -1;
};

export const finishedEditingPressed = (event: KeyboardEvent) => {
  const key = event.key;

  return key === KEY_ENTER || key === KEY_TAB;
};

export const getSeparators = (locale: string) => {
  const numberWithGroupAndDecimalSeparator = 1000.1;
  const numberFormattedToParts = Intl.NumberFormat('en-IN').formatToParts(numberWithGroupAndDecimalSeparator);

  return {
    decimal: numberFormattedToParts.find((part) => part.type === 'decimal')?.value,
    group: numberFormattedToParts.find((part) => part.type === 'group')?.value,
  };
};

export const isAllowedNumericFieldKey = (locale: string, event: KeyboardEvent) => {
  const { key } = event;
  const allowedKeys = [KEY_BACKSPACE, KEY_DELETE, KEY_ENTER, KEY_TAB];

  return isCharNumeric(key) || allowedKeys.includes(key) || isLeftOrRight(event) || deleteOrBackspace(event);
};

export const isAllowedDecimalFieldKey = (locale: string, event: KeyboardEvent) => {
  const { key } = event;
  const allowedKeys = [KEY_BACKSPACE, KEY_DELETE, KEY_ENTER, KEY_TAB];
  const separators = getSeparators(locale);

  //@ts-ignore
  if ('value' in event.target && key === separators.decimal && event.target.value.includes(key)) {
    return false;
  }

  return (
    isCharNumeric(key) ||
    allowedKeys.includes(key) ||
    separators.decimal === key ||
    isLeftOrRight(event) ||
    deleteOrBackspace(event)
  );
};
