import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { CellEditingStoppedEvent, ColDef, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Alert, InputNumber, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ProductContext, PermissionsContext, StoreManagementContext } from 'context';
import { cloneDeep, isEmpty, isNumber } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';
import { isAllowedDecimalFieldKey, isAllowedNumericFieldKey } from 'utils/validation';

import { NumericCellEditor } from 'components/AgGridComponents';
import {
  numberWithSpaceFormatter,
  numberWithSpaceHideZeroFormatter,
} from 'components/AgGridComponents/valueFormatters';
import { Checkbox, Spinner } from 'components/UI';
import {
  CENTERED_COL_DEF,
  COLUMN_TYPES,
  DISABLED_CELL_CONTENT_CLASS,
  EDITABLE_CELL_CONTENT_CLASS,
} from 'constants/gridConstants';
import {
  COMMON_DEFAULT_COL_DEFS,
  HAS_CONDITION_FIELD,
  HAS_STOCK_MAX_FIELD,
  HAS_STOCK_MIN_FIELD,
  MAX_CONDITION_FIELD,
  MAX_CONDITION_FIELD_VALUE,
  MIN_CONDITION_FIELD,
  MIN_CONDITION_FIELD_VALUE,
  NUMBER_OF_STORES_FIELD,
  ROW_TYPE_CUSTOM_FIELD,
  STOCK_MAX_FIELD,
  STOCK_MIN_FIELD,
  STORE_STRENGTH_FIELD,
  TOTAL_FIELD,
} from 'screens/ProductDetails/common/constants/optimalStockCalculationTable';
import { useProductIdParam } from 'screens/ProductDetails/common/hooks';
import { useOverrideFocusOnFirstSizeCell } from 'screens/ProductDetails/common/hooks/useOverrideFocusOnFirstSizeCell';
import {
  ArticleOptimalStockOverride,
  ProductOptimalStockMetadata,
  useUpdateBulkOptimalStockOverrideFlagsMutation,
  useUpdateOptimalStockOverrideMutation,
  useUpdateProductShowcaseMutation,
  useUpdateProductValidatedStrengthMutation,
  PermissionsEnum,
  useGetExceptionOptimalStockTableLazyQuery,
  ExceptionOptimalStockSalesEnum,
  ExceptionOptimalStockStockEnum,
  ProductOptimalStockTableWrapperDto,
} from 'services/graphql/main';
import { getCacheVal, useError } from 'services/utils';

import { GridOverlay, StyledDescriptions, StyledGridsWrapper } from './styles';
import { OverrideFlags, TableRow } from './types';

const getFirstExistingOverride: (overrides: (OverrideFlags | null)[] | null | undefined) => OverrideFlags = (
  overrides,
) => {
  return (
    (overrides &&
      overrides.length > 0 &&
      overrides.find((override) => override && Object.keys(override).length > 0)) || {
      hasStockMin: false,
      hasStockMax: false,
      hasCondition: false,
      stockMaxStoreStrengthCondition: 0,
      stockMinStoreStrengthCondition: 0,
    }
  );
};

const columnWidths = {
  [STORE_STRENGTH_FIELD]: 150,
  [NUMBER_OF_STORES_FIELD]: 150,
  dynamic: 100,
};

const storeStrengthColumnIndex = 0;

const doNotFilterMeText = '_dummyDoNotFilterMeText_';

const { Item } = StyledDescriptions;

type Props = {
  isProductArchived: boolean;
};

const IdealStockCalculationTable: React.FC<Props> = ({ isProductArchived }) => {
  const { t, i18n } = useTranslation('translation');
  const { addError } = useError();
  const { productId } = useProductIdParam();
  const { sizeSystemRowId, selectedProductModuleId, visibleSizeSystemsColsIds } = useContext(ProductContext);
  const { permissions } = useContext(PermissionsContext);
  const {
    getStoreStrengthTableLazy,
    storeStrengthTableData,
    loadingStoreStrengthTable,
    errorStoreStrengthTable,
    refetchStoreStrengthTable,
    refetchExceptionOptimalStockTable,
  } = useContext(StoreManagementContext);
  const [, lsSetFilterState] = useLocalStorage<{
    sales?: string;
    stocks?: string;
  }>('exceptionsFilters', {});

  const refetchData = useCallback(async () => {
    if (!productId) return;

    await refetchStoreStrengthTable();

    await refetchExceptionOptimalStockTable({
      productId,
      salesEnum: getCacheVal('exceptionsFilters')?.sales || ExceptionOptimalStockSalesEnum.SeasonTotal,
      stockEnum: getCacheVal('exceptionsFilters')?.stocks || ExceptionOptimalStockStockEnum.OptimalStock,
      hasException: false,
    });
  }, [refetchExceptionOptimalStockTable, refetchStoreStrengthTable, productId]);

  const [updateBulkOptimalStockOverrideFlagsMutation] = useUpdateBulkOptimalStockOverrideFlagsMutation({
    onError: (err) => addError(err, 'warning'),
  });
  const [updateBulkOptimalStockOverrideMutation] = useUpdateOptimalStockOverrideMutation({
    onError: (err) => addError(err, 'warning'),
  });

  const [updateProductShowcaseMutation] = useUpdateProductShowcaseMutation({
    onError: (err) => addError(err, 'warning'),
  });
  const [updateProductValidatedStrength] = useUpdateProductValidatedStrengthMutation({
    onError: (err) => addError(err, 'warning'),
  });

  const overridesGridRef = useRef<AgGridReact>(null);
  const gridsRenderedRef = useRef(0);
  const dynamicColDefsRef = useRef<ColDef[]>([]);
  const strengthValidationLatestValueRef = useRef<number | null>(null);
  const maxConditionValueRef = useRef<number | null>(null);
  const minConditionValueRef = useRef<number | null>(null);
  const focusedConditionFieldRef = useRef<string | null>(null);
  const firstExistingOverrideRef = useRef<OverrideFlags | null>(null);
  const validatedStrengthInputRef = useRef<HTMLInputElement>(null);

  const [metadata, setMetadata] = useState<ProductOptimalStockMetadata | null>(null);
  const [mainTableColumnDefs, setMainTableColumnDefs] = useState<ColDef[] | null>(null);
  const [mainTableRowData, setMainTableRowData] = useState<TableRow[] | null>(null);
  const [overridesColumnDefs, setOverridesColumnDefs] = useState<ColDef[] | null>(null);
  const [summaryColumnDefs, setSummaryColumnDefs] = useState<ColDef[] | null>(null);
  const [overridesRowData, setOverridesRowData] = useState<TableRow[] | null>(null);
  const [summaryRowData, setSummaryRowData] = useState<TableRow[] | null>(null);
  const [hasStockMin, setHasStockMin] = useState<boolean | null>(null);
  const [hasStockMax, setHasStockMax] = useState<boolean | null>(null);
  const [hasStockCondition, setHasStockCondition] = useState<boolean | null>(null);
  const [areAllGridsRendered, setAreAllGridsRendered] = useState(false);
  const [validatedStrength, setValidatedStrength] = useState<null | number>(null);
  const [isOverridesUpdateInProgress, setIsOverridesUpdateInProgress] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  useOverrideFocusOnFirstSizeCell({
    overridesGridRef,
    overridesColumnDefs,
    isOverridesUpdateInProgress,
    hasStockMin,
    hasStockMax,
  });

  const updateValidatedStrength = useCallback(async () => {
    if (validatedStrength === null) return;

    const result = await updateProductValidatedStrength({
      variables: {
        productId,
        validatedProductStrength: validatedStrength,
      },
    });

    if (result.data?.updateProduct.id) {
      await refetchData();
      validatedStrengthInputRef.current?.blur();
    }
  }, [validatedStrength, validatedStrengthInputRef]);

  const updateOverride = useCallback(
    async (field: string, colId: number, value: number) => {
      if (isNaN(value)) return;

      setIsOverridesUpdateInProgress(true);
      await updateBulkOptimalStockOverrideMutation({
        variables: {
          stockOverride: {
            id: colId,
            [field]: value,
          },
        },
      }).then(async () => {
        setIsOverridesUpdateInProgress(false);
        await refetchData();
      });
    },
    [refetchData, updateBulkOptimalStockOverrideMutation],
  );

  const updateOverrideFlag = useCallback(
    async (field: string, value: number | boolean) => {
      const originalValue = firstExistingOverrideRef.current?.[field as keyof OverrideFlags];
      if (value === null || value === undefined || value === originalValue) return;

      setIsOverridesUpdateInProgress(true);

      await updateBulkOptimalStockOverrideFlagsMutation({
        variables: {
          stockOverrideBulk: {
            productId,
            [field]: value,
          },
        },
      }).then(async () => {
        setIsOverridesUpdateInProgress(false);
        await refetchData();
      });
    },
    [productId, refetchData, updateBulkOptimalStockOverrideFlagsMutation],
  );

  // data get
  const currentProductIdRef = useRef<number | null | undefined>(null);

  useEffect(() => {
    if (!sizeSystemRowId || !selectedProductModuleId || !productId) return;

    const loadData = async () => {
      setPageLoading(true);

      if (currentProductIdRef.current) {
        await refetchStoreStrengthTable({ productId });
      } else {
        await getStoreStrengthTableLazy({
          variables: { productId },
          fetchPolicy: 'cache-and-network',
          nextFetchPolicy: 'cache-first',
        });

        currentProductIdRef.current = productId;
      }

      setPageLoading(false);
    };

    loadData();
  }, [getStoreStrengthTableLazy, productId, refetchStoreStrengthTable, selectedProductModuleId, sizeSystemRowId]);

  useEffect(() => {
    if (errorStoreStrengthTable || !storeStrengthTableData || !sizeSystemRowId) return;

    const { metadata: rawMetadata } = storeStrengthTableData as ProductOptimalStockTableWrapperDto;

    rawMetadata && setMetadata(rawMetadata);
  }, [storeStrengthTableData, storeStrengthTableData?.metadata, errorStoreStrengthTable, sizeSystemRowId]);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      ...CENTERED_COL_DEF,
      ...COMMON_DEFAULT_COL_DEFS,
      suppressMovable: true,
    };
  }, []);

  // main table coldefs
  useEffect(() => {
    if (errorStoreStrengthTable || !storeStrengthTableData || !sizeSystemRowId) return;

    const { headers, metadata: rawMetadata } = storeStrengthTableData as ProductOptimalStockTableWrapperDto;

    if (!headers) return;

    let mainColDefs: ColDef[] = [
      {
        field: STORE_STRENGTH_FIELD,
        headerName: t('productOptimalStock.store_strength'),
        minWidth: columnWidths[STORE_STRENGTH_FIELD],
        maxWidth: columnWidths[STORE_STRENGTH_FIELD],
        valueFormatter: numberWithSpaceFormatter,
        pinned: 'left',
      },
      {
        field: NUMBER_OF_STORES_FIELD,
        headerName: `${t('productOptimalStock.number_of_stores_header')} (${rawMetadata?.numberOfStores})`,
        minWidth: columnWidths[NUMBER_OF_STORES_FIELD],
        maxWidth: columnWidths[NUMBER_OF_STORES_FIELD],
        valueFormatter: numberWithSpaceFormatter,
        pinned: 'left',
      },
    ];

    const dynamicColumns = headers.filter((header) => !!header?.label && !!header?.optimalStockOverrideId);
    const dynamicColumnsDefs: ColDef[] = dynamicColumns.map((column) => {
      const label = column?.label || undefined;
      const id = column?.optimalStockOverrideId ? String(column.optimalStockOverrideId) : undefined;

      return {
        field: label,
        headerName: label,
        colId: id,
        hide: !visibleSizeSystemsColsIds.includes(column?.id || 0),
        valueFormatter: numberWithSpaceHideZeroFormatter,
        valueGetter(params) {
          return !label ? undefined : params.data[label];
        },
      };
    });

    const totalColDef: ColDef = {
      field: TOTAL_FIELD,
      valueFormatter: numberWithSpaceHideZeroFormatter,
      headerName: t('productOptimalStock.total_caps'),
    };

    mainColDefs = [...mainColDefs, ...dynamicColumnsDefs, totalColDef, { minWidth: 100, suppressNavigable: true }];

    setMainTableColumnDefs(mainColDefs);

    dynamicColDefsRef.current = dynamicColumnsDefs;
  }, [errorStoreStrengthTable, productId, sizeSystemRowId, storeStrengthTableData, t, visibleSizeSystemsColsIds]);

  // main table rows
  useEffect(() => {
    if (errorStoreStrengthTable || !storeStrengthTableData || !mainTableColumnDefs?.length || !sizeSystemRowId) return;

    const { rows: rawRows, headers } = storeStrengthTableData as ProductOptimalStockTableWrapperDto;
    if (!rawRows) return;

    const correctIndexes = headers
      ?.map((x, i) => ({ index: i - 2, ...x }))
      .filter((x) => x.optimalStockOverrideId)
      .map((x) => x.index);
    const correctRawRows = rawRows.map((x) => ({
      ...x,
      optimalStock: x?.optimalStock?.filter((y, i) => correctIndexes?.includes(i)),
    }));

    const rows: TableRow[] = correctRawRows.map((row) => {
      const firstDynamicColDefIndex = 2;
      const dynamicRowCells = row?.optimalStock?.reduce<{ [key: string]: number }>((cells, cellValue, index) => {
        const currentColumnIndex = firstDynamicColDefIndex + index;
        const currentColumnName = mainTableColumnDefs[currentColumnIndex]?.field;

        if (currentColumnName && typeof cellValue === 'number') {
          cells[currentColumnName] = cellValue;
        }

        return cells;
      }, {});

      return (
        row && {
          [ROW_TYPE_CUSTOM_FIELD]: 'strength',
          [STORE_STRENGTH_FIELD]: row[STORE_STRENGTH_FIELD],
          [NUMBER_OF_STORES_FIELD]: row[NUMBER_OF_STORES_FIELD],
          ...dynamicRowCells,
          [TOTAL_FIELD]: row[TOTAL_FIELD],
        }
      );
    });

    setMainTableRowData(rows);
  }, [storeStrengthTableData, errorStoreStrengthTable, mainTableColumnDefs, sizeSystemRowId]);

  // overrides coldefs
  useEffect(() => {
    if (!storeStrengthTableData || !sizeSystemRowId || !selectedProductModuleId) return;

    firstExistingOverrideRef.current = getFirstExistingOverride(storeStrengthTableData.overrides);
  }, [selectedProductModuleId, sizeSystemRowId, storeStrengthTableData]);

  useEffect(() => {
    if (!mainTableColumnDefs || !storeStrengthTableData || !sizeSystemRowId) return;

    if (!firstExistingOverrideRef.current)
      firstExistingOverrideRef.current = getFirstExistingOverride(storeStrengthTableData.overrides);

    const totalColumnIndex = mainTableColumnDefs.length - 1;
    const colDefs = cloneDeep(mainTableColumnDefs);

    colDefs[storeStrengthColumnIndex].colSpan = () => 2;
    colDefs[storeStrengthColumnIndex].cellRenderer = (params: ICellRendererParams) => {
      const value = params.valueFormatted ? params.valueFormatted : params.value;

      return <span className="overridesNameCell rowNameCell">{value}</span>;
    };
    colDefs[storeStrengthColumnIndex].suppressKeyboardEvent = (params) => {
      const { key } = params.event;
      const rowId = params.data.id;

      return rowId === focusedConditionFieldRef.current && (key === 'ArrowLeft' || key === 'ArrowRight');
    };
    colDefs[storeStrengthColumnIndex].getQuickFilterText = (params) => {
      const { id } = params.data;
      const isHidableRow = id === MAX_CONDITION_FIELD || id === MIN_CONDITION_FIELD;
      const hasCondition = hasStockCondition ?? firstExistingOverrideRef.current?.hasCondition;

      return !isHidableRow || hasCondition ? doNotFilterMeText : '';
    };

    colDefs.forEach((colDef, index) => {
      if (index > 1 && index < totalColumnIndex) {
        colDef.valueParser = 'Number(newValue)';
        colDef.cellEditor = NumericCellEditor;
        colDef.valueFormatter = undefined;
      }
    });

    const isEditable = (colDef: ColDef, rowId: string) => {
      if (!colDef.colId) return false;
      if (
        !(permissions as PermissionsEnum[])?.includes(PermissionsEnum.ProductPerformanceDetailsEdit) ||
        isProductArchived
      )
        return false;

      let isEditable = false;

      switch (rowId) {
        case STOCK_MIN_FIELD:
          if (hasStockMin ?? firstExistingOverrideRef.current?.hasStockMin) isEditable = true;
          break;
        case STOCK_MAX_FIELD:
          if (hasStockMax ?? firstExistingOverrideRef.current?.hasStockMax) isEditable = true;
          break;
        case MIN_CONDITION_FIELD:
          isEditable = true;
          break;
        case MAX_CONDITION_FIELD:
          isEditable = true;
          break;
      }

      return isEditable;
    };

    colDefs!.forEach((colDef, index) => {
      const totalColumnIndex = colDefs!.length - 1;

      if (index > 1 && index < totalColumnIndex) {
        colDef.cellRenderer = (params: ICellRendererParams) => {
          const value = params.valueFormatted ? params.valueFormatted : params.value;
          const { id } = params.data;

          return (
            <span className={isEditable(colDef, id) ? EDITABLE_CELL_CONTENT_CLASS : DISABLED_CELL_CONTENT_CLASS}>
              {value}
            </span>
          );
        };

        colDef.editable = (params) => {
          return isEditable(colDef, params.data.id);
        };
      }
    });

    if (overridesColumnDefs) {
      overridesGridRef.current?.api?.setColumnDefs(colDefs);
    } else {
      setOverridesColumnDefs(colDefs);
    }
  }, [
    storeStrengthTableData,
    hasStockCondition,
    hasStockMax,
    hasStockMin,
    mainTableColumnDefs,
    sizeSystemRowId,
    overridesColumnDefs,
    permissions,
    isProductArchived,
  ]);

  // overrides rows
  useEffect(() => {
    if (!storeStrengthTableData || !dynamicColDefsRef.current || !sizeSystemRowId) return;

    const { overrides, rows } = storeStrengthTableData as ProductOptimalStockTableWrapperDto;

    if (!overrides || isEmpty(rows)) return;

    if (!firstExistingOverrideRef.current)
      firstExistingOverrideRef.current = getFirstExistingOverride(storeStrengthTableData.overrides);

    const overrideRows: TableRow[] = [
      { id: STOCK_MIN_FIELD },
      { id: STOCK_MAX_FIELD },
      { id: HAS_CONDITION_FIELD },
      { id: MIN_CONDITION_FIELD },
      { id: MAX_CONDITION_FIELD },
    ];

    const correctOverrides = overrides.filter((x) => x !== null);

    correctOverrides.forEach((column, index) => {
      if (column) {
        const currentDynamicColumnName = dynamicColDefsRef.current[index]?.field;
        if (typeof currentDynamicColumnName === 'string') {
          overrideRows[0]![currentDynamicColumnName] = column[STOCK_MIN_FIELD];
          overrideRows[1]![currentDynamicColumnName] = column[STOCK_MAX_FIELD];
          overrideRows[2]![currentDynamicColumnName] = '';
          overrideRows[3]![currentDynamicColumnName] = column[MIN_CONDITION_FIELD_VALUE];
          overrideRows[4]![currentDynamicColumnName] = column[MAX_CONDITION_FIELD_VALUE];
        }
      }
    });

    overrideRows![0]![STORE_STRENGTH_FIELD] = (
      <>
        <span>{t('productOptimalStock.stock_min')}</span>

        <div className="controlWrapper">
          <Checkbox
            checked={!!(hasStockMin ?? firstExistingOverrideRef.current?.hasStockMin)}
            disabled={
              isOverridesUpdateInProgress ||
              !(permissions as PermissionsEnum[])?.includes(PermissionsEnum.ProductPerformanceDetailsEdit) ||
              isProductArchived
            }
            onChange={(e: CheckboxChangeEvent) => {
              const checked = e.target.checked;
              setHasStockMin(checked);
              updateOverrideFlag(HAS_STOCK_MIN_FIELD, checked);
            }}
          />
        </div>
      </>
    );
    overrideRows![1]![STORE_STRENGTH_FIELD] = (
      <>
        <span>{t('productOptimalStock.stock_max')}</span>

        <div className="controlWrapper">
          <Checkbox
            checked={!!(hasStockMax ?? firstExistingOverrideRef.current?.hasStockMax)}
            disabled={
              isOverridesUpdateInProgress ||
              !(permissions as PermissionsEnum[])?.includes(PermissionsEnum.ProductPerformanceDetailsEdit) ||
              isProductArchived
            }
            onChange={(e: CheckboxChangeEvent) => {
              const checked = e.target.checked;
              setHasStockMax(checked);
              updateOverrideFlag(HAS_STOCK_MAX_FIELD, checked);
            }}
          />
        </div>
      </>
    );
    overrideRows![2]![STORE_STRENGTH_FIELD] = (
      <>
        <span>{t('productOptimalStock.stock_min_max_conditional')}</span>

        <div className="controlWrapper">
          <Checkbox
            checked={!!(hasStockCondition ?? firstExistingOverrideRef.current?.hasCondition)}
            disabled={
              isOverridesUpdateInProgress ||
              !(permissions as PermissionsEnum[])?.includes(PermissionsEnum.ProductPerformanceDetailsEdit) ||
              isProductArchived
            }
            onChange={(e: CheckboxChangeEvent) => {
              const checked = e.target.checked;
              setHasStockCondition(checked);
              updateOverrideFlag(HAS_CONDITION_FIELD, checked);
            }}
          />
        </div>
      </>
    );
    overrideRows![3]![STORE_STRENGTH_FIELD] = (
      <>
        <span>{t('productOptimalStock.stock_min_pm')}</span>

        <div className="controlWrapper">
          <InputNumber
            id={MIN_CONDITION_FIELD}
            min={0}
            step={0}
            defaultValue={
              minConditionValueRef.current ?? (firstExistingOverrideRef.current?.stockMinStoreStrengthCondition || 0)
            }
            onChange={(value) => {
              minConditionValueRef.current = value;
            }}
            onKeyDown={(e) => {
              const value = minConditionValueRef.current;

              if (e.key === 'Enter' && typeof value === 'number') {
                updateOverrideFlag(MIN_CONDITION_FIELD, value);
              } else {
                !isAllowedNumericFieldKey(i18n.language, e) && e.preventDefault();
              }
            }}
            onFocus={() => {
              focusedConditionFieldRef.current = MIN_CONDITION_FIELD;
            }}
            onBlur={() => {
              const value = minConditionValueRef.current;
              if (typeof value === 'number') {
                updateOverrideFlag(MIN_CONDITION_FIELD, value);
              }

              focusedConditionFieldRef.current = null;
            }}
            parser={(value) => Number(value)}
            size="small"
          />
        </div>
      </>
    );
    overrideRows![4]![STORE_STRENGTH_FIELD] = (
      <>
        <span>{t('productOptimalStock.stock_max_pm')}</span>

        <div className="controlWrapper">
          <InputNumber
            id={MAX_CONDITION_FIELD}
            min={0}
            step={0}
            defaultValue={
              maxConditionValueRef.current ?? (firstExistingOverrideRef.current?.stockMaxStoreStrengthCondition || 0)
            }
            onChange={(value) => {
              maxConditionValueRef.current = value;
            }}
            onKeyDown={(e) => {
              const value = maxConditionValueRef.current;

              if (e.key === 'Enter' && typeof value === 'number') {
                updateOverrideFlag(MAX_CONDITION_FIELD, value);
              } else {
                !isAllowedNumericFieldKey(i18n.language, e) && e.preventDefault();
              }
            }}
            onFocus={() => {
              focusedConditionFieldRef.current = MAX_CONDITION_FIELD;
            }}
            onBlur={() => {
              const value = maxConditionValueRef.current;
              if (typeof value === 'number') {
                updateOverrideFlag(MAX_CONDITION_FIELD, value);
              }

              focusedConditionFieldRef.current = null;
            }}
            parser={(value) => Number(value)}
            size="small"
          />
        </div>
      </>
    );

    setOverridesRowData(overrideRows);

    if (focusedConditionFieldRef.current) {
      setTimeout(() => {
        document.getElementById(focusedConditionFieldRef.current!)?.focus();
      }, 10);
    }
  }, [
    hasStockCondition,
    hasStockMax,
    hasStockMin,
    i18n.language,
    isOverridesUpdateInProgress,
    sizeSystemRowId,
    storeStrengthTableData,
    t,
    updateOverrideFlag,
    permissions,
    isProductArchived,
    productId,
  ]);

  // summary coldefs
  useEffect(() => {
    if (!mainTableColumnDefs || !storeStrengthTableData || !sizeSystemRowId) return;

    const summaryColDefs = cloneDeep(mainTableColumnDefs);

    summaryColDefs[storeStrengthColumnIndex] = {
      ...summaryColDefs[storeStrengthColumnIndex],
      cellStyle: { textAlign: 'left' },
      cellRenderer: (params: ICellRendererParams) => {
        const value = params.valueFormatted ? params.valueFormatted : params.value;

        return <span className="rowNameCell">{value}</span>;
      },
    };

    setSummaryColumnDefs(summaryColDefs);
  }, [mainTableColumnDefs, sizeSystemRowId, storeStrengthTableData]);

  // summary rows
  useEffect(() => {
    if (!storeStrengthTableData || !dynamicColDefsRef.current || !summaryColumnDefs || !sizeSystemRowId) return;

    const summaryRows: TableRow[] = [
      { [STORE_STRENGTH_FIELD]: t('productOptimalStock.optimal_stock') },
      { [STORE_STRENGTH_FIELD]: t('productOptimalStock.estimated_picking') },
      { [STORE_STRENGTH_FIELD]: t('productOptimalStock.actual_stock_demand') },
    ];

    const { summary, headers } = storeStrengthTableData as ProductOptimalStockTableWrapperDto;

    if (summary) {
      const totals = [0, 0, 0];

      const correctIndexes = headers
        ?.map((x, i) => ({ index: i - 2, ...x }))
        .filter((x) => x.optimalStockOverrideId)
        .map((x) => x.index);
      const correctSummary = summary?.filter((y, i) => correctIndexes?.includes(i));

      correctSummary.forEach((column, index) => {
        if (column) {
          const currentDynamicColumnName = dynamicColDefsRef.current[index]?.field;

          if (typeof currentDynamicColumnName === 'string') {
            summaryRows[0] && (summaryRows[0][currentDynamicColumnName] = column['optimalStock']);
            totals[0] += column['optimalStock'];
            summaryRows[1] && (summaryRows[1][currentDynamicColumnName] = column['estimatedPicking']);
            totals[1] += column['estimatedPicking'];
            summaryRows[2] && (summaryRows[2][currentDynamicColumnName] = column['actualStockDemand']);
            totals[2] += column['actualStockDemand'];
          }
        }
      });

      totals.forEach((total, index) => {
        summaryRows[index] && (summaryRows[index]![TOTAL_FIELD] = total);
      });
    }

    setSummaryRowData(summaryRows);
  }, [storeStrengthTableData, sizeSystemRowId, summaryColumnDefs, t]);

  useEffect(() => {
    if (areAllGridsRendered) {
      overridesGridRef.current?.api?.setQuickFilter(doNotFilterMeText);
    }
  }, [areAllGridsRendered]);

  useEffect(() => {
    lsSetFilterState({});
  }, []);

  const handleIsOnShowcaseChanged = useCallback(
    async (e: CheckboxChangeEvent) => {
      const newValue = e.target.checked;
      const result = await updateProductShowcaseMutation({
        variables: {
          productId,
          isOnShowcase: newValue,
        },
      });

      if (result.data?.updateProductDetails.isOnShowcase === newValue) {
        refetchData();
      }
    },
    [productId, refetchData, updateProductShowcaseMutation],
  );

  const handleFirstDataRendered = useCallback(() => {
    gridsRenderedRef.current++;
    if (gridsRenderedRef.current === 3) {
      setAreAllGridsRendered(true);
    }
  }, []);

  const handleOverrideCellEditingStop = useCallback(
    async (event: CellEditingStoppedEvent) => {
      let editedRowId = event.data.id;
      if (editedRowId === MIN_CONDITION_FIELD) editedRowId = MIN_CONDITION_FIELD_VALUE;
      if (editedRowId === MAX_CONDITION_FIELD) editedRowId = MAX_CONDITION_FIELD_VALUE;

      const editedColId = Number(event.column.getColId());
      const newValue = Number(event.newValue);

      let isValueEqualToInitial = false;

      const { overrides: overridesInitialValues } = storeStrengthTableData as ProductOptimalStockTableWrapperDto;

      if (overridesInitialValues) {
        const editedColIndex = overridesInitialValues.findIndex((col) => col?.id === editedColId);
        if (editedColIndex > -1) {
          const editedCol = overridesInitialValues[editedColIndex] as ArticleOptimalStockOverride;
          isValueEqualToInitial = newValue === editedCol[editedRowId as keyof typeof editedCol];
        }
      }

      !isValueEqualToInitial && (await updateOverride(editedRowId, editedColId, newValue));

      const rowIndex = event.rowIndex;
      const columns = overridesGridRef.current?.columnApi.getColumns();
      const colKey = columns?.find((column) => column.getInstanceId() === event.column.getInstanceId() + 1);
      rowIndex != null && colKey && overridesGridRef.current?.api.startEditingCell({ rowIndex, colKey });
    },
    [storeStrengthTableData?.overrides, updateOverride],
  );

  const handleValidatedStrengthKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (!isAllowedDecimalFieldKey(i18n.language, event)) {
        event.preventDefault();
      }
    },
    [i18n.language],
  );

  const handleValidatedStrengthStepChange = useCallback(async () => {
    if (typeof strengthValidationLatestValueRef.current === 'number') {
      setValidatedStrength(parseFloat(strengthValidationLatestValueRef.current.toFixed(1)));
      await updateValidatedStrength();
    }
  }, [updateValidatedStrength]);

  const handleValidatedStrengthChange = useCallback((value: number | null) => {
    if (!value) value = 0;

    strengthValidationLatestValueRef.current = value;

    setValidatedStrength(value);
  }, []);

  if (!sizeSystemRowId || !selectedProductModuleId) {
    return (
      <Alert
        message={
          <>
            {!sizeSystemRowId && <div>{t('productOptimalStock.please_select_size_system_row')}</div>}
            {!selectedProductModuleId && <div>{t('productOptimalStock.please_select_product_module')}</div>}
          </>
        }
        type="info"
        showIcon
        style={{ float: 'left' }}
      />
    );
  }

  if (loadingStoreStrengthTable || pageLoading) return <Spinner />;

  const gridOverlay = (
    <GridOverlay visible={isOverridesUpdateInProgress}>
      <Spinner size="small" />
    </GridOverlay>
  );

  return (
    <>
      <Space wrap>
        <StyledDescriptions size="small">
          <Item label={t('productOptimalStock.is_on_showcase')}>
            {typeof metadata?.isOnShowcase === 'boolean' && (
              <Checkbox
                checked={metadata.isOnShowcase}
                onChange={handleIsOnShowcaseChanged}
                disabled={
                  !(permissions as PermissionsEnum[])?.includes(PermissionsEnum.ProductPerformanceDetailsEdit) ||
                  isProductArchived
                }
              />
            )}
          </Item>

          <Item label={t('productOptimalStock.number_of_stores_with_showcase')}>
            {metadata?.numberOfStoresWithShowcase}
          </Item>
        </StyledDescriptions>

        <StyledDescriptions size="small">
          <Item label={t('productOptimalStock.weekly_sales_flow')}>{Number(metadata?.weeklySalesFlow).toFixed(1)}</Item>
          <Item label={t('productOptimalStock.theoretical_sales_flow')}>
            {Number(metadata?.theoreticalSalesFlow).toFixed(1)}
          </Item>
        </StyledDescriptions>

        <StyledDescriptions size="small">
          <Item label={t('productOptimalStock.proposed_strength')}>
            {isNumber(metadata?.proposedStrength) && metadata?.proposedStrength
              ? metadata?.proposedStrength.toFixed(1)
              : metadata?.proposedStrength}
          </Item>
          <Item label={t('productOptimalStock.validate')}>
            {typeof metadata?.validatedProductStrength === 'number' && (
              <InputNumber
                min={0}
                step={0.1}
                ref={validatedStrengthInputRef}
                defaultValue={metadata?.validatedProductStrength}
                value={metadata?.validatedProductStrength}
                onChange={handleValidatedStrengthChange}
                onBlur={updateValidatedStrength}
                onPressEnter={updateValidatedStrength}
                onKeyDown={handleValidatedStrengthKeyDown}
                onStep={handleValidatedStrengthStepChange}
                size="small"
                disabled={
                  !(permissions as PermissionsEnum[])?.includes(PermissionsEnum.ProductPerformanceDetailsEdit) ||
                  isProductArchived
                }
              />
            )}
          </Item>
        </StyledDescriptions>
      </Space>
      <StyledGridsWrapper>
        <div style={{ position: 'relative' }}>
          <AgGridReact
            columnDefs={mainTableColumnDefs}
            rowData={mainTableRowData}
            defaultColDef={defaultColDef}
            domLayout="autoHeight"
            onFirstDataRendered={handleFirstDataRendered}
            headerHeight={22}
            rowHeight={23}
            columnTypes={COLUMN_TYPES}
            enableRangeSelection={true}
          />
          {gridOverlay}
        </div>

        {!isEmpty(overridesRowData) && (
          <AgGridReact
            ref={overridesGridRef}
            columnDefs={overridesColumnDefs}
            rowData={overridesRowData}
            defaultColDef={defaultColDef}
            rowHeight={23}
            domLayout="autoHeight"
            onFirstDataRendered={handleFirstDataRendered}
            onCellEditingStopped={handleOverrideCellEditingStop}
            stopEditingWhenCellsLoseFocus
            suppressRowTransform
            suppressClickEdit={isOverridesUpdateInProgress}
            className="overridesGrid"
            animateRows
            columnTypes={COLUMN_TYPES}
            enableRangeSelection={true}
            singleClickEdit={true}
          />
        )}

        <div style={{ position: 'relative' }}>
          <AgGridReact
            columnDefs={summaryColumnDefs}
            rowData={summaryRowData}
            headerHeight={0}
            rowHeight={23}
            defaultColDef={defaultColDef}
            domLayout="autoHeight"
            onFirstDataRendered={handleFirstDataRendered}
            className="summaryGrid"
            columnTypes={COLUMN_TYPES}
            enableRangeSelection={true}
          />
          {gridOverlay}
        </div>
      </StyledGridsWrapper>
    </>
  );
};

export default IdealStockCalculationTable;
