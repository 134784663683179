import React, { useState } from 'react';

import styled from 'styled-components';

const StyledButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  width: 100%;
`;
type Props = {
  changeColor?: boolean;
  checked: boolean;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
  size?: number;
};

const CellCheckbox: React.FC<Props> = ({ checked, disabled, onChange, size = 24, changeColor }) => {
  const [isChecked, setIsChecked] = useState(checked);
  const onClickHandler = () => {
    setIsChecked((prevChecked) => {
      onChange(!prevChecked);

      return !prevChecked;
    });
  };

  return (
    <StyledButton disabled={disabled} onClick={onClickHandler}>
      <span
        style={{ fontSize: size, color: changeColor ? (isChecked ? 'green' : 'red') : 'unset' }}
        className={`ag-icon ${isChecked ? 'ag-icon-tick' : 'ag-icon-cross'}`}
      />
    </StyledButton>
  );
};

export default CellCheckbox;
