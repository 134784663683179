import { DefaultTheme } from 'styled-components';

const defaultTheme: DefaultTheme = {
  black: '#000000D9',
  primary_yellow: '#FFCF00',
  pale_yellow: '#FDE269',
  dark_gray: '#3C3C3F',
  light_gray: '#949496',
  green: '#4FAB16',
  light_green: '#b4e497',
  light_soft_gray: '#ddd',
  dark_blue_background: '#39495c',
  light_blue_background: '#4c5b6d',
  white: '#ffffff',
};

export default defaultTheme;
