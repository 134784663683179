import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';

import { ColDef, RowClassParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { getFormattedCurrentDateTime } from 'utils/moment';

import ImportExportXlsxComponent from 'components/ImportExportXlsxComponent';
import { CENTERED_COL_DEF, COLUMN_TYPES, FOOTER_ROW_CLASS } from 'constants/gridConstants';
import { getContextMenu } from 'helpers/gridContextMenu';
import { useCommonData } from 'hooks/useCommonData';
import { StoreFleetSummaryStorePerformanceDto, useGetStoreFleetSummaryLazyQuery } from 'services/graphql/main';
import { useError } from 'services/utils';

import { ExportButtonWrapper } from './styles';

const StorePerformanceSummary: React.FC = () => {
  const { t } = useTranslation('translation');
  const { addError } = useError();

  const { seasonId, universeId } = useCommonData();

  const gridRef = useRef<AgGridReact>(null);

  const [rowData, setRowData] = useState<StoreFleetSummaryStorePerformanceDto[]>();

  const [getStoreFleetSummaryLazyQuery, { data }] = useGetStoreFleetSummaryLazyQuery({
    onError: (err) => addError(err, 'error'),
  });

  useEffect(() => {
    if (seasonId && universeId) getStoreFleetSummaryLazyQuery({ variables: { universeId, seasonId } });
  }, [getStoreFleetSummaryLazyQuery, seasonId, universeId]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100,
      ...CENTERED_COL_DEF,
    };
  }, []);

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        field: 'storePerformance',
        sort: 'asc',
        sortable: true,
        headerName: t('storeFleetSummary.store_fleet_summary_performance'),
      },
      {
        field: 'storePerformanceStoreCount',
        headerName: t('storeFleetSummary.number_of_stores'),
      },
      {
        field: 'percentageOfTotal',
        headerName: `% ${t('storeFleetSummary.number_of_stores')}`,
        valueGetter(params) {
          if (params?.data?.percentageOfTotal) {
            const value = params?.data?.percentageOfTotal.toFixed(0) + ' %';

            return value ? value : null;
          }
        },
      },
    ],
    [t],
  );

  const getRowClass = useCallback((params: RowClassParams) => {
    const lastRow = gridRef.current!.api.getLastDisplayedRow();

    if (params.node.rowIndex === lastRow) {
      return FOOTER_ROW_CLASS;
    }
  }, []);

  useEffect(() => {
    if (data?.storeFleetSummary) {
      const calculateData = (rawData: StoreFleetSummaryStorePerformanceDto[]) => {
        const initialValue = { storePerformance: 'Total', storePerformanceStoreCount: 0, percentageOfTotal: 0 };
        const calculatedData = rawData?.reduce(
          (previousValue: StoreFleetSummaryStorePerformanceDto, currentValue: StoreFleetSummaryStorePerformanceDto) => {
            return {
              storePerformance: 'Total',
              storePerformanceStoreCount:
                previousValue.storePerformanceStoreCount + currentValue.storePerformanceStoreCount,
              percentageOfTotal: previousValue.percentageOfTotal + currentValue.percentageOfTotal,
            };
          },
          initialValue,
        );

        return {
          ...calculatedData,
          percentageOfTotal:
            calculatedData.percentageOfTotal > 99.9 ? 100 : calculatedData.percentageOfTotal.toFixed(2),
        };
      };

      const mutableData: any =
        data &&
        data?.storeFleetSummary?.storePerformanceTable?.map((x) => ({
          storePerformance: x?.storePerformance,
          storePerformanceStoreCount: x?.storePerformanceStoreCount,
          percentageOfTotal: x?.percentageOfTotal,
        }));

      const calculatedData = calculateData(mutableData);

      data && setRowData([...mutableData, calculatedData]);
    } else {
      setRowData([]);
    }
  }, [data]);

  const onExport = useCallback(() => {
    gridRef.current &&
      gridRef.current.api.exportDataAsExcel({
        fileName: `${t('storeFleetSummary.store_fleet_summary')}_${getFormattedCurrentDateTime()}`,
      });
  }, [t]);

  const onCSVExport = useCallback(() => {
    gridRef.current!.api.exportDataAsCsv();
  }, []);

  return (
    <>
      <ExportButtonWrapper>
        <ImportExportXlsxComponent exportClickHandler={onExport} noImportOption />
      </ExportButtonWrapper>
      <AgGridReact
        containerStyle={{ width: '100%', marginBottom: 30, height: 'auto' }}
        ref={gridRef}
        columnDefs={columnDefs}
        rowData={rowData}
        defaultColDef={defaultColDef}
        domLayout={'autoHeight'}
        getRowClass={getRowClass}
        columnTypes={COLUMN_TYPES}
        enableRangeSelection={true}
        getContextMenuItems={() => getContextMenu(onExport, onCSVExport)}
      />
    </>
  );
};

export default StorePerformanceSummary;
