import styled from 'styled-components';

import { GRID_MAX_HEIGHT } from 'constants/gridConstants';

export const GridButtonsWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin: 20px 0;
`;

export const GridTypeWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-start;
  gap: 10px;

  p {
    display: flex;
    align-items: center;
    margin: 0;
  }
`;

export const NoCollectionSelectedMessage = styled.div`
  display: flex;
  justify-content: center;
  height: 310px;
  align-items: center;
  color: ${(p) => p.theme.white};

  p {
    font-size: 26px;
    font-weight: 500;
  }
`;

export const AdaptiveGridResizer = styled.div`
  div.ag-root .ag-body-viewport {
    height: auto;
    max-height: ${GRID_MAX_HEIGHT};
    overflow-y: scroll;
  }

  .ag-pinned-right-header {
    width: 76px !important;
    min-width: unset !important;
    max-width: unset !important;
  }
`;
