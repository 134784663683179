import { OptimalStockSimulationDto } from 'services/graphql/main';

export const prepareRowData = (optimalStockData: OptimalStockSimulationDto[] | null | undefined) => {
  const rows = optimalStockData?.map((row) => ({
    ...row,
    permutations: row.permutationField2
      ? `${row.permutationField1} - ${row.permutationField2}`
      : row.permutationField1!,
  }));

  return rows || [];
};
