import React from 'react';

import noSeasonPlaceholderImage from 'assets/images/noSeasonPlaceholderImage.png';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const PlaceholderImg = styled.img`
  display: block;
  margin: auto;
  margin-top: 80px;
  width: 200px;
`;

const InfoText = styled.p`
  padding: 100px 60px;
  font-size: 22px;
  text-align: center;
  font-weight: 500;
`;

const NoSeasonEmptyState: React.FC = () => {
  const { t } = useTranslation('translation');

  return (
    <div>
      <PlaceholderImg src={noSeasonPlaceholderImage} />
      <InfoText>{t('common.select_season_to_display')}</InfoText>
    </div>
  );
};

export default NoSeasonEmptyState;
