import React, { useEffect, useRef, useState, useCallback, useMemo, useContext } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { CellEditingStartedEvent, CellEditingStoppedEvent, ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Checkbox } from 'antd';
import { SeasonContext } from 'context';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { AlphanumericCellEditor } from 'components/AgGridComponents';
import ConfirmModal from 'components/Modals/ConfirmModal/ConfirmModal';
import { NakedButton, PrimaryButton, Spinner } from 'components/UI';
import { ActionButtonsWrapper } from 'components/wrappers';
import { CENTERED_COL_DEF, COLUMN_TYPES } from 'constants/gridConstants';
import { VALIDATIONS_TYPES } from 'constants/validations';
import { forbidEmptyCellValueSetter } from 'helpers/gridCells';
import { sortEmpty } from 'helpers/gridCols';
import { partiallyEditedGridData } from 'helpers/gridData';
import { validateGridCustom } from 'helpers/validations';
import {
  Season as Sz,
  useBulkUpdateSeasonsMutation,
  useCreateSeasonMutation,
  useDeleteSeasonMutation,
  useGetSeasonsQuery,
  useUpdateSeasonMutation,
} from 'services/graphql/main';
import { useError } from 'services/utils';

import { CheckBoxProps, DeleteIconProps, MutationStateProps } from '../../common/types';

const SeasonProductTable: React.FC = () => {
  const { t } = useTranslation('translation');
  const { refetch } = useContext(SeasonContext);
  const { addCustomError, addError } = useError();

  const { loading, data, refetch: refetchSeasons } = useGetSeasonsQuery({ onError: (err) => addError(err, 'error') });

  const [updateBulkSeasons, { error: bulkUpdateError }] = useBulkUpdateSeasonsMutation({
    onError: (err) => addError(err, 'warning'),
  });
  const [updateSeason, { error: updateError }] = useUpdateSeasonMutation({
    onError: (err) => addError(err, 'warning'),
  });
  const [deleteSeasonsMutation] = useDeleteSeasonMutation({ onError: (err) => addError(err, 'warning') });
  const [createSeasonMutation, { error: createError }] = useCreateSeasonMutation({
    onError: (err) => addError(err, 'warning'),
  });

  const initialGridData = useRef<any>(null);
  const gridRef = useRef<AgGridReact<Sz>>(null);

  const [currentEditedRow, setCurrentEditedRow] = useState<number | null>(null);
  const [isUpdate, setIsUpdate] = useState({ isCreateRowActive: false, isCellActive: false });

  const [rowData, setRowData] = useState<(Sz | null)[] | null | undefined>();
  const [mutationState, setMutationState] = useState<MutationStateProps | null>(null);
  const hasUpdate = !isEmpty(mutationState?.data);
  const [errors, setErrors] = useState<any>(null);

  const onCellEditingStarted = useCallback(
    (event: CellEditingStartedEvent | null) => {
      if (currentEditedRow === null) {
        setCurrentEditedRow(event!.rowIndex);
      }

      setIsUpdate((prevState) => ({
        ...prevState,
        isCellActive: true,
      }));
    },
    [currentEditedRow],
  );

  const onCellEditingStopped = useCallback(
    (event: CellEditingStoppedEvent) => {
      const defaultChanges = {
        code: event!.data.code,
        name: event!.data.name,
      };

      if (mutationState?.type == 'create-row') {
        setMutationState({
          type: 'create-row',
          data: {
            ...defaultChanges,
          },
        });
      } else {
        const editedRows = partiallyEditedGridData({
          event,
          prevRows: mutationState?.data,
          initialRows: initialGridData.current,
        });

        if (editedRows) {
          setMutationState({
            type: 'update-row',
            data: editedRows,
          });
        }
      }

      setIsUpdate((prevState) => ({
        ...prevState,
        isCellActive: false,
      }));
    },
    [mutationState?.data, mutationState?.type],
  );

  const handleSaveClick = useCallback(async () => {
    const gridData = gridRef
      .current!.api.getRenderedNodes()
      .map((x) => x.data)
      .map((y) => {
        return {
          code: y?.code,
          name: y?.name,
        };
      });

    const { DUPLICATES, EMPTY_CELL, LENGTH } = VALIDATIONS_TYPES;

    const validations = [DUPLICATES, EMPTY_CELL, LENGTH];
    const validation = validateGridCustom(validations, gridData);

    if (validation?.isValid === false) {
      addCustomError({ message: validation?.code }, 'warning');
    } else if (mutationState && validation?.isValid) {
      switch (mutationState?.type) {
        case 'update-row':
          if (mutationState?.data?.length > 1)
            await updateBulkSeasons({
              variables: {
                inputSeasons: mutationState.data,
              },
            });
          else await updateSeason({ variables: { inputSeason: mutationState?.data[0] } });

          await refetchSeasons();
          await refetch();

          setMutationState(null);
          break;
        case 'create-row':
          await createSeasonMutation({
            variables: {
              season: mutationState.data,
            },
          });

          await refetchSeasons();
          await refetch();

          setMutationState(null);
          break;

        default:
          break;
      }

      setIsUpdate((prevState) => ({
        ...prevState,
        isCreateRowActive: false,
        isUpdateRowActive: false,
      }));

      setCurrentEditedRow(null);
    }
  }, [addCustomError, createSeasonMutation, mutationState, refetch, updateBulkSeasons, refetchSeasons, updateSeason]);

  const handleCreateSeasonClick = useCallback(() => {
    let idRow;
    const lastElement = rowData?.at(-1);

    (lastElement && (idRow = lastElement.id + 1)) ?? (idRow = 0);

    const agGridConstructorObject = {
      id: idRow,
      code: '',
      isActive: true,
      name: '',
    };

    gridRef.current!.api.applyTransaction({
      add: [agGridConstructorObject],
      addIndex: rowData?.length,
    });

    const gridData = gridRef.current!.api.getRenderedNodes();

    setTimeout(() => {
      gridRef.current!.api.startEditingCell({
        rowIndex: gridData.length - 1,
        colKey: 'code',
      });
    }, 1);

    const mutationStateNew = {
      type: 'create-row',
      data: { code: '', name: '' },
    };

    setIsUpdate((prevState) => ({
      ...prevState,
      isCreateRowActive: true,
    }));

    setMutationState(mutationStateNew);
  }, [rowData]);

  const columnDefs: ColDef[] = useMemo(() => {
    const DeleteIcon: React.FC<DeleteIconProps> = (props) => {
      const [isModalOpen, setIsModalOpen] = useState(false);

      const showModal = useCallback(() => {
        setIsModalOpen(true);
      }, []);
      const hideModal = useCallback(() => {
        setIsModalOpen(false);
      }, []);

      const clickAction = useCallback(() => {
        props.delete(props.id, props.season);
        hideModal();
      }, [hideModal, props]);

      return (
        <div>
          <NakedButton disabled={isUpdate.isCreateRowActive || hasUpdate} type="text" onClick={showModal}>
            <DeleteOutlined style={{ cursor: 'pointer', textAlign: 'center' }} />
          </NakedButton>

          <ConfirmModal
            customTitle={t('operationalSettings.are_you_sure_you_want_to_delete_season')}
            onOk={clickAction}
            open={isModalOpen}
            onCancel={hideModal}
          />
        </div>
      );
    };

    const MyCheckBox: React.FC<CheckBoxProps> = (props) => {
      const [isCheckedBox, setIsCheckedBox] = useState(props.isActive);
      const clickAction = async () => {
        await props.edit(props.id, props.rowIndex, !isCheckedBox);
        setIsCheckedBox(!isCheckedBox);
      };

      return (
        <Checkbox
          defaultChecked={props.isActive}
          disabled={isUpdate.isCreateRowActive || hasUpdate}
          onClick={clickAction}
        />
      );
    };

    const updateIsActive = async (id: number, rowIndex: number, isActiveValue: boolean) => {
      const gridData = gridRef
        .current!.api.getRenderedNodes()
        .map((x) => x.data)
        .map((y) => {
          return {
            id: y!.id,
            isActive: id === y?.id ? isActiveValue : y?.isActive,
            name: y?.name,
            code: y?.code,
          };
        })
        .sort((a, b) => a.id - b.id);

      await updateBulkSeasons({
        variables: {
          inputSeasons: gridData,
        },
      });

      await refetchSeasons();
      await refetch();
    };

    const deleteSeason = async (id: number, season: Sz) => {
      if (mutationState == null) {
        await deleteSeasonsMutation({
          variables: {
            id: id,
          },
        });

        await refetchSeasons();
        await refetch();
      } else {
        gridRef.current?.api.applyTransaction({ remove: [season] });
        setMutationState(null);
        setCurrentEditedRow(null);
      }
    };

    return [
      {
        field: 'code',
        ...(!mutationState === null && { sort: 'asc' }),
        comparator: sortEmpty,
        width: 300,
        headerName: t('operationalSettings.code'),
        editable: true,
        valueSetter: forbidEmptyCellValueSetter,
        cellEditor: AlphanumericCellEditor,
      },
      {
        field: 'name',
        comparator: sortEmpty,
        width: 300,
        headerName: t('operationalSettings.label'),
        editable: true,
        valueSetter: forbidEmptyCellValueSetter,
      },
      {
        field: 'isActive',
        maxWidth: 80,
        sortable: false,
        pinned: 'right',
        suppressMenu: true,
        headerName: t('operationalSettings.active'),
        ...CENTERED_COL_DEF,
        cellRendererSelector: (params) => {
          return {
            component: MyCheckBox,
            params: {
              isActive: params.data.isActive,
              id: params.data.id,
              edit: updateIsActive,
              currentEditedRow: currentEditedRow,
              rowIndex: params.rowIndex,
              mutationState: mutationState,
            },
          };
        },
      },
      {
        maxWidth: 60,
        cellStyle: { textAlign: 'center' },
        pinned: 'right',
        suppressMenu: true,
        sortable: false,
        cellRendererSelector: (params) => {
          return {
            component: DeleteIcon,
            params: {
              id: params.data.id,
              delete: deleteSeason,
              season: params.data,
              currentEditedRow: currentEditedRow,
              setCurrentEditedRow: setCurrentEditedRow,
              rowIndex: params.rowIndex,
            },
          };
        },
      },
    ];
  }, [
    currentEditedRow,
    deleteSeasonsMutation,
    hasUpdate,
    isUpdate.isCreateRowActive,
    mutationState,
    refetch,
    refetchSeasons,
    t,
    updateBulkSeasons,
  ]);

  const handleCancelButton = useCallback(() => {
    if (mutationState?.type === 'create-row') {
      const lastRowIndex = gridRef.current!.api.getLastDisplayedRow();
      const lastRow = gridRef.current!.api.getDisplayedRowAtIndex(lastRowIndex);

      gridRef.current?.api.applyTransaction({ remove: [lastRow?.data!] });

      const mutableData =
        data &&
        data!.seasons
          ?.map((x) => {
            return {
              id: x?.id,
              isActive: x?.isActive,
              name: x?.name,
              code: x?.code,
            };
          })
          .sort((a, b) => a.id - b.id);

      mutableData && gridRef.current?.api.setRowData(mutableData);

      setRowData(mutableData);
    } else {
      const mutableData =
        data &&
        data!.seasons
          ?.map((x) => {
            return {
              id: x?.id,
              isActive: x?.isActive,
              name: x?.name,
              code: x?.code,
            };
          })
          .sort((a, b) => a.id - b.id);

      mutableData && gridRef.current?.api.setRowData(mutableData);

      setRowData(mutableData);
    }

    setIsUpdate((prevState) => ({
      ...prevState,
      isCreateRowActive: false,
      isUpdateRowActive: false,
      isCellActive: false,
    }));

    setMutationState(null);
  }, [data, mutationState?.type]);

  useEffect(() => {
    const mutableData =
      data &&
      data!.seasons
        ?.map((x) => {
          return {
            id: x?.id,
            isActive: x?.isActive,
            name: x?.name,
            code: x?.code,
          };
        })
        .sort((a, b) => a.id - b.id);

    const gridData =
      data &&
      data!.seasons
        ?.map((y) => {
          return {
            id: y?.id,
            isActive: y?.isActive,
            name: y?.name,
            code: y?.code,
          };
        })
        .sort((a, b) => a.id - b.id);

    initialGridData.current = gridData;

    data && setRowData(mutableData);
  }, [data]);

  useEffect(() => {
    createError?.graphQLErrors || bulkUpdateError?.graphQLErrors ? setErrors(true) : setErrors(null);
  }, [bulkUpdateError?.graphQLErrors, createError?.graphQLErrors]);

  const defaultColDef: ColDef = useMemo(
    () => ({
      flex: 1,
      suppressMovable: true,
      editable: true,
      sortable: true,
      minWidth: 100,
    }),
    [],
  );

  return (
    <div>
      {loading ? (
        <Spinner size="large" />
      ) : rowData?.length ? (
        <AgGridReact
          ref={gridRef}
          containerStyle={{ width: '100%' }}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          domLayout={'autoHeight'}
          stopEditingWhenCellsLoseFocus={true}
          onCellEditingStopped={onCellEditingStopped}
          onCellEditingStarted={onCellEditingStarted}
          columnTypes={COLUMN_TYPES}
          enableRangeSelection={true}
        />
      ) : null}
      <ActionButtonsWrapper>
        <NakedButton
          type="text"
          text={t('operationalSettings.create_product')}
          onClick={handleCreateSeasonClick}
          disabled={isUpdate.isCreateRowActive || hasUpdate || errors}
          style={{ color: '#FFFFFF', marginRight: 'auto' }}
        />
        {isUpdate.isCreateRowActive || hasUpdate ? (
          <>
            <NakedButton
              type="text"
              onClick={handleCancelButton}
              textKey="common.cancel"
              style={{ color: '#FFFFFF' }}
            />

            <PrimaryButton
              onClick={handleSaveClick}
              textKey="common.save"
              disabled={isUpdate.isCellActive}
            ></PrimaryButton>
          </>
        ) : null}
      </ActionButtonsWrapper>
    </div>
  );
};
export default SeasonProductTable;
