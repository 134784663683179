import { Menu } from 'antd';
import styled from 'styled-components';

export const StyledMenu = styled(Menu)<{ $isCollapsed?: boolean }>`
  min-height: 100%;
  border-right: none;
  background-color: ${(p) => p.theme.dark_blue_background};
  color: ${(p) => p.theme.white};

  .ant-menu-sub {
    background-color: ${(p) => p.theme.dark_blue_background};
  }

  .ant-menu-item:hover,
  .ant-menu-item a:hover,
  .ant-menu-submenu:hover,
  .ant-menu-submenu-title:hover,
  .ant-menu-item-active,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title-active,
  .ant-menu-item-open,
  .ant-menu-submenu-open,
  .ant-menu-item-selected,
  .ant-menu-item-selected a,
  .ant-menu-submenu-selected,
  .ant-menu-submenu-title-selected,
  .ant-menu-submenu > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ant-menu-title-content a {
    color: ${(p) => p.theme.white};
  }

  .ant-menu-item-selected a,
  .ant-menu-submenu-selected > .ant-menu-submenu-title {
    font-weight: bold;
  }

  .ant-menu-item-selected {
    background-color: ${(p) => p.theme.dark_blue_background} !important;
  }

  .ant-menu-inline .ant-menu-item:after,
  .ant-menu-item:after {
    border-right: 3px solid ${(p) => p.theme.primary_yellow};
  }

  .ant-menu-submenu-arrow {
    display: ${(p) => p.$isCollapsed && 'none'};
  }
`;
