import styled from 'styled-components';

export const StyledTableWrapper = styled.div`
  display: flex;

  .ag-center-cols-clipper,
  .ag-center-cols-container {
    min-height: 41px !important;
  }
`;
