import styled from 'styled-components';

export const SectionTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
  color: ${(p) => p.theme.white};
`;

export const StyledLabel = styled.label`
  color: ${(p) => p.theme.white};
  text-decoration: none;
  font-size: 12px;
  align-items: center;
  display: block;
  margin-bottom: 5px;
`;

export const InfoText = styled.p`
  font-size: 15px;
  color: ${(p) => p.theme.white};
  font-style: italic;
`;

export const DataImportSection = styled.div`
  display: flex;
`;

export const LeftPart = styled.div`
  flex: 1;
  margin-right: 30px;
`;

export const RightPart = styled.div`
  flex: 1;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  & label:not(:first-child) {
    margin-bottom: 0px;
    margin-left: 10px;
  }
`;
